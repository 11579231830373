import * as _ from 'lodash';
import React, { Component } from 'react';
import moment, { Moment } from 'moment';
import { DatePicker, Form, Select, TimePicker } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { connect } from 'react-redux';
import IApplicationState from '../../types/state.types';
import * as selectors from '../../redux/selectors';
import './appointmentForm.scss';
import { AdminUserType } from '../../types/serverTypes/adminTypes';
import { AvailabilityType } from '../../types/serverTypes/appointmentTypes';
import { createStructuredSelector } from 'reselect';
import AntdIcon from '../antdIcon/AntdIcon';
import { faUser } from '@fortawesome/pro-regular-svg-icons';

const { Item } = Form;
const { Option } = Select;

interface StateProps {
  admin: Optional<AdminUserType[]>;
}

interface DispatchProps {}

interface ComponentProps extends StateProps, DispatchProps {
  formRef: React.RefObject<FormInstance>;
  availability: AvailabilityType;
  onChange: () => void;
  disabled?: boolean;
}

interface ComponentState {}

export interface DisabledTimes {
  disabledHours?: () => number[];
  disabledMinutes?: (hour: number) => number[];
  disabledSeconds?: (hour: number, minute: number) => number[];
}

function filter(inputValue: any, path: any): boolean {
  return path.some((option: any) =>
    option.label
      ? option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      : false
  );
}
function range(start: number, end: number) {
  const result: number[] = [];
  for (let i: number = start; i <= end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current: Moment | null): boolean {
  return !!(current && current < moment().startOf('day'));
}

const labelColStyle = {
  padding: 0,
  maxWidth: '40px',
};

class AvailabilityForm extends Component<ComponentProps, ComponentState> {
  disabledEndHours = () => {
    const { formRef } = this.props;
    const form = formRef.current;
    const start = moment(form?.getFieldValue('startTime'));
    return range(0, start.hour() - 1);
  };

  disabledEndMinutes = () => {
    const { formRef } = this.props;
    const form = formRef.current;
    const start = moment(form?.getFieldValue('startTime'));
    const end = moment(form?.getFieldValue('endTime'));
    return end?.isSame(start, 'hour') ? range(0, start.minute()) : [];
  };

  onStartTimeBlur = (value: Moment) => {
    const form = this.props.formRef.current;
    if (form) {
      const start = moment(form.getFieldValue('startTime'));
      const end = moment(form.getFieldValue('endTime'));
      if (value.isSameOrAfter(end)) {
        // If new startTime is after the previous endTime,
        // we shift the endTime to be the same duration after
        // the new startTime.
        const diff = end.diff(start, 'minutes');
        const newEnd = value.clone();
        newEnd.add(diff, 'minutes');
        form.setFieldsValue({
          startTime: value,
          endTime: newEnd,
        });
      } else {
        form.setFieldsValue({
          startTime: value,
        });
      }
      this.props.onChange();
    }
  };

  onEndTimeBlur = (value: Moment) => {
    const form = this.props.formRef.current;
    if (form) {
      form.setFieldsValue({
        endTime: value,
      });
      this.props.onChange();
    }
  };

  validateTimeRange = (rule, value) => {
    const { formRef } = this.props;
    const form = formRef.current;
    if (form) {
      const start: Moment | undefined = form.getFieldValue('startTime');
      const end: Moment | undefined = form.getFieldValue('endTime');
      if (!moment.isMoment(start) || !moment.isMoment(end)) {
        return Promise.reject();
      }
      return Promise.resolve();
    }
  };

  render() {
    const { formRef, availability, admin, disabled } = this.props;
    const { id, userId, startDate, endDate } = availability;

    return (
      <Form ref={formRef} key={id} colon={false}>
        <Item
          name="userId"
          key={userId}
          rules={[{ required: true, message: 'Admin is required.' }]}
          initialValue={userId}
          label={
            <AntdIcon
              classes="form-item-icon"
              size="lg"
              fontAwesomeIcon={faUser}
            />
          }
          labelCol={{
            style: labelColStyle,
          }}
        >
          <Select
            value={userId}
            disabled={disabled}
            style={{ width: 'auto', minWidth: '200px' }}
            onChange={() => this.props.onChange()}
          >
            {_.map(admin, (admin) => (
              <Option key={admin.id} value={admin.id}>
                {' '}
                {admin.username}{' '}
              </Option>
            ))}
          </Select>
        </Item>
        <Item className="parent-form-item" style={{ marginBottom: 0 }}>
          <Item
            name="startDate"
            key={`${id}-startDate`}
            initialValue={moment(startDate)}
            rules={[{ required: true, message: 'Start date is required.' }]}
            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
          >
            <DatePicker
              disabled={disabled}
              disabledDate={disabledDate}
              bordered={false}
              format="YYYY-MM-DD"
              onChange={() => this.props.onChange()}
            />
          </Item>
          <Item
            name="timeRange"
            key={`${id}-timeRange`}
            rules={[
              {
                required: true,
                message: 'Start and end times are required.',
                validator: this.validateTimeRange,
              },
            ]}
            style={{ display: 'inline-block', width: 'calc(60% - 8px)' }}
          >
            <Item
              name="startTime"
              key={`${id}-startTime`}
              initialValue={moment(startDate)}
              style={{
                marginBottom: 0,
                display: 'inline-block',
                width: 'calc(50% - 4px)',
              }}
            >
              <TimePicker
                showNow={false}
                bordered={false}
                onSelect={this.onStartTimeBlur}
                format="hh:mm A"
                minuteStep={5}
                disabled={disabled}
              />
            </Item>
            <Item
              name="endTime"
              key={`${id}-endTime`}
              initialValue={moment(endDate)}
              style={{
                marginBottom: 0,
                display: 'inline-block',
                width: 'calc(50% - 4px)',
              }}
            >
              <TimePicker
                showNow={false}
                disabledHours={this.disabledEndHours}
                disabledMinutes={this.disabledEndMinutes}
                bordered={false}
                onSelect={this.onEndTimeBlur}
                format="hh:mm A"
                minuteStep={5}
                disabled={disabled}
              />
            </Item>
          </Item>
        </Item>
      </Form>
    );
  }
}
const mapStateToProps = (state: IApplicationState) => {
  return {
    admin: selectors.getAdmin(state),
  };
};

export default connect(mapStateToProps)(AvailabilityForm);
