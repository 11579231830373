import { Tabs } from 'antd';
import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import BadgeCollections from './BadgeCollections';
import * as selectors from '../../redux/selectors';
import IApplicationState from '../../types/state.types';
import BadgeGroups from './BadgeGroups';
import {
  getBadgeCollectionsAsync,
  getBadgeCollectionTypesAsync,
  getBadgeGroupsAsync
} from '../../redux/badges/badges.types';

interface StateProps {
  badgeSubpage: Optional<string>,
  studyId: number;
}

interface DispatchProps {
  getBadgeCollections: typeof getBadgeCollectionsAsync.request;
  getBadgeCollectionTypes: typeof getBadgeCollectionTypesAsync.request;
  getBadgeGroups: typeof getBadgeGroupsAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps, RouteComponentProps {}

class BadgesLandingPage extends Component<ComponentProps, {}> {

  componentDidMount() {
    this.props.getBadgeCollections();
    this.props.getBadgeCollectionTypes();
    this.props.getBadgeGroups();
  }

  handleTabChange = (activeKey:string) => {
    const { history, studyId } = this.props;
    history.push(`/study/${studyId}/badges/${activeKey}`);
  }

  render() {
    const { badgeSubpage } = this.props;

    return (
      <div id="badges-landing-page">
        <div className="hmp-tab-contanier">
          <Tabs defaultActiveKey={badgeSubpage} onChange={this.handleTabChange}>
            <Tabs.TabPane tab="Badge Collections" key="badgeCollections">
              <BadgeCollections />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Badge Groups" key="badgeGroups">
              <BadgeGroups />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  studyId: selectors.getRequestedStudyId,
  badgeSubpage: selectors.getRequestedBadgeTab
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getBadgeCollections: () => dispatch(getBadgeCollectionsAsync.request()),
    getBadgeCollectionTypes: () => dispatch(getBadgeCollectionTypesAsync.request()),
    getBadgeGroups: () => dispatch(getBadgeGroupsAsync.request())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BadgesLandingPage));
