import configuredStore from '../../redux/store';
import IApplicationState from '../../types/state.types';

export const hasAccess = (roles: string[]) => {
  const user = (configuredStore.store.getState() as IApplicationState).auth
    .user;

  const filteredArray = user?.roles.filter((value) =>
    roles.includes(value.role.role)
  );
  if (filteredArray && filteredArray?.length > 0) {
    return true;
  }
  return false;
};
