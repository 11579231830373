import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import IApplicationState, {NormalizedState} from '../../types/state.types';
import { entitySchema } from '../schema';
import {getUrlNewOrEditId} from "../router/router.selectors";
import {getRequestedStudyParticipant} from "../baseSelectors";
import {BadgeCollectionType, BadgeCollectionTypeType, BadgeGroupType, BadgeType} from "../../types/serverTypes";

export const selectBadges = (state: IApplicationState) => {
  return state.entities.badges;
};

export const selectBadgeCollections = (state: IApplicationState) => {
  return state.entities.badgeCollections;
};

export const selectBadgeGroups = (state: IApplicationState) => {
  return state.entities.badgeGroups;
};

export const selectBadgeCollectionTypes = (state: IApplicationState) => {
  return state.entities.badgeCollectionTypes;
};

export const getBadges = createSelector(
  [selectBadges],
  (badges): Optional<BadgeType[]> => {
    if (badges && badges.allIds && badges.allIds.length > 0) {
      const { badges: denormalizedBadges } = denormalize({ badges: badges.allIds }, entitySchema, { badges: badges.byId });
      return toArray(denormalizedBadges);
    }
    return undefined;
  }
);



export const getBadgeCollections = createSelector(
  [selectBadgeCollections],
  (badgeCollections): Optional<BadgeCollectionType[]> => {
    if (badgeCollections?.allIds?.length > 0) {
      const { badgeCollections: denormalizedBadgeCollections } = denormalize({ badgeCollections: badgeCollections.allIds }, entitySchema, { badgeCollections: badgeCollections.byId });
      return toArray(denormalizedBadgeCollections);
    }
    return undefined;
  }
);

export const getRequestedBadgeCollection = createSelector(
  [getBadgeCollections, getUrlNewOrEditId],
  (badgeCollections:BadgeCollectionType[] | undefined, badgeCollectionIdPathParam: string): Optional<BadgeCollectionType> => {
    if (badgeCollections?.length && badgeCollectionIdPathParam) {
      const badgeCollectionId = parseInt(badgeCollectionIdPathParam);
      return badgeCollections.find(bg => bg.id === badgeCollectionId);
    }
    return undefined
  }
);

export const getBadgeGroups = createSelector(
  [selectBadgeGroups],
  (badgeGroups): Optional<BadgeGroupType[]> => {
    if (badgeGroups && badgeGroups.allIds && badgeGroups.allIds.length > 0) {
      const x = denormalize({ badgeGroups: badgeGroups.allIds }, entitySchema, { badgeGroups: badgeGroups.byId });
      const { badgeGroups: denormalizedBadgeGroups } = x;
      return toArray(denormalizedBadgeGroups);
    }
    return undefined;
  }
);

export const getRequestedBadgeGroup = createSelector(
  [getBadgeGroups, getUrlNewOrEditId],
  (badgeGroups:BadgeGroupType[] | undefined, badgeGroupIdPathParam: string): Optional<BadgeGroupType> => {
    if (badgeGroups?.length && badgeGroupIdPathParam) {
      const badgeGroupId = parseInt(badgeGroupIdPathParam);
      return badgeGroups.find(bg => bg.id === badgeGroupId);
    }
    return undefined
  }
);

export const getBadgeCollectionTypes = createSelector(
  [selectBadgeCollectionTypes],
  (badgeCollectionTypes): Optional<BadgeCollectionTypeType[]> => {
    if (badgeCollectionTypes && badgeCollectionTypes.allIds && badgeCollectionTypes.allIds.length > 0) {
      const x = denormalize({ badgeCollectionTypes: badgeCollectionTypes.allIds }, entitySchema, { badgeCollectionTypes: badgeCollectionTypes.byId });
      const { badgeCollectionTypes: denormalizedBadgeCollectionTypes } = x;
      return toArray(denormalizedBadgeCollectionTypes);
    }
    return undefined;
  }
);

export const getRequestedStudyParticipantBadges = createSelector(
  [getBadges, getRequestedStudyParticipant],
  (badges, participant): Optional<BadgeType[]> => {
    if (badges?.length && participant) {
      return badges.filter((b:BadgeType) => b.participantId === participant.id);
    }
    return [];
  }
);


export default { getBadges, getBadgeGroups };
