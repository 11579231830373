import React, { ReactNode, useEffect, useState } from 'react';
import { Button, Space } from 'antd';
import {
  faPen,
  faSave,
  faTimes,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import AntdIcon from '../antdIcon/AntdIcon';
import './modalHeader.scss';
import Title from 'antd/lib/typography/Title';

interface ComponentProps {
  title?: ReactNode;
  editing: boolean;
  editClick: (e?: any) => void;
  showRemove?: boolean;
  removeClick?: (e?: any) => void;
  closeClick: (e?: any) => void;
  showSave?: boolean;
  saveClick: (e?: any) => void;
  onMouseOver: (e?: any) => void;
  onMouseOut: (e?: any) => void;
}

export const ModalHeader = (props: ComponentProps) => {
  const [showRemove, setShowRemove] = useState(props.showRemove ?? true);
  const [showSave, setShowSave] = useState(props.showSave ?? true);
  useEffect(() => {
    setShowRemove(props.showRemove ?? true);
  }, [props.showRemove]);
  useEffect(() => {
    setShowSave(props.showSave ?? true);
  }, [props.showSave]);
  return (
    <div
      className="header-action-container"
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
    >
      {typeof props.title === 'string' ? (
        <Title level={4}>{props.title}</Title>
      ) : (
        props.title
      )}
      <Space>
        {!props.editing ? (
          <Button
            shape="circle"
            type="link"
            className="header-action"
            icon={
              <div title="Edit">
                <AntdIcon
                  fontAwesomeIcon={faPen}
                  classes="header-action-icon"
                />
              </div>
            }
            onClick={props.editClick}
          />
        ) : (
          showSave && (
            <Button
              shape="circle"
              type="link"
              className="header-action"
              icon={
                <div title="Save">
                  <AntdIcon
                    fontAwesomeIcon={faSave}
                    classes="header-action-icon"
                  />
                </div>
              }
              onClick={props.saveClick}
            />
          )
        )}
        {showRemove && (
          <Button
            shape="circle"
            type="link"
            className="header-action"
            icon={
              <div title="Delete">
                <AntdIcon
                  fontAwesomeIcon={faTrashAlt}
                  classes="header-action-icon"
                />
              </div>
            }
            onClick={props.removeClick}
          />
        )}
        <Button
          shape="circle"
          type="link"
          className="header-action close"
          icon={
            <div title="Close">
              <AntdIcon
                fontAwesomeIcon={faTimes}
                classes="header-action-icon"
              />
            </div>
          }
          onClick={props.closeClick}
        />
      </Space>
    </div>
  );
};
