import { Button } from 'antd';
import Collapse from 'antd/lib/collapse';
import Table from 'antd/lib/table';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { _ } from 'lodash';
import { updateRecentActivityScroll, updateRecentActivitySelectedPostId, updateRecentActivitySelectedQnaPostId } from '../../redux/forum/forum.types';
import IApplicationState from '../../types/state.types';
import * as selectors from '../../redux/selectors';
import { renderDateWithTime } from '../util/Util';
import { CommentType, PostType } from '../../types/serverTypes/forumTypes';
import { QnaPostType } from '../../types/serverTypes/qnaTypes';

const initialState = {
};

type ComponentState = Readonly<typeof initialState>;

interface DispatchProps {
  updateRecentActivityScroll: typeof updateRecentActivityScroll
  updateRecentActivitySelectedPostId: typeof updateRecentActivitySelectedPostId,
  updateRecentActivitySelectedQnaPostId: typeof updateRecentActivitySelectedQnaPostId,
}

interface MappedStateProps {
  posts: Optional<PostType[]>,
  qnaPosts: Optional<QnaPostType[]>,
  studyId: number
}

interface StateProps extends MappedStateProps {
  comments: Optional<CommentType[]>,
}

interface ComponentProps extends StateProps, DispatchProps, RouteComponentProps {}

class CommentsTable extends Component<ComponentProps, ComponentState> {
  static defaultProps = { };

  readonly state: ComponentState = initialState;

  navigateToPost = (comment: CommentType) => {
    const { studyId } = this.props;
    if (comment.type === 'post') {
      const post = _.find(this.props.posts, (p:PostType) => p.id == comment.typeId);
      this.props.updateRecentActivitySelectedPostId(post!.id);
      this.props.history.push(`/study/${studyId}/forums/recentActivity`);
    }
    if (comment.type === 'qna') {
      this.props.updateRecentActivitySelectedQnaPostId(comment.typeId);
      this.props.history.push(`/study/${studyId}/qna/`);
    }
  }

  columns = [
    {
      title: 'ID',
      render: (val:number, comment:CommentType) => {
        return <Button type="link" onClick={() => this.navigateToPost(comment)}>{val}</Button>;
      },
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id
    },
    {
      title: 'Date',
      dataIndex: 'createDate',
      key: 'createDate',
      render: renderDateWithTime,
      sorter: (a: any, b: any) => {
        return moment(a.createDate || 0).unix() - moment(b.createDate || 0).unix();
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a: any, b: any) => `${a.type}`.localeCompare(b.type)
    },
    {
      title: 'Comment',
      dataIndex: 'body',
      key: 'body',
      sorter: (a: any, b: any) => `${a.body}`.localeCompare(b.body)
    },
    {
      title: 'Post/Qna Text',
      dataIndex: 'parentText',
      key: 'parentText',
      sorter: (a: any, b: any) => `${a.parentText}`.localeCompare(b.parentText)
    }

  ];

  rowClassName = (record: any, index: number): string => {
    return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
  };

  render() {
    const { comments } = this.props;
    const { Panel } = Collapse;

    return (
      <div id="search-results-container">
        <Table
          columns={this.columns}
          dataSource={comments}
          pagination={false}
          rowClassName={this.rowClassName}
          rowKey="id"
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, MappedStateProps>({
  posts: selectors.getPosts,
  qnaPosts: selectors.getQnaPosts,
  studyId: selectors.getRequestedStudyId
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    updateRecentActivityScroll: (scrollTop:number) => dispatch(updateRecentActivityScroll(scrollTop)),
    updateRecentActivitySelectedPostId: (selectedPostId:number) => dispatch(updateRecentActivitySelectedPostId(selectedPostId)),
    updateRecentActivitySelectedQnaPostId: (selectedPostId:number) => dispatch(updateRecentActivitySelectedQnaPostId(selectedPostId))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommentsTable));
