import * as _ from 'lodash';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import IApplicationState from '../../types/state.types';

const URL_PATTERN = '/study/:studyId/:page/:subpage?/:newOrEdit?/:id?';

export const selectRouter = (state: IApplicationState) => {
  return state.router;
};

export const selectRouterLocation = createSelector(
  [selectRouter],
  (router) => {
    return router?.location
  }
);

export const selectRouterLocationPathname = createSelector(
  [selectRouterLocation],
  (location) => {
    return location ? location.pathname : undefined;
  }
);

export const selectRouterLocationQueryParams = createSelector(
  [selectRouterLocation],
  (location) => {
    return location?.query;
  }
)
export const getRequestedStudyId = createSelector(
  [selectRouterLocationPathname],
  (pathname) => {
    if (!pathname) {
      return -1;
    }

    const match = matchPath<{studyId: string}>(pathname, {
      path: '/study/:studyId'
    });

    if (match && match.params && match.params.studyId) {

      if (!isNaN(parseInt(match.params.studyId, 10))) {
        return parseInt(match.params.studyId, 10);
      }
    }
    return -1;
  }
);

export const getRequestedTestkitTab = createSelector(
 [selectRouterLocationPathname],
  (pathname) => {
   if (!pathname) {
     return '';
   }

   const match = matchPath<{testkitSubpage: string}> (pathname, {
     path: '/study/:studyId/testkits/:testkitSubpage'
   });

   if (match && match.params && match.params.testkitSubpage) {
     return match.params.testkitSubpage;
   }

   return '';
  }
);

export const getRequestedBadgeTab = createSelector(
  [selectRouterLocationPathname],
  (pathname) => {
    if (!pathname) {
      return '';
    }

    const match = matchPath<{badgeSubpage: string}> (pathname, {
      path: '/study/:studyId/badges/:badgeSubpage'
    });

    if (match && match.params && match.params.badgeSubpage) {
      return match.params.badgeSubpage;
    }

    return '';
  }
);

export const getRequestedAppointmentTab = createSelector(
  [selectRouterLocationPathname],
  (pathname) => {
    if (!pathname) {
      return '';
    }

    const match = matchPath<{appointmentSubpage: string}> (pathname, {
      path: '/study/:studyId/appointments/:appointmentSubpage'
    });

    if (match && match.params && match.params.appointmentSubpage) {
      return match.params.appointmentSubpage;
    }

    return '';
  }
);

interface UrlParamsType {
  studyId: string;
  page: string;
  subpage: string;
  newOrEdit: string;
  id: string;
}

const getRequestedUrlParams = createSelector(
  [selectRouterLocationPathname],
  (pathname) => {
    if (!pathname) {
      return undefined;
    }

    const match = matchPath<UrlParamsType>(pathname, {
      path: URL_PATTERN,
    });

    return match?.params;
  }
);

export const getUrlRoutePage = createSelector(
  [getRequestedUrlParams],
  (params) => {
    return params?.page;
  }
);

export const getUrlRouteSubpage = createSelector(
  [getRequestedUrlParams],
  (params) => {
    return params?.subpage;
  }
);

export const getUrlRouteNewOrEdit = createSelector(
  [getRequestedUrlParams],
  (params) => {
    return params?.newOrEdit;
  }
);

export const getUrlNewOrEditId = createSelector(
  [getRequestedUrlParams],
  (params) => {
      return params?.id;
  }
);

export const getRequestedInboxFilterBy = createSelector(
  [selectRouterLocationQueryParams],
  (queryParams) => {
    return _.get(queryParams, 'filterBy');
  }
);

export const getRequestedInboxView = createSelector(
  [selectRouterLocationQueryParams],
  (queryParams) => {
    return _.get(queryParams, 'view');
  }
);

export const getRequestedForumPostId = createSelector(
  [selectRouterLocationPathname],
  (pathname) => {
    if (!pathname) {
      return undefined;
    }

    const match = matchPath<{postId: string}> (pathname, {
      path: '/study/:studyId/forums/:forumSubpage/:postId'
    });

    if (match && match.params && match.params.postId) {
      return parseInt(match.params.postId);
    }

    return undefined;
  }
);

export const getRequestedParticipantTab = createSelector(
  [selectRouterLocationPathname],
  (pathname) => {
    if (!pathname) {
      return '';
    }

    const match = matchPath<{participantSubpage: string}> (pathname, {
      path: '/study/:studyId/participants/:participantId/:participantSubpage'
    });

    if (match && match.params && match.params.participantSubpage) {
      return match.params.participantSubpage;
    }

    return '';
  }
);

export const getRequestedForumsTab = createSelector(
  [selectRouterLocationPathname],
  (pathname) => {
    if (!pathname) {
      return '';
    }

    const match = matchPath<{forumsSubpage: string}> (pathname, {
      path: '/study/:studyId/forums/:forumsSubpage'
    });

    if (match && match.params && match.params.forumsSubpage) {
      return match.params.forumsSubpage;
    }

    return '';
  }
);


export const getRequestedTestkitId = createSelector(
  [selectRouterLocationPathname],
  (pathname) => {
    if (!pathname) {
      return -1;
    }

    const match = matchPath<{testkitId: string}>(pathname, {
      path: '/testkit/:testkitId'
    });

    if (match && match.params && match.params.testkitId) {

      if (!isNaN(parseInt(match.params.testkitId, 10))) {
        return parseInt(match.params.testkitId, 10);
      }
    }
    return -1;
  }
);

export const getRequestedParticipantId = (state: IApplicationState) => {
  const { pathname } = state.router.location;
  const match = matchPath<{studyId: string, participantId: string}>(pathname, {
    path: '/study/:studyId/participants/:participantId'
  });

  if (match && match.params && match.params.participantId) {

    if (!isNaN(parseInt(match.params.participantId, 10))) {
      return parseInt(match.params.participantId, 10);
    }
  }
  return -1;
};

export const getRequestedResourceId = (state: IApplicationState) => {
  const { pathname } = state.router.location;
  const match = matchPath<{studyId: string, resourceId: string}>(pathname, {
    path: '/study/:studyId/resources/:resourceId'
  });

  if (match && match.params && match.params.resourceId) {

    if (!isNaN(parseInt(match.params.resourceId, 10))) {
      return parseInt(match.params.resourceId, 10);
    }
  }
  return -1;
};

export const getRequestedEditResourceId = (state: IApplicationState) => {
  const { pathname } = state.router.location;
  const match = matchPath<{studyId: string, resourceId: string}>(pathname, {
    path: '/study/:studyId/resources/edit/:resourceId'
  });

  if (match && match.params && match.params.resourceId) {

    if (!isNaN(parseInt(match.params.resourceId, 10))) {
      return parseInt(match.params.resourceId, 10);
    }
  }
  return -1;
};


export const getRequestedEditAppointmentId = (
  state: IApplicationState
): string => {
  const { pathname } = state.router.location;
  const match = matchPath<{ studyId: string; appointmentId: string }>(
    pathname,
    {
      path: "/study/:studyId/appointments/:appointmentId",
    }
  );

  if (match && match.params && match.params.appointmentId) {
    return match.params.appointmentId;
  }
  return "";
};


export const getRequestedEditGoalId = (
  state: IApplicationState
): string => {
  const { pathname } = state.router.location;
  const match = matchPath<{ studyId: string; goalId: string }>(
    pathname,
    {
      path: "/study/:studyId/goals/:goalId",
    }
  );

  if (match && match.params && match.params.goalId) {
    return match.params.goalId;
  }
  return "";
};
