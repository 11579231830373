import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Button, Card, Checkbox, Input, message, Space, Table } from 'antd';
import moment, { Moment } from 'moment';
import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './goal.scss';
import { renderDateCalendar } from '../../../components/util/Util';
import Username from '../../../components/username/Username';
import UnsavedLeavingGuard from '../../../components/util/UnsavedLeavingGuard';
import GoalForm from '../../../components/goalSetting/GoalForm';
import { GoalType } from '../../../types/serverTypes/goalTypes';
import useQuery, { getQueryParams } from '../../../components/util/useQuery';
import { useParams } from 'react-router';
import { useGoalQuery, useGoalTopicQuery } from './queries';
import { UrlParamsType } from '../../../types';

const { Column } = Table;
const { Search } = Input;

interface ComponentProps extends RouteComponentProps {}

interface GoalSettingListQueryType {
  term: string;
  pageNumber: number;
  pageSize: number;
  includeDeleted: string;
  includeUnpublished: string;
}

const GoalSettingList = ({ history }: ComponentProps) => {
  const query = useQuery<GoalSettingListQueryType>();
  const params = useParams<UrlParamsType>();
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [term, setTerm] = useState(query.term);
  const [includeDeleted, setIncludeDeleted] = useState<boolean | undefined>(
    !!query.includeDeleted ? query.includeDeleted === 'true' : undefined
  );
  const [includeUnpublished, setIncludeUnpublished] = useState<boolean>(
    !!query.includeUnpublished ? query.includeUnpublished === 'true' : true
  );
  const { data: goals } = useGoalQuery(
    term,
    undefined,
    undefined,
    includeDeleted,
    includeUnpublished
  );
  const { data: topics } = useGoalTopicQuery(params.studyId);

  useEffect(() => {
    history.replace({ search: getStateQueryString() });
  }, [term, includeDeleted, includeUnpublished]);

  const getStateQueryString = () => {
    return `?${queryString.stringify(
      {
        ...getQueryParams(history.location.search),
        term: term ?? undefined,
        includeDeleted,
        includeUnpublished,
      },
      {
        arrayFormat: 'bracket-separator',
        arrayFormatSeparator: ',',
        skipNull: true,
        skipEmptyString: true,
      }
    )}`;
  };

  const rowClassName = (record: any, index: number): string =>
    (index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color') + 'goal-table-row';

  const onAddGoalClicked = () => {
    history.push({
      pathname: `/study/${params.studyId}/goals/new`,
      search: getStateQueryString(),
    });
  };

  const closeForm = (id?: string) => {
    if (!!id && typeof id === 'string') {
      history.push({
        pathname: `/study/${params.studyId}/goals/edit/${id}`,
        search: getStateQueryString(),
      });
    } else {
      history.push({
        pathname: `/study/${params.studyId}/goals/`,
        search: getStateQueryString(),
      });
    }
  };

  const handleRowClick = (record: GoalType) => {
    if (record && record.id) {
      history.push({
        pathname: `/study/${params.studyId}/goals/edit/${record.id}`,
        search: getStateQueryString(),
      });
    }
  };

  const renderTopic = (topicId: number) => {
    if (!topics) {
      return 'UNKNOWN';
    }
    for (const topic of topics) {
      if (topic.id === topicId) {
        return topic.title;
      }
      if (topic.children && topic.children.length > 0) {
        for (const child of topic.children) {
          if (child.id === topicId) {
            return `${topic.title} : ${child.title}`;
          }
        }
      }
    }
  };

  const renderParticipant = (participantId: number) => {
    return <Username participantId={participantId} />;
  };

  const dateSorter = (
    a?: Date | string | Moment,
    b?: Date | string | Moment
  ) => {
    if (!a && !b) return 0;
    if (a === undefined) return -1;
    if (b === undefined) return 1;
    if (a === null) return -1;
    if (b === null) return 1;
    return moment(a).unix() - moment(b).unix();
  };

  const createDateSorter = (a: GoalType, b: GoalType) =>
    dateSorter(a.createDate, b.createDate);

  const publishDateSorter = (a: GoalType, b: GoalType) =>
    dateSorter(a.publishDate, b.publishDate);

  const lastUpdateDateSorter = (a: GoalType, b: GoalType) =>
    dateSorter(a.lastUpdateDate, b.lastUpdateDate);

  const deleteDateSorter = (a: GoalType, b: GoalType) =>
    dateSorter(a.deleteDate, b.deleteDate);

  const onTopicFilter = (value: any, record: GoalType) => {
    return record.topicId === value;
  };

  const titleSorter = (a: GoalType, b: GoalType) => {
    return a.title.localeCompare(b.title);
  };

  const copyDeepLink = (id: string) => {
    const deepLink = `hmp://goalsetting/${id}`;
    navigator.clipboard.writeText(deepLink).then(
      () => {
        message.success('Copied to clipboard!');
      },
      () => {
        message.error('Failed to copy to clipboard.');
      }
    );
  };

  const renderActions = (goal: GoalType) => {
    return (
      <div className="goal-action-container">
        <a
          title="Copy deep link to clipboard"
          className="goal-action"
          onClick={(e) => {
            e.stopPropagation();
            copyDeepLink(goal.id!);
          }}
        >
          <i className="far fa-link" />
        </a>
      </div>
    );
  };

  const topicFilters = topics?.map((t) => {
    return { text: t.title, value: t.id };
  });
  const showModal =
    params?.subpage === 'new' || (params?.subpage === 'edit' && !!params?.id);
  return (
    <Card
      title={
        <div className="goal-title">
          <h1>Goals</h1>
          <Button type="primary" onClick={onAddGoalClicked}>
            + Add Goal
          </Button>
        </div>
      }
    >
      <Space size="small">
        <Search
          placeholder="Search text"
          allowClear
          enterButton
          style={{ width: 400 }}
          onSearch={setTerm}
          defaultValue={term}
        />
        <Checkbox
          onChange={(e) => setIncludeDeleted(e.target.checked)}
          defaultChecked={includeDeleted}
        >
          Include deleted
        </Checkbox>
        <Checkbox
          onChange={(e) => setIncludeUnpublished(e.target.checked)}
          defaultChecked={includeUnpublished}
        >
          Include unpublished
        </Checkbox>
      </Space>
      <Table
        dataSource={goals}
        rowKey="id"
        rowClassName={rowClassName}
        pagination={false}
        style={{ overflow: 'auto' }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => handleRowClick(record), // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
      >
        <Column
          title="Title"
          dataIndex="title"
          key="title"
          sorter={titleSorter}
        />
        <Column
          title="Participant"
          dataIndex="participantId"
          key="participantId"
          render={renderParticipant}
        />
        <Column title="Description" dataIndex="description" key="description" />
        <Column
          title="Journal Prompt"
          dataIndex="journalPrompt"
          key="journalPrompt"
        />
        <Column
          title="Topic"
          dataIndex="topicId"
          key="topicId"
          render={renderTopic}
          filters={topicFilters}
          onFilter={onTopicFilter}
        />
        <Column
          title="Sequence"
          dataIndex="sequence"
          key="sequence"
          sorter={(a: GoalType, b) => a.sequence - b.sequence}
        />
        <Column
          title="Create Date"
          dataIndex="createDate"
          key="createDate"
          render={renderDateCalendar}
          sorter={createDateSorter}
        />
        <Column
          title="Last Update Date"
          dataIndex="lastUpdateDate"
          key="lastUpdateDate"
          render={renderDateCalendar}
          sorter={lastUpdateDateSorter}
        />
        <Column
          title="Delete Date"
          dataIndex="deleteDate"
          key="deleteDate"
          render={renderDateCalendar}
          sorter={deleteDateSorter}
        />
        <Column
          title="Publish Date"
          dataIndex="publishDate"
          key="publishDate"
          render={renderDateCalendar}
          sorter={publishDateSorter}
        />
        <Column title="Actions" render={renderActions} />
      </Table>
      {showModal && (
        <GoalForm
          key={`goal-form-${params.id}`}
          visible={showModal}
          closeHandler={closeForm}
          isUnsaved={isUnsaved}
          onUnsavedChange={setIsUnsaved}
        />
      )}
      <UnsavedLeavingGuard
        when={isUnsaved}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => isUnsaved}
      />
    </Card>
  );
};

export default withRouter(GoalSettingList);
