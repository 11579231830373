import React from 'react';
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import './activity.scss';

interface ComponentProps {
  text: string;
  placement?: PlacementString;
  warning?: boolean;
  trigger?: TriggerString;
}
declare type PlacementString =
  | 'right'
  | 'top'
  | 'left'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom'
  | undefined;
declare type TriggerString = 'hover' | 'focus' | 'click' | undefined;
const ActivityTooltip = (props: ComponentProps) => {
  const {
    text, placement, warning, trigger
  } = props;
  const content = <p className="activity-popover-content">{text}</p>;
  return (
    <Popover
      placement={placement}
      content={content}
      trigger={trigger || 'click'}
    >
      <InfoCircleOutlined style={warning ? { color: '#faad13' } : undefined} />
    </Popover>
  );
};

export default ActivityTooltip;
