import React from 'react';
import { union } from 'lodash';
import './topicBadgeFilter.scss';
import { TreeSelect } from 'antd';
import { TopicType } from '../../../types/serverTypes/forumTypes';

const { SHOW_PARENT } = TreeSelect;

const initialState = {
  selectedTopicIds: []
};

interface ComponentState {
  selectedTopicIds: number[];
}

interface ComponentProps {
  topics: TopicType[];
  selectedTopicsChanged: Function;
  selectedTopicIds: number[];
}

class TopicBadgeFilter extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      ...initialState,
      selectedTopicIds: props.selectedTopicIds
    };
  }

  onTopicsSelectedChange = (selectedIds: number[]) => {
    const { selectedTopicsChanged, topics } = this.props;
    const selectedTopics: TopicType[] = topics.filter((t) => selectedIds.includes(t.id));
    const selectedParentTopics: TopicType[] = selectedTopics.filter(
      (t) => t.children && t.children.length
    );
    const selectedChildTopicIds: number[] = selectedParentTopics
      .map((t) => t.children)
      .reduce(
        (children: TopicType[], current) => (current ? children.concat(current) : children),
        []
      )
      .map((t) => t.id);
    const selectedTopicIds: number[] = union(
      selectedParentTopics.map((t) => t.id),
      selectedTopics.map((t) => t.id),
      selectedChildTopicIds,
      selectedIds
    );
    this.setState(
      {
        selectedTopicIds
      },
      () => {
        selectedTopicsChanged(selectedTopicIds);
      }
    );
  };

  render() {
    const { topics } = this.props;
    const { selectedTopicIds } = this.state;
    if (!topics || topics.length === 0) {
      return <div>No Topics</div>;
    }
    const treeData = topics.map((topic) => {
      const articleCount = topic.children
        ? topic.children.reduce(
          (sum: number, curr: TopicType) => sum + curr.articleCount,
          0
        )
        : 0;
      return {
        title: `${topic.title} (${articleCount})`,
        value: topic.id,
        key: topic.id,
        children: topic.children
          ? topic.children.map((child) => {
            return {
              title: `${child.title} (${child.articleCount})`,
              value: child.id,
              key: child.id
            };
          })
          : []
      };
    });
    return (
      <div className="hmp-topic-badge-filter">
        <TreeSelect
          treeData={treeData}
          value={selectedTopicIds}
          onChange={this.onTopicsSelectedChange}
          treeCheckable
          showCheckedStrategy={SHOW_PARENT}
          placeholder="Please select a topic"
          allowClear
          showSearch={false}
          style={{
            width: '100%'
          }}
        />
      </div>
    );
  }
}

export default TopicBadgeFilter;
