import { ActionType, createStandardAction } from 'typesafe-actions';

export const toggleSideBar = createStandardAction('hmp/admin/SIDEBAR_TOGGLE')<undefined>();

export interface ISideBarState {
  collapsed: boolean
}

const sideBarActions = {
  toggleSideBar
};

export type SideBarActionTypes = ActionType<typeof sideBarActions>;
