import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { Card, Col, Row } from 'antd';
import { connect } from 'react-redux';
import IApplicationState from '../../types/state.types';
import * as selectors from '../../redux/selectors';
import { getAvatarsAsync } from '../../redux/avatar/avatar.types';
import { AvatarType } from '../../types/serverTypes/avatarTypes';

interface StateProps {
  avatars: Optional<AvatarType[]>
}

interface DispatchProps {
  getAvatars: typeof getAvatarsAsync.request
}

interface ComponentProps extends StateProps, DispatchProps {}

class Avatars extends Component<ComponentProps, {}> {

  async componentDidMount() {
    this.props.getAvatars();
  }

  render() {

    const { avatars } = this.props;
    const avatarCols: any[] = [];
    if (avatars) {
      avatars.forEach((avatar: AvatarType) => {
        avatarCols.push(
          <Col id={avatar.id.toString()} key={avatar.id} span={4}>
            <Card style={{ height: 120, textAlign: 'center' }}>
              <img src={avatar.avatar} style={{ width: 80, alignSelf: 'center' }} />
            </Card>
          </Col>
        );
      });
    }

    return (
      <div>
        <Row>{avatarCols}</Row>
      </div>
    );
  }
}

const mapStateToProps = (state: IApplicationState): StateProps => {
  const avatars = selectors.getAvatars(state);

  return {
    avatars
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getAvatars: () => dispatch(getAvatarsAsync.request())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Avatars);
