export const ROLE_STUDY_MANAGER = 'Study Manager';
export type ROLE_STUDY_MANAGER = typeof ROLE_STUDY_MANAGER;

export const ROLE_EXPERT = 'Expert';
export type ROLE_EXPERT = typeof ROLE_EXPERT;

export const ROLE_CARE_NAVIGATOR = 'Care Navigator';
export type ROLE_CARE_NAVIGATOR = typeof ROLE_CARE_NAVIGATOR;

export const ROLE_YAB = 'YAB';
export type ROLE_YAB = typeof ROLE_YAB;

export const ROLE_DATA_ANALYST = 'Data Analyst';
export type ROLE_DATA_ANALYST = typeof ROLE_DATA_ANALYST;

export const ROLE_COUNSELOR = 'Counselor';
export type ROLE_COUNSELOR = typeof ROLE_COUNSELOR;

export type ROLE =
  | ROLE_STUDY_MANAGER
  | ROLE_EXPERT
  | ROLE_CARE_NAVIGATOR
  | ROLE_YAB
  | ROLE_DATA_ANALYST
  | ROLE_COUNSELOR;

export enum ROLE_ID {
  ROLE_STUDY_MANAGER = 1,
  ROLE_EXPERT = 2,
  ROLE_CARE_NAVIGATOR = 3,
  ROLE_YAB = 4,
  ROLE_DATA_ANALYST = 5,
  ROLE_COUNSELOR = 6,
}
