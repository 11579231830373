import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { getQnaPostAsync } from '../qna/qna.types';
import { entitySchema } from '../schema';
import {
  getMyAdminNotificationSubscriptionsAsync,
  getParticipantNotificationsAsync,
  sendAdHocAdminQnaNotificationAsync,
} from './notification.types';
import { NormalizerResult } from '../../types';

const getMyAdminNotificationSubscriptions = () => {
  return axios({
    method: 'get',
    url: `/a/notification/myAdminNotificationSubscriptions`,
  });
};

function* getMyAdminNotificationSubscriptionsHandler(
  action: ReturnType<typeof getParticipantNotificationsAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      getMyAdminNotificationSubscriptions
    )) as AxiosResponse;
    const { entities } = normalize(
      response.data,
      entitySchema.notifications
    ) as NormalizerResult;
    const { notifications } = entities;

    yield put(getMyAdminNotificationSubscriptionsAsync.success(notifications));
  } catch (error) {
    yield put(getMyAdminNotificationSubscriptionsAsync.failure(error));
  }
}

function* getMyAdminNotificationSubscriptionsWatcher() {
  yield takeLatest(
    getType(getMyAdminNotificationSubscriptionsAsync.request),
    getMyAdminNotificationSubscriptionsHandler
  );
}

const getParticipantNotifications = (participantId: number) => {
  return axios({
    method: 'get',
    url: `/a/notification/participantNotifications?participantId=${participantId}`,
  });
};

function* getParticipantNotificationsHandler(
  action: ReturnType<typeof getParticipantNotificationsAsync.request>
): Generator {
  try {
    const participantId: number = action.payload;
    const response: AxiosResponse = (yield call(
      getParticipantNotifications,
      participantId
    )) as AxiosResponse;
    const participantNotifications = response.data ? response.data : [];
    const { entities } = normalize(
      participantNotifications,
      entitySchema.notifications
    ) as NormalizerResult;
    const { notifications } = entities;

    yield put(getParticipantNotificationsAsync.success(notifications));
  } catch (error) {
    yield put(getParticipantNotificationsAsync.failure(error));
  }
}

function* getParticipantNotificationsWatcher() {
  yield takeLatest(
    getType(getParticipantNotificationsAsync.request),
    getParticipantNotificationsHandler
  );
}

const sendAdHocAdminQnaNotification = (userId: number, qnaPostId: number) => {
  return axios({
    method: 'put',
    url: `/a/notification/${userId}/${qnaPostId}/sendUserAdHocQnaEmail`,
  });
};

function* sendAdHocAdminQnaNotificationHandler(
  action: ReturnType<typeof sendAdHocAdminQnaNotificationAsync.request>
): Generator {
  try {
    const { userId, qnaPostId } = action.payload;
    const response: AxiosResponse = (yield call(
      sendAdHocAdminQnaNotification,
      userId,
      qnaPostId
    )) as AxiosResponse;
    const { entities } = normalize(
      response.data,
      entitySchema.notifications
    ) as NormalizerResult;
    const { notifications } = entities;

    yield put(sendAdHocAdminQnaNotificationAsync.success(notifications));

    yield put(getQnaPostAsync.request(qnaPostId));
  } catch (error) {
    yield put(sendAdHocAdminQnaNotificationAsync.failure(error));
  }
}

function* sendAdHocAdminQnaNotificationWatcher() {
  yield takeLatest(
    getType(sendAdHocAdminQnaNotificationAsync.request),
    sendAdHocAdminQnaNotificationHandler
  );
}

export default function* notificationsSaga() {
  yield all([
    fork(getParticipantNotificationsWatcher),
    fork(getMyAdminNotificationSubscriptionsWatcher),
    fork(sendAdHocAdminQnaNotificationWatcher),
  ]);
}
