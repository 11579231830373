import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import moment from 'moment';
import * as selectors from '../../redux/selectors';
import IApplicationState from '../../types/state.types';
import './newQnaPostForm.scss';
import { IApiRequestStatus } from '../../types/api.types';
import { message, Button } from 'antd';
import Username from '../username/Username';
import {
  resolveFlagsAsync, hideContentAsync, unhideContentAsync, HideContentPayloadType
} from '../../redux/flags/flags.types';
import { CommentType, FlagType, PostType } from '../../types/serverTypes/forumTypes';

interface StateProps {
  flags: Optional<FlagType[]>;
  posts: Optional<PostType[]>;
  comments: Optional<CommentType[]>;
}

interface DispatchProps {
  resolveFlags: typeof resolveFlagsAsync.request,
  hideContent: typeof hideContentAsync.request,
  unhideContent: typeof unhideContentAsync.request,
}

interface ComponentProps extends StateProps, DispatchProps {
  participantId: number;
  id: number;
  type: string;
  isHidden: boolean;
}

class FlagResolveMenu extends Component<ComponentProps, {}> {
  readonly state = {
  };

  componentDidMount() {
  }

  getContentFlags = () => {
    const { flags, id, type } = this.props;
    return _.filter(flags, { typeId: id, type });
  }

  resolveFlags = (resolution: string) => {
    const { resolveFlags } = this.props;
    const flags = this.getContentFlags();
    if (flags && flags.length) {
      const ids = _.map(flags, 'id');
      resolveFlags({ ids, resolution });
    }
  }

  hideContent = () => {
    const { id, type, hideContent } = this.props;
    hideContent({ type, typeId: id });
    this.resolveFlags('confirmed');
    message.success('Flag resolved: content hidden.');
  }

  unhideContent = () => {
    const { id, type, unhideContent } = this.props;
    unhideContent({ type, typeId: id });
    message.success('Content un-hidden.');
  }

  render() {
    const {
      flags,
      posts,
      comments,
      participantId,
      id,
      type,
      isHidden
    } = this.props;
    const theirPosts = _.filter(posts, (p:PostType) => p.createdByParticipantId === participantId);
    const theirFlaggedPosts = _.filter(theirPosts, (p:PostType) => _.find(flags, f => f.flaggedParticipantId === p.createdByParticipantId));
    const theirHiddenPosts = _.filter(theirPosts, (p:PostType) => p.isHidden);
    const theirComments = _.filter(comments, (c:CommentType) => c.participantId === participantId);
    const theirFlaggedComments = _.filter(theirComments, (c:CommentType) => _.find(flags, f => f.flaggedParticipantId === c.participantId));
    const theirHiddenComments = _.filter(theirComments, (c:CommentType) => c.isHidden);
    const flagsForThisContent = _.filter(flags, (f:FlagType) => f.type === type && f.typeId === id);
    return (
      <div id="flagResolvePopup">
        <div id="buttonRow">
          {isHidden
            ? <Button onClick={() => this.unhideContent()}> Unhide Content </Button>
            : <Button onClick={() => this.hideContent()}> Hide Content </Button>}
          {flagsForThisContent.length
            ? <Button onClick={() => this.resolveFlags('ignored')}>Ignore Flags</Button>
            : undefined}
        </div>
        <div id="titleRow">
          <Username participantId={participantId} style={{ color: '#8185B3' }} />
        </div>
        <div id="tableRow">
          <table id="table">
            <thead>
              <tr>
                <th />
                <th />
                <th className="center">flagged</th>
                <th className="center">hidden</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Posts</td>
                <td>{theirPosts.length}</td>
                <td className="center">{theirFlaggedPosts.length}</td>
                <td className="center">{theirHiddenPosts.length}</td>
              </tr>
              <tr>
                <td>Comments</td>
                <td>{theirComments.length}</td>
                <td className="center">{theirFlaggedComments.length}</td>
                <td className="center">{theirHiddenComments.length}</td>
              </tr>
              <tr>
                <td className="bottomRow" />
                <td className="bottomRow">{theirPosts.length + theirComments.length}</td>
                <td className="center bottomRow">{theirFlaggedPosts.length + theirFlaggedComments.length}</td>
                <td className="center bottomRow">{theirHiddenPosts.length + theirHiddenComments.length}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    flags: selectors.getFlags(state),
    posts: selectors.getPosts(state),
    comments: selectors.getComments(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    resolveFlags: (flagResolution: {ids: number[], resolution: string}) => dispatch(resolveFlagsAsync.request(flagResolution)),
    hideContent: (payload: HideContentPayloadType) => dispatch(hideContentAsync.request(payload)),
    unhideContent: (payload: HideContentPayloadType) => dispatch(unhideContentAsync.request(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlagResolveMenu);
