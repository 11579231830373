import { PlusCircleOutlined } from '@ant-design/icons';
import {
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getStudyArms } from '../../service/studyService';
import { StudyArmType } from '../../types/serverTypes/studyTypes';
import {
  getAvatarParts,
  getScheduleAvatarParts,
  removePartSchedule,
} from '../../service/avatar/avatarService';
import {
  AvatarPartPointType,
  AvatarPartType,
} from '../../types/serverTypes/avatarTypes';
import './AvatarCustom.scss';

const { Text } = Typography;

interface AvatarScheduleEditProp {
  selectedBlock: number;
  onSelectedBlock: (block) => void;
  selectedStudyArm?: number;
}
const AvatarScheduleEdit = (props: AvatarScheduleEditProp) => {
  const [scheduleBlocks, setScheduleBlocks] = useState<number[]>([]);
  const [newBlockRange, setNewBlockRange] = useState<number[]>([1, -1]);
  const [newBlockValue, setNewBlockValue] = useState<number>();
  const [showNewBlockPrompt, setShowNewBlockPrompt] = useState<boolean>(false);
  const parts = useQuery<AvatarPartType[]>('avatarParts', getAvatarParts);

  const scheduleAvatarParts = useQuery<AvatarPartPointType[]>(
    ['scheduleAvatarParts', props.selectedStudyArm],
    getScheduleAvatarParts,
    {
      enabled: !!props.selectedStudyArm,
      onSuccess: (data) => {
        let tmpBlocks: number[] = [];
        data.map((d) => {
          if (d.pointsRequired === 0 || !!d.pointsRequired) {
            if (tmpBlocks.findIndex((t) => t === d.pointsRequired) === -1) {
              tmpBlocks.push(d.pointsRequired);
            }
          }
        });
        setScheduleBlocks(tmpBlocks);
      },
    }
  );

  const onBlockSelect = (block) => {
    props.onSelectedBlock(block);
  };
  const onAddNewBlock = (fromBlock, toBlock) => {
    setShowNewBlockPrompt(true);
    setNewBlockRange([fromBlock, toBlock]);
  };
  const onNewBlockChange = (value) => {
    setNewBlockValue(value);
  };
  const onCancelNewBlock = () => {
    setNewBlockValue(undefined);
    setShowNewBlockPrompt(false);
  };
  const onSaveNewBlock = () => {
    if (newBlockValue === 0 || !!newBlockValue) {
      if (scheduleBlocks.length === 0) {
        setScheduleBlocks([newBlockValue]);
        setShowNewBlockPrompt(false);
        return;
      }

      if (
        newBlockValue < newBlockRange[0] ||
        newBlockValue > newBlockRange[1]
      ) {
        message.error(
          `Please enter a numerical point between ${newBlockRange[0]} and ${newBlockRange[1]}`
        );
        return;
      }

      const tmpBlocks: number[] = [...scheduleBlocks];
      let splitIndex = tmpBlocks.findIndex((block) => block > newBlockValue);
      splitIndex =
        splitIndex === 0
          ? 0
          : splitIndex === -1
          ? scheduleBlocks.length
          : splitIndex;

      tmpBlocks.splice(splitIndex, 0, newBlockValue);

      setScheduleBlocks(tmpBlocks);
      setShowNewBlockPrompt(false);
    }
  };

  const queryClient = useQueryClient();
  const removeScheduleMutation = useMutation(removePartSchedule, {
    onError: (error, variables, context) => {
      message.error('Error in removing award schedule.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('scheduleAvatarParts');
      message.success('Avatar award schedule removed successfully.');
    },
  });
  const onRemovePartSchedule = (scheduleId) => {
    removeScheduleMutation.mutate(scheduleId);
  };
  return (
    <>
      <div>
        <Text>Base set</Text>
      </div>
      <div style={{ marginBottom: 10 }}>
        {parts.data &&
          parts.data
            .filter((part) => part.isBaseSet)
            .map((part) => {
              return (
                <div key={part.id} className="avatar-svg-container-no-action">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: part.svg,
                    }}
                  ></div>
                </div>
              );
            })}
      </div>
      <div>
        {(scheduleBlocks.length === 0 || scheduleBlocks[0] > 0) && (
          <div
            className="schedule-new-container"
            onClick={() =>
              onAddNewBlock(
                scheduleBlocks.length > 0
                  ? Math.max(scheduleBlocks[0] - 5, 1)
                  : 1,
                scheduleBlocks.length > 0 ? scheduleBlocks[0] - 1 : 5
              )
            }
          >
            <Tooltip title="Click to add new point">
              <PlusCircleOutlined />
            </Tooltip>
          </div>
        )}
        <div>
          {scheduleBlocks.map((block, index) => {
            const blockClassName =
              block === props.selectedBlock
                ? 'schedule-block-selected'
                : 'schedule-block';
            return (
              <div key={block} className="schedule-container">
                {scheduleBlocks.length - 1 > index && (
                  <div
                    className="schedule-new-container"
                    onClick={() =>
                      onAddNewBlock(block + 1, scheduleBlocks[index + 1] - 1)
                    }
                  >
                    <Tooltip title="Click to add new point">
                      <PlusCircleOutlined />
                    </Tooltip>
                  </div>
                )}
                {scheduleBlocks.length - 1 <= index && (
                  <div className="schedule-new-container"></div>
                )}
                <div
                  className={blockClassName}
                  onClick={() => onBlockSelect(block)}
                >
                  <div className="schedule-point-label">
                    {block} <Text>point</Text>
                  </div>
                  <div>
                    {parts.data &&
                      parts.data
                        .filter(
                          (part) =>
                            !part.isBaseSet &&
                            scheduleAvatarParts.data &&
                            scheduleAvatarParts.data?.findIndex(
                              (schedulePart) =>
                                schedulePart.avatarId === part.id &&
                                schedulePart.pointsRequired === block
                            ) > -1
                        )
                        .map((part) => {
                          const partSchedule = scheduleAvatarParts.data?.find(
                            (sp) => sp.avatarId === part.id
                          );
                          return (
                            <div key={part.id} className="avatar-svg-container">
                              <div
                                onClick={() =>
                                  onRemovePartSchedule(partSchedule?.scheduleId)
                                }
                                dangerouslySetInnerHTML={{
                                  __html: part.svg,
                                }}
                              ></div>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {scheduleBlocks.length > 0 && (
          <div
            className="schedule-new-container"
            onClick={() =>
              onAddNewBlock(
                scheduleBlocks[scheduleBlocks.length - 1] + 1,
                Math.min(scheduleBlocks[scheduleBlocks.length - 1] + 1000, 1000)
              )
            }
          >
            <Tooltip title="Click to add new point">
              <PlusCircleOutlined />
            </Tooltip>
          </div>
        )}
      </div>
      <Modal
        title="Add New Point Criteria"
        visible={showNewBlockPrompt}
        onOk={onSaveNewBlock}
        onCancel={onCancelNewBlock}
      >
        <p>
          Please enter the new point between {newBlockRange[0]} and{' '}
          {newBlockRange[1]}:
        </p>
        <InputNumber
          onChange={(e) => onNewBlockChange(e)}
          min={newBlockRange[0]}
          max={newBlockRange[1]}
          value={newBlockValue}
        />
      </Modal>
    </>
  );
};

export default AvatarScheduleEdit;
