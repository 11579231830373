import { createSelector } from "reselect";
import { matchPath } from "react-router-dom";
import toArray from "lodash/toArray";
import { denormalize } from "normalizr";
import IApplicationState from "../../types/state.types";
import entitySchema from "../schema";
import { selectRouterLocationPathname } from "../router/router.selectors";
import {GoalType} from "../../types/serverTypes/goalTypes";

export const selectGoals = (state: IApplicationState) => {
  return state.entities.goals;
};

export const getGoals = createSelector(
  [selectGoals],
  (goals): Optional<GoalType[]> => {
    if (goals && goals.allIds && goals.allIds.length > 0) {
      const { goals: denormalizedGoals } = denormalize(
        { goals: goals.allIds },
        entitySchema,
        { goals: goals.byId }
      );
      return toArray(denormalizedGoals);
    }
    return undefined;
  }
);

export const getRequestedGoal = createSelector(
  [selectGoals, selectRouterLocationPathname],
  (goals, pathname): Optional<GoalType> => {
    if (pathname) {
      const match = matchPath<{
        studyId: string;
        subpage: 'new' | 'edit';
        id: string;
      }>(pathname, {
        path: '/study/:studyId/goals/:subpage/:id',
      });

      const goalId = match?.params?.id;
      if (
        goals &&
        goals.allIds &&
        goals.allIds.length > 0 &&
        goalId &&
        goalId.length > 0
      ) {
        return goals.byId[goalId];
      }
    }
    return undefined;
  }
);

export default { getGoals, getRequestedGoal };
