import React, { Component } from 'react';
import * as _ from 'lodash';
import generateUUID from 'uuid';
import {
  Button, Form, Input, InputNumber
} from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import '../activity.scss';
import CategoryOption from './CategoryOption';
import QuizOption from './QuizOption';
import OptionFeedbackItem from '../feedback/OptionFeedbackItem';
import { Type } from '../ActivityFormContainer';
import { OptionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  typeId: number;
  disabled: boolean;
  option: OptionType;
  updateOption: (q: OptionType) => void;
  moveUp: () => void;
  moveDown: () => void;
}

const defaultFeedback = {
  uuid: '',
  title: '',
  text: '',
  min: undefined,
  max: undefined,
  isOverallFeedback: false
};
const itemStyle = {
  width: '100%',
  marginBottom: '10px',
  marginTop: '10px'
};

class OptionItem extends Component<ComponentProps, {}> {
  onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { option, updateOption } = this.props;
    option.text = e.target.value;
    updateOption(option);
  };

  onPointsChange = (value: string | number | undefined) => {
    const { option, updateOption } = this.props;
    option.points = Number(value);
    updateOption(option);
  };

  onCorrectChange = (e: CheckboxChangeEvent) => {
    const { option, updateOption } = this.props;
    option.isCorrect = e.target.checked;
    updateOption(option);
  };

  setPoints = (value: number) => {
    const { option, updateOption } = this.props;
    option.points = value;
    updateOption(option);
  };

  onMoveUpClick = (e: React.MouseEvent<HTMLElement>) => {
    const { moveUp } = this.props;
    e.preventDefault();
    moveUp();
  };

  onMoveDownClick = (e: React.MouseEvent<HTMLElement>) => {
    const { moveDown } = this.props;
    e.preventDefault();
    moveDown();
  };

  updateFeedback = (f: FeedbackType) => {
    const { option, updateOption } = this.props;
    option.feedback = f;
    updateOption(option);
  };

  addFeedback = () => {
    const { option, updateOption } = this.props;
    option.feedback = _.cloneDeep(defaultFeedback);
    option.feedback.uuid = generateUUID();
    updateOption(option);
  };

  removeFeedback = () => {
    const { option, updateOption } = this.props;
    option.feedback = undefined;
    updateOption(option);
  };

  render() {
    const { option, typeId, disabled } = this.props;
    const {
      text, points, uuid, feedback, id
    } = option;
    let item;
    switch (typeId) {
      case Type.quiz:
        item = (
          <QuizOption
            disabled={disabled}
            option={option}
            onTextChange={this.onTextChange}
            onPointsChange={this.onPointsChange}
            onCorrectChange={this.onCorrectChange}
            updateFeedback={this.updateFeedback}
            addFeedback={this.addFeedback}
            removeFeedback={this.removeFeedback}
          />
        );
        break;
      case Type.category:
        item = (
          <CategoryOption
            disabled={disabled}
            option={option}
            onTextChange={this.onTextChange}
            setPoints={this.setPoints}
          />
        );
        break;
      case Type.ranking:
        item = (
          <div className="option-container">
            <Input
              spellCheck
              disabled={disabled}
              placeholder="Option text"
              value={text}
              onChange={this.onTextChange}
              style={{ width: '100%' }}
            />
            <div className="option-props">
              <span className="option-prop">
                Point Value
                {' '}
                <InputNumber
                  disabled={disabled}
                  value={points}
                  min={0}
                  onChange={this.onPointsChange}
                />
              </span>
              <Button
                disabled={disabled}
                onClick={this.onMoveUpClick}
              >
                <UpOutlined />
              </Button>
              <Button
                disabled={disabled}
                onClick={this.onMoveDownClick}
              >
                <DownOutlined />
              </Button>
            </div>
          </div>
        );
        break;
      case Type.category_no_answer:
      case Type.screener:
        item = (
          <div className="option-container">
            <Input
              spellCheck
              disabled={disabled}
              placeholder="Option text"
              value={text}
              onChange={this.onTextChange}
              style={{ width: '100%' }}
            />
            <div className="option-props">
              <Item key={`input-number-option-${id}`}>
                <span className="option-prop">
                  Point Value
                  {' '}
                  <InputNumber
                    disabled={disabled}
                    value={points}
                    min={0}
                    onChange={this.onPointsChange}
                  />
                </span>
              </Item>
            </div>
          </div>
        );
        break;
      case Type.goals:
        item = (
          <div className="option-container">
            <Input
              spellCheck
              disabled={disabled}
              placeholder="Option text"
              value={text}
              onChange={this.onTextChange}
              style={{ width: '100%' }}
            />
            <div className="option-props">
              <OptionFeedbackItem
                type={Type.goals}
                disabled={disabled}
                feedback={feedback}
                option={option}
                updateFeedback={this.updateFeedback}
                addFeedback={this.addFeedback}
                removeFeedback={this.removeFeedback}
              />
            </div>
          </div>
        );
        break;
      default:
        item = (
          <Input
            spellCheck
            disabled={disabled}
            placeholder="Option text"
            value={text}
            onChange={this.onTextChange}
            style={{ width: '80%' }}
          />
        );
        break;
    }
    return (
      <Item
        required={false}
        key={`option-${id || uuid}`}
        name={`options-${id || uuid}`}
        style={itemStyle}
        initialValue={text}
      >
        {item}
      </Item>
    );
  }
}

export default OptionItem;
