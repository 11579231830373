import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import FullCalendar, {
  DateSelectArg,
  EventChangeArg,
  EventClickArg,
  EventInput,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IApplicationState from 'types/state.types';
import * as selectors from '../../redux/selectors';
import {
  createAvailabilityAsync,
  getAvailabilitiesAsync,
  GetAvailabilitiesQueryParameters,
} from '../../redux/availabilty/availability.types';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { AvailabilityType } from '../../types/serverTypes/appointmentTypes';
import AvailabilityModal from './AvailabilityModal';
import { message } from 'antd';
import { updateAvailability } from '../../service/auth/availabilityService';

const initialState: ComponentState = {
  selectedAvailability: undefined,
  availabilityEvents: [],
};

interface StateProps {
  requestedAppointmentTab: Optional<string>;
  studyId: Optional<number>;
  selectedAvailabilityId: Optional<string>;
  availabilities: Optional<AvailabilityType[]>;
}

interface DispatchProps {
  getAvailabilities: typeof getAvailabilitiesAsync.request;
  createAvailability: typeof createAvailabilityAsync.request;
}

interface ComponentProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {}

interface ComponentState {
  selectedAvailability?: AvailabilityType;
  availabilityEvents: EventInput[];
  removeEvent?: Function;
}

const AppointmentCalendar = (props: ComponentProps) => {
  const [state, setState] = useState<ComponentState>(_.clone(initialState));
  let calendar = React.createRef<FullCalendar>();

  useEffect(() => {
    refreshCalendar();
  }, []);

  const refreshCalendar = () => {
    if (calendar?.current) {
      const startMoment = moment(
        calendar?.current?.getApi().getCurrentData().dateProfile.currentRange
          .start
      );
      const getAvailabilitiesQueryParameters: GetAvailabilitiesQueryParameters =
        {
          startDate: startMoment.toDate(),
        };

      props.getAvailabilities(getAvailabilitiesQueryParameters);
      if (props.availabilities?.length) {
        setState({
          ...state,
          availabilityEvents: props.availabilities.map(
            (a: AvailabilityType): EventInput => {
              return {
                id: a.id?.toString(),
                userId: a.userId,
                title: a.username,
                start: a.startDate,
                end: a.endDate,
              };
            }
          ),
        });
      }
    }
  };

  useEffect(() => {
    const { requestedAppointmentTab, selectedAvailabilityId, availabilities } =
      props;
    if (
      requestedAppointmentTab === 'availability' &&
      selectedAvailabilityId &&
      availabilities
    ) {
      const idInt = parseInt(selectedAvailabilityId);
      const selectedAvailability = _.find(
        availabilities,
        (a: AvailabilityType) => a.id === idInt
      );
      setState({ ...state, selectedAvailability });
    }
  }, [props.selectedAvailabilityId, props.history, props.availabilities]);

  useEffect(() => {
    const { availabilities } = props;

    if (availabilities?.length) {
      setState({
        ...state,
        availabilityEvents: availabilities.map((a: AvailabilityType) => {
          return {
            id: a.id?.toString(),
            userId: a.userId,
            title: a.username,
            start: a.startDate,
            end: a.endDate,
          };
        }),
      });
    }
  }, [props.availabilities]);

  const selectAvailability = (id: string) => {
    const { studyId, history } = props;
    history.push(`/study/${studyId}/appointments/availability/edit/${id}`);
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    const selectedAvailability: AvailabilityType = {
      startDate: selectInfo.start,
      endDate: selectInfo.end,
    };
    setState({
      ...state,
      selectedAvailability,
      removeEvent: () => selectInfo.view.calendar.unselect(),
    });

    const { history, studyId } = props;
    history.push(`/study/${studyId}/appointments/availability/new`);
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    setState({
      ...state,
      removeEvent: () => clickInfo.event.remove(),
    });
    selectAvailability(clickInfo.event.id);
  };

  const saveAvailability = (availabilityToSave: AvailabilityType) => {
    if (state.removeEvent) {
      state.removeEvent();
    }
    props.createAvailability(availabilityToSave);
  };

  const handleEventUpdate = async (changeInfo: EventChangeArg) => {
    try {
      const apiParameter = {
        startDate: changeInfo.event.start,
        endDate: changeInfo.event.end,
        userId: changeInfo.event.extendedProps['userId'],
        availabilityId: changeInfo.event.id,
      };
      await updateAvailability(apiParameter);
      message.success('Update successful.');
    } catch (err) {
      if ((err as any).response) {
        message.error((err as any).response.data);
      } else {
        message.error('Error occurred while updating: ' + err);
      }
      changeInfo.revert();
    }
  };

  const newAvailability = () => {
    const { history, studyId } = props;
    history.push(`/study/${studyId}/appointments/availability/new`);
  };

  const { selectedAvailability, availabilityEvents, removeEvent } = state;
  return (
    <>
      {selectedAvailability && (
        <AvailabilityModal
          selectedAvailability={selectedAvailability}
          saveAvailability={saveAvailability}
          removeEvent={removeEvent}
        />
      )}
      <FullCalendar
        ref={calendar}
        unselectAuto={false}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        customButtons={{
          new: {
            text: 'new',
            click: newAvailability,
          },
        }}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'new',
        }}
        initialView="timeGridWeek"
        events={availabilityEvents}
        editable={true}
        allDaySlot={false}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        select={handleDateSelect}
        eventClick={handleEventClick}
        eventDrop={handleEventUpdate}
        eventResize={handleEventUpdate}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    selectedAvailabilityId: selectors.getUrlNewOrEditId,
    requestedAppointmentTab: selectors.getUrlRouteSubpage,
    studyId: selectors.getRequestedStudyId,
    availabilities: selectors.getAvailabilities,
  }
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getAvailabilities: (params: GetAvailabilitiesQueryParameters | undefined) =>
      dispatch(getAvailabilitiesAsync.request(params)),
    createAvailability: (a: AvailabilityType) =>
      dispatch(createAvailabilityAsync.request(a)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppointmentCalendar));
