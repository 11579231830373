import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import { selectParticipants } from '../../redux/participants/participants.selectors';
import IApplicationState from '../../types/state.types';
import { entitySchema } from '../schema';
import {TestkitType} from "../../types/serverTypes/testkitTypes";

export const selectTestkits = (state: IApplicationState) => {
  return state.entities.testkits;
};

export const getTestkits = createSelector(
  [selectTestkits, selectParticipants],
  (testkits, participants): Optional<TestkitType[]> => {
    if (testkits.allIds && testkits.allIds.length > 0) {
      const { testkits: denormalizedTestkits } = denormalize({ testkits: testkits.allIds }, entitySchema, { testkits: testkits.byId, participants: participants.byId });
      return toArray(denormalizedTestkits);
    }
    return undefined;
  }
);

export const getRequestedTestkits = createSelector(
  [getTestkits],
  (testkits): Optional<TestkitType[]> => {
    return testkits ? testkits.filter(t =>  t.sentDate === null) : [];
  }
);

export const getAwaitingResultTestkits = createSelector(
  [getTestkits],
  (testkits): Optional<TestkitType[]> => {
    return testkits ? testkits.filter(t =>  t.sentDate !== null && t.reportDate === null && t.completedDate === null) : [];
  }
);

export const getNeedsReviewTestkits = createSelector(
  [getTestkits],
  (testkits): Optional<TestkitType[]> => {
    return testkits ? testkits.filter(t =>  t.sentDate !== null && t.reportDate !== null && t.completedDate === null) : [];
  }
);

export const getCompletedTestkits = createSelector(
  [getTestkits],
  (testkits): Optional<TestkitType[]> => {
    return testkits ? testkits.filter(t => t.completedDate !== null) : [];
  }
);