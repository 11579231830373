import logoImage from '/assets/images/logo.png';
import React from 'react';

const MobilePageNotFound = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ margin: '50px 0' }}>
        <img id="logo" src={logoImage} alt="Logo" />
      </div>
      <div style={{ textAlign: 'center', fontSize: '1.5em' }}>
        Sorry, the link you clicked will only work on your phone.
        <br />
        Please use your phone and try again.
      </div>
    </div>
  );
};

export default MobilePageNotFound;
