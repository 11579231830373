import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import { getType } from 'typesafe-actions';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import {
  clearPosts,
  deletePostAsync,
  getFlaggedPostsAsync,
  getParticipantPostsAsync,
  getPostsAsync,
  getUncategorizedPostsAsync,
  PostsActionTypes,
  savePostAsync,
  updatePosts
} from './posts.types';
import {PostType} from "../../types/serverTypes/forumTypes";

const updatePostsReducer = produce((draft, posts) => {
  if (!posts) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(posts), Number));
  const ids = Object.keys(posts);
  for (const id of ids) {
    draft.byId[id] = posts[id];
  }
});

export const postsReducer = (state: NormalizedState<PostType> = initialNormalizedState, action: PostsActionTypes) => {
  switch (action.type) {
    case getType(getPostsAsync.success):
    case getType(savePostAsync.success):
    case getType(getFlaggedPostsAsync.success):
    case getType(getParticipantPostsAsync.success):
    case getType(deletePostAsync.success):
    case getType(getUncategorizedPostsAsync.success):
    case getType(updatePosts):
      return updatePostsReducer(state, action.payload);

    case getType(clearPosts): return {byId:{}, allIds: []}

    default:
      return state;
  }
};

export default postsReducer;
