import {all, fork} from 'redux-saga/effects';
import activitiesSaga from './activities/activities.sagas';
import analyticsSaga from './analitycs/analytics.sagas';
import announcementsSaga from './announcements/announcements.sagas';
import appointmentsSaga from './appointments/appointments.sagas';
import appSaga from './app/app.sagas';
import articleSaga from './article/article.sagas';
import availabilitySaga from './availabilty/availability.sagas';
import avatarSaga from './avatar/avatar.sagas';
import badgeSaga from './badges/badges.sagas';
import commentsSaga from './comments/comments.sagas';
import dashbordSaga from './dashboard/dashboard.sagas';
import favoriteSaga from './favorites/favorites.sagas';
import flagSaga from './flags/flags.sagas';
import formSaga from './form/form.sagas';
import forumSaga from './forum/forum.sagas';
import goalsSaga from './goals/goals.sagas';
import messagesSaga from './messages/messages.sagas';
import notesSaga from './notes/notes.sagas';
import notificationsSaga from './notification/notifiication.sagas';
import participantsSaga from './participants/participants.sagas';
import paymentSaga from './payment/payment.sagas';
import postsSaga from './posts/posts.sagas';
import qnaSaga from './qna/qna.sagas';
import stringLookupsSaga from './stringLookups/stringLookups.sagas';
import studySaga from './study/study.sagas';
import testkitSaga from './testkit/testkit.sagas';
import thumbsupSaga from './thumbsups/thumbsups.sagas';
import topicSaga from './topics/topic.sagas';
import userSaga from './user/user.sagas';
import userSurveySaga from './userSurvey/userSurvey.sagas';

export default function* rootSaga() {
  yield all([
    fork(activitiesSaga),
    fork(analyticsSaga),
    fork(announcementsSaga),
    fork(appointmentsSaga),
    fork(appSaga),
    fork(articleSaga),
    fork(availabilitySaga),
    fork(avatarSaga),
    fork(badgeSaga),
    fork(commentsSaga),
    fork(dashbordSaga),
    fork(favoriteSaga),
    fork(flagSaga),
    fork(formSaga),
    fork(forumSaga),
    fork(goalsSaga),
    fork(messagesSaga),
    fork(notesSaga),
    fork(notificationsSaga),
    fork(participantsSaga),
    fork(paymentSaga),
    fork(postsSaga),
    fork(qnaSaga),
    fork(stringLookupsSaga),
    fork(studySaga),
    fork(testkitSaga),
    fork(thumbsupSaga),
    fork(topicSaga),
    fork(userSaga),
    fork(userSurveySaga),
  ]);
}
