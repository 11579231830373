import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import IApplicationState from '../../types/state.types';
import entitySchema from '../schema';
import { getCommentFlags } from '../flags/flags.selectors';
import { getQnaThumbsups } from '../thumbsups/thumbsups.selectors';
import { getQnaComments } from '../comments/comments.selectors';
import { getQnaFavorites } from '../favorites/favorites.selectors';
import { getAdmin } from '../user/user.selectors';
import { IQnaUIState } from './qna.types';
import {QnaPostType} from "../../types/serverTypes/qnaTypes";

export const selectQnaPosts = (state: IApplicationState) => {
  return state.entities.qnaPosts;
};

export const getQnaPosts = createSelector(
  [selectQnaPosts, getCommentFlags, getQnaThumbsups, getQnaComments, getQnaFavorites, getAdmin],
  (posts, flags, thumbsups, comments, favorites, admins): Optional<QnaPostType[]> => {
    if (posts && posts.allIds && posts.allIds.length > 0) {
      const { qnaPosts: denormalizedQnaPosts } = denormalize({ qnaPosts: posts.allIds }, entitySchema, {
        qnaPosts: posts.byId
      });
      const mappedQnaPosts = toArray(denormalizedQnaPosts).map(post => {
        if(thumbsups) {
          post.thumbsups = thumbsups ? thumbsups.filter(t => t.typeId === post.id) : [];
        }
        if(comments) {
          post.comments = comments.filter(c => c.typeId === post.id).map(c => {
            c.flags = flags ? flags.filter(f => f.typeId === c.id) : [];
            return c;
          });
        }
        if(favorites) {
          post.favorites = favorites ? favorites.filter(f => f.typeId === post.id) :[];
        }

        return post;
      });
      return mappedQnaPosts;
    }
    return undefined;
  }
);

export const getQnaPostCount = (state: IApplicationState) => {
  return state.ui.qnas.postCount;
};

export default { getQnaPosts };
