import React, { ChangeEvent, FormEvent, Component } from 'react';
import Collapse from 'antd/lib/collapse';
import SearchInput from './SearchInput';
import './searchPanel.scss';

const { Panel } = Collapse;

interface ComponentProps {
  extra?: React.ReactNode;
  filters: Array<React.ComponentElement<any, any>>;
  searchChangeHandler: (event: ChangeEvent<HTMLInputElement>) => any;
  searchSubmitHandler: (value: string) => any;
  searchTerm: string;
}

class SearchPanel extends Component<ComponentProps, {}> {
  render() {
    const {
      extra,
      filters,
      searchChangeHandler,
      searchSubmitHandler,
      searchTerm
    } = this.props;
    return (
      <div className="hmp-search-panel">
        <Collapse defaultActiveKey={['search']} expandIconPosition="right">
          <Panel key="search" header="Search Criteria" extra={extra}>
            <div className="hmp-search-panel-input-wrapper">
              <SearchInput
                onChangeHandler={searchChangeHandler}
                onSubmitHandler={searchSubmitHandler}
                value={searchTerm}
              />
            </div>
            <div className="hmp-search-panel-filters-wrapper">
              {filters.map((filter, index) => {
                return (
                  <div
                    key={`hmp-search-panel-filter-wrapper-${index}`}
                    className="hmp-search-panel-filter-wrapper"
                  >
                    {filter}
                  </div>
                );
              })}
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default SearchPanel;
