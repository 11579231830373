import moment from 'moment';
import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { entitySchema } from '../schema';
import { AvailabilityType } from '../../types/serverTypes/appointmentTypes';
import { NormalizerResult } from '../../types';
import {
  createAvailabilityAsync,
  deleteAvailabilityAsync,
  getAvailabilitiesAsync,
  GetAvailabilitiesQueryParameters,
} from './availability.types';

const getAvailabilities = (queryParams?: GetAvailabilitiesQueryParameters) => {
  if (queryParams) {
    const { startDate, endDate } = queryParams;
    let url = `/a/appointment/availability?startDate=${moment(
      startDate
    ).toISOString()}`;

    if (endDate) {
      url += `&endDate=${moment(endDate).toISOString()}`;
    }

    return axios({
      method: 'get',
      url,
    });
  }
};

const createAvailability = (availability: AvailabilityType) => {
  return axios({
    method: "put",
    url: `/a/appointment/availability`,
    data:  availability,
  });
}

const deleteAvailability = (id: string) => {
  return axios({
    method: "delete",
    url: `/a/appointment/availability/${id}`,
  });
}

let lastGetAvailabilitiesParam: GetAvailabilitiesQueryParameters | undefined;

function* refreshAvailabilityHandler() {
  yield put(getAvailabilitiesAsync.request(lastGetAvailabilitiesParam));
}

function* getAvailabilitiesHandler(action: ReturnType<typeof getAvailabilitiesAsync.request>): Generator {
  try {
    lastGetAvailabilitiesParam = action.payload;
    const response: AxiosResponse = (yield call( getAvailabilities, action.payload )) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.availabilities) as NormalizerResult;
    const { availabilities } = entities;
    yield put(getAvailabilitiesAsync.success(availabilities));
  } catch (error) {
    yield put(getAvailabilitiesAsync.failure(error));
  }
}

function* createAvailabilityHandler(action: ReturnType<typeof createAvailabilityAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(createAvailability, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.availabilities) as NormalizerResult;
    const { availabilities } = entities;
    yield put(createAvailabilityAsync.success(availabilities));
    yield call(refreshAvailabilityHandler);
  } catch (error) {
    yield put(createAvailabilityAsync.failure(error));
  }
}

function* deleteAvailabilityHandler( action: ReturnType<typeof deleteAvailabilityAsync.request> ): Generator {
  try {
    yield call(
      deleteAvailability,
      action.payload
    );
    yield put(deleteAvailabilityAsync.success(action.payload));
    yield call(refreshAvailabilityHandler);
  } catch (error) {
    yield put(deleteAvailabilityAsync.failure(error));
  }
}

function* getAvailabilitiesWatcher() {
  yield takeEvery( getType(getAvailabilitiesAsync.request), getAvailabilitiesHandler );
}

function* createAvailabilityWatcher() {
  yield takeLatest(getType(createAvailabilityAsync.request), createAvailabilityHandler);
}

function* deleteAvailabilityWatcher() {
  yield takeLatest(getType(deleteAvailabilityAsync.request), deleteAvailabilityHandler);
}

export default function* availabilitySaga() {
  yield all([
    fork(getAvailabilitiesWatcher),
    fork(createAvailabilityWatcher),
    fork(deleteAvailabilityWatcher)
  ]);
}
