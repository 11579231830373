import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import Draggable from 'react-draggable';
import UnsavedLeavingGuard from '../util/UnsavedLeavingGuard';
import IApplicationState from '../../types/state.types';
import * as selectors from '../../redux/selectors';
import './appointmentModal.scss';
import { deleteAppointmentAsync } from '../../redux/appointments/appointments.types';
import { ModalHeader } from '../modal/ModalHeader';
import AppointmentForm from './AppointmentForm';
import { createStructuredSelector } from 'reselect';
import { AppointmentType } from '../../types/serverTypes/appointmentTypes';

const { confirm } = Modal;

interface StateProps {
  studyId: Optional<number>;
  newOrEdit: Optional<string>;
  requestedAppointmentTab: Optional<string>;
  defaultAppointmentLength: Optional<number>;
}

interface DispatchProps {
  deleteAppointment: typeof deleteAppointmentAsync.request;
}

interface ComponentProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {
  selectedAppointment: AppointmentType;
  saveAppointment: Function;
  removeEvent?: Function;
}

interface ComponentState {
  isUnsaved: boolean;
  editing: boolean;
  disableDrag: boolean;
}

const defaultState: ComponentState = {
  isUnsaved: false,
  editing: false,
  disableDrag: true,
};

class AppointmentModal extends Component<ComponentProps, ComponentState> {
  readonly state = _.clone(defaultState);

  form = React.createRef<FormInstance>();

  handleSave = async () => {
    const { selectedAppointment, saveAppointment } = this.props;
    if (this.form.current) {
      try {
        const values = await this.form.current.validateFields();
        const {
          title,
          startDate,
          startTime,
          endTime,
          allDay,
          participantId,
          adminId,
          notes,
          adminNotes,
          address,
          phoneNumber,
          meetingMethod,
          isConfirmed,
        } = values;

        const startMoment = moment(
          `${startDate.format('YYYY-MM-DD')} ${startTime.format('hh:mm A')}`,
          'YYYY-MM-DD hh:mm A'
        );
        const endMoment = moment(
          `${startDate.format('YYYY-MM-DD')} ${endTime.format('hh:mm A')}`,
          'YYYY-MM-DD hh:mm A'
        );

        const newOrUpdateAppointment: AppointmentType = {
          id: selectedAppointment ? selectedAppointment.id : uuidv4(),
          title,
          startDate: startMoment.toDate(),
          endDate: endMoment.toDate(),
          participantId: participantId,
          adminId: adminId,
          notes,
          adminNotes,
          address,
          phoneNumber,
          meetingMethod,
          isConfirmed,
        };

        this.resetState();
        saveAppointment(newOrUpdateAppointment);
      } catch (err) {
        console.log(err);
      }
    }
  };

  handleCancel = () => {
    const { history, studyId, removeEvent, newOrEdit } = this.props;
    if (newOrEdit === 'new' && removeEvent) {
      removeEvent();
    }
    history.push(`/study/${studyId}/appointments/appointments`);
  };

  handleRemove = () => {
    const {
      studyId,
      selectedAppointment,
      newOrEdit,
      deleteAppointment,
      history,
      removeEvent,
    } = this.props;
    if (selectedAppointment) {
      if (newOrEdit === 'new' && removeEvent) {
        this.resetState();
        removeEvent();
        history.push(`/study/${studyId}/appointments/appointments`);
      } else {
        confirm({
          title: 'Are you sure you want to remove this appointment?',
          okText: 'Remove',
          okType: 'danger',
          onOk: () => {
            deleteAppointment(selectedAppointment.id!);
            this.resetState();
            history.push(`/study/${studyId}/appointments/appointments`);
          },
          onCancel() {},
        });
      }
    }
  };

  setIsUnsaved = (isUnsaved: boolean) => {
    this.setState({ isUnsaved });
  };

  onEditClick = () => {
    this.setState({ editing: true });
  };

  resetState = () => {
    this.setState(_.clone(defaultState));
  };

  onChange = () => {
    this.setState({
      isUnsaved: true,
    });
  };

  onMouseOverHeader = () => {
    if (this.state.disableDrag) {
      this.setState({
        disableDrag: false,
      });
    }
  };

  onMouseOutHeader = () => {
    if (!this.state.disableDrag) {
      this.setState({
        disableDrag: true,
      });
    }
  };

  render() {
    const { editing, isUnsaved, disableDrag } = this.state;
    const { newOrEdit, requestedAppointmentTab, selectedAppointment } =
      this.props;

    const showModal: boolean = !!(
      newOrEdit && requestedAppointmentTab === 'appointments'
    );

    return (
      <Modal
        title={
          <ModalHeader
            title={
              newOrEdit
                ? selectedAppointment.id
                  ? 'Edit Appointment'
                  : 'Add Appointment'
                : ' '
            }
            editing={!!newOrEdit}
            editClick={this.onEditClick}
            removeClick={this.handleRemove}
            closeClick={this.handleCancel}
            saveClick={this.handleSave}
            onMouseOver={this.onMouseOverHeader}
            onMouseOut={this.onMouseOutHeader}
          />
        }
        className={
          this.form.current?.getFieldValue('isConfirmed')
            ? 'modal-confirmed'
            : undefined
        }
        width={600}
        visible={showModal}
        onOk={this.handleSave}
        onCancel={this.handleCancel}
        footer={null}
        closable={false}
        mask={false}
        destroyOnClose
        afterClose={() => this.resetState()}
        modalRender={(modal) => (
          <Draggable disabled={disableDrag}>{modal}</Draggable>
        )}
      >
        {newOrEdit === 'view' ? (
          <p> event detail </p>
        ) : (
          <>
            <UnsavedLeavingGuard
              when={isUnsaved}
              navigate={(path) => this.props.history.push(path)}
              shouldBlockNavigation={() => isUnsaved}
            />
            <AppointmentForm
              formRef={this.form}
              appointment={selectedAppointment}
              onChange={this.onChange}
            />
          </>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    studyId: selectors.getRequestedStudyId,
    requestedAppointmentTab: selectors.getUrlRouteSubpage,
    newOrEdit: selectors.getUrlRouteNewOrEdit,
    defaultAppointmentLength: selectors.getDefaultAppointmentLength,
  }
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    deleteAppointment: (id: string) =>
      dispatch(deleteAppointmentAsync.request(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppointmentModal));
