import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import * as selectors from '../../redux/selectors';
import IApplicationState from '../../types/state.types';
import { transliterate as tr } from 'transliteration';
import './newQnaPostForm.scss';
import Form, { FormInstance } from 'antd/lib/form';
import { Modal, Select, Button } from 'antd';
import {
  createQnaPostAsync,
  updateQnaPostAsync,
} from '../../redux/qna/qna.types';
import HMPTextArea from '../../components/textarea/HMPTextArea';
import FroalaCustomUpload from '../activity/FroalaCustomUpload';
import { updateCmsEditorBeforeSave } from '../util/Util';
import { TopicType } from '../../types/serverTypes/forumTypes';
import { ParticipantType } from '../../types/serverTypes/studyTypes';
import { QnaPostType } from '../../types/serverTypes/qnaTypes';
import { cleanstring } from '../../service/util';

const { confirm } = Modal;
const { Item } = Form;
const { Option } = Select;

interface StateProps {
  participants: ParticipantType[];
  e;
  topics: Optional<TopicType[]>;
}

interface DispatchProps {
  createQnaPost: typeof createQnaPostAsync.request;
  updateQnaPost: typeof updateQnaPostAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {
  visible: boolean;
  closeHandler: () => void;
  post?: QnaPostType;
}

const defaultPost: QnaPostType = {
  id: undefined,
  topicId: undefined,
  question: '',
  answer: '',
  topic: '',
  isAnswered: true,
  isPrivate: false,
  answeredByUserId: -1,
  createDate: new Date(),
  lastUpdateDate: new Date(),
  username: '',
  answeredByUsername: '',
  deleteDate: null,
  threadId: null,
  tags: '',
  thumbsups: [],
  favorites: [],
  comments: [],
  adHocNotifications: [],
  createdByParticipantId: -1,
};
const itemStyle = {
  marginBottom: '10px',
  marginTop: '10px',
};
const charLimit = 2000;
const MEDIUMTEXT = 16777215;

class QnaFormContainer extends Component<ComponentProps, {}> {
  readonly state = {
    post: defaultPost,
  };

  form = React.createRef<FormInstance>();

  componentDidMount() {
    const { post } = this.props;
    if (post && post.id) {
      this.setState({ post });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<ComponentProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    if (prevProps.visible && prevProps.post && this.form.current) {
      this.form.current.setFieldsValue({
        topicId: prevProps.post.topicId,
        question: prevProps.post.question,
        answer: prevProps.post.answer,
      });
    }
  }

  renderTitle = () => {
    return (
      <div className="qna-form-title">
        <span className="qna-title-text">Create/Edit Post</span>
      </div>
    );
  };

  handleSave = () => {
    if (this.form.current) {
      this.form.current.validateFields().then(() => {
        confirm({
          title: 'Are you sure you want to post this?',
          content: '',
          okText: 'Confirm',
          onOk: () => {
            this.handleSaveQnaPost();
          },
          onCancel() {},
        });
      });
    }
  };

  handleSaveQnaPost = async () => {
    const { closeHandler, createQnaPost, updateQnaPost } = this.props;
    const { post } = this.state;
    const { question, answer } = post;
    post.question = cleanstring(question);
    post.answer = answer ? cleanstring(answer) : '';
    if (post.id) {
      updateQnaPost(post);
    } else {
      createQnaPost(post);
    }
    this.resetState();
    closeHandler();
  };

  resetState = () => {
    const resetState = _.cloneDeep({ post: defaultPost });
    this.setState(resetState);
  };

  handleCancel = () => {
    const { closeHandler } = this.props;
    confirm({
      title: 'Are you sure you want to leave this post?',
      content: 'You will lose all changes.',
      okText: 'Leave',
      okType: 'danger',
      onOk: () => {
        this.resetState();
        closeHandler();
      },
      onCancel() {},
    });
  };

  onTopicIdChange = (id: number) => {
    const { post } = this.state;
    post.topicId = id;
    this.setState({ post });
  };

  onQuestionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { post } = this.state;
    post.question = e.target.value;
    this.setState({ post });
  };

  onAnswerChange = (value: string) => {
    const { post } = this.state;
    post.answer = updateCmsEditorBeforeSave(value);
    this.setState({ post });
  };

  characterLimit = (s: string | null, limit: number): string => {
    if (s) {
      if (s.length <= limit) {
        return `(${limit - s.length} characters left.)`;
      }
      return `(${s.length - limit} characters over character limit.)`;
    }
    return `(${limit} characters left)`;
  };

  render() {
    const { visible, topics } = this.props;
    const { post } = this.state;
    const { topicId, question, answer } = post;
    const footerActions = [
      <Button key="cancel" type="default" onClick={this.handleCancel}>
        Cancel
      </Button>,
      <Button key="primary" type="primary" onClick={this.handleSave}>
        Post
      </Button>,
    ];
    return (
      <Modal
        title={this.renderTitle()}
        visible={visible}
        onOk={this.handleSave}
        onCancel={this.handleCancel}
        footer={footerActions}
        closable={false}
        destroyOnClose
        width="50%"
        style={{ minWidth: '800px' }}
      >
        <div>
          <Form
            ref={this.form}
            key="post-form"
            className="new-qna-post-form"
            layout="vertical"
            colon={false}
          >
            <Item
              key="post-form-topic"
              name="topicId"
              label="Topic:"
              style={itemStyle}
              rules={[{ required: true, message: 'Type is required.' }]}
              initialValue={topicId}
            >
              <Select
                showSearch
                onChange={this.onTopicIdChange}
                style={{ width: 'auto', minWidth: 200 }}
                placeholder="Choose a topic"
                optionFilterProp="value"
                filterOption={(input, option) => {
                  const current = topics?.find(
                    (t) => t.id === option?.props.value
                  );
                  if (current) {
                    return (
                      current.title
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }
                  return false;
                }}
              >
                {topics &&
                  _.map(_.sortBy(topics, 'title'), (topic) => (
                    <Option key={`option-${topic.id}`} value={topic.id}>
                      {topic.title}
                    </Option>
                  ))}
              </Select>
            </Item>
            <Item
              key="post-form-label"
              name="question"
              label="Question:"
              style={itemStyle}
              rules={[
                { required: true, message: `Question is required.` },
                {
                  max: charLimit,
                  message: `${question.length - charLimit} characters over`,
                },
              ]}
              initialValue={question}
            >
              <HMPTextArea
                spellCheck
                maxRows={8}
                onChange={this.onQuestionChange}
                placeholder="Question text"
                characterLimit={charLimit}
              />
            </Item>
            <Item
              key="post-form-answerhtml"
              name="answer"
              label="Answer:"
              style={itemStyle}
              rules={[
                { required: true, message: `Answer is required.` },
                {
                  max: MEDIUMTEXT,
                  message: `${
                    answer ? answer.length - MEDIUMTEXT : 0
                  } characters over. Consider using less stylized text or mark up.`,
                },
              ]}
              valuePropName="model"
              initialValue={answer}
            >
              <FroalaCustomUpload
                onChange={this.onAnswerChange}
                charLimit={charLimit}
              />
            </Item>
          </Form>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    participants: selectors.getRequestedStudyParticipantsWithUsernames(state),
    topics: selectors.getQnaTopics(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    createQnaPost: (post: QnaPostType) =>
      dispatch(createQnaPostAsync.request(post)),
    updateQnaPost: (post: QnaPostType) =>
      dispatch(updateQnaPostAsync.request(post)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QnaFormContainer);
