import { InboxOutlined, WarningOutlined } from '@ant-design/icons';
import {
  Button,
  Cascader,
  Checkbox,
  message,
  Modal,
  Select,
  Table,
  Tooltip,
} from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import uuid from 'uuid';
import {
  createAvatarParts,
  getAvatarCategories,
  getAvatarParts,
} from '../../service/avatar/avatarService';
import {
  AvatarPartCategoryType,
  AvatarPartType,
  AvatarPartUploadType,
} from '../../types/serverTypes/avatarTypes';
import AvatarPartSvgViewer from './AvatarPartSvgViewer';
const { Option } = Select;

interface AvatarNewPartModalProp {
  showModal: boolean;
  onCancel: () => void;
}

const AvatarNewPartModal = (props: AvatarNewPartModalProp) => {
  const [uploadedData, setUploadedData] = useState<AvatarPartUploadType[]>([]);
  const queryClient = useQueryClient();
  const secondaryOption = useRef<any>();

  const buildSecondaryOptions = () => {
    let tmpSecondaryOptions: any[] = [];
    if (categories.data && parts.data) {
      // create cascade options for secondary avatar id
      for (let index = 0; index < categories.data.length; index++) {
        const category = categories.data[index];
        let children: any[] = [];
        for (let index = 0; index < parts.data.length; index++) {
          const part = parts.data[index];
          if (part.avatarPartCategoryId === category.id) {
            children.push({
              value: part.id,
              label: (
                <Tooltip title={part.name} placement="left" key={part.id}>
                  {part.secondarySvg && (
                    <WarningOutlined alt="Secondary SVG already specified for this item" />
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: part.svg,
                    }}
                    style={{ width: 50, height: 50 }}
                  ></div>
                </Tooltip>
              ),
            });
          }
        }
        tmpSecondaryOptions.push({
          value: category.id,
          label: category.label,
          children: children,
          disabled: children.length === 0,
        });
      }
      secondaryOption.current = tmpSecondaryOptions;
    }
  };

  useEffect(() => {
    buildSecondaryOptions();
  }, [props]);

  const parts = useQuery<AvatarPartType[]>('avatarParts', getAvatarParts);
  const categories = useQuery<AvatarPartCategoryType[]>(
    'avatarCategories',
    getAvatarCategories,
    {
      onSuccess: buildSecondaryOptions,
      enabled: parts.isSuccess,
    }
  );

  const onSave = () => {
    // save data
    if (validate()) {
      avatarMutation.mutate(uploadedData);
      // close modal
      onCancel();
    }
  };
  const avatarMutation = useMutation(createAvatarParts, {
    onError: (error, variables, context) => {
      message.error('Error in creating new avatars');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('avatarParts');
      message.success('Avatar part(s) created successfully.');
    },
  });

  const validate = () => {
    for (let index = 0; index < uploadedData.length; index++) {
      const uploadedItem = uploadedData[index];
      if (!uploadedItem.categoryId) {
        message.error('Category is required');
        return false;
      }
    }
    return true;
  };
  const onCancel = () => {
    setUploadedData([]);
    props.onCancel();
  };

  const readUploadFile = (file): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
    });
  };

  const onIsBaseSetChange = (isBaseSet, uid) => {
    setData('isBaseSet', isBaseSet, uid);
  };
  const onIsBaseSetDefaultAppliedChange = (isBaseSetDefaultApplied, uid) => {
    setData('isBaseSetDefaultApplied', isBaseSetDefaultApplied, uid);
  };
  const onCategoryChange = (value, uid) => {
    setData('categoryId', value, uid);
  };
  const onSecondaryChange = (value, uid) => {
    const selectedValue = value[value.length - 1];
    setData('secondaryAvatarPartId', selectedValue, uid);
  };

  const setData = (key: string, value: any, uid: string) => {
    let newUploadedData: AvatarPartUploadType[] = [];
    for (let index = 0; index < uploadedData.length; index++) {
      const uploadedItem = uploadedData[index];
      if (uploadedItem.uid === uid) {
        newUploadedData.push({
          ...uploadedItem,
          [key]: value,
        });
      } else {
        newUploadedData.push(uploadedItem);
      }
    }
    setUploadedData(newUploadedData);
  };

  const displayRender = (label) => {
    let displayNode: JSX.Element[] = [];
    for (let index = 0; index < label.length; index++) {
      const item = label[index];
      if (index === label.length - 1) {
        displayNode.push(item);
      } else {
        displayNode.push(<span key={uuid()}>{`${item} / `}</span>);
      }
    }
    return (
      <div key={uuid()} style={{ display: 'flex', flexDirection: 'row' }}>
        {displayNode}
      </div>
    );
  };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (item, uploadedData: AvatarPartUploadType) =>
        uploadedData.fileName,
    },
    {
      title: 'Preview',
      render: (item, uploadedData: AvatarPartUploadType) => (
        <AvatarPartSvgViewer
          svgString={uploadedData.fileContent}
          key={uploadedData.uid}
        />
      ),
    },
    {
      title: 'Category',
      render: (item, uploadedData: AvatarPartUploadType) => (
        <Select
          key={`category-${uploadedData.uid}`}
          style={{ width: 150 }}
          value={uploadedData.categoryId}
          onChange={(value) => onCategoryChange(value, uploadedData.uid)}
        >
          {categories.data?.map((category) => {
            return (
              <Option key={category.id} value={category.id}>
                {category.label}
              </Option>
            );
          })}
        </Select>
      ),
    },
    {
      title: 'Secondary Of',
      render: (item, uploadedData: AvatarPartUploadType) => (
        <Cascader
          options={secondaryOption.current}
          displayRender={displayRender}
          onChange={(value) => onSecondaryChange(value, uploadedData.uid)}
          expandTrigger="hover"
          placeholder="Please select"
        />
      ),
    },
    {
      title: 'Base Set?',
      key: 'isBaseSet',
      render: (item, uploadedData: AvatarPartUploadType) => {
        if (uploadedData.secondaryAvatarPartId) {
          return null;
        }
        return (
          <Checkbox
            checked={uploadedData.isBaseSet}
            onChange={(e) =>
              onIsBaseSetChange(e.target.checked, uploadedData.uid)
            }
          />
        );
      },
    },
    {
      title: 'Default Applied?',
      render: (item, uploadedData: AvatarPartUploadType) => {
        if (uploadedData.secondaryAvatarPartId) {
          return null;
        }
        return (
          <Checkbox
            checked={uploadedData.isBaseSetDefaultApplied}
            onChange={(e) =>
              onIsBaseSetDefaultAppliedChange(
                e.target.checked,
                uploadedData.uid
              )
            }
          />
        );
      },
    },
  ];

  const beforeUpload = async (file, fileList) => {
    let tmpUploadedFile: AvatarPartUploadType[] = [];
    for (let index = 0; index < fileList.length; index++) {
      const file = fileList[index];
      const fileContent = await readUploadFile(file);
      tmpUploadedFile.push({
        uid: file.uid,
        isBaseSet: false,
        isBaseSetDefaultApplied: false,
        categoryId: 0,
        fileName: file.name,
        fileContent: fileContent,
      });
    }
    setUploadedData(tmpUploadedFile);
    return false;
  };

  const uploadProps = {
    name: 'file',
    method: undefined,
    multiple: true,
    showUploadList: false,
    beforeUpload: beforeUpload,
  };

  return (
    <Modal
      destroyOnClose={true}
      maskClosable={false}
      width={1000}
      title="Create New Avatar Part"
      visible={props.showModal}
      onOk={onSave}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onSave}>
          Submit
        </Button>,
      ]}
    >
      <Dragger {...uploadProps} accept="image/svg+xml" height={100}>
        <p className="ant-upload-drag-icon" style={{ height: 30 }}>
          <InboxOutlined style={{ fontSize: 30 }} />
        </p>
        <p className="ant-upload-text" style={{ height: 30 }}>
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Upload</p>
      </Dragger>
      <Table
        dataSource={uploadedData}
        columns={columns}
        rowKey="uid"
        style={{ marginTop: 10 }}
      />
    </Modal>
  );
};

export default AvatarNewPartModal;
