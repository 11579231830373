import * as _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import includes from 'lodash/includes';
import Table, { ColumnType } from 'antd/lib/table';
import { renderDateWithoutTime } from '../../components/util/Util';
import SearchInput from '../../components/search/SearchInput';
import './Participants.scss';
import { Card } from 'antd';
import Username from '../../components/username/Username';
import {
  ParticipantType,
  StudyArmType,
} from '../../types/serverTypes/studyTypes';
import { useQuery } from 'react-query';
import { getParticipants } from '../../service/participant/participantService';
import { getStudyArms, getStudyId } from '../../service/studyService';

interface StateProps {
  searchString: string;
  armFilterId: number;
  filtersCollapsed: boolean;
  participantSubpage: string;
}

const Participants = (props: RouteComponentProps) => {
  const [state, setState] = useState<StateProps>({
    searchString: '',
    armFilterId: 0,
    filtersCollapsed: false,
    participantSubpage: 'participants',
  });
  const participants = useQuery<ParticipantType[]>(
    'participants',
    getParticipants
  );
  const studyArms = useQuery<StudyArmType[]>('studyArms', getStudyArms);

  const handleSearchStringChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value.toString();
    setState({
      ...state,
      searchString: value,
    });
  };

  const getStudyArmFilters = () => {
    if (studyArms.data) {
      return studyArms.data.map((arm) => {
        return {
          text: arm.name,
          value: arm.id,
        };
      });
    }
    return [];
  };
  const renderParticipantLink = (value: any, row: any) => {
    return <Username participantId={row.id} />;
  };
  const renderFirstNameLink = (value: any, row: any) => {
    const studyId = getStudyId();
    return <Link to={`/study/${studyId}/participants/${row.id}`}>{value}</Link>;
  };
  const renderStudyArm = (value: number) => {
    const studyId = getStudyId();
    if (value && studyArms.data) {
      const arm = studyArms.data.find((sa) => sa.id === value);
      return arm ? arm.name : value;
    }
  };

  const handleRowClick = (record: ParticipantType) => {
    if (record && record.id) {
      const studyId = getStudyId();
      props.history.push(`/study/${studyId}/participants/${record.id}`);
    }
  };

  const studyArmFilters = getStudyArmFilters();
  const columns: ColumnType<ParticipantType>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: renderParticipantLink,
      sorter: (a: any, b: any) => `${a.username}`.localeCompare(b.username),
      filters: [
        {
          text: 'Unenrolled',
          value: 'Unenrolled',
        },
      ],
      onFilter: (value, record: ParticipantType) =>
        value === 'Unenrolled' && _.isEmpty(record.username),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: renderFirstNameLink,
      sorter: (a: any, b: any) => `${a.firstName}`.localeCompare(b.firstName),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: any, b: any) => `${a.email}`.localeCompare(b.email),
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      sorter: (a: any, b: any) => `${a.mobile}`.localeCompare(b.mobile),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: renderDateWithoutTime,
      sorter: (a: any, b: any) =>
        moment(a.startDate).unix() - moment(b.startDate).unix(),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Study Arm',
      dataIndex: 'studyArmId',
      key: 'studyArmId',
      render: renderStudyArm,
      filters: studyArmFilters,
      onFilter: (value, record: ParticipantType) => record.studyArmId === value,
    },
  ];

  const searchParticipants =
    state.searchString === ''
      ? participants.data
      : participants.data?.filter((participant) => {
          return Object.values(participant).some((property) =>
            includes(
              property ? property.toString().toLowerCase() : '',
              state.searchString.toLowerCase()
            )
          );
        });

  const filteredParticipants =
    state.armFilterId === 0
      ? searchParticipants
      : searchParticipants?.filter((participant) => {
          return participant.studyArmId === state.armFilterId;
        });
  const rowClassName = (record: any, index: number): string => {
    return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
  };

  return (
    <Card id="participant-search">
      <div className="table-filter-search">
        <SearchInput
          className="search-bar"
          id="search-input"
          onChangeHandler={handleSearchStringChange}
          placeholder="Search Participants"
          value={state.searchString}
        />
      </div>
      <div id="search-results-container">
        <div style={{ flex: '0 0 auto', height: '100%' }}>
          <Table
            loading={participants.isLoading}
            columns={columns}
            dataSource={filteredParticipants}
            rowClassName={rowClassName}
            pagination={{
              defaultPageSize: 20,
              pageSizeOptions: ['20', '50', '100'],
              position: ['bottomRight'],
            }}
            tableLayout="fixed"
            rowKey="id"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => handleRowClick(record), // click row
                onDoubleClick: (event) => {}, // double click row
                onContextMenu: (event) => {}, // right button click row
                onMouseEnter: (event) => {}, // mouse enter row
                onMouseLeave: (event) => {}, // mouse leave row
              };
            }}
          />
        </div>
      </div>
    </Card>
  );
};

export default withRouter(Participants);
