import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import {AnnouncementType} from "../../types/serverTypes/announcementTypes";

export const getAnnouncementsAsync = createAsyncAction(
  'hmp/admin/GET_ANNOUNCEMENTS_REQUEST',
  'hmp/admin/GET_ANNOUNCEMENTS_SUCCESS',
  'hmp/admin/GET_ANNOUNCEMENTS_FAILURE'
)<number, Optional<NormalizedType<AnnouncementType>>, Error>();

export const createAnnouncementAsync = createAsyncAction(
  'hmp/admin/CREATE_ANNOUNCEMENTS_REQUEST',
  'hmp/admin/CREATE_ANNOUNCEMENTS_SUCCESS',
  'hmp/admin/CREATE_ANNOUNCEMENTS_FAILURE'
)<AnnouncementType, Optional<NormalizedType<AnnouncementType>>, Error>();

export const updateAnnouncementAsync = createAsyncAction(
  'hmp/admin/UPDATE_ANNOUNCEMENTS_REQUEST',
  'hmp/admin/UPDATE_ANNOUNCEMENTS_SUCCESS',
  'hmp/admin/UPDATE_ANNOUNCEMENTS_FAILURE'
)<AnnouncementType, Optional<NormalizedType<AnnouncementType>>, Error>();

export const updateAnnouncements = createStandardAction('hmp/admin/UPDATE_ANNOUNCEMENTS')<Optional<NormalizedType<AnnouncementType>>>();

const activitiesActions = {
  getAnnouncementsAsync,
  createAnnouncementAsync,
  updateAnnouncementAsync
};

export type ActivitiesActionTypes = ActionType<typeof activitiesActions>;
