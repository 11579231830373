import {
  ActionType,
  createAsyncAction,
  createStandardAction,
} from "typesafe-actions";
import { NormalizedType } from "../../types/state.types";
import {AppointmentType} from "../../types/serverTypes/appointmentTypes";

export interface GetAppointmentsQueryParameters {
  includeDeleted?: boolean;
}

export const getAppointmentsAsync = createAsyncAction(
  "hmp/admin/GET_APPOINTMENTS_REQUEST",
  "hmp/admin/GET_APPOINTMENTS_SUCCESS",
  "hmp/admin/GET_APPOINTMENTS_FAILURE"
)<GetAppointmentsQueryParameters | undefined, Optional<NormalizedType<AppointmentType>>,Error>();

export const createAppointmentAsync = createAsyncAction(
  "hmp/admin/CREATE_APPOINTMENTS_REQUEST",
  "hmp/admin/CREATE_APPOINTMENTS_SUCCESS",
  "hmp/admin/CREATE_APPOINTMENTS_FAILURE"
)<AppointmentType, Optional<NormalizedType<AppointmentType>>, Error>();

export const updateAppointmentAsync = createAsyncAction(
  "hmp/admin/UPDATE_APPOINTMENTS_REQUEST",
  "hmp/admin/UPDATE_APPOINTMENTS_SUCCESS",
  "hmp/admin/UPDATE_APPOINTMENTS_FAILURE"
)<AppointmentType, Optional<NormalizedType<AppointmentType>>, Error>();

export const deleteAppointmentAsync = createAsyncAction(
  "hmp/admin/DELETE_APPOINTMENTS_REQUEST",
  "hmp/admin/DELETE_APPOINTMENTS_SUCCESS",
  "hmp/admin/DELETE_APPOINTMENTS_FAILURE"
)<string, Optional<NormalizedType<AppointmentType>>, Error>();

export const updateAppointments = createStandardAction(
  "hmp/admin/UPDATE_APPOINTMENTS"
)<Optional<NormalizedType<AppointmentType>>>();

const activitiesActions = {
  getAppointmentsAsync,
  createAppointmentAsync,
  updateAppointmentAsync,
  deleteAppointmentAsync,
};

export type ActivitiesActionTypes = ActionType<typeof activitiesActions>;
