import React, { ChangeEvent } from 'react';
import * as _ from 'lodash';
import generateUUID from 'uuid';
import {
  Input, Checkbox, Form
} from 'antd';
import '../activity.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import DynamicOptionItems from '../options/DynamicOptionItems';
import { Type } from '../ActivityFormContainer';
import { OptionType, QuestionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  disabled: boolean;
  question: QuestionType;
  onTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  updateQuestion: (q: QuestionType) => void;
}
const defaultOption = {
  text: '',
  uuid: '',
  points: 0,
  isCorrect: false,
  correctPosition: undefined,
  feedback: undefined
};

const QuizQuestion = (props: ComponentProps) => {
  const {
    question, onTextChange, updateQuestion, disabled
  } = props;
  const {
    id, text, options, randomizeOptions, uuid
  } = question;
  const onRandomizeOptions = (e: CheckboxChangeEvent) => {
    question.randomizeOptions = e.target.checked;
    updateQuestion(question);
  };
  const updateOption = (option: OptionType, index: number) => {
    if (options) {
      options[index] = option;
      updateQuestion(question);
    }
  };
  const addOption = () => {
    const updatedQuestion = _.cloneDeep(question);
    const newOption = _.cloneDeep(defaultOption);
    newOption.uuid = generateUUID();
    if (!updatedQuestion.options) {
      updatedQuestion.options = [newOption];
    } else {
      updatedQuestion.options[updatedQuestion.options.length] = newOption;
    }
    updateQuestion(updatedQuestion);
  };
  const removeOption = (index: number) => {
    let i = 0;
    question.options = options ? options.filter(o => { return index !== i++; }) : [];
    updateQuestion(question);
  };
  const validateQuizQuestion = (rule, value) => {
    try {
      if (!options || options.length === 0) {
        throw new Error('Quiz questions must have at least one option');
      }
      if (options?.some((o) => o.text.length == 0)) {
        throw new Error('Question options must have text');
      }
      if (!options?.some((o) => o.isCorrect)) {
        throw new Error(
          'Quiz questions need at least one correct option defined'
        );
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };
  return (
    <Item
      key={`question-${id || uuid}`}
      name={`question-${id || uuid}`}
      rules={[{ required: true, validator: validateQuizQuestion }]}
    >
      <Item
        key={`question-text-${id || uuid}`}
        name={`question-text-${id || uuid}`}
        initialValue={text}
        rules={[{ required: true, message: 'Please add question text.' }]}
      >
        <Input spellCheck disabled={disabled} placeholder="Question text" onChange={onTextChange} />
      </Item>
      <Checkbox disabled={disabled} checked={randomizeOptions} onChange={onRandomizeOptions}>Randomize answer options</Checkbox>
      <DynamicOptionItems
        disabled={disabled}
        options={options || []}
        typeId={Type.quiz}
        updateOption={updateOption}
        addOption={addOption}
        removeOption={removeOption}
      />
    </Item>
  );
};

export default QuizQuestion;
