import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  getQnaPostsAsync,
  createQnaPostAsync,
  updateQnaPostAsync,
  getQnaPostAsync,
  deleteQnaPostAsync,
  getFlaggedQnaContentAsync,
  clearQnaPosts
} from './qna.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import {QnaPostType} from "../../types/serverTypes/qnaTypes";

const updateQnaPostsReducer = produce((draft, qnaPosts) => {
  if (!qnaPosts) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(qnaPosts), Number));
  const ids = Object.keys(qnaPosts);
  for (const id of ids) {
    draft.byId[id] = qnaPosts[id];
  }
});

export const qnaReducer = (state: NormalizedState<QnaPostType> = initialNormalizedState, action: any) => {
  switch (action.type) {
    case getType(getQnaPostsAsync.success): return updateQnaPostsReducer(state, action.payload);
    case getType(getQnaPostAsync.success): return updateQnaPostsReducer(state, action.payload);
    case getType(createQnaPostAsync.success): return updateQnaPostsReducer(state, action.payload);
    case getType(updateQnaPostAsync.success): return updateQnaPostsReducer(state, action.payload);
    case getType(deleteQnaPostAsync.success): return updateQnaPostsReducer(state, action.payload);
    case getType(getFlaggedQnaContentAsync.success): return updateQnaPostsReducer(state, action.payload);

    case getType(clearQnaPosts): return { allIds: [], byId: {}};

    default: return state;
  }
};

export default qnaReducer;
