import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import { number } from 'prop-types';

export type CreateThreadArguments = {
  participantId: number;
  title: string;
  body: string;
}

export type SendMessageArguments = {
  threadId: number;
  body: string;
}

export type AssignThreadArguments = {
  threadId: number;
  userId: number;
}

export type WebSocketMessage = {
  type: string;
  message: any;
}

export type TypingIndicator = {
  threadId: number,
  username: string
}

export const getParticipantThreadsAsync = createAsyncAction(
  'hmp/admin/GET_PARTICIPANT_MESSAGES_REQUEST',
  'hmp/admin/GET_PARTICIPANT_MESSAGES_SUCCESS',
  'hmp/admin/GET_PARTICIPANT_MESSAGES_FAILURE'
)<number, Optional<NormalizedType<MessageThreadType>>, Error>();

export interface GetInboxArguments {
  pageSize: number;
  pageNumber: number;
  previewLength?: number;
  searchTerm?: string;
  view: string;
  filterBy: string;
  sortBy: string;
}

export interface IMessageUIState {
  threadCount: number;
  isTypingIndicators: Map<number, Set<string>>
}

export const getMessageInboxAsync = createAsyncAction(
  'hmp/admin/GET_MESSAGE_INBOX_REQUEST',
  'hmp/admin/GET_MESSAGE_INBOX_SUCCESS',
  'hmp/admin/GET_MESSAGE_INBOX_FAILURE'
)<GetInboxArguments, Optional<NormalizedType<MessageThreadType>>, Error>();

export const getMessageThreadCountAsync = createAsyncAction(
  'hmp/admin/GET_MESSAGE_THREAD_COUNT_REQUEST',
  'hmp/admin/GET_MESSAGE_THREAD_COUNT_SUCCESS',
  'hmp/admin/GET_MESSAGE_THREAD_COUNT_FAILURE'
)<void, number, Error>();

export interface GetMessagesArguments {
  threadId: number;
  pageNumber?: number;
  pageSize?: number;
}

export const getMessagesAsync = createAsyncAction(
  'hmp/admin/GET_MESSAGES_REQUEST',
  'hmp/admin/GET_MESSAGES_SUCCESS',
  'hmp/admin/GET_MESSAGES_FAILURE'
)<GetMessagesArguments, Optional<NormalizedType<MessageResponseType>>, Error>();

export const createThreadAsync = createAsyncAction(
  'hmp/admin/CREATE_THREAD_REQUEST',
  'hmp/admin/CREATE_THREAD_SUCCESS',
  'hmp/admin/CREATE_THREAD_FAILURE'
)<CreateThreadArguments, Optional<NormalizedType<MessageThreadType>>, Error>();

export const sendMessageAsync = createAsyncAction(
  'hmp/admin/SEND_MESSAGE_REQUEST',
  'hmp/admin/SEND_MESSAGE_SUCCESS',
  'hmp/admin/SEND_MESSAGE_FAILURE'
)<SendMessageArguments, Optional<NormalizedType<MessageType>>, Error>();

export const archiveThreadAsync = createAsyncAction(
  'hmp/admin/ARCHIVE_THREAD_REQUEST',
  'hmp/admin/ARCHIVE_THREAD_SUCCESS',
  'hmp/admin/ARCHIVE_THREAD_FAILURE',
)<number, Optional<NormalizedType<MessageThreadType>>, Error>();

export const unarchiveThreadAsync = createAsyncAction(
  'hmp/admin/UNARCHIVE_THREAD_REQUEST',
  'hmp/admin/UNARCHIVE_THREAD_SUCCESS',
  'hmp/admin/UNARCHIVE_THREAD_FAILURE',
)<number, Optional<NormalizedType<MessageThreadType>>, Error>();

export const markThreadImportantAsync = createAsyncAction(
  'hmp/admin/MARK_THREAD_IMPORTANT_REQUEST',
  'hmp/admin/MARK_THREAD_IMPORTANT_SUCCESS',
  'hmp/admin/MARK_THREAD_IMPORTANT_FAILURE'
)<number, Optional<NormalizedType<MessageThreadType>>, Error>();

export const unmarkThreadImportantAsync = createAsyncAction(
  'hmp/admin/UNMARK_THREAD_IMPORTANT_REQUEST',
  'hmp/admin/UNMARK_THREAD_IMPORTANT_SUCCESS',
  'hmp/admin/UNMARK_THREAD_IMPORTANT_FAILURE'
)<number, Optional<NormalizedType<MessageThreadType>>, Error>();

export const markThreadUnreadAsync = createAsyncAction(
  'hmp/admin/MARK_THREAD_UNREAD_REQUEST',
  'hmp/admin/MARK_THREAD_UNREAD_SUCCESS',
  'hmp/admin/MARK_THREAD_UNREAD_FAILURE'
)<number, Optional<NormalizedType<MessageThreadType>>, Error>();

export const markThreadReadAsync = createAsyncAction(
  'hmp/admin/MARK_THREAD_READ_REQUEST',
  'hmp/admin/MARK_THREAD_READ_SUCCESS',
  'hmp/admin/MARK_THREAD_READ_FAILURE'
)<number, Optional<NormalizedType<MessageThreadType>>, Error>();

export const assignThreadAsync = createAsyncAction(
  'hmp/admin/ASSIGN_THREAD_REQUEST',
  'hmp/admin/ASSIGN_THREAD_SUCCESS',
  'hmp/admin/ASSIGN_THREAD_FAILURE'
)<AssignThreadArguments, Optional<NormalizedType<MessageThreadType>>, Error>();

export const unassignThreadAsync = createAsyncAction(
  'hmp/admin/UNASSIGN_THREAD_REQUEST',
  'hmp/admin/UNASSIGN_THREAD_SUCCESS',
  'hmp/admin/UNASSIGN_THREAD_FAILURE'
)<AssignThreadArguments, Optional<NormalizedType<MessageThreadType>>, Error>();

export const updateMessages = createStandardAction('hmp/admin/UPDATE_MESSAGES')<Optional<NormalizedType<MessageType>>>();
export const clearMessages = createStandardAction('hmp/admin/CLEAR_MESSAGES')<void>();
export const updateMessageThreadCount = createStandardAction(
  "hmp/admin/UPDATE_MESSAGE_THREAD_COUNT"
)<number>();

export const addToIsTypingIndicators = createStandardAction(
"hmp/admin/ADD_TO_IS_TYPING_INDICATORS"
)<{typingIndicator: TypingIndicator}>();

export const removeFromIsTypingIndicators = createStandardAction(
  "hmp/admin/REMOVE_FROM_IS_TYPING_INDICATORS"
)<{typingIndicator: TypingIndicator}>();

export const refreshInboxAndThread = createStandardAction('hmp/admin/REFRESH_INBOX_AND_THREAD')<number>();
export const refreshInbox = createStandardAction('hmp/admin/REFRESH_INBOX')<void>();

const messagesActions = {
  getParticipantThreadsAsync,
  getMessageInboxAsync,
  getMessagesAsync,
  updateMessages,
  clearMessages,
  createThreadAsync,
  sendMessageAsync,
  archiveThreadAsync,
  unarchiveThreadAsync,
  markThreadImportantAsync,
  unmarkThreadImportantAsync,
  markThreadUnreadAsync,
  assignThreadAsync,
  unassignThreadAsync,
  getMessageThreadCountAsync,
  updateMessageThreadCount,
  refreshInboxAndThread,
  addToIsTypingIndicators,
  removeFromIsTypingIndicators
};

export type MessagesActionTypes = ActionType<typeof messagesActions>;
