import { Reducer } from 'react';
import { getType } from 'typesafe-actions';
import { IStudyUIState, loadStudyStatsAsync, StudyActionTypes } from './study.types';

export const initialStudyUIState = {
 stats: [
 ]
}

export const studyUIReducer: Reducer<IStudyUIState, StudyActionTypes> = (state: IStudyUIState = initialStudyUIState, action: StudyActionTypes) => {

  switch (action.type) {
    case getType(loadStudyStatsAsync.success):
      return {
        ...state,
        stats: action.payload
      };

    default: return state;
  }

};

export default studyUIReducer;
