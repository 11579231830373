import React from 'react';
import { Link } from 'react-router-dom';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import Button from 'antd/lib/button/button';
import Input from 'antd/lib/input/Input';
import AntdIcon from '../../../components/antdIcon/AntdIcon';

class CmsPage extends React.Component<{}, {}> {

  render() {
    const { Search } = Input;

    return (
      <div>
        <div>
          <span>Content Library</span>
          <Search
            enterButton
            onSearch={value => console.log(value)}
            placeholder="search for content"
            style={{ width: 200 }}
          />
          <span style={{ float: 'right' }}>Add</span>
        </div>
        <div>
          <span>Collections</span>
          <span>HMP Core</span>
        </div>
        <div>List articles/search articles/categories/etc</div>
        <Link to="/admin/cms/article/new">New Article</Link>
      </div>
    );
  }
}

export default CmsPage;
