import {Action, Store} from "redux";
import {
  addToIsTypingIndicators,
  refreshInbox,
  refreshInboxAndThread,
  removeFromIsTypingIndicators,
  TypingIndicator,
} from "./messages/messages.types";
import {w3cwebsocket} from "websocket";
import IApplicationState from "../types/state.types";
import { WSMessageType } from '../types/serverTypes/index';
import { parseJSON } from '../service/util';

var WebSocketClient = require('websocket').w3cwebsocket;
const wsApiUrl = process.env.WS_API_URL || `wss://${window.location.host}`;

const DEBUG = false;
const MILLIS_WAIT_ON_SERVER_TO_RESPOND = 5000; // a longer timeout to wait on server to respond
const MILLIS_WAIT_ON_TOKEN = 250; // a short interval for checking to see if token exists

class ClientWebSocketManager {
  private static store: Store<any, Action>;
  private static client: w3cwebsocket;

  private static initIntervalObj: any; // wait on this to try to reconnect
  private static retrySemaphore: any; // while this exists

  constructor(store: Store<any, Action>) {
    ClientWebSocketManager.store = store;
  }

  // First we'll loop this init interval until we have a token
  static init = () => {
    if (DEBUG) console.log('initialize websocket');

    clearInterval(ClientWebSocketManager.initIntervalObj);
    ClientWebSocketManager.initIntervalObj = setInterval(async () => {
      const token = (
        ClientWebSocketManager.store.getState() as IApplicationState
      ).oidc?.user?.id_token;

      if (!token) {
        if (DEBUG) console.log('No token, return');
        return; //  if no token do nothing, wait for next interval
      }

      if (ClientWebSocketManager.retrySemaphore) {
        // a connection is in progress, wait for next interval
        if (DEBUG) console.log(`retrySemaphore is on, return`);
        return;
      }

      ClientWebSocketManager.createConnection();
    }, MILLIS_WAIT_ON_TOKEN);
  };

  // Next we loop this createConnection interval until we have a connection
  static createConnection = () => {
    if (DEBUG) console.log('create websocket connection');
    if (ClientWebSocketManager.retrySemaphore) {
      // a connection is in progress, wait for next interval
      if (DEBUG) console.log('retrySemaphore is on, return');
      return;
    }

    if (DEBUG) console.log('create new websocket client');
    ClientWebSocketManager.client = new WebSocketClient(
      wsApiUrl,
      'echo-protocol'
    );

    ClientWebSocketManager.retrySemaphore = setTimeout(async () => {
      if (DEBUG)
        console.log(
          `MILLIS_WAIT_ON_SERVER_TO_RESPOND (${MILLIS_WAIT_ON_SERVER_TO_RESPOND}) hit, delete retrySemaphore obj`
        );
      ClientWebSocketManager.retrySemaphore = undefined;
    }, MILLIS_WAIT_ON_SERVER_TO_RESPOND);

    ClientWebSocketManager.client.onopen = () => {
      if (DEBUG) console.log('websocket connection onopen');
      clearInterval(ClientWebSocketManager.initIntervalObj);
      const token = (
        ClientWebSocketManager.store.getState() as IApplicationState
      ).oidc?.user?.id_token;
      if (token) {
        ClientWebSocketManager.sendMessage({ type: 'auth', meta: { token } });
      } else {
        //shouldn't get here, we established that we had a token before calling, but if we did get here then start over
        ClientWebSocketManager.init();
      }
    };

    ClientWebSocketManager.client.onmessage = ({ data }) => {
      const msg: WSMessageType = parseJSON(data as any) as WSMessageType;
      ClientWebSocketManager.handleMessage(msg);
    };

    ClientWebSocketManager.client.onclose = () => {
      if (DEBUG) console.log('websocket connection onclose');
      ClientWebSocketManager.closeAndReInit();
    };
  };

  static closeAndReInit = () => {
    if (DEBUG) console.log('close websocket and reinitialize');
    clearTimeout(ClientWebSocketManager.retrySemaphore);
    ClientWebSocketManager.retrySemaphore = null;
    ClientWebSocketManager.init();
  };

  static handleMessage = (message: WSMessageType) => {
    if (DEBUG) console.log('handle websocket message', message);
    if (message.type === 'auth-success' || message.type === 'connect-success') {
      //noop
    } else if (message.type === 'new-inbox-message') {
      const threadId = parseInt(message.meta.threadId) as number;
      ClientWebSocketManager.store.dispatch(refreshInboxAndThread(threadId));
    } else if (message.type === 'new-inbox-thread') {
      ClientWebSocketManager.store.dispatch(refreshInbox());
    } else if (message.type === 'is-typing') {
      const typingIndicator = message.meta as TypingIndicator;
      ClientWebSocketManager.store.dispatch(
        addToIsTypingIndicators({ typingIndicator })
      );
    } else if (message.type === 'is-not-typing') {
      const typingIndicator = message.meta as TypingIndicator;
      ClientWebSocketManager.store.dispatch(
        removeFromIsTypingIndicators({ typingIndicator })
      );
    } else {
      throw Error(
        `Unrecognized websocket message type: ${JSON.stringify(message)}`
      );
    }
  };

  static registerObservingMessageThread(threadId: number) {
    if (DEBUG) console.log(`registerObservingMessageThread ${threadId}`);
    const msg: WSMessageType = { type: 'observe-thread', meta: { threadId } };
    ClientWebSocketManager.sendMessage(msg);
  }

  static unregisterObservingMessageThread(threadId: number) {
    if (DEBUG) console.log(`unregisterObservingMessageThread ${threadId}`);
    const msg: WSMessageType = { type: 'unobserve-thread', meta: { threadId } };
    ClientWebSocketManager.sendMessage(msg);
  }

  static sendTypingIndicator(threadId: number) {
    if (DEBUG) console.log(`sendTypingIndicator ${threadId}`);
    const msg: WSMessageType = { type: 'is-typing', meta: { threadId } };
    ClientWebSocketManager.sendMessage(msg);
  }

  static sendNotTypingIndicator(threadId: number) {
    if (DEBUG) console.log(`sendNotTypingIndicator ${threadId}`);
    const msg: WSMessageType = { type: 'is-not-typing', meta: { threadId } };
    ClientWebSocketManager.sendMessage(msg);
  }

  static sendMessage(msg: WSMessageType) {
    ClientWebSocketManager.client.send(JSON.stringify(msg));
  }
}

export default ClientWebSocketManager;