import { _ } from 'lodash';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import IApplicationState from '../../types/state.types';
import { selectParticipants } from '../participants/participants.selectors';
import { entitySchema } from '../schema';
import {PaymentType} from "../../types/serverTypes/paymentTypes";

export const selectPayments = (state: IApplicationState) => {
  return state.entities.payments;
};

export const getPayments = createSelector(
  [selectPayments, selectParticipants],
  (payments, participants): Optional<PaymentType[]> => {
    if (payments?.allIds?.length && participants?.allIds?.length) {
      const { payments: denormalizedPayments } = denormalize({ payments: payments.allIds }, entitySchema, { payments: payments.byId });

      _.forEach(denormalizedPayments, payment => {
        payment.participant = participants.byId[payment.participantId];
      });
      return toArray(denormalizedPayments);
    }
    return [];
  }
);

export default { getPayments, selectPayments };
