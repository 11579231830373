import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getStudyArms,
  getStudyFeatures,
  updateStudyFeature,
} from '../../../service/studyService';
import {
  StudyArmType,
  StudyFeatureType,
} from '../../../../../server/src/types/studyTypes';
import {
  Card,
  Col,
  message,
  Modal,
  Row,
  Switch,
  Table,
  Typography,
} from 'antd';
import '../../page.scss';
import TextArea from 'antd/lib/input/TextArea';
import { SortOrder } from 'antd/lib/table/interface';
import { parseJSON } from '../../../service/util';

const { Text } = Typography;
const FeatureSetting = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [editableRecord, setEditableRecord] = useState<StudyFeatureType>();
  const queryClient = useQueryClient();

  const features = useQuery<StudyFeatureType[]>('studyFeatures', () =>
    getStudyFeatures()
  );
  const studyArms = useQuery<StudyArmType[]>('studyArms', getStudyArms);

  const featureMutation = useMutation(updateStudyFeature, {
    onError: (error, variables, context) => {
      message.error('Error in updating feature.');
    },
    onSuccess: (data, variables, context) => {
      setIsModalVisible(false);
      queryClient.invalidateQueries('studyFeatures');
      message.success('Feature successfully updated.');
    },
  });

  const showEdit = (record) => {
    // show feature edit screen
    setEditableRecord({
      ...record,
      meta: record.meta ? JSON.stringify(record.meta) : null,
    });
    setIsModalVisible(true);
  };
  const cancelEdit = () => {
    setIsModalVisible(false);
    setEditableRecord(undefined);
  };
  const saveEdit = () => {
    if (editableRecord) {
      let featureData = { ...editableRecord };
      if (editableRecord.meta) {
        try {
          const newMeta = parseJSON(editableRecord.meta);
          featureData = {
            ...editableRecord,
            meta: newMeta,
          };
        } catch (e) {
          message.error('Error in meta data.  Please check the syntax.');
          return;
        }
      }
      featureMutation.mutate(featureData);
    }
  };
  const onDescriptionChange = ({ target: { value } }) => {
    if (editableRecord) {
      setEditableRecord({ ...editableRecord, description: value });
    }
  };
  const onEnabledChange = (checked: boolean) => {
    if (editableRecord) {
      setEditableRecord({ ...editableRecord, enabled: checked });
    }
  };
  const onMetaChange = ({ target: { value } }) => {
    if (editableRecord) {
      setEditableRecord({ ...editableRecord, meta: value });
    }
  };

  const onStudyArmAvailableChange = (
    studyArm: StudyArmType,
    enabled: boolean
  ) => {
    if (editableRecord) {
      if (!enabled) {
        setEditableRecord({
          ...editableRecord,
          studyArmIds: editableRecord.studyArmIds.filter(
            (id) => id !== studyArm.id
          ),
        });
      } else {
        setEditableRecord({
          ...editableRecord,
          studyArmIds: editableRecord.studyArmIds.concat(studyArm.id),
        });
      }
    }
  };

  const columns = [
    {
      title: 'Feature',
      dataIndex: 'feature',
      key: 'feature',
      render: (feature, record) => (
        <a onClick={(e) => showEdit(record)}>{feature}</a>
      ),
      sorter: (a, b) => a.feature.localeCompare(b.feature),
      defaultSortOrder: 'ascend' as SortOrder,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description, record) =>
        record.enabled ? (
          <Text>{description}</Text>
        ) : (
          <Text disabled>{description}</Text>
        ),
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      key: 'enabled',
      render: (enabled) => (enabled ? 'Yes' : 'No'),
      sorter: (a, b) => (a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1),
      defaultSortOrder: 'descend' as SortOrder,
    },
    {
      title: 'Additional Data',
      dataIndex: 'meta',
      key: 'meta',
      width: 200,
      render: (meta) =>
        meta ? (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {JSON.stringify(meta)}
          </div>
        ) : (
          ''
        ),
    },
    {
      title: 'Available for Study Arm',
      dataIndex: 'studyArmIds',
      key: 'studyArmIds',
      render: (studyArmIds) => (
        <>
          {studyArmIds.map((armId, index) => {
            if (studyArms) {
              const studyArmData = studyArms.data?.find(
                (armData) => armData.id === armId
              );
              if (studyArmData) {
                return <div key={index}>{studyArmData.name}</div>;
              }
            }
          })}
        </>
      ),
    },
  ];
  let content;
  if (features && features.data) {
    content = (
      <Table
        dataSource={features.data}
        columns={columns}
        pagination={false}
        rowKey="featureId"
      />
    );
  } else {
    content = <div>Loading...</div>;
  }

  return (
    <>
      <Card
        title={
          <>
            <div className="page-title">
              <h1>Features</h1>
            </div>
            <div>
              <Text type="danger">
                Please be careful with any changes!! Features should not be
                changed once the study starts.
              </Text>
            </div>
            <div>
              <Text type="warning">
                If a study arm does not have any features allowed, participants
                of that study arm will not have access to mobile app.
              </Text>
            </div>
          </>
        }
      >
        {content}
      </Card>
      <Modal
        title={editableRecord?.feature}
        visible={isModalVisible}
        onOk={saveEdit}
        onCancel={cancelEdit}
        okText="Save"
      >
        <Row className="marginBottom">
          <Col span={8}>Description</Col>
          <Col span={16}>
            <TextArea
              rows={2}
              value={editableRecord?.description}
              onChange={onDescriptionChange}
            />
          </Col>
        </Row>
        <Row className="marginBottom">
          <Col span={8}>Enabled</Col>
          <Col span={16}>
            <Switch
              checked={editableRecord?.enabled}
              onChange={onEnabledChange}
            />
          </Col>
        </Row>
        <Row className="marginBottom">
          <Col span={8}>Additional Data</Col>
          <Col span={16}>
            <TextArea
              rows={2}
              value={editableRecord?.meta}
              onChange={onMetaChange}
            />
          </Col>
        </Row>
        <Row className="marginBottom">
          <Col span={8}>Study Arm</Col>
          <Col span={16}>
            {studyArms &&
              studyArms.data &&
              editableRecord &&
              studyArms.data.map((studyArm, index) => {
                let available = false;
                if (
                  editableRecord.studyArmIds &&
                  editableRecord.studyArmIds.findIndex(
                    (id) => id === studyArm.id
                  ) > -1
                ) {
                  available = true;
                }
                return (
                  <Row key={index}>
                    <Col span={8}>{studyArm.name}</Col>
                    <Col span={16}>
                      <Switch
                        checked={available}
                        onChange={(checked) =>
                          onStudyArmAvailableChange(studyArm, checked)
                        }
                      ></Switch>
                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default FeatureSetting;
