import { SaveFilled } from '@ant-design/icons';
import { Row, Col, Form, Checkbox, Button, Input, Typography } from 'antd';
import { renderDateWithTime } from '../util/Util';
import React from 'react';
import { AvatarPartType } from '../../types/serverTypes/avatarTypes';
const { Text } = Typography;
interface AvatarCustomPartEditProp {
  selectedPart: AvatarPartType;
  baseBody: string[];
  onUpdateAvatarPart: (data: any) => void;
  onSaveAvatarPart: () => void;
}

const AvatarCustomPartEdit = (props: AvatarCustomPartEditProp) => {
  const getCompositePart = () => {
    if (props.baseBody && props.baseBody.length > 0) {
      return props.baseBody.map((part, index) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: part,
            }}
            className="avatar-detail-svg"
            key={index}
          ></div>
        );
      });
    }
    return undefined;
  };

  return (
    <Row wrap>
      <Col>
        <Row wrap>
          <Col>
            <div>Main:</div>
            <div
              dangerouslySetInnerHTML={{
                __html: props.selectedPart.svg,
              }}
              className="avatar-selected-svg"
              key={`selected-avatar`}
            ></div>
          </Col>
          {!!props.selectedPart.secondarySvg && (
            <Col>
              <div>Secondary:</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: props.selectedPart.secondarySvg,
                }}
                className="avatar-secondary-svg"
              ></div>
            </Col>
          )}
          <Col>
            <div style={{ marginLeft: 20 }}>
              <div>Id: {props.selectedPart.id}</div>
              <Form
                name="basic"
                initialValues={{
                  name: props.selectedPart.name,
                }}
                autoComplete="off"
              >
                <Form.Item label="Name">
                  <Input
                    value={props.selectedPart.name}
                    onChange={(e) => {
                      props.onUpdateAvatarPart({ name: e.target.value });
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    onChange={(e) => {
                      props.onUpdateAvatarPart({ isBaseSet: e.target.checked });
                    }}
                    checked={props.selectedPart.isBaseSet}
                  >
                    Is Base Set?
                    <br />
                    Given to participant without earning any point
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    onChange={(e) => {
                      props.onUpdateAvatarPart({
                        isBaseSetDefaultApplied: e.target.checked,
                      });
                    }}
                    checked={props.selectedPart.isBaseSetDefaultApplied}
                  >
                    Is Base Set Default Applied?
                    <br /> Applied on the avatar
                  </Checkbox>
                </Form.Item>
              </Form>
              {props.selectedPart.deleteDate && (
                <div>
                  Deleted date:{' '}
                  {renderDateWithTime(props.selectedPart.deleteDate)}
                </div>
              )}
              <Button
                type="primary"
                onClick={props.onSaveAvatarPart}
                icon={<SaveFilled />}
                style={{ marginRight: 15 }}
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
        <Row wrap style={{ marginTop: 20 }}>
          <div>Composite View of Selected Parts:</div>
          <Col span={24}>{getCompositePart()}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AvatarCustomPartEdit;
