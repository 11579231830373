import { FlagResolutions } from 'constant/serverConstants/socialConstants';
import axios from '../../redux/api';

export const resolveFlag = async ({flagId, resolution}: {flagId: number, resolution: FlagResolutions}) => {
    const data = {
      ids: [flagId],
      resolution,
    };
  
    await axios({
      method: 'post',
      url: `/a/social/flag/resolve`,
      data,
    });
  };
  