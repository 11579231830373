import { AnyAction } from 'redux';
import getAsyncActionName from './api.util';
import IApiState from '../../types/api.types';
import { getType } from 'typesafe-actions';
import { clearStatus } from './api.types';

export const apiReducer = (state: IApiState = {}, action: AnyAction): IApiState => {
  const { type, payload } = action;
  
  if (type === getType(clearStatus)) {
    const actionName: Optional<string> = getAsyncActionName(payload);
    if(!actionName) {
      return {
        ...state
      };
    }
    return {
      ...state,
      [actionName]: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        errorStatus: undefined,
        errorMessage: undefined,
        params: {},
        requestedAt: new Date(),
        lastUpdated: new Date()
      }
    };
  }
  const actionName: Optional<string> = getAsyncActionName(type);

  if (!actionName) {
    return {
      ...state
    };
  }

  if (type.endsWith('_REQUEST')) {
    return {
      ...state,
      [actionName]: {
        isLoading: true,
        isError: false,
        isSuccess: false,
        errorStatus: undefined,
        errorMessage: undefined,
        params: cleanPayload(action.payload),
        requestedAt: new Date()
      }
    };
  }

  if (type.endsWith('_SUCCESS')) {
    return {
      ...state,
      [actionName]: {
        ...state[actionName],
        isLoading: false,
        isError: false,
        isSuccess: true,
        lastUpdated: new Date()
      }
    };
  }

  if (type.endsWith('_FAILURE')) {

    const error = action.payload ? action.payload : action;
    let errorStatus: number;
    let errorMessage: string;

    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      errorStatus = error.response.status;
      errorMessage = error.response.statusText;

    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      errorStatus = 400;
      errorMessage = error.message ? error.message : 'Bad Request';
    } else {
      // Something happened in setting up the request and triggered an Error
      errorStatus = 500;
      errorMessage = error.message ? error.message : 'Unknown Server Error';
    }

    return {
      ...state,
      [actionName]: {
        ...state[actionName],
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorStatus,
        errorMessage,
        lastUpdated: new Date()
      }
    };

  }

  return {
    ...state
  };
};

const cleanPayload = <T extends {[key: string]: string}>(payload: T): T => {

  if (typeof payload !== 'object') {
    return payload;
  }

  if (payload && payload.hasOwnProperty('password')) {

    return Object.assign({ password: 'XXXXXXXXXXXX' }, payload);
  }
  return payload;
};

export default apiReducer;
