import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import {PostType} from "../../types/serverTypes/forumTypes";

export const getParticipantPostsAsync = createAsyncAction(
  'hmp/admin/GET_PARTICIPANT_POSTS_REQUEST',
  'hmp/admin/GET_PARTICIPANT_POSTS_SUCCESS',
  'hmp/admin/GET_PARTICIPANT_POSTS_FAILURE'
)<number, Optional<NormalizedType<PostType>>, Error>();

export const initialGetPostsSearch = {
  searchText: undefined,
  participantId: undefined,
  studyArmIds: [],  //must be set by initializing component
  pageNumber: 0,
  pageSize: 10,
  sortBy: 'createDate',
  sortDirection: 'desc',
  previewLength: 100,
  includeDeleted: true,
  includeUnpublished: true
};
export type GetPostsSearchType = typeof initialGetPostsSearch;

export const getPostsAsync = createAsyncAction(
  'hmp/admin/GET_STUDY_POSTS_REQUEST',
  'hmp/admin/GET_STUDY_POSTS_SUCCESS',
  'hmp/admin/GET_STUDY_POSTS_FAILURE'
)<GetPostsSearchType, Optional<NormalizedType<PostType>>, Error>();

export const getUncategorizedPostsAsync = createAsyncAction(
  'hmp/admin/GET_UNCATEGORIZED_POSTS_REQUEST',
  'hmp/admin/GET_UNCATEGORIZED_POSTS_SUCCESS',
  'hmp/admin/GET_UNCATEGORIZED_POSTS_FAILURE'
)<void, Optional<NormalizedType<PostType>>, Error>();

export const getFlaggedPostsAsync = createAsyncAction(
  'hmp/admin/GET_FLAGGED_POSTS_REQUEST',
  'hmp/admin/GET_FLAGGED_POSTS_SUCCESS',
  'hmp/admin/GET_FLAGGED_POSTS_FAILURE'
)<void, Optional<NormalizedType<PostType>>, Error>();

export const savePostAsync = createAsyncAction(
  'hmp/admin/SAVE_POST_REQUEST',
  'hmp/admin/SAVE_POST_SUCCESS',
  'hmp/admin/SAVE_POST_FAILURE'
)<PostType, Optional<NormalizedType<PostType>>, Error>();

export const deletePostAsync = createAsyncAction(
  'hmp/admin/DELETE_POST_REQUEST',
  'hmp/admin/DELETE_POST_SUCCESS',
  'hmp/admin/DELETE_POST_FAILURE'
)<number, Optional<NormalizedType<PostType>>, Error>();

export const updatePosts = createStandardAction('hmp/admin/UPDATE_POSTS')<Optional<NormalizedType<PostType>>>();
export const clearPosts = createStandardAction('hmp/admin/CLEAR_POSTS')<void>();

const postsActions = {
  getParticipantPostsAsync,
  getPostsAsync,
  getUncategorizedPostsAsync,
  getFlaggedPostsAsync,
  updatePosts,
  savePostAsync,
  deletePostAsync,
  clearPosts
};

export type PostsActionTypes = ActionType<typeof postsActions>;
