import {getType} from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  BadgesActionTypes,
  deleteBadgeGroupAsync,
  getBadgeGroupsAsync,
  saveBadgeGroupAsync,
  undeleteBadgeGroupAsync
} from './badges.types';
import {initialNormalizedState, NormalizedState} from '../../types/state.types';
import {BadgeType} from "../../types/serverTypes";

const updateBadgeGroupsReducer = produce((draft, badges) => {
  if (!badges) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(badges), Number));
  const ids = Object.keys(badges);
  for (const id of ids) {
    draft.byId[id] = badges[id];
  }
});

export const badgeGroupsReducer = (state: NormalizedState<BadgeType> = initialNormalizedState, action: BadgesActionTypes) => {
  switch (action.type) {
    case getType(getBadgeGroupsAsync.success):
    case getType(deleteBadgeGroupAsync.success):
    case getType(undeleteBadgeGroupAsync.success):
    case getType(saveBadgeGroupAsync.success):
      return updateBadgeGroupsReducer(state, action.payload);
      break;
    default:
      return state;
  }
};

export default badgeGroupsReducer;
