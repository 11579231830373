import {
  ActionType,
  createAsyncAction,
  createStandardAction,
} from "typesafe-actions";
import { NormalizedType } from "../../types/state.types";
import {GoalType} from "../../types/serverTypes/goalTypes";

export interface GetGoalsArguments {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  includeDeleted?: boolean;
  includeUnpublished?: boolean;
}

export const getGoalsAsync = createAsyncAction(
  "hmp/admin/GET_GOALS_REQUEST",
  "hmp/admin/GET_GOALS_SUCCESS",
  "hmp/admin/GET_GOALS_FAILURE"
)<GetGoalsArguments, Optional<NormalizedType<GoalType>>, Error>();

export const getGoalAsync = createAsyncAction(
  "hmp/admin/GET_GOAL_REQUEST",
  "hmp/admin/GET_GOAL_SUCCESS",
  "hmp/admin/GET_GOAL_FAILURE"
)<string, Optional<NormalizedType<GoalType>>, Error>();

export const createGoalAsync = createAsyncAction(
  "hmp/admin/CREATE_GOAL_REQUEST",
  "hmp/admin/CREATE_GOAL_SUCCESS",
  "hmp/admin/CREATE_GOAL_FAILURE"
)<GoalType, Optional<NormalizedType<GoalType>>, Error>();

export const updateGoalAsync = createAsyncAction(
  "hmp/admin/UPDATE_GOAL_REQUEST",
  "hmp/admin/UPDATE_GOAL_SUCCESS",
  "hmp/admin/UPDATE_GOAL_FAILURE"
)<GoalType, Optional<NormalizedType<GoalType>>, Error>();

export const deleteGoalAsync = createAsyncAction(
  "hmp/admin/DELETE_GOAL_REQUEST",
  "hmp/admin/DELETE_GOAL_SUCCESS",
  "hmp/admin/DELETE_GOAL_FAILURE"
)<string, Optional<NormalizedType<GoalType>>, Error>();

export const updateGoals = createStandardAction("hmp/admin/UPDATE_GOALS")<
  Optional<NormalizedType<GoalType>>
>();

export const clearGoals = createStandardAction("hmp/admin/CLEAR_GOALS")<void>();

const goalsActions = {
  getGoalsAsync,
  getGoalAsync,
  createGoalAsync,
  updateGoalAsync,
  deleteGoalAsync,
  updateGoals,
  clearGoals,
};

export type GoalsActionTypes = ActionType<typeof goalsActions>;
