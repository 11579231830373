import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Input, InputNumber, Modal } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { createStructuredSelector } from 'reselect';
import IApplicationState from '../../types/state.types';
import * as selectors from '../../redux/selectors';
import { saveBadgeGroupAsync } from '../../redux/badges/badges.types';

import './badge.scss';
import { BadgeGroupType } from '../../types/serverTypes';

const { confirm } = Modal;

interface StateProps { }

interface DispatchProps {
  saveBadgeGroup: typeof saveBadgeGroupAsync.request
}

interface ComponentProps extends StateProps, DispatchProps {
  visible: boolean,
  hide: Function,
  badgeGroup: Optional<BadgeGroupType>
}

const initialState = { };

type ComponentState = typeof initialState;

class AddBadgeGroupModal extends Component<ComponentProps, ComponentState> {
  readonly state = initialState;

  form = React.createRef<FormInstance>();

  hide = () => {
    this.props.hide();
  }

  onFinish = (values: any) => {
    if (this.props.badgeGroup?.id) {
      Object.assign(values, { id: this.props.badgeGroup.id });
    }
    this.props.saveBadgeGroup(values);
    this.props.hide();
  };

  handleOk = () => {
    if (this.form?.current) {
      this.form.current
        .validateFields()
        .then(() => {
          confirm({
            title: 'Are you sure you want to create this badge collection?',
            content: '',
            okText: 'Confirm',
            onOk: () => {
              this.onFinish(this.form.current?.getFieldsValue());
            },
            onCancel() {}
          });
        });
    }
  }

  handleCancel = () => {
    confirm({
      title: 'Are you sure you want to leave this badge collection?',
      content: 'You will lose all changes',
      okText: 'Leave',
      okType: 'danger',
      onOk: () => {
        this.hide();
      },
      onCancel() {}
    });
  }

  render() {
    const { visible, badgeGroup } = this.props;
    const { } = this.state;

    return (
      <Modal
        className=""
        title="Add Badge Group"
        width="45%"
        visible={visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        destroyOnClose
      >
        <Form
          ref={this.form}
          key="form"
          initialValues={badgeGroup}
          onFinish={this.onFinish}
        >
          <Form.Item label="Label" name="label" rules={[{ required: true }]}>
            <Input placeholder="This is the label the user's will see on the badge" />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <Input placeholder="This is the Description the user's will see on the badge" />
          </Form.Item>
          <Form.Item label="Sequence" name="sequence" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  badges: selectors.getBadges,
  badgeGroup: selectors.getRequestedBadgeGroup
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    saveBadgeGroup: (badgeGroup: BadgeGroupType) => dispatch(saveBadgeGroupAsync.request(badgeGroup))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddBadgeGroupModal);
