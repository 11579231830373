import * as React from 'react';
import { _ } from 'lodash';
import { Popover } from 'antd';
import moment from 'moment';
import { StudyArmType } from '../../types/serverTypes/studyTypes';

export function renderDateWithTime(date: any) {
  return date ? moment(date).format('YYYY-MM-DD h:mm:ss a') : 'N/A';
}

export function renderDateWithoutTime(date: any) {
  return date ? moment(date).format('YYYY-MM-DD') : 'N/A';
}

export function renderPopoverDate(date: any) {
  return (
    <Popover content={renderDateWithTime(date)}>
      {renderDateWithoutTime(date)}
    </Popover>
  );
}

export function renderDateCalendar(date: any) {
  return date ? moment(date).local().calendar() : 'N/A';
}

export function renderDateCalendarWithTime(date: any) {
  return moment(date).local().calendar(null, {
    sameElse: 'DD/MM/YYYY [at] HH:mm A',
  });
}

export function renderDateCalendarWithoutTime(date: any) {
  return moment(date).local().calendar(null, {
    sameDay: 'dddd, MMMM Do',
    nextDay: 'dddd, MMMM Do',
    nextWeek: 'dddd, MMMM Do',
    lastDay: 'dddd, MMMM Do',
    lastWeek: 'dddd, MMMM Do',
    sameElse: 'dddd, MMMM Do',
  });
}

export function renderPopoverDateCalendar(date: any) {
  return (
    <Popover content={renderDateWithTime(date)}>
      {renderDateCalendar(date)}
    </Popover>
  );
}

export function renderDateWithTimeTwelveHourPeriod(date: any) {
  return date ? moment(date).format('YYYY-MM-DD hh:mma') : 'N/A';
}

export function renderDateFromNow(date: any) {
  return moment(date).fromNow();
}

export function renderPopoverDateFromNow(date: any) {
  return (
    <Popover content={renderDateWithTime(date)}>
      {renderDateFromNow(date)}
    </Popover>
  );
}

/**
 * Adjusts body text before it is saved to database
 * @param body String for the editor content
 */
export function updateCmsEditorBeforeSave(body: string) {
  if (body) {
    const updatedBody = body.replace(/hmp%3A/g, 'hmp:');
    return updatedBody;
  }

  return body;
}

export function getStudyArmLabelFromId(
  studyArms: Optional<StudyArmType[]>,
  studyArmId: number | string
): string {
  if (!studyArms) return '';
  const studyArm: StudyArmType | undefined = _.find(
    studyArms,
    (sa: StudyArmType) => sa.id == studyArmId
  );
  return studyArm!.name;
}

export function camelCaseToSentenceCase(str: string) {
  if (!str) return '';
  const result = str.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export function isBlank(value: string) {
  return !value || value.trim().length === 0;
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(
  obj: O
): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}
