import React, { useEffect, useState } from 'react';
import {
  Button,
  Empty,
  Form,
  Input,
  message,
  Popconfirm,
  Typography,
  Upload,
} from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { CompactPicker } from 'react-color';
import { TopicType } from '../../../../types/serverTypes/forumTypes';
import { useDeleteTopic, useUpdateTopic } from '../queries';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import IApplicationState from '../../../../types/state.types';
import * as selectors from '../../../../redux/selectors';
import { UserState } from 'redux-oidc';

const { Text } = Typography;

interface TopicDetailProps {
  topic?: TopicType;
  oidc: UserState;
  onChange: (hasChange: boolean) => void;
  onDelete: () => void;
}
const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 10, span: 12 },
};
const TopicDetail = (props: TopicDetailProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>(
    props.topic?.backgroundImageUri || ''
  );
  const [backgroundColor, setBackgroundColor] = useState<string>(
    props.topic?.backgroundColor || ''
  );
  const [fields, setFields] = useState<any>([]);
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const updateTopic = useUpdateTopic();
  const deleteTopic = useDeleteTopic();

  const convertTopicToFields = () => {
    return [
      { name: 'title', value: props.topic?.title },
      { name: 'id', value: props.topic?.id },
      { name: 'background_color', value: props.topic?.backgroundColor },
      { name: 'background_image_uri', value: props.topic?.backgroundImageUri },
    ];
  };
  useEffect(() => {
    setBackgroundColor(props.topic?.backgroundColor || '');
    setImageUrl(props.topic?.backgroundImageUri || '');
    setFields(convertTopicToFields());

    if (props.topic) {
      if (props.topic.children && props.topic.children.length > 0) {
        let isUsed = false;
        for (const topic of props.topic.children) {
          if (topic.articleCount != null && topic.articleCount > 0) {
            isUsed = true;
            break;
          }
        }
        setCanDelete(!isUsed);
      } else {
        setCanDelete(props.topic.articleCount === 0);
      }
    } else {
      setCanDelete(false);
    }
  }, [props.topic]);

  useEffect(() => {
    setFields(convertTopicToFields());
  }, []);

  const onFinish = async (values: any) => {
    const updatedTopic = {
      ...props.topic,
      id: props.topic?.id,
      title: values.title,
      backgroundColor,
      backgroundImageUri: imageUrl,
    };
    props.onChange(false);
    updateTopic.mutate(updatedTopic, {
      onSuccess: () => {
        message.success('Topic successfully updated.');
      },
      onError: () => {
        message.error('Error updating topic.');
      },
    });
  };
  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      message.error('Image must be smaller than 3MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      const uploadResponse = info.file.response;
      props.onChange(true);
      setLoading(false);
      setImageUrl(`/api${uploadResponse.link}`);
      getBase64(info.file.originFileObj, (imageUrl: any) => {});
    }
  };
  const handleBackgroundColorChange = (color: any) => {
    setBackgroundColor(color.hex);
    props.onChange(true);
  };
  const deleteBackgroundImage = () => {
    setImageUrl('');
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const onTitleChange = (e: any) => {
    const { value } = e.target;
    props.onChange(true);
  };
  const onDelete = async () => {
    props.onDelete();
    if (props.topic?.id != null) {
      deleteTopic.mutate(props.topic.id, {
        onSuccess: () => {
          message.success('Deleted successfully.');
        },
      });
    }
  };
  return (
    <div style={{ padding: 10 }}>
      <h3>Topic Detail</h3>
      {!props.topic && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Select a topic from left to see detail"
        />
      )}
      {!!props.topic && (
        <Form {...layout} name="basic" onFinish={onFinish} fields={fields}>
          {props.topic.id && (
            <Form.Item label="ID" name="id">
              <Text
                title={
                  props.topic.parentTopicId
                    ? `Parent ID: ${props.topic.parentTopicId}`
                    : undefined
                }
              >
                {props.topic.id}
              </Text>
            </Form.Item>
          )}
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Please put title' }]}
          >
            <Input maxLength={100} onChange={onTitleChange} />
          </Form.Item>
          <Form.Item label="Background Image">
            <Upload
              name="file"
              listType="picture-card"
              showUploadList={false}
              action="/api/a/upload/topic"
              beforeUpload={beforeUpload}
              onChange={handleChange}
              headers={{
                Authorization: `Bearer ${props.oidc.user?.access_token}`,
              }}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="Background Image"
                  style={{ width: '100%', backgroundColor }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
            {imageUrl && (
              <Popconfirm
                title="Are you sure to delete background image?"
                onConfirm={deleteBackgroundImage}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined style={{ fontSize: 18 }} />
              </Popconfirm>
            )}
          </Form.Item>
          <Form.Item label="Background Color">
            <div>
              <CompactPicker
                color={backgroundColor}
                onChangeComplete={handleBackgroundColorChange}
              />
            </div>
            {!!backgroundColor && (
              <div style={{ marginTop: 10 }}>
                <Popconfirm
                  title="Are you sure to delete background color?"
                  onConfirm={() => setBackgroundColor('')}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined style={{ fontSize: 18 }} />
                </Popconfirm>
              </div>
            )}
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: 10 }}
            >
              Save
            </Button>
            {canDelete && (
              <Popconfirm
                title="Are you sure to delete this topic?"
                onConfirm={onDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button type="default">Delete</Button>
              </Popconfirm>
            )}
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector<IApplicationState, any>({
  oidc: selectors.selectOidc,
});

export default connect(mapStateToProps, {})(TopicDetail);
