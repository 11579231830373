import React, { Component } from 'react';
import moment from 'moment';
import { Button, Descriptions, Modal } from 'antd';
import SecureImage from '../../components/image/SecureImage';
import { TestkitType } from '../../types/serverTypes/testkitTypes';

const { Item } = Descriptions;

interface StateProps {
}

interface DispatchProps {
}

interface ComponentProps extends StateProps, DispatchProps {
  testkit: TestkitType
}

class TestkitRequestedCard extends Component<ComponentProps, {}> {
  readonly state = {
    trackingNumber: null,
    modalVisible: false
  }

  componentDidMount() {
  }

  onChange = (state: any) => {
    this.setState(state);
  }

  handleTrackingNumberChange(event: any) {
    this.setState({
      trackingNumber: event.target.value
    });
  }

  render() {
    const { testkit } = this.props;
    const { participant } = testkit;

    if (!participant) {
      return <div>Participant Not Found.</div>;
    }

    return (
      <Descriptions layout="vertical" column={testkit.type.match(/oraquick/i) ? 7 : 5} colon={false}>
        <Item label="Fulfillment:" span={1}>
          <span>{`${testkit.firstName} ${testkit.lastName}`}</span>
          <br />
          <span>{`${testkit.address1}`}</span>
          <br />
          {testkit.address2 ? (
            <span>
              {`${testkit.address2}`}
              <br />
            </span>
          ) : ''}
          <span>{`${testkit.city}, ${testkit.state} ${testkit.postalCode}`}</span>
          <br />
        </Item>
        <Item label="Tracking ID:"><span><a href={`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${testkit.trackingNumber}`}>{testkit.trackingNumber}</a></span></Item>
        <Item label="Delivered Date:"><span>{testkit.deliveredDate && testkit.receivedDate ? moment(testkit.deliveredDate).calendar() : 'Kit not yet delivered according to USPS.'}</span></Item>
        <Item label="Report Date:"><span>{moment(testkit.reportDate).calendar()}</span></Item>
        {testkit.type.match(/oraquick/i)
          ? <Item label="Reported Result:"><span>{testkit.reportedResult}</span></Item>
          : undefined}
        {testkit.type.match(/oraquick/i)
          ? (
            <Item label="Actual Result:">
              <span>{testkit.actualResult}</span>
              <div>
                <Button type="primary" onClick={(e) => { this.onChange({ modalVisible: true }); }} style={{ marginTop: '5px' }}>View Image</Button>
                <Modal
                  bodyStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  centered
                  closable={false}
                  visible={this.state.modalVisible}
                  onOk={() => this.onChange({ modalVisible: false })}
                  onCancel={() => this.onChange({ modalVisible: false })}
                >
                  <SecureImage url={`/a/testkit/${testkit.participantId}/${testkit.id}/image`} alt="OraQuick test kit." />
                </Modal>
              </div>
            </Item>
          )
          : undefined}
        <Item label="Completed Date:"><span>{moment(testkit.completedDate).calendar()}</span></Item>

      </Descriptions>
    );
  }
}

export default TestkitRequestedCard;
