import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import IApplicationState from '../../types/state.types';
import { selectParticipants } from '../participants/participants.selectors';
import { entitySchema } from '../schema';
import {FavoriteType} from "../../types/serverTypes/forumTypes";

export const selectFavorites = (state: IApplicationState) => {
  return state.entities.favorites;
};

export const getFavorites = createSelector(
  [selectFavorites, selectParticipants],
  (favorites, participants): Optional<FavoriteType[]> => {
    if (favorites && favorites.allIds && favorites.allIds.length > 0) {
      const { favorites: denormalizedFavorites } = denormalize({ favorites: favorites.allIds }, entitySchema, { favorites: favorites.byId, participants: participants.byId });
      return toArray(denormalizedFavorites);
    }
    return undefined;
  }
);

export const getQnaFavorites = createSelector(
  [selectFavorites, selectParticipants],
  (favorites, participants): Optional<FavoriteType[]> => {
    if (favorites && favorites.allIds && favorites.allIds.length > 0) {
      const { favorites: denormalizedFavorites } = denormalize({ favorites: favorites.allIds }, entitySchema, { favorites: favorites.byId, participants: participants.byId });
      const qnaFavorites = toArray(denormalizedFavorites).filter(f => f.type === 'qna');
      return qnaFavorites;
    }
    return undefined;
  }
);

export const getCommentFavorites = createSelector(
  [selectFavorites, selectParticipants],
  (favorites, participants): Optional<FavoriteType[]> => {
    if (favorites && favorites.allIds && favorites.allIds.length > 0) {
      const { favorites: denormalizedFavorites } = denormalize({ favorites: favorites.allIds }, entitySchema, { favorites: favorites.byId, participants: participants.byId });
      const qnaFavorites = toArray(denormalizedFavorites).filter(f => f.type === 'comment');
      return qnaFavorites;
    }
    return undefined;
  }
);

export default { getFavorites };
