import {
  Button, Checkbox, Collapse, Form, Input, Modal, Select, Table
} from 'antd';
import * as _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import Username from '../../../components/username/Username';
import { renderDateWithoutTime } from '../../../components/util/Util';
import { getPaymentsAsync, markPaidAsync } from '../../../redux/payment/payment.types';
import * as selectors from '../../../redux/selectors';
import IApplicationState from '../../../types/state.types';
import { MarkPaidType } from '../../../types';
import { PaymentType } from '../../../types/serverTypes/paymentTypes';
import { ParticipantType } from '../../../types/serverTypes/studyTypes';

const { Panel } = Collapse;
const { Option } = Select;
const { Column } = Table;

interface StateProps {
  payments: Optional<PaymentType[]>,
  participants: Optional<ParticipantType[]>
}

interface DispatchProps {
  getPayments: typeof getPaymentsAsync.request,
  markPaid: typeof markPaidAsync.request
}

interface ComponentProps extends DispatchProps, StateProps {
  // type: string
}

const initialState = {
  checked: {},
  payCheckedModalVisible: false,
  trackingInfo: ''
};

type ComponentState = Readonly<typeof initialState>;

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

class PaymentsLandingPage extends Component<ComponentProps, ComponentState> {
  readonly state: ComponentState = initialState;

  private payAll() {
    this.setState({ payCheckedModalVisible: true });
  }

  private markPaid() {
    const ids: number[] = _.flatten(_.map(_.values(this.state.checked), val => _.keys(val).map(id => parseInt(id))));

    const markPaidObj: MarkPaidType = {
      trackingInfo: this.state.trackingInfo,
      incentivePaymentIds: ids
    };

    this.props.markPaid(markPaidObj);
    this.setState({ checked: {}, payCheckedModalVisible: false, trackingInfo: '' });
  }

  componentDidMount(): void {
    this.props.getPayments();
  }

  showPayCheckedModal = () => {
    this.setState({
      payCheckedModalVisible: true
    });
  };

  paidRenderer(date:any, payment: PaymentType) {
    const { checked } = this.state;
    const chk = !!(checked && checked[payment.label] && checked[payment.label][payment.incentivePaymentId]);

    return (date
      ? moment(date).format('YYYY-MM-DD HH:SSa')
      : (
        <Checkbox
          checked={chk}
          onChange={(v) => {
            const updatedChecked: any = _.cloneDeep(this.state.checked);
            if (v.target.checked) {
              if (!updatedChecked[payment.label]) {
                updatedChecked[payment.label] = {};
              }
              updatedChecked[payment.label][payment.incentivePaymentId] = true;
            } else {
              delete updatedChecked[payment.label][payment.incentivePaymentId];
              if (_.isEmpty(updatedChecked[payment.label])) {
                delete updatedChecked[payment.label];
              }
            }
            // console.log(JSON.stringify(updatedChecked));
            this.setState({ checked: updatedChecked });
          }}
        />
      )
    );
  }

  private checkAll() {
    const { payments } = this.props;
    const checked = _.reduce(payments, (checked:object, payment:PaymentType) => {
      if (payment.paidDate) return checked;
      if (!checked[payment.label]) {
        checked[payment.label] = {};
      }
      checked[payment.label][payment.incentivePaymentId] = true;
      return checked;
    }, {});
    this.setState({ checked });
  }

  renderUsername = (value: string) => {
    const { participants } = this.props;
    if (participants) {
      const participant = participants.find(p => p.username === value);
      return participant ? <Username participantId={participant.id} /> : value;
    }
    return value;
  };

  render() {

    const { trackingInfo } = this.state;
    const { payments } = this.props;

    const paymentsById = _.groupBy(payments, 'incentivePaymentId');

    return (
      <div>
        <div>
          <div className="actions">
            <Button type="primary" onClick={() => this.checkAll()}>Check All</Button>
            &nbsp;
            <Button type="primary" aria-disabled={_.isEmpty(this.state.checked)} onClick={() => this.payAll()}>Process Payment</Button>
          </div>
          <br />
          <Table
            {...this.state}
            dataSource={payments}
            rowClassName={rowClassName}
            rowKey="incentivePaymentId"
            scroll={{ y: 'calc(100vh - 200px)' }}
            pagination={false}
          >
            <Column
              title="ID"
              dataIndex="incentivePaymentId"
              key="incentivePaymentId"
              sorter={(a: any, b: any) => a.incentivePaymentId - b.incentivePaymentId}
            />
            <Column
              title="Label"
              dataIndex="label"
              key="label"
              sorter={(a: any, b: any) => `${a.label}`.localeCompare(b.label)}
              filters={_.map(_.sortedUniq(_.uniq(_.sortBy(_.map(payments, 'label')))), x => { return { text: x, value: x }; })}
              onFilter={(value, record) => record.label === value}
            />
            <Column
              title="Username"
              dataIndex="participant.username"
              key="username"
              render={this.renderUsername}
              sorter={(a: any, b: any) => `${a.participant.username}`.localeCompare(b.participant.username)}
              filters={_.map(_.sortedUniq(_.uniq(_.sortBy(_.map(payments, 'participant.username')))), x => { return { text: x, value: x }; })}
              onFilter={(value, record) => record.participant.username === value}
            />
            <Column
              title="Amount"
              dataIndex="amount"
              key="amount"
              render={(amount:any) => `$${amount}`}
              sorter={(a: any, b: any) => a.amount - b.amount}
            />
            <Column
              title="Create Date"
              dataIndex="createDate"
              key="createDate"
              render={renderDateWithoutTime}
              sorter={(a: any, b: any) => moment(a.createDate).unix() - moment(b.createDate).unix()}
            />
            <Column
              title="Paid"
              dataIndex="paidDate"
              key="paidDate"
              render={this.paidRenderer.bind(this)}
              defaultSortOrder="descend"
              sorter={(a: any, b: any) => {
                if (!a.paidDate && !b.paidDate) return 0;
                if (!a.paidDate && b.paidDate) return 1;
                if (a.paidDate && !b.paidDate) return -1;
                return moment(a.paidDate).unix() - moment(b.paidDate).unix();
              }}
              filters={[{ text: 'unpaid', value: 'unpaid' }]}
              onFilter={(value, record) => value === 'unpaid' && !record.paidDate}
            />
            <Column
              title="Order Number"
              dataIndex="trackingInfo"
              key="trackingInfo"
              sorter={(a:any, b: any) => `${a.trackingInfo}`.localeCompare(b.trackingInfo)}
            />
          </Table>

        </div>
        <Modal
          title="Process Payment"
          visible={this.state.payCheckedModalVisible}
          okText="Mark Paid"
          onOk={() => this.markPaid()}
          okButtonProps={{ disabled: _.isEmpty(this.state.trackingInfo) }}
          onCancel={() => { this.setState({ payCheckedModalVisible: false, trackingInfo: '' }); }}
        >
          <div>
            {_.keys(this.state.checked).map((key:string) => (
              <div key={key}>
                <h4>
                  {key}
                  {' '}
                  $
                  {(_.find(payments, p => p.label === key)).amount}
                </h4>
                <p>{_.keys(this.state.checked[key]).map(id => paymentsById[id][0].participant.email).join(', ')}</p>
              </div>
            ))}
            <br />
            <Form.Item label="Order Number">
              <Input
                allowClear
                value={trackingInfo}
                style={{ width: 320 }}
                onChange={v => {
                  this.setState({ trackingInfo: v.target.value });
                }}
              />
            </Form.Item>
            <br />
            <br />
          </div>
        </Modal>
      </div>
    );
  }

}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  payments: selectors.getPayments,
  participants: selectors.getRequestedStudyParticipants
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getPayments: () => dispatch(getPaymentsAsync.request()),
    markPaid: (markPaid: MarkPaidType) => dispatch(markPaidAsync.request(markPaid))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsLandingPage);
