import { Tabs } from 'antd';
import AvatarCustomSchedule from '../../../components/avatar/AvatarCustomSchedule';
import React from 'react';
const { TabPane } = Tabs;
import AvatarCustom from '../../../components/avatar/AvatarCustom';

const AvatarCustomLanding = () => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Avatar Parts" key="1">
        <AvatarCustom />
      </TabPane>
      <TabPane tab="Avatar Award Schedule" key="2">
        <AvatarCustomSchedule />
      </TabPane>
    </Tabs>
  );
};

export default AvatarCustomLanding;
