import { createSelector } from 'reselect';
import IApplicationState from '../../types/state.types';

export const selectSidebarState = (state: IApplicationState) => {
  return state.ui.sidebar;
};

export const isSidebarCollapsed = createSelector(
  [selectSidebarState],
  (sidebarState) => {
    if (sidebarState) {
      return sidebarState.collapsed;
    }
    return false;
  }
);

export default selectSidebarState;
