import { AdminNotificationSubscriptionType } from 'types/serverTypes/adminNotificationTypes';
import axios from '../../redux/api';

export const getAvailableAdminNotifications = async () => {
  const response = await axios.get(
    `/a/notification/availableAdminNotifications`
  );
  return response.data;
};

export const updateSubscription = async (
  subscription: AdminNotificationSubscriptionType
) => {
  const response = await axios.put(`/a/notification/subscribe`, subscription);
  return response.data;
};

export const getNotificationsForParticipant = async ({ queryKey }) => {
  const [_, participantId] = queryKey;
  const response = await axios.get(
    `/a/notification/participantNotifications?participantId=${participantId}`
  );
  return response.data;
};
