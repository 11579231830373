import { User } from "oidc-client";
import { getType } from 'typesafe-actions';
import {
  all, fork, select, takeLatest
} from 'redux-saga/effects';
import * as selectors from '../selectors';
import userManager from '../../oidc/UserManager';
import { logout } from './app.types';

function* logoutHandler(): Generator {

  const oidcUser = (yield select(selectors.selectOidcUser)) as User;

  // Signs you out of the application
  userManager.removeUser();

  if (oidcUser && oidcUser.id_token) {
    // Signs you out of the Authorization server
    userManager.signoutRedirect({ id_token_hint: oidcUser && oidcUser.id_token });
  }
}

function* logoutWatcher() {
  yield takeLatest(getType(logout), logoutHandler);
}

export default function* appSaga() {
  yield all([
    fork(logoutWatcher)
  ]);
}
