import { Form, Input } from 'antd';
import { InputFocusOptions } from 'antd/lib/input/Input';
import { Rule } from 'rc-field-form/lib/interface';
import React, { useContext, useEffect, useState } from 'react';
import EditableContext from './EditableContext';

interface EditableCellProps<T> {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: T;
  handleSaveCell: (record: T) => void;
  required?: boolean;
  validator?: (rule, value) => Promise<void>;
  editRender?: (record, ref, rules?: Rule[], save?: Function) => JSX.Element;
}

interface Keyable {
  key: React.Key;
}

interface Focusable {
  focus: (option?: InputFocusOptions | undefined) => void;
}

const EditableCell = <T extends Keyable>(props: EditableCellProps<T>) => {
  const inputRef = React.useRef<any & Focusable>(null);
  const form = useContext(EditableContext);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    const { dataIndex, record } = props;
    setEditing(!editing);
    if (form) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
  };

  const save = async () => {
    try {
      if (form) {
        const values = await form.validateFields();
        props.handleSaveCell({ ...props.record, ...values });
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const {
    children,
    editable,
    dataIndex,
    title,
    record,
    required,
    validator,
    editRender,
    handleSaveCell,
    ...restProps
  } = props;
  let childNode = children;
  if (editable) {
    const rules: Rule[] = [];
    if (required) {
      rules.push({
        required,
        message: `${title} is required.`,
      });
    }
    if (validator) {
      rules.push(() => ({
        validator(_, value) {
          if (!value || record[dataIndex] === value) {
            return Promise.resolve();
          }
          return validator(_, value);
        },
        validateTrigger: ['onChange', 'onBlur'],
      }));
    }
    childNode = editing ? (
      editRender ? (
        editRender(record, inputRef, rules, save)
      ) : (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={rules}
          initialValue={record[dataIndex]}
        >
          <Input
            className="editable-cell-input"
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      )
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
