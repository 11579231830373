import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import { all, call, fork, takeLatest, takeEvery, put } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { entitySchema } from '../schema';
import { loadResourceTopicsAsync, loadForumTopicsAsync, loadQnaTopicsAsync, loadGoalTopicsAsync } from './topic.types';
import {NormalizerResult} from "../../types";

// const getTopics = (topicType: {type: string, typeId: number}) => {
//   return axios({
//     method: 'get',
//     url: `/a/topic/${topicType.type}/${topicType.typeId}/topicStructure`
//   });
// };

const getResourceTopics = () => {
  return axios( {
    method: 'get',
    url: `/a/topic/knowledgecenter/1/topicStructure`
  })
};

const getForumTopics = (forumId: number) => {
  return axios( {
    method: 'get',
    url: `/a/topic/forum/${forumId}/topicStructure`
  })
};

const getQnaTopics = () => {
  return axios( {
    method: 'get',
    url: `/a/topic/qna/1/topicStructure`
  })
};

const getGoalTopics = () => {
  return axios( {
    method: 'get',
    url: `/a/topic/goal/1/topicStructure`
  })
};

function* loadResourceTopicsHandler(action: ReturnType<typeof loadResourceTopicsAsync.request>) {
  try {
    // const topicType: any = action.payload;
    // const response: AxiosResponse = (yield call(getTopics, topicType)) as AxiosResponse;
    const response: AxiosResponse = (yield call(getResourceTopics)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.topics) as NormalizerResult;
    const { topics } = entities;
    yield put(loadResourceTopicsAsync.success(topics));

  } catch (error) {
    yield put(loadResourceTopicsAsync.failure(error));
  }
}

function* loadForumTopicsHandler(action: ReturnType<typeof loadForumTopicsAsync.request>) {
  try {
    const forumId:number = action.payload;
    const response: AxiosResponse = (yield call(getForumTopics, forumId)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.topics) as NormalizerResult;
    const { topics } = entities;
    yield put(loadForumTopicsAsync.success(topics));

  } catch (error) {
    yield put(loadForumTopicsAsync.failure(error));
  }
}

function* loadQnaTopicsHandler(action: ReturnType<typeof loadQnaTopicsAsync.request>) {
  try {
    const response: AxiosResponse = (yield call(getQnaTopics)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.topics) as NormalizerResult;
    const { topics } = entities;
    yield put(loadQnaTopicsAsync.success(topics));

  } catch (error) {
    yield put(loadQnaTopicsAsync.failure(error));
  }
}

function* loadGoalTopicsHandler(action: ReturnType<typeof loadGoalTopicsAsync.request>) {
  try {
    const response: AxiosResponse = (yield call(getGoalTopics)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.topics) as NormalizerResult;
    const { topics } = entities;
    yield put(loadGoalTopicsAsync.success(topics));

  } catch (error) {
    yield put(loadGoalTopicsAsync.failure(error));
  }
}

function* loadResourceTopicsWatcher() {
  yield takeLatest(getType(loadResourceTopicsAsync.request), loadResourceTopicsHandler);
}
function* loadForumTopicsWatcher() {
  yield takeEvery(getType(loadForumTopicsAsync.request), loadForumTopicsHandler);
}
function* loadQnaTopicsWatcher() {
  yield takeLatest(getType(loadQnaTopicsAsync.request), loadQnaTopicsHandler);
}
function* loadGoalTopicsWatcher() {
  yield takeLatest(getType(loadGoalTopicsAsync.request), loadGoalTopicsHandler);
}

export default function* topicSaga() {
  yield all([fork(loadResourceTopicsWatcher)]);
  yield all([fork(loadForumTopicsWatcher)]);
  yield all([fork(loadQnaTopicsWatcher)]);
  yield all([fork(loadGoalTopicsWatcher)]);
}