import React, { Component } from 'react';
import {
  Input, Form, Button, Select, List
} from 'antd';
import '../activity.scss';
import { CloseOutlined } from '@ant-design/icons';
import { OptionType, QuestionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;
const { Option } = Select;

interface ComponentProps {
  disabled: boolean;
  question: QuestionType;
  groups: [string, OptionType[]][];
  updateQuestion: (q: QuestionType) => void;
  removeQuestion: () => void;
}
class ScreenerQuestionItem extends Component<ComponentProps, {}> {
  private styles = {
    removeButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#f17878',
      borderColor: '#f17878',
      backgroundColor: '#FBDDDD',
      width: '32px',
      marginLeft: '10px'
    }
  };

  onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { question, updateQuestion } = this.props;
    question.text = e.target.value;
    updateQuestion(question);
  };

  onGroupChange = (value: string) => {
    const { question, updateQuestion } = this.props;
    question.optionGroupId = value;
    updateQuestion(question);
  };

  render() {
    const {
      question, groups, disabled, removeQuestion
    } = this.props;
    const { text, optionGroupId, uuid } = question;
    return (
      <List.Item
        key={`question-list-item-${uuid}`}
        extra={(
          <Button
            key={`remove-question-${uuid}`}
            disabled={disabled}
            style={this.styles.removeButton}
            onClick={removeQuestion}
            title="Remove question"
          >
            <CloseOutlined />
          </Button>
        )}
      >
        <List.Item.Meta
          title={(
            <Item
              key={`question-text-${uuid}`}
              name={`question-text-${uuid}`}
              validateTrigger={['onChange', 'onBlur', 'onFocus']}
              rules={[
                {
                  required: true,
                  message: 'Please input option text or delete this question.'
                }
              ]}
              initialValue={text}
            >
              <Input
                spellCheck
                disabled={disabled}
                placeholder="Question text"
                onChange={this.onTextChange}
              />
            </Item>
          )}
          description={(
            <Item
              key={`select-optionGroupId-${uuid}`}
              name={`select-optionGroupId-${uuid}`}
              rules={[
                {
                  required: true,
                  message: 'Please select a group ID.'
                }
              ]}
              initialValue={optionGroupId}
            >
              <Select disabled={disabled} onChange={this.onGroupChange}>
                {groups.map(([id, options]) => (
                  <Option key={`group-option-${id}`} value={id}>
                    {options.map((o) => `${o.text} (${o.points})`).join(', ')}
                  </Option>
                ))}
              </Select>
            </Item>
          )}
        />
      </List.Item>
    );
  }
}

export default ScreenerQuestionItem;
