import {connectRouter} from 'connected-react-router';
import {History} from 'history';
import {reducer as oidcReducer} from 'redux-oidc';
import {combineReducers} from 'redux';
import analyticsUIReducer from './analitycs/analytics.ui.reducer';
import {apiReducer as api} from './api/api.reducer';
import {articleReducer} from './article/article.reducer';
import articleUIReducer from './article/article.ui.reducer';
import {avatarReducer} from './avatar/avatar.reducer';
import dashboardReducer from './dashboard/dashboard.reducer';
import {notificationsReducer} from './notification/notification.reducer';
import {participantsReducer} from './participants/participants.reducer';
import paymentReducer from './payment/payment.reducer';
import {postsReducer} from './posts/posts.reducer';
import forumUIReducer from './forum/forum.ui.reducer';
import studyUIReducer from './study/study.ui.reducer';
import {sideBarReducer} from './sidebar/sidebar.reducer';
import studyReducer from './study/study.reducer';
import studyArmReducer from './study/studyArms.reducer';
import topicsReducer from './topics/topic.reducer';
import {adminRoleReducer, adminUserReducer, userReducer} from './user/user.reducer';
import {IApplicationState} from '../types/state.types';
import formsReducer from './form/form.reducer';
import commentsReducer from './comments/comments.reducer';
import {messageResponsesReducer, messagesReducer} from './messages/messages.reducer';
import threadsReducer from './messages/threads.reducer';
import testkitReducer from './testkit/testkit.reducer';
import flagsReducer from './flags/flags.reducer';
import thumbsupsReducer from "./thumbsups/thumbsups.reducer";
import favoritesReducer from "./favorites/favorites.reducer";
import notesReducer from './notes/notes.reducer';
import {activitiesReducer, activityCategoriesReducer, activityTypesReducer} from './activities/activities.reducer';
import announcementReducer from './announcements/announcements.reducer';
import userSurveyReducer from './userSurvey/userSurvey.reducer';
import qnaReducer from './qna/qna.reducer';
import qnaUIReducer from './qna/qna.ui.reducer';
import messageUIReducer from './messages/messages.ui.reducer';
import forumReducer from './forum/forum.reducer';
import appointmentsReducer from './appointments/appointments.reducer';
import goalsReducer from './goals/goals.reducer';
import badgeGroupsReducer from './badges/badgeGroups.reducer';
import badgeCollectionTypesReducer from './badges/badgeCollectionTypes.reducer';
import badgeCollectionsReducer from './badges/badgeCollections.reducer';
import badgesReducer from './badges/badges.reducer';
import availabilitiesReducer from './availabilty/availability.reducer';
import stringLookupsReducer from './stringLookups/stringLookups.reducer';

const rootReducer = (history: History) => {
  return combineReducers<IApplicationState>({
    api,
    dashboard: dashboardReducer,
    auth: combineReducers({
      user: userReducer,
    }),
    entities: combineReducers({
      activities: activitiesReducer,
      activityCategories: activityCategoriesReducer,
      activityTypes: activityTypesReducer,
      admin: adminUserReducer,
      adminRoles: adminRoleReducer,
      announcements: announcementReducer,
      appointments: appointmentsReducer,
      articles: articleReducer,
      availabilities: availabilitiesReducer,
      avatars: avatarReducer,
      badgeCollections: badgeCollectionsReducer,
      badgeCollectionTypes: badgeCollectionTypesReducer,
      badgeGroups: badgeGroupsReducer,
      badges: badgesReducer,
      comments: commentsReducer,
      favorites: favoritesReducer,
      flags: flagsReducer,
      forms: formsReducer,
      forums: forumReducer,
      goals: goalsReducer,
      messageResponses: messageResponsesReducer,
      messages: messagesReducer,
      notes: notesReducer,
      notifications: notificationsReducer,
      participants: participantsReducer,
      payments: paymentReducer,
      posts: postsReducer,
      qnaPosts: qnaReducer,
      stringLookups: stringLookupsReducer,
      studies: studyReducer,
      studyArms: studyArmReducer,
      testkits: testkitReducer,
      threads: threadsReducer,
      thumbsups: thumbsupsReducer,
      topics: topicsReducer,
      userSurveys: userSurveyReducer,
    }),
    router: connectRouter(history),
    oidc: oidcReducer,
    ui: combineReducers({
      articles: articleUIReducer,
      sidebar: sideBarReducer,
      forums: forumUIReducer,
      study: studyUIReducer,
      qnas: qnaUIReducer,
      messages: messageUIReducer,
      reports: combineReducers({
        analytics: analyticsUIReducer,
      }),
    }),
  });
};

export default rootReducer;
