import { ActionType, createAsyncAction } from 'typesafe-actions';
import { NormalizedType } from 'types/state.types';
import {NewUserType, UserType} from "../../types/serverTypes/usmgTypes";
import {AdminUserType, RoleType} from "../../types/serverTypes/adminTypes";

export interface PasswordArguments {
  password: string;
  currentPassword: string;
  updatePseudoParticipants?: boolean;
  userId?: number;
}

export interface AdminPasswordArguments {
  userIds: number[];
  password: string;
  updatePseudoParticipants?: boolean;
}

export interface CreateUserArguments {
  user: NewUserType;
  sendEmail: boolean;
  createPseudos: boolean;
}

export interface UpdateAdminUserArguments {
  user: AdminUserType;
  roleIds?: number[];
}

export interface DeleteAdminUserArguments {
  userIds: number[];
  deleteDate?: Date;
}

export const updatePasswordAsync = createAsyncAction(
  'hmp/admin/UPDATE_PASSWORD_REQUEST',
  'hmp/admin/UPDATE_PASSWORD_SUCCESS',
  'hmp/admin/UPDATE_PASSWORD_FAILURE'
)<PasswordArguments, Optional<UserType>, Error>();

export const updateEmailAsync = createAsyncAction(
  'hmp/admin/UPDATE_EMAIL_REQUEST',
  'hmp/admin/UPDATE_EMAIL_SUCCESS',
  'hmp/admin/UPDATE_EMAIL_FAILURE'
)<Optional<string>, Optional<UserType>, Error>();

export const updateMobileAsync = createAsyncAction(
  'hmp/admin/UPDATE_PASSWORD_EMAIL_MOBILE_REQUEST',
  'hmp/admin/UPDATE_PASSWORD_EMAIL_MOBILE_SUCCESS',
  'hmp/admin/UPDATE_PASSWORD_EMAIL_MOBILE_FAILURE'
)<Optional<string>, Optional<UserType>, Error>();

export const getUserAsync = createAsyncAction(
  'hmp/admin/USER_REQUEST',
  'hmp/admin/USER_SUCCESS',
  'hmp/admin/USER_FAILURE'
)<number, Optional<AdminUserType>, Error>();

export const getAdminAsync = createAsyncAction(
  'hmp/admin/GET_ADMIN_REQUEST',
  'hmp/admin/GET_ADMIN_SUCCESS',
  'hmp/admin/GET_ADMIN_FAILURE'
)<void, Optional<NormalizedType<AdminUserType>>, Error>();

export const createUserAsync = createAsyncAction(
  'hmp/admin/CREATE_USER_REQUEST',
  'hmp/admin/CREATE_USER_SUCCESS',
  'hmp/admin/CREATE_USER_FAILURE'
)<CreateUserArguments, void, Error>();

export const getAdminRolesAsync = createAsyncAction(
  'hmp/admin/GET_ADMIN_ROLES_REQUEST',
  'hmp/admin/GET_ADMIN_ROLES_SUCCESS',
  'hmp/admin/GET_ADMIN_ROLES_FAILURE'
)<void, Optional<NormalizedType<RoleType>>, Error>();

export const updateAdminPasswordAsync = createAsyncAction(
  'hmp/admin/UPDATE_ADMIN_PASSWORD_REQUEST',
  'hmp/admin/UPDATE_ADMIN_PASSWORD_SUCCESS',
  'hmp/admin/UPDATE_ADMIN_PASSWORD_FAILURE'
)<AdminPasswordArguments, void, Error>();

export const updateAdminUserAsync = createAsyncAction(
  'hmp/admin/UPDATE_ADMIN_USER_REQUEST',
  'hmp/admin/UPDATE_ADMIN_USER_SUCCESS',
  'hmp/admin/UPDATE_ADMIN_USER_FAILURE'
)<UpdateAdminUserArguments, Optional<NormalizedType<AdminUserType>>, Error>();

export const deleteAdminUserAsync = createAsyncAction(
  'hmp/admin/DELETE_ADMIN_USER_REQUEST',
  'hmp/admin/DELETE_ADMIN_USER_SUCCESS',
  'hmp/admin/DELETE_ADMIN_USER_FAILURE'
)<DeleteAdminUserArguments, void, Error>();

const userActions = {
  getUserAsync,
  getAdminAsync,
  updatePasswordAsync,
  updateEmailAsync,
  updateMobileAsync,
  createUserAsync,
  getAdminRolesAsync,
  updateAdminPasswordAsync,
  updateAdminUserAsync,
  deleteAdminUserAsync,
};

export type UserActionTypes = ActionType<typeof userActions>;
