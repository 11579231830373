import React, { Component } from 'react';
import Button from 'antd/lib/button';
import Collapse from 'antd/lib/collapse';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import ScreenerOptionItem from './ScreenerOptionItem';
import './screenerOptionGroup.scss';
import { List, Form } from 'antd';
import { OptionType } from '../../../types/serverTypes/activityTypes';

const { Panel } = Collapse;
const { Item } = Form;

interface ComponentProps {
  disabledProp: boolean;
  header: string;
  optionGroupId: string;
  options: OptionType[];
  addOption: (optionGroupId: string) => void;
  updateOption: (q: OptionType) => void;
  removeOption: (uuid: string) => void;
  removeGroup: () => void;
}
class ScreenerOptionGroup extends Component<ComponentProps, {}> {
  private styles: { [key: string]: React.CSSProperties } = {
    addButton: {
      width: '200px',
      color: '#439AFF',
      borderColor: '#439AFF',
      backgroundColor: '#D0E6FF'
    },
    removeButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#f17878',
      borderColor: '#f17878',
      backgroundColor: '#FBDDDD',
      marginLeft: '5px',
      width: '32px',
      height: '32px'
    },
    panelExtra: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start'
    }
  };

  validateOptionGroup = (rule, value) => {
    const { options, header } = this.props;
    try {
      if (options.length === 0) {
        throw new Error(`(${header}) An option group must have at least one option.`);
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  render() {
    const {
      disabledProp: disabled,
      optionGroupId,
      options,
      addOption,
      updateOption,
      removeOption,
      removeGroup
    } = this.props;
    return (
      <Item
        key={`option-group-items-${optionGroupId}`}
        name={`option-group-items-${optionGroupId}`}
        rules={[{ required: true, validator: this.validateOptionGroup }]}
        noStyle
      >
        <Panel
          key={`option-group-panel-${optionGroupId}`}
          className="option-group-panel"
          extra={(
            <div
              style={this.styles.panelExtra}
              onClick={(e) => e.stopPropagation()}
            >
              <Button
                key={`add-option-${optionGroupId}`}
                style={this.styles.addButton}
                onClick={() => addOption(optionGroupId)}
                disabled={disabled}
                type="dashed"
                title="Add new option to group"
              >
                <PlusOutlined />
                {' '}
                Add option
              </Button>
              <Button
                key={`remove-group-${optionGroupId}`}
                style={this.styles.removeButton}
                onClick={removeGroup}
                disabled={disabled}
                title="Remove option group"
              >
                <CloseOutlined />
              </Button>
            </div>
          )}
          {...this.props}
        >
          <List
            itemLayout="horizontal"
            dataSource={options}
            renderItem={(o) => {
              return (
                <ScreenerOptionItem
                  key={`option-item-${o.uuid}`}
                  disabled={disabled}
                  option={o}
                  updateOption={updateOption}
                  removeOption={removeOption}
                />
              );
            }}
          />
        </Panel>
      </Item>
    );
  }
}

export default ScreenerOptionGroup;
