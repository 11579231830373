import { Input } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import IApplicationState from 'types/state.types';
import { IApiRequestStatus } from '../../types/api.types';
import * as selectors from '../../redux/selectors';
import './threadSearch.scss';

const { Search } = Input;

interface StateProps {
  loadInboxStatus: IApiRequestStatus;
}

interface ComponentProps extends StateProps {
  searchTerm?: string;
  search: (term: string) => void;
}

interface ComponentState {}

class ThreadSearch extends Component<ComponentProps, ComponentState> {
  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.search(event.target.value);
  };

  onSearch = (value: string) => {
    this.props.search(value);
  };

  render() {
    return (
      <Search
        allowClear
        value={this.props.searchTerm}
        className="thread-search-input"
        placeholder="Search Threads"
        onChange={this.onChange}
        onSearch={this.onSearch}
        bordered={false}
        loading={
          !!this.props.searchTerm
          && this.props.loadInboxStatus.isLoading
        }
        enterButton
        style={{
          padding: '10px 15px 0 15px'
        }}
      />
    );
  }
}

const mapStateToProps = (state: IApplicationState): StateProps => {
  return {
    loadInboxStatus: selectors.loadMessageInboxStatus(state)
  };
};

export default connect(mapStateToProps)(ThreadSearch);
