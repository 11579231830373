import React, { Component } from 'react';
import {
  Input, InputNumber, Form, Button, List
} from 'antd';
import '../activity.scss';
import { CloseOutlined } from '@ant-design/icons';
import { OptionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  disabled: boolean;
  option: OptionType;
  updateOption: (q: OptionType) => void;
  removeOption: (uuid: string) => void;
}
class ScreenerOptionItem extends Component<ComponentProps, {}> {
  private styles = {
    removeButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#f17878',
      borderColor: '#f17878',
      backgroundColor: '#FBDDDD',
      marginLeft: '5px',
      width: '32px',
      height: '32px'
    },
    optionItem: {
      backgroundColor: 'white',
      padding: '10px'
    }
  };

  onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { option, updateOption } = this.props;
    option.text = e.target.value;
    updateOption(option);
  };

  onPointsChange = (value: string | number | undefined) => {
    const { option, updateOption } = this.props;
    option.points = Number(value);
    updateOption(option);
  };

  render() {
    const { option, disabled, removeOption } = this.props;
    const { text, points, uuid } = option;
    return (
      <List.Item
        style={this.styles.optionItem}
        extra={(
          <Button
            key={`remove-option-${uuid}`}
            disabled={disabled}
            onClick={() => removeOption(uuid)}
            title="Remove option"
            style={this.styles.removeButton}
          >
            <CloseOutlined />
          </Button>
        )}
      >
        <List.Item.Meta
          title={(
            <Item
              key={`option-${uuid}`}
              name={`option-text-${uuid}`}
              validateTrigger={['onChange', 'onBlur', 'onSubmit', 'onFocus']}
              rules={[
                {
                  required: true,
                  message: 'Please input option text.'
                }
              ]}
              initialValue={text}
            >
              <Input
                spellCheck
                disabled={disabled}
                placeholder="Option text"
                onChange={this.onTextChange}
                style={{ width: '100%' }}
              />
            </Item>
          )}
          description={(
            <Item
              key={`input-number-option-${uuid}`}
              name={`input-number-option-${uuid}`}
              label="Point Value:"
              validateTrigger={['onChange', 'onBlur', 'onSubmit', 'onFocus']}
              rules={[
                {
                  required: true,
                  message: 'Please input valid point value.'
                }
              ]}
              initialValue={points}
            >
              <InputNumber
                disabled={disabled}
                min={0}
                onChange={this.onPointsChange}
              />
            </Item>
          )}
        />
      </List.Item>
    );
  }
}

export default ScreenerOptionItem;
