import Input, { InputProps } from 'antd/lib/input';
import React, { ChangeEvent, Component } from 'react';

const { Search } = Input;

interface ComponentProps extends InputProps {
  onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => any,
  onSubmitHandler?: (value: string) => any,
}

class SearchInput extends Component<ComponentProps, {}> {
  readonly state = {
    searchString: ''
  };

  localChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const { onChangeHandler } = this.props;
    onChangeHandler(event);
    this.setState({
      searchString: event.target.value
    });
  };

  localSubmitHandler = (value: string) => {
    const { onSubmitHandler } = this.props;
    if (onSubmitHandler) {
      onSubmitHandler(value);
    }
  };

  render() {
    const {
      onChangeHandler,
      onSubmitHandler,
      ...rest
    } = this.props;

    return (
      <div className="hmp-search-input-container">
        <Search
          placeholder="Press enter to search"
          autoComplete="off"
          allowClear
          onChange={this.localChangeHandler}
          onSearch={this.localSubmitHandler}
          enterButton
          {...rest}
        />
      </div>
    );
  }
}

export default SearchInput;
