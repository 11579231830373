import { Card, Col, Empty, message, Row, Select, Spin } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getStudyArms } from '../../service/studyService';
import { StudyArmType } from '../../types/serverTypes/studyTypes';
import {
  createScheduleAvatarPart,
  getAvatarCategories,
  getAvatarParts,
  getScheduleAvatarParts,
} from '../../service/avatar/avatarService';
import {
  AvatarPartCategoryType,
  AvatarPartPointType,
  AvatarPartType,
} from '../../types/serverTypes/avatarTypes';
import './AvatarCustom.scss';
import AvatarScheduleEdit from './AvatarScheduleEdit';
const { Option } = Select;

const AvatarCustomSchedule = () => {
  const categories = useQuery<AvatarPartCategoryType[]>(
    'avatarCategories',
    getAvatarCategories
  );
  const parts = useQuery<AvatarPartType[]>('avatarParts', getAvatarParts);
  const [selectedBlock, setSelectedBlock] = useState(0);
  const studyArms = useQuery<StudyArmType[]>('studyArms', getStudyArms);
  const [studyArmSelected, setStudyArmSelected] = useState();

  const onStudyArmChanged = (val) => {
    setStudyArmSelected(val);
  };

  const scheduleAvatarParts = useQuery<AvatarPartPointType[]>(
    ['scheduleAvatarParts', studyArmSelected],
    getScheduleAvatarParts,
    {
      enabled: !!studyArmSelected,
    }
  );

  const queryClient = useQueryClient();
  const addScheduleMutation = useMutation(createScheduleAvatarPart, {
    onError: (error, variables, context) => {
      message.error('Error in adding avatar award schedule.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('scheduleAvatarParts');
      message.success('Avatar award schedule added successfully.');
    },
  });

  const onClickPart = (partId) => {
    // add the schedule for the part based on the selected block
    if (studyArmSelected) {
      const avatarPartPoint: AvatarPartPointType = {
        avatarId: partId,
        pointsRequired: selectedBlock,
        studyArmId: studyArmSelected,
      };
      addScheduleMutation.mutate(avatarPartPoint);
    }
  };

  return (
    <Card
      title={
        <div>
          <ul>
            <li>Select a study arm from the drop down on the right side.</li>
            <li>Create a new award point by click on plus(+) sign.</li>
            <li>
              Assign an avatar part by click on the award point and then
              clicking on the part.
            </li>
            <li>
              Unlink an avatar part with an award point by clicking on an avatar
              part under the point.
            </li>
          </ul>
        </div>
      }
      extra={
        <div>
          <div>Study Arm:</div>
          <Select onChange={onStudyArmChanged} style={{ width: 150 }}>
            {studyArms.data?.map((studyArm) => (
              <Option key={studyArm.id} value={studyArm.id}>
                {studyArm.name}
              </Option>
            ))}
          </Select>
        </div>
      }
    >
      <Row>
        <Col span={8}>
          <Spin spinning={categories.isLoading}>
            {!categories.isLoading && categories.data?.length === 0 && (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {categories.data &&
              categories.data.map((category) => (
                <div className="category-container" key={category.id}>
                  <div className="category-label-section">{category.label}</div>
                  <div className="avatar-svg-containers">
                    {parts.data &&
                      parts.data
                        .filter(
                          (p) =>
                            !p.isBaseSet &&
                            p.avatarPartCategoryId === category.id &&
                            scheduleAvatarParts.data?.findIndex(
                              (schedulePart) =>
                                schedulePart.avatarId === p.id &&
                                !!schedulePart.pointsRequired
                            ) === -1
                        )
                        .map((part) => (
                          <div className="avatar-svg-container" key={part.id}>
                            <div
                              className="avatar-svg"
                              onClick={() => onClickPart(part.id)}
                              dangerouslySetInnerHTML={{
                                __html: part.svg,
                              }}
                            ></div>
                          </div>
                        ))}
                  </div>
                </div>
              ))}
          </Spin>
        </Col>
        <Col span={16}>
          <AvatarScheduleEdit
            selectedBlock={selectedBlock}
            onSelectedBlock={(block) => setSelectedBlock(block)}
            selectedStudyArm={studyArmSelected}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default AvatarCustomSchedule;
