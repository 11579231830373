import { Button } from 'antd';
import Collapse from 'antd/lib/collapse';
import Table from 'antd/lib/table';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { updateRecentActivityScroll, updateRecentActivitySelectedPostId } from '../../redux/forum/forum.types';
import IApplicationState from '../../types/state.types';
import * as selectors from '../../redux/selectors';
import { PostType } from '../../types/serverTypes/forumTypes';

const initialState = {
};

type ComponentState = Readonly<typeof initialState>;

interface StateProps {
  posts: Optional<PostType[]>;
  studyId: number;
}

interface DispatchProps {
  updateRecentActivityScroll: typeof updateRecentActivityScroll
  updateRecentActivitySelectedPostId: typeof updateRecentActivitySelectedPostId,
}

interface ComponentProps extends StateProps, DispatchProps, RouteComponentProps {}

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

class PostsTable extends Component<ComponentProps, ComponentState> {
  static defaultProps = { };

  readonly state: ComponentState = initialState;

  navigateToPost = (post: PostType) => {
    const { studyId } = this.props;
    this.props.updateRecentActivitySelectedPostId(post.id);
    this.props.history.push(`/study/${studyId}/forums/recentActivity`);
  }

   columns = [
     {
       title: 'ID',
       render: (val:number, post:PostType) => {
         return <Button type="link" onClick={() => this.navigateToPost(post)}>{val}</Button>;
       },
       dataIndex: 'id',
       key: 'id',
       sorter: (a: any, b: any) => a.id - b.id
     },
     {
       title: 'Date',
       dataIndex: 'createDate',
       key: 'createDate',
       render: ((date: Date) => moment(date).calendar()),
       sorter: (a: any, b: any) => {
         return moment(a.createDate || 0).unix() - moment(b.createDate || 0).unix();
       },
       defaultSortOrder: 'descend'
     },
     {
       title: 'Topic',
       dataIndex: 'topic',
       key: 'topic',
       // render: renderParticipantLink,
       sorter: (a: any, b: any) => `${a.topic}`.localeCompare(b.topic)
     }, {
       title: 'Post',
       dataIndex: 'body',
       key: 'body',
       // render: renderParticipantLink,
       sorter: (a: any, b: any) => `${a.body}`.localeCompare(b.body)
     },
     {
       title: 'Flags',
       dataIndex: 'flagCount',
       sorter: (a: any, b: any) => a.id - b.id
     },
     {
       title: 'Hidden?',
       dataIndex: 'isHidden',
       filters: [{ text: 'yes', value: 1 }, { text: 'no', value: 0 }],
       onFilter: (value:number, record:any) => record.isHidden === value,
       render: (val:number) => (val === 0 ? 'no' : 'yes'),
       sorter: (a: number, b: number) => a.id - b.id
     },
     {
       title: 'Comments',
       dataIndex: 'commentCount',
       sorter: (a: any, b: any) => a.id - b.id
     },
     {
       title: 'Thumbusups',
       dataIndex: 'thumbsupsCount',
       sorter: (a: any, b: any) => a.id - b.id
     },
     {
       title: 'Favorites',
       dataIndex: 'favoritesCount',
       sorter: (a: any, b: any) => a.id - b.id
     }
     // , {
     //   title: 'createdByParticipantId',
     //   dataIndex: 'createdByParticipantId',
     //   key: 'createdByParticipantId',
     //   sorter: (a: any, b: any) => `${a.createdByParticipantId}`.localeCompare(b.createdByParticipantId)
     // }
   ];

   render() {
     const { Panel } = Collapse;

     return (
       <div id="search-results-container">
         <Table
           columns={this.columns}
           dataSource={this.props.posts}
           pagination={false}
           rowClassName={rowClassName}
           rowKey="id"
         />
       </div>
     );
   }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    studyId: selectors.getRequestedStudyId(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    updateRecentActivityScroll: (scrollTop:number) => dispatch(updateRecentActivityScroll(scrollTop)),
    updateRecentActivitySelectedPostId: (selectedPostId:number) => dispatch(updateRecentActivitySelectedPostId(selectedPostId))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostsTable));
