import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Card, Checkbox, Input, Space, Table } from 'antd';
import { useParams } from 'react-router';
import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as selectors from '../../../redux/selectors';
import IApplicationState from '../../../types/state.types';
import UnsavedLeavingGuard from '../../../components/util/UnsavedLeavingGuard';
import { useNotificationQuery, useNotificationScheduleQuery } from './queries';
import { NotificationType2 } from '../../../types/serverTypes/notificationTypes';
import {
  NotificationTypes,
  NotificationSubTypes,
} from '../../../constant/serverConstants/notificationConstants';
import './notificationLandingPage.scss';
import NotificationForm from '../../../components/notification/NotificationForm';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import HMPJsonView from '../../../components/jsonView/HMPJsonView';
import { parseJSON } from '../../../service/util';
import useQuery, { getQueryParams } from '../../../components/util/useQuery';
import { UrlParamsType } from '../../../types';

const { Column } = Table;
const { Search } = Input;

interface NotificationLandingPageQueryType {
  term: string;
  enabled: 'true' | 'false';
  types: string[];
  subtypes: string[];
  adminOnly: 'true' | 'false';
}

interface StateProps {
  studyId: number;
}

interface DispatchProps {}

interface ComponentProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {}

const NotificationLandingPage = (props: ComponentProps) => {
  const query = useQuery<NotificationLandingPageQueryType>();
  const params = useParams<UrlParamsType>();
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [term, setTerm] = useState<string>(query.term);
  const [enabled, setEnabled] = useState<boolean>(query.enabled === 'true');
  const [types, setTypes] = useState<string[]>(query.types);
  const [subtypes, setSubtypes] = useState<string[]>(query.subtypes);
  const [adminOnly, setAdminOnly] = useState<boolean>(
    query.adminOnly === 'true'
  );
  const showModal =
    params?.subpage === 'edit' && params.id && !isNaN(parseInt(params.id));

  const { data } = useNotificationQuery(
    types,
    subtypes,
    enabled,
    adminOnly,
    term
  );
  const { data: schedules } = useNotificationScheduleQuery();
  const { history } = props;

  useEffect(() => {
    history.replace({
      search: getStateQueryString(),
    });
  }, [term, enabled, adminOnly, types, subtypes]);

  const getStateQueryString = () => {
    return `?${queryString.stringify(
      {
        ...getQueryParams(history.location.search),
        term: term ?? undefined,
        enabled: enabled ?? undefined,
        adminOnly: adminOnly ?? undefined,
        types: types && types.length ? types : undefined,
        subtypes: subtypes && subtypes.length ? subtypes : undefined,
      },
      {
        arrayFormat: 'bracket-separator',
        arrayFormatSeparator: ',',
        skipNull: true,
        skipEmptyString: true,
      }
    )}`;
  };

  const typeFilters = Object.entries(NotificationTypes).map((entry) => ({
    text: entry[0],
    value: entry[1],
  }));

  const subtypeFilters = Object.entries(NotificationSubTypes).map((entry) => ({
    text: entry[0],
    value: entry[1],
  }));

  const rowClassName = (record: any, index: number): string =>
    `${index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color'} goal-table-row`;

  const typeSorter = (a: NotificationType2, b: NotificationType2) => {
    return a.type.localeCompare(b.type);
  };

  const renderHTML = (model) => {
    return <FroalaEditorView model={model} />;
  };

  const expandedRowRender = (record) => {
    const columns = [
      { title: 'Title', dataIndex: 'title', key: `title` },
      {
        title: 'Body',
        dataIndex: 'body',
        key: `body`,
        render: renderHTML,
      },
      {
        title: 'Delivery Method',
        dataIndex: 'method',
        key: `method`,
      },
      {
        title: 'Enabled',
        dataIndex: 'enabled',
        key: `enabled`,
        render: renderBoolean,
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={record.templates}
        pagination={false}
        rowKey="id"
      />
    );
  };

  const renderBoolean = (bool: number) => {
    return bool ? 'True' : 'False';
  };

  const closeHandler = () => {
    setIsUnsaved(false);
    history.push({
      pathname: `/study/${params.studyId}/notification/`,
      search: getStateQueryString(),
    });
  };

  const handleRowClick = (record) => {
    history.push({
      pathname: `/study/${params.studyId}/notification/edit/${record.id}`,
      search: getStateQueryString(),
    });
  };

  return (
    <Card
      title={
        <div className="goal-title">
          <h1>Notifications</h1>
        </div>
      }
    >
      <Space size="small">
        <Search
          placeholder="Search text"
          allowClear
          enterButton
          style={{ width: 400 }}
          onSearch={setTerm}
          defaultValue={term}
        />
        <Checkbox
          onChange={(e) => setEnabled(e.target.checked)}
          defaultChecked={enabled}
        >
          Enabled only
        </Checkbox>
        <Checkbox
          onChange={(e) => setAdminOnly(e.target.checked)}
          defaultChecked={adminOnly}
        >
          Admin notifications only
        </Checkbox>
      </Space>
      <Table
        dataSource={data}
        rowKey="id"
        rowClassName={rowClassName}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => handleRowClick(record), // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        expandable={{
          expandedRowRender,
          rowExpandable: (record) => record.templates?.length > 0,
        }}
        onChange={(pagination, filters: any) => {
          setTypes(filters.type ?? []);
          setSubtypes(filters.subtype ?? []);
        }}
      >
        <Column
          title="Type"
          dataIndex="type"
          key="type"
          sorter={typeSorter}
          filters={typeFilters}
          onFilter={(value, record) => record.type === value}
          filteredValue={types}
        />
        <Column
          title="Subtype"
          dataIndex="subtype"
          key="subtype"
          filters={subtypeFilters}
          onFilter={(value, record: NotificationType2) =>
            record.subtype === value
          }
          filteredValue={subtypes}
        />
        <Column
          title="Description"
          dataIndex="description"
          key="description"
          filteredValue={null}
        />
        <Column
          title="Schedule"
          dataIndex="scheduleId"
          key="scheduleId"
          filteredValue={null}
          render={(id) => schedules?.find((s) => s.id === id)?.description}
        />
        <Column
          title="Enabled"
          dataIndex="enabled"
          key="enabled"
          filteredValue={null}
          render={renderBoolean}
        />
        <Column
          title="Admin Only"
          dataIndex="isAdminOnly"
          key="isAdminOnly"
          filteredValue={null}
          render={renderBoolean}
        />
        <Column
          title="Criteria"
          dataIndex="criteria"
          key="criteria"
          filteredValue={null}
          render={(value) =>
            value ? (
              <HMPJsonView
                src={parseJSON(value)}
                enableClipboard={false}
                name="criteria"
                displayDataTypes={false}
                displayObjectSize={false}
              />
            ) : null
          }
        />
      </Table>
      {showModal && (
        <NotificationForm
          visible={showModal}
          closeHandler={closeHandler}
          isUnsaved={isUnsaved}
          setIsUnsaved={setIsUnsaved}
          mode={params?.subpage}
          id={params.id!}
        />
      )}
      <UnsavedLeavingGuard
        when={isUnsaved}
        navigate={(path) => props.history.push(path)}
        shouldBlockNavigation={() => isUnsaved}
      />
    </Card>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    studyId: selectors.getRequestedStudyId(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotificationLandingPage));
