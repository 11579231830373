import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import cloneDeep from 'lodash/cloneDeep';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { getParticipantNotificationsAsync } from '../notification/notification.types';
import { getParticipantPaymentsAsync } from '../payment/payment.types';
import entitySchema from '../schema';
import { getParticipantsTestkitsAsync } from '../testkit/testkit.types';
import { getParticipantUserSurveysAsync } from '../userSurvey/userSurvey.types';
import {
  loadParticipantAsync,
  loadRequestedParticipantAsync,
  saveParticipantActualHivStatusAsync,
  saveParticipantInterviewSelectedAsync,
  saveParticipantAsync,
  getParticipantsByStudyArmAsync,
  updateParticipants,
  getParticipantsByStudyAsync,
} from './participants.types';
import { getParticipantPostsAsync } from '../posts/posts.types';
import * as selectors from '../selectors';
import { NormalizedType } from '../../types/state.types';
import { getParticipantCommentsAsync } from '../comments/comments.types';
import { getParticipantThreadsAsync } from '../messages/messages.types';
import { getParticipantFormsAsync } from '../form/form.types';
import { getParticipantNotesAsync } from '../notes/notes.types';
import {
  getBadgeCollectionsAsync,
  getParticipantBadgesAsync,
} from '../badges/badges.types';
import { ParticipantType } from '../../types/serverTypes/studyTypes';
import { NormalizerResult } from '../../types';

export function* updateParticipantsHandler(
  participants: Optional<NormalizedType<ParticipantType>>
) {
  if (participants) {
    yield put(updateParticipants(participants));
  }
}

const getParticipant = (participantId: number) => {
  return axios({
    method: 'get',
    url: `/a/participant/${participantId}`,
  });
};

function* loadParticipantHandler(
  action: ReturnType<typeof loadParticipantAsync.request>
): Generator {
  try {
    const participantId: number = action.payload;
    const response: AxiosResponse = (yield call(
      getParticipant,
      participantId
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.participants
    ) as NormalizerResult;
    const { participants } = entities;

    yield call(updateParticipantsHandler, participants);

    yield call(getParticipantNotes, participantId);

    yield call(getParticipantPosts, participantId);

    yield call(getParticipantComments, participantId);

    yield call(getParticipantThreads, participantId);

    yield call(getParticipantForms, participantId);

    yield call(getParticipantNotifications, participantId);

    yield call(getParticipantUserSurveys, participantId);

    yield call(getParticipantTestkits, participantId);

    yield call(getParticipantPayments, participantId);

    yield put(getBadgeCollectionsAsync.request());

    yield put(getParticipantBadgesAsync.request(participantId));

    yield put(loadParticipantAsync.success());
  } catch (error) {
    yield put(loadParticipantAsync.failure(error));
  }
}

function* getParticipantNotes(participantId: number) {
  yield put(getParticipantNotesAsync.request(participantId));
}

function* getParticipantPosts(participantId: number) {
  yield put(getParticipantPostsAsync.request(participantId));
}

function* getParticipantComments(participantId: number) {
  yield put(getParticipantCommentsAsync.request(participantId));
}

function* getParticipantThreads(participantId: number) {
  yield put(getParticipantThreadsAsync.request(participantId));
}

function* getParticipantNotifications(participantId: number) {
  yield put(getParticipantNotificationsAsync.request(participantId));
}

function* getParticipantTestkits(participantId: number) {
  yield put(getParticipantsTestkitsAsync.request(participantId));
}

function* getParticipantPayments(participantId: number) {
  yield put(getParticipantPaymentsAsync.request(participantId));
}

function* getParticipantBadges(participantId: number) {
  yield put(getParticipantBadgesAsync.request(participantId));
}

function* getParticipantForms(participantId: number) {
  yield put(getParticipantFormsAsync.request(participantId));
}

function* getParticipantUserSurveys(participantId: number) {
  yield put(getParticipantUserSurveysAsync.request(participantId));
}

function* loadParticipantWatcher() {
  yield takeLatest(
    getType(loadParticipantAsync.request),
    loadParticipantHandler
  );
}

function* loadRequestedParticipantHandler(
  action: ReturnType<typeof loadRequestedParticipantAsync.request>
): Generator {
  try {
    const requestedParticipantId: number = (yield select(
      selectors.getRequestedParticipantId
    )) as number;
    const response: AxiosResponse = (yield call(
      getParticipant,
      requestedParticipantId
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.participants
    ) as NormalizerResult;
    const { participants } = entities;

    yield call(updateParticipantsHandler, participants);

    yield call(getParticipantNotes, requestedParticipantId);

    yield call(getParticipantPosts, requestedParticipantId);

    yield call(getParticipantComments, requestedParticipantId);

    yield call(getParticipantThreads, requestedParticipantId);

    yield call(getParticipantForms, requestedParticipantId);

    yield call(getParticipantNotifications, requestedParticipantId);

    yield call(getParticipantUserSurveys, requestedParticipantId);

    yield call(getParticipantTestkits, requestedParticipantId);

    yield call(getParticipantPayments, requestedParticipantId);

    yield call(getParticipantBadges, requestedParticipantId);

    yield put(loadRequestedParticipantAsync.success());
  } catch (error) {
    yield put(loadRequestedParticipantAsync.failure(error));
  }
}

function* loadRequestedParticipantWatcher() {
  yield takeLatest(
    getType(loadRequestedParticipantAsync.request),
    loadRequestedParticipantHandler
  );
}

const saveParticipant = (participant: ParticipantType) => {
  const data = cloneDeep(participant);

  return axios({
    method: 'put',
    url: '/a/participant',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
};

function* saveParticipantHandler(
  action: ReturnType<typeof saveParticipantAsync.request>
): Generator {
  try {
    const participant: ParticipantType = action.payload;

    const response: AxiosResponse = (yield call(
      saveParticipant,
      participant
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.participants
    ) as NormalizerResult;
    const { participants } = entities;

    yield put(saveParticipantAsync.success(participants));
  } catch (error) {
    yield put(saveParticipantAsync.failure(error));
  }
}

function* saveParticipantWatcher() {
  yield takeLatest(
    getType(saveParticipantAsync.request),
    saveParticipantHandler
  );
}

const saveParticipantActualHivStatus = (
  participantId: number,
  status: string
) => {
  return axios({
    method: 'put',
    url: `/a/participant/hivStatus/${participantId}/${status}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

function* saveParticipantActualHivStatusHandler(
  action: ReturnType<typeof saveParticipantActualHivStatusAsync.request>
): Generator {
  try {
    const { participantId, status } = action.payload;

    const response: AxiosResponse = (yield call(
      saveParticipantActualHivStatus,
      participantId,
      status
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.participants
    ) as NormalizerResult;
    const { participants } = entities;

    yield put(saveParticipantActualHivStatusAsync.success(participants));
  } catch (error) {
    yield put(saveParticipantActualHivStatusAsync.failure(error));
  }
}

function* saveParticipantActualHivStatusWatcher() {
  yield takeLatest(
    getType(saveParticipantActualHivStatusAsync.request),
    saveParticipantActualHivStatusHandler
  );
}

const saveParticipantInterviewSelected = (
  participantId: number,
  interviewSelected: Boolean
) => {
  return axios({
    method: 'put',
    url: `/a/participant/interviewSelected/${participantId}/${interviewSelected.toString()}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

function* saveParticipantInterviewSelectedHandler(
  action: ReturnType<typeof saveParticipantInterviewSelectedAsync.request>
): Generator {
  try {
    const { participantId, interviewSelected } = action.payload;

    const response: AxiosResponse = (yield call(
      saveParticipantInterviewSelected,
      participantId,
      interviewSelected
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.participants
    ) as NormalizerResult;
    const { participants } = entities;

    yield put(saveParticipantInterviewSelectedAsync.success(participants));
  } catch (error) {
    yield put(saveParticipantInterviewSelectedAsync.failure(error));
  }
}

function* saveParticipantInterviewSelectedWatcher() {
  yield takeLatest(
    getType(saveParticipantInterviewSelectedAsync.request),
    saveParticipantInterviewSelectedHandler
  );
}

const getParticipantsByStudyArm = (studyArmId: number) => {
  return axios({
    method: 'get',
    url: `/a/participant/participantsByStudyArm/${studyArmId}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

function* getParticipantsByStudyArmHandler(
  action: ReturnType<typeof getParticipantsByStudyArmAsync.request>
): Generator {
  try {
    const response: AxiosResponse<ParticipantType[]> = (yield call(
      getParticipantsByStudyArm,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      response.data,
      entitySchema.participants
    ) as NormalizerResult;
    const { participants } = entities;

    yield put(getParticipantsByStudyArmAsync.success(participants));
  } catch (error) {
    yield put(getParticipantsByStudyArmAsync.failure(error));
  }
}

function* getParticipantsByStudyArmWatcher() {
  yield takeLatest(
    getType(getParticipantsByStudyArmAsync.request),
    getParticipantsByStudyArmHandler
  );
}

const getParticipantsByStudy = (studyId: number) => {
  return axios({
    method: 'get',
    url: `/a/participant/participantsByStudy/${studyId}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

function* getParticipantsByStudyHandler(
  action: ReturnType<typeof getParticipantsByStudyAsync.request>
): Generator {
  try {
    const response: AxiosResponse<ParticipantType[]> = (yield call(
      getParticipantsByStudy,
      action.payload,
    )) as AxiosResponse;
    const { entities } = normalize(
      response.data,
      entitySchema.participants
    ) as NormalizerResult;
    const { participants } = entities;

    yield put(getParticipantsByStudyAsync.success(participants));
  } catch (error) {
    yield put(getParticipantsByStudyAsync.failure(error));
  }
}

function* getParticipantsByStudyWatcher() {
  yield takeLatest(
    getType(getParticipantsByStudyAsync.request),
    getParticipantsByStudyHandler
  );
}

export default function* participantsSaga() {
  yield all([
    fork(loadParticipantWatcher),
    fork(loadRequestedParticipantWatcher),
    fork(saveParticipantWatcher),
    fork(saveParticipantActualHivStatusWatcher),
    fork(saveParticipantInterviewSelectedWatcher),
    fork(getParticipantsByStudyArmWatcher),
    fork(getParticipantsByStudyWatcher),
  ]);
}
