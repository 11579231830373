import axios from '../../../redux/api';
import * as qs from 'query-string';
import { useMutation, useQuery } from 'react-query';
import {
  NotificationType2,
  ScheduleType,
} from 'types/serverTypes/notificationTypes';
import { queryClient } from '../../../App';

export const notificationKeys = {
  all: ['notifications'] as const,
  lists: () => [...notificationKeys.all, 'list'] as const,
  list: (filters: string) => [notificationKeys.lists(), { filters }] as const,
  details: () => [...notificationKeys.all, 'detail'] as const,
  detail: (id: number) => [...notificationKeys.details(), id] as const,
};

export const fetchNotification = async (id: number) => {
  const response = await axios.get(`/a/notification/detail/${id}`);
  return response.data;
};

export const useNotificationDetailQuery = (id: number) =>
  useQuery<NotificationType2>(notificationKeys.detail(id), () =>
    fetchNotification(id)
  );

export const fetchNotifications = async (
  notificationTypes: string[],
  notificationSubTypes: string[],
  enabled?: boolean,
  isAdminOnly?: boolean,
  term?: string
) => {
  const response = await axios.get(`/a/notification/all`, {
    params: {
      notificationTypes,
      notificationSubTypes,
      enabled,
      isAdminOnly,
      term,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'comma' });
    },
  });
  return response.data;
};

export const useNotificationQuery = (
  notificationTypes: string[] = [],
  notificationSubTypes: string[] = [],
  enabled?: boolean,
  isAdminOnly?: boolean,
  term?: string
) =>
  useQuery<NotificationType2[]>(
    [
      ...notificationKeys.list(
        notificationTypes.join(' ').concat(notificationSubTypes.join(' ')) +
          enabled +
          isAdminOnly +
          term
      ),
    ],
    () =>
      fetchNotifications(
        notificationTypes,
        notificationSubTypes,
        enabled,
        isAdminOnly,
        term
      ),
    {
      initialData: () => {
        const allNotifications = queryClient.getQueryData<NotificationType2[]>(
          notificationKeys.all
        );
        const filteredData =
          notificationTypes.length > 0
            ? notificationSubTypes.length > 0
              ? allNotifications?.filter((notification) =>
                  notificationTypes.indexOf(notification.type) > -1 &&
                  notification.subtype
                    ? notificationSubTypes.indexOf(notification.subtype) > -1
                    : false
                ) ?? []
              : allNotifications?.filter(
                  (notification) =>
                    notificationTypes.indexOf(notification.type) > -1
                ) ?? []
            : allNotifications ?? [];

        return filteredData.length > 0 ? filteredData : undefined;
      },
    }
  );

export const updateNotification = async (notification: NotificationType2) => {
  const response = await axios.put(`/a/notification/update`, notification);
  return response.data;
};

export const useUpdateNotification = () =>
  useMutation(updateNotification, {
    onSuccess: (data) => {
      // invalidate query list for notifications to retrieve the new notification from the database
      queryClient.invalidateQueries(notificationKeys.lists());
      queryClient.invalidateQueries(notificationKeys.details());
    },
  });

export const scheduleKeys = {
  all: ['schedules'] as const,
};

export const fetchNotificationSchedules = async () => {
  const response = await axios.get(`/a/notification/schedule`);
  return response.data;
};

export const useNotificationScheduleQuery = () =>
  useQuery<ScheduleType[]>(scheduleKeys.all, () =>
    fetchNotificationSchedules()
  );

export const deleteNotificationTemplate = async (id: number) => {
  const response = await axios.delete(`/a/notification/template/${id}`);
  return response.data;
};

export const useDeleteTemplate = () =>
  useMutation(deleteNotificationTemplate, {
    onSuccess: (data) => {
      // invalidate query list for notifications to retrieve the updated notification from the database
      queryClient.invalidateQueries(notificationKeys.lists());
      queryClient.invalidateQueries(notificationKeys.details());
    },
  });