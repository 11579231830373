import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import {FormType} from "../../types/serverTypes/formTypes";

export const getFormsAsync = createAsyncAction(
  'hmp/admin/GET_FORMS_REQUEST',
  'hmp/admin/GET_FORMS_SUCCESS',
  'hmp/admin/GET_FORMS_FAILURE'
)<number, Optional<NormalizedType<FormType>>, Error>();

export const getParticipantFormsAsync = createAsyncAction(
  'hmp/admin/GET_PARTICIPANT_FORMS_REQUEST',
  'hmp/admin/GET_PARTICIPANT_FORMS_SUCCESS',
  'hmp/admin/GET_PARTICIPANT_FORMS_FAILURE'
)<number, Optional<NormalizedType<FormType>>, Error>();

export const loadFormsAsync = createAsyncAction(
  'hmp/admin/LOAD_FORMS_REQUEST',
  'hmp/admin/LOAD_FORMS_SUCCESS',
  'hmp/admin/LOAD_FORMS_FAILURE',
)<number, void, Error>();

export const loadRequestedFormsAsync = createAsyncAction(
  'hmp/admin/LOAD_REQUESTED_FORMS_REQUEST',
  'hmp/admin/LOAD_REQUESTED_FORMS_SUCCESS',
  'hmp/admin/LOAD_REQUESTED_FORMS_FAILURE',
)<void, void, Error>();

export const saveFormAsync = createAsyncAction(
  'hmp/admin/SAVE_FORM_REQUEST',
  'hmp/admin/SAVE_FORM_SUCCESS',
  'hmp/admin/SAVE_FORM_FAILURE'
)<FormType, Optional<NormalizedType<FormType>>, Error>();

export const updateFormAsync = createAsyncAction(
  'hmp/admin/UPDATE_FORM_REQUEST',
  'hmp/admin/UPDATE_FORM_SUCCESS',
  'hmp/admin/UPDATE_FORM_FAILURE'
)<FormType, Optional<NormalizedType<FormType>>, Error>();

export const updateForms = createStandardAction('hmp/admin/UPDATE_FORMS')<Optional<NormalizedType<FormType>>>();

const formsActions = {
  getFormsAsync,
  getParticipantFormsAsync,
  loadRequestedFormsAsync,
  loadFormsAsync,
  saveFormAsync,
  updateForms,
  updateFormAsync
};

export type FormsActionTypes = ActionType<typeof formsActions>;
