import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import { TopicActionTypes, loadResourceTopicsAsync, loadQnaTopicsAsync, loadForumTopicsAsync, loadGoalTopicsAsync } from './topic.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import {TopicType} from "../../types/serverTypes/forumTypes";

const updateTopicsReducer = produce((draft, topics) => {
  if (!topics) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(topics), Number));
  const ids = Object.keys(topics);
  for (const id of ids) {
    draft.byId[id] = topics[id];
  }
});

export const topicReducer = (state: NormalizedState<TopicType> = initialNormalizedState, action: TopicActionTypes) => {

  switch (action.type) {
    case getType(loadResourceTopicsAsync.success): return updateTopicsReducer(state, action.payload);
    case getType(loadForumTopicsAsync.success): return updateTopicsReducer(state, action.payload);
    case getType(loadQnaTopicsAsync.success): return updateTopicsReducer(state, action.payload);
    case getType(loadGoalTopicsAsync.success): return updateTopicsReducer(state, action.payload);
    default: return state;
  }
};

export default topicReducer;
