import React, { Component } from 'react';
import './activity.scss';
import SecureImage from '../../components/image/SecureImage';
import { BackgroundType } from './ActivityFormContainer';
import {ActivityBackgroundType} from "../../types/serverTypes/activityTypes";

interface ComponentProps {
  creative: boolean;
  editable: boolean;
  background?: ActivityBackgroundType;
  backgroundType: BackgroundType;
  previewImage: string;
  imageFile: any;
}

class ActivityPreview extends Component<ComponentProps, {}> {
  render() {
    const {
      backgroundType,
      background,
      previewImage,
      creative,
      editable
    } = this.props;
    if (
      background
      && backgroundType === 'image'
      && (previewImage.length > 0
        || (background.filename && background.filename.length > 0))
    ) {
      return (
        <div className="activity-preview-container">
          <div className="activity-preview">
            <div className="activity-preview-screen">
              <div
                className="activity-preview-image"
                style={
                  creative || editable
                    ? { backgroundImage: `url(${previewImage})` }
                    : {}
                }
              >
                {!creative && !editable && (
                  <SecureImage
                    url={background.filename}
                    alt="Activity background image."
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } if (
      backgroundType === 'gradient'
      && background
      && background.gradient
    ) {
      return (
        <div className="activity-preview-container">
          <div className="activity-preview">
            <div
              style={{
                background: `linear-gradient(#${background.gradient?.start}, #${background.gradient?.end})`
              }}
              className="activity-preview-screen"
            />
          </div>
        </div>
      );
    }
    return (
      <div className="activity-preview-container">
        <div className="activity-preview">
          <div className="activity-preview-screen">
            <span>
              Content will appear once you complete the Background step.
            </span>
          </div>
        </div>
      </div>
    );

  }
}

export default ActivityPreview;
