import {Button, Card, Col, message, Modal, Row, Spin, Typography} from "antd";
import React, {useEffect, useState} from "react";
import { useParams } from 'react-router';
import TopicDetail from './TopicDetail';
import { FileImageOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { TopicCollectionIds } from '../../../../constant/TopicConstant';
import {
  TopicSortType,
  TopicType,
} from '../../../../types/serverTypes/forumTypes';
import { useGoalTopicQuery, useUpdateTopicSort } from '../queries';
import { UrlParamsType } from '../../../../types';

const { Text } = Typography;

const droppableId = 'droppable';
interface StateProps {}
const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // change background colour if dragging
  background: isDragging ? 'lightblue' : undefined,

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightyellow' : undefined,
});
const TopicManagement = (props: StateProps) => {
  const params = useParams<UrlParamsType>();
  const [topicSelected, setTopicSelected] = useState<TopicType>();
  const [tmpTopicSelected, setTmpTopicSelected] = useState<TopicType>();
  const [resourceTopics, setResourceTopics] = useState<TopicType[]>();
  const [hasChange, setHasChange] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: topics } = useGoalTopicQuery(params.studyId);
  const updateTopicSort = useUpdateTopicSort();

  const [showDiscardChangeConfirm, setShowDiscardChangeConfirm] =
    useState<boolean>(false);

  useEffect(() => {
    setResourceTopics(topics);
  }, [topics]);

  const addNewTopic = (parentTopic?: TopicType) => {
    const topicCollectionId = TopicCollectionIds.GOAL;
    let sortOrder = 1;
    if (
      parentTopic &&
      parentTopic.children &&
      parentTopic.children.length > 0
    ) {
      const len = parentTopic.children.length - 1;
      const sortOrderLast = parentTopic.children[len].sortOrder;
      sortOrder = sortOrderLast ?? 1;
    }

    const newTopic = {
      parentTopicId: parentTopic?.id,
      topicCollectionId: topicCollectionId,
      sortOrder: sortOrder,
    };
    setTopicSelected(newTopic);
  };

  const getTopicRow = (topic: TopicType, index: number, level: number = 0) => {
    let selectedStyle = {};
    if (topic.id === topicSelected?.id) {
      selectedStyle = {
        fontBold: 'bold',
        backgroundColor: '#FEF3BD',
        borderRadius: 6,
      };
    }

    return (
      <Draggable key={topic.id} draggableId={topic.id.toString()} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  marginLeft: 15 * level,
                  marginBottom: 5,
                  paddingRight: 10,
                  cursor: 'pointer',
                  ...selectedStyle,
                }}
                onClick={() => selectTopic(topic)}
              >
                <Text style={{ padding: 3 }}>{topic.title}</Text>
                {!!topic.backgroundImageUri && (
                  <FileImageOutlined style={{ fontSize: 12 }} />
                )}
              </div>
              {level === 0 && (
                <div>
                  <Button
                    shape="round"
                    icon={<PlusOutlined />}
                    size="small"
                    onClick={() => addNewTopic(topic)}
                  >
                    Subtopic
                  </Button>
                </div>
              )}
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={`${droppableId}-${topic.id}`}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {topic.children &&
                      topic.children.map((child, index) => {
                        return getTopicRow(child, index, level + 1);
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </Draggable>
    );
  };
  const selectTopic = (topic: TopicType) => {
    if (hasChange) {
      setTmpTopicSelected(topic);
      setShowDiscardChangeConfirm(true);
    } else {
      setTopicSelected(topic);
    }
  };
  const handleDiscardChange = () => {
    setShowDiscardChangeConfirm(false);
    setTopicSelected(tmpTopicSelected);
    setHasChange(false);
  };
  const handleDiscardCancel = () => {
    setShowDiscardChangeConfirm(false);
    setHasChange(false);
  };
  const onChange = (hasChange: boolean = true) => {
    setHasChange(hasChange);
  };
  const onDragEnd = async (result: any) => {
    try {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      setIsLoading(true);
      if (topics) {
        let existingTopics: TopicSortType[] = [];
        const sourceIndex = result.source.index;
        const destIndex = result.destination.index;

        if (
          result.destination &&
          result.destination.droppableId === droppableId
        ) {
          // top level reorder
          existingTopics = topics.map((topic) => {
            return { id: topic.id, sortOrder: topic.sortOrder };
          });
          // update topic stored in state so UI will show correct order
          if (resourceTopics) {
            const tmpResourceTopic = [...resourceTopics];
            const [removed] = tmpResourceTopic.splice(sourceIndex, 1);
            tmpResourceTopic.splice(destIndex, 0, removed);
            setResourceTopics(tmpResourceTopic);
          }
        } else if (
          result.destination &&
          result.destination.droppableId.startsWith(droppableId + '-')
        ) {
          const parentTargetId = result.destination.droppableId.replace(
            droppableId + '-',
            ''
          );
          const topicChildren = topics.find(
            (topic) => topic.id === parseInt(parentTargetId)
          )?.children;
          if (topicChildren) {
            topicChildren.forEach((childTopic) => {
              existingTopics.push({
                id: childTopic.id,
                sortOrder: childTopic.sortOrder,
              });
            });
            const tmpTopicChildren = [...topicChildren];
            const [removed] = tmpTopicChildren.splice(sourceIndex, 1);
            tmpTopicChildren.splice(destIndex, 0, removed);
            const tmpResourceTopic = resourceTopics?.map((topic) => {
              if (topic.id === parseInt(parentTargetId)) {
                return {
                  ...topic,
                  children: tmpTopicChildren,
                };
              }
              return topic;
            });
            setResourceTopics(tmpResourceTopic);
          }
        }
        if (existingTopics && existingTopics.length > 0) {
          await reorderSubmit(existingTopics, sourceIndex, destIndex);
        }
      }
    } catch (err: any) {
      console.error(err);
      message.error('Error in updating sort', err);
    } finally {
      setIsLoading(false);
    }
  };
  const reorderSubmit = async (
    topics: TopicSortType[],
    sourceIndex: number,
    destIndex: number
  ) => {
    if (topics) {
      const [removed] = topics.splice(sourceIndex, 1);
      topics.splice(destIndex, 0, removed);
      const updatedTopLevel: TopicSortType[] = topics.map((topic, index) => {
        return { id: topic.id, sortOrder: index + 1 };
      });

      // call api to update the sort order
      updateTopicSort.mutate(updatedTopLevel, {
        onSuccess: () => {
          message.success('Topic sort successfully updated.');
        },
        onError: () => {
          message.error('Error updating topic sort.');
        },
      });
    }
  };

  const onDelete = () => {
    setTopicSelected(undefined);
  };
  return (
    <>
      <Card
        title={
          <div className="resources-title">
            <h1>Topics</h1>
            <Button type="primary" onClick={() => addNewTopic()}>
              + Add Topic
            </Button>
          </div>
        }
      >
        {!resourceTopics && <Spin />}
        {resourceTopics && (
          <>
            <Row>
              <Col span={8}>
                <Spin spinning={isLoading}>
                  <div
                    style={{
                      backgroundColor: '#eeeeee',
                      height: 'calc(100vh - 300px)',
                      overflow: 'auto',
                      padding: 10,
                    }}
                  >
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId={droppableId}>
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {resourceTopics?.map((topic, index) => {
                              return getTopicRow(topic, index);
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </Spin>
              </Col>
              <Col span={16}>
                <TopicDetail
                  topic={topicSelected}
                  onChange={onChange}
                  onDelete={onDelete}
                />
              </Col>
            </Row>
          </>
        )}
      </Card>
      <Modal
        title="Unsaved Changes"
        visible={showDiscardChangeConfirm}
        onOk={handleDiscardChange}
        onCancel={handleDiscardCancel}
      >
        <p>There are some unsaved changes. Are you sure you want to proceed?</p>
      </Modal>
    </>
  );
};

export default TopicManagement;
