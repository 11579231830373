import React, {Component} from 'react';
import { clone, find, map, isEqual, isEmpty } from 'lodash';
import FullCalendar, {DateSelectArg, EventChangeArg, EventClickArg, EventInput,} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, {
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IApplicationState from 'types/state.types';
import AppointmentModal from './AppointmentModal';
import {
  createAppointmentAsync,
  getAppointmentsAsync,
  GetAppointmentsQueryParameters,
  updateAppointmentAsync,
} from '../../redux/appointments/appointments.types';
import * as selectors from '../../redux/selectors';
import {
  AppointmentType,
  AvailabilityType,
} from '../../types/serverTypes/appointmentTypes';
import {
  AVAILABILITY_TYPE,
  APPOINTMENT_TYPE,
} from '../../constant/AppointmentConstant';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { Modal, Space, Tooltip } from 'antd';
import {
  getAvailabilitiesAsync,
  GetAvailabilitiesQueryParameters,
} from '../../redux/availabilty/availability.types';
import Text from 'antd/lib/typography/Text';

const { confirm } = Modal;

interface StateProps {
  requestedAppointmentTab: Optional<string>;
  studyId: number;
  defaultAppointmentLength: Optional<number>;
  selectedAppointmentId: Optional<string>;
  appointments: Optional<AppointmentType[]>;
  availabilities: Optional<AvailabilityType[]>;
}

interface DispatchProps {
  getAppointments: typeof getAppointmentsAsync.request;
  getAvailabilities: typeof getAvailabilitiesAsync.request;
  createAppointment: typeof createAppointmentAsync.request;
  updateAppointment: typeof updateAppointmentAsync.request;
}

interface ComponentProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {}

interface ComponentState {
  selectedAppointment: Optional<AppointmentType>;
  events: EventInput[];
  removeEvent?: Function;
  loading: boolean;
}

const defaultState: ComponentState = {
  selectedAppointment: undefined as Optional<AppointmentType>,
  events: [],
  loading: false,
};

class AppointmentCalendar extends Component<ComponentProps, ComponentState> {
  readonly state = clone(defaultState);

  private calendar = React.createRef<FullCalendar>();

  componentDidMount() {
    this.refreshData();
  }

  componentDidUpdate(
    prevProps: Readonly<ComponentProps>,
    prevState: Readonly<ComponentState>,
    snapshot
  ) {
    const {
      requestedAppointmentTab,
      selectedAppointmentId,
      appointments,
      availabilities,
    } = this.props;
    const { events } = this.state;

    if (
      requestedAppointmentTab === 'appointments' &&
      selectedAppointmentId &&
      appointments
    ) {
      const selectedAppointment = find(
        appointments,
        (a: AppointmentType) => a.id === selectedAppointmentId
      );

      if (
        (!prevState.selectedAppointment && selectedAppointment) ||
        !isEqual(selectedAppointment, prevState.selectedAppointment)
      ) {
        this.setState({ selectedAppointment });
      }
    } else if (
      !isEmpty(prevProps.selectedAppointmentId) &&
      isEmpty(selectedAppointmentId)
    ) {
      this.setState({ selectedAppointment: undefined });
    }

    if (
      availabilities?.length &&
      !isEqual(prevProps.availabilities, availabilities)
    ) {
      const updatedEvents = events
        // Removes old availability data
        .filter((e) => e.extendedProps?.type !== AVAILABILITY_TYPE)
        // Maps new data to events
        .concat(
          availabilities.map((a: AvailabilityType) => {
            return {
              id: a.id,
              start: a.startDate,
              end: a.endDate,
              display: 'background',
              backgroundColor: '#FF7785',
              extendedProps: {
                type: AVAILABILITY_TYPE,
              },
            };
          })
        );
      this.setState({
        events: updatedEvents,
      });
    }

    if (
      appointments?.length &&
      !isEqual(prevProps.appointments, appointments)
    ) {
      const updatedEvents = events
        // Removes old appointment data
        .filter((e) => e.extendedProps?.type !== APPOINTMENT_TYPE)
        // Maps new data to events
        .concat(
          appointments.map((a: AppointmentType) => {
            return {
              id: a.id,
              title: a.title,
              start: a.startDate,
              end: a.endDate,
              backgroundColor: a.isConfirmed ? 'lightgreen' : 'lightsalmon',
              extendedProps: {
                type: APPOINTMENT_TYPE,
              },
            };
          })
        );
      this.setState({
        events: updatedEvents,
      });
    }
  }

  selectAppointment = (id: string) => {
    const { studyId, history } = this.props;
    history.push(`/study/${studyId}/appointments/appointments/edit/${id}`);
  };

  handleDateSelect = (selectInfo: DateSelectArg) => {
    const selectedAppointment: AppointmentType = {
      startDate: selectInfo.start,
      endDate: selectInfo.end,
    };

    this.setState({
      selectedAppointment,
      removeEvent: () => selectInfo.view.calendar.unselect(),
    });

    const { history, studyId } = this.props;
    history.push(`/study/${studyId}/appointments/appointments/new`);
  };

  handleEventClick = (clickInfo: EventClickArg) => {
    this.selectAppointment(clickInfo.event.id);
    this.setState({
      removeEvent: () => clickInfo.event.remove(),
    });
  };

  saveAppointment = (appointmentToSave: AppointmentType) => {
    const {
      history,
      studyId,
      appointments,
      defaultAppointmentLength,
      createAppointment,
      updateAppointment,
    } = this.props;
    const startMoment = moment(appointmentToSave.startDate),
      endMoment = moment(appointmentToSave.endDate);

    const beforeSave = find(
      appointments,
      (a) => a.id === appointmentToSave?.id
    );

    const timesHaveChanged =
      beforeSave &&
      (!startMoment.isSame(beforeSave.startDate) ||
        !endMoment.isSame(beforeSave.endDate));

    const diffMinutes = endMoment.diff(startMoment, 'minutes');
    const showNonDefaultMeetingLengthConfirmation =
      timesHaveChanged && diffMinutes !== defaultAppointmentLength;

    const doSave = () => {
      try {
        if (appointmentToSave.id) {
          updateAppointment(appointmentToSave);
        } else {
          createAppointment(appointmentToSave);
        }
      } finally {
        if (this.state.removeEvent) {
          this.state.removeEvent();
        }
        history.push(`/study/${studyId}/appointments/appointments`);
      }
    };

    if (showNonDefaultMeetingLengthConfirmation) {
      confirm({
        title: `This appointments duration is ${diffMinutes} minutes and not the default ${defaultAppointmentLength} minutes. Do you want to continue?`,
        okText: 'Save',
        okType: 'primary',
        onOk: doSave,
      });
    } else {
      doSave();
    }
  };

  /**
   *  handleEventDrop occurs when an existing event is dragged to a new datetime
   *  or when an event is dragged onto the calendar from an external component.
   *  Currently, the second case should never happen, so when we save an event in
   *  this method we'll set the parameter isNew to false.
   */
  handleEventDrop = (changeInfo: EventChangeArg) => {
    const { appointments } = this.props;
    const { event } = changeInfo;
    const appointmentId: string = event._def.publicId;
    const existingAppointment = find(
      appointments,
      (a) => a.id === appointmentId
    );

    if (existingAppointment) {
      const newStart = moment(event.start),
        newEnd = moment(event.end);

      const {
        id,
        title,
        participantId,
        adminId,
        notes,
        adminNotes,
        address,
        phoneNumber,
      } = existingAppointment;
      const newOrUpdateAppointment: AppointmentType = {
        id,
        title,
        startDate: newStart.toDate(),
        endDate: newEnd.toDate(),
        participantId,
        adminId,
        notes,
        adminNotes,
        address,
        phoneNumber,
      };

      confirm({
        title: `Are you sure you want to update this appointment?`,
        okText: 'Save',
        okType: 'primary',
        onOk: () => {
          this.saveAppointment(newOrUpdateAppointment);
        },
        onCancel: () => {
          changeInfo.revert();
        },
      });
    }
  };

  /**
   *  handleEventResize occurs when an existing event is resized to a new start
   *  or end time.
   */
  handleEventResize = (changeInfo: EventResizeDoneArg) => {
    const { appointments } = this.props;
    const { event } = changeInfo;
    const appointmentId: string = event._def.publicId;
    const existingAppointment = find(
      appointments,
      (a) => a.id === appointmentId
    );

    if (existingAppointment) {
      const newStart = moment(event.start),
        newEnd = moment(event.end);

      const {
        id,
        title,
        participantId,
        adminId,
        notes,
        adminNotes,
        address,
        phoneNumber,
      } = existingAppointment;
      const newOrUpdateAppointment: AppointmentType = {
        id,
        title,
        startDate: newStart.toDate(),
        endDate: newEnd.toDate(),
        participantId,
        adminId,
        notes,
        adminNotes,
        address,
        phoneNumber,
      };

      confirm({
        title: `Are you sure you want to update this appointment?`,
        okText: 'Save',
        okType: 'primary',
        onOk: () => {
          this.saveAppointment(newOrUpdateAppointment);
        },
        onCancel: () => {
          changeInfo.revert();
        },
      });
    }
  };

  mapAppointmentTypeToEventInput = (
    appointment: AppointmentType
  ): EventInput => {
    const { id, title, startDate, endDate } = appointment;
    return {
      id,
      title,
      start: startDate,
      end: endDate,
      allDay: false,
      color: !appointment.adminId ? '#ff4d4f' : undefined,
      extendedProps: {
        ...appointment,
        participantId: appointment.participantId?.toString(),
        adminId: appointment.adminId?.toString(),
      },
    };
  };

  refreshData = () => {
    this.setState({
      loading: true,
    });
    // Get appointment data
    const getAppointmentsQueryParameters: GetAppointmentsQueryParameters = {
      includeDeleted: false,
    };
    this.props.getAppointments(getAppointmentsQueryParameters);
    // Get availability data
    const startMoment = moment(
      this.calendar?.current?.getApi().getCurrentData().dateProfile.currentRange
        .start
    );
    const getAvailabilitiesQueryParameters: GetAvailabilitiesQueryParameters = {
      startDate: startMoment.toDate(),
    };
    this.props.getAvailabilities(getAvailabilitiesQueryParameters);
    let events: EventInput[] = map(
      this.props.appointments,
      (a: AppointmentType) => {
        return {
          id: a.id,
          title: a.title,
          start: a.startDate,
          end: a.endDate,
          backgroundColor: a.isConfirmed ? 'lightgreen' : 'lightsalmon',
          extendedProps: {
            type: APPOINTMENT_TYPE,
          },
        };
      }
    );
    if (this.props.availabilities?.length) {
      events = events.concat(
        this.props.availabilities.map((a: AvailabilityType) => {
          return {
            id: a.id,
            start: a.startDate,
            end: a.endDate,
            display: 'background',
            backgroundColor: '#FF7785',
            extendedProps: {
              type: AVAILABILITY_TYPE,
            },
          };
        })
      );
    }
    if (events && events.length) {
      this.setState({
        events,
      });
    }
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 500);
  };

  render() {
    const { selectedAppointment, events, removeEvent, loading } = this.state;

    return (
      <>
        {selectedAppointment && (
          <AppointmentModal
            selectedAppointment={selectedAppointment}
            saveAppointment={this.saveAppointment}
            removeEvent={removeEvent}
          />
        )}
        <FullCalendar
          ref={this.calendar}
          unselectAuto={false}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
          ]}
          customButtons={{
            refresh: {
              text: loading ? 'loading...' : 'refresh',
              click: loading ? undefined : this.refreshData,
            },
          }}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'refresh dayGridMonth,timeGridWeek,timeGridDay,listWeek',
          }}
          initialView="dayGridMonth"
          events={events}
          editable
          selectable
          selectMirror
          dayMaxEvents
          weekends
          select={this.handleDateSelect}
          eventClick={this.handleEventClick}
          eventDrop={this.handleEventDrop}
          eventResize={this.handleEventResize}
        />
        <Space
          style={{
            display: 'flex',
            // justifyContent: 'space-between',
            alignItems: 'center',
            minWidth: '1100px',
            margin: '1.5em auto',
          }}
        >
          <div
            style={{
              width: 14,
              height: 14,
              borderRadius: 7,
              backgroundColor: 'lightgreen',
              marginRight: '2px',
            }}
          />
          <Text style={{ color: 'lightgreen' }}>Confirmed</Text>
          <div
            style={{
              width: 14,
              height: 14,
              borderRadius: 7,
              backgroundColor: 'lightsalmon',
              marginRight: '2px',
            }}
          />
          <Text style={{ color: 'lightsalmon' }}>Unconfirmed</Text>
        </Space>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    selectedAppointmentId: selectors.getUrlNewOrEditId,
    studyId: selectors.getRequestedStudyId,
    requestedAppointmentTab: selectors.getUrlRouteSubpage,
    appointments: selectors.getAppointments,
    defaultAppointmentLength: selectors.getDefaultAppointmentLength,
    availabilities: selectors.getAvailabilities,
  }
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getAppointments: (params: GetAppointmentsQueryParameters | undefined) =>
      dispatch(getAppointmentsAsync.request(params)),
    getAvailabilities: (params: GetAvailabilitiesQueryParameters | undefined) =>
      dispatch(getAvailabilitiesAsync.request(params)),
    createAppointment: (appt: AppointmentType) =>
      dispatch(createAppointmentAsync.request(appt)),
    updateAppointment: (appt: AppointmentType) =>
      dispatch(updateAppointmentAsync.request(appt)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppointmentCalendar));
