import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Input,
  message,
  Modal,
  Select,
  Space,
  Spin,
  Switch,
} from 'antd';
import Form from 'antd/lib/form';
import Draggable from 'react-draggable';
import { convert } from 'html-to-text';
import { ModalHeader } from '../modal/ModalHeader';
import {
  useDeleteTemplate,
  useNotificationDetailQuery,
  useNotificationScheduleQuery,
  useUpdateNotification,
} from '../../pages/study/notification/queries';
import { MessageDeliveryMethods } from '../../constant/serverConstants/notificationConstants';
import {
  TemplateEditorMode,
  NotificationVariable,
} from '../../constant/NotificationConstant';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { NotificationTemplateType } from '../../types/serverTypes/notificationTypes';
import TemplateVariables from './templateVariables.json';
import TemplateEditor from './TemplateEditor';
import AntdIcon from '../antdIcon/AntdIcon';
import { faUsersCog } from '@fortawesome/pro-regular-svg-icons';
import HMPJsonView from '../jsonView/HMPJsonView';
import { isEmpty } from 'lodash';
import { parseJSON } from '../../service/util';

const { confirm } = Modal;
const { Item, List } = Form;
const { Option } = Select;

interface ComponentProps {
  visible: boolean;
  closeHandler: () => void;
  isUnsaved: boolean;
  setIsUnsaved: (isUnsaved: boolean) => void;
  mode: string;
  id: string;
}

const itemStyle = {
  marginBottom: '10px',
  marginTop: '10px',
};

const NotificationForm = ({
  visible,
  isUnsaved,
  setIsUnsaved,
  closeHandler,
  mode,
  id,
}: ComponentProps) => {
  const [form] = Form.useForm();

  const updateNotification = useUpdateNotification();
  const { data: schedules } = useNotificationScheduleQuery();
  const { data } = useNotificationDetailQuery(parseInt(id));
  const deleteTemplate = useDeleteTemplate();
  const [disableDrag, setDisableDrag] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>();
  const [deletedTemplates, setDeletedTemplates] = useState<
    NotificationTemplateType[]
  >([]);
  const [availableMethods, setAvailableMethods] = useState(
    Object.values(MessageDeliveryMethods).filter(
      (value) => !data?.templates.some((t) => t.method === value)
    )
  );
  const variables = useMemo(() => {
    const updatedVariables = {};
    if (data?.type) {
      const baseVariables = TemplateVariables['variables'] ?? [];
      const typeVariables: NotificationVariable[] =
        TemplateVariables[data?.type]?.variables ?? [];
      const subtypeVariables: NotificationVariable[] =
        TemplateVariables[data?.type][data?.subtype]?.variables ?? [];
      baseVariables.map((v) => {
        updatedVariables[v.name] = '${' + v.name + '}';
      });
      typeVariables.map((v) => {
        updatedVariables[v.name] = '${' + v.name + '}';
      });
      subtypeVariables.map((v) => {
        updatedVariables[v.name] = '${' + v.name + '}';
      });
    }
    return updatedVariables;
  }, [data?.type, data?.subtype]);

  useEffect(() => {
    if (!isUnsaved) {
      form.resetFields();
    }
    setAvailableMethods(
      Object.values(MessageDeliveryMethods).filter(
        (value) => !data?.templates.some((t) => t.method === value)
      )
    );
  }, [data]);

  const handleSave = (e: any) => {
    if (isUnsaved) {
      form.validateFields().then((fields) => {
        const {
          description,
          scheduleId,
          enabled,
          isAdminOnly,
          criteria,
          templates,
        } = fields;
        const { type, subtype } = data!;
        // Converts template.body from HTML to plain text if template.method is not equal to 'email'
        const cleanedTemplates =
          templates && templates.length > 0
            ? templates.map((t) => {
                if (t.method !== 'email') {
                  return {
                    ...t,
                    body: convert(t.body),
                  };
                }
                return t;
              })
            : [];

        confirm({
          title: 'Are you sure you want to save this notification?',
          content: '',
          okText: 'Confirm',
          onOk: () => {
            if (mode === 'edit') {
              updateNotification.mutate(
                {
                  id: parseInt(id),
                  type,
                  subtype,
                  description,
                  scheduleId,
                  enabled,
                  templates: cleanedTemplates,
                  isAdminOnly,
                  criteria: !isEmpty(criteria) ? criteria : null,
                },
                {
                  onSuccess: () => {
                    message.success('Notification successfully updated.');
                    closeHandler();
                  },
                  onError: (err) => {
                    message.error('Error updating notification.');
                  },
                }
              );
              if (deletedTemplates && deletedTemplates.length > 0) {
                deletedTemplates.forEach((template) =>
                  deleteTemplate.mutate(template.id, {
                    onError: (err) => {
                      message.error('Error deleting notification templates.');
                    },
                  })
                );
              }
            }
          },
          onCancel() {},
        });
      });
    }
  };

  const handleCancel = (e: any) => {
    closeHandler();
  };

  const onChange = (changedValues, values) => {
    if (!isUnsaved) {
      setIsUnsaved(true);
    }

    if (
      changedValues.templates &&
      changedValues.templates.find((t) => Object(t).hasOwnProperty('method')) &&
      values
    ) {
      const { templates } = values;
      setAvailableMethods(
        Object.values(MessageDeliveryMethods).filter(
          (method) => !templates?.some((t) => t.method === method)
        )
      );
    }
  };

  const onMouseOverHeader = () => {
    if (disableDrag) {
      setDisableDrag(false);
    }
  };

  const onMouseOutHeader = () => {
    if (!disableDrag) {
      setDisableDrag(true);
    }
  };

  const handleTemplateRemove = (
    remove: (index: number | number[]) => void,
    index
  ) => {
    if (mode === 'edit') {
      const existingItems = Array.from(deletedTemplates);
      const item = form.getFieldValue(['templates', index]);
      if (item) {
        const replaceIndex = existingItems.map((i) => i.id).indexOf(item.id);
        if (replaceIndex > -1) {
          existingItems.splice(replaceIndex, 1, item);
        } else {
          existingItems.push(item);
        }
        if (availableMethods.indexOf(item.method) === -1) {
          setAvailableMethods(availableMethods.concat(item.method));
        }
        setDeletedTemplates(Array.from(existingItems));
      }
    }
    remove(index);
  };

  const handleTemplateAdd = (add, method) => {
    let defaultValue = { method };

    if (deletedTemplates) {
      const existingTemplate = deletedTemplates.find(
        (t) => t.method === method
      );
      if (existingTemplate) {
        // If there's an existing deleted template with the same method, restore the template for the user to update
        // rather than delete existing and create a new one.
        defaultValue = existingTemplate;
        setDeletedTemplates(
          deletedTemplates.filter((t) => t.method !== method)
        );
      }
    }
    add(defaultValue);
  };

  const handleCriteriaChange = (e) => {
    if (e.new_value == 'error') {
      return false;
    }
    if (!isUnsaved) {
      setIsUnsaved(true);
    }
    form.setFieldsValue({ criteria: e.updated_src });
  };

  return (
    <Modal
      key={`notification-form-modal-${id}`}
      title={
        <ModalHeader
          title={
            <Space align="center">
              <span style={{ color: '#8185B3' }}>
                <i>type</i>
              </span>
              <h4 style={{ margin: 0, fontWeight: 600 }}>{data?.type} / </h4>
              {data?.subtype ? (
                <>
                  <span style={{ color: '#8185B3' }}>
                    <i>subtype</i>
                  </span>
                  <h4 style={{ margin: 0, fontWeight: 600 }}>
                    {data?.subtype}
                  </h4>
                </>
              ) : null}
              {data?.isAdminOnly ? (
                <AntdIcon
                  title="Only available for admin users"
                  fontAwesomeIcon={faUsersCog}
                />
              ) : null}
            </Space>
          }
          editing
          editClick={() => {}}
          closeClick={handleCancel}
          showSave={isUnsaved}
          saveClick={handleSave}
          onMouseOver={onMouseOverHeader}
          onMouseOut={onMouseOutHeader}
        />
      }
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      closable={false}
      destroyOnClose
      width="50%"
      style={{ minWidth: '400px' }}
      bodyStyle={{ height: '80vh', overflowY: 'auto' }}
      modalRender={(modal) => (
        <Draggable disabled={disableDrag}>{modal}</Draggable>
      )}
    >
      {!loading ? (
        <Form
          form={form}
          name="notification-form"
          key={`notification-form-${id}`}
          layout="vertical"
          colon={false}
          scrollToFirstError
          onValuesChange={onChange}
          initialValues={data}
        >
          <Item
            key={`description-${id}`}
            name="description"
            label="Description:"
            style={itemStyle}
            rules={[{ required: true, max: 1000 }]}
          >
            <Input max={1000} />
          </Item>
          <Item
            key={`scheduleId-${id}`}
            name="scheduleId"
            label="Schedule:"
            style={itemStyle}
          >
            <Select>
              {schedules?.map((s) => (
                <Option key={`schedule-option-${s.id}`} value={s.id}>
                  {s.description}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            key={`enabled-${id}`}
            name="enabled"
            label="Enabled:"
            style={itemStyle}
            valuePropName="checked"
          >
            <Switch />
          </Item>
          <Item
            key={`criteria-${id}`}
            name="criteria"
            label="Criteria:"
            style={itemStyle}
            rules={[
              {
                validator: (rule, value) => {
                  if (value && value.length > 0) {
                    try {
                      parseJSON(value);
                    } catch (err) {
                      return Promise.reject(err);
                    }
                  }
                  return Promise.resolve();
                },
                message: 'Please check syntax, error parsing JSON object.',
              },
            ]}
            valuePropName="src"
          >
            <HMPJsonView
              enableClipboard={false}
              name="criteria"
              displayObjectSize={false}
              onEdit={handleCriteriaChange}
              onDelete={handleCriteriaChange}
              onAdd={handleCriteriaChange}
            />
          </Item>
          <Item label="Templates:" style={itemStyle}>
            <List key={`templates-${id}`} name="templates">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Card
                      key={`template-space-${key}`}
                      style={{ width: '100%', marginBottom: '24px' }}
                    >
                      <Space direction="horizontal">
                        <Item
                          {...restField}
                          label="Method"
                          name={[name, 'method']}
                          rules={[
                            { required: true, message: 'Method required' },
                          ]}
                        >
                          <Select>
                            {availableMethods.map((method) => (
                              <Option
                                key={`method-option-${method}`}
                                value={method}
                              >
                                {method}
                              </Option>
                            ))}
                          </Select>
                        </Item>
                        <Item
                          dependencies={[[name, 'method']]}
                          key={`title-container-${key}-${id}`}
                          noStyle
                        >
                          {({ getFieldValue }) => {
                            return getFieldValue([
                              'templates',
                              name,
                              'method',
                            ]) !== 'sms' ? (
                              <Item
                                {...restField}
                                label="Title"
                                name={[name, 'title']}
                                rules={[{ max: 1000 }]}
                              >
                                <Input
                                  style={{ minWidth: 200 }}
                                  placeholder="Template title"
                                  maxLength={1000}
                                />
                              </Item>
                            ) : null;
                          }}
                        </Item>
                        <Button
                          title="Delete template"
                          danger
                          type="ghost"
                          onClick={() => handleTemplateRemove(remove, name)}
                          style={{ margin: '6px 0 0 0' }}
                        >
                          <DeleteOutlined />
                          Delete
                        </Button>
                      </Space>
                      <Item
                        {...restField}
                        label="Enabled"
                        name={[name, 'enabled']}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Item>
                      <Item
                        dependencies={[[name, 'method']]}
                        key={`body-container-${key}-${id}`}
                        noStyle
                      >
                        {({ getFieldValue }) => {
                          return (
                            <Item
                              {...restField}
                              key={`template-body-${key}-${id}`}
                              label="Body"
                              name={[name, 'body']}
                              rules={[
                                { max: 1000 },
                                {
                                  required: true,
                                  message: 'Template body is required.',
                                },
                              ]}
                              style={{ width: '100%' }}
                            >
                              <TemplateEditor
                                mode={
                                  getFieldValue([
                                    'templates',
                                    name,
                                    'method',
                                  ]) === 'email'
                                    ? TemplateEditorMode.HTML
                                    : TemplateEditorMode.PLAIN
                                }
                                variables={variables}
                              />
                            </Item>
                          );
                        }}
                      </Item>
                    </Card>
                  ))}
                  <Item>
                    {availableMethods.map((method) => (
                      <Button
                        key={`add-template-${method}`}
                        type="dashed"
                        onClick={() => handleTemplateAdd(add, method)}
                        block
                        icon={<PlusOutlined />}
                      >
                        {`Add ${method} template`}
                      </Button>
                    ))}
                  </Item>
                </>
              )}
            </List>
          </Item>
        </Form>
      ) : (
        <Spin />
      )}
    </Modal>
  );
};

export default NotificationForm;
