import { message, Popconfirm, Table } from 'antd';
import { FlagResolutions } from '../../constant/serverConstants/socialConstants';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getFlaggedParticipants } from '../../service/participant/participantService';
import { getStudyId } from '../../service/studyService';
import { FlaggedParticipantType } from '../../types/serverTypes/participantTypes';
import { renderDateWithTime } from '../util/Util';
import { resolveFlag } from '../../service/social/socialService';

const FlaggedParticipants = (props: RouteComponentProps) => {
  const queryClient = useQueryClient();

  const flaggedParticipants = useQuery<FlaggedParticipantType[]>(
    'flaggedParticipants',
    getFlaggedParticipants
  );

  const flagMutation = useMutation(resolveFlag, {
    onError: (error, variables, context) => {
      message.error('Error in updating flag resolution.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('flaggedParticipants');
      message.success('Flag resolution successfully updated.');
    },
  });
  
  const rowClassName = (record: any, index: number): string => {
    return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
  };

  const onResolveFlag = async (record: any) => {
    try {
      flagMutation.mutate({
        flagId: record.id,
        resolution: FlagResolutions.Confirmed,
      });
    } catch (e) {
      message.error('Error occurred while updating: ' + e);
    }
  };

  const onIgnoreFlag = async (record: any) => {
    try {
      flagMutation.mutate({
        flagId: record.id,
        resolution: FlagResolutions.Ignore,
      });
    } catch (e) {
      message.error('Error occurred while updating: ' + e);
    }
  };
  const handleFlaggedRowClick = (record: any) => {
    if (record && record.id) {
      const studyId = getStudyId();
      props.history.push(
        `/study/${studyId}/participants/${record.typeId}`
      );
    }
  };

  const flaggedColumns = [
    {
      title: 'Flagged Participant',
      dataIndex: 'username',
      key: 'username',
      render: (username: any, record: any) => (
        <a onClick={(e) => handleFlaggedRowClick(record)}>{username}</a>
      ),
    },
    {
      title: 'Flagged On',
      dataIndex: 'createDate',
      key: 'createDate',
      render: renderDateWithTime,
    },
    {
      title: 'Resolution',
      dataIndex: 'resolution',
      key: 'resolution',
      sorter: (a: any, b: any) => a.id - b.id,
      render: (feature: any, record: any) => (
        <Popconfirm
          title="Select to resolution:"
          onConfirm={(e) => onResolveFlag(record)}
          onCancel={(e) => onIgnoreFlag(record)}
          okText="Confirm"
          cancelText="Ignore"
        >
          <a>{record.resolution ?? '<Click to Update>'}</a>
        </Popconfirm>
      ),
    },
    {
      title: 'Resolve Date',
      dataIndex: 'resolveDate',
      key: 'resolveDate',
      render: renderDateWithTime,
    },
  ];

  return (
    <Table
      columns={flaggedColumns}
      loading={flaggedParticipants.isLoading}
      dataSource={flaggedParticipants.data}
      rowClassName={rowClassName}
      pagination={false}
      tableLayout="fixed"
      rowKey="id"
    />
  );
};

export default withRouter(FlaggedParticipants);
