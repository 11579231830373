import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import IApplicationState from '../../types/state.types';
import { entitySchema } from '../schema';
import { selectTopics } from '../topics/topic.selectors';
import {ArticleType} from "../../types/serverTypes/articleTypes";

export const selectArticles = (state: IApplicationState) => {
  return state.entities.articles;
};

export const selectedArticleIds = (state: IApplicationState) => {
  return state.ui.articles.articleIds;
};

export const getSelectedArticles = createSelector(
  [selectArticles, selectedArticleIds, selectTopics],
  (articles, articleIds, selectTopics): Optional<ArticleType[]> => {
    if (articles && articles.allIds && articles.allIds.length > 0) {
      const { articles: denormalizedArticles } = denormalize({ articles: articleIds }, entitySchema, { articles: articles.byId, topics: selectTopics.byId });
      return toArray(denormalizedArticles);
    }
    return undefined;
  }
);

export const selectNewArticleId = (state: IApplicationState) => {
  return state.ui.articles.newArticleId;
};

export const getSelectedArticleTopicIds = (state: IApplicationState) => {
  return state.ui.articles.selectedTopicIds;
};

export const getIncludeDeletedArticles = (state: IApplicationState) => {
  return state.ui.articles.includeDeleted;
};

export const getArticleSearchTerm = (state: IApplicationState) => {
  return state.ui.articles.searchTerm;
};

export default {
  selectArticles,
  selectedArticleIds,
  getSelectedArticles,
  selectNewArticleId,
  getSelectedArticleTopicIds,
  getIncludeDeletedArticles,
  getArticleSearchTerm,
};
