import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import IApplicationState from './types/state.types';

interface StateProps {
}

interface ComponentProps extends StateProps {}

class GitInfo extends React.Component<ComponentProps, {}> {

  render() {
    return (
      <div>
        <div>
          RELEASE DATE:
          {' '}
          {process.env.RELEASE_DATE}
        </div>
        <div>
          BRANCH:
          {' '}
          {process.env.BRANCH}
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
});

export default connect(mapStateToProps)(GitInfo);
