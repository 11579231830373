import React, { Component } from 'react';
import './activity.scss';
import uuid from 'uuid';

interface ComponentProps {
  disabled: boolean;
  gradient: any | undefined;
  setGradient: (start: string, end: string) => void;
}

const colorOptions = [
  { start: 'FC4548', end: 'FFB100' },
  { start: 'FFB100', end: '00D5B6' },
  { start: '00D5B6', end: '924DFC' },
  { start: '924DFC', end: 'FC4548' },
  { start: 'FFB100', end: '924DFC' },
  { start: '00D5B6', end: 'FC4548' }
];

class ActivityGradientSelector extends Component<ComponentProps, {}> {
  readonly state = {
    start: null,
    end: null,
    bothSet: false
  };

  componentDidMount() {
    const { gradient } = this.props;
    if (gradient) {
      this.setState({
        start: gradient.start,
        end: gradient.end
      });
    }
  }

  setGradient = (grad: any) => {
    const { setGradient } = this.props;
    const { start, end } = this.state;
    if (grad.start !== start || grad.end !== end) {
      setGradient(grad.start, grad.end);
      this.setState({
        start: grad.start,
        end: grad.end
      });
    }
  };

  render() {
    const { disabled } = this.props;
    const { start, end } = this.state;
    return (
      <div className="activity-gradient">
        <div className="color-panel">
          {colorOptions.map(grad => {
            return (
              <button
                key={uuid()}
                disabled={disabled}
                style={{
                  background: `linear-gradient(#${grad.start}, #${grad.end})`
                }}
                className={[
                  'color-option',
                  grad.start === start && grad.end === end ? 'selected' : ''
                ].join(' ')}
                onClick={(e) => {
                  e.stopPropagation();
                  this.setGradient(grad);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default ActivityGradientSelector;
