import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Layout from 'antd/lib/layout';
import { Route, Switch } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Header from '../components/header/Header';
import AdminSideBar from '../components/sidebar/AdminSideBar';
import Admin from '../pages/admin/home/Admin';
import AdminSettingsPage from '../pages/admin/settings/AdminSettingsPage';
import AdminStudiesPage from '../pages/admin/studies/AdminStudiesPage';
import AdminUsersPage from '../pages/admin/users/AdminUsersPage';
import CmsPage from '../pages/cms/home/CmsPage';
import CmsEditPage from '../pages/cms/home/CmsEditPage';
import * as selectors from '../redux/selectors';
import { loadStudiesAsync } from '../redux/study/study.types';
import { IApiRequestState } from '../types/api.types';
import IApplicationState from '../types/state.types';
import { StudyType } from '../types/serverTypes/studyTypes';

const {
  Content,
  Footer
} = Layout;

interface StateProps {
  studies: Optional<StudyType[]>,
  studiesLoadingStatus: IApiRequestState
}

interface DispatchProps {
  loadStudies: typeof loadStudiesAsync.request
}

interface ComponentProps extends StateProps, DispatchProps {}

class AdminRouter extends React.Component<ComponentProps, any> {

  componentDidMount(): void {
    const { loadStudies, studies } = this.props;
    if (!studies) {
      loadStudies();
    }
  }

  render() {

    const { studies, studiesLoadingStatus } = this.props;

    if (studiesLoadingStatus.isLoading) {
      return <div>Loading...</div>;
    }

    if (studiesLoadingStatus.isError) {
      return <div>Error</div>;
    }

    if (!studies) {
      return <div>The studies could not be loaded</div>;
    }

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <AdminSideBar />
        <Layout id="main">
          <Header />
          <Content id="content">
            <Switch>
              <Route exact path="/admin/home" component={Admin} />
              <Route exact path="/admin/users" component={AdminUsersPage} />
              <Route exact path="/admin/studies" component={AdminStudiesPage} />
              <Route exact path="/admin/settings" component={AdminSettingsPage} />
              <Route exact path="/admin/cms" component={CmsPage} />
              <Route exact path="/admin/cms/article/new" component={CmsEditPage} />
              <Route path="/admin" component={Admin} />
            </Switch>
          </Content>
          <Footer>Footer</Footer>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  studies: selectors.getStudies,
  studiesLoadingStatus: selectors.loadStudiesStatus
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadStudies: () => dispatch(loadStudiesAsync.request())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminRouter);
