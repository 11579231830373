import React, { useEffect, useRef, FC, useMemo } from 'react';

import '@fortawesome/fontawesome-pro/css/all.min.css';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/char_counter.min';
import 'froala-editor/js/plugins/code_beautifier.min';
import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/js/plugins/colors.min';
import 'froala-editor/js/plugins/draggable.min';
import 'froala-editor/js/plugins/emoticons.min';
import 'froala-editor/js/plugins/font_size.min';
import 'froala-editor/js/plugins/fullscreen.min';
import 'froala-editor/js/plugins/help.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/inline_class.min';
import 'froala-editor/js/plugins/inline_style.min';
import 'froala-editor/js/plugins/line_height.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/paragraph_style.min';
import 'froala-editor/js/plugins/print.min';
import 'froala-editor/js/plugins/quote.min';
import 'froala-editor/js/plugins/save.min';
import 'froala-editor/js/plugins/special_characters.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/plugins/url.min';
import 'froala-editor/js/plugins/video.min';
import 'froala-editor/js/plugins/word_paste.min';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/char_counter.min.css';
import 'froala-editor/css/plugins/code_view.min.css';
import 'froala-editor/css/plugins/colors.min.css';
import 'froala-editor/css/plugins/draggable.min.css';
import 'froala-editor/css/plugins/emoticons.min.css';
import 'froala-editor/css/plugins/fullscreen.min.css';
import 'froala-editor/css/plugins/help.min.css';
import 'froala-editor/css/plugins/image.min.css';
import 'froala-editor/css/plugins/special_characters.min.css';
import 'froala-editor/css/plugins/table.min.css';
import 'froala-editor/css/plugins/video.min.css';
import FroalaEditor from 'react-froala-wysiwyg';
import Froala from 'froala-editor';
import { TemplateEditorMode } from '../../constant/NotificationConstant';

interface ComponentProps {
  value?: string;
  onChange?: (model: string | undefined) => void;
  mode?: TemplateEditorMode;
  variables: Object;
}

const defaultConfig = {
  charCounterCount: true,
  charLimit: 1000,
  fontSizeSelection: true,
  fontSize: [
    '8',
    '9',
    '10',
    '11',
    '12',
    '14',
    '16',
    '18',
    '24',
    '30',
    '36',
    '48',
    '60',
    '72',
    '96',
  ],
  iconsTemplate: 'font_awesome_5r',
  placeholderText: 'Template body',
  htmlAllowedAttrs: [
    'ids',
    'accept',
    'accept-charset',
    'accesskey',
    'action',
    'align',
    'allowfullscreen',
    'allowtransparency',
    'alt',
    'aria-.*',
    'async',
    'autocomplete',
    'autofocus',
    'autoplay',
    'autosave',
    'background',
    'bgcolor',
    'border',
    'charset',
    'cellpadding',
    'cellspacing',
    'checked',
    'cite',
    'class',
    'color',
    'cols',
    'colspan',
    'content',
    'contenteditable',
    'contextmenu',
    'controls',
    'coords',
    'data',
    'data-.*',
    'datetime',
    'default',
    'defer',
    'dir',
    'dirname',
    'disabled',
    'download',
    'draggable',
    'dropzone',
    'enctype',
    'for',
    'form',
    'formaction',
    'frameborder',
    'headers',
    'height',
    'hidden',
    'high',
    'href',
    'hreflang',
    'http-equiv',
    'icon',
    'id',
    'ismap',
    'itemprop',
    'keytype',
    'kind',
    'label',
    'lang',
    'language',
    'list',
    'loop',
    'low',
    'max',
    'maxlength',
    'media',
    'method',
    'min',
    'mozallowfullscreen',
    'multiple',
    'muted',
    'name',
    'novalidate',
    'open',
    'optimum',
    'pattern',
    'ping',
    'placeholder',
    'playsinline',
    'poster',
    'preload',
    'pubdate',
    'radiogroup',
    'readonly',
    'rel',
    'required',
    'reversed',
    'rows',
    'rowspan',
    'sandbox',
    'scope',
    'scoped',
    'scrolling',
    'seamless',
    'selected',
    'shape',
    'size',
    'sizes',
    'span',
    'src',
    'srcdoc',
    'srclang',
    'srcset',
    'start',
    'step',
    'summary',
    'spellcheck',
    'style',
    'tabindex',
    'target',
    'title',
    'type',
    'translate',
    'usemap',
    'value',
    'valign',
    'webkitallowfullscreen',
    'width',
    'wrap',
  ],
  htmlAllowedTags: [
    'arms',
    'a',
    'abbr',
    'address',
    'area',
    'article',
    'aside',
    'audio',
    'b',
    'base',
    'bdi',
    'bdo',
    'blockquote',
    'br',
    'button',
    'canvas',
    'caption',
    'cite',
    'code',
    'col',
    'colgroup',
    'datalist',
    'dd',
    'del',
    'details',
    'dfn',
    'dialog',
    'div',
    'dl',
    'dt',
    'em',
    'embed',
    'fieldset',
    'figcaption',
    'figure',
    'footer',
    'form',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'header',
    'hgroup',
    'hr',
    'i',
    'iframe',
    'img',
    'input',
    'ins',
    'kbd',
    'keygen',
    'label',
    'legend',
    'li',
    'link',
    'main',
    'map',
    'mark',
    'menu',
    'menuitem',
    'meter',
    'nav',
    'noscript',
    'object',
    'ol',
    'optgroup',
    'option',
    'output',
    'p',
    'param',
    'pre',
    'progress',
    'queue',
    'rp',
    'rt',
    'ruby',
    's',
    'samp',
    'script',
    'style',
    'section',
    'select',
    'small',
    'source',
    'span',
    'strike',
    'strong',
    'sub',
    'summary',
    'sup',
    'table',
    'tbody',
    'td',
    'textarea',
    'tfoot',
    'th',
    'thead',
    'time',
    'title',
    'tr',
    'track',
    'u',
    'ul',
    'var',
    'video',
    'wbr',
  ],
  pluginsEnabled: [
    'align',
    'charCounter',
    'codeBeautifier',
    'codeView',
    'colors',
    'draggable',
    'emoticons',
    'entities',
    'fontSize',
    'fullscreen',
    'help',
    'image',
    'inlineClass',
    'inlineStyle',
    'lineHeight',
    'link',
    'lists',
    'paragraphFormat',
    'paragraphStyle',
    'print',
    'quote',
    'specialCharacters',
    'table',
    'url',
    'video',
    'wordPaste',
    'customPlugin',
  ],
  toolbarButtons: {
    moreText: {
      buttons: [
        'insertVariable',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontFamily',
        'fontSize',
        'textColor',
        'backgroundColor',
        'inlineClass',
        'inlineStyle',
        'clearFormatting',
      ],
      buttonsVisible: 4,
    },
    moreParagraph: {
      buttons: [
        'alignLeft',
        'alignCenter',
        'alignRight',
        'alignJustify',
        'outdent',
        'indent',
        'formatOL',
        'formatUL',
        'lineHeight',
        'paragraphFormat',
        'paragraphStyle',
        'quote',
      ],
      buttonsVisible: 6,
    },
    moreRich: {
      buttons: [
        'insertLink',
        'insertImage',
        'insertVideo',
        'insertTable',
        'emoticons',
        'specialCharacters',
      ],
      buttonsVisible: 4,
    },
    moreMisc: {
      buttons: [
        'undo',
        'redo',
        'fullscreen',
        'print',
        'getPDF',
        'selectAll',
        'html',
        'help',
      ],
      align: 'right',
      buttonsVisible: 2,
    },
  },
  linkAutoPrefix: '',
};

const plainTextConfig = {
  charCounterCount: true,
  charLimit: 1000,
  iconsTemplate: 'font_awesome_5r',
  placeholderText: 'Template body',
  htmlAllowedAttrs: [],
  htmlAllowedTags: [],
  pluginsEnabled: ['charCounter', 'customPlugin'],
  toolbarButtons: {
    moreText: {
      buttons: ['insertVariable'],
      buttonsVisible: 3,
    },
    moreMisc: {
      buttons: ['undo', 'redo', 'fullscreen'],
      align: 'right',
      buttonsVisible: 2,
    },
  },
};

const insertVariableCommand = 'insertVariable';

export const TemplateEditor: FC<ComponentProps> = ({
  value,
  onChange,
  mode,
  variables,
}: ComponentProps) => {
  const ref = useRef<Froala>(null);
  const config = useMemo(() => {
    return mode === TemplateEditorMode.HTML ? defaultConfig : plainTextConfig;
  }, [mode]);

  // Definte custom dropdown for inserting template variables with dynamic options
  useEffect(() => {
    Froala.DefineIcon(insertVariableCommand, {
      NAME: 'far fa-plus',
      SVG_KEY: 'far fa-plus',
    });
    Froala.RegisterCommand(insertVariableCommand, {
      title: 'Insert Variable',
      type: 'dropdown',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      options: variables,
      callback: function (cmd, val) {
        this.html.insert('${' + val + '}', true);
      },
    });
  }, [variables]);

  const onModelChange = (model: string | undefined) => {
    onChange?.(model);
  };

  return (
    <FroalaEditor
      ref={ref}
      tag="textarea"
      config={config}
      model={value}
      onModelChange={onModelChange}
    />
  );
};

export default TemplateEditor;
