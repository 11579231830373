import React, { Component } from 'react';
import { Button, Form, List } from 'antd';
import Collapse from 'antd/lib/collapse';
import uuid from 'uuid';
import { PlusOutlined } from '@ant-design/icons';
import ScreenerOptionGroup from './ScreenerOptionGroup';
import ScreenerQuestionItem from './ScreenerQuestionItem';
import { OptionType, QuestionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  disabled: boolean;
  questions?: QuestionType[];
  options?: OptionType[];
  updateQuestions: (questions: QuestionType[]) => void;
  updateOptions: (options: OptionType[]) => void;
}
interface ComponentState {
  optionGroups: Map<string, string[]>;
  questions: Map<string, QuestionType>;
  options: Map<string, OptionType>;
  isUnsaved: boolean;
}

class ScreenerQuestionForm extends Component<ComponentProps, ComponentState> {
  private styles = {
    item: {
      width: '100%',
      marginBottom: '10px',
      marginTop: '10px'
    },
    addButton: {
      width: '200px',
      color: '#439AFF',
      borderColor: '#439AFF',
      backgroundColor: '#D0E6FF',
      marginTop: '5px',
      marginBottom: '5px'
    }
  };

  constructor(props: ComponentProps) {
    super(props);
    const { questions, options } = props;
    const defaultState: ComponentState = {
      optionGroups: new Map(),
      questions: new Map(),
      options: new Map(),
      isUnsaved: false
    };
    // Adding options passed in through props to the state options Map
    // and building option groups map
    if (options && options.length > 0) {
      for (const option of options) {
        // Use existing id/uuid when possible, otherwise, generate a new one
        const optionReferenceUUID = option.uuid || (option.id ? option.id.toString() : uuid());
        defaultState.options.set(
          optionReferenceUUID,
          {
            ...option,
            uuid: optionReferenceUUID
          }
        );
        if (option.optionGroupId) {
          const currentGroup = defaultState.optionGroups.get(option.optionGroupId);
          if (currentGroup) {
            defaultState.optionGroups.set(option.optionGroupId, [
              ...currentGroup,
              optionReferenceUUID
            ]);
          } else {
            defaultState.optionGroups.set(option.optionGroupId, [
              optionReferenceUUID
            ]);
          }
        }
      }
    }
    // If questions are passed in as props, copy those to state and
    // generate UUIDs
    if (questions && questions.length > 0) {
      for (const question of questions) {
        const questionReferenceUUID = question.uuid || (question.id ? question.id.toString() : uuid());
        defaultState.questions.set(questionReferenceUUID, { ...question, uuid: questionReferenceUUID });
      }
    }
    this.state = {
      ...defaultState
    };
  }

  componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState) {
    if (!prevState.isUnsaved && this.state.isUnsaved) {
      const { questions, options } = this.state;
      this.props.updateQuestions([...questions.values()]);
      this.props.updateOptions([...options.values()]);
      this.setState({
        isUnsaved: false
      });
    }
  }

  addQuestion = () => {
    const { questions } = this.state;
    const newUUID = uuid();
    this.setState({
      questions: new Map(
        questions.set(newUUID, {
          uuid: newUUID,
          text: ''
        })
      ),
      isUnsaved: true
    });
  };

  updateQuestion = (question: QuestionType) => {
    const { questions } = this.state;
    this.setState({
      questions: new Map(questions.set(question.uuid, { ...question })),
      isUnsaved: true
    });
  };

  removeQuestion = (uuid: string) => {
    const { questions } = this.state;
    questions.delete(uuid);
    this.setState({
      questions: new Map(questions),
      isUnsaved: true
    });
  };

  addOption = (optionGroupId: string) => {
    const { optionGroups, options } = this.state;
    const groupToAdd = optionGroups.get(optionGroupId);
    const newUUID = uuid();
    if (groupToAdd) {
      this.setState({
        optionGroups: new Map(
          optionGroups.set(optionGroupId, [...groupToAdd, newUUID])
        ),
        options: new Map(
          options.set(newUUID, {
            uuid: newUUID,
            text: '',
            optionGroupId
          })
        ),
        isUnsaved: true
      });
    }
  };

  updateOption = (option: OptionType) => {
    const { options } = this.state;
    this.setState({
      options: new Map(options.set(option.uuid, { ...option })),
      isUnsaved: true
    });
  };

  removeOption = (uuid: string) => {
    const { options, optionGroups } = this.state;
    const optionToDelete = options.get(uuid);
    if (optionToDelete) {
      options.delete(uuid);
      if (optionToDelete.optionGroupId) {
        // this.removeOptionGroup calls this method which means the optionGroup may already
        // have been deleted, so this logic ensures that we only update the optionGroup if
        // it hasn't been deleted yet
        const groupToDeleteFrom = optionGroups.get(optionToDelete.optionGroupId);
        if (groupToDeleteFrom) {
          this.setState({
            optionGroups: new Map(
              optionGroups.set(
                optionToDelete.optionGroupId,
                groupToDeleteFrom.filter((id) => id !== uuid)
              )
            )
          });
        }
      }
      this.setState({
        options: new Map(options),
        isUnsaved: true
      });
    }
  };

  addOptionGroup = () => {
    const { optionGroups } = this.state;
    this.setState({
      optionGroups: new Map(optionGroups.set(uuid(), []))
    });
  };

  removeOptionGroup = (optionGroupId: string) => {
    const { optionGroups } = this.state;
    const optionsToRemove = optionGroups.get(optionGroupId);
    if (optionsToRemove && optionsToRemove.length > 0) {
      optionsToRemove.forEach((uuid) => this.removeOption(uuid));
    }
    optionGroups.delete(optionGroupId);
    this.setState({
      optionGroups: new Map(optionGroups)
    });
  };

  validateQuestions = (rule, value) => {
    try {
      const { questions } = this.state;
      if (questions.size === 0) {
        throw new Error('No questions defined.');
      }
      questions.forEach((q) => {
        if (!q.optionGroupId) {
          throw new Error('All questions must be assigned a group ID.');
        }
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  render() {
    const { disabled } = this.props;
    const { questions, options, optionGroups } = this.state;
    const optionSets: [string, OptionType[]][] = [
      ...optionGroups.entries()
    ].map(([optionGroupId, optionIds]) => {
      return [optionGroupId, optionIds.map((id) => options.get(id)!)];
    });
    return (
      <>
        <Item
          key="option-groups"
          label="Option Groups:"
          style={this.styles.item}
        >
          <Collapse ghost>
            {optionSets.map(([optionGroupId, options], index) => {
              return (
                <ScreenerOptionGroup
                  key={`option-group-${optionGroupId}`}
                  disabledProp={disabled}
                  header={`Group ${index}`}
                  optionGroupId={optionGroupId}
                  options={options}
                  addOption={this.addOption}
                  updateOption={this.updateOption}
                  removeOption={this.removeOption}
                  removeGroup={() => this.removeOptionGroup(optionGroupId)}
                />
              );
            })}
          </Collapse>
          <Button
            key="add-option-group"
            disabled={disabled}
            style={this.styles.addButton}
            onClick={this.addOptionGroup}
            type="dashed"
            title="Add new option group"
          >
            <PlusOutlined />
            {' '}
            Add option group
          </Button>
        </Item>
        <Item
          key="screener-questions"
          name="screener-questions"
          label="Questions"
          style={this.styles.item}
          validateTrigger={['onChange', 'onSubmit', 'onBlur', 'onFocus']}
          rules={[{ required: true, validator: this.validateQuestions }]}
        >
          {questions.size > 0 && (
            <List
              itemLayout="horizontal"
              dataSource={[...questions.values()]}
              renderItem={(q) => {
                return (
                  <ScreenerQuestionItem
                    key={`question-item-${q.uuid}`}
                    disabled={disabled}
                    question={q}
                    groups={optionSets}
                    updateQuestion={this.updateQuestion}
                    removeQuestion={() => this.removeQuestion(q.uuid)}
                  />
                );
              }}
            />
          )}
          <Button
            key="add-question"
            disabled={disabled}
            style={this.styles.addButton}
            onClick={() => this.addQuestion()}
            type="dashed"
            title="Add new question"
          >
            <PlusOutlined />
            {' '}
            Add question
          </Button>
        </Item>
      </>
    );
  }
}

export default ScreenerQuestionForm;
