import { getStudyId } from '../studyService';
import axios from '../../redux/api';
import { matchPath } from 'react-router-dom';

export const getParticipants = async () => {
  const studyId = getStudyId();
  const response = await axios({
    method: 'get',
    url: `/a/participant/participantsByStudy/${studyId}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

export const getFlaggedParticipants = async () => {
  const response = await axios({
    method: 'get',
    url: `/a/social/flaggedParticipants`,
  });
  return response.data;
};

export const getDefaultTab = (pathname) => {
  const match = matchPath<{ participantsSubpage: string }>(pathname, {
    path: '/study/:studyId/participants/:participantsSubpage',
  });

  if (match && match.params && match.params.participantsSubpage) {
    return match.params.participantsSubpage;
  }
  return 'participants';
};
