export const cleanstring = (dirty: string) => {
  if (dirty) {
    return dirty
      .replace(/‘/g, "'")
      .replace(/’/g, "'")
      .replace(/“/g, '"')
      .replace(/”/g, '"');
  }
  return "";
};

export const parseJSON = (stringToParse: any, reviver?) => {
  // check if the stringToParse is really a string
  if (typeof stringToParse === 'string' || stringToParse instanceof String) {
    return JSON.parse(stringToParse as string, reviver);
  }
  // return as is since stringToParse is most likely already parsed
  // This can happen if the data is fetched from db and mariadb is on 10.5.7 or above.
  // The JSON data is already parsed from database when it is fetched.
  return stringToParse;
};
