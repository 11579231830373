import React, { Component } from 'react';
import Tabs from 'antd/lib/tabs';
import './resourceLandingPage.scss';
import TopicManagement from './topic/TopicManagement';
import ResourceList from './ResourceList';

class ResourcesLandingPage extends Component {
  render() {

    return (
      <div id="hmp-resources-landing-page">
        <div className="hmp-tab-container">
          <Tabs defaultActiveKey="resources">
            <Tabs.TabPane tab="Resources" key="resources">
              <ResourceList />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Topics" key="topics">
              <TopicManagement />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default ResourcesLandingPage;
