import { Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../redux/selectors';
import IApplicationState from '../../types/state.types';
import { getCompletedTestkitsAsync } from '../../redux/testkit/testkit.types';
import { renderDateWithoutTime } from '../util/Util';
import TestkitCompletedCard from './TestkitCompletedCard';
import Username from '../../components/username/Username';
import { TestkitType } from '../../types/serverTypes/testkitTypes';

interface StateProps {
  testkits: Optional<TestkitType[]>
}

interface DispatchProps {
  loadCompletedTestkits: typeof getCompletedTestkitsAsync.request
}

interface ComponentProps extends DispatchProps, StateProps {
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a: any, b: any) => a.id - b.id
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: (a: any, b: any) => `${a.type}`.localeCompare(b.type),
    filters: [{ text: 'OraQuick', value: 'oraquick' }, { text: 'HEMASPOT', value: 'hemaspot' }],
    onFilter: (value:number, record:any) => record.type.toLowerCase() === value
  },
  {
    title: 'Request Date',
    dataIndex: 'requestDate',
    key: 'requestDate',
    render: renderDateWithoutTime,
    sorter: (a: any, b: any) => moment(a.requestDate).unix() - moment(b.requestDate).unix()
  },
  {
    title: 'Username',
    dataIndex: 'participant.id',
    key: 'username',
    render: (value: any, row: any) => <Username participantId={value} />,
    sorter: (a: any, b: any) => `${a.participant.username}`.localeCompare(b.participant.username)
  }
];

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

class TestkitCompletedTable extends Component<ComponentProps, {}> {
  readonly state = {
    studyId: 1
  }

  componentDidMount() {
    const { loadCompletedTestkits } = this.props;
    loadCompletedTestkits({ studyId: this.state.studyId });
  }

  rowRender = (record: any) => <TestkitCompletedCard testkit={record} />;

  render() {
    const { testkits } = this.props;
    return (
      <Table
        columns={columns}
        dataSource={testkits}
        scroll={{ y: 'calc(100vh - 200px)' }}
        pagination={false}
        rowClassName={rowClassName}
        rowKey="id"
        expandedRowRender={this.rowRender}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  testkits: selectors.getCompletedTestkits
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadCompletedTestkits: (args: {studyId: number}) => dispatch(getCompletedTestkitsAsync.request(args))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestkitCompletedTable);
