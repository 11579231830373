import { push } from 'connected-react-router';
import { User } from 'oidc-client';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { CallbackComponent } from 'redux-oidc';
import userManager from '../../oidc/UserManager';

interface ComponentProps extends RouteComponentProps<{}> {
  dispatch: Dispatch;
}

class Callback extends React.Component<ComponentProps, {}> {

  successCallback = (user: User) => {
    // the user object gets the browser's URL before
    // redirection was triggered passed into its state
    // when triggerAuthFlow is set to `true`
    const urlBeforeRedirection = user.state.path;
    this.props.dispatch(push(urlBeforeRedirection));
  };

  errorCallback = (error: Error) => {

    // TODO Create Error Component to display error messages reaturned from the server
    this.props.dispatch(push('/'));
  };

  // TODO Create a full page spinner component to show the redirect is in progress.
  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}
      >
        <div>Redirecting</div>
      </CallbackComponent>
    );
  }
}

export default connect()(Callback);
