import Collapse from 'antd/lib/collapse';
import Card from 'antd/lib/card';
import React, { Component } from 'react';
import NotesTable from '../../../../components/note/NotesTable';
import './participant.scss';
import Button from 'antd/lib/button';
import Form, { FormInstance } from 'antd/lib/form';
import Input from 'antd/lib/input';
import PageHeader from 'antd/lib/page-header';
import { ParticipantType } from '../../../../types/serverTypes/studyTypes';
import { NoteType } from '../../../../types/serverTypes/noteTypes';

interface ComponentProps {
  participant: ParticipantType;
  notes: Optional<NoteType[]>;
  saveClickHandler: (note: NoteType) => void;
}

const initialState = {
  inAddMode: false as boolean,
  addedNote: {} as NoteType
};
type ComponentState = Readonly<typeof initialState>;

class ParticipantNoteCard extends Component<ComponentProps, ComponentState> {
  readonly state: ComponentState = initialState;

  form = React.createRef<FormInstance>();

  handleSubmit = () => {
    if (this.form.current) {
      this.form.current.validateFields().then(() => {
        this.state.addedNote.participantId = this.props.participant.id;
        this.props.saveClickHandler(this.state.addedNote);
        this.setState({ inAddMode: false });
      });
    }
  };

  handleAddClick = () => {
    this.setState({ inAddMode: true });
  };

  handleCancelClick = () => {
    this.setState({ inAddMode: false });
  };

  render() {
    const { participant, notes } = this.props;
    const { addedNote } = this.state;
    const { Panel } = Collapse;
    const genExtra = () => (
      <Button size="small" onClick={this.handleAddClick}>
        Add Note
      </Button>
    );

    return (
      <div id="participant-notes-container">
        {this.state.inAddMode ? (
          <Card key="add">
            <PageHeader title="Add Note" />
            <Form
              ref={this.form}
              layout="inline"
              key={participant.id}
              onFinish={this.handleSubmit}
            >
              <Form.Item
                name="body"
                style={{ alignItems: 'flex-start', marginLeft: '20px' }}
                rules={[{ required: true, message: 'Note is required!' }]}
                initialValue=""
              >
                <Input
                  onChange={(v) => {
                    addedNote.body = v.target.value;
                  }}
                  style={{ width: '750px' }}
                />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit">Save</Button>
              </Form.Item>
              <Form.Item>
                <Button onClick={this.handleCancelClick}>Cancel</Button>
              </Form.Item>
            </Form>
          </Card>
        ) : (
          <Collapse expandIconPosition="left">
            <Panel
              key="1"
              header={`Notes (${notes ? notes.length : 0})`}
              extra={genExtra()}
            >
              <NotesTable notes={notes} />
            </Panel>
          </Collapse>
        )}
      </div>
    );
  }
}

export default ParticipantNoteCard;
