import IApplicationState from '../../types/state.types';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import entitySchema from '../schema';
import {ForumDetailType} from "../../types/serverTypes/forumTypes";
import {createSelector} from "reselect";

export const selectForumUncategorizedPostIds = (state: IApplicationState) => {
  return state.ui.forums.uncategorizedPostIds;
};

export const selectFlaggedPostIds = (state: IApplicationState) => {
  return state.ui.forums.flaggedPostIds;
};

export const selectForums = (state: IApplicationState) => {
  return state.entities.forums;
};

export const getForums = createSelector(
  [selectForums],
  (forums): Optional<ForumDetailType[]> => {
    if (forums && forums.allIds && forums.allIds.length > 0) {
      const { forums: denormalizedForums } = denormalize({ forums: forums.allIds }, entitySchema, { forums: forums.byId });
      return toArray(denormalizedForums);
    }
    return undefined;
  }
);

export default { selectForumUncategorizedPostIds};
