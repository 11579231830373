import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  Alert, Button, DatePicker, Descriptions, Modal, Select
} from 'antd';
import { reviewTestkitAsync } from '../../redux/testkit/testkit.types';
import SecureImage from '../../components/image/SecureImage';
import { TestkitType } from '../../types/serverTypes/testkitTypes';

const { Item } = Descriptions;

const { Option } = Select;

interface StateProps {
}

interface DispatchProps {
  reviewTestkit: typeof reviewTestkitAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {
  testkit: TestkitType
}

const inputStyle = {
  marginTop: '5px',
  marginBottom: '5px'
};

class TestkitNeedsReviewCard extends Component<ComponentProps, {}> {
  readonly state = {
    reportDate: undefined,
    actualResult: '',
    reviewError: false,
    errorMessage: 'Must provide Actual Result to review a test kit.',
    modalVisible: false
  };

  componentDidMount() {
  }

  onChange(state: any) {
    this.setState(state);
  }

  handleReviewTestkit = () => {
    const { reviewTestkit, testkit } = this.props;
    const { actualResult, reportDate } = this.state;
    if (testkit.type.match(/oraquick/i) && actualResult && actualResult.length > 0 && actualResult.match(/(positive|negative|unknown)/i)) {
      const reviewArg = {
        id: testkit.id,
        actualResult
      };
      reviewTestkit(reviewArg);
      this.setState({ reviewError: false });
    } else if (testkit.type.match(/hemaspot/i) && !!reportDate) {
      const reviewArg = {
        id: testkit.id,
        reportDate
      };
      reviewTestkit(reviewArg);
    } else {
      if (testkit.type.match(/oraquick/i)) {
        this.setState({ reviewError: true, errorMessage: 'Must provide Actual Result to review a test kit.' });
      } else {
        this.setState({ reviewError: true, errorMessage: 'Must provide Report Date to review a test kit.' });
      }
      if (actualResult.length > 0 && !actualResult.match(/(positive|negative|unknown)/i)) {
        this.setState({ errorMessage: "Actual Result must either be 'positive', 'negative' or 'unknown'" });
      }
    }
  };

  render() {
    const { testkit } = this.props;
    const { participant } = testkit;

    if (!participant) {
      return <div>Participant Not Found.</div>;
    }

    return (
      <div>
        <Descriptions layout="vertical" column={testkit.type.match(/oraquick/i) ? 5 : 3}>
          <Item label="Fulfillment" span={1}>
            <span>{`${testkit.firstName} ${testkit.lastName}`}</span>
            <br />
            <span>{`${testkit.address1}`}</span>
            <br />
            {testkit.address2 ? (
              <span>
                {`${testkit.address2}`}
                <br />
              </span>
            ) : ''}
            <span>{`${testkit.city}, ${testkit.state} ${testkit.postalCode}`}</span>
            <br />
          </Item>
          { testkit.type.match(/oraquick/i)
            ? <Item label="Report Date"><span style={inputStyle}>{moment(testkit.reportDate).calendar()}</span></Item>
            : undefined}
          <Item label="Current Hiv Status"><span style={inputStyle}>{participant.actualHivStatus}</span></Item>
          {testkit.type.match(/oraquick/i)
            ? <Item label="Reported Result"><span style={inputStyle}>{testkit.reportedResult}</span></Item>
            : undefined}
          <Item label="Actions">
            { testkit.type.match(/oraquick/i)
              ? (
                <div>
                  <Button type="primary" onClick={(e) => { this.onChange({ modalVisible: true }); }} style={{ marginTop: '5px' }}>View Image</Button>
                  <br />
                  {' '}
                  <br />
                  <Select placeholder="Actual Results" style={{ width: 120 }} onChange={(value) => this.onChange({ actualResult: value })}>
                    <Option value="POSITIVE">POSITIVE</Option>
                    <Option value="NEGATIVE">NEGATIVE</Option>
                    <Option value="UNKNOWN">UNKNOWN</Option>
                  </Select>
                  <Modal
                    bodyStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    centered
                    closable={false}
                    visible={this.state.modalVisible}
                    onOk={() => this.onChange({ modalVisible: false })}
                    onCancel={() => this.onChange({ modalVisible: false })}
                  >
                    <SecureImage url={`/a/testkit/${testkit.participantId}/${testkit.id}/image`} alt="OraQuick test kit." />
                  </Modal>
                  <br />
                </div>
              )
              : (
                <div>
                  <br />
                  <DatePicker placeholder="Report Date" style={inputStyle} onChange={(date, dateString) => this.onChange({ reportDate: dateString })} />
                </div>
              )}
            <br />
            <Button type="primary" onClick={this.handleReviewTestkit} style={{ marginTop: '5px' }}>Mark Completed</Button>
          </Item>
        </Descriptions>
        <Alert style={this.state.reviewError ? { visibility: 'visible' } : { visibility: 'hidden' }} message={this.state.errorMessage} type="error" showIcon />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    reviewTestkit: (testkit) => dispatch(reviewTestkitAsync.request(testkit))
  };
};

export default connect(null, mapDispatchToProps)(TestkitNeedsReviewCard);
