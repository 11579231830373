import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import IApplicationState from '../../types/state.types';
import entitySchema from '../schema';
import { AvailabilityType } from '../../types/serverTypes/appointmentTypes';

export const selectAvailabilities = (state: IApplicationState) => {
  return state.entities.availabilities;
};

export const getAvailabilities = createSelector(
  [selectAvailabilities],
  (availabilities): Optional<AvailabilityType[]> => {
    if (availabilities && availabilities.allIds && availabilities.allIds.length > 0) {
      const { availabilities: denormalizedAvailabilities } = denormalize({ availabilities: availabilities.allIds }, entitySchema, { availabilities: availabilities.byId });
      return toArray(denormalizedAvailabilities);
    }
    return undefined;
  }
);

export default { getAvailabilities };
