import React, { Component } from 'react';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import Form from 'antd/lib/form';
import '../activity.scss';
import OptionItem from './OptionItem';
import { OptionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  typeId: number;
  disabled: boolean;
  options: OptionType[];
  updateOption: (option: OptionType, index: number) => void;
  addOption: () => void;
  removeOption: (index: number) => void;
  swapOptionPosition?: (indexA: number, indexB: number) => void;
}

class DynamicOptionItems extends Component<ComponentProps, {}> {
  onAddClick = (e: React.MouseEvent<HTMLElement>) => {
    const { addOption } = this.props;
    e.preventDefault();
    addOption();
  };

  moveUp = (index: number) => {
    const { swapOptionPosition } = this.props;
    if (swapOptionPosition) {
      swapOptionPosition(index, index - 1);
    }
  }

  moveDown = (index: number) => {
    const { swapOptionPosition } = this.props;
    if (swapOptionPosition) {
      swapOptionPosition(index, index + 1);
    }
  }

  render() {
    const {
      disabled,
      options,
      typeId,
      updateOption,
      removeOption
    } = this.props;
    const items = options.map((o: OptionType, index: number) => {
      return (
        <li key={`option-item-${o.id ? o.id : o.uuid}`} className="option-list-container">
          <OptionItem
            typeId={typeId}
            option={o}
            disabled={disabled}
            updateOption={(o: OptionType) => updateOption(o, index)}
            moveUp={() => this.moveUp(index)}
            moveDown={() => this.moveDown(index)}
          />
          {options.length > 1 && (!disabled) && (
            <button
              className="dashed-danger-button"
              onClick={() => removeOption(index)}
            >
              <MinusOutlined />
              {' '}
              Remove Option
            </button>
          )}
        </li>
      );
    });
    return (
      <ul className="dynamic-option-items">
        {items}
        <Item>
          <Button
            disabled={disabled}
            type="dashed"
            onClick={this.onAddClick}
            style={{ width: '100%' }}
          >
            <PlusOutlined />
            {' '}
            Add option
          </Button>
        </Item>
      </ul>
    );
  }
}

export default DynamicOptionItems;
