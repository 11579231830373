import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { getAnalyticsReportAsync } from './analytics.types';

// sortBy, sortDir, participantId, studyId, studyArmId, pageNumber, pageSize
const getAnalytics = (sortBy?:string, sortDir?:string, participantId?:number, studyId?:number, studyArmId?:number, pageNumber?:number, pageSize?:number ) => {
  let url = `/a/event/all`;

  //todo do we need the params?
  return axios({
    method: 'get',
    url
  });
};

function* getAnalyticsReportHandler(action: ReturnType<typeof getAnalyticsReportAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(getAnalytics)) as AxiosResponse;
    yield put(getAnalyticsReportAsync.success(response.data));
  } catch (error) {
    yield put(getAnalyticsReportAsync.failure(error));
  }
}

function* getAnalyticsReportWatcher() {
  yield takeLatest(getType(getAnalyticsReportAsync.request), getAnalyticsReportHandler);
}

export default function* analyticsSaga() {
  yield all([
    fork(getAnalyticsReportWatcher)
  ]);
}
