import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import IApplicationState from '../../types/state.types';
import entitySchema from '../schema';
import { StringLookupType } from '../../types/serverTypes';

export const selectStringLookups = (state: IApplicationState) => {
  return state.entities.stringLookups;
};

export const getStringLookups = createSelector(
  [selectStringLookups],
  (stringLookups): Optional<StringLookupType[]> => {
    if (
      stringLookups &&
      stringLookups.allIds &&
      stringLookups.allIds.length > 0
    ) {
      const { stringLookups: denormalizedStringLookups } = denormalize(
        { stringLookups: stringLookups.allIds },
        entitySchema,
        { stringLookups: stringLookups.byId }
      );
      return toArray(denormalizedStringLookups);
    }
    return undefined;
  }
);

export default { getStringLookups };
