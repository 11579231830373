import React from 'react';
import * as _ from 'lodash';
import generateUUID from 'uuid';
import {
  Input, Form
} from 'antd';
import '../activity.scss';
import QuizQuestion from './QuizQuestion';
import FillInTheBlankQuestion from './FillInTheBlankQuestion';
import CategoryQuestion from './CategoryQuestion';
import DynamicFeedbackItems from '../feedback/DynamicFeedbackItems';
import { FeedbackType, OptionType, QuestionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  typeId: number;
  disabled: boolean;
  question: QuestionType;
  options: OptionType[];
  updateQuestion: (q: QuestionType) => void;
}

enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener,
  question
}
const defaultFeedback = {
  uuid: '',
  title: '',
  text: '',
  min: undefined,
  max: undefined,
  isOverallFeedback: false
};
const itemStyle = {
  width: '100%',
  marginBottom: '10px',
  marginTop: '10px'
};

const QuestionItem = (props: ComponentProps) => {
  const {
    question,
    updateQuestion,
    typeId,
    disabled,
    options
  } = props;
  const {
    text, feedback, uuid, id
  } = question;
  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    question.text = e.target.value;
    updateQuestion(question);
  };
  const updateFeedback = (fb: FeedbackType, index: number) => {
    if (question.feedback) {
      question.feedback[index] = fb;
      updateQuestion(question);
    }
  };
  const addFeedback = () => {
    const updatedQuestion = _.cloneDeep(question);
    const newFeedback = _.cloneDeep(defaultFeedback);
    newFeedback.uuid = generateUUID();
    if (!updatedQuestion.feedback) {
      updatedQuestion.feedback = [newFeedback];
    } else {
      updatedQuestion.feedback[updatedQuestion.feedback.length] = newFeedback;
    }
    updateQuestion(updatedQuestion);
  };
  const removeFeedback = (index: number) => {
    let i = 0;
    question.feedback = question.feedback ? question.feedback.filter(f => { return index !== i++; }) : [];
    updateQuestion(question);
  };
  let item;
  switch (typeId) {
    case Type.quiz:
      item = (
        <QuizQuestion
          disabled={disabled}
          question={question}
          onTextChange={onTextChange}
          updateQuestion={updateQuestion}
        />
      );
      break;
    case Type.fill_in_the_blank:
      item = (
        <FillInTheBlankQuestion
          disabled={disabled}
          question={question}
          onTextChange={onTextChange}
          updateQuestion={updateQuestion}
        />
      );
      break;
    case Type.category:
      item = (
        <CategoryQuestion
          disabled={disabled}
          question={question}
          options={options}
          onTextChange={onTextChange}
          updateQuestion={updateQuestion}
        />
      );
      break;
    default:
      item = <Input spellCheck disabled={disabled} placeholder="Question text" value={text} onChange={onTextChange} />;
      break;
  }
  const displayFeedback = [Type.category].indexOf(typeId) === -1;
  return (
    <Item
      key={id || uuid}
      style={itemStyle}
    >
      <div className="question-container">
        {item}
        {displayFeedback
      && <h4>Question Feedback:</h4>
      && (
      <DynamicFeedbackItems
        type={Type.question}
        disabled={disabled}
        feedback={feedback || []}
        options={options || []}
        updateFeedback={updateFeedback}
        addFeedback={addFeedback}
        removeFeedback={removeFeedback}
      />
      )}
      </div>
    </Item>
  );
};

export default QuestionItem;
