export enum CommentTypes {
  POST = 'post',
  QNA = 'qna',
}
export enum FlaggableTypes {
  Post = 'post',
  Comment = 'comment',
  Participant = 'participant'
}
export enum FlagResolutions {
  Ignore = 'ignored',
  Confirmed = 'confirmed'
}