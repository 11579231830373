import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Button from 'antd/lib/button';
import Cascader from 'antd/lib/cascader';
import PageHeader from 'antd/lib/page-header';
import Input from 'antd/lib/input';

import '@fortawesome/fontawesome-pro/css/all.min.css';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/char_counter.min';
import 'froala-editor/js/plugins/code_beautifier.min';
import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/js/plugins/colors.min';
import 'froala-editor/js/plugins/draggable.min';
import 'froala-editor/js/plugins/emoticons.min';
import 'froala-editor/js/plugins/font_size.min';
import 'froala-editor/js/plugins/fullscreen.min';
import 'froala-editor/js/plugins/help.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/inline_class.min';
import 'froala-editor/js/plugins/inline_style.min';
import 'froala-editor/js/plugins/line_height.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/paragraph_style.min';
import 'froala-editor/js/plugins/print.min';
import 'froala-editor/js/plugins/quote.min';
import 'froala-editor/js/plugins/save.min';
import 'froala-editor/js/plugins/special_characters.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/plugins/url.min';
import 'froala-editor/js/plugins/video.min';
import 'froala-editor/js/plugins/word_paste.min';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/char_counter.min.css';
import 'froala-editor/css/plugins/code_view.min.css';
import 'froala-editor/css/plugins/colors.min.css';
import 'froala-editor/css/plugins/draggable.min.css';
import 'froala-editor/css/plugins/emoticons.min.css';
import 'froala-editor/css/plugins/fullscreen.min.css';
import 'froala-editor/css/plugins/help.min.css';
import 'froala-editor/css/plugins/image.min.css';
import 'froala-editor/css/plugins/special_characters.min.css';
import 'froala-editor/css/plugins/table.min.css';
import 'froala-editor/css/plugins/video.min.css';
import FroalaEditor from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor';

import { connect } from 'react-redux';
import { UserState } from 'redux-oidc';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../redux/selectors';
import './resourceEditor.scss';
import IApplicationState from '../../../types/state.types';
import ActivityTooltip from '../../activity/ActivityTooltip';
import CoverImageEditor from '../../image/cover/CoverImageEditor';
import FieldErrorMessage from './FieldErrorMessage';
import moment, { Moment } from 'moment';
import { Card, DatePicker, Modal, Spin } from 'antd';
import { updateCmsEditorBeforeSave } from '../../util/Util';
import { ArticleType } from '../../../types/serverTypes/articleTypes';
import { StudyFeatureType } from '../../../../../server/src/types/studyTypes';
import { useQuery } from 'react-query';
import { getStudyFeatures } from '../../../service/studyService';
import froalaConfig from './froalaConfig.json';
import { Select } from 'antd';

const { Option } = Select;

const { confirm } = Modal;
const { TextArea } = Input;

const countCharacters = (value: string) => {
  if (!value) {
    return 0;
  }
  return value.length;
};

interface StateProps {
  oidc: UserState;
}

interface ComponentProps extends StateProps {
  resourceTopics: any[];
  originalArticle?: Optional<ArticleType>;
  saveArticle: (article: ArticleType, close?: boolean) => void;
}

interface ComponentState extends ArticleType {
  imageChoices: string[];
  isDirty: boolean;
  selectedTopicId: string;
  selectedSubTopicId: string;
  showTitleError: boolean;
  showSummaryError: boolean;
  showTopicError: boolean;
  showContentError: boolean;
  isUnsaved: boolean;
}

const parseForImageUrls = (body: string = '') => {
  const imageUrls = (
    body.match(/src=("\/api\/u\/upload\/view\/.*?)"/g) || []
  ).map((imageUrl: string) => {
    return imageUrl.substring(5, imageUrl.length - 1);
  });

  const youtubeRegex = /src=("https:\/\/www\.(youtube\.com\/embed\/)(.*?))"/g;
  let m;
  while ((m = youtubeRegex.exec(body)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === youtubeRegex.lastIndex) {
      youtubeRegex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match, groupIndex) => {
      // console.log(`Found match, group ${groupIndex}: ${match}`);
      if (groupIndex === 3) {
        const path = `https://img.youtube.com/vi/${match}/0.jpg`;
        imageUrls.push(path);
      }
    });
  }
  return imageUrls;
};

const mergeImageOptions = (imageUrl: string, images: string[] = []) => {
  const duplicates = images.concat(imageUrl);
  return duplicates.filter((url, index) => {
    return url && url.trim() != '' ? duplicates.indexOf(url) === index : false;
  });
};

const isBlank = (value: string) => {
  return !value || value.trim().length === 0;
};

const ResourceEditor = (props: ComponentProps) => {
  const froalaRef = useRef<any>();

  // The topicId assigned to an article is actually a Subtopic ID. This is a
  // helper function to find the matching Topic ID for the Subtopic ID supplied.
  const findSelectedTopicId = (subTopicId: number): string => {
    if (props.resourceTopics) {
      for (const topic of props.resourceTopics) {
        if (topic.children) {
          for (const subTopic of topic.children) {
            if (subTopic.value === `${subTopicId}`) {
              return topic.value ? topic.value : '';
            }
          }
        }
      }
    }
    return '';
  };

  const [state, setState] = useState<ComponentState>(
    props.originalArticle
      ? {
          imageChoices: mergeImageOptions(
            props.originalArticle.cover,
            parseForImageUrls(props.originalArticle.body)
          ),
          isDirty: false,
          isUnsaved: false,
          selectedTopicId: findSelectedTopicId(props.originalArticle.topicId),
          selectedSubTopicId: `${props.originalArticle.topicId}`,
          showTitleError: false,
          showSummaryError: false,
          showTopicError: false,
          showContentError: false,
          ...props.originalArticle,
        }
      : {
          imageChoices: [],
          isDirty: false,
          selectedTopicId: '',
          selectedSubTopicId: '',
          showTitleError: false,
          showSummaryError: false,
          showTopicError: false,
          showContentError: false,
          isUnsaved: false,
          // article properties, flattened out, because React does shallow state comparisons
          id: undefined,
          body: '',
          cover: '',
          deleteDate: null,
          previewUrl: '',
          summary: '',
          title: '',
          topicId: -1,
          videoUrl: '',
          publishDate: null,
          createDate: moment().toDate().toDateString(),
          lastUpdateDate: null,
        }
  );

  const features = useQuery<StudyFeatureType[]>('studyFeatures', () =>
    getStudyFeatures()
  );

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  useEffect(() => {
    setEditor();
  }, [features.status]);

  const setEditor = () => {
    const editor = froalaRef.current;

    // Define popup template.
    Froalaeditor.POPUP_TEMPLATES['customPlugin.popup'] =
      '[_CUSTOM_LAYER_][_BUTTONS_]';

    // Define popup buttons.
    Object.assign(Froalaeditor.DEFAULTS, {
      popupButtons: ['insertButton'],
    });

    let featureOptions = '';
    features.data?.map((feature, index) => {
      featureOptions += `
        <option value='${feature.featureId}' id='feature${feature.featureId}'>
          ${feature.feature}
        </option>`;
      // `<input type='checkbox' value='${feature.featureId}' id="feature${feature.featureId}"><label for="feature${feature.featureId}">&nbsp;${feature.feature}</label><br/>`;
    });

    Froalaeditor.PLUGINS.customPlugin = function (_editor) {
      // Create custom popup.
      function initPopup() {
        const popupButtons = ['insertButton'];
        // Popup buttons.
        var popup_buttons = '';

        // Create the list of buttons.
        if (popupButtons.length > 0) {
          popup_buttons += '<div class="fr-buttons">';
          popup_buttons += _editor.button.buildList(popupButtons);
          popup_buttons += '</div>';
        }

        // Load popup template.
        var template = {
          buttons: popup_buttons,
          custom_layer: `
            <div className="custom-layer">
              Restrict content to feature:
              <br />
              <select
                name='features'
                id='features'              
              >
                ${featureOptions}
              </select>
            </div>
          `,
        };

        // Create popup.
        var $popup = _editor.popups.create('customPlugin.popup', template);

        return $popup;
      }

      // Show the popup
      function showPopup() {
        // Get the popup object defined above.
        var $popup = _editor.popups.get('customPlugin.popup');

        // If popup doesn't exist then create it.
        // To improve performance it is best to create the popup when it is first needed
        // and not when the editor is initialized.
        if (!$popup) $popup = initPopup();

        // Set the editor toolbar as the popup's container.
        _editor.popups.setContainer('customPlugin.popup', _editor.$tb);

        // This custom popup is opened by pressing a button from the editor's toolbar.
        // Get the button's object in order to place the popup relative to it.
        var $btn = _editor.$tb.find('.fr-command[data-cmd="featureTag"]');

        // Set the popup's position.
        var left = $btn.offset().left + $btn.outerWidth() / 2;
        var top =
          $btn.offset().top +
          (_editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

        // Show the custom popup.
        // The button's outerHeight is required in case the popup needs to be displayed above it.
        _editor.popups.show(
          'customPlugin.popup',
          left,
          top,
          $btn.outerHeight()
        );
      }

      // Hide the custom popup.
      function hidePopup() {
        _editor.popups.hide('customPlugin.popup');
      }

      // Methods visible outside the plugin.
      return {
        showPopup: showPopup,
        hidePopup: hidePopup,
      };
    };

    Froalaeditor.DefineIcon('featureTag', { NAME: 'eye-slash' });
    Froalaeditor.RegisterCommand('featureTag', {
      title: 'Restrict content to feature:',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      plugin: 'customPlugin',
      callback: () => {
        const cur = editor.getEditor();
        cur.customPlugin.showPopup();
      },
    });

    // Define insert button
    Froalaeditor.DefineIcon('insertButton', {
      NAME: 'Insert',
      template: 'text',
    });
    Froalaeditor.RegisterCommand('insertButton', {
      title: 'Update',
      undo: false,
      focus: false,
      callback: () => {
        const cur = editor.getEditor();

        // check which feature are checked:
        let featureChecked: number[] = [];
        if ((document.getElementById(`features`) as any)?.value) {
          featureChecked.push(
            (document.getElementById(`features`) as any)?.value
          );
        }

        const innerHtml = cur.selection
          .element()
          .innerHTML.replace(/[\u200B-\u200D\uFEFF]/g, '');
        let insertion;
        if (!cur.selection.text()) {
          insertion = `<features ids="${featureChecked}"><p>Insert Content Here</p></features><p></p>`;
        } else {
          if (!innerHtml) {
            insertion = `<features ids="${featureChecked}">${cur.selection.text()}</features>`;
          } else {
            insertion = `<features ids="${featureChecked}">${
              cur.selection.element().outerHTML
            }</features>`;
          }
        }
        cur.html.insert(insertion);
      },
    });
  };

  // TODO: Modify the code in the handleSaveClick to pass the article back out to the
  //  ResourceEditPage. Then add Redux event/saga/etc to save the updated Article.
  //  Dispatching of all actions should be handled by the ResourceEditPage.

  const handleSaveClick = (close: boolean = false) => {
    const {
      showTitleError,
      showSummaryError,
      showTopicError,
      showContentError,
    } = validateArticle();
    if (
      showTitleError ||
      showSummaryError ||
      showTopicError ||
      showContentError
    ) {
      setState({
        ...state,
        showTitleError,
        showSummaryError,
        showTopicError,
        showContentError,
      });
      return;
    }

    const article = {
      id: state.id != -1 ? state.id : -1,
      title: state.title,
      summary: state.summary,
      topicId: state.selectedSubTopicId,
      cover: state.cover ? state.cover.trim() : '',
      body: updateCmsEditorBeforeSave(state.body),
      publishDate: state.publishDate,
      createdByUserId: 1001,
    };

    props.saveArticle(article, close);
    setState({
      ...state,
      isUnsaved: false,
    });
    // TODO: this notification would need to be triggered elsewhere.  But for now I'm doing it here.
    alert('Article Saved');
  };

  const validateArticle = () => {
    return {
      showTitleError: isBlank(state.title),
      showSummaryError: isBlank(state.summary),
      showTopicError: isBlank(state.selectedSubTopicId),
      showContentError: isBlank(state.body),
    };
  };

  const handleTopicChange = (values: string[]) => {
    const selectedTopicId = values[0];
    const selectedSubTopicId = values[1];
    setState({
      ...state,
      selectedTopicId,
      selectedSubTopicId,
      showTopicError: isBlank(selectedSubTopicId),
      isUnsaved: true,
    });
  };

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setState({
      ...state,
      title: value,
      showTitleError: isBlank(value),
      isUnsaved: true,
    });
  };

  const handleSummaryChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setState({
      ...state,
      summary: value,
      showSummaryError: isBlank(value),
      isUnsaved: true,
    });
  };

  const handleCoverImageChange = (imageUrl: string) => {
    setState({
      ...state,
      cover: imageUrl,
      imageChoices: mergeImageOptions(imageUrl, state.imageChoices),
      isUnsaved: true,
    });
  };

  const handleBodyChange = (body: string) => {
    const images = parseForImageUrls(body) || [];
    setState({
      ...state,
      body,
      imageChoices: mergeImageOptions(
        state.cover,
        images.concat(state.imageChoices)
      ),
      showContentError: isBlank(body),
      isUnsaved: true,
    });
  };

  const handlePublishDateChange = (date: Moment | null, datestring: string) => {
    setState({
      ...state,
      publishDate: datestring
        ? moment(datestring).toDate().toDateString()
        : null,
      isUnsaved: true,
    });
  };

  const handleCancel = () => {
    if (state.isUnsaved) {
      confirm({
        title: 'Are you sure you want to leave this resource?',
        content: '',
        okText: 'Leave',
        okType: 'danger',
        onOk: () => {
          window.history.back();
          setState({ ...state, isUnsaved: false });
        },
        onCancel() {},
      });
    } else {
      window.history.back();
    }
  };

  const { oidc } = props;

  const config = {
    charCounterCount: true,
    fontSizeSelection: true,
    iconsTemplate: 'font_awesome_5r',
    imageDefaultAlign: 'left',
    imageMaxSize: 16 * 1024 * 1024, // 16MB
    imageUploadParam: 'file',
    imageUploadMethod: 'POST',
    imageUploadURL: '/api/a/cms/uploads/image',
    placeholderText: 'Edit Your Content Here!',
    ...froalaConfig,
    // TODO Figure out why this works.  The backend seems to only be validating that the token can be decoded; not that it has expired or anything like that.
    requestHeaders: {
      Authorization: `Bearer ${oidc.user?.access_token}`,
    },
    toolbarButtons: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          'subscript',
          'superscript',
          'fontFamily',
          'fontSize',
          'textColor',
          'backgroundColor',
          'inlineClass',
          'inlineStyle',
          'clearFormatting',
        ],
        buttonsVisible: 3,
      },
      moreParagraph: {
        buttons: [
          'alignLeft',
          'alignCenter',
          'alignRight',
          'alignJustify',
          'outdent',
          'indent',
          'formatOL',
          'formatUL',
          'lineHeight',
          'paragraphFormat',
          'paragraphStyle',
          'quote',
        ],
        buttonsVisible: 6,
      },
      moreRich: {
        buttons: [
          'insertLink',
          'insertImage',
          'insertVideo',
          'insertTable',
          'emoticons',
          'specialCharacters',
          'insertHR',
          'featureTag',
        ],
        buttonsVisible: 4,
      },
      moreMisc: {
        buttons: [
          'undo',
          'redo',
          'fullscreen',
          'print',
          'getPDF',
          'selectAll',
          'html',
          'help',
          'save',
        ],
        align: 'right',
        buttonsVisible: 2,
      },
    },
    linkAutoPrefix: '',
  };

  const { resourceTopics } = props;
  const { showTitleError, showSummaryError, showTopicError, showContentError } =
    state;
  const {
    selectedTopicId,
    selectedSubTopicId,
    title,
    summary,
    body,
    cover,
    imageChoices,
    publishDate,
  } = state;

  if (features.isLoading || !features.data) {
    return <Spin />;
  }

  return (
    <div className="hmp-resource-editor">
      <PageHeader
        title="Return to Resources"
        subTitle=""
        onBack={handleCancel}
      />
      <Card>
        <div className="hmp-resource-editor-title">
          <label>Title</label>
          <Input
            className={showTitleError ? 'error' : ''}
            maxLength={300}
            onChange={handleTitleChange}
            value={title}
          />
          <label className="counter">
            Characters:
            {countCharacters(title)}
            /300
          </label>
          <FieldErrorMessage
            isVisible={showTitleError}
            message="You must enter a title for the article."
          />
        </div>
        <div className="hmp-resource-editor-summary">
          <label>Summary</label>
          <TextArea
            className={showSummaryError ? 'error' : ''}
            rows={3}
            value={summary}
            maxLength={1000}
            onChange={handleSummaryChange}
          />
          <label className="counter">
            Characters:
            {countCharacters(summary)}
            /1000
          </label>
          <FieldErrorMessage
            isVisible={showSummaryError}
            message="You must enter a summary for the article."
          />
        </div>
        <div className="hmp-resource-editor-topic">
          <label>Topic</label>
          <Cascader
            className={`hmp-resource-editor-topic-selector ${
              showTopicError ? 'error' : ''
            }`}
            options={resourceTopics}
            defaultValue={[`${selectedTopicId}`, `${selectedSubTopicId}`]}
            dropdownClassName="hmp-resource-editor-topic-selector-popup-menu"
            changeOnSelect
            onChange={handleTopicChange}
          />
          <FieldErrorMessage
            isVisible={showTopicError}
            message="You must select a valid Topic -> Subtopic"
          />
        </div>
        <div className="hmp-resource-editor-publish-date">
          <label>
            <span>Publish Date</span>
            <ActivityTooltip text="This date is based on the Coordinated Universal Time so if you are in a different timezone, please make sure to account for the difference.  For example, publish date of 2/1 on UTC will be 1/31 8PM on EST." />
          </label>
          <DatePicker
            value={publishDate ? moment(publishDate).utc() : undefined}
            onChange={handlePublishDateChange}
          />
        </div>
        <div className="hmp-resource-editor-cover">
          <label>Cover</label>
          <CoverImageEditor
            currentCoverImageUrl={cover}
            coverImageUrlChoices={imageChoices}
            coverImageChangeHandler={handleCoverImageChange}
          />
        </div>
        <div className="hmp-resource-editor-body">
          <label>Content</label>
          <FieldErrorMessage
            isVisible={showContentError}
            message="You must enter body copy for the article."
          />
          <FroalaEditor
            ref={froalaRef}
            tag="textarea"
            config={config}
            model={body}
            onModelChange={handleBodyChange}
          />
        </div>
        <div className="hmp-resource-editor-footer">
          <Button
            key="cancel-footer"
            className="footer-action"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            key="save-footer"
            className="footer-action"
            type="primary"
            onClick={(e) => handleSaveClick(false)}
          >
            Save
          </Button>
          <Button
            key="save-and-close-footer"
            className="footer-action"
            type="primary"
            onClick={(e) => handleSaveClick(true)}
          >
            Save and Close
          </Button>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    oidc: selectors.selectOidc,
  }
);

export default connect(mapStateToProps, null)(ResourceEditor);
