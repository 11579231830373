import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
/**
 *  Helper function for parsing URLSearchParams and returning
 *  an object, uses '[]' syntax for array query params.
 */
export const getQueryParams = (query): any => {
  let params = {};
  new URLSearchParams(query).forEach((value, key) => {
    let decodedKey = decodeURIComponent(key);
    let decodedValue = decodeURIComponent(value);
    // This key is part of an array
    if (decodedKey.endsWith('[]')) {
      decodedKey = decodedKey.replace('[]', '');
      params[decodedKey] || (params[decodedKey] = []);
      params[decodedKey].push(...decodedValue.split(','));
      // Just a regular parameter
    } else {
      params[decodedKey] = decodedValue;
    }
  });

  return params;
};

export default function useQuery<G = any>() {
  const { search } = useLocation();
  return useMemo<G>(() => getQueryParams(search), [search]);
}
