import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { entitySchema } from '../schema';
import {
  CreateUserArguments,
  createUserAsync,
  DeleteAdminUserArguments,
  deleteAdminUserAsync,
  getAdminAsync,
  getAdminRolesAsync,
  getUserAsync,
  PasswordArguments,
  updateAdminPasswordAsync,
  UpdateAdminUserArguments,
  updateAdminUserAsync,
  updateEmailAsync,
  updateMobileAsync,
  updatePasswordAsync,
} from './user.types';
import { UserType } from '../../types/serverTypes/usmgTypes';
import { NormalizerResult } from '../../types';
import { getParticipantsByStudyAsync } from '../participants/participants.types';

const updatePassword = (args: PasswordArguments) => {
  const { password, currentPassword, updatePseudoParticipants } = args;
  return axios({
    method: 'put',
    url: `/a/usmg/updatePassword?updatePseudoParticipants=${updatePseudoParticipants}`,
    data: { password, currentPassword },
  });
};

const updateMobile = (mobile: string) => {
  return axios({
    method: 'put',
    url: `/a/usmg/updateMobile/`,
    data: { mobile },
  });
};

const updateEmail = (email: string) => {
  return axios({
    method: 'put',
    url: `/a/usmg/updateEmail/`,
    data: { email },
  });
};

const getUser = (id: number) => {
  return axios({
    method: 'get',
    url: `/a/usmg/user/${id}`,
  });
};

const getAdmin = () => {
  return axios({
    method: 'get',
    url: `/a/usmg/admin`,
  });
};

const createUser = (args: CreateUserArguments) => {
  return axios({
    method: 'put',
    url: `a/usmg/user?sendEmail=${args.sendEmail}&createPseudos=${args.createPseudos}`,
    data: args.user,
  });
};

const getAdminRoles = () => {
  return axios({
    method: 'get',
    url: `/a/usmg/admin/roles`,
  });
};

const updateAdminPassword = (args: PasswordArguments) => {
  const { password, userIds, updatePseudoParticipants } = args;
  return axios({
    method: 'put',
    url: `/a/usmg/updatePasswordStudyManager?updatePseudoParticipants=${updatePseudoParticipants}`,
    data: { userIds, password },
  });
};

const updateAdminUser = (args: UpdateAdminUserArguments) => {
  const { user, roleIds } = args;
  return axios({
    method: 'put',
    url: `/a/usmg/admin/${user.id}/update`,
    data: { user, roleIds },
  });
};

const deleteAdminUser = (args: DeleteAdminUserArguments) => {
  const { userIds, deleteDate } = args;
  return axios({
    method: 'delete',
    url: `/a/usmg/admin/`,
    data: { userIds },
    params: { deleteDate },
  });
};

function* updatePasswordHandler(
  action: ReturnType<typeof updatePasswordAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      updatePassword,
      action.payload
    )) as AxiosResponse;
    yield put(updatePasswordAsync.success(response.data as Optional<UserType>));
  } catch (error) {
    yield put(updatePasswordAsync.failure(error));
  }
}

function* updateMobileHandler(
  action: ReturnType<typeof updateMobileAsync.request>
): Generator {
  const mobile = action.payload;
  try {
    const response: AxiosResponse = (yield call(
      updateMobile,
      mobile
    )) as AxiosResponse;
    yield put(updateMobileAsync.success(response.data as Optional<UserType>));
  } catch (error) {
    yield put(updateMobileAsync.failure(error));
  }
}

function* updateEmailHandler(
  action: ReturnType<typeof updateEmailAsync.request>
): Generator {
  const password = action.payload;
  try {
    const response: AxiosResponse = (yield call(
      updateEmail,
      password
    )) as AxiosResponse;
    yield put(updateEmailAsync.success(response.data as Optional<UserType>));
  } catch (error) {
    yield put(updateEmailAsync.failure(error));
  }
}

function* userHandler(
  action: ReturnType<typeof getUserAsync.request>
): Generator {
  const id: number = action.payload;
  try {
    const response: AxiosResponse = (yield call(getUser, id)) as AxiosResponse;
    yield put(getUserAsync.success(response.data as Optional<UserType>));
  } catch (error) {
    yield put(getUserAsync.failure(error));
  }
}

function* getAdminHandler(
  action: ReturnType<typeof getAdminAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(getAdmin)) as AxiosResponse;
    const { entities } = normalize(
      response.data,
      entitySchema.admin
    ) as NormalizerResult;
    const { admin } = entities;

    yield put(getParticipantsByStudyAsync.request(1));
    yield put(getAdminAsync.success(admin));
  } catch (error) {
    yield put(getAdminAsync.failure(error));
  }
}

function* createUserHandler(
  action: ReturnType<typeof createUserAsync.request>
): Generator {
  try {
    yield call(createUser, action.payload);
    yield put(createUserAsync.success());
  } catch (error) {
    yield put(createUserAsync.failure(error));
  }
}

function* getAdminRolesHandler(
  action: ReturnType<typeof getAdminRolesAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      getAdminRoles
    )) as AxiosResponse;
    const { entities } = normalize(
      response.data,
      entitySchema.adminRoles
    ) as NormalizerResult;
    const { adminRoles } = entities;

    yield put(getAdminRolesAsync.success(adminRoles));
  } catch (error) {
    yield put(getAdminRolesAsync.failure(error));
  }
}

function* updateAdminPasswordHandler(
  action: ReturnType<typeof updateAdminPasswordAsync.request>
): Generator {
  try {
    yield call(updateAdminPassword, action.payload);
    yield put(updateAdminPasswordAsync.success());
    // Refresh admin data
    yield put(getAdminAsync.request());
  } catch (error) {
    yield put(updateAdminPasswordAsync.failure(error));
  }
}

function* updateAdminUserHandler(
  action: ReturnType<typeof updateAdminUserAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      updateAdminUser,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.admin
    ) as NormalizerResult;
    const { admin } = entities;
    yield put(updateAdminUserAsync.success(admin));
  } catch (error) {
    yield put(updateAdminUserAsync.failure(error));
  }
}

function* deleteAdminUserHandler(
  action: ReturnType<typeof deleteAdminUserAsync.request>
): Generator {
  try {
    yield call(deleteAdminUser, action.payload);
    yield put(deleteAdminUserAsync.success());
    // Refresh admin data
    yield put(getAdminAsync.request());
  } catch (error) {
    yield put(deleteAdminUserAsync.failure(error));
  }
}

function* updatePasswordWatcher() {
  yield takeLatest(getType(updatePasswordAsync.request), updatePasswordHandler);
}

function* updateMobileWatcher() {
  yield takeLatest(getType(updateMobileAsync.request), updateMobileHandler);
}

function* updateEmailWatcher() {
  yield takeLatest(getType(updateEmailAsync.request), updateEmailHandler);
}

function* userWatcher() {
  yield takeLatest(getType(getUserAsync.request), userHandler);
}

function* getAdminWatcher() {
  yield takeLatest(getType(getAdminAsync.request), getAdminHandler);
}

function* createUserWatcher() {
  yield takeLatest(getType(createUserAsync.request), createUserHandler);
}

function* getAdminRolesWatcher() {
  yield takeLatest(getType(getAdminRolesAsync.request), getAdminRolesHandler);
}

function* updateAdminPasswordWatcher() {
  yield takeLatest(
    getType(updateAdminPasswordAsync.request),
    updateAdminPasswordHandler
  );
}

function* updateAdminUserWatcher() {
  yield takeLatest(
    getType(updateAdminUserAsync.request),
    updateAdminUserHandler
  );
}

function* deleteAdminUserWatcher() {
  yield takeLatest(
    getType(deleteAdminUserAsync.request),
    deleteAdminUserHandler
  );
}

export default function* userSaga() {
  yield all([
    fork(userWatcher),
    fork(getAdminWatcher),
    fork(updatePasswordWatcher),
    fork(updateEmailWatcher),
    fork(updateMobileWatcher),
    fork(createUserWatcher),
    fork(getAdminRolesWatcher),
    fork(updateAdminPasswordWatcher),
    fork(updateAdminUserWatcher),
    fork(deleteAdminUserWatcher),
  ]);
}
