import Layout from 'antd/lib/layout';
import React, { PropsWithChildren } from 'react';
import mobileLogoImage from '../../assets/images/logo-mobile.png';
import logoImage from '../../assets/images/logo.png';

import './sidebar.scss';

interface ComponentProps {
  collapsed: boolean
}

const SideBar = (props: PropsWithChildren<ComponentProps>) => {

  const { collapsed, children } = props;
  const { Sider } = Layout;

  const logo = collapsed ? (
    <div id="logo-container-collapsed">
      <img id="logo" src={mobileLogoImage} alt="Logo" />
    </div>
  ) : (
    <div id="logo-container">
      <img id="logo" src={logoImage} alt="Logo" />
    </div>
  );

  return (
    <Sider theme="dark" className="sidebar" trigger={null} collapsible collapsed={collapsed}>
      {logo}
      {children}
    </Sider>
  );
};

export default SideBar;
