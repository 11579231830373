import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { entitySchema } from '../schema';
import {
  createAppointmentAsync,
  deleteAppointmentAsync,
  getAppointmentsAsync,
  GetAppointmentsQueryParameters,
  updateAppointmentAsync,
} from './appointments.types';
import { AppointmentType } from '../../types/serverTypes/appointmentTypes';
import { NormalizerResult } from '../../types';

const getAppointments = (queryParams?: GetAppointmentsQueryParameters) => {
  if (queryParams) {
    const { includeDeleted } = queryParams;
    return axios({
      method: 'get',
      url: `/a/appointment/?includeDeleted=${includeDeleted}`,
    });
  }
  return axios({
    method: 'get',
    url: `/a/appointment/`,
  });
};

const createAppointment = (appointment: AppointmentType) => {
  return axios({
    method: 'put',
    url: `/a/appointment/create`,
    data: appointment,
  });
};

const updateAppointment = (appointment: AppointmentType) => {
  const { id } = appointment;
  return axios({
    method: 'put',
    url: `/a/appointment/${id}/update`,
    data: appointment,
  });
};

const deleteAppointment = (id: string) => {
  return axios({
    method: 'delete',
    url: `/a/appointment/appointment/${id}`,
  });
};

let lastGetAppointmentsParam: GetAppointmentsQueryParameters | undefined;

function* refreshAppointmentsHandler() {
  yield put(getAppointmentsAsync.request(lastGetAppointmentsParam));
}

function* getAppointmentsHandler(
  action: ReturnType<typeof getAppointmentsAsync.request>
): Generator {
  try {
    lastGetAppointmentsParam = action.payload;
    const response: AxiosResponse = (yield call(
      getAppointments,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      response.data,
      entitySchema.appointments
    ) as NormalizerResult;
    const { appointments } = entities;
    yield put(getAppointmentsAsync.success(appointments));
  } catch (error) {
    yield put(getAppointmentsAsync.failure(error));
  }
}

function* createAppointmentHandler(
  action: ReturnType<typeof createAppointmentAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      createAppointment,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.appointments
    ) as NormalizerResult;
    const { appointments } = entities;
    yield put(createAppointmentAsync.success(appointments));
    yield call(refreshAppointmentsHandler);
  } catch (error) {
    yield put(createAppointmentAsync.failure(error));
  }
}

function* deleteAppointmentHandler(
  action: ReturnType<typeof deleteAppointmentAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      deleteAppointment,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.appointments
    ) as NormalizerResult;
    const { appointments } = entities;
    yield put(deleteAppointmentAsync.success(appointments));
    yield call(refreshAppointmentsHandler);
  } catch (error) {
    yield put(deleteAppointmentAsync.failure(error));
  }
}

function* updateAppointmentHandler(
  action: ReturnType<typeof updateAppointmentAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      updateAppointment,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      response.data,
      entitySchema.appointments
    ) as NormalizerResult;
    const { appointments } = entities;
    yield put(updateAppointmentAsync.success(appointments));
    yield call(refreshAppointmentsHandler);
  } catch (error) {
    yield put(updateAppointmentAsync.failure(error));
  }
}

function* getAppointmentsWatcher() {
  yield takeEvery(
    getType(getAppointmentsAsync.request),
    getAppointmentsHandler
  );
}

function* createAppointmentWatcher() {
  yield takeLatest(
    getType(createAppointmentAsync.request),
    createAppointmentHandler
  );
}

function* updateAppointmentWatcher() {
  yield takeLatest(
    getType(updateAppointmentAsync.request),
    updateAppointmentHandler
  );
}

function* deleteAppointmentWatcher() {
  yield takeLatest(
    getType(deleteAppointmentAsync.request),
    deleteAppointmentHandler
  );
}

export default function* appointmentsSaga() {
  yield all([
    fork(getAppointmentsWatcher),
    fork(createAppointmentWatcher),
    fork(updateAppointmentWatcher),
    fork(deleteAppointmentWatcher),
  ]);
}
