{
  "fontSize": [
    "8",
    "9",
    "10",
    "11",
    "12",
    "14",
    "16",
    "18",
    "24",
    "30",
    "36",
    "48",
    "60",
    "72",
    "96"
  ],
  "htmlAllowedAttrs": [
    "ids",
    "accept",
    "accept-charset",
    "accesskey",
    "action",
    "align",
    "allowfullscreen",
    "allowtransparency",
    "alt",
    "aria-.*",
    "async",
    "autocomplete",
    "autofocus",
    "autoplay",
    "autosave",
    "background",
    "bgcolor",
    "border",
    "charset",
    "cellpadding",
    "cellspacing",
    "checked",
    "cite",
    "class",
    "color",
    "cols",
    "colspan",
    "content",
    "contenteditable",
    "contextmenu",
    "controls",
    "coords",
    "data",
    "data-.*",
    "datetime",
    "default",
    "defer",
    "dir",
    "dirname",
    "disabled",
    "download",
    "draggable",
    "dropzone",
    "enctype",
    "for",
    "form",
    "formaction",
    "frameborder",
    "headers",
    "height",
    "hidden",
    "high",
    "href",
    "hreflang",
    "http-equiv",
    "icon",
    "id",
    "ismap",
    "itemprop",
    "keytype",
    "kind",
    "label",
    "lang",
    "language",
    "list",
    "loop",
    "low",
    "max",
    "maxlength",
    "media",
    "method",
    "min",
    "mozallowfullscreen",
    "multiple",
    "muted",
    "name",
    "novalidate",
    "open",
    "optimum",
    "pattern",
    "ping",
    "placeholder",
    "playsinline",
    "poster",
    "preload",
    "pubdate",
    "radiogroup",
    "readonly",
    "rel",
    "required",
    "reversed",
    "rows",
    "rowspan",
    "sandbox",
    "scope",
    "scoped",
    "scrolling",
    "seamless",
    "selected",
    "shape",
    "size",
    "sizes",
    "span",
    "src",
    "srcdoc",
    "srclang",
    "srcset",
    "start",
    "step",
    "summary",
    "spellcheck",
    "style",
    "tabindex",
    "target",
    "title",
    "type",
    "translate",
    "usemap",
    "value",
    "valign",
    "webkitallowfullscreen",
    "width",
    "wrap"
  ],
  "htmlAllowedTags": [
    "features",
    "arms",
    "a",
    "abbr",
    "address",
    "area",
    "article",
    "aside",
    "audio",
    "b",
    "base",
    "bdi",
    "bdo",
    "blockquote",
    "br",
    "button",
    "canvas",
    "caption",
    "cite",
    "code",
    "col",
    "colgroup",
    "datalist",
    "dd",
    "del",
    "details",
    "dfn",
    "dialog",
    "div",
    "dl",
    "dt",
    "em",
    "embed",
    "fieldset",
    "figcaption",
    "figure",
    "footer",
    "form",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "header",
    "hgroup",
    "hr",
    "i",
    "iframe",
    "img",
    "input",
    "ins",
    "kbd",
    "keygen",
    "label",
    "legend",
    "li",
    "link",
    "main",
    "map",
    "mark",
    "menu",
    "menuitem",
    "meter",
    "nav",
    "noscript",
    "object",
    "ol",
    "optgroup",
    "option",
    "output",
    "p",
    "param",
    "pre",
    "progress",
    "queue",
    "rp",
    "rt",
    "ruby",
    "s",
    "samp",
    "script",
    "style",
    "section",
    "select",
    "small",
    "source",
    "span",
    "strike",
    "strong",
    "sub",
    "summary",
    "sup",
    "table",
    "tbody",
    "td",
    "textarea",
    "tfoot",
    "th",
    "thead",
    "time",
    "title",
    "tr",
    "track",
    "u",
    "ul",
    "var",
    "video",
    "wbr"
  ],
  "htmlDoNotWrapTags": ["features", "arms"],
  "pluginsEnabled": [
    "align",
    "charCounter",
    "codeBeautifier",
    "codeView",
    "colors",
    "draggable",
    "emoticons",
    "entities",
    "fontSize",
    "fullscreen",
    "help",
    "image",
    "inlineClass",
    "inlineStyle",
    "lineHeight",
    "link",
    "lists",
    "paragraphFormat",
    "paragraphStyle",
    "print",
    "quote",
    "save",
    "specialCharacters",
    "table",
    "url",
    "video",
    "wordPaste",
    "customPlugin"
  ]
}
