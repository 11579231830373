import React, { Component } from 'react';
import moment, { Moment } from 'moment';
import {
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Switch,
  TimePicker,
} from 'antd';
import {
  faMapMarkerAlt,
  faPhone,
  faUser,
  faUserCog,
  faText,
} from '@fortawesome/pro-regular-svg-icons';
import { FormInstance } from 'antd/lib/form';
import { connect } from 'react-redux';
import AntdIcon from '../antdIcon/AntdIcon';
import IApplicationState from '../../types/state.types';
import * as selectors from '../../redux/selectors';
import './appointmentForm.scss';
import ActivityTooltip from '../activity/ActivityTooltip';
import HMPTextArea from '../textarea/HMPTextArea';
import { ParticipantType } from '../../types/serverTypes/studyTypes';
import { AdminUserType } from '../../types/serverTypes/adminTypes';
import {
  AppointmentType,
  AvailabilityType,
} from '../../types/serverTypes/appointmentTypes';
import { AppointmentMethodTypes } from '../../constant/serverConstants/appointmentConstants';
import Paragraph from 'antd/lib/typography/Paragraph';
import Text from 'antd/lib/typography/Text';

const { Item } = Form;
const { Option } = Select;
const { confirm } = Modal;

interface StateProps {
  participants: Optional<ParticipantType[]>;
  admin: Optional<AdminUserType[]>;
  availabilities: Optional<AvailabilityType[]>;
}

interface ComponentProps extends StateProps {
  formRef: React.RefObject<FormInstance>;
  appointment: AppointmentType;
  onChange: () => void;
}

interface ComponentState {
  adminOptions: { value: number; label: string }[];
  participantOptions: { value: number; label: string }[];
  assignedParticipantId?: number;
}

export interface DisabledTimes {
  disabledHours?: () => number[];
  disabledMinutes?: (hour: number) => number[];
  disabledSeconds?: (hour: number, minute: number) => number[];
}

function filter(input, option) {
  return (
    option && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  );
}

function range(start: number, end: number) {
  const result: number[] = [];
  for (let i: number = start; i <= end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current: Moment | null): boolean {
  return !!(current && current < moment().startOf('day'));
}

class AppointmentForm extends Component<ComponentProps, ComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      adminOptions: [],
      participantOptions: [],
      assignedParticipantId: this.props.appointment.participantId,
    };
  }
  componentDidMount() {
    let state: ComponentState = { ...this.state };
    if (this.props.participants) {
      state.participantOptions = this.props.participants.map((p) => {
        return {
          value: p.id,
          label: `${p.firstName} ${p.lastName} (${p.username})`,
        };
      });
    }
    if (this.props.admin) {
      state.adminOptions = this.props.admin
        .map((a) => {
          return {
            value: a.id,
            label: `${a.firstName} ${a.lastName} (${a.username})`,
          };
        })
        .sort((a, b) =>
          this.isAvailable(a.value) ? (this.isAvailable(b.value) ? 0 : -1) : 1
        );
    }
    this.setState(state);
  }

  disabledEndHours = () => {
    const { formRef } = this.props;
    const form = formRef.current;
    const start = moment(form?.getFieldValue('startTime'));
    return range(0, start.hour() - 1);
  };

  disabledEndMinutes = () => {
    const { formRef } = this.props;
    const form = formRef.current;
    const start = moment(form?.getFieldValue('startTime'));
    const end = moment(form?.getFieldValue('endTime'));
    return end?.isSame(start, 'hour') ? range(0, start.minute()) : [];
  };

  onStartTimeBlur = (value: Moment) => {
    const form = this.props.formRef.current;
    if (form) {
      const start = moment(form.getFieldValue('startTime'));
      const end = moment(form.getFieldValue('endTime'));
      if (value.isSameOrAfter(end)) {
        // If new startTime is after the previous endTime,
        // we shift the endTime to be the same duration after
        // the new startTime.
        const diff = end.diff(start, 'minutes');
        const newEnd = value.clone();
        newEnd.add(diff, 'minutes');
        form.setFieldsValue({
          startTime: value,
          endTime: newEnd,
        });
      } else {
        form.setFieldsValue({
          startTime: value,
        });
      }
      this.onChange();
    }
  };

  onEndTimeBlur = (value: Moment) => {
    const form = this.props.formRef.current;
    if (form) {
      form.setFieldsValue({
        endTime: value,
      });
      this.onChange();
    }
  };

  validateTimeRange = (rule, value) => {
    const { formRef } = this.props;
    const form = formRef.current;
    if (form) {
      const start: Moment | undefined = form.getFieldValue('startTime');
      const end: Moment | undefined = form.getFieldValue('endTime');
      if (!moment.isMoment(start) || !moment.isMoment(end)) {
        return Promise.reject();
      }
      return Promise.resolve();
    }
  };

  isAvailable = (adminId: number): boolean => {
    const { availabilities, formRef } = this.props;
    const form = formRef.current;
    const startDate = form?.getFieldValue('startDate');
    const startTime = form?.getFieldValue('startTime');
    const endTime = form?.getFieldValue('endTime');
    const startMoment = moment(
      `${startDate.format('YYYY-MM-DD')} ${startTime.format('hh:mm A')}`,
      'YYYY-MM-DD hh:mm A'
    );
    const endMoment = moment(
      `${startDate.format('YYYY-MM-DD')} ${endTime.format('hh:mm A')}`,
      'YYYY-MM-DD hh:mm A'
    );
    return !!availabilities
      ?.filter((a) => a.userId === adminId)
      .find(
        (a) =>
          moment(a.startDate).isSameOrBefore(startMoment) &&
          moment(a.endDate).isSameOrAfter(endMoment)
      );
  };

  sortAdminOptions = () => {
    if (this.props.admin) {
      this.setState({
        adminOptions: this.props.admin
          .map((a) => {
            return {
              value: a.id,
              label: `${a.firstName} ${a.lastName} (${a.username})`,
            };
          })
          .sort((a, b) =>
            this.isAvailable(a.value) ? (this.isAvailable(b.value) ? 0 : -1) : 1
          ),
      });
    }
  };

  onChange = (changedValues?, values?) => {
    // Show confirm if the participantId is changed
    if (
      changedValues.participantId &&
      this.state.assignedParticipantId &&
      changedValues.participantId != this.state.assignedParticipantId
    ) {
      const form = this.props.formRef.current;
      confirm({
        title:
          'Are you sure you want to change the participant for this appointment?',
        content: (
          <>
            <Paragraph>
              The previous participant assigned to this appointment was:
            </Paragraph>
            <Text strong>
              {
                this.state.participantOptions.find(
                  (p) => p.value === this.state.assignedParticipantId
                )?.label
              }
            </Text>
            <Paragraph>You are reassigning this appointment to:</Paragraph>
            <Text strong>
              {
                this.state.participantOptions.find(
                  (p) => p.value === changedValues.participantId
                )?.label
              }
            </Text>
          </>
        ),
        onOk: () => {
          if (form) {
            this.props.onChange();
            // Update participantId in state
            this.setState({
              assignedParticipantId: changedValues.participantId,
            });
          }
        },
        onCancel: () => {
          if (form) {
            // Revert changed participantId value to previous assigned participant
            form.setFieldsValue({
              participantId: this.state.assignedParticipantId,
            });
          }
        },
      });
    } else {
      this.props.onChange();
      // Resort admin options whenever a value is changed on the form
      this.sortAdminOptions();
    }
  };

  render() {
    const { formRef, appointment } = this.props;
    const { adminOptions, participantOptions } = this.state;
    const {
      id,
      startDate,
      endDate,
      title,
      participantId,
      adminId,
      adminNotes,
      address,
      phoneNumber,
      notes,
      meetingMethod,
      isConfirmed,
    } = appointment;

    return (
      <Form
        ref={formRef}
        key="appointment-form"
        colon={false}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        layout="horizontal"
        onValuesChange={this.onChange}
      >
        <Item
          label="Title"
          name="title"
          key={`${id}-title`}
          rules={[
            { required: true, message: 'Title is required.' },
            {
              max: 255,
              message: `${
                title
                  ? `${255 - title.length} characters over`
                  : '255 character limit exceeded'
              }`,
            },
          ]}
          initialValue={title}
        >
          <Input placeholder="Title" />
        </Item>
        <Item
          label="Date/Time"
          className="parent-form-item"
          style={{ marginBottom: 0 }}
        >
          <Item
            name="startDate"
            key={`${id}-startDate`}
            initialValue={moment.utc(startDate, 'YYYY-MM-DD HH:mm a')}
            rules={[{ required: true, message: 'Start date is required.' }]}
            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
          >
            <DatePicker
              disabledDate={disabledDate}
              bordered={false}
              format="YYYY-MM-DD"
            />
          </Item>
          <Item
            key={`${id}-timeRange`}
            rules={[
              {
                required: true,
                message: 'Start and end times are required.',
                validator: this.validateTimeRange,
              },
            ]}
            style={{ display: 'inline-block', width: 'calc(60% - 8px)' }}
          >
            <Item
              name="startTime"
              key={`${id}-startTime`}
              initialValue={moment(startDate)}
              style={{
                marginBottom: 0,
                display: 'inline-block',
                width: 'calc(50% - 4px)',
              }}
            >
              <TimePicker
                showNow={false}
                bordered={false}
                onSelect={this.onStartTimeBlur}
                format="hh:mm A"
                minuteStep={5}
              />
            </Item>
            <Item
              name="endTime"
              key={`${id}-endTime`}
              initialValue={moment(endDate)}
              style={{
                marginBottom: 0,
                display: 'inline-block',
                width: 'calc(50% - 4px)',
              }}
            >
              <TimePicker
                showNow={false}
                disabledHours={this.disabledEndHours}
                disabledMinutes={this.disabledEndMinutes}
                bordered={false}
                onSelect={this.onEndTimeBlur}
                format="hh:mm A"
                minuteStep={5}
              />
            </Item>
          </Item>
        </Item>
        <Item
          label={
            <AntdIcon
              classes="form-item-icon"
              size="lg"
              fontAwesomeIcon={faUser}
            />
          }
          name="participantId"
          key={`${id}-participantId`}
          rules={[{ required: true, message: 'Participant is required.' }]}
          initialValue={participantId}
        >
          <Select
            key={`${id}-participantId-select`}
            placeholder="Participant"
            bordered={false}
            showSearch
            optionFilterProp="label"
            filterOption={filter}
          >
            {participantOptions.map((option) => (
              <Option
                key={`${option.value}-participant-option`}
                value={option.value}
              >
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          label={
            <AntdIcon
              classes="form-item-icon"
              size="lg"
              fontAwesomeIcon={faUserCog}
            />
          }
          name="adminId"
          key={`${id}-adminId`}
          initialValue={adminId}
        >
          <Select
            key={`${id}-adminId-select`}
            placeholder="Admin"
            bordered={false}
            showSearch
            optionFilterProp="label"
            filterOption={filter}
          >
            {adminOptions.map((option) => {
              return (
                <Option
                  key={`${option.value}-admin-option`}
                  value={option.value}
                  className={this.isAvailable(option.value) ? 'admin-bold' : ''}
                >
                  {option.label}
                </Option>
              );
            })}
          </Select>
        </Item>
        <Item
          key={`${id}-isConfirmed`}
          initialValue={isConfirmed}
          name="isConfirmed"
          valuePropName="checked"
          label="Confirm Meeting"
        >
          <Switch />
        </Item>
        <Item
          label={
            <AntdIcon
              classes="form-item-icon"
              size="lg"
              fontAwesomeIcon={faPhone}
            />
          }
          name="phoneNumber"
          key={`${id}-phoneNumber`}
          initialValue={phoneNumber}
        >
          <Input placeholder="Phone" style={{ width: '150px' }} />
        </Item>
        <Item
          label={
            <AntdIcon
              classes="form-item-icon"
              size="lg"
              fontAwesomeIcon={faMapMarkerAlt}
            />
          }
          name="address"
          key={`${id}-address`}
          initialValue={address ? address : ''}
        >
          <HMPTextArea placeholder="Address" style={{ width: '100%' }} />
        </Item>
        <Item
          label={
            <>
              <AntdIcon
                classes="form-item-icon"
                size="lg"
                fontAwesomeIcon={faText}
              />
              <ActivityTooltip
                text="This note will be visible to the participant"
                placement="top"
                warning
                trigger="hover"
              />
            </>
          }
          name="notes"
          key={`${id}-notes`}
          initialValue={notes ? notes : ''}
        >
          <HMPTextArea
            spellCheck
            placeholder="Notes (Visible to the participant)"
            style={{ width: '100%' }}
          />
        </Item>
        <Item
          label={
            <div>
              <span>Meet by:</span>
            </div>
          }
          name="meetingMethod"
          key={`${id}-meetingMethod`}
          initialValue={meetingMethod}
        >
          <Radio.Group>
            <Radio.Button value={AppointmentMethodTypes.Call}>
              Call
            </Radio.Button>
            <Radio.Button value={AppointmentMethodTypes.Chat}>
              Chat
            </Radio.Button>
            <Radio.Button value={AppointmentMethodTypes.Video}>
              Video
            </Radio.Button>
          </Radio.Group>
        </Item>
        <Item
          label={
            <div>
              <span>Admin Notes </span>
              <ActivityTooltip
                text="These notes won't be visible to the participant"
                placement="top"
                warning
                trigger="hover"
              />
            </div>
          }
          name="adminNotes"
          key={`${id}-adminNotes`}
          initialValue={adminNotes ? adminNotes : ''}
        >
          <HMPTextArea
            placeholder="(These notes won't be visible to the participant)"
            style={{ width: '100%' }}
          />
        </Item>
      </Form>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    participants: selectors.getRequestedStudyParticipants(state),
    admin: selectors.getAdmin(state),
    availabilities: selectors.getAvailabilities(state),
  };
};

export default connect(mapStateToProps)(AppointmentForm);
