import { message } from 'antd';
import { useMutation, useQuery } from 'react-query';
import {
  getAvatarCategories,
  getAvatarParts,
  updateAvatarCategoriesSequence,
  updateAvatarCategory,
  updateAvatarPart,
} from '../../service/avatar/avatarService';
import {
  AvatarPartCategoryType,
  AvatarPartType,
} from '../../types/serverTypes/avatarTypes';

export const useGetAvatarCategories = () => {
  return useQuery<AvatarPartCategoryType[]>(
    'avatarCategories',
    getAvatarCategories
  );
};
export const useGetAvatarParts = () => {
  return useQuery<AvatarPartType[]>('avatarParts', getAvatarParts);
};

export const useUpdateAvatarPartMutation = (queryClient) => {
  return useMutation(updateAvatarPart, {
    onError: (error, variables, context) => {
      message.error('Error in updating avatar part.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('avatarParts');
      message.success('Avatar part successfully updated.');
    },
  });
};

export const useUpdateAvatarCategoryMutation = (queryClient) => {
  return useMutation(updateAvatarCategory, {
    onError: (error, variables, context) => {
      message.error('Error in updating avatar category.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('avatarCategories');
      message.success('Avatar category successfully updated.');
    },
  });
};

export const useMoveAvatarPartMutation = (queryClient) => {
  return useMutation(updateAvatarPart, {
    onError: (error, variables, context) => {
      message.error('Error in moving avatar item.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('avatarParts');
      message.success('Avatar item successfully moved.');
    },
  });
};

export const useUpdateAvatarCategorySequenceMutation = (queryClient) => {
  return useMutation(updateAvatarCategoriesSequence, {
    onError: (error, variables, context) => {
      message.error('Error in moving category sequence.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('avatarCategories');
      message.success('Category successfully moved.');
    },
  });
};
