import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import {StudyArmType, StudyType} from "../../types/serverTypes/studyTypes";

export interface IStudyUIState  {
  stats: any[]
}

export const loadStudiesAsync = createAsyncAction(
  'hmp/admin/LOAD_STUDIES_REQUEST',
  'hmp/admin/LOAD_STUDIES_SUCCESS',
  'hmp/admin/LOAD_STUDIES_FAILURE'
)<void, void, Error>();

export const loadStudyAsync = createAsyncAction(
  'hmp/admin/LOAD_STUDY_REQUEST',
  'hmp/admin/LOAD_STUDY_SUCCESS',
  'hmp/admin/LOAD_STUDY_FAILURE'
)<number, void, Error>();

export const loadStudyStatsAsync = createAsyncAction(
  'hmp/admin/LOAD_STUDY_STATS_REQUEST',
  'hmp/admin/LOAD_STUDY_STATS_SUCCESS',
  'hmp/admin/LOAD_STUDY_STATS_FAILURE'
)<number, void, Error>();


export const updateStudies = createStandardAction('hmp/admin/UPDATE_STUDIES')<Optional<NormalizedType<StudyType>>>();
export const updateStudyArms = createStandardAction('hmp/admin/UPDATE_STUDY_ARMS')<Optional<NormalizedType<StudyArmType>>>();

const studyActions = {
  loadStudiesAsync,
  loadStudyAsync,
  loadStudyStatsAsync,
  updateStudies,
  updateStudyArms
};

export type StudyActionTypes = ActionType<typeof studyActions>;
