import { Table, Popconfirm, Typography } from 'antd';
import React, { Component } from 'react';
import EditableRow from './EditableRow';
import EditableCell from './EditableCell';
import _, { isEqual } from 'lodash';
import './editableTable.scss';
import UnsavedLeavingGuard from '../util/UnsavedLeavingGuard';
import { RouteComponentProps, withRouter } from 'react-router';
import { Rule } from 'rc-field-form/lib/interface';
import uuid from 'uuid';

interface EditableTableState<S> {
  dataSource: readonly S[];
  count: number;
  editingKey: React.Key;
  previousVersion?: S;
  isUnsaved: boolean;
}

interface Keyable {
  key: React.Key;
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
export type ColumnData = ColumnTypes[number] & {
  editable?: boolean;
  dataIndex: string;
  required?: boolean;
  validator?: (rule, value) => Promise<void>;
  editRender?: (record, ref, rules?: Rule[], save?: Function) => JSX.Element;
};

interface ComponentProps<P extends object & Keyable>
  extends EditableTableProps,
    RouteComponentProps {
  dataSource?: readonly P[];
  columns: ColumnData[];
  handleSave: (row: P) => void;
  rowActions?: (key: any) => JSX.Element[];
  disabled?: boolean;
}

class EditableTable<C extends object & Keyable> extends Component<
  ComponentProps<C>,
  EditableTableState<C>
> {
  columns: ColumnData[];

  constructor(props: ComponentProps<C>) {
    super(props);
    this.columns = [...this.props.columns];
    if (!props.disabled) {
      this.columns.push({
        key: 'action-col',
        dataIndex: 'key',
        title: 'Action',
        render: this.renderActions,
      });
    }
    this.state = {
      dataSource: this.props.dataSource || [],
      count: 2,
      editingKey: '',
      isUnsaved: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.dataSource, this.props.dataSource)) {
      this.setState({
        dataSource: this.props.dataSource || [],
      });
    }
    if (!isEqual(this.columns, this.props.columns)) {
      this.columns = [...this.props.columns];
      if (!this.props.disabled) {
        this.columns.push({
          key: 'action',
          dataIndex: 'key',
          title: 'Action',
          render: this.renderActions,
        });
      }
    }
  }

  save = (key: React.Key) => {
    const row = this.state.dataSource.find((u) => u.key === key);
    if (this.state.isUnsaved && !!row && this.props.handleSave) {
      this.props.handleSave(row);
    }
    this.setState({
      editingKey: '',
      previousVersion: undefined,
      isUnsaved: false,
    });
  };

  // Updates data in state
  handleSaveCell = (row: C) => {
    if (this.state.dataSource) {
      const newData = [...this.state.dataSource];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];
      if (!_.isEqual(item, row)) {
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ dataSource: newData, isUnsaved: true });
      }
    }
  };

  renderActions = (key, record: any) => {
    const editable = this.isEditing(key);
    const actions: JSX.Element[] = [];
    if (editable) {
      actions.push(
        <span key={`${record.key}-save-cancel`}>
          <a onClick={() => this.save(key)} style={{ marginRight: 8 }}>
            Save
          </a>
          <Popconfirm title="Sure to cancel?" onConfirm={this.onCancel}>
            <a>Cancel</a>
          </Popconfirm>
        </span>
      );
    } else {
      actions.push(
        <Typography.Link
          key={`${record.key}-edit`}
          disabled={this.state.editingKey !== ''}
          onClick={() => this.onEdit(key)}
        >
          Edit
        </Typography.Link>
      );
    }
    if (this.props.rowActions) {
      actions.push(
        ...this.props
          .rowActions(record.key)
          .map((action, index) => (
            <span key={`${record.key}-${index}`}>{action}</span>
          ))
      );
    }
    return actions;
  };

  rowClassName = (record: any, index: number): string =>
    index % 2 === 0
      ? 'editable-row tr-even-color'
      : 'editable-row tr-odd-color';

  isEditing = (key: React.Key) => {
    return this.state.editingKey === key;
  };

  onEdit = (key: React.Key) => {
    const previousVersion = this.state.dataSource.find((a) => a.key === key);
    this.setState({
      editingKey: key,
      previousVersion,
    });
  };

  onCancel = () => {
    const { previousVersion, dataSource } = this.state;
    if (previousVersion) {
      // If previousVersion, revert to that and overwrite changes
      const newData = [...dataSource];
      const index = newData.findIndex(
        (item) => previousVersion.key === item.key
      );
      newData.splice(index, 1, previousVersion);
      this.setState({ dataSource: newData });
    }
    this.setState({
      editingKey: '',
      previousVersion: undefined,
      isUnsaved: false,
    });
  };

  render() {
    const { dataSource, isUnsaved } = this.state;
    const { rowSelection } = this.props;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: C) => {
          const isEditing = this.isEditing(record.key);
          return {
            record,
            editable: isEditing,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSaveCell: this.handleSaveCell,
            required: col.required,
            validator: col.validator,
            editRender: col.editRender,
          };
        },
      };
    });
    return (
      <>
        <Table
          components={components}
          columns={columns as ColumnTypes}
          dataSource={dataSource}
          rowKey={this.props.rowKey}
          rowClassName={this.rowClassName}
          rowSelection={rowSelection}
          scroll={{ x: 1280, y: 980 }}
          pagination={false}
        />
        <UnsavedLeavingGuard
          when={isUnsaved}
          navigate={(path) => this.props.history.push(path)}
          shouldBlockNavigation={() => isUnsaved}
        />
      </>
    );
  }
}

export default withRouter(EditableTable);
