import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import { Button, DatePicker, Input, Modal, Select } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import * as selectors from '../../redux/selectors';
import IApplicationState from '../../types/state.types';
import {
  createAnnouncementAsync,
  updateAnnouncementAsync,
} from '../../redux/announcements/announcements.types';
import FroalaCustomUpload from '../activity/FroalaCustomUpload';
import { updateCmsEditorBeforeSave } from '../util/Util';
import ActivityTooltip from '../activity/ActivityTooltip';
import { StudyArmType } from '../../types/serverTypes/studyTypes';
import { AnnouncementType } from '../../types/serverTypes/announcementTypes';
import { cleanstring } from '../../service/util';

const { confirm } = Modal;
const { Item } = Form;
const { Option } = Select;

interface StateProps {
  studyId: number;
  arms: Optional<StudyArmType[]>;
}

interface DispatchProps {
  createAnnouncement: typeof createAnnouncementAsync.request;
  updateAnnouncement: typeof updateAnnouncementAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {
  announcement: AnnouncementType;
  visible: boolean;
  closeHandler: () => {};
}
const defaultState = {
  id: undefined,
  type: 'study',
  typeId: 1,
  title: '',
  body: '',
  effectiveStartDate: new Date() as Date,
  effectiveEndDate: new Date() as Date,
};
const itemStyle = {
  marginBottom: '10px',
  marginTop: '10px',
};

class AnnouncementForm extends Component<ComponentProps, {}> {
  readonly state = _.cloneDeep(defaultState);

  form = React.createRef<FormInstance>();

  componentDidMount() {
    const { announcement, studyId } = this.props;
    if (announcement && announcement.id) {
      const mergedAnnouncement: AnnouncementType = _.merge(
        _.cloneDeep(defaultState),
        announcement
      );
      this.setState({ ...mergedAnnouncement });
    } else {
      // Default state is set to create an annoucement
      // on the study level.
      const modifiedAnnouncement = _.cloneDeep(this.state);
      modifiedAnnouncement.typeId = studyId;
      this.setState({ ...modifiedAnnouncement });
    }
  }

  handleSave = (e: any) => {
    const { id } = this.state;
    if (this.form.current) {
      this.form.current.validateFields().then(() => {
        confirm({
          title: `Are you sure you want to ${
            id ? 'edit' : 'create'
          } this announcement?`,
          content: '',
          okText: 'Confirm',
          onOk: () => {
            this.handleSaveAnnouncement();
          },
          onCancel() {},
        });
      });
    }
  };

  handleSaveAnnouncement = async () => {
    const { closeHandler, createAnnouncement, updateAnnouncement } = this.props;
    const {
      id,
      title,
      body,
      type,
      typeId,
      effectiveStartDate,
      effectiveEndDate,
    } = this.state;
    const announcement: AnnouncementType = {
      id,
      title,
      body,
      type,
      typeId,
      effectiveStartDate,
      effectiveEndDate,
    };
    announcement.body = cleanstring(body);
    if (id) {
      updateAnnouncement(announcement);
    } else {
      createAnnouncement(announcement);
    }
    this.resetState();
    closeHandler();
  };

  handleCancel = (e: any) => {
    const { closeHandler } = this.props;
    confirm({
      title: 'Are you sure you want to leave this announcement?',
      content: 'You will lose all changes.',
      okText: 'Leave',
      okType: 'danger',
      onOk: () => {
        this.resetState();
        closeHandler();
      },
      onCancel() {},
    });
  };

  resetState = () => {
    const resetState = _.cloneDeep(defaultState);
    this.setState(resetState);
  };

  onTypeTypeIdChange = (value: string) => {
    const typeTypeId = value.split('-');
    if (typeTypeId.length === 2) {
      this.setState({
        type: typeTypeId[0],
        typeId: parseInt(typeTypeId[1]),
      });
    }
  };

  onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: e.target.value });
  };

  onBodyChange = (value: string) => {
    this.setState({ body: updateCmsEditorBeforeSave(value) });
  };

  validateBody = (rule, value, cb) => {
    const { body } = this.state;
    try {
      if (body.length === 0) {
        throw new Error('Body is required.');
      }
      cb();
    } catch (err) {
      cb(err);
    }
  };

  onStartDateChange = (date: Moment | null, dateString: string) => {
    this.setState({
      effectiveStartDate: dateString.length ? dateString : undefined,
    });
  };

  onEndDateChange = (date: Moment | null, dateString: string) => {
    this.setState({
      effectiveEndDate: dateString.length ? dateString : undefined,
    });
  };

  disabledDate = (current: Moment | null): boolean => {
    return !!(current && current < moment().startOf('day'));
  };

  validateStartDate = (rule, value, cb) => {
    try {
      const { effectiveEndDate } = this.state;
      if (effectiveEndDate && moment(value).isSameOrAfter(effectiveEndDate)) {
        throw new Error(
          "An announcement's start date must proceed its end date."
        );
      }
      cb();
    } catch (err) {
      cb(err);
    }
  };

  validateEndDate = (rule, value, cb) => {
    try {
      const { effectiveStartDate } = this.state;
      if (value && moment(effectiveStartDate).isSameOrAfter(value)) {
        throw new Error(
          "An announcement's end date must follow its start date."
        );
      }
      cb();
    } catch (err) {
      cb(err);
    }
  };

  render() {
    const {
      id,
      type,
      typeId,
      title,
      body,
      effectiveStartDate,
      effectiveEndDate,
    } = this.state;
    const { visible, arms, studyId } = this.props;
    const footerActions = [
      <Button type="primary" onClick={this.handleSave} key="btn-save">
        Save
      </Button>,
      <Button type="default" onClick={this.handleCancel} key="btn-cancel">
        Cancel
      </Button>,
    ];
    return (
      <Modal
        title="Add/Edit Announcement"
        visible={visible}
        onOk={this.handleSave}
        onCancel={this.handleCancel}
        footer={footerActions}
        closable={false}
        destroyOnClose
        width="50%"
        style={{ minWidth: '400px' }}
      >
        <Form
          ref={this.form}
          key={`announcement-form-${id}`}
          layout="vertical"
          colon={false}
        >
          <Item
            key={`type-${id}`}
            name="type"
            label="Type:"
            style={itemStyle}
            rules={[{ required: true, message: 'Type is required.' }]}
            initialValue={`${type}-${typeId}`}
          >
            <Select onChange={this.onTypeTypeIdChange} style={{ width: 120 }}>
              <Option value={`study-${studyId}`}>Study</Option>
              {arms &&
                arms.map((arm, index) => {
                  return (
                    <Option value={`arm-${arm.id}`} key={index}>
                      {arm.name}
                    </Option>
                  );
                })}
            </Select>
          </Item>
          <Item
            key={`title-${id}`}
            name="title"
            label="Title:"
            style={itemStyle}
            rules={[{ required: true, message: 'Title is required.' }]}
            initialValue={title}
          >
            <Input placeholder="Title" onChange={this.onTitleChange} />
          </Item>
          <Item
            key={`body-${id}`}
            name="body"
            label="Body:"
            style={itemStyle}
            rules={[{ required: true, validator: this.validateBody }]}
            valuePropName="model"
            initialValue={body}
          >
            <FroalaCustomUpload onChange={this.onBodyChange} />
          </Item>
          <Item
            key={`effectiveStartDate-${id}`}
            name="effectiveStartDate"
            label={
              <span>
                Start Date:
                <ActivityTooltip text="If no start date is provided this will start now. Otherwise it will start on the hour you specify in the user's local timezone." />
              </span>
            }
            style={itemStyle}
            rules={[{ required: false, validator: this.validateStartDate }]}
            validateTrigger={['onChange']}
            initialValue={
              effectiveStartDate ? moment(effectiveStartDate) : undefined
            }
          >
            <DatePicker
              disabledDate={this.disabledDate}
              showTime
              placeholder="Select start date"
              onChange={this.onStartDateChange}
              format="YYYY-MM-DD HH:mm"
            />
          </Item>
          <Item
            key={`effectiveEndDate-${id}`}
            name="effectiveEndDate"
            label={
              <span>
                End Date:
                <ActivityTooltip text="If no end date is provided, then the announcement will be active until the next announcement start date. Otherwise it will end on the hour you specify in the user's local timezone" />
              </span>
            }
            style={itemStyle}
            rules={[{ required: false, validator: this.validateEndDate }]}
            validateTrigger={['onChange']}
            initialValue={
              effectiveEndDate ? moment(effectiveEndDate) : undefined
            }
          >
            <DatePicker
              disabledDate={this.disabledDate}
              showTime
              placeholder="Select end date"
              onChange={this.onEndDateChange}
              format="YYYY-MM-DD HH:mm"
            />
          </Item>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    studyId: selectors.getRequestedStudyId(state),
    arms: selectors.getRequestedStudyStudyArms(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    createAnnouncement: (announcement: AnnouncementType) =>
      dispatch(createAnnouncementAsync.request(announcement)),
    updateAnnouncement: (announcement: AnnouncementType) =>
      dispatch(updateAnnouncementAsync.request(announcement)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementForm);
