import * as _ from 'lodash';
import { Button, List, message, Popover, Tooltip, Menu, Dropdown } from 'antd';
import {
  LikeOutlined,
  MailOutlined,
  MessageOutlined,
  StarOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import React, { Component, ReactNode, RefObject } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { sendAdHocAdminQnaNotificationAsync } from '../../redux/notification/notification.types';
import * as selectors from '../../redux/selectors';
import IApplicationState from '../../types/state.types';
import './newQnaPostForm.scss';
import QnaCommentList from './QnaCommentList';
import QnaFormContainer from './QnaFormContainer';
import Username from '../username/Username';
import { AdminUserType } from '../../types/serverTypes/adminTypes';
import { QnaPostType } from '../../types/serverTypes/qnaTypes';

const { Item } = List;

interface StateProps {
  experts: AdminUserType[];
  studyId: number;
}

interface DispatchProps {
  sendAdHocAdminQnaNotification: typeof sendAdHocAdminQnaNotificationAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {
  post: QnaPostType;
  deleteQnaPost: (id: number) => void;
}

class QnaDetailCard extends Component<ComponentProps, {}> {
  private froalaRef: RefObject<any>;

  readonly state = {
    viewForm: false,
    isNotificationPopupVisible: false,
  };

  constructor(props: ComponentProps) {
    super(props);
    this.froalaRef = React.createRef();
  }

  renderDate = (value: string | Date) => {
    if (!value) {
      return 'N/a';
    }
    return moment(value).calendar();
  };

  allcaps = (s: string): string => s.toUpperCase();

  copyDeepLink = (id: number) => {
    const deepLink = `hmp://qna/${id}`;
    navigator.clipboard.writeText(deepLink).then(
      () => {
        message.success('Copied to clipboard!');
      },
      () => {
        message.error('Failed to copy to clipboard.');
      }
    );
  };

  deletePost = () => {
    const { post, deleteQnaPost } = this.props;
    if (post.id) {
      deleteQnaPost(post.id);
    }
  };

  showQnaForm = () => {
    this.setState({ viewForm: true });
  };

  closeQnaForm = () => {
    this.setState({ viewForm: false });
  };

  private handleNotificationPopoverVisibleChange(visible: boolean) {
    this.setState({ isNotificationPopupVisible: visible });
  }

  private sendAdHocNotification(userId: number) {
    const { id: qnaPostId } = this.props.post;
    const { sendAdHocAdminQnaNotification } = this.props;

    if (qnaPostId) {
      sendAdHocAdminQnaNotification({ userId, qnaPostId });
      message.success('Notification sent successfully.');
    }

    this.setState({ isNotificationPopupVisible: false });
  }

  showAdHocNotificationPopover = () => {
    this.setState({
      isNotificationPopupVisible: true,
    });
  };

  handleActionsDropdownVisibleChange = (flag: boolean) => {
    if (!flag) {
      this.setState({
        isNotificationPopupVisible: flag,
      });
    }
  };

  render() {
    const { viewForm } = this.state;
    const { post, experts } = this.props;

    return (
      <div id="hmp-qna-detail-card">
        {viewForm && (
          <QnaFormContainer
            visible={viewForm}
            closeHandler={this.closeQnaForm}
            post={_.cloneDeep(post)}
          />
        )}
        <List
          bordered
          itemLayout="vertical"
          size="large"
          dataSource={[post]}
          renderItem={(item: QnaPostType) => {
            const {
              id,
              favorites,
              thumbsups,
              comments,
              topic,
              question,
              answer,
              createdByParticipantId,
              createDate,
              adHocNotifications,
              deleteDate,
            } = item;
            const commentCount: number = comments
              ? comments.length +
                comments
                  .map((c) => c.comments.length)
                  .reduce((sum, c) => sum + c, 0)
              : 0;
            const sendAdHocNotificationPopoverContent = (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%',
                  marginBottom: '10px',
                }}
              >
                {...experts.map((e) => {
                  return (
                    <div
                      key={e.id}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <Button
                        style={{ width: '90%' }}
                        onClick={() => this.sendAdHocNotification(e.id)}
                      >
                        <MailOutlined /> {e.username}{' '}
                      </Button>
                    </div>
                  );
                })}
              </div>
            );
            let actions: ReactNode[] = [];
            const sendAdHocNotificationPopover = (
              <Popover
                content={sendAdHocNotificationPopoverContent}
                title="Notify Expert of this post"
                trigger="click"
                visible={this.state.isNotificationPopupVisible}
                onVisibleChange={this.handleNotificationPopoverVisibleChange.bind(
                  this
                )}
              >
                <Tooltip
                  placement="bottom"
                  title={() => {
                    return (
                      <div>
                        {(adHocNotifications || []).map((n) => (
                          <div>
                            <b>{n.username}</b>{' '}
                            {moment(n.createDate).calendar()} <br />{' '}
                            <b>{n.method}:</b>
                            <b>{n.statusCode ? 'success' : 'error'}</b>
                            <br />
                            <br />
                          </div>
                        ))}
                      </div>
                    );
                  }}
                >
                  <span className="hmp-qna-icon" key="list-vertical-mail">
                    <MailOutlined style={{ marginRight: 8 }} />
                    {adHocNotifications?.length
                      ? adHocNotifications[0].username
                      : 'Notify'}
                  </span>
                </Tooltip>
              </Popover>
            );
            const dropdownMenu = (
              <Menu>
                <Menu.Item
                  key="delete"
                  title="Delete post"
                  className="qna-action"
                  onClick={(e) => this.deletePost()}
                >
                  <i className="fal fa-trash-alt" /> Delete
                </Menu.Item>
                <Menu.Item
                  key="edit"
                  title="Edit"
                  className="qna-action"
                  onClick={(e) => this.showQnaForm()}
                >
                  <i className="far fa-edit" /> Edit
                </Menu.Item>
                <Menu.Item
                  key="notify"
                  title="Notify expert"
                  className="qna-action"
                  onClick={(e) => this.showAdHocNotificationPopover()}
                >
                  {sendAdHocNotificationPopover}
                </Menu.Item>
              </Menu>
            );
            actions = actions.concat([
              <span className="hmp-qna-username">
                <Username participantId={createdByParticipantId} />
              </span>,
              <span className="hmp-qna-date">
                {this.renderDate(createDate)}
              </span>,
              <span className="hmp-qna-icon" key="list-vertical-message">
                <MessageOutlined style={{ marginRight: 8 }} />
                {commentCount}
              </span>,
              <span className="hmp-qna-icon" key="list-vertical-like-o">
                <LikeOutlined style={{ marginRight: 8 }} />
                {thumbsups ? thumbsups.length : 0}
              </span>,
              <span className="hmp-qna-icon" key="list-vertical-like-o">
                <StarOutlined style={{ marginRight: 8 }} />
                {favorites ? favorites.length : 0}
              </span>,
              <a
                title="Copy deep link to clipboard"
                className="qna-action"
                onClick={(e) => {
                  e.stopPropagation();
                  this.copyDeepLink(id);
                }}
              >
                <i className="far fa-link" />
              </a>,
              <Dropdown
                trigger={['click']}
                overlay={dropdownMenu}
                onVisibleChange={this.handleActionsDropdownVisibleChange}
              >
                <a className="hmp-qna-icon">
                  <i className="far fa-ellipsis-h fa-lg" />
                </a>
              </Dropdown>,
            ]);

            const tags: any = [];
            if (deleteDate !== undefined && deleteDate !== null) {
              tags.push(
                <span
                  title={moment(deleteDate).calendar()}
                  className="post-tag-bubble deleted"
                >
                  DELETED
                </span>
              );
            }
            return (
              <div>
                <Item key={`qna-post-${id}`} actions={actions}>
                  <Item.Meta title={`Q: ${question}`} />
                  {answer ? (
                    <>
                      <div>Answer:</div>
                      <div dangerouslySetInnerHTML={{ __html: answer }} />
                    </>
                  ) : (
                    '(Unanswered)'
                  )}
                  <div className="hmp-qna-topic-container">
                    {tags}
                    <span className="hmp-qna-topic">{this.allcaps(topic)}</span>
                  </div>
                </Item>
                <QnaCommentList level={0} comments={comments} />
              </div>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    experts: selectors.getExpertAdmin(state),
    studyId: selectors.getRequestedStudyId(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    sendAdHocAdminQnaNotification: (val: {
      qnaPostId: number;
      userId: number;
    }) => dispatch(sendAdHocAdminQnaNotificationAsync.request(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QnaDetailCard);
