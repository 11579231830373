import { AxiosResponse } from "axios";
import { normalize } from "normalizr";
import _ from "lodash";
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { getType } from "typesafe-actions";
import axios from "../api";
import { entitySchema } from "../schema";
import {
  getGoalsAsync,
  createGoalAsync,
  updateGoalAsync,
  GetGoalsArguments,
  getGoalAsync,
  deleteGoalAsync,
} from "./goals.types";
import {GoalType} from "../../types/serverTypes/goalTypes";
import {NormalizerResult} from "../../types";

const getGoals = (args: GetGoalsArguments) => {
  const {
    query,
    pageNumber,
    pageSize,
    includeDeleted = true,
    includeUnpublished = true,
  } = args;
  let url = `/a/goal/all?includeDeleted=${includeDeleted}&includeUnpublished=${includeUnpublished}`;
  if (query && !!query.length) {
    url += `&query=${query}`;
  }
  if (_.isNumber(pageNumber) && _.isNumber(pageSize)) {
    url += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }
  return axios({
    method: "get",
    url,
  });
};

const getGoal = (id: string) => {
  return axios({
    method: "get",
    url: `/a/goal/${id}`,
  });
};

const createGoal = (goal: GoalType) => {
  return axios({
    method: "put",
    url: `/a/goal/create`,
    data: goal,
  });
};

const updateGoal = (goal: GoalType) => {
  const { id } = goal;
  return axios({
    method: "put",
    url: `/a/goal/${id}/update`,
    data: goal,
  });
};

const deleteGoal = (id: string) => {
  return axios({
    method: "delete",
    url: `/a/goal/${id}/delete`,
  });
};

function* getGoalsHandler(
  action: ReturnType<typeof getGoalsAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      getGoals,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      response.data,
      entitySchema.goals
    ) as NormalizerResult;
    const { goals } = entities;
    yield put(getGoalsAsync.success(goals));
  } catch (error) {
    yield put(getGoalsAsync.failure(error));
  }
}

function* createGoalHandler(
  action: ReturnType<typeof createGoalAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      createGoal,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.goals
    ) as NormalizerResult;
    const { goals } = entities;
    yield put(createGoalAsync.success(goals));
  } catch (error) {
    yield put(createGoalAsync.failure(error));
  }
}

function* getGoalHandler(
  action: ReturnType<typeof getGoalAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      getGoal,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.goals
    ) as NormalizerResult;
    const { goals } = entities;
    yield put(getGoalAsync.success(goals));
  } catch (error) {
    yield put(getGoalAsync.failure(error));
  }
}

function* updateGoalHandler(
  action: ReturnType<typeof updateGoalAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      updateGoal,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.goals
    ) as NormalizerResult;
    const { goals } = entities;
    yield put(updateGoalAsync.success(goals));
  } catch (error) {
    yield put(updateGoalAsync.failure(error));
  }
}

function* deleteGoalHandler(
  action: ReturnType<typeof deleteGoalAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      deleteGoal,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.goals
    ) as NormalizerResult;
    const { goals } = entities;
    yield put(deleteGoalAsync.success(goals));
  } catch (error) {
    yield put(deleteGoalAsync.failure(error));
  }
}

function* getGoalsWatcher() {
  yield takeLatest(getType(getGoalsAsync.request), getGoalsHandler);
}

function* getGoalWatcher() {
  yield takeEvery(getType(getGoalAsync.request), getGoalHandler);
}

function* createGoalWatcher() {
  yield takeLatest(getType(createGoalAsync.request), createGoalHandler);
}

function* updateGoalWatcher() {
  yield takeLatest(getType(updateGoalAsync.request), updateGoalHandler);
}

function* deleteGoalWatcher() {
  yield takeLatest(getType(deleteGoalAsync.request), deleteGoalHandler);
}

export default function* goalsSaga() {
  yield all([
    fork(getGoalsWatcher),
    fork(getGoalWatcher),
    fork(createGoalWatcher),
    fork(updateGoalWatcher),
    fork(deleteGoalWatcher),
  ]);
}
