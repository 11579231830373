import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Icon from '@ant-design/icons';

interface ComponentProps {
  fontAwesomeIcon: IconDefinition;
  size?: IconSize;
  classes?: string;
  title?: string;
}

declare type IconSize =
  | 'xs'
  | 'lg'
  | 'sm'
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x'
  | undefined;

export const AntdIcon = (props: ComponentProps) => {

  const {
    fontAwesomeIcon, size, classes, title
  } = props;

  const FaIcon = (icon: IconDefinition) => {
    return () => (
      <FontAwesomeIcon size={size} icon={icon} className={classes} />
    );
  };

  return <Icon title={title} component={FaIcon(fontAwesomeIcon)} />;
};

export default AntdIcon;
