import { Tabs } from 'antd';
import FlaggedParticipants from '../../../../components/participant/FlaggedParticipants';
import Participants from '../../../../components/participant/Participants';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getStudyId } from '../../../../service/studyService';
import { getDefaultTab } from '../../../../service/participant/participantService';
const { TabPane } = Tabs;

interface StateProps {
  participantSubpage: string;
}

const ParticipantsScreen = (props: RouteComponentProps) => {
  const [state, setState] = useState<StateProps>({
    participantSubpage: 'participants',
  });
  const handleTabChange = async (activeKey: string) => {
    setState({ ...state, participantSubpage: activeKey });
    const studyId = getStudyId();
    props.history.push(
      `/study/${studyId}/participants${
        activeKey === 'flagged' ? '/flagged' : ''
      }`
    );
  };

  useEffect(() => {
    setState({ participantSubpage: getDefaultTab(props.location.pathname) });
  }, [props]);

  return (
    <Tabs
      defaultActiveKey="participants"
      activeKey={state.participantSubpage}
      onChange={handleTabChange}
    >
      <TabPane key="participants" tab="Participants">
        <Participants />
      </TabPane>
      <TabPane key="flagged" tab="Flagged Participants">
        <FlaggedParticipants />
      </TabPane>
    </Tabs>
  );
};

export default withRouter(ParticipantsScreen);
