import Icon, { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';

interface ComponentProps {
  collapsed: boolean,
  toggleCallback: () => void
}

const SideBarToggleButton = (props: ComponentProps) => {

  const { collapsed, toggleCallback } = props;
  if (collapsed) {
    return (
      <MenuUnfoldOutlined
        onClick={toggleCallback}
        style={{ fontSize: '24px', cursor: 'hand', color: 'white' }}
      />
    );
  }
  return (
    <MenuFoldOutlined
      onClick={toggleCallback}
      style={{ fontSize: '24px', cursor: 'hand', color: 'white' }}
    />
  );
};

SideBarToggleButton.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  toggleCallback: PropTypes.func.isRequired
};

export default SideBarToggleButton;
