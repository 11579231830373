import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { Card } from 'antd';
import Button from 'antd/lib/button';
import Tabs from 'antd/lib/tabs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import AntdIcon from '../../../components/antdIcon/AntdIcon';
import ParticipantsByMonthBarChart from '../../../components/charts/bar/ParticipantsByMonthBarChart';
import * as selectors from '../../../redux/selectors';
import IApplicationState from '../../../types/state.types';
import { StudyType } from '../../../types/serverTypes/studyTypes';

const { TabPane } = Tabs;

interface StateProps {
  study: Optional<StudyType>
}

interface ComponentProps extends StateProps {}

class ArmsPage extends Component<ComponentProps, {}> {

  render() {

    const { study } = this.props;

    if (!study) {
      return <div>Error Loading Study</div>;
    }

    const addArm = (
      <Button disabled>
        Add Arm
        <AntdIcon fontAwesomeIcon={faPlusCircle} classes="fa-lg" />
      </Button>
    );

    // TODO use this array to populate the ArmTabPanes
    const arms = study.studyArms;

    return (
      <div className="arms-page">

        <Tabs defaultActiveKey="arm-1" tabBarExtraContent={addArm}>
          <TabPane key="arm-1" tab="Control Group">
            <Card>
              <div style={{ height: '300px' }}>
                <ParticipantsByMonthBarChart data={participantCounts} />
              </div>
            </Card>
          </TabPane>
          <TabPane key="arm-2" tab="Arm 1: No Referrals">
            <Card>
              <div style={{ height: '300px' }}>
                <ParticipantsByMonthBarChart data={participantCounts} />
              </div>
            </Card>
          </TabPane>
          <TabPane key="arm-3" tab="Arm 2: Peer Referrals">
            <Card>
              <div style={{ height: '300px' }}>
                <ParticipantsByMonthBarChart data={participantCounts} />
              </div>
            </Card>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  study: selectors.getRequestedStudy
});

const participantCounts = [
  {
    month: 'JAN',
    Active: 72,
    ActiveColor: 'hsl(311, 70%, 50%)',
    Accepted: 5,
    AcceptedColor: 'hsl(33, 70%, 50%)',
    Dismissed: 0,
    DismissedColor: 'hsl(343, 70%, 50%)',
    Dropped: 1,
    DroppedColor: 'hsl(319, 70%, 50%)',
    Finished: 2,
    FinishedColor: 'hsl(276, 70%, 50%)'
  },
  {
    month: 'FEB',
    Active: 150,
    ActiveColor: 'hsl(311, 70%, 50%)',
    Accepted: 7,
    AcceptedColor: 'hsl(33, 70%, 50%)',
    Dismissed: 1,
    DismissedColor: 'hsl(343, 70%, 50%)',
    Dropped: 3,
    DroppedColor: 'hsl(319, 70%, 50%)',
    Finished: 15,
    FinishedColor: 'hsl(276, 70%, 50%)'
  },
  {
    month: 'MAR',
    Active: 203,
    ActiveColor: 'hsl(311, 70%, 50%)',
    Accepted: 4,
    AcceptedColor: 'hsl(33, 70%, 50%)',
    Dismissed: 0,
    DismissedColor: 'hsl(343, 70%, 50%)',
    Dropped: 2,
    DroppedColor: 'hsl(319, 70%, 50%)',
    Finished: 33,
    FinishedColor: 'hsl(276, 70%, 50%)'
  }
];

export default connect(mapStateToProps)(ArmsPage);
