import React from 'react';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import Form from 'antd/lib/form';
import FeedbackItem from './FeedbackItem';
import '../activity.scss';
import { FeedbackType, OptionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  type: number;
  disabled: boolean;
  feedback: FeedbackType[];
  options: OptionType[];
  updateFeedback: (feedback: FeedbackType, index: number) => void;
  addFeedback: () => void;
  removeFeedback: (index: number) => void;
}

const DynamicFeedbackItems = (props: ComponentProps) => {
  const {
    type,
    disabled,
    feedback,
    options,
    updateFeedback,
    addFeedback,
    removeFeedback
  } = props;
  const onAddClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    addFeedback();
  };
  const items = feedback.map((f: FeedbackType, index: number) => {
    return (
      <li
        key={`feedback-item-${f.id ? f.id : f.uuid}`}
        className="feedback-list-item-container"
      >
        <FeedbackItem
          type={type}
          disabled={disabled}
          feedback={f}
          options={options}
          updateFeedback={(fb: FeedbackType) => updateFeedback(fb, index)}
        />
        {(!disabled) && (
          <button
            disabled={disabled}
            className="dashed-danger-button"
            onClick={() => removeFeedback(index)}
          >
            <MinusOutlined />
            {' '}
            Remove Feedback
          </button>
        )}
      </li>
    );
  });
  return (
    <ul className="dynamic-feedback-items">
      {items}
      <Item>
        <Button
          disabled={disabled}
          type="dashed"
          onClick={onAddClick}
          style={{ width: '100%' }}
        >
          <PlusOutlined />
          {' '}
          Add feedback
        </Button>
      </Item>
    </ul>
  );
};

export default DynamicFeedbackItems;
