import { ActionType, createAsyncAction } from 'typesafe-actions';

export const loadDashboardDataAsync = createAsyncAction(
  'hmp/admin/LOAD_DASHBOARD_REQUEST',
  'hmp/admin/LOAD_DASHBOARD_SUCCESS',
  'hmp/admin/LOAD_DASHBOARD_FAILURE'
)<void, void, Error>();

const dashboardActions = {
  loadDashboardDataAsync
};

export type DashboardActionTypes = ActionType<typeof dashboardActions>;
