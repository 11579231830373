import { MoreOutlined } from '@ant-design/icons';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  AvatarPartCategoryType,
  AvatarPartType,
} from 'types/serverTypes/avatarTypes';

interface AvatarCustomPartProp {
  part: AvatarPartType;
  category: AvatarPartCategoryType;
  baseBody: Map<number, AvatarPartType>;
  selectedPart?: AvatarPartType;
  onAvatarPartDetail: (part) => void;
  index: number;
}
const AvatarCustomPart = (props: AvatarCustomPartProp) => {
  let isSelected = false;
  props.baseBody.forEach((item) => {
    if (item.id === props.part.id) isSelected = true;
  });
  const selected = props.part.id === props.selectedPart?.id || isSelected;
  return (
    <Draggable draggableId={`part-${props.part.id}`} index={props.index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={`${
            selected ? 'avatar-part-selected' : ''
          } avatar-svg-container${
            props.part.isBaseSetDefaultApplied
              ? '-base-set-applied'
              : props.part.isBaseSet
              ? '-base-set'
              : ''
          }`}
        >
          <div
            className="avatar-svg"
            onClick={() => props.onAvatarPartDetail(props.part)}
            dangerouslySetInnerHTML={{
              __html: props.part.svg,
            }}
          ></div>
          {!!props.part.secondarySvg && <MoreOutlined className="more-icon" />}
        </div>
      )}
    </Draggable>
  );
};

export default AvatarCustomPart;
