import {getType} from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {initialNormalizedState, NormalizedState} from '../../types/state.types';
import {getThumbsupsAsync, ThumbsupsActionTypes} from "./thumbsups.types";
import {ThumbsupType} from "../../types/serverTypes/forumTypes";

const updateThumbsupsReducer = produce((draft, thumbsups) => {
  if (!thumbsups) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(thumbsups), Number));
  const ids = Object.keys(thumbsups);
  for (const id of ids) {
    draft.byId[id] = thumbsups[id];
  }
});

export const thumbsupsReducer = (state: NormalizedState<ThumbsupType> = initialNormalizedState, action: ThumbsupsActionTypes) => {

  switch (action.type) {
    case getType(getThumbsupsAsync.success): {
      return updateThumbsupsReducer(state, action.payload);
    }
    default: return state;
  }
};

export default thumbsupsReducer;
