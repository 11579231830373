import { schema } from 'normalizr';

/**
 * Define the entities schema...
 *
 * {
 *   studies: {
 *     "1": StudyType,
 *     "2": StudyType
 *   },
 *   studyArms: {
 *     "1": StudyArmType,
 *     "2": StudyArmType
 *   },
 *   participants: {
 *     "1": ParticipantType,
 *     "2": ParticipantType
 *   },
 *   posts: {
 *     "1": PostType,
 *     "2": PostType
 *   }
 * }
 *
 */

export const avatarSchema = new schema.Entity('avatars');
export const avatarListSchema = new schema.Array(avatarSchema);

export const adminSchema = new schema.Entity('admin');
export const adminListSchema = new schema.Array(adminSchema);

export const participantSchema = new schema.Entity(
  'participants',
  { avatar: avatarSchema },
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        avatar: { id: value.avatarId },
      };
    },
  }
);
export const participantListSchema = new schema.Array(participantSchema);

export const studyArmSchema = new schema.Entity('studyArms');

export const studyArmListSchema = new schema.Array(studyArmSchema);

export const studySchema = new schema.Entity('studies', {
  studyArms: studyArmListSchema,
});
export const studyListSchema = new schema.Array(studySchema);

export const noteSchema = new schema.Entity('notes');
export const noteListSchema = new schema.Array(noteSchema);

export const notificationSchema = new schema.Entity(
  'notifications',
  { participant: participantSchema },
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: { id: value.participantId },
      };
    },
  }
);
export const notificationListSchema = new schema.Array(notificationSchema);

export const userSurveySchema = new schema.Entity(
  'userSurveys',
  { participant: participantSchema },
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: { id: value.participantId },
      };
    },
  }
);
export const userSurveyListSchema = new schema.Array(userSurveySchema);

export const postSchema = new schema.Entity(
  'posts',
  { participant: participantSchema },
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: { id: value.createdByParticipantId },
      };
    },
  }
);
export const postListSchema = new schema.Array(postSchema);

export const topicSchema = new schema.Entity('topics');
topicSchema.define({ parentTopic: [topicSchema] });
export const topicListSchema = new schema.Array(topicSchema);

export const formSchema = new schema.Entity('forms');
export const formListSchema = new schema.Array(formSchema);

export const commentSchema = new schema.Entity(
  'comments',
  { participant: participantSchema },
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: { id: value.participantId },
      };
    },
  }
);
export const commentListSchema = new schema.Array(commentSchema);

export const messageSchema = new schema.Entity(
  'messages',
  {},
  { idAttribute: 'messageId' }
);
export const messageListSchema = new schema.Array(messageSchema);
export const messageResponseSchema = new schema.Entity(
  'messageResponses',
  { messages: messageListSchema },
  {
    idAttribute: 'threadId',
  }
);
export const messageResponseListSchema = new schema.Array(
  messageResponseSchema
);

export const threadSchema = new schema.Entity(
  'threads',
  { participant: participantSchema },
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: { id: value.participantId },
      };
    },
  }
);
export const threadListSchema = new schema.Array(threadSchema);

export const participantBadgeSchema = new schema.Entity('participantBadges');
export const participantBadgeListSchema = new schema.Array(
  participantBadgeSchema
);

export const badgeSchema = new schema.Entity('badges');
export const badgeListSchema = new schema.Array(badgeSchema);

export const badgeCollectionSchema = new schema.Entity('badgeCollections');
export const badgeCollectionListSchema = new schema.Array(
  badgeCollectionSchema
);

export const badgeGroupSchema = new schema.Entity('badgeGroups');
export const badgeGroupListSchema = new schema.Array(badgeGroupSchema);

export const badgeCollectionTypeSchema = new schema.Entity(
  'badgeCollectionTypes'
);
export const badgeCollectionTypeListSchema = new schema.Array(
  badgeCollectionTypeSchema
);

export const paymentSchema = new schema.Entity(
  'payments',
  {},
  { idAttribute: 'incentivePaymentId' }
);
export const paymentListSchema = new schema.Array(paymentSchema);

export const testkitSchema = new schema.Entity(
  'testkits',
  { participant: participantSchema },
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: { id: value.participantId },
      };
    },
  }
);
export const testkitListSchema = new schema.Array(testkitSchema);

export const flagSchema = new schema.Entity(
  'flags',
  {
    admin: adminSchema,
    participant: participantSchema,
    flaggedParticipant: participantSchema,
  },
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: { id: value.participantId },
        flaggedParticipant: { id: value.flaggedParticipantId },
        admin: { id: value.resolveUserId },
      };
    },
  }
);
export const flagListSchema = new schema.Array(flagSchema);

export const thumbsupSchema = new schema.Entity(
  'thumbsups',
  { participant: participantSchema },
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: { id: value.participantId },
      };
    },
  }
);
export const thumbsupListSchema = new schema.Array(thumbsupSchema);

export const favoriteSchema = new schema.Entity(
  'favorites',
  { participant: participantSchema },
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        participant: { id: value.participantId },
      };
    },
  }
);
export const favoriteListSchema = new schema.Array(favoriteSchema);

export const activitySchema = new schema.Entity('activities');
export const activityListSchema = new schema.Array(activitySchema);

export const activityCategorySchema = new schema.Entity('activityCategories');
export const activityCategoryListSchema = new schema.Array(
  activityCategorySchema
);

export const activityTypeSchema = new schema.Entity('activityTypes');
export const activityTypeListSchema = new schema.Array(activityTypeSchema);

export const articleSchema = new schema.Entity(
  'articles',
  {
    topic: topicSchema,
  },
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        topic: { id: value.topicId },
      };
    },
  }
);
export const articleListSchema = new schema.Array(articleSchema);

export const announcementSchema = new schema.Entity('announcements');
export const announcementListSchema = new schema.Array(announcementSchema);

export const appointmentSchema = new schema.Entity('appointments');
export const appointmentListSchema = new schema.Array(appointmentSchema);

export const availabilitySchema = new schema.Entity('availabilities');
export const availabilitiesListSchema = new schema.Array(availabilitySchema);

export const qnaPostSchema = new schema.Entity('qnaPosts');
export const qnaPostListSchema = new schema.Array(qnaPostSchema);

export const adminRoleSchema = new schema.Entity('adminRoles');
export const adminRoleListSchema = new schema.Array(adminRoleSchema);

export const forumSchema = new schema.Entity('forums');
export const forumListSchema = new schema.Array(forumSchema);

export const goalSchema = new schema.Entity(
  'goals',
  { topic: topicSchema },
  {
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        topic: { id: value.topicId },
      };
    },
  }
);
export const goalListSchema = new schema.Array(goalSchema);

export const stringLookupSchema = new schema.Entity('stringLookups');
export const stringLookupListSchema = new schema.Array(stringLookupSchema);

export const entitySchema = {
  activities: activityListSchema,
  activityCategories: activityCategoryListSchema,
  activityTypes: activityTypeListSchema,
  admin: adminListSchema,
  adminRoles: adminRoleListSchema,
  announcements: announcementListSchema,
  appointments: appointmentListSchema,
  availabilities: availabilitiesListSchema,
  articles: articleListSchema,
  avatars: avatarListSchema,
  badgeCollections: badgeCollectionListSchema,
  badgeCollectionTypes: badgeCollectionTypeListSchema,
  badgeGroups: badgeGroupListSchema,
  badges: badgeListSchema,
  comments: commentListSchema,
  favorites: favoriteListSchema,
  flags: flagListSchema,
  forms: formListSchema,
  forums: forumListSchema,
  goals: goalListSchema,
  stringLookups: stringLookupListSchema,
  messageResponses: messageResponseListSchema,
  messages: messageListSchema,
  notes: noteListSchema,
  notifications: notificationListSchema,
  participantBadges: participantBadgeListSchema,
  participants: participantListSchema,
  payments: paymentListSchema,
  posts: postListSchema,
  qnaPosts: qnaPostListSchema,
  studies: studyListSchema,
  studyArms: studyArmListSchema,
  testkits: testkitListSchema,
  threads: threadListSchema,
  thumbsups: thumbsupListSchema,
  topics: topicListSchema,
  userSurveys: userSurveyListSchema,
};

export default entitySchema;
