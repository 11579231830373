import React, { useState } from 'react';
import { Modal, Button, Upload, Table, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { KnowledgeCenterArticleExportType } from '../../types/serverTypes/knowledgeCenterTypes';
import KnowledgeCenterService from '../../service/knowledgeCenter/knowledgeCenterService';
import {
  ArticleFolder,
  ArticlesFile,
} from '../../constant/serverConstants/knowledgeCenterConstants';
import * as path from 'path';
import { parseJSON } from '../../service/util';

const JSZip = require('jszip');

interface ResourceImportModalProp {
  showModal: boolean;
  handleCancel: () => void;
}
const ResourceImportModal = (props: ResourceImportModalProp) => {
  const [importing, setImporting] = useState<boolean>(false);
  const [zipFile, setZipFile] = useState();
  const [articles, setArticles] =
    useState<KnowledgeCenterArticleExportType[]>();
  const [fileList, setFileList] = useState<any[]>([]);

  const handleSave = async () => {
    try {
      setImporting(true);
      if (zipFile) {
        const formData = new FormData();
        formData.append('file', zipFile);
        await KnowledgeCenterService.importResources(formData);
        window.location.reload();
        message.success('Import completed successfully');
      }
    } catch (err) {
      message.error('Error while importing: ' + err);
    } finally {
      setImporting(false);
      onCancel();
    }
  };

  const onCancel = () => {
    setZipFile(undefined);
    setArticles(undefined);
    setFileList([]);
    props.handleCancel();
  };
  const handlePreview = async () => {
    const resourceZip = new JSZip();

    resourceZip.loadAsync(zipFile).then(async function (zip) {
      const articleJson = await zip
        .file(path.join(ArticleFolder, ArticlesFile))
        .async('string');
      setArticles(parseJSON(articleJson));
    });
  };
  const beforeUpload = (file) => {
    setZipFile(file);
    setFileList([file]);
    return false;
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Summary',
      dataIndex: 'summary',
      key: 'summary',
    },
  ];

  return (
    <Modal
      width={800}
      title="Import Resources (articles and topics)"
      visible={props.showModal}
      onOk={handleSave}
      onCancel={onCancel}
      footer={[
        <Button key="preview" type="link" onClick={handlePreview}>
          Preview
        </Button>,
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={importing}
          onClick={handleSave}
        >
          Submit
        </Button>,
      ]}
    >
      <Upload accept="zip" beforeUpload={beforeUpload} fileList={fileList}>
        <Button icon={<UploadOutlined />}>Click to Upload Resource File</Button>
      </Upload>
      {articles && articles.length > 0 && (
        <Table dataSource={articles} columns={columns} rowKey="id" />
      )}
    </Modal>
  );
};

export default ResourceImportModal;
