import { createSelector } from 'reselect';
import IApplicationState from '../../types/state.types';
import {DashboardDataType} from "../../types";

export const selectDashboard = (state: IApplicationState) => {
  return state.dashboard;
};

export const getDashboardData = createSelector(
  [selectDashboard],
  (dashboardData:DashboardDataType) => {
    return dashboardData;
  }
);
