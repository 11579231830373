import React, { Component } from 'react';
import { _ } from 'lodash';
import { Modal, Input, Cascader } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import IApplicationState from '../../types/state.types';
import { CreateThreadArguments } from '../../redux/messages/messages.types';
import * as selectors from '../../redux/selectors';
import './thread.scss';
import { ParticipantType } from '../../types/serverTypes/studyTypes';

const { TextArea } = Input;
const { confirm } = Modal;
const { Item } = Form;

interface StateProps {
  participants: ParticipantType[]
}
interface ComponentProps extends StateProps {
  createThread: (thread: CreateThreadArguments) => Promise<void>;
  close: () => void;
  visible: boolean;
}
interface ComponentState {
  isUnsaved: boolean;
  confirmLoading: boolean;
}
function filter(inputValue: any, path: any): boolean {
  return path.some((option: any) => (option.label
    ? option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    : false));
}

class NewThreadListItem extends Component<ComponentProps, ComponentState> {
  readonly state: ComponentState = {
    isUnsaved: false,
    confirmLoading: false
  };

  form = React.createRef<FormInstance>();

  handleOk = async () => {
    if (this.form.current) {
      const form = this.form.current;
      const { createThread, close } = this.props;
      await form.validateFields().then(async () => {
        const {
          title,
          body,
          participant
        } = form.getFieldsValue();
        this.setState({
          confirmLoading: true
        });
        await createThread({
          title,
          body,
          participantId: parseInt(participant[0])
        }).then(() => {
          this.setState({
            isUnsaved: false,
            confirmLoading: false
          });
          close();
        });
      });
    }
  };

  handleCancel = () => {
    const { close } = this.props;
    if (this.state.isUnsaved) {
      confirm({
        title: 'Are you sure you want to delete this message?',
        content: '',
        okText: 'Delete',
        okType: 'danger',
        onOk: () => {
          close();
        },
        onCancel() {}
      });
    } else {
      close();
    }
  };

  onChange = () => {
    this.setState({ isUnsaved: true });
  };

  render() {
    const { visible, participants } = this.props;
    const { confirmLoading } = this.state;
    const options = _.map(participants, (participant) => _.pick(participant, ['username', 'id'])).map((option) => {
      return { label: option.username, value: option.id.toString() };
    });
    return (
      <Modal
        destroyOnClose
        closable={false}
        centered
        title="Compose Message"
        visible={visible}
        okText="Send Message"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        confirmLoading={confirmLoading}
      >
        <Form
          ref={this.form}
          key="thread-form"
          layout="vertical"
          colon={false}
        >
          <Item
            name="participant"
            label="Participant:"
            rules={[{ required: true, message: 'Participant is required.' }]}
          >
            <Cascader
              placeholder="Enter participant's username"
              options={options}
              onChange={this.onChange}
              showSearch={{ filter }}
            />
          </Item>
          <Item
            name="title"
            label="Title:"
            rules={[
              { required: true, message: 'Title is required.' },
              { max: 50, message: 'Exceeds 50 character limit.' }
            ]}
          >
            <Input
              placeholder="Enter thread title"
              onChange={this.onChange}
            />
          </Item>
          <Item
            name="body"
            label="Message:"
            rules={[{ required: true, message: 'Message body is required.' }]}
          >
            <TextArea
              placeholder="Type a message"
              autoSize={{ minRows: 2, maxRows: 6 }}
              onChange={this.onChange}
            />
          </Item>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    participants: selectors.getRequestedStudyParticipantsWithUsernames
  }
);

export default connect(mapStateToProps)(NewThreadListItem);
