import { Collapse, Popconfirm } from 'antd';
import Avatar from 'antd/lib/avatar';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Col from 'antd/lib/grid/col';
import Row from 'antd/lib/grid/row';
import Switch from 'antd/lib/switch';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PaymentType } from '../../../../types/serverTypes/paymentTypes';
import {
  getStudyArmLabelFromId,
  renderDateWithTime,
} from '../../../../components/util/Util';
import {
  saveParticipantActualHivStatusAsync,
  saveParticipantInterviewSelectedAsync,
} from '../../../../redux/participants/participants.types';
import { requestExitInterviewPaymentAsync } from '../../../../redux/payment/payment.types';
import * as selectors from '../../../../redux/selectors';
import {
  ParticipantType,
  StudyArmType,
} from '../../../../types/serverTypes/studyTypes';
import IApplicationState from '../../../../types/state.types';

const { Panel } = Collapse;

interface StateProps {
  exitInterviewPayment: Optional<PaymentType>;
  studyArms: Optional<StudyArmType[]>;
  studyId: number;
  surveysFeatureEnabled: boolean;
  referralsFeatureEnabled: boolean;
  exitInterviewFeatureEnabled: boolean;
}

interface DispatchProps {
  requestExitInterviewPayment: typeof requestExitInterviewPaymentAsync.request;
  saveParticipantActualHivStatus: typeof saveParticipantActualHivStatusAsync.request;
  saveParticipantInterviewSelected: typeof saveParticipantInterviewSelectedAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {
  participant: ParticipantType;
  avatarBase64?: string;
}

class ParticipantViewCard extends Component<ComponentProps, StateProps> {
  render() {
    const requestExitInterviewPayment = () => {
      const { participant, requestExitInterviewPayment } = this.props;
      requestExitInterviewPayment(participant.id);
    };

    const {
      participant,
      avatarBase64,
      saveParticipantActualHivStatus,
      saveParticipantInterviewSelected,
      studyArms,
      studyId,
      exitInterviewPayment,
      surveysFeatureEnabled,
      referralsFeatureEnabled,
      exitInterviewFeatureEnabled,
    } = this.props;

    const avatar = avatarBase64 ? (
      <Avatar
        size={50}
        src={avatarBase64}
        style={{
          backgroundColor: participant.avatarBackground
            ? `#${participant.avatarBackground}`
            : '#FFB100',
        }}
      />
    ) : null;

    return (
      <Collapse defaultActiveKey={['headerPanel']}>
        <Panel
          key="headerPanel"
          header={
            <div className="profile" style={{display: 'inline-block'}}>
              <Row align="middle" justify="start" style={{minWidth: "700px"}}>
                <Col span={3}>
                  <div className="profile-header">{avatar}</div>
                </Col>
                <Col span={16} pull={1}>
                  <div className="profile-header">
                    {' '}
                    {`${participant.firstName} ${
                      participant.lastName ? participant.lastName : ''
                    } (${participant.id}) `}
                  </div>
                </Col>
              </Row>
            </div>
          }
        >
          <div className="profile">
            <div className="profile-container">
              <div className="demo">
                <div className="demo-section">
                  <div className="demo-row">
                    <div className="label">
                      <label>Username</label>
                    </div>
                    <div className="field">{participant.username}</div>
                  </div>
                  <div className="demo-row">
                    <div className="label">
                      <label>Email</label>
                    </div>
                    <div className="field">{participant.email}</div>
                  </div>
                  <div className="demo-row">
                    <div className="label">
                      <label>Mobile</label>
                    </div>
                    <div className="field">{participant.mobile}</div>
                  </div>
                  <div className="demo-row">
                    <div className="label" style={{ alignSelf: 'flex-start' }}>
                      <label>Address</label>
                    </div>
                    <div className="field">
                      {participant.address1 ? participant.address1 : ''}
                      <br />
                      {participant.address2 ? `${participant.address2}` : ''}
                      {participant.address2 ? <br /> : ''}
                      {`${participant.city ? participant.city : ''}, ${
                        participant.state ? participant.state : ''
                      } ${
                        participant.postalCode ? participant.postalCode : ''
                      }`}
                    </div>
                  </div>
                </div>
                {(surveysFeatureEnabled || referralsFeatureEnabled) && (
                  <div className="demo-section">
                    {surveysFeatureEnabled && (
                      <div className="demo-row">
                        <div className="label">
                          <label>Qualtrics Study Id</label>
                        </div>
                        <div className="field">
                          {participant.qualtricsStudyId}
                        </div>
                      </div>
                    )}
                    {referralsFeatureEnabled && (
                      <div className="demo-row">
                        <div className="label">
                          <label>Referred By</label>
                        </div>
                        {participant.referredByParticipantId ? (
                          <a
                            href={`/study/${studyId}/participants/${participant.referredByParticipantId}/`}
                          >
                            {participant.referredByParticipant?.username}{' '}
                          </a>
                        ) : (
                          <div className="field">None</div>
                        )}
                      </div>
                    )}
                    {referralsFeatureEnabled && (
                      <div className="demo-row">
                        <div className="label">
                          <label>Referrals</label>
                        </div>
                        {participant.referredParticipants ? (
                          <div className="field">
                            {_.map(
                              participant.referredParticipants,
                              (p: ParticipantType) => {
                                return (
                                  <div key={`ref_${p.id}`}>
                                    <a
                                      href={`/study/${studyId}/participants/${p.id}/`}
                                    >
                                      {p.username}{' '}
                                    </a>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          <div className="field">None</div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                <div className="demo-section">
                  <div className="demo-row">
                    <div className="label">
                      <label>Study Arm</label>
                    </div>
                    <div className="field">
                      {getStudyArmLabelFromId(
                        studyArms,
                        participant.studyArmId
                      )}
                    </div>
                  </div>
                  <div className="demo-row">
                    <div className="label">
                      <label>Type</label>
                    </div>
                    <div className="field">{participant.type}</div>
                  </div>
                  <div className="demo-row">
                    <div className="label">
                      <label>Created Date</label>
                    </div>
                    <div className="field">
                      {moment(participant.createDate).format('MM/DD/YYYY')}
                    </div>
                  </div>
                  <div className="demo-row">
                    <div className="label">
                      <label>Mobile Enrollment Date</label>
                    </div>
                    <div className="field">
                      {moment(participant.mobileEnrollmentDate).format(
                        'MM/DD/YYYY'
                      )}
                    </div>
                  </div>
                  <div className="demo-row">
                    <div className="label">
                      <label>External Id</label>
                    </div>
                    <div className="field">{participant.externalId}</div>
                  </div>
                </div>
                <div className="demo-section">
                  {exitInterviewFeatureEnabled && (
                    <div className="demo-row">
                      <div className="label">
                        <label>Exit Interview?</label>
                      </div>
                      <div className="field">
                        <Popconfirm
                          title={'Are you sure?'}
                          onConfirm={() => {
                            saveParticipantInterviewSelected({
                              participantId: participant.id,
                              interviewSelected: Boolean(
                                !participant.interviewSelected
                              ),
                            });
                          }}
                        >
                          <Switch checked={participant.interviewSelected} />
                        </Popconfirm>
                      </div>
                    </div>
                  )}
                  {exitInterviewFeatureEnabled &&
                    !!participant.interviewSelected &&
                    !exitInterviewPayment && (
                      <div className="demo-row">
                        <div className="label">
                          <label>Pay Incentive</label>
                        </div>
                        <div className="field">
                          <Button
                            size="small"
                            onClick={requestExitInterviewPayment}
                          >
                            Create Incentive
                          </Button>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    studyArms: selectors.getStudyArms,
    studyId: selectors.getRequestedStudyId,
    exitInterviewPayment: selectors.getRequestedParticipantExitInterviewPayment,
    surveysFeatureEnabled: selectors.getSurveysFeatureEnabled,
    referralsFeatureEnabled: selectors.getReferralsFeatureEnabled,
    exitInterviewFeatureEnabled: selectors.getExitInterviewFeatureEnabled,
  }
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    requestExitInterviewPayment: (participantId: number) =>
      dispatch(requestExitInterviewPaymentAsync.request(participantId)),
    saveParticipantActualHivStatus: (val: {
      participantId: number;
      status: string;
    }) => dispatch(saveParticipantActualHivStatusAsync.request(val)),
    saveParticipantInterviewSelected: (val: {
      participantId: number;
      interviewSelected: Boolean;
    }) => dispatch(saveParticipantInterviewSelectedAsync.request(val)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipantViewCard);
