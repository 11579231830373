export const FontColor = '#8185B3';

export const TimeZoneColorStyles = {
  singleValue: (base) => ({
    ...base,
    color: FontColor,
  }),
  input: (base) => ({
    ...base,
    color: FontColor,
  }),
};
