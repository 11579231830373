import {AxiosResponse} from 'axios';
import {normalize} from 'normalizr';
import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects';
import {getType} from 'typesafe-actions';
import {NormalizedType} from '../../types/state.types';
import axios from '../api';
import {entitySchema} from '../schema';
import {
  deleteArticleAsync,
  getRequestedArticleAsync,
  loadArticlesAsync,
  PublishArticleArguments,
  publishArticleAsync,
  saveArticleAsync,
  unpublishArticleAsync,
  updateNewArticleId,
  updateSelectedArticleIds,
} from "./article.types";
import * as selectors from '../selectors';
import {ArticleType} from "../../types/serverTypes/articleTypes";
import {NormalizerResult} from "../../types";

const getArticles = (
  query?:string,
  topicIds?:number[],
  includeDeleted?: boolean,
  includeUnpublished?: boolean
) => {
  let url = `/a/kc/articles?includeDeleted=${includeDeleted}&includeUnpublished=${includeUnpublished}`;
  if (query) {
    url += `&query=${query}`;
  }

  if (topicIds && topicIds.length) {
    url += '&topicIds=' + topicIds.join(',');
  }

  return axios( {
    method: 'get',
    url
  })
};

const deleteArticle = (articleId: number) => {
  return axios({
    method: 'delete',
    url: `/a/kc/article/${articleId}`
  })
}

const getArticle = (articleId: number) => {
  const url = `/a/kc/article/${articleId}`;
  return axios({
    method: 'get',
    url
  })
};

const saveArticle = (article: ArticleType) => {
  return axios({
    method: 'post',
    url: '/a/cms/save',
    data: article
  })
};

const publishArticle = (args: PublishArticleArguments) => {
  const { id, publishDate } = args;
  return axios({
    method: 'put',
    url: `/a/kc/${id}/publish`,
    data: {
      publishDate
    }
  });
};

const unpublishArticle = (id: number) => {
  return axios({
    method: 'put',
    url: `/a/kc/${id}/unpublish`
  });
};

function* loadArticlesHandler(action: ReturnType<typeof loadArticlesAsync.request>) {
  try {
    const { query, topicIds, includeDeleted, includeUnpublished } = action.payload;

    const response: AxiosResponse = (yield call(getArticles, query, topicIds, includeDeleted, includeUnpublished)) as AxiosResponse;
    const { entities, result } = normalize(response.data, entitySchema.articles) as NormalizerResult;
    const { articles } = entities;
    yield put(loadArticlesAsync.success(articles));
    yield put(updateSelectedArticleIds(result));

  } catch (error) {
    yield put(loadArticlesAsync.failure(error));
  }
}

function* deleteArticleHandler(action: ReturnType<typeof deleteArticleAsync.request>) {
  try {
    const articleId:number = action.payload
    const response: AxiosResponse = (yield call(deleteArticle, articleId)) as AxiosResponse;
    const {entities} = normalize([response.data], entitySchema.articles) as NormalizerResult;
    const { articles } = entities;
    yield put(deleteArticleAsync.success(articles));

  } catch (error) {
    yield put(deleteArticleAsync.failure(error));
  }
}

function* getRequestedArticleHandler(action: ReturnType<typeof getRequestedArticleAsync.request>) {
  try {
    const requestedArticleId: number = (yield select(selectors.getRequestedEditResourceId)) as number;

    const response: AxiosResponse = (yield call(getArticle, requestedArticleId)) as AxiosResponse;
    // const {entities} = normalize(response.data, entitySchema.articles) as NormalizerResult;
    const article = {[requestedArticleId]: response.data} as Optional<NormalizedType<ArticleType>>;
    yield put(getRequestedArticleAsync.success(article));

  } catch (error) {
    yield put(getRequestedArticleAsync.failure(error));
  }
}

function* saveArticleHandler(action: ReturnType<typeof saveArticleAsync.request>) {
  try {
    const article:ArticleType  = action.payload;

    const response: AxiosResponse = (yield call(saveArticle, article)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.articles) as NormalizerResult;
    const { articles } = entities;
    yield put(updateNewArticleId(response.data.id));
    yield put(saveArticleAsync.success(articles));
  } catch (error) {
    yield put(saveArticleAsync.failure(error));
  }
}

function* publishArticleHandler(action: ReturnType<typeof publishArticleAsync.request>) {
  try {
    const response: AxiosResponse = (yield call(publishArticle, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.articles) as NormalizerResult;
    const { articles } = entities;
    yield put(publishArticleAsync.success(articles));
  } catch (error) {
    yield put(publishArticleAsync.failure(error));
  }
}

function* unpublishArticleHandler(action: ReturnType<typeof unpublishArticleAsync.request>) {
  try {
    const response: AxiosResponse = (yield call(unpublishArticle, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.articles) as NormalizerResult;
    const { articles } = entities;
    yield put(unpublishArticleAsync.success(articles));
  } catch (error) {
    yield put(unpublishArticleAsync.failure(error));
  }
}

function* publishArticleWatcher() {
  yield takeLatest(getType(publishArticleAsync.request), publishArticleHandler);
}

function* unpublishArticleWatcher() {
  yield takeLatest(getType(unpublishArticleAsync.request), unpublishArticleHandler);
}

function* loadArticlesWatcher() {
  yield takeLatest(getType(loadArticlesAsync.request), loadArticlesHandler);
}

function* deleteArticleWatcher() {
  yield takeLatest(getType(deleteArticleAsync.request), deleteArticleHandler);
}

function* getRequestedArticleWatcher() {
  yield takeLatest(getType(getRequestedArticleAsync.request), getRequestedArticleHandler);
}

function* saveArticleWatcher() {
  yield takeLatest(getType(saveArticleAsync.request), saveArticleHandler);
}

export default function* articleSaga() {
  yield all([
    fork(loadArticlesWatcher),
    fork(deleteArticleWatcher),
    fork(getRequestedArticleWatcher),
    fork(saveArticleWatcher),
    fork(publishArticleWatcher),
    fork(unpublishArticleWatcher)
  ])
}