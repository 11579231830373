import {AxiosResponse} from 'axios';
import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {normalize} from 'normalizr';
import {getType} from 'typesafe-actions';
import axios from '../api';
import {entitySchema} from '../schema';
import {
  awardParticipantBadgeAsync,
  deleteBadgeCollectionAsync,
  deleteBadgeGroupAsync,
  getBadgeCollectionsAsync,
  getBadgeCollectionTypesAsync,
  getBadgeGroupsAsync,
  getParticipantBadgesAsync,
  saveBadgeCollectionAsync,
  saveBadgeGroupAsync,
  undeleteBadgeCollectionAsync,
  undeleteBadgeGroupAsync,
} from './badges.types';
import {BadgeCollectionType, BadgeGroupType} from "../../types/serverTypes";
import {NormalizerResult} from "../../types";

const getBadgeCollections = () => {
  return axios({
    method: 'get',
    url: '/a/badge/collections'
  });
};

const getBadgeGroups = () => {
  return axios({
    method: 'get',
    url: '/a/badge/groups'
  });
};

const getBadgeCollectionTypes = () => {
  return axios({
    method: 'get',
    url: '/a/badge/collectionTypes'
  });
};

const deleteBadgeCollection = (badgeCollectionId:number) => {
  return axios({
    method: 'delete',
    url: `/a/badge/collections/${badgeCollectionId}`
  });
};

const deleteBadgeGroup = (badgeGroupId:number) => {
  return axios({
    method: 'delete',
    url: `/a/badge/groups/${badgeGroupId}`
  });
};

const undeleteBadgeGroup = (badgeGroupId:number) => {
  return axios({
    method: 'put',
    url: `/a/badge/groups/undelete/${badgeGroupId}`
  });
};

const undeleteBadgeCollection = (badgeCollectionId:number) => {
  return axios({
    method: 'put',
    url: `/a/badge/collections/undelete/${badgeCollectionId}`
  });
};

const saveBadgeGroup = (badgeGroup:BadgeGroupType) => {
  return axios( {
    method: 'put',
    url: `/a/badge/groups`,
    data: badgeGroup
  })
};

const saveBadgeCollection = (badgeCollection:BadgeCollectionType) => {
  return axios( {
    method: 'put',
    url: `/a/badge/collections`,
    data: badgeCollection
  })
};


const getParticipantBadges = (participantId:number) => {
  return axios({
    method: 'get',
    url: `/a/badge/getBadgesForParticipant?participantId=${participantId}`
  });
};

const awardParticipantBadge = (participantId:number, badgeCollectionId: number) => {
  return axios({
    method: 'put',
    url: `/a/badge/awardBadgeToParticipant/${badgeCollectionId}/${participantId}`
  });
};

function* getBadgeCollectionsHandler(action: ReturnType<typeof getBadgeCollectionsAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(getBadgeCollections)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.badgeCollections) as NormalizerResult;
    const { badgeCollections } = entities;

    yield put(getBadgeCollectionsAsync.success(badgeCollections));
  } catch (error) {
    yield put(getBadgeCollectionsAsync.failure(error));
  }
}

function* getBadgeGroupsHandler(action: ReturnType<typeof getBadgeGroupsAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(getBadgeGroups)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.badgeGroups) as NormalizerResult;
    const { badgeGroups } = entities;

    yield put(getBadgeGroupsAsync.success(badgeGroups));
  } catch (error) {
    yield put(getBadgeGroupsAsync.failure(error));
  }
}

function* getBadgeCollectionTypesHandler(action: ReturnType<typeof getBadgeCollectionTypesAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(getBadgeCollectionTypes)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.badgeCollectionTypes) as NormalizerResult;
    const { badgeCollectionTypes } = entities;

    yield put(getBadgeCollectionTypesAsync.success(badgeCollectionTypes));
  } catch (error) {
    yield put(getBadgeCollectionTypesAsync.failure(error));
  }
}

function* deleteBadgeCollectionHandler(action: ReturnType<typeof deleteBadgeCollectionAsync.request>): Generator {
  try {
    const badgeCollectionId: number = action.payload;
    const response: AxiosResponse = (yield call(deleteBadgeCollection, badgeCollectionId)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.badges) as NormalizerResult;
    const { badges } = entities;

    yield put(deleteBadgeCollectionAsync.success(badges));
  } catch (error) {
    yield put(deleteBadgeCollectionAsync.failure(error));
  }
}

function* deleteBadgeGroupHandler(action: ReturnType<typeof deleteBadgeGroupAsync.request>): Generator {
  try {
    const badgeGroupId: number = action.payload;
    const response: AxiosResponse = (yield call(deleteBadgeGroup, badgeGroupId)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.badgeGroups) as NormalizerResult;
    const { badgeGroups } = entities;

    yield put(deleteBadgeGroupAsync.success(badgeGroups));
  } catch (error) {
    yield put(deleteBadgeGroupAsync.failure(error));
  }
}

function* undeleteBadgeGroupHandler(action: ReturnType<typeof undeleteBadgeGroupAsync.request>): Generator {
  try {
    const badgeGroupId: number = action.payload;
    const response: AxiosResponse = (yield call(undeleteBadgeGroup, badgeGroupId)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.badgeGroups) as NormalizerResult;
    const { badgeGroups } = entities;

    yield put(undeleteBadgeGroupAsync.success(badgeGroups));
  } catch (error) {
    yield put(undeleteBadgeGroupAsync.failure(error));
  }
}

function* undeleteBadgeCollectionHandler(action: ReturnType<typeof undeleteBadgeCollectionAsync.request>): Generator {
  try {
    const badgeCollectionId: number = action.payload;
    const response: AxiosResponse = (yield call(undeleteBadgeCollection, badgeCollectionId)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.badges) as NormalizerResult;
    const { badges } = entities;

    yield put(undeleteBadgeCollectionAsync.success(badges));
  } catch (error) {
    yield put(undeleteBadgeCollectionAsync.failure(error));
  }
}

function* saveBadgeGroupHandler(action: ReturnType<typeof saveBadgeGroupAsync.request>): Generator {
  try {
    const badgeGroup: BadgeGroupType = action.payload;
    const response: AxiosResponse = (yield call(saveBadgeGroup, badgeGroup)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.badgeGroups) as NormalizerResult;
    const { badgeGroups } = entities;

    yield put(saveBadgeGroupAsync.success(badgeGroups));
  } catch (error) {
    yield put(saveBadgeGroupAsync.failure(error));
  }
}

function* saveBadgeCollectionHandler(action: ReturnType<typeof saveBadgeCollectionAsync.request>): Generator {
  try {
    const badgeCollection: BadgeCollectionType = action.payload;
    const response: AxiosResponse = (yield call(saveBadgeCollection, badgeCollection)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.badgeCollections) as NormalizerResult;
    const { badgeCollections } = entities;

    yield put(saveBadgeCollectionAsync.success(badgeCollections));
  } catch (error) {
    yield put(saveBadgeCollectionAsync.failure(error));
  }
}

function* getParticipantBadgesHandler(
  action: ReturnType<typeof getParticipantBadgesAsync.request>
): Generator {
  try {
    const participantId = action.payload;
    const response: AxiosResponse = (yield call(
      getParticipantBadges,
      participantId
    )) as AxiosResponse;
    const participantBadgesResponse = response.data ? response.data : [];
    const { entities } = normalize(
      participantBadgesResponse,
      entitySchema.participantBadges
    ) as NormalizerResult;
    const { participantBadges } = entities;

    yield put(getParticipantBadgesAsync.success(participantBadges));
  } catch (error) {
    yield put(getParticipantBadgesAsync.failure(error));
  }
}

function* awardParticipantBadgeHandler(action: ReturnType<typeof awardParticipantBadgeAsync.request>): Generator {

  try {
    const { participantId, badgeCollectionId} = action.payload;
    const response: AxiosResponse = (yield call(awardParticipantBadge, participantId, badgeCollectionId)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.badges) as NormalizerResult;
    const { badges } = entities;

    // yield put(awardParticipantBadgeAsync.success(badges));

    yield put(getParticipantBadgesAsync.request(participantId))

  } catch (error) {
    yield put(awardParticipantBadgeAsync.failure(error));
  }
}


function* getBadgeCollectionsWatcher() {
  yield takeLatest(getType(getBadgeCollectionsAsync.request), getBadgeCollectionsHandler);
}

function* getBadgeGroupsWatcher() {
  yield takeLatest(getType(getBadgeGroupsAsync.request), getBadgeGroupsHandler);
}

function* getBadgeCollectionTypesWatcher() {
  yield takeLatest(getType(getBadgeCollectionTypesAsync.request), getBadgeCollectionTypesHandler);
}

function* deleteBadgeCollectionWatcher() {
  yield takeLatest(getType(deleteBadgeCollectionAsync.request), deleteBadgeCollectionHandler);
}

function* deleteBadgeGroupWatcher() {
  yield takeLatest(getType(deleteBadgeGroupAsync.request), deleteBadgeGroupHandler);
}

function* undeleteBadgeGroupWatcher() {
  yield takeLatest(getType(undeleteBadgeGroupAsync.request), undeleteBadgeGroupHandler);
}

function* undeleteBadgeCollectionWatcher() {
  yield takeLatest(getType(undeleteBadgeCollectionAsync.request), undeleteBadgeCollectionHandler);
}

function* saveBadgeGroupWatcher() {
  yield takeLatest(getType(saveBadgeGroupAsync.request), saveBadgeGroupHandler);
}

function* saveBadgeCollectionWatcher() {
  yield takeLatest(getType(saveBadgeCollectionAsync.request), saveBadgeCollectionHandler);
}

function* getParticipantBadgesWatcher() {
  yield takeLatest(getType(getParticipantBadgesAsync.request), getParticipantBadgesHandler);
}

function* awardParticipantBadgeWatcher() {
  yield takeLatest(getType(awardParticipantBadgeAsync.request), awardParticipantBadgeHandler);
}

export default function* badgeSaga() {
  yield all([
    fork(getBadgeCollectionsWatcher),
    fork(getBadgeGroupsWatcher),
    fork(getBadgeCollectionTypesWatcher),
    fork(deleteBadgeCollectionWatcher),
    fork(deleteBadgeGroupWatcher),
    fork(undeleteBadgeGroupWatcher),
    fork(undeleteBadgeCollectionWatcher),
    fork(saveBadgeGroupWatcher),
    fork(saveBadgeCollectionWatcher),
    fork(getParticipantBadgesWatcher),
    fork(awardParticipantBadgeWatcher),
  ]);
}
