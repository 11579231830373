import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import * as _ from 'lodash';
import moment from 'moment';
import {
  Button,
  Card,
  DatePicker,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
  Spin,
} from 'antd';
import {
  MinusCircleOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import Form from 'antd/lib/form';
import Draggable from 'react-draggable';
import { ModalHeader } from '../modal/ModalHeader';
import HMPTextArea from '../textarea/HMPTextArea';
import {
  GoalConnectionType,
  GoalMilestoneType,
  GoalTaskType,
  GoalTipType,
  GoalSuggestionType,
  GoalDetailType,
} from '../../types/serverTypes/goalTypes';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { AddSuggestedGoalModal } from './AddSuggestedGoalModal';
import './goalForm.scss';
import {
  useCreateGoal,
  useDeleteGoal,
  useGoalDetailQuery,
  useGoalQuery,
  useGoalTopicQuery,
  useUpdateGoal,
} from '../../pages/study/goalSetting/queries';
import { UrlParamsType } from '../../types';

const { confirm } = Modal;
const { Item, List } = Form;
const { Option } = Select;

interface ComponentProps {
  visible: boolean;
  closeHandler: (id?: string) => void;
  isUnsaved: boolean;
  onUnsavedChange: (isUnsaved: boolean) => void;
}
enum DeletedItemType {
  connection = 'connections',
  milestone = 'milestones',
  task = 'tasks',
  tip = 'tips',
  suggestedGoal = 'suggestedGoals',
}
const itemStyle = {
  marginBottom: '10px',
  marginTop: '10px',
};

const initialValues = {
  title: '',
  description: '',
  connections: [],
  milestones: [],
  tasks: [],
  tips: [],
  suggestedGoals: [],
};

const GoalForm = ({
  isUnsaved,
  onUnsavedChange,
  closeHandler,
  visible,
}: ComponentProps) => {
  const [form] = Form.useForm<GoalDetailType>();
  const params = useParams<UrlParamsType>();
  const [draggable, setDraggable] = useState(false);
  const [isSuggestedGoalModalVisible, setIsSuggestedGoalModalVisible] =
    useState(false);
  const [deletedItemsMap, setDeletedItemsMap] = useState<
    Map<
      DeletedItemType,
      | GoalConnectionType[]
      | GoalMilestoneType[]
      | GoalTaskType[]
      | GoalTipType[]
      | GoalSuggestionType[]
    >
  >(new Map());
  const [suggestedGoalOptions, setSuggestedGoalOptions] = useState<
    { value; label }[]
  >([]);
  const { data: goals } = useGoalQuery(
    undefined,
    undefined,
    undefined,
    true,
    true
  );
  const { data: requestedGoal, isLoading } = useGoalDetailQuery(params.id!);
  const { data: topics } = useGoalTopicQuery(params.studyId);
  const deleteGoal = useDeleteGoal();
  const updateGoal = useUpdateGoal();
  const createGoal = useCreateGoal();

  useEffect(() => {
    if (!isUnsaved) {
      form.resetFields();
    }
    if (requestedGoal) {
      const { suggestedGoals } = requestedGoal;
      const suggestedGoalOptions =
        goals
          ?.filter(
            (goal) =>
              goal.id !== params.id &&
              !suggestedGoals?.find(
                (suggestedGoal) => suggestedGoal?.suggestedGoalId === goal.id
              )
          )
          ?.map((goal) => ({
            value: goal.id,
            label: goal.title,
          })) ?? [];
      setSuggestedGoalOptions(suggestedGoalOptions);
    }
  }, [requestedGoal]);

  const handleSave = (e: any) => {
    if (isUnsaved) {
      form.validateFields().then((fields) => {
        confirm({
          title: 'Are you sure you want to save this goal?',
          content: '',
          okText: 'Confirm',
          onOk: () => {
            const {
              title,
              description,
              journalPrompt,
              topicId,
              sequence,
              connections,
              milestones,
              tasks,
              tips,
              suggestedGoals,
              deleteDate,
              publishDate,
            } = fields;
            const deletedConnections = deletedItemsMap.get(
              DeletedItemType.connection
            );
            const deletedMilestones = deletedItemsMap.get(
              DeletedItemType.milestone
            );
            const deletedTasks = deletedItemsMap.get(DeletedItemType.task);
            const deletedTips = deletedItemsMap.get(DeletedItemType.tip);
            const deletedSuggestedGoals = deletedItemsMap.get(
              DeletedItemType.suggestedGoal
            );
            const itemDeleteDate = new Date();
            const mapDeleteDateToItem = (item) => ({
              ...item,
              deleteDate: itemDeleteDate,
            });
            const connectionsToSave = connections?.filter(
              (c) => c !== undefined
            );
            if (deletedConnections) {
              connectionsToSave?.push(
                ...(deletedConnections.map(
                  mapDeleteDateToItem
                ) as GoalConnectionType[])
              );
            }
            const milestonesToSave = milestones?.filter((m) => m !== undefined);
            if (deletedMilestones) {
              milestonesToSave?.push(
                ...(deletedMilestones.map(
                  mapDeleteDateToItem
                ) as GoalMilestoneType[])
              );
            }
            const tasksToSave = tasks?.filter((t) => t !== undefined);
            if (deletedTasks) {
              tasksToSave?.push(
                ...(deletedTasks.map(mapDeleteDateToItem) as GoalTaskType[])
              );
            }
            const tipsToSave = tips?.filter((t) => t !== undefined);
            if (deletedTips) {
              tipsToSave?.push(
                ...(deletedTips.map(mapDeleteDateToItem) as GoalTipType[])
              );
            }
            const suggestedGoalsToSave = suggestedGoals?.filter(
              (s) => s !== undefined
            );
            if (deletedSuggestedGoals) {
              suggestedGoalsToSave?.push(
                ...(deletedSuggestedGoals.map(
                  mapDeleteDateToItem
                ) as GoalSuggestionType[])
              );
            }
            const goalToSave: any = {
              title,
              participantId: requestedGoal?.participantId,
              description,
              journalPrompt,
              topicId,
              sequence,
              connections: connectionsToSave,
              milestones: milestonesToSave,
              tasks: tasksToSave,
              tips: tipsToSave,
              suggestedGoals: suggestedGoalsToSave,
              deleteDate: deleteDate
                ? moment(deleteDate).toISOString()
                : undefined,
              publishDate: publishDate
                ? moment(publishDate).toISOString()
                : undefined,
            };
            onUnsavedChange(false);
            if (
              !!params.id &&
              params.id.length > 0 &&
              params.subpage === 'edit'
            ) {
              updateGoal.mutate(
                {
                  ...goalToSave,
                  id: params.id,
                },
                {
                  onSuccess: () => {
                    message.success('Goal successfully updated.');
                    closeHandler();
                  },
                  onError: (err) => {
                    message.error('Error updating goal.');
                    onUnsavedChange(true);
                  },
                }
              );
            } else if (params.subpage === 'new') {
              createGoal.mutate(
                {
                  ...goalToSave,
                },
                {
                  onSuccess: (data) => {
                    message.success('Goal successfully created.');
                    closeHandler(data.id);
                  },
                  onError: (err) => {
                    message.error('Error creating goal.');
                    onUnsavedChange(true);
                  },
                }
              );
            }
          },
          onCancel() {},
        });
      });
    }
  };

  const onMouseOverHeader = () => {
    if (!draggable) {
      setDraggable(true);
    }
  };

  const onMouseOutHeader = () => {
    if (draggable) {
      setDraggable(false);
    }
  };

  const handleRemove = () => {
    confirm({
      title: 'Are you sure you want to delete this goal?',
      content: '',
      okText: 'Delete',
      okType: 'danger',
      onOk: () => {
        if (!!params.id && params.subpage !== 'new') {
          deleteGoal.mutate(params.id, {
            onSuccess: () => {
              message.success('Goal successfully deleted.');
              closeHandler();
            },
            onError: (err) => {
              message.error('Error deleting goal.');
            },
          });
        } else {
          message.success('Goal successfully deleted.');
          closeHandler();
        }
      },
      onCancel() {},
    });
  };

  const handleItemRemove = (
    remove: (index: number | number[]) => void,
    name,
    type: DeletedItemType
  ) => {
    if (!!params.id && params.subpage !== 'new') {
      const updatedDeletedItemsMap = new Map(deletedItemsMap.entries());
      const existingItems = updatedDeletedItemsMap.get(type);
      const item = form.getFieldValue([type, name]);
      if (item) {
        if (existingItems) {
          updatedDeletedItemsMap.set(type, [...existingItems, item]);
        } else {
          updatedDeletedItemsMap.set(type, [item]);
        }
        setDeletedItemsMap(updatedDeletedItemsMap);
      }
    }
    remove(name);
  };

  const handleAddSuggestedGoal = (suggestedGoalId: string, add) => {
    add({
      goalId: params.id,
      suggestedGoalId,
    });
    setIsSuggestedGoalModalVisible(false);
    onUnsavedChange(true);
  };

  const handleRemoveSuggestedGoal = (
    suggestedGoalId: string,
    index: number,
    remove
  ) => {
    const originalSuggestedGoals = (requestedGoal as Optional<GoalDetailType>)
      ?.suggestedGoals;
    // If editing an existing, non-new goal, then we should push the deleted suggestion
    // to the deletedItemsMap
    if (
      !!params.id &&
      params.subpage !== 'new' &&
      // If the original goal, didn't include the suggested goal being deleted,
      // there's no need to add it to the deletedItemsMap
      !!originalSuggestedGoals?.find(
        (s) => s.suggestedGoalId === suggestedGoalId
      )
    ) {
      const updatedDeletedItemsMap = new Map(deletedItemsMap.entries());
      const existingItems = updatedDeletedItemsMap.get(
        DeletedItemType.suggestedGoal
      );
      const item = form.getFieldValue([DeletedItemType.suggestedGoal, index]);
      if (item) {
        if (existingItems) {
          updatedDeletedItemsMap.set(DeletedItemType.suggestedGoal, [
            ...existingItems,
            item,
          ]);
        } else {
          updatedDeletedItemsMap.set(DeletedItemType.suggestedGoal, [item]);
        }
        setDeletedItemsMap(updatedDeletedItemsMap);
      }
    }
    remove(index);
    setIsSuggestedGoalModalVisible(false);
    onUnsavedChange(true);
  };

  const onValuesChange = (changes, values) => {
    const { suggestedGoals } = values;
    const suggestedGoalOptions =
      goals
        ?.filter(
          (goal) =>
            goal.id !== params.id &&
            !suggestedGoals?.find(
              (suggestedGoal) => suggestedGoal?.suggestedGoalId === goal.id
            )
        )
        ?.map((goal) => ({
          value: goal.id,
          label: goal.title,
        })) ?? [];
    setSuggestedGoalOptions(suggestedGoalOptions);
    onUnsavedChange(true);
  };
  return (
    <Modal
      key={`goal-form-modal-${params.id}`}
      title={
        <ModalHeader
          title={
            params.subpage === 'new'
              ? 'New Goal'
              : params.subpage === 'edit'
              ? requestedGoal?.title
              : ''
          }
          editing
          editClick={() => {}}
          showRemove={!requestedGoal?.deleteDate}
          removeClick={handleRemove}
          closeClick={() => closeHandler()}
          showSave={isUnsaved}
          saveClick={handleSave}
          onMouseOver={onMouseOverHeader}
          onMouseOut={onMouseOutHeader}
        />
      }
      visible={visible}
      onCancel={() => closeHandler()}
      footer={null}
      closable={false}
      destroyOnClose
      width="50%"
      style={{ minWidth: '400px' }}
      bodyStyle={{ height: '80vh', overflowY: 'auto' }}
      modalRender={(modal) => (
        <Draggable disabled={!draggable}>{modal}</Draggable>
      )}
    >
      <Form
        form={form}
        name="goal-form"
        key={`goal-form-${params.id}`}
        layout="vertical"
        colon={false}
        scrollToFirstError
        onValuesChange={onValuesChange}
        initialValues={{
          ...initialValues,
          ...requestedGoal,
          // Must convert Date properties to Moment
          publishDate: requestedGoal?.publishDate
            ? moment(requestedGoal.publishDate)
            : null,
          deleteDate: requestedGoal?.deleteDate
            ? moment(requestedGoal.deleteDate)
            : null,
        }}
      >
        <Item
          key={`title-${params.id}`}
          name="title"
          label="Title:"
          style={itemStyle}
          rules={[
            { required: true, message: 'Title is required.' },
            { max: 255 },
          ]}
        >
          <Input />
        </Item>
        <Item
          key={`description-${params.id}`}
          name="description"
          label="Description:"
          style={itemStyle}
          rules={[{ required: true, max: 5000 }]}
        >
          <HMPTextArea />
        </Item>
        <Item
          key={`journalPrompt-${params.id}`}
          name="journalPrompt"
          label="Journal Prompt:"
          style={itemStyle}
          rules={[{ max: 255 }]}
        >
          <Input />
        </Item>
        <Item
          key={`topicId-${params.id}`}
          name="topicId"
          label="Topic:"
          style={itemStyle}
          rules={[{ required: true, message: 'Type is required.' }]}
        >
          <Select
            showSearch
            style={{ width: 'auto', minWidth: 200 }}
            placeholder="Choose a topic"
            optionFilterProp="option"
            filterOption={(input, option) =>
              option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {topics &&
              _.map(_.sortBy(topics, 'title'), (topic) => (
                <Option
                  key={`option-${topic.id}`}
                  value={topic.id}
                  title={topic.title}
                >
                  {topic.title}
                </Option>
              ))}
          </Select>
        </Item>
        <Item
          key={`sequence-${params.id}`}
          name="sequence"
          label="Sequence:"
          style={itemStyle}
        >
          <InputNumber min={0} />
        </Item>
        <Item
          key={`deleteDate-${params.id}`}
          name="deleteDate"
          label="Delete Date:"
          style={itemStyle}
        >
          <DatePicker />
        </Item>
        <Item
          key={`publishDate-${params.id}`}
          name="publishDate"
          label="Publish Date:"
          style={itemStyle}
        >
          <DatePicker showTime />
        </Item>
        <Item label="Connections" style={itemStyle}>
          <List name="connections">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                      flexWrap: 'wrap',
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'title']}
                      rules={[
                        { required: true, message: 'Title required' },
                        { max: 255 },
                      ]}
                    >
                      <Input
                        style={{ minWidth: 200 }}
                        placeholder="Connection Title"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'uri']}
                      rules={[{ max: 2048 }]}
                    >
                      <Input
                        style={{ minWidth: 200 }}
                        placeholder="Connection Link"
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() =>
                        handleItemRemove(
                          remove,
                          name,
                          DeletedItemType.connection
                        )
                      }
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add connection
                  </Button>
                </Form.Item>
              </>
            )}
          </List>
        </Item>
        <Item label="Milestones" style={itemStyle}>
          <List name="milestones">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                      flexWrap: 'wrap',
                    }}
                    align="baseline"
                    direction="vertical"
                  >
                    <Space
                      key={`child-space-${key}`}
                      style={{ display: 'flex' }}
                      align="baseline"
                    >
                      <Item
                        {...restField}
                        name={[name, 'title']}
                        rules={[
                          { required: true, message: 'Title required' },
                          { max: 255 },
                        ]}
                      >
                        <Input
                          style={{ minWidth: 200 }}
                          placeholder="Milestone Title"
                        />
                      </Item>
                      <Item
                        {...restField}
                        name={[name, 'sequence']}
                        rules={[
                          { required: true, message: 'Sequence required' },
                        ]}
                        initialValue={0}
                      >
                        <InputNumber min={0} />
                      </Item>
                      <MinusCircleOutlined
                        onClick={() =>
                          handleItemRemove(
                            remove,
                            name,
                            DeletedItemType.milestone
                          )
                        }
                      />
                    </Space>
                    <Item
                      {...restField}
                      name={[name, 'description']}
                      rules={[{ max: 5000 }]}
                    >
                      <HMPTextArea
                        style={{ minWidth: 400 }}
                        placeholder="Description"
                      />
                    </Item>
                  </Space>
                ))}
                <Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add milestone
                  </Button>
                </Item>
              </>
            )}
          </List>
        </Item>
        <Item label="Tasks" style={itemStyle}>
          <List name="tasks">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                      flexWrap: 'wrap',
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'title']}
                      rules={[
                        { required: true, message: 'Title required' },
                        { max: 255 },
                      ]}
                    >
                      <Input
                        style={{ minWidth: 200 }}
                        placeholder="Task Title"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'uri']}
                      rules={[{ max: 2048 }]}
                    >
                      <Input
                        style={{ minWidth: 200 }}
                        placeholder="Task Link"
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() =>
                        handleItemRemove(remove, name, DeletedItemType.task)
                      }
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add task
                  </Button>
                </Form.Item>
              </>
            )}
          </List>
        </Item>
        <Item label="Tips" style={itemStyle}>
          <List name="tips">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                      flexWrap: 'wrap',
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'tip']}
                      rules={[
                        { required: true, message: 'Tip required' },
                        { max: 255 },
                      ]}
                    >
                      <HMPTextArea
                        style={{ minWidth: 400 }}
                        placeholder="Tip"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'uri']}
                      rules={[{ max: 2048 }]}
                    >
                      <Input style={{ minWidth: 200 }} placeholder="Tip Link" />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() =>
                        handleItemRemove(remove, name, DeletedItemType.tip)
                      }
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add tip
                  </Button>
                </Form.Item>
              </>
            )}
          </List>
        </Item>
        <Item
          label="Suggested Goals"
          style={itemStyle}
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.suggestedGoals?.length !==
            currentValues.suggestedGoals?.length
          }
        >
          {({ getFieldValue }) => {
            const suggestedGoals = getFieldValue('suggestedGoals');
            return (
              <List name="suggestedGoals">
                {(fields, { add, remove }) => (
                  <Space wrap>
                    {fields.map(({ key, name, ...restField }) => {
                      const suggestion = suggestedGoals[name];
                      const goal = goals?.find(
                        (g) => suggestion.suggestedGoalId === g.id
                      );
                      return (
                        <Card
                          key={`suggested-goal-${suggestion.suggestedGoalId}`}
                          actions={[
                            <Button
                              danger
                              type="ghost"
                              onClick={() =>
                                handleRemoveSuggestedGoal(
                                  suggestion.suggestedGoalId,
                                  name,
                                  remove
                                )
                              }
                              title="Delete suggested goal"
                              icon={<MinusOutlined />}
                              style={{ width: '100%' }}
                            >
                              Remove
                            </Button>,
                          ]}
                          style={{
                            width: 300,
                            height: 150,
                          }}
                          bodyStyle={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Title ellipsis level={4}>
                            {goal?.title}
                          </Title>
                          <Title ellipsis level={5} style={{ margin: 0 }}>
                            {topics?.find((t) => t.id === goal?.topicId)?.title}
                          </Title>
                          <Text ellipsis>{goal?.description}</Text>
                        </Card>
                      );
                    })}
                    <Item noStyle>
                      <Button
                        type="dashed"
                        onClick={() => setIsSuggestedGoalModalVisible(true)}
                        block
                        title="Add suggested goal"
                        icon={<PlusOutlined />}
                        style={{ height: 166, width: 166 }}
                      />
                      <AddSuggestedGoalModal
                        visible={isSuggestedGoalModalVisible}
                        options={suggestedGoalOptions}
                        onSubmit={(id) => handleAddSuggestedGoal(id, add)}
                        onCancel={() => setIsSuggestedGoalModalVisible(false)}
                      />
                    </Item>
                  </Space>
                )}
              </List>
            );
          }}
        </Item>
      </Form>
      {isLoading && <Spin />}
    </Modal>
  );
};

export default GoalForm;
