import React from 'react';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';
import { parseJSON } from '../../service/util';

interface ComponentProps extends Omit<ReactJsonViewProps, 'src'> {
  src?: object | string;
}

/**
 * This component simply wraps ReactJson with a div and allows control over the onClick
 * method so that when it's used in a table, the table's onRowClick doesn't propagate.
 *
 * Additionally, it overrides the src prop so that it can accept undefined to fix an issue
 * when using ReactJson inside antd's Form.Item.
 */

const HMPJsonView = ({ src, ...props }: ComponentProps) => {
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  return (
    <div onClick={onClick}>
      <ReactJson src={src ? parseJSON(src) : {}} {...props} />
    </div>
  );
};

export default HMPJsonView;
