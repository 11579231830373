import React, { ChangeEvent } from 'react';
import {
  Input, InputNumber, Checkbox, Form
} from 'antd';
import '../activity.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import OptionFeedbackItem from '../feedback/OptionFeedbackItem';
import { Type } from '../ActivityFormContainer';
import { FeedbackType, OptionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  disabled: boolean;
  option: OptionType;
  onTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onPointsChange: (value: string | number | undefined) => void;
  onCorrectChange: (e: CheckboxChangeEvent) => void;
  updateFeedback: (f: FeedbackType) => void;
  addFeedback: () => void;
  removeFeedback: () => void;
}

const QuizOption = (props: ComponentProps) => {
  const {
    option,
    disabled,
    onTextChange,
    onPointsChange,
    onCorrectChange,
    updateFeedback,
    removeFeedback,
    addFeedback
  } = props;
  const {
    text, points, isCorrect, feedback, id, uuid
  } = option;
  return (
    <Item
      key={`quiz-option-${id || uuid}`}
      name={`quiz-option-${id || uuid}`}
      className="option-container"
    >
      <Item
        key={`quiz-option-text-${id || uuid}`}
        name={`quiz-option-text-${id || uuid}`}
        rules={[{ required: true, message: 'Option must have text.' }]}
        initialValue={text}
      >
        <Input spellCheck disabled={disabled} placeholder="Option text" onChange={onTextChange} style={{ width: '100%' }} />
      </Item>
      <div className="option-props">
        <span className="option-prop">
          Correct Answer
          {' '}
          <Checkbox disabled={disabled} checked={isCorrect} onChange={onCorrectChange} />
        </span>
        <span className="option-prop">
          Point Value
          {' '}
          <InputNumber disabled={disabled} value={points} min={0} onChange={onPointsChange} />
        </span>
      </div>
      <h4>Option Feedback:</h4>
      <OptionFeedbackItem
        type={Type.quiz}
        disabled={disabled}
        feedback={feedback}
        option={option}
        updateFeedback={updateFeedback}
        addFeedback={addFeedback}
        removeFeedback={removeFeedback}
      />
    </Item>
  );
};

export default QuizOption;
