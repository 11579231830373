import React, { Component } from 'react';
import Tab from 'antd/lib/tabs';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  createAppointmentAsync,
  deleteAppointmentAsync,
  updateAppointmentAsync,
} from '../../../redux/appointments/appointments.types';
import IApplicationState from '../../../types/state.types';
import { EventInput } from '@fullcalendar/react';
import { message } from 'antd';
import * as selectors from '../../../redux/selectors';
import './appointmentLandingPage.scss';
import { clearStatus } from '../../../redux/api/api.types';
import { IApiRequestStatus } from 'types/api.types';
import { getType } from 'typesafe-actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getParticipantsByStudyAsync } from '../../../redux/participants/participants.types';
import AvailabilityCalendar from '../../../components/appointment/AvailabilityCalendar';
import AppointmentCalendar from '../../../components/appointment/AppointmentCalendar';
import { createStructuredSelector } from 'reselect';
import {
  createAvailabilityAsync,
  deleteAvailabilityAsync,
} from '../../../redux/availabilty/availability.types';

interface StateProps {
  studyId: number;
  appointmentSubpage: Optional<string>;
  showAvailability: boolean;
  createAppointmentStatus: IApiRequestStatus;
  updateAppointmentStatus: IApiRequestStatus;
  deleteAppointmentStatus: IApiRequestStatus;
  createAvailabilityStatus: IApiRequestStatus;
  deleteAvailabilityStatus: IApiRequestStatus;
}

interface DispatchProps {
  clearLoadStatus: typeof clearStatus;
  getParticipantsByStudy: typeof getParticipantsByStudyAsync.request;
}

interface ComponentProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {}

interface ComponentState {}

class AppointmentLandingPage extends Component<ComponentProps, ComponentState> {
  readonly state: ComponentState = {
    events: new Map<string, EventInput>(),
  };

  componentDidMount() {
    this.props.getParticipantsByStudy(this.props.studyId);
  }

  componentDidUpdate(prevProps: ComponentProps) {
    // Error and success handling
    const {
      createAppointmentStatus,
      updateAppointmentStatus,
      deleteAppointmentStatus,
      createAvailabilityStatus,
      deleteAvailabilityStatus,
      clearLoadStatus,
    } = this.props;
    if (
      !prevProps.createAppointmentStatus.isError &&
      createAppointmentStatus.isError
    ) {
      message.error(`Error: ${createAppointmentStatus.errorMessage}`);
      clearLoadStatus(getType(createAppointmentAsync.failure));
    } else if (
      !prevProps.updateAppointmentStatus.isError &&
      updateAppointmentStatus.isError
    ) {
      message.error('Error while updating appointment.');
      clearLoadStatus(getType(updateAppointmentAsync.failure));
    } else if (
      !prevProps.deleteAppointmentStatus.isError &&
      deleteAppointmentStatus.isError
    ) {
      message.error('Error while deleting appointment.');
      clearLoadStatus(getType(deleteAppointmentAsync.failure));
    } else if (
      !prevProps.createAppointmentStatus.isSuccess &&
      createAppointmentStatus.isSuccess
    ) {
      message.success('Appointment successfully created.');
      clearLoadStatus(getType(createAppointmentAsync.success));
    } else if (
      !prevProps.updateAppointmentStatus.isSuccess &&
      updateAppointmentStatus.isSuccess
    ) {
      message.success('Appointment successfully updated.');
      clearLoadStatus(getType(updateAppointmentAsync.success));
    } else if (
      !prevProps.deleteAppointmentStatus.isSuccess &&
      deleteAppointmentStatus.isSuccess
    ) {
      message.success('Appointment successfully deleted.');
      clearLoadStatus(getType(deleteAppointmentAsync.success));
    } else if (
      !prevProps.deleteAvailabilityStatus.isSuccess &&
      deleteAvailabilityStatus.isSuccess
    ) {
      message.success('Availability successfully deleted.');
      clearLoadStatus(getType(deleteAvailabilityAsync.success));
    } else if (
      !prevProps.deleteAvailabilityStatus.isError &&
      deleteAvailabilityStatus.isError
    ) {
      message.error('Error while deleting availability.');
      clearLoadStatus(getType(deleteAvailabilityAsync.failure));
    } else if (
      !prevProps.createAvailabilityStatus.isSuccess &&
      createAvailabilityStatus.isSuccess
    ) {
      message.success('Availability successfully created.');
      clearLoadStatus(getType(createAvailabilityAsync.success));
    } else if (
      !prevProps.createAvailabilityStatus.isError &&
      createAvailabilityStatus.isError
    ) {
      message.error(`Error while creating availability.`);
      clearLoadStatus(getType(createAvailabilityAsync.failure));
    }
  }

  handleTabChange = (activeKey: string) => {
    const { history, studyId } = this.props;
    history.push(`/study/${studyId}/appointments/${activeKey}`);
  };

  render() {
    const { showAvailability, appointmentSubpage } = this.props;

    if (showAvailability) {
      return (
        <div className="appointment-landing">
          <div className="appointment-main">
            <Tab
              defaultActiveKey={appointmentSubpage}
              onChange={this.handleTabChange}
            >
              <Tab.TabPane tab="Availability" key="availability">
                <AvailabilityCalendar />
              </Tab.TabPane>
              <Tab.TabPane tab="Appointments" key="appointments">
                <AppointmentCalendar />
              </Tab.TabPane>
            </Tab>
          </div>
        </div>
      );
    } else {
      return (
        <div className="appointment-landing">
          <div className="appointment-main">
            <AppointmentCalendar />
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    studyId: selectors.getRequestedStudyId,
    showAvailability: selectors.getAppointmentAvailabilityFeatureEnabled,
    createAppointmentStatus: selectors.createAppointmentStatus,
    updateAppointmentStatus: selectors.updateAppointmentStatus,
    deleteAppointmentStatus: selectors.deleteAppointmentStatus,
    appointmentSubpage: selectors.getRequestedAppointmentTab,
    createAvailabilityStatus: selectors.createAvailabilityStatus,
    deleteAvailabilityStatus: selectors.deleteAvailabilityStatus,
  }
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    clearLoadStatus: (type: string) => dispatch(clearStatus(type)),
    getParticipantsByStudy: (studyId: number) =>
      dispatch(getParticipantsByStudyAsync.request(studyId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppointmentLandingPage));
