import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  getAnnouncementsAsync,
  updateAnnouncements,
  createAnnouncementAsync,
  updateAnnouncementAsync
} from './announcements.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import {AnnouncementType} from "../../types/serverTypes/announcementTypes";

const updateAnnouncementsReducer = produce((draft, announcements) => {
  if (!announcements) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(announcements), Number));
  const ids = Object.keys(announcements);
  for (const id of ids) {
    draft.byId[id] = announcements[id];
  }
});

export const announcementsReducer = (state: NormalizedState<AnnouncementType> = initialNormalizedState, action: any) => {
  switch (action.type) {
    case getType(getAnnouncementsAsync.success): return updateAnnouncementsReducer(state, action.payload);
    case getType(createAnnouncementAsync.success): return updateAnnouncementsReducer(state, action.payload);
    case getType(updateAnnouncementAsync.success): return updateAnnouncementsReducer(state, action.payload);
    case getType(updateAnnouncements): return updateAnnouncementsReducer(state, action.payload);
    default: return state;
  }
};

export default announcementsReducer;
