import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import {FavoritesActionTypes, getFavoritesAsync} from "./favorites.types";
import {FavoriteType} from "../../types/serverTypes/forumTypes";

const updateFavoritesReducer = produce((draft, favorites) => {
  if (!favorites) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(favorites), Number));
  const ids = Object.keys(favorites);
  for (const id of ids) {
    draft.byId[id] = favorites[id];
  }
});

export const favoritesReducer = (state: NormalizedState<FavoriteType> = initialNormalizedState, action: FavoritesActionTypes) => {

  switch (action.type) {
    case getType(getFavoritesAsync.success): return updateFavoritesReducer(state, action.payload);
    default: return state;
  }
};

export default favoritesReducer;
