import {RouterState} from 'connected-react-router';
import {UserState as OidcUserState} from 'redux-oidc';
import {initialAnalyticsUIState} from '../redux/analitycs/analytics.ui.reducer';
import {initialArticleUIState} from '../redux/article/article.ui.reducer';
import {initialForumUIState} from '../redux/forum/forum.ui.reducer';
import {ISideBarState} from '../redux/sidebar/sidebar.types';
import {initialStudyUIState} from '../redux/study/study.ui.reducer';
import IApiState from './api.types';
import {GetGoalsArguments} from "redux/goals/goals.types";
import {IQnaUIState} from '../redux/qna/qna.types';
import {IMessageUIState} from '../redux/messages/messages.types';
import {UserType} from "./serverTypes/usmgTypes";
import {ActivityType, ActivityTypeType, CategoryType} from "./serverTypes/activityTypes";
import {AnnouncementType} from "./serverTypes/announcementTypes";
import {AppointmentType, AvailabilityType} from "./serverTypes/appointmentTypes";
import {AdminUserType, RoleType} from "./serverTypes/adminTypes";
import {ArticleType} from "./serverTypes/articleTypes";
import {AvatarType} from "./serverTypes/avatarTypes";
import {
  BadgeCollectionType,
  BadgeCollectionTypeType,
  BadgeGroupType,
  BadgeType,
  StringLookupType,
} from './serverTypes';
import {
  CommentType,
  FavoriteType,
  FlagType,
  ForumDetailType,
  PostType,
  ThumbsupType,
  TopicType,
} from './serverTypes/forumTypes';
import { FormType } from './serverTypes/formTypes';
import {
  MessageResponseType,
  MessageThreadType,
  MessageType,
} from './serverTypes/messageTypes';
import {
  ParticipantType,
  StudyArmType,
  StudyType,
} from './serverTypes/studyTypes';
import { NoteType } from './serverTypes/noteTypes';
import { NotificationType } from './serverTypes/notificationTypes';
import { PaymentType } from './serverTypes/paymentTypes';
import { QnaPostType } from './serverTypes/qnaTypes';
import { GoalType } from './serverTypes/goalTypes';
import { TestkitType } from './serverTypes/testkitTypes';
import { UserSurveyType } from './index';

export type NormalizedType<T> = {
  [key: string]: T;
};

export const initialNormalizedState = {
  allIds: [],
  byId: {},
};

export const initialUserState: AdminUserType = {
  id: -1,
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  roles: [],
  mobile: '',
  type: '',
  pseudoParticipantIds: [],
  notificationSubscriptions: [],
};

export type NormalizedState<T> = {
  allIds: number[] | string[];
  byId: {
    [key: string]: T;
  };
};

export interface IApplicationState {
  dashboard: any;
  api: IApiState;
  auth: {
    user: Optional<AdminUserType>;
  };
  entities: {
    activities: NormalizedState<ActivityType>;
    availabilities: NormalizedState<AvailabilityType>;
    activityCategories: NormalizedState<CategoryType>;
    activityTypes: NormalizedState<ActivityTypeType>;
    admin: NormalizedState<AdminUserType>;
    adminRoles: NormalizedState<RoleType>;
    announcements: NormalizedState<AnnouncementType>;
    appointments: NormalizedState<AppointmentType>;
    articles: NormalizedState<ArticleType>;
    avatars: NormalizedState<AvatarType>;
    badgeCollections: NormalizedState<BadgeCollectionType>;
    badgeCollectionTypes: NormalizedState<BadgeCollectionTypeType>;
    badgeGroups: NormalizedState<BadgeGroupType>;
    badges: NormalizedState<BadgeType>;
    comments: NormalizedState<CommentType>;
    favorites: NormalizedState<FavoriteType>;
    flags: NormalizedState<FlagType>;
    forms: NormalizedState<FormType>;
    forums: NormalizedState<ForumDetailType>;
    goals: NormalizedState<GoalType>;
    stringLookups: NormalizedState<StringLookupType>;
    messageResponses: NormalizedState<MessageResponseType>;
    messages: NormalizedState<MessageType>;
    notes: NormalizedState<NoteType>;
    notifications: NormalizedState<NotificationType>;
    participantBadges: NormalizedState<BadgeType>;
    participants: NormalizedState<ParticipantType>;
    payments: NormalizedState<PaymentType>;
    posts: NormalizedState<PostType>;
    qnaPosts: NormalizedState<QnaPostType>;
    studies: NormalizedState<StudyType>;
    studyArms: NormalizedState<StudyArmType>;
    testkits: NormalizedState<TestkitType>;
    threads: NormalizedState<MessageThreadType>;
    thumbsups: NormalizedState<ThumbsupType>;
    topics: NormalizedState<TopicType>;
    uncategorizedPostIds: NormalizedState<PostType>;
    userSurveys: NormalizedState<UserSurveyType>;
  };
  oidc: OidcUserState;
  router: RouterState;
  ui: {
    forums: typeof initialForumUIState;
    study: typeof initialStudyUIState;
    sidebar: ISideBarState;
    articles: typeof initialArticleUIState;
    qnas: IQnaUIState;
    messages: IMessageUIState;
    reports: {
      analytics: typeof initialAnalyticsUIState;
    };
    goals: GetGoalsArguments;
  };
}

export default IApplicationState;
