import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import {
  all, call, fork, put, takeEvery
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { getFavoritesAsync } from '../favorites/favorites.types';
import { getFlagsAsync } from '../flags/flags.types';
import { entitySchema } from '../schema';
import { getThumbsupsAsync } from '../thumbsups/thumbsups.types';
import {CreateUpdateCommentParam, createJsonParam, SocialJsonParamType} from '../user/socialUtil';
import {getParticipantCommentsAsync, getCommentsAsync, createCommentAsync, updateCommentAsync} from './comments.types';
import {CommentType} from "../../types/serverTypes/forumTypes";
import {NormalizerResult} from "../../types";

const getParticipantComments = (participantId: number) => {
  return axios({
    method: 'get',
    url: `/a/social/commentsByParticipant/${participantId}`
  });
};

const getComments = (jsonComments: SocialJsonParamType[]) => {
  const param = JSON.stringify(jsonComments);
  return axios({
    method: 'get',
    url: `/a/social/comments?commentsJson=${encodeURIComponent(param)}`
  });
};

const createComment = (param: CreateUpdateCommentParam) => {
  return axios({
      method: 'put',
      url: `/a/social/comment/${param.type}/${param.typeId}?participantId=${param.participantId}`,
      data: {
        body: param.comment,
        parentCommentId: param.parentCommentId,
        createDate: param.createDate
      }
  });
}

const updateComment = (comment: CommentType) => {
  return axios({
    method: 'put',
    url: `/a/social/updateComment/${comment.id}`,
    data: comment
  });
}

function* getParticipantCommentsHandler(action: ReturnType<typeof getParticipantCommentsAsync.request>): Generator {

  try {
    const participantId: number = action.payload;
    const response: AxiosResponse = (yield call(getParticipantComments, participantId)) as AxiosResponse;
    const participantComments = response.data ? response.data : [];
    const { entities } = normalize(participantComments, entitySchema.comments) as NormalizerResult;
    const { comments } = entities;

    yield put(getParticipantCommentsAsync.success(comments));
  } catch (error) {
    yield put(getParticipantCommentsAsync.failure(error));
  }
}

function* getParticipantCommentsWatcher() {
  yield takeEvery(getType(getParticipantCommentsAsync.request), getParticipantCommentsHandler);
}

function* getCommentsHandler(action: ReturnType<typeof getCommentsAsync.request>): Generator {

  try {
    const commentsJsonParam: SocialJsonParamType[] = action.payload;
    const response: AxiosResponse = (yield call(getComments, commentsJsonParam)) as AxiosResponse;
    const { entities, result } = normalize(response.data, entitySchema.comments) as NormalizerResult;
    const { comments } = entities;

    if (result?.length) {
      const jsonParam = [createJsonParam('comment', result)];
      yield put(getFlagsAsync.request(jsonParam));
      yield put(getThumbsupsAsync.request(jsonParam));
      yield put(getFavoritesAsync.request(jsonParam));
    }
    yield put(getCommentsAsync.success(comments));
  } catch (error) {
    yield put(getCommentsAsync.failure(error));
  }
}

function* createCommentHandler(action: ReturnType<typeof createCommentAsync.request>): Generator {

  try {
    const createCommentParam: CreateUpdateCommentParam = action.payload;
    const response: AxiosResponse = (yield call(createComment, createCommentParam)) as AxiosResponse;
    const { entities, result } = normalize([response.data], entitySchema.comments) as NormalizerResult;
    const { comments } = entities;

    if (result?.length) {
      const jsonParam = [createJsonParam('comment', result)];
      yield put(getFlagsAsync.request(jsonParam));
      yield put(getThumbsupsAsync.request(jsonParam));
      yield put(getFavoritesAsync.request(jsonParam));
    }
    yield put(getCommentsAsync.success(comments));
  } catch (error) {
    yield put(getCommentsAsync.failure(error));
  }
}


function* updateCommentHandler(action: ReturnType<typeof updateCommentAsync.request>): Generator {

  try {
    const updateCommentParam: CommentType = action.payload;
    const response: AxiosResponse = (yield call(updateComment, updateCommentParam)) as AxiosResponse;
    const { entities, result } = normalize([response.data], entitySchema.comments) as NormalizerResult;
    const { comments } = entities;

    if (result?.length) {
      const jsonParam = [createJsonParam('comment', result)];
      yield put(getFlagsAsync.request(jsonParam));
      yield put(getThumbsupsAsync.request(jsonParam));
      yield put(getFavoritesAsync.request(jsonParam));
    }
    yield put(getCommentsAsync.success(comments));
  } catch (error) {
    yield put(getCommentsAsync.failure(error));
  }
}

function* getCommentsWatcher() {
  yield takeEvery(getType(getCommentsAsync.request), getCommentsHandler);
}

function* createCommentWatcher() {
  yield takeEvery(getType(createCommentAsync.request), createCommentHandler);
}

function* updateCommentWatcher() {
  yield takeEvery(getType(updateCommentAsync.request), updateCommentHandler);
}

export default function* commentsSaga() {
  yield all([
    fork(getParticipantCommentsWatcher),
    fork(getCommentsWatcher),
    fork(createCommentWatcher),
    fork(updateCommentWatcher)
  ]);
}
