import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as selectors from '../../../redux/selectors';
import IApplicationState from '../../../types/state.types';
import ReactTelInput from 'react-international-telephone-input/lib/withStyles';
import { StudyArmType } from '../../../types/serverTypes/studyTypes';
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  DatePicker,
  Input,
  message,
  Modal,
  Select,
} from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import {
  CreateUserArguments,
  createUserAsync,
} from '../../../redux/user/user.types';
import axios from '../../../redux/api';
import { IApiRequestStatus } from '../../../types/api.types';
import { clearStatus } from '../../../redux/api/api.types';
import { getType } from 'typesafe-actions';
import './userCreation.scss';
import ActivityTooltip from '../../../components/activity/ActivityTooltip';
import HMPTextArea from '../../../components/textarea/HMPTextArea';
import { SelectValue } from 'antd/lib/select';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RoleType } from '../../../types/serverTypes/adminTypes';
import { NewUserType } from '../../../types/serverTypes/usmgTypes';
import TimezoneSelect from 'react-timezone-select';
import { TimeZoneColorStyles } from '../../../styles/styles';
import { useForm } from 'antd/lib/form/Form';
const flagsImagePath = require('../../../assets/images/flags.png');

const { Item } = Form;
const { Option } = Select;
const { Panel } = Collapse;
const { Password } = Input;
const { confirm } = Modal;

interface StateProps {
  adminRoles: Optional<RoleType[]>;
  studyId: number;
  arms: Optional<StudyArmType[]>;
  createUserStatus: IApiRequestStatus;
  showReferrals: boolean;
  showAccessCodeEnrolment: boolean;
  showDOB: boolean;
  showGenderIdentity: boolean;
}

interface DispatchProps {
  createUser: typeof createUserAsync.request;
  clearStatus: typeof clearStatus;
}

interface ComponentProps extends StateProps, DispatchProps {}

interface ComponentState {
  type: UserType;
  usernameStatus: ValidateStatus;
  sendEmail: boolean;
  createPseudos: boolean;
}

enum UserType {
  PARTICIPANT = 'PARTICIPANT',
  PSEUDO = 'PSEUDO PARTICIPANT',
  ADMIN = 'ADMIN',
}

declare type ValidateStatus =
  | ''
  | 'success'
  | 'warning'
  | 'error'
  | 'validating'
  | undefined;

const itemStyle = {
  width: 'auto',
  marginBottom: '10px',
  marginTop: '10px',
};

const UserCreationPage = (props: ComponentProps) => {
  const [state, setState] = useState<ComponentState>({
    type: UserType.PARTICIPANT as UserType,
    usernameStatus: undefined,
    sendEmail: false,
    createPseudos: true,
  });
  const [selectedTimezone, setSelectedTimezone] = useState<any>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const setTimeZone = (timeZoneValue) => {
    setSelectedTimezone(timeZoneValue.value);
  };
  const [form] = useForm<NewUserType>();

  const availableUserTypes: string[] = [
    UserType.ADMIN,
    UserType.PARTICIPANT,
    UserType.PSEUDO,
  ];

  useEffect(() => {
    const { createUserStatus, clearStatus } = props;
    if (createUserStatus.isError) {
      message.error('Error creating user.');
      clearStatus(getType(createUserAsync.failure));
    } else if (createUserStatus.isSuccess) {
      message.success('User created successfully.');
      clearStatus(getType(createUserAsync.success));
    }
  }, [props.createUserStatus.isError, props.createUserStatus.isSuccess]);

  const onArmChange = (value: SelectValue) => {
    const { arms } = props;
    const selectedArm = arms?.find((a) => a.id === value);
    if (!selectedArm) {
      throw Error('No selected arm during arm change handler');
    }

    setState({
      ...state,
      sendEmail: false,
      createPseudos: true,
    });
  };
  const onCreatePseudosChange = (e: CheckboxChangeEvent) => {
    setState({
      ...state,
      createPseudos: e.target.checked,
    });
  };
  const onSendEmailChange = (e: CheckboxChangeEvent) => {
    setState({
      ...state,
      sendEmail: e.target.checked,
    });
  };
  const emailValidator = (rule, value: string) => {
    // check presence of @. (https://jira.atlassian.com/browse/CWD-2065)
    if (value && value.indexOf('@') > 0) {
      return Promise.resolve();
    }
    return Promise.reject('Please enter a valid email address.');
  };
  const onUserTypeChange = (value: UserType) => {
    setState({
      ...state,
      type: value,
      sendEmail: false,
      createPseudos: true,
    });
  };
  const usernameValidator = async (rule, value: string) => {
    const { studyId } = props;
    const { type } = state;
    if (type === UserType.ADMIN) {
      if (!value || value.length === 0) {
        return Promise.reject('Username is a required field.');
      }
      setState({
        ...state,
        usernameStatus: 'validating',
      });
      const isUsernameAvailable = await axios({
        method: 'get',
        url: `/a/usmg/availability/username/${value}?isAdminUser=true&studyId=${studyId}`,
      });
      if (!isUsernameAvailable.data) {
        setState({
          ...state,
          usernameStatus: 'error',
        });
        return Promise.reject('This username is already in-use.');
      }
      setState({
        ...state,
        usernameStatus: 'success',
      });
    }
    return Promise.resolve();
  };
  const passwordValidator = (rule, value: string) => {
    if (state.sendEmail) return Promise.resolve();

    if (value && value.length < 8) {
      return Promise.reject(
        "Please enter a password that's at least 8 characters long."
      );
    }
    return Promise.resolve();
  };
  const confirmPasswordValidator = (rule, value: string) => {
    if (state.sendEmail) return Promise.resolve();

    if (value !== form.getFieldValue('password')) {
      return Promise.reject('Passwords do not match.');
    }
    return Promise.resolve();
  };
  const handleCreateUser = (userFields) => {
    const { createUser, studyId, showAccessCodeEnrolment } = props;
    const { type, sendEmail, createPseudos } = state;

    const sendEnrollmentEmail = sendEmail && showAccessCodeEnrolment;
    if (userFields) {
      const {
        roleIds = [],
        username,
        password,
        studyArmId,
        email,
        firstName,
        lastName,
        dateOfBirth,
        genderIdentity,
        address1,
        mobile,
        referralCode,
        externalId,
      } = userFields;
      const user: NewUserType = {
        type,
        roleIds,
        username,
        password,
        studyId,
        studyArmId,
        email,
        firstName,
        lastName,
        dateOfBirth: dateOfBirth?.toISOString(),
        genderIdentity,
        address1,
        mobile,
        referralCode,
        hivStatus: -1,
        externalId,
        timeZone: selectedTimezone,
      };
      if (type === UserType.PARTICIPANT && sendEnrollmentEmail) {
        // If enrolling via in-app sign up flow, we need to sanitize the following fields
        user.username = undefined;
        user.password = undefined;
        user.roleIds = undefined;
      }
      if (type === UserType.ADMIN) {
        // When creating an admin user, we don't need to specify a studyArmId
        user.studyArmId = -1;
      }
      createUser({
        user,
        sendEmail: sendEnrollmentEmail,
        createPseudos: createPseudos,
      });
      resetState();
    }
  };

  const resetState = () => {
    setState({
      ...state,
      type: UserType.PARTICIPANT,
      usernameStatus: undefined,
    });
    if (form) {
      form.resetFields();
    }
  };

  const handleReset = () => {
    confirm({
      title: 'Are you sure you want to reset this form?',
      content: 'You will lose all changes.',
      okText: 'Reset',
      okType: 'danger',
      onOk: () => {
        resetState();
      },
      onCancel() {},
    });
  };

  const handleSave = () => {
    if (form) {
      form.validateFields().then((fields) => {
        confirm({
          title: 'Are you sure you want to create this user?',
          content: '',
          okText: 'Confirm',
          onOk: () => {
            handleCreateUser(fields);
          },
          onCancel() {},
        });
      });
    }
  };

  const capitalize = (s: string): string =>
    s
      ? s
          .split(' ')
          .map((word) => word[0].toUpperCase() + word.substring(1))
          .join(' ')
      : s;

  const {
    arms,
    adminRoles,
    showReferrals,
    showAccessCodeEnrolment,
    showDOB,
    showGenderIdentity,
  } = props;
  const { type, usernameStatus, sendEmail } = state;

  return (
    <div className="form-container">
      <Card
        className="user-creation-form"
        title={
          <div className="announcement-title">
            <h1>User Creation</h1>
          </div>
        }
      >
        <Form
          form={form}
          key="user-creation-form"
          layout="vertical"
          colon={false}
        >
          <Item
            key="type-select"
            name="type"
            label="Select the type of user:"
            style={itemStyle}
            rules={[{ required: true }]}
            initialValue={type}
          >
            <Select onChange={onUserTypeChange}>
              {availableUserTypes.map((option) => (
                <Option key={`option-${option}`} value={option}>
                  {capitalize(option.toLowerCase())}
                </Option>
              ))}
            </Select>
          </Item>
          {type === 'ADMIN' && (
            <Checkbox
              onChange={onCreatePseudosChange}
              style={{ color: '#000000d9' }}
            >
              Create Pseudo Users?
            </Checkbox>
          )}
          <Item
            key="role-select"
            name="roleIds"
            label="Role:"
            hidden={type !== UserType.ADMIN}
            style={itemStyle}
            rules={[
              {
                required: type === UserType.ADMIN,
                message: 'Must select at least one role.',
              },
            ]}
          >
            <Select mode="multiple" allowClear>
              {adminRoles?.map((role) => (
                <Option key={`role-${role.id}`} value={role.id}>
                  {role.role}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            key="arm-select"
            name="studyArmId"
            hidden={type === UserType.ADMIN}
            label="Select the study arm:"
            style={itemStyle}
            required={type !== UserType.ADMIN}
          >
            <Select onChange={onArmChange}>
              {arms?.map((arm) => (
                <Option key={`arm-${arm.id}`} value={arm.id}>
                  {arm.name}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            key="send-email"
            name="sendEmail"
            hidden={type === UserType.ADMIN || !showAccessCodeEnrolment}
            required={type !== UserType.ADMIN}
            valuePropName="checked"
          >
            <Checkbox
              onChange={onSendEmailChange}
              style={{ color: '#000000d9' }}
            >
              Would you like to send the enrollment email to the user? (uncheck
              to enroll now)
            </Checkbox>
          </Item>
          <Item
            key="email-input"
            name="email"
            label="Email:"
            style={itemStyle}
            rules={[{ required: true, validator: emailValidator }]}
            validateTrigger={['onSubmit', 'onBlur']}
          >
            <Input placeholder="email@email.com" />
          </Item>
          <Item
            key="username-input"
            name="username"
            hidden={sendEmail}
            label="Username:"
            validateStatus={usernameStatus}
            style={itemStyle}
            rules={[
              {
                required: type === UserType.ADMIN || !sendEmail,
                validator: usernameValidator,
              },
            ]}
            validateTrigger={['onBlur', 'onSubmit']}
          >
            <Input placeholder="Username" autoComplete="off" />
          </Item>
          <Item
            key="password-input"
            name="password"
            hidden={sendEmail}
            label="Password:"
            style={itemStyle}
            rules={[
              {
                required: type === UserType.ADMIN || !sendEmail,
                validator: passwordValidator,
              },
            ]}
            validateTrigger={['onBlur', 'onChange']}
          >
            <Password
              visibilityToggle
              placeholder="Password"
              autoComplete="new-password"
            />
          </Item>
          <Item
            key="confirm-password-input"
            name="confirmPassword"
            hidden={sendEmail}
            label="Confirm Password:"
            style={itemStyle}
            rules={[
              {
                required: type === UserType.ADMIN || !sendEmail,
                validator: confirmPasswordValidator,
              },
            ]}
            validateTrigger={['onBlur', 'onChange']}
          >
            <Password visibilityToggle placeholder="Confirm Password" />
          </Item>

          <Item
            key="first-name-input"
            name="firstName"
            label="First Name:"
            rules={[{ required: true, message: 'First name is required.' }]}
            style={itemStyle}
          >
            <Input placeholder="First name" />
          </Item>
          <Item
            key="last-name-input"
            name="lastName"
            label="Last Name:"
            rules={[{ required: true, message: 'Last name is required.' }]}
            style={itemStyle}
          >
            <Input placeholder="Last name" />
          </Item>
          <Item
            key="mobile-input"
            name="mobile"
            label="Mobile Phone:"
            style={itemStyle}
            rules={[{ required: true, message: 'Mobile phone is required.' }]}
          >
            <ReactTelInput flagsImagePath={flagsImagePath} />
          </Item>
          <Item
            key="external-id-input"
            name="externalId"
            label="External Id:"
            style={itemStyle}
          >
            <Input placeholder="" />
          </Item>
          <Item label="Time Zone" style={itemStyle}>
            <TimezoneSelect
              value={selectedTimezone}
              onChange={setTimeZone}
              styles={TimeZoneColorStyles}
              labelStyle="abbrev"
            />
          </Item>
          <Collapse>
            <Panel header="Optional" key="0">
              <Item
                key="dob-input"
                name="dateOfBirth"
                hidden={!showDOB}
                label="Date of Birth:"
                style={itemStyle}
              >
                <DatePicker />
              </Item>
              <Item
                key="gender-identity-input"
                name="genderIdentity"
                hidden={!showGenderIdentity}
                label="Gender Identity:"
                style={itemStyle}
              >
                <Input placeholder="Gender Identity" />
              </Item>
              <Item key="address-input" name="address1" label="Address">
                <HMPTextArea placeholder="Address" />
              </Item>
              <Item
                key="referral-input"
                name="referralCode"
                hidden={showReferrals}
                label={
                  <div>
                    <span>Referral Code: </span>
                    <ActivityTooltip text="Referral code of the participant who is referring the user you are creating" />
                  </div>
                }
                style={itemStyle}
              >
                <Input placeholder="Referral Code" />
              </Item>
            </Panel>
          </Collapse>
          <div className="actions">
            <Button className="action" type="default" onClick={handleReset}>
              Reset
            </Button>
            <Button className="action" type="primary" onClick={handleSave}>
              Save
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    adminRoles: selectors.getAdminRoles(state),
    studyId: selectors.getRequestedStudyId(state),
    arms: selectors.getRequestedStudyStudyArms(state),
    createUserStatus: selectors.createUserStatus(state),
    showReferrals: selectors.getReferralsFeatureEnabled(state),
    showAccessCodeEnrolment: selectors.getAccessCodeEnrollmentEnabled(state),
    showDOB: selectors.getShowDOBEnrollmentEnabled(state),
    showGenderIdentity: selectors.getShowGenderIdentityEnrollmentEnabled(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    createUser: (args: CreateUserArguments) =>
      dispatch(createUserAsync.request(args)),
    clearStatus: (type: string) => dispatch(clearStatus(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCreationPage);
