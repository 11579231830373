import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import moment from 'moment';
import {
  Steps,
  Modal,
  Button,
  Row,
  Col,
  message,
  Switch,
  Tooltip,
  Spin,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
// import { OptionType } from 'antd/es/select';
import {
  upsertActivityAsync,
  publishActivityAsync,
} from '../../redux/activities/activities.types';
import ActivityBackgroundForm from './forms/ActivityBackgroundForm';
import ActivityDetailsForm from './forms/ActivityDetailsForm';
import ActivityQuestionForm from './forms/ActivityQuestionForm';
import ActivityReviewForm from './forms/ActivityReviewForm';
import IApplicationState from '../../types/state.types';
import { IApiRequestStatus } from '../../types/api.types';
import * as selectors from '../../redux/selectors';
import {
  ActivityType,
  FeedbackType,
  QuestionType,
  OptionType,
  TreeOptionType,
  TreeQuestionType,
} from '../../types/serverTypes/activityTypes';
import { cleanstring, parseJSON } from '../../service/util';

const { Step } = Steps;
const { confirm } = Modal;

interface StateProps {
  getActivityStatus: IApiRequestStatus;
}

interface DispatchProps {
  upsertActivity: typeof upsertActivityAsync.request;
  publishActivity: typeof publishActivityAsync.request;
}

interface ComponentProps extends DispatchProps, StateProps {
  activity: ActivityType;
  closeHandler: () => {};
  visible: boolean;
  creative: boolean;
  copied: boolean;
}

interface ComponentState {
  activity: ActivityType;
  current: number;
  statuses: ActivityFormStatus[];
  visible: boolean;
  backgroundType: BackgroundType;
  previewImage: string;
  image?: File;
  form?: FormInstance;
  creative: boolean;
  editable: boolean;
  loading: boolean;
}

export enum ActivityFormStatus {
  WAIT = 'wait',
  PROCESS = 'process',
  FINISH = 'finish',
  ERROR = 'error',
}

export declare type BackgroundType = 'image' | 'gradient';

export enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener,
  question,
}

class ActivityFormContainer extends Component<ComponentProps, ComponentState> {
  private defaultState: ComponentState = {
    activity: {
      title: '',
      type: '',
      typeId: -1,
      category: '',
      description: '',
      prompt: '',
      intro: '',
      randomizeQuestions: false,
      isHealthAssessment: false,
      background: {
        gradient: {},
        filename: '',
      },
      publishDate: '',
      feedback: [] as FeedbackType[],
      options: [] as OptionType[],
      questions: [] as QuestionType[],
      tree: {
        questions: {},
        options: {},
      },
      upload: undefined,
    } as ActivityType,
    current: 0,
    statuses: [
      ActivityFormStatus.PROCESS,
      ActivityFormStatus.WAIT,
      ActivityFormStatus.WAIT,
      ActivityFormStatus.WAIT,
    ],
    visible: true,
    backgroundType: 'gradient',
    previewImage: '',
    image: undefined,
    creative: false,
    editable: false,
    loading: false,
  };

  constructor(props: ComponentProps) {
    super(props);
    const { creative, activity, getActivityStatus } = this.props;
    const updatedState: ComponentState = _.cloneDeep({
      ...this.defaultState,
      creative,
    });
    if (!creative && activity) {
      const { categoryId } = activity;
      const mergedActivity = _.merge(updatedState.activity, activity);
      if (categoryId) {
        mergedActivity.category = categoryId;
      }
      this.state = {
        ...updatedState,
        activity: mergedActivity,
        loading: getActivityStatus.isLoading,
      };
    } else {
      this.state = { ...updatedState };
    }
  }

  componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState) {
    if (prevState.editable !== this.state.editable && !this.props.creative) {
      this.setStatus(ActivityFormStatus.FINISH);
    }
    if (
      prevProps.getActivityStatus.isLoading &&
      !this.props.getActivityStatus.isLoading
    ) {
      const { creative, activity, getActivityStatus } = this.props;
      if (!creative && activity) {
        const { categoryId, typeId, background, tree } = activity;
        const mergedActivity = _.merge(this.defaultState.activity, activity);
        const updatedState: ComponentState = _.cloneDeep(this.defaultState);
        if (categoryId) {
          mergedActivity.category = categoryId;
        }
        if (background) {
          if (
            background.gradient &&
            background.gradient.start &&
            background.gradient.start.length > 0
          ) {
            updatedState.backgroundType = 'gradient';
          } else if (background.filename && background.filename.length > 0) {
            updatedState.backgroundType = 'image';
          }
        }
        if (typeId === Type.cyoa && tree) {
          const { questions, options } = tree;
          const { firstId } = mergedActivity;
          // activity.tree.questions and activity.tree.options sent from api are JSON strings
          mergedActivity.firstId = firstId.toString();
          const iterableQuestions: [string, TreeQuestionType][] = [];
          const iterableOptions: [string, TreeOptionType][] = [];
          const intakeQuestions = parseJSON(questions as unknown as string);
          const intakeOptions = parseJSON(options as unknown as string);
          function mapToTreeQuestion(id: number): [string, TreeQuestionType] {
            const question = intakeQuestions[id];
            return [
              id.toString(),
              {
                ...question,
                id: id.toString(),
                optionIds: question.optionIds.map((id: number) =>
                  id.toString()
                ),
              },
            ];
          }
          function mapToTreeOption(id: number): [string, TreeOptionType] {
            const option = intakeOptions[id];
            return [
              id.toString(),
              {
                ...option,
                id: id.toString(),
                questionId: option.questionId?.toString(),
              },
            ];
          }
          function buildQuestions(id?: number) {
            if (id) {
              iterableQuestions.push(mapToTreeQuestion(id));
              intakeQuestions[id].optionIds.forEach((optionId: number) => {
                buildOptions(optionId);
              });
            }
          }
          function buildOptions(id: number) {
            iterableOptions.push(mapToTreeOption(id));
            buildQuestions(intakeOptions[id].questionId);
          }
          buildQuestions(firstId);
          mergedActivity.tree = {
            questions: new Map<string, TreeQuestionType>(iterableQuestions),
            options: new Map<string, TreeOptionType>(iterableOptions),
          };
        }
        this.setState({
          ...updatedState,
          activity: mergedActivity,
          loading: getActivityStatus.isLoading,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  }

  handleSave = (e: any) => {
    const { form } = this.state;
    if (form) {
      form
        .validateFields()
        .then(() => {
          this.setStatus(ActivityFormStatus.FINISH);
          confirm({
            title: 'Are you sure you want to create this activity?',
            content: '',
            okText: 'Confirm',
            onOk: () => {
              this.handleSaveActivity();
            },
            onCancel() {},
          });
        })
        .catch((err) => {
          this.setStatus(ActivityFormStatus.ERROR);
        });
    }
  };

  handlePublish = (e: any) => {
    const { form } = this.state;
    if (form) {
      form
        .validateFields()
        .then(() => {
          this.setStatus(ActivityFormStatus.FINISH);
          confirm({
            title: 'Are you sure you want to create this activity?',
            content: '',
            okText: 'Confirm',
            onOk: () => {
              this.handleSaveAndPublishActivity();
            },
            onCancel() {},
          });
        })
        .catch((err) => {
          this.setStatus(ActivityFormStatus.ERROR);
        });
    }
  };

  handleSaveActivity = async () => {
    const { closeHandler, upsertActivity } = this.props;
    const { activity, backgroundType, image } = this.state;
    // validate and save
    if (activity) {
      activity.intro = cleanstring(activity.intro);
      activity.description = cleanstring(activity.description);
      if (activity.background) {
        if (backgroundType === 'image') {
          activity.background.gradient = undefined;
          if (image) {
            activity.upload = image;
          }
        } else if (backgroundType === 'gradient') {
          activity.background.filename = undefined;
        }
      }
      if (activity.typeId === Type.cyoa && activity.tree) {
        /**
         * After saving, request gets sent over axios, which doesn't support
         * sending Maps over the request.body, so we stringify the Map entries
         * to parse once we receive them on the server
         */
        activity.tree = {
          questions: JSON.stringify([...activity.tree.questions]),
          options: JSON.stringify([...activity.tree.options]),
        };
      }
      upsertActivity(activity);
      message.success('Activity created successfully!');
    }
    this.resetState();
    closeHandler();
  };

  handleSaveAndPublishActivity = async () => {
    const { closeHandler, upsertActivity } = this.props;
    const { activity, backgroundType, image } = this.state;
    // validate and save
    if (activity) {
      activity.intro = cleanstring(activity.intro);
      activity.description = cleanstring(activity.description);
      if (activity.background) {
        if (backgroundType === 'image') {
          activity.background.gradient = undefined;
          if (image) {
            activity.upload = image;
          }
        } else if (backgroundType === 'gradient') {
          activity.background.filename = undefined;
        }
      }
      if (!activity.publishDate) {
        activity.publishDate = moment().format('YYYY-MM-DD HH:mm:ss');
      }
      if (activity.typeId === Type.cyoa && activity.tree) {
        /**
         * After saving, request gets sent over axios, which doesn't support
         * sending Maps over the request.body, so we stringify the Map entries
         * to parse once we receive them on the server
         */
        activity.tree = {
          questions: JSON.stringify([...activity.tree.questions]),
          options: JSON.stringify([...activity.tree.options]),
        };
      }
      upsertActivity(activity);
      message.success('Activity created successfully!');
    }
    this.resetState();
    closeHandler();
  };

  handleCancel = (e: any) => {
    const { closeHandler, creative } = this.props;
    const { editable } = this.state;
    if (creative || editable) {
      confirm({
        title: 'Are you sure you want to leave this activity?',
        content: 'You will lose all changes.',
        okText: 'Leave',
        okType: 'danger',
        onOk: () => {
          this.resetState();
          closeHandler();
        },
        onCancel() {},
      });
    } else {
      this.resetState();
      closeHandler();
    }
  };

  handleClose = (e: any) => {
    const { closeHandler } = this.props;
    closeHandler();
    this.resetState();
  };

  next = () => {
    const { current, form } = this.state;
    if (form) {
      form.validateFields().then(() => {
        this.setState({ current: current + 1 });
      });
    }
  };

  previous = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  setStatus = (status: ActivityFormStatus) => {
    const { current, statuses } = this.state;
    const updated = [...statuses];
    updated[current] = status;
    this.setState({
      statuses: updated,
    });
  };

  setBackgroundType = (type: BackgroundType) => {
    const { previewImage, activity } = this.state;
    if (type === 'image' && previewImage.length === 0) {
      this.setStatus(ActivityFormStatus.PROCESS);
    } else if (
      type === 'gradient' &&
      activity.background &&
      !activity.background.gradient
    ) {
      this.setStatus(ActivityFormStatus.PROCESS);
    }
    this.setState({
      backgroundType: type,
    });
  };

  setPreviewImage = (base64: string | undefined) => {
    if (base64) {
      this.setState(
        {
          previewImage: base64,
        },
        () => {
          this.setStatus(ActivityFormStatus.FINISH);
        }
      );
    }
  };

  uploadFile = (file: File): Promise<boolean> => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        if (reader.result) {
          this.setPreviewImage(reader.result as string);
          this.setStatus(ActivityFormStatus.FINISH);
          this.setState({
            image: file,
          });
          resolve(true);
        }
        resolve(false);
      };
    });
  };

  removeFile = () => {
    this.setState({
      previewImage: '',
      image: undefined,
    });
  };

  resetState = () => {
    const resetState = _.cloneDeep(this.defaultState);
    this.setState(resetState);
  };

  setActivity = (props: any) => {
    if (props) {
      const { activity } = this.state;
      _.assign(activity, props);
      this.setState({
        activity: _.cloneDeep(activity),
      });
    }
  };

  setActiveForm = (form: any) => {
    this.setState({ form });
  };

  onEditChange = (checked: boolean) => {
    const { activity } = this.props;
    if (
      checked &&
      activity?.id &&
      moment(activity.publishDate).isSameOrBefore()
    ) {
      message.error(
        'You cannot edit a published activity, if you need to update or fix a small detail or typo contact the OCS team.'
      );
    } else {
      this.setState({ editable: checked });
    }
  };

  renderTitle = () => {
    const { creative, editable } = this.state;
    let text;
    if (creative) {
      text = 'Create New Activity';
    } else if (editable) {
      text = 'Activity: Edit Mode';
    } else {
      text = 'Activity: View Mode';
    }
    return (
      <div className="activity-form-title">
        <span className="activity-title-text">{text}</span>
        {!creative && (
          <Tooltip title="Click the switch to toggle view/edit mode.">
            <Switch checked={editable} onChange={this.onEditChange} />
          </Tooltip>
        )}
      </div>
    );
  };

  renderSteps = () => {
    const {
      backgroundType,
      activity,
      previewImage,
      image,
      creative,
      editable,
    } = this.state;
    const { background } = activity;
    return [
      {
        title: 'Background',
        content: (
          <ActivityBackgroundForm
            creative={creative}
            editable={editable}
            background={background}
            backgroundType={backgroundType}
            setActivity={this.setActivity}
            uploadFile={this.uploadFile}
            removeFile={this.removeFile}
            setBackgroundType={this.setBackgroundType}
            image={image}
            previewImage={previewImage}
            setActiveForm={this.setActiveForm}
          />
        ),
      },
      {
        title: 'Details',
        content: (
          <ActivityDetailsForm
            creative={creative}
            editable={editable}
            activity={activity}
            setStatus={this.setStatus}
            setActivity={this.setActivity}
            setActiveForm={this.setActiveForm}
          />
        ),
      },
      {
        title: 'Questions',
        content: (
          <ActivityQuestionForm
            creative={creative}
            editable={editable}
            activity={activity}
            setActivity={this.setActivity}
            setActiveForm={this.setActiveForm}
          />
        ),
      },
      {
        title: 'Save',
        content: (
          <ActivityReviewForm
            creative={creative}
            editable={editable}
            activity={activity}
            setActivity={this.setActivity}
            setActiveForm={this.setActiveForm}
          />
        ),
      },
    ];
  };

  renderActions = (length: number) => {
    const { current, editable, creative } = this.state;
    const footerActions: any[] = [];
    if (current < length - 1) {
      footerActions.push(
        <Button key="next" type="primary" onClick={this.next}>
          Next
        </Button>
      );
    }
    if (current === length - 1) {
      if (creative || editable) {
        footerActions.push(
          <Button key="save" type="primary" onClick={this.handleSave}>
            Save
          </Button>
        );
        footerActions.push(
          <Button
            key="saveAndPublish"
            type="primary"
            onClick={this.handlePublish}
          >
            Save & Publish
          </Button>
        );
      } else {
        footerActions.push(
          <Button key="close" type="primary" onClick={this.handleClose}>
            Close
          </Button>
        );
      }
    }
    if (current > 0) {
      footerActions.push(
        <Button key="previous" onClick={this.previous}>
          Previous
        </Button>
      );
    }
    return footerActions;
  };

  render() {
    const { current, statuses, loading } = this.state;
    const { visible, activity } = this.props;
    const steps = this.renderSteps();
    const footerActions = this.renderActions(steps.length);
    return (
      <Modal
        key={activity ? activity.id : 'activity-modal'}
        title={this.renderTitle()}
        visible={visible}
        onCancel={this.handleCancel}
        destroyOnClose
        footer={footerActions}
        width="60%"
        style={{ minWidth: '800px' }}
      >
        {!loading ? (
          <div className="activity-form-container">
            <Steps current={current} status={statuses[current]}>
              {steps.map((s) => (
                <Step key={s.title} title={s.title} />
              ))}
            </Steps>
            <div className="activity-form-content">
              <Row>
                <Col span={24}>{steps[current].content}</Col>
              </Row>
            </div>
          </div>
        ) : (
          <Spin />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    getActivityStatus: selectors.getActivityStatus(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    upsertActivity: (activity: ActivityType) =>
      dispatch(upsertActivityAsync.request(activity)),
    publishActivity: (id: number) => dispatch(publishActivityAsync.request(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityFormContainer);
