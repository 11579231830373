import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import * as _ from 'lodash';
import * as qs from 'querystring';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { entitySchema } from '../schema';
import {
  getStringLookupsAsync,
  getStringLookupAsync,
  updateStringLookupAsync,
  GetStringLookupArguments,
  UpdateStringLookupArguments,
} from './stringLookups.types';
import { NormalizerResult } from '../../types';

const getStringLookups = (params: GetStringLookupArguments) => {
  return axios({
    method: 'get',
    url: `/a/lookup/all`,
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

const getStringLookup = (id: number) => {
  return axios({
    method: 'get',
    url: `/a/lookup/${id}/view`,
  });
};

const updateStringLookup = (data: UpdateStringLookupArguments) => {
  return axios({
    method: 'put',
    url: `/a/lookup/${data.id}/update`,
    data,
  });
};

function* getStringLookupsHandler(
  action: ReturnType<typeof getStringLookupsAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      getStringLookups,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      response.data,
      entitySchema.stringLookups
    ) as NormalizerResult;
    const { stringLookups } = entities;
    yield put(getStringLookupsAsync.success(stringLookups));
  } catch (error) {
    yield put(getStringLookupsAsync.failure(error as Error));
  }
}

function* getStringLookupHandler(
  action: ReturnType<typeof getStringLookupAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      getStringLookup,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.stringLookups
    ) as NormalizerResult;
    const { stringLookups } = entities;
    yield put(getStringLookupAsync.success(stringLookups));
  } catch (error) {
    yield put(getStringLookupAsync.failure(error as Error));
  }
}

function* updateStringLookupHandler(
  action: ReturnType<typeof updateStringLookupAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      updateStringLookup,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.stringLookups
    ) as NormalizerResult;
    const { stringLookups } = entities;
    yield put(updateStringLookupAsync.success(stringLookups));
  } catch (error) {
    yield put(updateStringLookupAsync.failure(error as Error));
  }
}

function* getStringLookupsWatcher() {
  yield takeLatest(
    getType(getStringLookupsAsync.request),
    getStringLookupsHandler
  );
}

function* getStringLookupWatcher() {
  yield takeLatest(
    getType(getStringLookupAsync.request),
    getStringLookupHandler
  );
}

function* updateStringLookupWatcher() {
  yield takeLatest(
    getType(updateStringLookupAsync.request),
    updateStringLookupHandler
  );
}

export default function* stringLookupsSaga() {
  yield all([
    fork(getStringLookupsWatcher),
    fork(getStringLookupWatcher),
    fork(updateStringLookupWatcher),
  ]);
}
