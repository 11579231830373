import React, { Component } from 'react';
import { Input, Form, Button } from 'antd';
import {
  CloseOutlined,
  PlusOutlined
} from '@ant-design/icons';
import './treeNode.scss';
import { TreeQuestionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  disabled: boolean;
  question: TreeQuestionType;
  updateQuestion: (question: TreeQuestionType) => void;
  removeQuestion: (id: string) => void;
  addOption: (questionId: string) => void;
}
interface ComponentState {}

class TreeQuestionNode extends Component<ComponentProps, ComponentState> {
  styles: any = {
    deleteButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#f17878',
      borderColor: '#f17878',
      backgroundColor: '#FBDDDD',
      marginTop: '5px',
      marginRight: '5px',
      marginBottom: '5px'
    },
    addButton: {
      width: '200px',
      color: '#439AFF',
      borderColor: '#439AFF',
      backgroundColor: '#D0E6FF',
      marginTop: '5px',
      marginBottom: '5px'
    }
  };

  onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { question, updateQuestion } = this.props;
    updateQuestion({
      ...question,
      text: e.target.value
    });
  };

  render() {
    const {
      disabled, question, removeQuestion, addOption
    } = this.props;
    const { text, id } = question;
    return (
      <div className="tree-node-content">
        <Item
          className="tree-node-title"
          key={`node-question-title-${id}`}
          name={`node-question-title-${id}`}
          rules={[
            {
              required: true,
              message: 'Tree questions must have text'
            }
          ]}
          initialValue={text}
          noStyle
        >
          <Input
            spellCheck
            disabled={disabled}
            placeholder="Question text"
            bordered={false}
            onChange={this.onTextChange}
          />
        </Item>
        <Button
          key={`add-question-${id}`}
          className="tree-node-add"
          color="#439AFF"
          disabled={disabled}
          type="dashed"
          onClick={() => addOption(id)}
          style={this.styles.addButton}
        >
          <PlusOutlined />
          {' '}
          Add option
        </Button>
        {!disabled && (
          <Button
            key={`delete-question-${id}`}
            className="tree-node-delete"
            color="#f17878"
            onClick={() => removeQuestion(id)}
            style={this.styles.deleteButton}
          >
            <CloseOutlined />
          </Button>
        )}
      </div>
    );
  }
}

export default TreeQuestionNode;
