import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import IApplicationState from '../../types/state.types';
import { entitySchema } from '../schema';
import {StudyArmType, StudyType} from "../../types/serverTypes/studyTypes";

export const selectStudies = (state: IApplicationState) => {
  return state.entities.studies;
};

export const selectStudyArms = (state: IApplicationState) => {
  return state.entities.studyArms;
};

export const getStudyArms = createSelector(
  [selectStudyArms],
  (studyArms): Optional<StudyArmType[]> => {
    if (studyArms.allIds && studyArms.allIds.length > 0) {
      const { studyArms: denormalizedStudyArms } = denormalize({ studyArms: studyArms.allIds }, entitySchema, { studyArms: studyArms.byId });
      return toArray(denormalizedStudyArms);
    }
    return undefined;
  }
);

export const getStudies = createSelector(
  [selectStudies, selectStudyArms],
  (studies, studyArms): Optional<StudyType[]> => {
    if (studies.allIds && studies.allIds.length > 0) {
      const { studies: denormalizedStudies } = denormalize({ studies: studies.allIds }, entitySchema, { studies: studies.byId, studyArms: studyArms.byId });
      return toArray(denormalizedStudies);
    }
    return undefined;
  }
);

export const getStudyStats = (state: IApplicationState) => {
  return state.ui.study.stats;
}


export default getStudies;
