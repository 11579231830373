import moment from 'moment';
import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { Button, Card, Table } from 'antd';
import { connect } from 'react-redux';
import { _ } from 'lodash';
import Column from 'antd/es/table/Column';
import { RouteComponentProps, withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { deleteBadgeGroupAsync, undeleteBadgeGroupAsync } from '../../redux/badges/badges.types';
import IApplicationState from '../../types/state.types';
import * as selectors from '../../redux/selectors';
import { IApiRequestState } from '../../types/api.types';
import { renderDateWithTime } from '../util/Util';
import AddBadgeGroupModal from './AddBadgeGroupModal';

import './badge.scss';
import { BadgeGroupType } from '../../types/serverTypes';

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

interface StateProps {
  badgeGroups: Optional<BadgeGroupType[]>,
  studyId: number,
  status: IApiRequestState,
  requestedBadgeTab: string,
  newOrEdit: string
}

interface DispatchProps {
  deleteBadgeGroup: typeof deleteBadgeGroupAsync.request,
  undeleteBadgeGroup: typeof undeleteBadgeGroupAsync.request,
}

interface ComponentProps extends StateProps, DispatchProps, RouteComponentProps {}

const initialState = {
};

type ComponentState = typeof initialState;

class BadgeGroups extends Component<ComponentProps, ComponentState> {
  readonly state = initialState;

  constructor(props: ComponentProps) {
    super(props);
  }

  async componentDidMount() {
  }

  showAddBadgeGroupModal = () => {
    const { history, studyId } = this.props;
    history.push(`/study/${studyId}/badges/badgeGroups/new/`);
  }

  hideAddBadgeGroupModal = () => {
    const { history, studyId } = this.props;
    history.push(`/study/${studyId}/badges/badgeGroups/`);
  }

  editBadgeGroup = (badgeGroupId: number) => {
    const { history, studyId } = this.props;
    history.push(`/study/${studyId}/badges/badgeGroups/edit/${badgeGroupId}`);
  }

  renderActions = (badgeGroup: BadgeGroupType) => {
    return (
      <div className="badge-action-container">
        <a title="Edit" className="badge-action" onClick={(e) => { e.stopPropagation(); this.editBadgeGroup(badgeGroup.id!); }}>
          <i className="far fa-eye fa-lg" />
        </a>
        {badgeGroup.deleteDate
          ? (
            <a
              title="Undelete"
              className="badge-action"
              onClick={(e) => {
                e.stopPropagation();
                this.props.undeleteBadgeGroup(badgeGroup.id!);
              }}
            >
              <i className="fal fa-trash-undo-alt fa-lg" />
            </a>
          )
          : (
            <a
              title="Delete"
              className="badge-action"
              onClick={(e) => {
                e.stopPropagation();
                this.props.deleteBadgeGroup(badgeGroup.id!);
              }}
            >
              <i className="fal fa-trash-alt fa-lg" />
            </a>

          )}
      </div>
    );
  };

  onRow = (row: BadgeGroupType) => {
    const { history, studyId } = this.props;
    return {
      onClick: (event: React.MouseEvent) => {
        event.stopPropagation();
        history.push(`/study/${studyId}/badges/badgeGroups/edit/${row.id}`);
      }
    };
  };

  render() {
    const { badgeGroups, newOrEdit, requestedBadgeTab } = this.props;
    const { } = this.state;

    const showModal = newOrEdit && requestedBadgeTab === 'badgeGroups';

    return (
      <Card title={(
        <div className="badges-title">
          <h1>Badge Groups</h1>
          <Button type="primary" onClick={this.showAddBadgeGroupModal}> + Add Badge Group</Button>
        </div>
      )}
      >
        <AddBadgeGroupModal visible={showModal} hide={this.hideAddBadgeGroupModal} />
        <div>
          <Table
            dataSource={badgeGroups}
            pagination={{ pageSize: 100 }}
            rowClassName={rowClassName}
            onRow={this.onRow}
            rowKey="id"
          >
            <Column
              title="ID"
              dataIndex="id"
              key="id"
              width="70px"
              defaultSortOrder="ascend"
              sorter={(a: any, b: any) => a.id - b.id}
            />
            <Column
              title="Label"
              dataIndex="label"
              key="label"
              width="200px"
              sorter={(a: any, b: any) => `${a.label}`.localeCompare(b.label)}
              filters={_.map(_.sortedUniq(_.uniq(_.sortBy(_.map(badgeGroups, 'label')))), x => { return { text: x, value: x }; })}
              onFilter={(value, record) => record.label === value}
            />
            <Column
              title="Description"
              dataIndex="description"
              key="description"
              width="200px"
              sorter={(a: any, b: any) => `${a.description}`.localeCompare(b.description)}
            />
            <Column
              title="Sequence"
              dataIndex="sequence"
              key="sequence"
              width="200px"
              sorter={(a: any, b: any) => a.sequence - b.sequence}
            />
            <Column
              title="Create Date"
              width="100px"
              dataIndex="createDate"
              key="createDate"
              render={renderDateWithTime}
              sorter={(a: any, b: any) => moment(a.createDate).unix() - moment(b.createDate).unix()}
            />
            <Column
              title="Last Update Date"
              width="100px"
              dataIndex="lastUpdateDate"
              key="lastUpdateDate"
              render={renderDateWithTime}
              sorter={(a: any, b: any) => moment(a.lastUpdateDate).unix() - moment(b.lastUpdateDate).unix()}
            />
            <Column
              title="Delete Date"
              width="100px"
              dataIndex="deleteDate"
              key="deleteDate"
              render={renderDateWithTime}
              sorter={(a: any, b: any) => moment(a.deleteDate).unix() - moment(b.deleteDate).unix()}
            />
            <Column title="Actions" render={this.renderActions} />
          </Table>
        </div>
      </Card>
    );
  }

}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    badgeGroups: selectors.getBadgeGroups,
    status: selectors.loadBadgeGroupsStatus,
    studyId: selectors.getRequestedStudyId,
    requestedBadgeTab: selectors.getRequestedBadgeTab,
    newOrEdit: selectors.getUrlRouteNewOrEdit
  }
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    deleteBadgeGroup: (badgeGroupId: number) => dispatch(deleteBadgeGroupAsync.request(badgeGroupId)),
    undeleteBadgeGroup: (badgeGroupId: number) => dispatch(undeleteBadgeGroupAsync.request(badgeGroupId))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BadgeGroups));
