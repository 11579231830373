import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { IApiRequestState } from '../types/api.types';
import IApplicationState from '../types/state.types';
import * as selectors from '../redux/selectors';
import { getUserAsync } from '../redux/user/user.types';
import LandingPage from '../pages/auth/LandingPage';
import AdminRouter from './AdminRouter';
import StudyRouter from './StudyRouter';
import ClientWebSocketManager from '../redux/clientWebsocketManager';
import GitInfo from '../GitInfo';
import { UserType } from '../types/serverTypes/usmgTypes';

interface StateProps {
  authenticatedUserId: Optional<number>;
  userLoadingStatus: IApiRequestState;
  user: Optional<UserType>;
}

interface DispatchProps {
  getUser: typeof getUserAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {}

const Router = (props: ComponentProps) => {
  useEffect(() => {
    const { authenticatedUserId, user, getUser } = props;
    if (authenticatedUserId && !user) {
      getUser(authenticatedUserId);
      ClientWebSocketManager.createConnection();
    }
  }, []);

  const { userLoadingStatus, user } = props;

  if (userLoadingStatus.isLoading) {
    return <div>Loading...</div>;
  }

  if (userLoadingStatus.isError) {
    if (userLoadingStatus.errorStatus === 403) {
      return <div>Forbidden</div>;
    }
    return <div>Error</div>;
  }

  if (!user) {
    return <div>The user could not be loaded.</div>;
  }

  return (
    <Switch>
      <Route path="/admin" component={AdminRouter} />
      <Route path="/study" component={StudyRouter} />
      <Route path="/gitInfo" component={GitInfo} />
      <Route component={LandingPage} />
    </Switch>
  );
};

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    authenticatedUserId: selectors.getAuthenticatedUserId,
    userLoadingStatus: selectors.getUserLoadingStatus,
    user: selectors.getUser,
  }
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getUser: (id: number) => dispatch(getUserAsync.request(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
