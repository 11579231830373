import _ from "lodash";
import {CascaderOptionType} from "antd/lib/cascader";
import {denormalize} from "normalizr";
import {createSelector} from "reselect";
import IApplicationState, {NormalizedState} from "../../types/state.types";
import {entitySchema} from "../schema";
import {TopicType} from "../../types/serverTypes/forumTypes";

export const selectTopics = (state: IApplicationState) => {
  return state.entities.topics;
};

export const getForumTopics = createSelector([selectTopics], (topics): Optional<
  TopicType[]
> => {
  return filterByCollectionType(topics, "forum");
});

export const getFlattenedForumTopics = createSelector(
  [selectTopics],
  (topics): Optional<TopicType[]> => {
    return flatten(filterByCollectionType(topics, "forum"));
  }
);

export const getQnaTopics = createSelector([selectTopics], (topics): Optional<
  TopicType[]
> => {
  return filterByCollectionType(topics, "qna");
});

export const getResourceTopics = createSelector(
  [selectTopics],
  (topics): Optional<TopicType[]> => {
    const updatedTopic = filterByCollectionType(
      topics,
      "knowledgecenter"
    )?.sort((a, b) => {
      if (a === b) return 0;
      if (a === null || a.sortOrder === null) return 1;
      if (b === null || b.sortOrder === null) return -1;
      return (a.sortOrder ?? 100000) > (b.sortOrder ?? 10000) ? 1 : -1;
    });

    return updatedTopic;
  }
);

export const getResourceTopicsCascader = createSelector(
  [getResourceTopics],
  (topics): CascaderOptionType[] => {
    if (topics) {
      const options: CascaderOptionType[] = topics.map((topic) => {
        return {
          value: `${topic.id}`,
          label: topic.title,
          children: !topic.children
            ? []
            : topic.children.map((child) => {
                return {
                  value: `${child.id}`,
                  label: child.title,
                };
              }),
        };
      });
      return options;
    }
    return [];
  }
);

export const getGoalTopics = createSelector([selectTopics], (topics): Optional<
  TopicType[]
> => {
  return filterByCollectionType(topics, "goal");
});

// stigma doesn't have deeply nested topics but our test data does and the model supports it so...
function flatten(topics: TopicType[] | undefined) {
  if (!topics) return [];
  const flat: TopicType[] = [];

  topics.forEach((topic: TopicType) => {
    flat.push(topic);
    if (
      topic.children &&
      Array.isArray(topic.children) &&
      topic.children.length > 0
    ) {
      flat.push(...flatten(topic.children));
    }
  });

  return flat;
}

const filterByCollectionType = (
  topics: NormalizedState<TopicType>,
  collectionType: string
): Optional<TopicType[]> => {
  if (topics && topics.allIds && topics.allIds.length > 0) {
    const { topics: denormalizedTopics } = denormalize(
      { topics: topics.allIds },
      entitySchema,
      { topics: topics.byId }
    );
    return _.toArray(denormalizedTopics).filter((topic: TopicType) => {
      return topic.collectionType === collectionType;
    });
  }
  return undefined;
};

export default {
  getForumTopics,
  getQnaTopics,
  getResourceTopics,
  getGoalTopics,
  selectTopics,
};
