import React, {Component} from "react";
import {connect} from "react-redux";
import IApplicationState from "../../types/state.types";
import {createStructuredSelector} from "reselect";
import {Link} from "react-router-dom";
import * as selectors from "../../redux/selectors";
import {Popover} from "antd";
import {
  ParticipantType,
  StudyArmType,
} from '../../types/serverTypes/studyTypes';
import "./username.scss";

interface StateProps {
  participants: Optional<ParticipantType[]>;
  studyArms: Optional<StudyArmType[]>;
  studyId: number;
}

interface ComponentProps extends StateProps {
  participantId: number;
  username?: string;
  style?: any;
  link?: string;
}

class Username extends Component<ComponentProps, {}> {
  readonly state = {};

  renderPopover = () => {
    const { participantId, participants, studyArms } = this.props;
    const currentParticipant = participants?.find(
      (p) => p.id === participantId
    );
    const currentStudyArm = studyArms?.find(
      (sa) => sa.id === currentParticipant?.studyArmId
    );
    if (!currentParticipant) {
      if(this.props.username)
      {
        return <span>{this.props.username}</span>;
      }
      return <span>No Participant Found.</span>;
    }
    return (
      <div className="username-popover">
        <span>
          <b>ID:</b>
          {' '}
          {currentParticipant.id}
        </span>
        <span>
          <b>Name:</b>
          {' '}
          {`${currentParticipant.firstName} ${currentParticipant.lastName}`}
        </span>
        <span>
          <b>Study Arm:</b>
          {' '}
          {currentStudyArm?.name}
        </span>
        <span>
          <b>Mobile:</b> {currentParticipant.mobile}
        </span>
      </div>
    );
  };

  render() {
    const {
      participantId, participants, style, studyId, link
    } = this.props;
    const currentParticipant = participants?.find(
      (p) => p.id === participantId
    );
    if (!currentParticipant && !this.props.username) {
      return <span>No Participant Found.</span>;
    }
    const username = currentParticipant?.username ?? this.props.username;
    return (
      <Popover content={this.renderPopover()}>
        <Link
          style={style || {}}
          to={link || `/study/${studyId}/participants/${participantId}`}
        >
          {username}
        </Link>
      </Popover>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    participants: selectors.getParticipants,
    studyArms: selectors.getStudyArms,
    studyId: selectors.getRequestedStudyId
  }
);

export default connect(mapStateToProps, null)(Username);
