import {ActionType, createAsyncAction} from 'typesafe-actions';
import {AnalyticsType} from "../../types/serverTypes/analyticsTypes";

export interface IAnalyticsUIState {
  reportRows: AnalyticsType[]
}
export const getAnalyticsReportAsync = createAsyncAction(
  'hmp/admin/GET_ANALYTICS_REPORT_REQUEST',
  'hmp/admin/GET_ANALYTICS_REPORT_SUCCESS',
  'hmp/admin/GET_ANALYTICS_REPORT_FAILURE'
)<void, Optional<AnalyticsType[]>, Error>();

const analyticsActions = {
  getAnalyticsReportAsync,
};

export type AnalyticsActionTypes = ActionType<typeof analyticsActions>;
