import { _ } from 'lodash';
import {PostType} from "../../types/serverTypes/forumTypes";

export interface SocialJsonParamType {
  type: string;
  ids: number[];
}

export interface CreateUpdateCommentParam {
  id?: number;
  type: string,
  typeId: number,
  comment: string,
  parentCommentId?: number,
  participantId: number,
  createDate?: Date
}

export const createJsonParam = (type: string, ids: number[]): SocialJsonParamType => {
  return {
    type,
    ids
  }
}

export const createJsonParamFromPosts = (posts:PostType[]): SocialJsonParamType => {
  return createJsonParam('post', _.map(posts, 'id'))
}

export default createJsonParamFromPosts;