import {
  AvatarCategorySequenceType,
  AvatarPartCategoryBaseType,
  AvatarPartCategoryType,
  AvatarPartPointType,
  AvatarPartType,
  AvatarPartUploadType,
} from '../../types/serverTypes/avatarTypes';
import axios from '../../redux/api';

export const getAvatarCategories = async () => {
  const response = await axios.get(`/a/avatar/categories`);
  return response.data;
};

export const getAvatarParts = async () => {
  const response = await axios.get(`/a/avatar/parts`);
  return response.data;
};

export const updateAvatarPart = async (avatarPart: AvatarPartType) => {
  const response = await axios.put(`/a/avatar/part`, avatarPart);
  return response.data;
};

export const updateAvatarCategory = async (
  avatarCategory: AvatarPartCategoryType
) => {
  const response = await axios.put(`/a/avatar/category`, avatarCategory);
  return response.data;
};

export const updateAvatarCategoriesSequence = async (
  avatarCategories: AvatarCategorySequenceType[]
) => {
  const response = await axios.put(
    `/a/avatar/categories/sequence`,
    avatarCategories
  );
  return response.data;
};

export const createAvatarCategory = async (
  avatarCategory: AvatarPartCategoryBaseType
) => {
  const response = await axios.post(`/a/avatar/category`, avatarCategory);
  return response.data;
};

export const deleteAvatarCategory = async (
  avatarCategory: AvatarPartCategoryType
) => {
  const response = await axios.delete(
    `/a/avatar/category/${avatarCategory.id}`
  );
  return response.data;
};

export const createAvatarParts = async (
  avatarUploadData: AvatarPartUploadType[]
) => {
  const response = await axios.post(`/a/avatar/parts`, avatarUploadData);
  return response.data;
};

export const getScheduleAvatarParts = async ({
  queryKey,
}): Promise<AvatarPartPointType[]> => {
  console.debug('Query Key: ', queryKey);
  const [_key, studyArmSelected] = queryKey;
  const response = await axios.get(
    `/a/avatar/scheduleParts?studyArm=${studyArmSelected}`
  );
  return response.data;
};
export const createScheduleAvatarPart = async (
  schedulePart: AvatarPartPointType
) => {
  await axios.post(`/a/avatar/partSchedule`, schedulePart);
};

export const removePartSchedule = async (scheduleId: number) => {
  await axios.delete(`/a/avatar/schedule/${scheduleId}`);
};

export const resetParticipantAvatars = async (selectedParts: string[]) => {
  const serializedParts = serializeAvatarParts(selectedParts);
  await axios.post(`/a/avatar/participants/reset`, {
    serializedAvatar: serializedParts,
  });
};

const serializeAvatarParts = (parts: string[]) => {
  const avatarSerializedSvg = parts
    // Remove <svg /> and <xml /> tags
    .map((part) => {
      // const processedSvg = convertToSvg(part);
      const split = part.split('>');
      return split
        .filter((str) => !str.includes('xml') && !str.includes('svg'))
        .join('>');
    })
    .join('');
  return avatarSerializedSvg;
};
