import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import {FlagsActionTypes, getFlagsAsync, resolveFlagsAsync} from "./flags.types";
import {FlagType} from "../../types/serverTypes/forumTypes";

const updateFlagsReducer = produce((draft, flags) => {
  if (!flags) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(flags), Number));
  const ids = Object.keys(flags);
  for (const id of ids) {
    draft.byId[id] = flags[id];
  }
});

export const flagsReducer = (state: NormalizedState<FlagType> = initialNormalizedState, action: FlagsActionTypes) => {

  switch (action.type) {
    case getType(getFlagsAsync.success): return updateFlagsReducer(state, action.payload);
    case getType(resolveFlagsAsync.success): return updateFlagsReducer(state, action.payload);
    default: return state;
  }
};

export default flagsReducer;
