import { Reducer } from 'react';
import { getType } from 'typesafe-actions';
import {
  ForumActionTypes,
  IForumUIState, updateFlaggedPostIds, updateFlaggedScroll, updateFlaggedSelectedPostId,
  updateRecentActivityPostIds, updateRecentActivityScroll, updateRecentActivitySelectedPostId,
  updateUncategorizedPostIds, updateUncategorizedScroll, updateUncategorizedSelectedPostId,
  updateRecentActivitySelectedQnaPostId, resetRecentActivitySelectedQnaPostId, resetRecentActivityPostIds
} from './forum.types';

export const initialForumUIState = {
  recentActivityPostIds: [],
  uncategorizedPostIds: [],
  flaggedPostIds: [],
  recentActivity: {
    scrollTop: 0,
    selectedPostId: undefined,
    selectedQnaPostId: undefined
  },
  flaggedPosts: {
    scrollTop: 0,
    selectedPostId: undefined
  },
  uncategorizedPosts: {
    scrollTop: 0,
    selectedPostId: undefined
  },
}

export const forumUIReducer: Reducer<IForumUIState, ForumActionTypes> = (state: IForumUIState = initialForumUIState, action: ForumActionTypes) => {

  switch (action.type) {
    case getType(resetRecentActivityPostIds):
      return {
        ...state,
        recentActivityPostIds: []
      };
    case getType(updateRecentActivityPostIds):
      return {
        ...state,
        recentActivityPostIds: state.recentActivityPostIds.concat(action.payload)
      };
    case getType(updateUncategorizedPostIds):
      return {
        ...state,
        uncategorizedPostIds: action.payload
      };
    case getType(updateFlaggedPostIds):
      return {
        ...state,
        flaggedPostIds: action.payload
      };
    case getType(updateRecentActivityScroll):
      return {
        ...state,
        recentActivity: {
          scrollTop: action.payload,
          selectedPostId: state.recentActivity.selectedPostId
        }
      }
    case getType(updateFlaggedScroll):
      return {
        ...state,
        flaggedPosts: {
          scrollTop: action.payload,
          selectedPostId: state.flaggedPosts.selectedPostId
        }
      }
    case getType(updateUncategorizedScroll):
      return {
        ...state,
        uncategorizedPosts: {
          scrollTop: action.payload,
          selectedPostId: state.uncategorizedPosts.selectedPostId
        }
      }
    case getType(updateRecentActivitySelectedPostId):
      return {
        ...state,
        recentActivity: {
          scrollTop: state.recentActivity.scrollTop,
          selectedPostId: action.payload
        }
      }
    case getType(updateRecentActivitySelectedQnaPostId):
      return {
        ...state,
        recentActivity: {
          scrollTop: state.recentActivity.scrollTop,
          selectedQnaPostId: action.payload
        }
      }
    case getType(resetRecentActivitySelectedQnaPostId):
      return {
        ...state,
        recentActivity: {
          scrollTop: state.recentActivity.scrollTop,
          selectedQnaPostId: undefined
        }
      }
    case getType(updateFlaggedSelectedPostId):
      return {
        ...state,
        flaggedPosts: {
          scrollTop: state.flaggedPosts.scrollTop,
          selectedPostId: action.payload
        }
      }
    case getType(updateUncategorizedSelectedPostId):
      return {
        ...state,
        uncategorizedPosts: {
          scrollTop: state.uncategorizedPosts.scrollTop,
          selectedPostId: action.payload
        }
      }

    default: return state;
  }

};

export default forumUIReducer;
