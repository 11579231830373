import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import { initialUserState, initialNormalizedState, NormalizedState } from '../../types/state.types';
import {
  getUserAsync,
  UserActionTypes,
  getAdminAsync,
  updatePasswordAsync,
  updateEmailAsync,
  updateMobileAsync,
  getAdminRolesAsync,
  updateAdminUserAsync,
} from './user.types';
import { AdminUserType, RoleType } from '../../types/serverTypes/adminTypes';

const updateAdminReducer = produce((draft, admin) => {
  if (!admin) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(admin), Number));
  const ids = Object.keys(admin);
  for (const id of ids) {
    draft.byId[id] = admin[id];
  }
});

const updateAdminRolesReducer = produce((draft, adminRoles) => {
  if (!adminRoles) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(adminRoles), Number));
  const ids = Object.keys(adminRoles);
  for (const id of ids) {
    draft.byId[id] = adminRoles[id];
  }
});

export const userReducer = (
  state: Optional<AdminUserType> = initialUserState,
  action: UserActionTypes
): Optional<AdminUserType> => {
  switch (action.type) {
    case getType(getUserAsync.request): {
      return initialUserState;
    }

    case getType(updatePasswordAsync.success):
    case getType(updateEmailAsync.success):
    case getType(updateMobileAsync.success):
    case getType(getUserAsync.success): {
      return action.payload as AdminUserType;
    }
    case getType(getUserAsync.failure): {
      return initialUserState;
    }
    default: {
      return state;
    }
  }
};

export const adminUserReducer = (
  state: NormalizedState<AdminUserType> = initialNormalizedState,
  action: UserActionTypes
) => {
  switch (action.type) {
    case getType(getAdminAsync.success):
    case getType(updateAdminUserAsync.success):
      return updateAdminReducer(state, action.payload);
    default: {
      return state;
    }
  }
};

export const adminRoleReducer = (state: NormalizedState<RoleType> = initialNormalizedState, action: UserActionTypes) => {
  switch (action.type) {
    case getType(getAdminRolesAsync.success): return updateAdminRolesReducer(state, action.payload);
    default: {
      return state;
    }
  }
}

export default userReducer;
