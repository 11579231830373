{
  "variables": [
    {
      "name": "userId",
      "type": "number",
      "format": null
    },
    {
      "name": "receiver_username",
      "type": "string",
      "format": null
    },
    {
      "name": "receiver_firstname",
      "type": "string",
      "format": null
    },
    {
      "name": "mobile_app_base_url",
      "type": "string",
      "format": null
    },
    {
      "name": "current_date",
      "type": "Date",
      "format": "lll"
    },
    {
      "name": "current_time",
      "type": "Date",
      "format": "lt"
    },
    {
      "name": "study_email",
      "type": "string",
      "format": null
    },
    {
      "name": "study_phone",
      "type": "string",
      "format": null
    },
    {
      "name": "app_name",
      "type": "string",
      "format": null
    },
    {
      "name": "admin_url",
      "type": "string",
      "format": null
    }
  ],
  "appointment": {
    "variables": [
      {
        "name": "adminId",
        "type": "number",
        "format": null
      },
      {
        "name": "appointmentId",
        "type": "string",
        "format": null
      },
      {
        "name": "startDate",
        "type": "Date",
        "format": "lll"
      },
      {
        "name": "actionByUsername",
        "type": "string",
        "format": null
      },
      {
        "name": "actionForUsername",
        "type": "string",
        "format": null
      }
    ],
    "appointment_due": {
      "variables": []
    }
  },
  "availability": {
    "variables": [
      {
        "name": "startDate",
        "type": "Date",
        "format": "lll"
      },
      {
        "name": "endDate",
        "type": "Date",
        "format": "lll"
      },
      {
        "name": "actionByUsername",
        "type": "string",
        "format": null
      },
      {
        "name": "actionForUsername",
        "type": "string",
        "format": null
      }
    ]
  },
  "login": {
    "variables": [
      {
        "name": "notificationId",
        "type": "number",
        "format": null
      }
    ]
  },
  "activity": {
    "variables": [
      {
        "name": "notificationId",
        "type": "number",
        "format": null
      },
      {
        "name": "activityId",
        "type": "number",
        "format": null
      }
    ]
  },
  "forum": {
    "variables": [
      {
        "name": "forumPostId",
        "type": "number",
        "format": null
      },
      {
        "name": "postTitle",
        "type": "string",
        "format": null
      },
      {
        "name": "postContent",
        "type": "string",
        "format": null
      }
    ]
  },
  "qna": {
    "variables": [
      {
        "name": "qnaPostId",
        "type": "number",
        "format": null
      },
      {
        "name": "commentId",
        "type": "number",
        "format": null
      },
      {
        "name": "question",
        "type": "string",
        "format": null
      }
    ]
  },
  "resource": {
    "variables": [
      {
        "name": "notificationId",
        "type": "number",
        "format": null
      },
      {
        "name": "articleId",
        "type": "number",
        "format": null
      },
      {
        "name": "articleTitle",
        "type": "string",
        "format": null
      }
    ]
  },
  "message": {
    "variables": [
      {
        "name": "threadId",
        "type": "number",
        "format": null
      }
    ]
  },
  "goal": {
    "variables": [
      {
        "name": "goalId",
        "type": "number",
        "format": null
      },
      {
        "name": "goalTitle",
        "type": "string",
        "format": null
      }
    ]
  },
  "badge": {
    "variables": [
      {
        "name": "earnedDate",
        "type": "Date",
        "format": "lll"
      },
      {
        "name": "badgeTitle",
        "type": "string",
        "format": null
      }
    ]
  }
}