import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  TestkitActionTypes,
  getRequestedTestkitsAsync,
  getAwaitingResultTestkitsAsync,
  getNeedsReviewTestkitsAsync,
  getCompletedTestkitsAsync,
  updateTestkits,
  fulfillTestkitAsync,
  reviewTestkitAsync, getParticipantsTestkitsAsync,
} from './testkit.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import {TestkitType} from "../../types/serverTypes/testkitTypes";

const updateTestkitsReducer = produce((draft, testkits) => {
  if (!testkits) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(testkits), Number));
  const ids = Object.keys(testkits);
  for (const id of ids) {
    draft.byId[id] = testkits[id];
  }
});

export const testkitReducer = (state: NormalizedState<TestkitType> = initialNormalizedState, action: TestkitActionTypes) => {
  switch (action.type) {
    case getType(getRequestedTestkitsAsync.success): return updateTestkitsReducer(state, action.payload);
    case getType(getParticipantsTestkitsAsync.success): return updateTestkitsReducer(state, action.payload);
    case getType(getAwaitingResultTestkitsAsync.success): return updateTestkitsReducer(state, action.payload);
    case getType(getNeedsReviewTestkitsAsync.success): return updateTestkitsReducer(state, action.payload);
    case getType(getCompletedTestkitsAsync.success): return updateTestkitsReducer(state, action.payload);
    case getType(fulfillTestkitAsync.success): return updateTestkitsReducer(state, action.payload);
    case getType(reviewTestkitAsync.success): return updateTestkitsReducer(state, action.payload);
    case getType(updateTestkits): return updateTestkitsReducer(state, action.payload);
    default: return state;
  }
};

export default testkitReducer;
