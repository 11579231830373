import Card from 'antd/lib/card';
import Descriptions from 'antd/lib/descriptions';
import { Empty } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import ProgressStatCard from '../../../components/statistics/ProgressStatCard';
import { getStudyArmLabelFromId } from '../../../components/util/Util';
import * as selectors from '../../../redux/selectors';
import { loadStudyStatsAsync } from '../../../redux/study/study.types';
import IApplicationState from '../../../types/state.types';
import './study.scss';
import { StudyArmType, StudyType } from '../../../types/serverTypes/studyTypes';

interface StateProps {
  study: Optional<StudyType>,
  studyArms: Optional<StudyArmType[]>,
  studyStats: Optional<any>
}

interface DispatchProps {
  loadStudyStats: typeof loadStudyStatsAsync.request
  // resolveFlags: typeof resolveFlagsAsync.request,
  // hideContent: typeof hideContentAsync.request,
  // unhideContent: typeof unhideContentAsync.request,
  // savePost: typeof savePostAsync.request,
}

interface ComponentProps extends StateProps {}

class Study extends Component<ComponentProps, {}> {

  componentDidMount() {
    const { study, loadStudyStats } = this.props;
    if (study) {
      loadStudyStats(study.id);
    }
  }

  render() {

    const { study, studyArms, studyStats } = this.props;

    if (!study) {
      return <div>Error Loading Study</div>;
    }

    const arms = study.studyArms;
    const studyStatsProp = studyStats || [];
    return (
      <div className="study-page">
        <Card title={study.name}>
          <Descriptions>
            <Descriptions.Item label="Start Date">
              {study.startDate ? moment(study.startDate).format('MM/DD/YYYY') : 'Unknown'}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions>
            <Descriptions.Item label="Description">
              {study?.description}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <div className="progress-stat-card-container">
          {studyStatsProp?.length
            ? studyStatsProp.map((study:any) => {
              return <ProgressStatCard key={study.studyArmId} title="Active Participants" subTitle={getStudyArmLabelFromId(studyArms, study.studyArmId)} count={study.activeParticipant} color="blue" total={study.targetCount} />;
            })
            : (
              <div className="empty-container">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No active participant or study arm data yet" />
              </div>
)}
        </div>
        {/* <Card title="Active Participants" style={{marginTop: '20px'}}> */}
        {/*  <div style={{height: '300px'}}> */}
        {/*    <ActiveParticipantsLineChart data={activeParticipantData} /> */}
        {/*  </div> */}
        {/* </Card> */}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  study: selectors.getRequestedStudy,
  studyArms: selectors.getStudyArms,
  studyStats: selectors.getStudyStats
});

const mapDispatchToProps = (dispatch: Dispatch) : DispatchProps => {
  return {
    loadStudyStats: (studyId: number) => dispatch(loadStudyStatsAsync.request(studyId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Study);
