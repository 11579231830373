import axios from '../../../redux/api';
import * as qs from 'query-string';
import { useMutation, useQuery } from 'react-query';
import { GoalType, GoalDetailType } from '../../../types/serverTypes/goalTypes';
import { queryClient } from '../../../App';
import {
  TopicSortType,
  TopicType,
} from '../../../types/serverTypes/forumTypes';
import { ResourceTopicType } from 'types/serverTypes/resourceTypes';

export const goalKeys = {
  all: ['goals'] as const,
  lists: () => [...goalKeys.all, 'list'] as const,
  list: (filters: any[]) => [goalKeys.lists(), ...filters] as const,
  details: () => [...goalKeys.all, 'detail'] as const,
  detail: (id: string) => [...goalKeys.details(), id] as const,
};

export const fetchGoal = async (id: string, includeDeleted?: boolean) => {
  const response = await axios.get(`/a/goal/detail/${id}`, {
    params: {
      includeDeleted,
    },
  });
  return response.data;
};

export const useGoalDetailQuery = (id: string, includeDeleted?: boolean) =>
  useQuery<GoalDetailType>(goalKeys.detail(id), () =>
    fetchGoal(id, includeDeleted)
  );

export const fetchGoals = async (
  term?: string,
  pageNumber?: number,
  pageSize?: number,
  includeDeleted?: boolean,
  includeUnpublished?: boolean
) => {
  const response = await axios.get(`/a/goal/all`, {
    params: {
      term,
      pageNumber,
      pageSize,
      includeDeleted,
      includeUnpublished,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'comma' });
    },
  });
  return response.data;
};

export const useGoalQuery = (
  term?: string,
  pageNumber?: number,
  pageSize?: number,
  includeDeleted?: boolean,
  includeUnpublished?: boolean
) =>
  useQuery<GoalType[]>(
    goalKeys.list([
      term,
      pageNumber,
      pageSize,
      includeDeleted,
      includeUnpublished,
    ]),
    () =>
      fetchGoals(
        term,
        pageNumber,
        pageSize,
        includeDeleted,
        includeUnpublished
      ),
    {
      initialData: () => {
        const allGoals = queryClient.getQueryData<GoalType[]>(goalKeys.all);
        const filteredData =
          allGoals
            ?.filter((goal) =>
              !!term
                ? goal.title.includes(term) ||
                  goal.description.includes(term) ||
                  goal.journalPrompt.includes(term)
                : true
            )
            .filter((goal) =>
              !!includeDeleted ? true : goal.deleteDate == null
            )
            .filter((goal) =>
              !!includeUnpublished ? true : goal.publishDate == null
            ) ?? [];

        return filteredData.length > 0 ? filteredData : undefined;
      },
    }
  );

export const updateGoal = async (goal: GoalDetailType) => {
  const response = await axios.put(`/a/goal/${goal.id}/update`, goal);
  return response.data;
};

export const useUpdateGoal = () =>
  useMutation(updateGoal, {
    onSuccess: (data) => {
      // invalidate query list for goals to retrieve the new goal from the database
      queryClient.invalidateQueries(goalKeys.lists());
      queryClient.invalidateQueries(goalKeys.detail(data.id));
    },
  });

export const createGoal = async (goal: GoalDetailType) => {
  const response = await axios.put(`/a/goal/create`, goal);
  return response.data;
};

export const useCreateGoal = () =>
  useMutation(createGoal, {
    onSuccess: (data) => {
      // invalidate query list for goals to retrieve the new goal from the database
      queryClient.invalidateQueries(goalKeys.lists());
    },
  });

export const deleteGoal = async (id: string) => {
  const response = await axios.delete(`/a/goal/${id}/delete`);
  return response.data;
};

export const useDeleteGoal = () =>
  useMutation(deleteGoal, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(goalKeys.lists());
      queryClient.invalidateQueries(goalKeys.detail(data.id));
    },
  });

export const goalTopicKeys = {
  all: ['goalTopics'] as const,
  lists: () => [...goalTopicKeys.all, 'list'] as const,
  list: (filters: any) => [goalTopicKeys.lists(), filters] as const,
  details: () => [...goalTopicKeys.all, 'detail'] as const,
  detail: (id: string) => [...goalTopicKeys.details(), id] as const,
};

export const fetchGoalTopics = async (studyId: string) => {
  const response = await axios.get(`/a/topic/goal/${studyId}/topicStructure`);
  return response.data;
};

export const useGoalTopicQuery = (studyId: string) =>
  useQuery<TopicType[]>(goalTopicKeys.all, () => fetchGoalTopics(studyId));

export const updateTopic = async (topic: ResourceTopicType) => {
  const response = await axios.put(`/a/topic`, { topic });
  return response.data;
};

export const useUpdateTopic = () =>
  useMutation(updateTopic, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(goalTopicKeys.lists());
      queryClient.invalidateQueries(goalTopicKeys.detail(data.id));
    },
  });

export const updateTopicSort = async (topics: TopicSortType[]) => {
  const response = await axios.put(`/a/topic/sort`, { topics });
  return response.data;
};

export const useUpdateTopicSort = () =>
  useMutation(updateTopicSort, {
    onSuccess: () => {
      queryClient.invalidateQueries(goalTopicKeys.lists());
      queryClient.invalidateQueries(goalTopicKeys.details());
    },
  });

export const deleteTopic = async (id: number) => {
  const response = await axios.delete(`/a/topic/${id}`);
  return response.data;
};

export const useDeleteTopic = () =>
  useMutation(deleteTopic, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(goalTopicKeys.lists());
      queryClient.invalidateQueries(goalTopicKeys.detail(data.id));
    },
  });
