import { Button, Spin, Table } from 'antd';
import Column from 'antd/es/table/Column';
import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { renderDateWithoutTime, renderDateWithTime } from '../../components/util/Util';
import { getAnalyticsReportAsync } from '../../redux/analitycs/analytics.types';
import * as selectors from '../../redux/selectors';
import { IApiRequestState } from '../../types/api.types';
import IApplicationState from '../../types/state.types';
import './report.scss';
import { AnalyticsType } from '../../types/serverTypes/analyticsTypes';

interface StateProps {
  analytics: Optional<AnalyticsType[]>,
  getAnalyticsReportStatus: IApiRequestState,
}

interface DispatchProps {
  getAnalyticsReport: typeof getAnalyticsReportAsync.request,
}

interface ComponentProps extends DispatchProps, StateProps { }

const initialState = {
};

type ComponentState = typeof initialState;

class IncentivesReport extends Component<ComponentProps, ComponentState> {
  readonly state: ComponentState = initialState;

  componentDidMount() {
    this.props.getAnalyticsReport();
  }

  rowClassName = (record: any, index: number): string => {
    return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
  };

  render() {
    const { analytics, getAnalyticsReportStatus } = this.props;

    if (getAnalyticsReportStatus.isLoading) {
      return <Spin />;
    }
    const headers = [
      { label: 'ParticipantId', key: 'participantId' },
      { label: 'Study Arm Id', key: 'studyArmId' },
      { label: 'Status', key: 'hivStatus' },
      { label: 'Create Date', key: 'createDate' },
      { label: 'Event Type', key: 'eventType' },
      { label: 'Meta', key: 'meta' }
    ];

    return (
      <div>
        <CSVLink data={analytics} headers={headers} filename={`Analytics-Report-${renderDateWithoutTime(new Date())}`}><Button>Export as CSV</Button></CSVLink>
        <Table
          {...this.state}
          style={{ paddingTop: '10px' }}
          dataSource={analytics}
          rowClassName={this.rowClassName}
          rowKey="incentivePaymentId"
          scroll={{ y: 'calc(100vh - 200px)' }}
          pagination={false}
        >
          <Column
            title="Participant Id"
            dataIndex="participantId"
            key="participantId"
            sorter={(a: any, b: any) => a.participantId - b.participantId}
          />
          <Column
            title="Study Arm Id"
            dataIndex="studyArmId"
            key="studyArmId"
            sorter={(a:any, b: any) => a.studyArmId - b.studyArmId}
          />
          <Column
            title="Status"
            dataIndex="hivStatus"
            key="hivStatus"
            sorter={(a: any, b: any) => a.hivStatus - b.hivStatus}
          />
          <Column
            title="Create Date"
            dataIndex="createDate"
            key="createDate"
            render={(paidDate:any) => `${paidDate ? renderDateWithTime(paidDate) : ''}`}
            defaultSortOrder="descend"
          />
          <Column title="Event Type" dataIndex="eventType" key="eventType" />
          <Column title="Meta" dataIndex="meta" key="meta" />
        </Table>
      </div>
    );
  }

}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  analytics: selectors.getAnalytics,
  getAnalyticsReportStatus: selectors.getAnalyticsReportStatus
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getAnalyticsReport: () => dispatch(getAnalyticsReportAsync.request())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IncentivesReport));
