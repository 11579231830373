import React, { Component, ReactNode } from 'react';
import { Dispatch } from 'redux';
import {
  Button, Card, Table, Popover
} from 'antd';
import { connect } from 'react-redux';
import { _ } from 'lodash';
import Column from 'antd/es/table/Column';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  deleteBadgeCollectionAsync,
  getBadgeCollectionsAsync,
  undeleteBadgeCollectionAsync
} from '../../redux/badges/badges.types';
import IApplicationState from '../../types/state.types';
import * as selectors from '../../redux/selectors';
import { IApiRequestState } from '../../types/api.types';
import { renderDateWithTime } from '../util/Util';
import AddBadgeCollectionModal from './AddBadgeCollectionModal';
import { BadgeCollectionType, BadgeType } from '../../types/serverTypes';

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

interface StateProps {
  badgeCollections: Optional<BadgeCollectionType[]>,
  status: IApiRequestState,
  newOrEdit: Optional<string>,
  studyId: number,
  requestedBadgeTab: string,
  id: Optional<string>
}

interface DispatchProps {
  getBadgeCollections: typeof getBadgeCollectionsAsync.request,
  deleteBadgeCollection: typeof deleteBadgeCollectionAsync.request,
  undeleteBadgeCollection: typeof undeleteBadgeCollectionAsync.request,
}

interface ComponentProps extends StateProps, DispatchProps, RouteComponentProps {}

const initialState = {
};

type ComponentState = typeof initialState;

class BadgeCollections extends Component<ComponentProps, ComponentState> {
  readonly state = initialState;

  async componentDidMount() {
    this.props.getBadgeCollections();
  }

  showAddBadgeCollectionModal = () => {
    const { history, studyId } = this.props;
    history.push(`/study/${studyId}/badges/badgeCollections/new/`);
  }

  hideAddBadgeCollectionModal = () => {
    const { history, studyId } = this.props;
    history.push(`/study/${studyId}/badges/badgeCollection/`);
  }

  editBadgeCollection = (badgeCollectionId: number) => {
    const { history, studyId } = this.props;
    history.push(`/study/${studyId}/badges/badgeCollections/edit/${badgeCollectionId}`);
  }

  deleteBadgeCollection(badgeCollectionId: number) {
    this.props.deleteBadgeCollection(badgeCollectionId);
  }

  undeleteBadgeCollection(badgeCollectionId: number) {
    this.props.undeleteBadgeCollection(badgeCollectionId);
  }

  getDeleteIcon = (badge:BadgeType): ReactNode => {
    if (badge.deleteDate) {
      return (
        <Button onClick={() => this.undeleteBadgeCollection(badge.id)}>
          <i className="fal fa-trash-undo-alt fa-lg" />
        </Button>
      );
    }

    return (
      <Button onClick={() => this.deleteBadgeCollection(badge.id)}>
        <i className="fal fa-trash-alt fa-lg" />
      </Button>
    );

  };

  renderActions = (badgeCollection: BadgeCollectionType) => {
    return (
      <div className="badge-action-container">
        <a title="Edit" className="badge-action" onClick={(e) => { e.stopPropagation(); this.editBadgeCollection(badgeCollection.id!); }}>
          <i className="far fa-eye fa-lg" />
        </a>
        {badgeCollection.deleteDate
          ? (
            <a
              title="Undelete"
              className="badge-action"
              onClick={(e) => {
                e.stopPropagation();
                this.props.undeleteBadgeCollection(badgeCollection.id!);
              }}
            >
              <i className="fal fa-trash-undo-alt fa-lg" />
            </a>
          )
          : (
            <a
              title="Delete"
              className="badge-action"
              onClick={(e) => {
                e.stopPropagation();
                this.deleteBadgeCollection(badgeCollection.id!);
              }}
            >
              <i className="fal fa-trash-alt fa-lg" />
            </a>
          )}
      </div>
    );
  };

  onRow = (row: BadgeType) => {
    const { history, studyId } = this.props;
    return {
      onClick: (event: React.MouseEvent) => {
        event.stopPropagation();
        history.push(`/study/${studyId}/badges/badgeCollections/edit/${row.id}`);
      }
    };
  };

  render() {
    const {
      badgeCollections, newOrEdit, requestedBadgeTab, id
    } = this.props;
    const { } = this.state;

    const showModal:boolean = !!(newOrEdit && requestedBadgeTab === 'badgeCollections');

    return (
      <Card title={(
        <div className="badges-title">
          <h1>Badge Collections</h1>
          <Button type="primary" onClick={this.showAddBadgeCollectionModal}> + Add Badge Collection</Button>
        </div>
      )}
      >
        <div>
          <AddBadgeCollectionModal visible={showModal} hide={this.hideAddBadgeCollectionModal} />
          <Table
            dataSource={badgeCollections}
            pagination={{ pageSize: 100 }}
            rowClassName={rowClassName}
            onRow={this.onRow}
            rowKey="id"
          >
            <Column
              title="ID"
              dataIndex="id"
              key="id"
              width="70px"
              defaultSortOrder="ascend"
              sorter={(a: any, b: any) => a.id - b.id}
            />
            <Column
              title="Label"
              dataIndex="label"
              key="label"
              width="200px"
              sorter={(a: any, b: any) => `${a.label}`.localeCompare(b.label)}
              filters={_.map(_.sortedUniq(_.uniq(_.sortBy(_.map(badgeCollections, 'label')))), x => { return { text: x, value: x }; })}
              onFilter={(value, record) => record.label === value}
            />
            <Column
              title="Description"
              dataIndex="description"
              key="description"
              width="200px"
              sorter={(a: any, b: any) => `${a.description}`.localeCompare(b.description)}
            />
            <Column
              title="Image"
              dataIndex="badge"
              width="50px"
              render={(badge) => (
                <Popover style={{ width: '100px' }} content={<img src={badge} />}>
                  <img style={{ height: '25px' }} src={badge} />
                </Popover>
              )}
            />
            <Column
              title="Quota"
              dataIndex="quota"
              key="quota"
              width="40px"
              sorter={(a: any, b: any) => a.quota - b.quota}
            />
            <Column
              title="Type"
              dataIndex="type"
              key="type"
              width="50px"
              sorter={(a: any, b: any) => `${a.type}`.localeCompare(b.type)}
              filters={_.map(_.sortedUniq(_.uniq(_.sortBy(_.map(badgeCollections, 'type')))), x => { return { text: x, value: x }; })}
              onFilter={(value, record) => record.type === value}
            />
            <Column
              title="Sequence"
              dataIndex="sequence"
              key="sequence"
              width="40px"
              sorter={(a: any, b: any) => a.sequence - b.sequence}
            />
            <Column
              title="Badge Collection Group"
              dataIndex="badgeCollectionGroupLabel"
              key="badgeCollectionGroupLabel"
              width="100px"
              sorter={(a: any, b: any) => `${a.badgeCollectionGroupLabel}`.localeCompare(b.badgeCollectionGroupLabel)}
              filters={_.map(_.sortedUniq(_.uniq(_.sortBy(_.map(badgeCollections, 'badgeCollectionGroupLabel')))), x => { return { text: x, value: x }; })}
              onFilter={(value, record) => record.badgeCollectionGroupLabel === value}
            />
            <Column
              title="Create Date"
              width="100px"
              dataIndex="createDate"
              key="createDate"
              render={renderDateWithTime}
              sorter={(a: any, b: any) => moment(a.createDate).unix() - moment(b.createDate).unix()}
            />
            <Column
              title="Delete Date"
              width="100px"
              dataIndex="deleteDate"
              key="deleteDate"
              render={renderDateWithTime}
              sorter={(a: any, b: any) => moment(a.deleteDate).unix() - moment(b.deleteDate).unix()}
            />
            <Column title="Actions" render={this.renderActions} />
          </Table>
        </div>
      </Card>
    );
  }

}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  badgeCollections: selectors.getBadgeCollections,
  status: selectors.loadBadgesStatus,
  studyId: selectors.getRequestedStudyId,
  newOrEdit: selectors.getUrlRouteNewOrEdit,
  requestedBadgeTab: selectors.getRequestedBadgeTab,
  id: selectors.getUrlNewOrEditId
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getBadgeCollections: () => dispatch(getBadgeCollectionsAsync.request()),
    deleteBadgeCollection: (badgeCollectionId: number) => dispatch(deleteBadgeCollectionAsync.request(badgeCollectionId)),
    undeleteBadgeCollection: (badgeCollectionId: number) => dispatch(undeleteBadgeCollectionAsync.request(badgeCollectionId))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BadgeCollections));
