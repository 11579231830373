import React, { Component } from 'react';
import {
  Input, Form, Checkbox
} from 'antd';
import FroalaCustomUpload from '../FroalaCustomUpload';
import '../activity.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { updateCmsEditorBeforeSave } from '../../util/Util';
import { Type } from '../ActivityFormContainer';
import { FeedbackType, OptionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  type: number;
  disabled: boolean;
  feedback: FeedbackType;
  options: OptionType[];
  updateFeedback: (f: FeedbackType) => void;
}
const itemStyle = {
  width: '100%',
  marginBottom: '10px',
  marginTop: '10px'
};

class FeedbackItem extends Component<ComponentProps, {}> {
  onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { feedback, updateFeedback } = this.props;
    e.preventDefault();
    feedback.title = e.target.value;
    updateFeedback(feedback);
  };

  onMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { feedback, updateFeedback } = this.props;
    const { value } = e.target;
    const reg = /[0-9]*(\.[0-9]*)?$/;
    if ((value.length && reg.test(value)) || value === '' || value === '-') {
      const min = parseInt(value);
      if (!isNaN(min)) {
        feedback.min = min;
      } else {
        feedback.min = undefined;
      }
    }
    updateFeedback(feedback);
  };

  onMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { feedback, updateFeedback } = this.props;
    const { value } = e.target;
    const reg = /[0-9]*(\.[0-9]*)?$/;
    if ((value.length && reg.test(value)) || value === '' || value === '-') {
      const max = parseInt(value);
      if (!isNaN(max)) {
        feedback.max = max;
      } else {
        feedback.max = undefined;
      }
    }
    updateFeedback(feedback);
  };

  onTextChange = (value: string) => {
    const { feedback, updateFeedback } = this.props;
    feedback.text = updateCmsEditorBeforeSave(value);
    updateFeedback(feedback);
  };

  onOverallChange = (e: CheckboxChangeEvent) => {
    const { feedback, updateFeedback } = this.props;
    feedback.min = 0;
    feedback.max = undefined;
    feedback.isOverallFeedback = e.target.checked;
    updateFeedback(feedback);
  };

  validateFeedbackTitle = (rule, value) => {
    const { feedback } = this.props;
    try {
      if (feedback && feedback.title && feedback.title.length === 0) {
        throw new Error('Feedback title is required.');
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  validateFeedbackText = (rule, value) => {
    const { feedback } = this.props;
    try {
      if (feedback && feedback.text.length === 0) {
        throw new Error('Feedback text is required.');
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  validateFeedbackPointRange = (rule, value) => {
    const { feedback } = this.props;
    try {
      if (feedback && feedback.min == undefined) {
        throw new Error('Feedback must have min point value defined.');
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  render() {
    // Including options in the props so that in the future we could
    // display which options would lead to the feedback based on the
    // feedback's min/max and the option's points.
    const { type, disabled, feedback } = this.props;
    const {
      text, title, min, max, uuid, isOverallFeedback, id
    } = feedback;
    // Only display min/max options for activity-level feedback,
    // not option or question level.
    const displayMinMax = (Type.zero === type && !isOverallFeedback) || Type.question === type;
    const isOverall = Type.zero === type && (isOverallFeedback || (min === 0 && max === null));
    return (
      <div className="feedback-container">
        <Item
          key={`feedback-title-${id || uuid}`}
          name={`feedback-title-${id || uuid}`}
          style={itemStyle}
          initialValue={title}
        >
          <Input
            spellCheck
            disabled={disabled}
            key={`feedback-title-input-${id || uuid}`}
            placeholder={title || 'Feedback title'}
            onChange={this.onTitleChange}
            style={{ width: '100%' }}
          />
        </Item>
        {Type.zero === type && (
          <Checkbox
            disabled={disabled}
            checked={isOverall}
            onChange={this.onOverallChange}
          >
            Overall Feedback
          </Checkbox>
        )}
        <Item
          key={`feedback-text-${id || uuid}`}
          name={`feedback-text-${id || uuid}`}
          style={itemStyle}
          validateTrigger={['onChange', 'onBlur', 'onFocus']}
          rules={[
            {
              required: true,
              message: 'Please input feedback text or delete this feedback.',
              validator: this.validateFeedbackText
            }
          ]}
          valuePropName="model"
          initialValue={text}
        >
          <FroalaCustomUpload
            disabled={disabled}
            onChange={this.onTextChange}
          />
        </Item>
        {displayMinMax && (
          <Item
            key={`feedback-range-${id || uuid}`}
            name={`feedback-range-${id || uuid}`}
            style={itemStyle}
            validateTrigger={['onChange', 'onBlur', 'onFocus']}
            rules={[
              {
                required: true,
                validator: this.validateFeedbackPointRange
              }
            ]}
            className="feedback-prop"
          >
            Point Range:
            {' '}
            <Input
              spellCheck
              disabled={disabled}
              size="small"
              value={min}
              min={0}
              onChange={this.onMinChange}
              style={{ width: '20%' }}
            />
            {' '}
            to
            {' '}
            <Input
              disabled={disabled}
              size="small"
              value={max}
              min={min}
              onChange={this.onMaxChange}
              style={{ width: '20%' }}
            />
          </Item>
        )}
      </div>
    );
  }
}

export default FeedbackItem;
