import { Select } from 'antd';
import Tab from 'antd/lib/tabs';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import TestkitRequestedTable from '../../../components/testkit/TestkitRequestedTable';
import TestkitAwaitingResultTable from '../../../components/testkit/TestkitAwaitingResultTable';
import TestkitNeedsReviewTable from '../../../components/testkit/TestkitNeedsReviewTable';
import TestkitCompletedTable from '../../../components/testkit/TestkitCompletedTable';
import * as selectors from '../../../redux/selectors';
import IApplicationState from '../../../types/state.types';
import './testkit.scss';
import { DashboardDataType } from '../../../types';

const { Option } = Select;

export enum Status {
  Requested,
  AwaitingResult,
  NeedsReview,
  Completed
}

interface StateProps {
  studyId: number,
  testkitSubpage: Optional<string>,
  dashboardData: Optional<DashboardDataType>,
}

interface ComponentProps extends StateProps, RouteComponentProps {}

class TestKitsLandingPage extends Component<ComponentProps, {}> {

  handleTabChange = (activeKey:string) => {
    const { studyId } = this.props;
    this.props.history.push(`/study/${studyId}/testkits/${activeKey}`);
  }

  render() {

    const { dashboardData } = this.props;
    const testkitSubpage = this.props.testkitSubpage === '' ? 'requested' : this.props.testkitSubpage;

    return (
      <div id="tab-bar">
        <Tab defaultActiveKey={testkitSubpage} onChange={this.handleTabChange}>
          <Tab.TabPane tab={`Requested (${dashboardData?.testkits.requestedCount})`} key="requested">
            <TestkitRequestedTable />
          </Tab.TabPane>
          <Tab.TabPane tab={`Awaiting Result (${dashboardData?.testkits.awaitingResultCount})`} key="awaiting">
            <TestkitAwaitingResultTable />
          </Tab.TabPane>
          <Tab.TabPane tab={`Needs Review (${dashboardData?.testkits.needsReviewCount})`} key="needsreview">
            <TestkitNeedsReviewTable />
          </Tab.TabPane>
          <Tab.TabPane tab={`Completed (${dashboardData?.testkits.completedCount})`} key="completed">
            <TestkitCompletedTable />
          </Tab.TabPane>
        </Tab>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  studyId: selectors.getRequestedStudyId,
  testkitSubpage: selectors.getRequestedTestkitTab,
  dashboardData: selectors.getDashboardData
});

export default connect(mapStateToProps, null)(TestKitsLandingPage);
