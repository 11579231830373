import React, { ChangeEvent } from 'react';
import { Input, InputNumber, Form } from 'antd';
import '../activity.scss';
import { QuestionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  disabled: boolean;
  question: QuestionType;
  onTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  updateQuestion: (q: QuestionType) => void;
}

const FillInTheBlankQuestion = (props: ComponentProps) => {
  const {
    question, onTextChange, updateQuestion, disabled
  } = props;
  const {
    text, numBlanks, id, uuid
  } = question;
  const onNumBlanksChange = (value: string | number | undefined) => {
    if (value) {
      question.numBlanks = value as number;
      updateQuestion(question);
    }
  };
  return (
    <div>
      <Item
        key={`fitb-question-text-${id || uuid}`}
        name={`fitb-question-text-${id || uuid}`}
        rules={[
          {
            required: true,
            message: 'Question must have text.'
          }
        ]}
        initialValue={text}
      >
        <Input
          spellCheck
          disabled={disabled}
          placeholder="Question text"
          onChange={onTextChange}
        />
      </Item>
      <Item
        key={`fitb-question-numOfBlanks-${id || uuid}`}
        name={`fitb-question-numOfBlanks-${id || uuid}`}
        rules={[
          {
            required: true,
            message:
              'Fill in the blank questions must have number of blanks defined.'
          }
        ]}
        initialValue={numBlanks}
        label="Number of Blanks:"
      >
        <InputNumber disabled={disabled} min={0} onChange={onNumBlanksChange} />
      </Item>
    </div>
  );
};

export default FillInTheBlankQuestion;
