import React, { ChangeEvent } from 'react';
import {
  Input
} from 'antd';
import '../activity.scss';
import { OptionType } from '../../../types/serverTypes/activityTypes';

interface ComponentProps {
  disabled: boolean;
  option: OptionType;
  onTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setPoints: (value: number) => void;
}

const CategoryOption = (props: ComponentProps) => {
  const { disabled, option, onTextChange } = props;
  const { text } = option;
  return (
    <Input
      spellCheck
      disabled={disabled}
      placeholder="Option text"
      value={text}
      onChange={onTextChange}
      style={{ width: '100%' }}
    />
  );
};

export default CategoryOption;
