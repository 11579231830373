import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import {NotificationSubscriptionType, NotificationType} from "../../types/serverTypes/notificationTypes";
import {FormType} from "../../types/serverTypes/formTypes";

export const getParticipantNotificationsAsync = createAsyncAction(
  'hmp/admin/GET_PARTICIPANT_NOTIFICATIONS_REQUEST',
  'hmp/admin/GET_PARTICIPANT_NOTIFICATIONS_SUCCESS',
  'hmp/admin/GET_PARTICIPANT_NOTIFICATIONS_FAILURE'
)<number, Optional<NormalizedType<NotificationType>>, Error>();

export const getMyAdminNotificationSubscriptionsAsync = createAsyncAction(
  'hmp/admin/GET_MY_ADMIN_NOTIFICATION_SUBSCRIPTIONS_REQUEST',
  'hmp/admin/GET_MY_ADMIN_NOTIFICATION_SUBSCRIPTIONS_SUCCESS',
  'hmp/admin/GET_MY_ADMIN_NOTIFICATION_SUBSCRIPTIONS_FAILURE'
)<void, Optional<NormalizedType<NotificationType>>, Error>();

export const updateNotificationSubscriptionAsync = createAsyncAction(
  'hmp/admin/UPDATE_NOTIFICATION_SUBSCRIPTION_REQUEST',
  'hmp/admin/UPDATE_NOTIFICATION_SUBSCRIPTION_SUCCESS',
  'hmp/admin/UPDATE_NOTIFICATION_SUBSCRIPTION_FAILURE'
)<NotificationSubscriptionType, Optional<NormalizedType<NotificationType>>, Error>();

export const sendAdHocAdminQnaNotificationAsync = createAsyncAction(
  'hmp/admin/SEND_AD_HOC_ADMIN_QNA_NOTIFICATION_REQUEST',
  'hmp/admin/SEND_AD_HOC_ADMIN_QNA_NOTIFICATION_SUCCESS',
  'hmp/admin/SEND_AD_HOC_ADMIN_QNA_NOTIFICATION_FAILURE'
)<{userId: number, qnaPostId: number}, Optional<NormalizedType<NotificationType>>, Error>();


export const updateForms = createStandardAction('hmp/admin/UPDATE_FORMS')<Optional<NormalizedType<FormType>>>();

const notificationsActions = {
  getParticipantNotificationsAsync,
  getMyAdminNotificationSubscriptionsAsync,
  updateNotificationSubscriptionAsync,
  sendAdHocAdminQnaNotificationAsync
};

export type NotificationsActionTypes = ActionType<typeof notificationsActions>;
