import { FileTextOutlined } from '@ant-design/icons';
import { Spin, Table, Tooltip } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { getNotificationsForParticipant } from '../../service/notification/adminNotificationService';
import { NotificationType } from '../../types/serverTypes/notificationTypes';

interface NotificationLogTableProp {
  participantId: number;
}

const renderDateWithTime = (value: any, row: any) => {
  return moment(value).format('YYYY-MM-DD HH:SSa');
};
const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a: any, b: any) => a.id - b.id,
  },
  {
    title: 'Create Date',
    dataIndex: 'createDate',
    key: 'createDate',
    render: renderDateWithTime,
    sorter: (a: any, b: any) =>
      moment(a.createDate).unix() - moment(b.createDate).unix(),
    defaultSortOrder: 'descend' as SortOrder,
  },
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    sorter: (a: any, b: any) => `${a.label}`.localeCompare(b.label),
  },
  {
    title: 'Message',
    dataIndex: 'body',
    key: 'body',
    render: (value: any, row: any) => (
      <Tooltip title={<div dangerouslySetInnerHTML={{ __html: row.body }} />}>
        <FileTextOutlined />
      </Tooltip>
    ),
  },
  {
    title: 'Method',
    dataIndex: 'method',
    key: 'method',
    render: (value: any, row: any) => {
      return (value as string).replace('_', ' ');
    },
  },
  {
    title: 'Additional Info',
    dataIndex: 'meta',
    key: 'meta',
    render: (value: any, row: any) => {
      let output: any = [];
      if (value) {
        let valueObj = JSON.parse(value);
        for (const [key, value] of Object.entries(valueObj)) {
          if (!!value) {
            output.push(
              <span key={key}>
                {key}: {value}
                {` `}
              </span>
            );
          }
        }
      }
      return <span>{output}</span>;
    },
  },
];

const NotificationLogTable = (props: NotificationLogTableProp) => {
  const participantNotifications = useQuery<NotificationType[]>(
    ['participantNotificationLog', props.participantId],
    getNotificationsForParticipant
  );
  if (participantNotifications.isLoading) {
    return <Spin />;
  }
  return (
    <Table
      columns={columns}
      dataSource={participantNotifications.data}
      pagination={{ pageSize: 20 }}
      rowClassName={rowClassName}
      rowKey="id"
    />
  );
};

export default NotificationLogTable;
