import { SaveFilled, DeleteFilled } from '@ant-design/icons';
import {
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Popconfirm,
  message,
} from 'antd';
import { enumKeys } from '../util/Util';
import { AvatarPaletteTypes } from '../../constant/AvatarConstant';
import React from 'react';
import { AvatarPartCategoryType } from '../../types/serverTypes/avatarTypes';
import { useMutation, useQueryClient } from 'react-query';
import { deleteAvatarCategory } from '../../service/avatar/avatarService';
const { Option } = Select;

interface AvatarCustomCategoryEditProp {
  selectedCategory: AvatarPartCategoryType;
  onUpdateCategory: (data: any) => void;
  onSaveAvatarCategory: () => void;
  canDelete: boolean;
  onDelete: () => void;
}

const AvatarCustomCategoryEdit = (props: AvatarCustomCategoryEditProp) => {
  const getOptions = () => {
    let options: JSX.Element[] = [];
    for (const value of enumKeys(AvatarPaletteTypes)) {
      options.push(
        <Option key={value} value={AvatarPaletteTypes[value]}>
          {value}
        </Option>
      );
    }
    return options;
  };

  const onDelete = () => {
    avatarCategoryMutation.mutate(props.selectedCategory);
    props.onDelete();
  };
  const queryClient = useQueryClient();
  const avatarCategoryMutation = useMutation(deleteAvatarCategory, {
    onError: (error, variables, context) => {
      message.error('Error in deleting avatar category.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('avatarCategories');
      message.success('Avatar category successfully deleted.');
    },
  });
  return (
    <div key={props.selectedCategory.id}>
      <Form
        name="basic"
        initialValues={{
          label: props.selectedCategory.label,
          heightVal: props.selectedCategory.heightVal,
          widthVal: props.selectedCategory.widthVal,
          topVal: props.selectedCategory.topVal,
          leftVal: props.selectedCategory.leftVal,
          paletteType: props.selectedCategory.paletteType,
        }}
        autoComplete="off"
      >
        <Form.Item label="Label" name="label">
          <Input
            value={props.selectedCategory.label}
            onChange={(e) => {
              props.onUpdateCategory({ label: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item label="Palette Type" name="paletteType">
          <Select
            value={props.selectedCategory.paletteType}
            onChange={(value) => {
              props.onUpdateCategory({ paletteType: value });
            }}
          >
            {getOptions()}
          </Select>
        </Form.Item>
        <Form.Item name="isRequired">
          <Checkbox
            onChange={(e) => {
              props.onUpdateCategory({ isRequired: e.target.checked });
            }}
            checked={props.selectedCategory.isRequired}
          >
            Is Required?
          </Checkbox>
        </Form.Item>
        <Form.Item name="isCustomizable">
          <Checkbox
            onChange={(e) => {
              props.onUpdateCategory({ isCustomizable: e.target.checked });
            }}
            checked={props.selectedCategory.isCustomizable}
          >
            Is Customizable?
            <br />
            This mean color(s) can be changed.
          </Checkbox>
        </Form.Item>
        <Form.Item name="isOnlyColorCustomizable">
          <Checkbox
            onChange={(e) => {
              props.onUpdateCategory({
                isOnlyColorCustomizable: e.target.checked,
              });
            }}
            checked={props.selectedCategory.isOnlyColorCustomizable}
          >
            Is Only Color Customizable? (e.g.: body)
          </Checkbox>
        </Form.Item>
        <Form.Item label="Height" name="heightVal">
          <Input
            value={props.selectedCategory.heightVal}
            onChange={(e) => {
              props.onUpdateCategory({
                heightVal: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Width" name="widthVal">
          <Input
            value={props.selectedCategory.widthVal}
            onChange={(e) => {
              props.onUpdateCategory({
                widthVal: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Top Offset" name="topVal">
          <Input
            value={props.selectedCategory.topVal}
            onChange={(e) => {
              props.onUpdateCategory({
                topVal: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Left Offset" name="leftVal">
          <Input
            value={props.selectedCategory.leftVal}
            onChange={(e) => {
              props.onUpdateCategory({
                leftVal: e.target.value,
              });
            }}
          />
        </Form.Item>
      </Form>
      <Button
        type="primary"
        onClick={props.onSaveAvatarCategory}
        icon={<SaveFilled />}
        style={{ marginRight: 15 }}
      >
        Save
      </Button>
      {props.canDelete && (
        <Popconfirm
          title="Are you sure to delete this category?"
          onConfirm={onDelete}
          okText="Yes"
          cancelText="No"
        >
          <Button icon={<DeleteFilled />} style={{ marginRight: 15 }}>
            Delete
          </Button>
        </Popconfirm>
      )}
    </div>
  );
};

export default AvatarCustomCategoryEdit;
