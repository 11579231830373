import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import {
  ArticleActionTypes,
  IArticleUIState,
  updateNewArticleId,
  updateSelectedArticleIds,
  updateSelectedTopicIds,
  updateIncludeDeleted,
  updateSearchTerm,
} from "./article.types";

export const initialArticleUIState = {
  articleIds: [],
  newArticleId: -1,
  selectedTopicIds: [],
  includeDeleted: true,
  searchTerm: "",
};
export const articleUIReducer: Reducer<IArticleUIState, ArticleActionTypes> = (state: IArticleUIState = initialArticleUIState, action: ArticleActionTypes) => {

  switch (action.type) {
    case getType(updateSelectedArticleIds):
      return {
        ...state,
        articleIds: action.payload,
      };
    case getType(updateNewArticleId):
      return {
        ...state,
        newArticleId: action.payload,
      };
    case getType(updateSelectedTopicIds):
      return {
        ...state,
        selectedTopicIds: action.payload,
      };
    case getType(updateIncludeDeleted):
      return {
        ...state,
        includeDeleted: action.payload,
      };
    case getType(updateSearchTerm):
      return {
        ...state,
        searchTerm: action.payload,
      };
    default:
      return { ...state };
  }
};

export default articleUIReducer;
