import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  AvatarActionTypes, getAvatarAsync, getAvatarsAsync, updateAvatars
} from './avatar.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import {AvatarType} from "../../types/serverTypes/avatarTypes";

const updateAvatarsReducer = produce((draft, avatars) => {
  if (!avatars) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(avatars), Number));
  const ids = Object.keys(avatars);
  for (const id of ids) {
    draft.byId[id] = avatars[id];
  }
});

export const avatarReducer = (state: NormalizedState<AvatarType> = initialNormalizedState, action: AvatarActionTypes) => {

  switch (action.type) {
    case getType(getAvatarAsync.success): return updateAvatarsReducer(state, action.payload);
    case getType(getAvatarsAsync.success): return updateAvatarsReducer(state, action.payload);
    case getType(updateAvatars): return updateAvatarsReducer(state, action.payload);
    default: return state;
  }
};

export default avatarReducer;
