import {
  Card, Tabs, Button, Dropdown, Menu
} from 'antd';
import Icon, { DownOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as selectors from '../../../redux/selectors';
import IApplicationState from '../../../types/state.types';
import { IApiRequestState } from '../../../types/api.types';
import FormTable from '../../../components/form/FormTable';
import { getFormsAsync } from '../../../redux/form/form.types';

import './form.scss';
import { FormType } from '../../../types/serverTypes/formTypes';

const gridStyle = {
  width: '50%',
  height: '50%',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
};

const iconStyle = {
  background: '$font-color-primary'
};

const Tab = Tabs;

interface StateProps {
  loadFormsStatus: IApiRequestState,
  forms: Optional<FormType[]>,
  currentPath: string
}

interface DispatchProps {
  loadForms: typeof getFormsAsync.request,
}

interface ComponentProps extends StateProps, DispatchProps {}

const validFormTypes = ['ADVERSE_EVENT', 'EARLY_TERMINATION', 'PROTOCOL_DEVIATION', 'SOCIAL_HARM'];

class Form extends Component<ComponentProps, {}> {
  readonly state = {
    studyId: 1, // TODO: get study id from the redux store
    activeForm: ''
  };

  componentDidMount(): void {
    const { loadForms } = this.props;
    loadForms(this.state.studyId);
  }

  setActiveForm = (type: string) => {
    if (!validFormTypes.includes(type)) {
      type = '';
    }
    this.setState({
      activeForm: type
    });
  };

  closeForm = () => {
    this.setState({
      activeForm: ''
    });
  };

  handleMenuClick(e: any) {
    this.setState({
      activeForm: e.key
    });
  }

  formOptions = (<Menu onClick={this.handleMenuClick.bind(this)} />);

  renderFormModal = (type: string) => {
    switch (type) {
      default:
        return <div />;
    }
  };

  render() {
    const { activeForm } = this.state;
    const { forms } = this.props;

    return (
      <div id="form">
        {this.renderFormModal(activeForm)}
        <div id="tab-bar">
          <Card
            style={{ background: 'white' }}
            title={(
              <div className="form-title">
                <h1>Forms</h1>
                <Dropdown overlay={this.formOptions}>
                  <Button type="primary">
                    + Add Form
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            )}
          >
            <FormTable forms={forms} />
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  const { pathname } = state.router.location;
  return {
    loadFormsStatus: selectors.loadRequestedFormsStatus(state),
    forms: selectors.getRequestedForms(state),
    currentPath: pathname
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadForms: (studyId: number) => dispatch(getFormsAsync.request(studyId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
