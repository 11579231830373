import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import {BadgeCollectionType, BadgeCollectionTypeType, BadgeGroupType, BadgeType} from "../../types/serverTypes";

export const getBadgeCollectionsAsync = createAsyncAction(
  'hmp/admin/GET_BADGE_COLLECTIONS_REQUEST',
  'hmp/admin/GET_BADGE_COLLECTIONS_SUCCESS',
  'hmp/admin/GET_BADGE_COLLECTIONS_FAILURE'
)<void, Optional<NormalizedType<BadgeCollectionType>>, Error>();

export const deleteBadgeCollectionAsync = createAsyncAction(
  'hmp/admin/DELETE_BADGE_COLLECTIONS_REQUEST',
  'hmp/admin/DELETE_BADGE_COLLECTIONS_SUCCESS',
  'hmp/admin/DELETE_BADGE_COLLECTIONS_FAILURE'
)<number, Optional<NormalizedType<BadgeType>>, Error>();

export const undeleteBadgeCollectionAsync = createAsyncAction(
  'hmp/admin/UNDELETE_BADGES_REQUEST',
  'hmp/admin/UNDELETE_BADGES_SUCCESS',
  'hmp/admin/UNDELETE_BADGES_FAILURE'
)<number, Optional<NormalizedType<BadgeType>>, Error>();

export const getBadgeGroupsAsync = createAsyncAction(
  'hmp/admin/GET_BADGE_GROUPS_REQUEST',
  'hmp/admin/GET_BADGE_GROUPS_SUCCESS',
  'hmp/admin/GET_BADGE_GROUPS_FAILURE'
)<void, Optional<NormalizedType<BadgeGroupType>>, Error>();

export const saveBadgeGroupAsync = createAsyncAction(
  'hmp/admin/SAVE_BADGE_GROUP_REQUEST',
  'hmp/admin/SAVE_BADGE_GROUP_SUCCESS',
  'hmp/admin/SAVE_BADGE_GROUP_FAILURE'
)<BadgeGroupType, Optional<NormalizedType<BadgeGroupType>>, Error>();

export const saveBadgeCollectionAsync = createAsyncAction(
  'hmp/admin/SAVE_BADGE_COLLECTION_REQUEST',
  'hmp/admin/SAVE_BADGE_COLLECTION_SUCCESS',
  'hmp/admin/SAVE_BADGE_COLLECTION_FAILURE'
)<BadgeCollectionType, Optional<NormalizedType<BadgeCollectionType>>, Error>();

export const deleteBadgeGroupAsync = createAsyncAction(
  'hmp/admin/DELETE_BADGE_GROUPS_REQUEST',
  'hmp/admin/DELETE_BADGES_GROUP_SUCCESS',
  'hmp/admin/DELETE_BADGES_GROUP_FAILURE'
)<number, Optional<NormalizedType<BadgeGroupType>>, Error>();

export const undeleteBadgeGroupAsync = createAsyncAction(
  'hmp/admin/UNDELETE_BADGE_GROUPS_REQUEST',
  'hmp/admin/UNDELETE_BADGES_GROUP_SUCCESS',
  'hmp/admin/UNDELETE_BADGES_GROUP_FAILURE'
)<number, Optional<NormalizedType<BadgeGroupType>>, Error>();

export const getBadgeCollectionTypesAsync = createAsyncAction(
  'hmp/admin/GET_BADGE_COLLECTION_TYPES_REQUEST',
  'hmp/admin/GET_BADGE_COLLECTION_TYPES_SUCCESS',
  'hmp/admin/GET_BADGE_COLLECTION_TYPES_FAILURE'
)<void, Optional<NormalizedType<BadgeCollectionTypeType>>, Error>();

export const getParticipantBadgesAsync = createAsyncAction(
  "hmp/admin/GET_PARTICIPANT_BADGES_REQUEST",
  "hmp/admin/GET_PARTICIPANT_BADGES_SUCCESS",
  "hmp/admin/GET_PARTICIPANT_BADGES_FAILURE"
)<number, Optional<NormalizedType<BadgeType>>, Error>();

export const awardParticipantBadgeAsync = createAsyncAction(
  'hmp/admin/AWARD_PARTICIPANT_BADGE_REQUEST',
  'hmp/admin/AWARD_PARTICIPANT_BADGE_SUCCESS',
  'hmp/admin/AWARD_PARTICIPANT_BADGE_FAILURE'
)<{participantId: number, badgeCollectionId: number}, Optional<NormalizedType<BadgeType>>, Error> ();


const badgesActions = {
  getBadgeCollectionsAsync,
  getBadgeGroupsAsync,
  getBadgeCollectionTypesAsync,
  deleteBadgeCollectionAsync,
  deleteBadgeGroupAsync,
  undeleteBadgeCollectionAsync,
  undeleteBadgeGroupAsync,
  saveBadgeGroupAsync,
  saveBadgeCollectionAsync,
  getParticipantBadgesAsync,
  awardParticipantBadgeAsync
};

export type BadgesActionTypes = ActionType<typeof badgesActions>;
