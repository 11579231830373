import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Select, Input } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import * as selectors from '../../../redux/selectors';
import '../activity.scss';
import {
  getActivityCategoriesAsync,
  getActivityTypesAsync
} from '../../../redux/activities/activities.types';
import IApplicationState from '../../../types/state.types';
import FroalaCustomUpload from '../FroalaCustomUpload';
import ActivityItemLabel from '../ActivityItemLabel';
import { updateCmsEditorBeforeSave } from '../../util/Util';
import { ActivityFormStatus } from '../ActivityFormContainer';
import { ActivityType, ActivityTypeType, CategoryType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;
const { Option } = Select;

interface StateProps {
  categories: Optional<CategoryType[]>;
  types: Optional<ActivityTypeType[]>;
}

interface DispatchProps {
  loadCategories: typeof getActivityCategoriesAsync.request;
  loadTypes: typeof getActivityTypesAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {
  creative: boolean;
  editable: boolean;
  setStatus: (status: ActivityFormStatus) => void;
  setActivity: (props: any) => void;
  setActiveForm: (form: any) => void;
  activity: ActivityType;
}

interface ComponentState {}

const itemStyle = {
  width: '100%',
  marginBottom: '10px',
  marginTop: '10px'
};

class ActivityDetailForm extends Component<ComponentProps, ComponentState> {
  form = React.createRef<FormInstance>();

  componentDidMount() {
    const {
      setStatus, loadCategories, loadTypes, setActiveForm
    } = this.props;
    setStatus(ActivityFormStatus.PROCESS);
    setActiveForm(this.form.current);
    loadCategories();
    loadTypes();
  }

  componentDidUpdate() {
    const { setStatus, activity } = this.props;
    const {
      typeId, category, title, description, intro
    } = activity;
    if (
      typeId > -1
      && category
      && title.length > 0
      && description.length > 0
      && intro.length > 0
    ) {
      setStatus(ActivityFormStatus.FINISH);
    }
  }

  // Action Handlers
  onChange = (field: string, state: any) => {
    const { setActivity } = this.props;
    setActivity(state);
  };

  onIntroChange = (intro: string) => {
    this.onChange('intro', {
      intro: updateCmsEditorBeforeSave(intro)
    });
  };

  // Custom Field Validators
  validateIntro = (rule, value) => {
    const { activity } = this.props;
    const { intro } = activity;
    try {
      if (intro.length === 0) {
        throw new Error('Introduction is required.');
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  // This validator is a hotfix to resolve an issue where
  // navigating to another form step and back causes the
  // description field in the form to be undefined.
  validateDescription = (rule, value) => {
    const { activity } = this.props;
    const { description } = activity;
    try {
      if (description.length === 0) {
        throw new Error('Description is required.');
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  validateTitle = (rule, value) => {
    const { activity } = this.props;
    const { title } = activity;
    try {
      if (title.length === 0) {
        throw new Error('Title is required.');
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  // Renderers
  renderType = (value: string) => {
    switch (value) {
      case 'quiz':
        return 'Quizzes';
      case 'category':
        return 'Break it down';
      case 'category no-answer':
        return 'Break it down (no answer)';
      case 'cyoa':
        return 'Call the shots';
      case 'fill in the blank':
        return 'Fill it in';
      case 'goals':
        return 'Goals';
      case 'ranking':
        return 'Sort it out';
      case 'screener':
        return 'Assessments';
    }
  };

  capitalize = (s: string): string => {
    return s
      ? s
        .split(' ')
        .map((word) => word[0].toUpperCase() + word.substring(1))
        .join(' ')
      : s;
  };

  characterLimit = (s: string, limit: number): number => {
    return s ? (s.length < limit ? limit - s.length : 0) : 0;
  };

  render() {
    const {
      activity, categories, types, creative, editable
    } = this.props;
    const {
      title, description, prompt, intro
    } = activity;
    return (
      <div className="activity-form">
        <Form ref={this.form} key="form" layout="vertical" colon={false}>
          <Item
            key="typeId"
            name="typeId"
            label={(
              <ActivityItemLabel
                label="Activity Type:"
                tooltip="Type determines what kind of activity you will create."
              />
            )}
            style={itemStyle}
            rules={[{ required: true, message: 'Type is required.' }]}
            initialValue={activity.typeId}
          >
            <Select
              disabled={!creative && !editable}
              onChange={(val) => this.onChange('typeId', { typeId: val })}
              placeholder="Type"
              style={{ width: 220 }}
            >
              {types?.map((t) => (
                <Option key={t.id} value={t.id}>
                  {this.renderType(t.type)}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            key="category"
            name="category"
            label="Activity Category:"
            style={itemStyle}
            rules={[{ required: true, message: 'Category is required.' }]}
            initialValue={activity.category}
          >
            <Select
              disabled={!creative && !editable}
              onChange={(val) => this.onChange('category', { category: val })}
              placeholder="Category"
              style={{ width: 180 }}
            >
              {categories?.map((cat) => (
                <Option key={cat.id} value={cat.id}>
                  {cat.category}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            key="title"
            name="title"
            label="Title:"
            style={itemStyle}
            rules={[
              {
                required: true,
                message: 'Title is required.',
                validator: this.validateTitle
              }
            ]}
            initialValue={title}
          >
            <>
              <Input
                spellCheck
                disabled={!creative && !editable}
                placeholder="Name the activity"
                maxLength={200}
                defaultValue={title}
                onChange={(e) => this.onChange('title', { title: e.target.value })}
              />
              <span>
                (
                {this.characterLimit(title, 200)}
                {' '}
                characters left.)
              </span>
            </>
          </Item>
          <Item
            key="description"
            name="description"
            label="Description:"
            style={itemStyle}
            rules={[{ required: true, validator: this.validateDescription }]}
          >
            <>
              <Input
                spellCheck
                disabled={!creative && !editable}
                placeholder="Blurb shown on main list"
                maxLength={400}
                defaultValue={description}
                onChange={(e) => this.onChange('description', {
                  description: e.target.value
                })}
              />
              <span>
                (
                {this.characterLimit(description, 400)}
                {' '}
                characters left.)
              </span>
            </>
          </Item>
          <Item
            key="prompt"
            name="prompt"
            label={(
              <ActivityItemLabel
                label="Prompt:"
                tooltip="The text that appears on the screen with each question."
              />
            )}
            style={itemStyle}
          >
            <>
              <Input
                spellCheck
                disabled={!creative && !editable}
                placeholder="Prompt to appear above questions"
                maxLength={200}
                defaultValue={prompt}
                onChange={(e) => this.onChange('prompt', { prompt: e.target.value })}
              />
              <span>
                (
                {this.characterLimit(prompt, 200)}
                {' '}
                characters left.)
              </span>
            </>
          </Item>
          <Item
            key="intro"
            name="intro"
            label="Introduction:"
            style={itemStyle}
            rules={[{ required: true, validator: this.validateIntro }]}
            valuePropName="model"
            initialValue={intro}
          >
            <FroalaCustomUpload
              disabled={!creative && !editable}
              onChange={this.onIntroChange}
            />
          </Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    categories: selectors.getCategories(state),
    types: selectors.getTypes(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadCategories: () => dispatch(getActivityCategoriesAsync.request()),
    loadTypes: () => dispatch(getActivityTypesAsync.request())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDetailForm);
