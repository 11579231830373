import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  getStringLookupsAsync,
  getStringLookupAsync,
  updateStringLookupAsync,
} from './stringLookups.types';
import {
  initialNormalizedState,
  NormalizedState,
} from '../../types/state.types';
import { StringLookupType } from '../../types/serverTypes';

const updateStringLookupsReducer = produce((draft, stringLookups) => {
  if (!stringLookups) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(stringLookups), Number));
  const ids = Object.keys(stringLookups);
  for (const id of ids) {
    draft.byId[id] = stringLookups[id];
  }
});

export const stringLookupsReducer = (
  state: NormalizedState<StringLookupType> = initialNormalizedState,
  action: any
) => {
  switch (action.type) {
    case getType(getStringLookupsAsync.success):
      return updateStringLookupsReducer(state, action.payload);
    case getType(getStringLookupAsync.success):
      return updateStringLookupsReducer(state, action.payload);
    case getType(updateStringLookupAsync.success):
      return updateStringLookupsReducer(state, action.payload);
    default:
      return state;
  }
};

export default stringLookupsReducer;
