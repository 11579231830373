import {AxiosResponse} from 'axios';
import {normalize} from 'normalizr';
import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {getType} from 'typesafe-actions';
import axios from '../api';
import {entitySchema} from '../schema';
import { getParticipantUserSurveysAsync, getUserSurveysAsync } from './userSurvey.types';
import {NormalizerResult} from "../../types";

const getUserSurveysByParticipant = (participantId: number) => {
  return axios({
    method: 'get',
    url: `/a/survey/${participantId}`
  });
};

function* getUserSurveysByParticipantHandler(action: ReturnType<typeof getParticipantUserSurveysAsync.request>): Generator {

  try {
    const participantId: number = action.payload;
    const response: AxiosResponse = (yield call(getUserSurveysByParticipant, participantId)) as AxiosResponse;
    const participantUserSurvey = response.data ? response.data : [];
    const { entities } = normalize(participantUserSurvey, entitySchema.userSurveys) as NormalizerResult;
    const { userSurveys } = entities;

    yield put(getParticipantUserSurveysAsync.success(userSurveys));
  } catch (error) {
    yield put(getParticipantUserSurveysAsync.failure(error));
  }
}

function* getUserSurveysByParticipantWatcher() {
  yield takeLatest(getType(getParticipantUserSurveysAsync.request), getUserSurveysByParticipantHandler);
}

const getUserSurveys = () => {
  return axios({
    method: 'get',
    url: `/a/survey`
  });
};

function* getUserSurveysHandler(action: ReturnType<typeof getUserSurveysAsync.request>): Generator {

  try {
    const response: AxiosResponse = (yield call(getUserSurveys)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.userSurveys) as NormalizerResult;
    const { userSurveys } = entities;

    yield put(getUserSurveysAsync.success(userSurveys));
  } catch (error) {
    yield put(getUserSurveysAsync.failure(error));
  }
}

function* getUserSurveysWatcher() {
  yield takeLatest(getType(getUserSurveysAsync.request), getUserSurveysHandler);
}

export default function* userSurveySaga() {
  yield all([
    fork(getUserSurveysByParticipantWatcher),
    fork(getUserSurveysWatcher)
  ]);
}

