import React, { Component } from 'react';
import { connect } from 'react-redux';
import userManager from '../../oidc/UserManager';
import * as selectors from '../../redux/selectors';
import IApplicationState from '../../types/state.types';

interface StateProps {
}

class Logout extends Component<StateProps, {}> {

  onLoginButtonClick = (event: { stopPropagation: any; }) => {
    event.stopPropagation;
    userManager.signinRedirect('/');
  }

  render() {
    return (
      <div>
        <p>You have successfully logged out of HMP!</p>
        <p>Click here to log back in again.</p>
        <button onClick={this.onLoginButtonClick}>Login</button>
      </div>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
  };
};

export default connect(mapStateToProps)(Logout);
