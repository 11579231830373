import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  Card,
  message,
  Select,
  Form,
  Input,
  Checkbox,
  Button,
  Modal,
} from 'antd';
import IApplicationState from '../../../types/state.types';
import './adminManagement.scss';
import {
  AdminUserType,
  RoleType,
  UserRoleType,
} from '../../../types/serverTypes/adminTypes';
import * as selectors from '../../../redux/selectors';
import Username from '../../../components/username/Username';
import EditableTable, {
  ColumnData,
} from '../../../components/table/EditableTable';
import axios from '../../../redux/api';
import {
  AdminPasswordArguments,
  DeleteAdminUserArguments,
  deleteAdminUserAsync,
  getAdminAsync,
  getAdminRolesAsync,
  updateAdminPasswordAsync,
  UpdateAdminUserArguments,
  updateAdminUserAsync,
} from '../../../redux/user/user.types';
import { clearStatus } from '../../../redux/api/api.types';
import { IApiRequestStatus } from '../../../types/api.types';
import { getType } from 'typesafe-actions';
import { renderDateWithTime } from '../../../components/util/Util';
import { faKey, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import AntdIcon from '../../../components/antdIcon/AntdIcon';
import ReactTelInput from 'react-international-telephone-input/lib/withStyles';
const flagsImagePath = require('../../../assets/images/flags.png');
const { Option } = Select;
const { confirm } = Modal;

interface StateProps {
  admin: Optional<AdminUserType[]>;
  roles: Optional<RoleType[]>;
  user: Optional<AdminUserType>;
  studyId: number;
  updateAdminPasswordStatus: IApiRequestStatus;
  updateAdminUserStatus: IApiRequestStatus;
  deleteAdminUserStatus: IApiRequestStatus;
  hasStudyManagerRole: boolean;
}

interface DispatchProps {
  updatePassword: typeof updateAdminPasswordAsync.request;
  updateAdminUser: typeof updateAdminUserAsync.request;
  deleteAdminUser: typeof deleteAdminUserAsync.request;
  getAdmin: typeof getAdminAsync.request;
  getAdminRoles: typeof getAdminRolesAsync.request;
  clearLoadStatus: typeof clearStatus;
}

interface ComponentProps extends StateProps, DispatchProps {}

interface RoleUpdateType {
  userId: number;
  roleIds: number[];
}

interface ComponentState {
  columns: ColumnData[];
  isPasswordResetVisible: boolean;
  roleUpdate?: RoleUpdateType;
  selectedRowKeys: number[];
}

class AdminManagementLandingPage extends Component<
  ComponentProps,
  ComponentState
> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isPasswordResetVisible: false,
      columns: [
        {
          key: 'id-col',
          title: 'ID',
          dataIndex: 'id',
        },
        {
          key: 'username-col',
          title: 'Username',
          dataIndex: 'username',
        },
        {
          key: 'firstName-col',
          title: 'First Name',
          dataIndex: 'firstName',
        },
        {
          key: 'lastName-col',
          title: 'Last Name',
          dataIndex: 'lastName',
        },
        {
          key: 'roles-col',
          title: 'Roles',
          dataIndex: 'id',
          render: this.renderRoles,
        },
        {
          key: 'participants-col',
          title: 'Participants',
          dataIndex: 'id',
          render: this.renderParticipants,
        },
        {
          key: 'email-col',
          title: 'Email',
          dataIndex: 'email',
        },
        {
          key: 'mobile-col',
          title: 'Mobile',
          dataIndex: 'mobile',
        },
        {
          key: 'deleteDate-col',
          title: 'Delete Date',
          dataIndex: 'deleteDate',
          render: (date) => renderDateWithTime(date),
        },
      ],
    };
    props.getAdmin();
    props.getAdminRoles();
  }

  componentDidMount() {
    // If current user has the Study Manager role, update columns to be editable
    if (this.props.hasStudyManagerRole) {
      this.setState({
        columns: [
          {
            key: 'id-col',
            title: 'ID',
            dataIndex: 'id',
          },
          {
            key: 'username-col',
            title: 'Username',
            dataIndex: 'username',
            editable: true,
            required: true,
            validator: async (rule, value) => {
              const { studyId } = this.props;
              if (!value || value.length === 0) {
                return Promise.reject('Username is a required field.');
              }
              const isUsernameAvailable = await axios({
                method: 'get',
                url: `/a/usmg/availability/username/${value}?isAdminUser=true&studyId=${studyId}`,
              });
              if (!isUsernameAvailable.data) {
                return Promise.reject('This username is already in-use.');
              }
              return Promise.resolve();
            },
          },
          {
            key: 'firstName-col',
            title: 'First Name',
            dataIndex: 'firstName',
            editable: true,
            required: true,
          },
          {
            key: 'lastName-col',
            title: 'Last Name',
            dataIndex: 'lastName',
            editable: true,
          },
          {
            key: 'roles-col',
            title: 'Roles',
            dataIndex: 'roles',
            render: this.renderRoles,
            editable: true,
            editRender: this.renderEditRoles,
          },
          {
            key: 'participants-col',
            title: 'Participants',
            dataIndex: 'id',
            render: this.renderParticipants,
          },
          {
            key: 'email-col',
            title: 'Email',
            dataIndex: 'email',
            editable: true,
            required: true,
          },
          {
            key: 'mobile-col',
            title: 'Mobile',
            dataIndex: 'mobile',
            editable: true,
            editRender: this.renderEditMobile,
          },
          {
            key: 'deleteDate-col',
            title: 'Delete Date',
            dataIndex: 'deleteDate',
            render: (date) => renderDateWithTime(date),
          },
        ],
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      clearLoadStatus,
      updateAdminPasswordStatus,
      updateAdminUserStatus,
      deleteAdminUserStatus,
    } = this.props;
    if (
      !prevProps.updateAdminPasswordStatus.isError &&
      updateAdminPasswordStatus.isError
    ) {
      message.error(`Error while updating admin password.`);
      clearLoadStatus(getType(updateAdminPasswordAsync.failure));
    } else if (
      !prevProps.updateAdminPasswordStatus.isSuccess &&
      updateAdminPasswordStatus.isSuccess
    ) {
      message.success('Admin password successfully updated.');
      clearLoadStatus(getType(updateAdminPasswordAsync.success));
    }
    if (
      !prevProps.updateAdminUserStatus.isError &&
      updateAdminUserStatus.isError
    ) {
      message.error(`Error while updating admin user.`);
      clearLoadStatus(getType(updateAdminUserAsync.failure));
    } else if (
      !prevProps.updateAdminUserStatus.isSuccess &&
      updateAdminUserStatus.isSuccess
    ) {
      message.success('Admin user successfully updated.');
      clearLoadStatus(getType(updateAdminUserAsync.success));
    }
    if (
      !prevProps.deleteAdminUserStatus.isError &&
      deleteAdminUserStatus.isError
    ) {
      message.error(`Error while deleting admin user(s).`);
      clearLoadStatus(getType(deleteAdminUserAsync.failure));
    } else if (
      !prevProps.deleteAdminUserStatus.isSuccess &&
      deleteAdminUserStatus.isSuccess
    ) {
      message.success('Admin user(s) successfully deleted.');
      clearLoadStatus(getType(deleteAdminUserAsync.success));
    }
  }

  componentWillUnmount() {
    if (this.state.isPasswordResetVisible) {
      this.setState({
        isPasswordResetVisible: false,
      });
    }
    if (this.state.roleUpdate) {
      this.setState({
        roleUpdate: undefined,
      });
    }
  }

  onPasswordResetClick = () => {
    this.setState({ isPasswordResetVisible: true });
  };

  onPasswordResetClose = () => {
    this.setState({ isPasswordResetVisible: false });
  };

  onResetAdminPassword = ({ password, updatePseudoParticipants }) => {
    const { selectedRowKeys } = this.state;
    const { updatePassword } = this.props;
    confirm({
      title: 'Are you sure you want to change this password?',
      onOk: () => {
        updatePassword({
          password,
          userIds: selectedRowKeys,
          updatePseudoParticipants,
        });
        this.onPasswordResetClose();
      },
      onCancel() {},
    });
  };

  rowClassName = (record: any, index: number): string =>
    index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';

  renderRoles = (value: any, record: any, index: number) => {
    return (
      <span key={`${record.key}-roles`}>
        {record.roles.map((role) => role.role.role).join(', ')}
      </span>
    );
  };

  renderEditRoles = (record, ref, rules, save) => {
    const { roles } = this.props;
    return (
      <Form.Item
        key="roleIds"
        name="roleIds"
        rules={rules}
        style={{ margin: 0 }}
        initialValue={record.roles.map((role: UserRoleType) => role.role.id)}
      >
        <Select
          ref={ref}
          mode="multiple"
          allowClear
          showSearch={false}
          dropdownMatchSelectWidth={false}
          onChange={(values: number[]) => {
            this.onRolesChange(values, record.id);
            if (save) {
              save();
            }
          }}
          onBlur={() => {
            if (save) {
              save();
            }
          }}
        >
          {roles?.map((role) => (
            <Option key={`role-${role.id}`} value={role.id}>
              {role.role}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  renderEditMobile = (record, ref, rules, save) => {
    return (
      <Form.Item
        key="mobile"
        name="mobile"
        rules={rules}
        style={{ margin: 0 }}
        initialValue={record.mobile}
      >
        <ReactTelInput
          inputProps={{
            style: { minHeight: '32px' },
          }}
          className="editable-cell-input"
          onBlur={save}
          onChange={save}
          flagsImagePath={flagsImagePath}
        />
      </Form.Item>
    );
  };

  renderParticipants = (value: any, record: any, index: number) => {
    return (
      <span key={`${record.key}-participants`}>
        {record.pseudoParticipantIds
          .map((id) => (
            <Username key={`${record.key}-${id}-username`} participantId={id} />
          ))
          .reduce(
            (prev, curr, index) =>
              index > 0
                ? [
                    ...prev,
                    <span key={`${record.key}-${index}-comma`}>&#44; </span>,
                    curr,
                  ]
                : [curr],
            new Array()
          )}
      </span>
    );
  };

  handleSave = (user) => {
    // dispatch update admin user
    const { roleUpdate } = this.state;
    const { updateAdminUser } = this.props;
    if (roleUpdate && roleUpdate.userId === user.id) {
      updateAdminUser({
        user,
        roleIds: roleUpdate.roleIds,
      });
    } else {
      updateAdminUser({
        user,
      });
    }
    if (roleUpdate) {
      this.setState({
        roleUpdate: undefined,
      });
    }
  };

  onRolesChange = (roleIds: number[], userId: number) => {
    this.setState({
      roleUpdate: {
        userId,
        roleIds,
      },
    });
  };

  onSelectedRowsChange = (selectedRowKeys) => {
    this.setState({
      selectedRowKeys,
    });
  };

  onDeleteSelectedAdmin = () => {
    const { selectedRowKeys } = this.state;
    const { deleteAdminUser } = this.props;
    confirm({
      title: 'Are you sure you want to delete these admin?',
      content:
        'Once the delete date is set, they will no longer have app access and will only be able to login to the admin portal up until the delete date passes.',
      okText: 'Delete',
      okType: 'danger',
      onOk: () => {
        deleteAdminUser({
          userIds: selectedRowKeys,
        });
        // Clear selectedRowKeys after performing an action
        this.setState({
          selectedRowKeys: [],
        });
      },
      onCancel() {},
    });
  };

  render() {
    const { admin, hasStudyManagerRole } = this.props;
    const { columns, selectedRowKeys, isPasswordResetVisible } = this.state;
    let rowSelection;
    if (hasStudyManagerRole) {
      rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectedRowsChange,
      };
    }
    return (
      <Card
        title={
          <div className="admin-management-title">
            <h1>Admin</h1>
          </div>
        }
      >
        <div>
          <h2 className="table-selection-title">Actions:</h2>
          <div className="table-selection-actions">
            <Button
              type="primary"
              title="Delete admin users"
              disabled={selectedRowKeys.length === 0}
              onClick={this.onDeleteSelectedAdmin}
              className="table-selection-action"
            >
              <AntdIcon fontAwesomeIcon={faTrashAlt} classes="action-icon" />
              <strong>Delete</strong>
            </Button>
            <Button
              type="primary"
              title="Change admin password(s)"
              disabled={selectedRowKeys.length === 0}
              onClick={this.onPasswordResetClick}
              className="table-selection-action"
            >
              <AntdIcon fontAwesomeIcon={faKey} classes="action-icon" />
              <strong>Change password</strong>
            </Button>
          </div>
        </div>
        <EditableTable
          columns={columns}
          dataSource={admin?.map((a) => ({ ...a, key: a.id }))}
          rowKey="id"
          rowClassName={this.rowClassName}
          handleSave={this.handleSave}
          rowSelection={rowSelection}
          disabled={!hasStudyManagerRole}
        />
        <Modal
          title="Reset Password"
          className="admin-action"
          visible={isPasswordResetVisible}
          onCancel={this.onPasswordResetClose}
          footer={null}
          destroyOnClose
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ updatePseudoParticipants: false }}
            onFinish={(values) => {
              this.onResetAdminPassword(values);
            }}
            autoComplete="off"
          >
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input password' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: 'Please confirm the entered password',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!'
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="updatePseudoParticipants"
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox>Update associated pseudo participants too?</Checkbox>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: '8px' }}
              >
                Submit
              </Button>
              <Button key="cancel" onClick={this.onPasswordResetClose}>
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    admin: selectors.getAdmin(state),
    roles: selectors.getAdminRoles(state),
    user: selectors.selectUser(state),
    studyId: selectors.getRequestedStudyId(state),
    updateAdminPasswordStatus: selectors.updateAdminPasswordStatus(state),
    updateAdminUserStatus: selectors.updateAdminUserStatus(state),
    deleteAdminUserStatus: selectors.deleteAdminUserStatus(state),
    hasStudyManagerRole: selectors.hasStudyManagerRole(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    updatePassword: (args: AdminPasswordArguments) =>
      dispatch(updateAdminPasswordAsync.request(args)),
    updateAdminUser: (args: UpdateAdminUserArguments) =>
      dispatch(updateAdminUserAsync.request(args)),
    deleteAdminUser: (args: DeleteAdminUserArguments) =>
      dispatch(deleteAdminUserAsync.request(args)),
    getAdmin: () => dispatch(getAdminAsync.request()),
    getAdminRoles: () => dispatch(getAdminRolesAsync.request()),
    clearLoadStatus: (type: string) => dispatch(clearStatus(type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminManagementLandingPage);
