import React from 'react';

const StudyNotFound = () => {

  return (
    <div>
      Study Access Restricted
    </div>
  );
};

export default StudyNotFound;
