import React, { Component, useEffect, useState } from 'react';
import * as _ from 'lodash';
import './goal.scss';
import { Tabs } from 'antd';
import GoalSettingList from './GoalSettingList';
import TopicManagement from './topic/TopicManagement';
import { useParams } from 'react-router';
import queryString from 'query-string';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UrlParamsType } from '../../../types';
import { getQueryParams } from '../../../components/util/useQuery';

interface ComponentProps extends RouteComponentProps {}

const GoalSettingLandingPage = ({ history }: ComponentProps) => {
  const params = useParams<UrlParamsType>();
  const [tab, setTab] = useState<string>();

  useEffect(() => {
    switch (tab) {
      case 'topics':
        if (params.subpage !== 'topics') {
          history.push({
            pathname: `/study/${params.studyId}/goals/topics/`,
          });
        }
        break;
      case 'goals':
        if (params.subpage === 'topics') {
          history.push({
            pathname: `/study/${params.studyId}/goals/`,
            search: `?${queryString.stringify({
              ...getQueryParams(history.location.search),
            })}`,
          });
        }
        break;
    }
  }, [tab]);

  return (
    <div id="hmp-resources-landing-page">
      <div className="hmp-tab-container">
        <Tabs
          defaultActiveKey="goals"
          activeKey={params.subpage !== 'topics' ? 'goals' : 'topics'}
          onChange={setTab}
        >
          <Tabs.TabPane tab="Goals" key="goals">
            <GoalSettingList />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Topics" key="topics">
            <TopicManagement />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default withRouter(GoalSettingLandingPage);
