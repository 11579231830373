import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  Card, Col, Row, Tooltip
} from 'antd';
import { loadDashboardDataAsync } from '../../../redux/dashboard/dashboard.types';
import * as selectors from '../../../redux/selectors';
import { IApiRequestState } from '../../../types/api.types';
import IApplicationState from '../../../types/state.types';
import './dashboard.scss';
import {createStructuredSelector} from "reselect";
import { ParticipantType } from '../../../types/serverTypes/studyTypes';

interface DispatchProps {
  loadDashboardData: typeof loadDashboardDataAsync.request
}

interface StateProps {
  studyId: number,
  dashboardData: any,
  loadDashboardDataStatus: IApiRequestState,
  showTestKits: boolean;
  showIncentives: boolean;
  showForms: boolean;
}

interface ComponentProps extends StateProps, DispatchProps {}

const initialState = {
  inEditMode: false as boolean,
  editedParticipant: {} as ParticipantType,
  selectedKey: 'posts' as string,
  activeForm: ''
};
type ComponentState = Readonly<typeof initialState>;

class DashboardLandingPage extends Component<ComponentProps, ComponentState> {
  async componentDidMount() {
    this.props.loadDashboardData();
  }

  readonly state: ComponentState = initialState;

  render() {
    const { loadDashboardDataStatus, dashboardData, studyId, showForms, showIncentives, showTestKits } = this.props;

    if (loadDashboardDataStatus.isLoading) {
      return <div>Loading</div>;
    }

    if (!dashboardData) {
      return <div> LOADING ... </div>;
    }

    const {
      testkits, payments, forum, inbox, forms, qnas
    } = dashboardData;

    return (
      <div id="dashboard">
        <div className="site-card-wrapper">
          <Row gutter={16} className="row">
            <Col span={8}>
              <Card title="Inbox" className="card" bordered={false}>
                <Tooltip title="Unread threads that are assigned to you">
                  <h3>
                    <a href={`/study/${studyId}/inbox?view=assigned&filterBy=unread`}>
                      {' '}
                      {inbox.unreadAssignedThreads}
                      {' '}
                      Unread assigned threads
                    </a>
                  </h3>
                </Tooltip>
                <Tooltip title="Unread threads that you've participated in">
                  <h3>
                    <a href={`/study/${studyId}/inbox?view=my&filterBy=unread`}>
                      {' '}
                      {inbox.unreadMyThreads}
                      {' '}
                      Unread my threads
                    </a>
                  </h3>
                </Tooltip>
                <Tooltip title="All unread threads that aren't archived">
                  <h3>
                    <a href={`/study/${studyId}/inbox?view=all&filterBy=unread`}>
                      {' '}
                      {inbox.unreadThreads}
                      {' '}
                      Unread threads
                      {' '}
                    </a>
                  </h3>
                </Tooltip>
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Forum" className="card" bordered={false}>
                <h3>
                  <a href={`/study/${studyId}/forums/uncategorized`}>
                    {' '}
                    {forum.uncategorized}
                    {' '}
                    posts uncategorized
                  </a>
                </h3>
                <h3>
                  <a href={`/study/${studyId}/forums/flagged`}>
                    {' '}
                    {forum.flagged}
                    {' '}
                    posts/comments flagged
                  </a>
                </h3>
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Ask The Expert" className="card" bordered={false}>
                <h3>
                  <a href={`/study/${studyId}/qna/`}>
                    {' '}
                    {qnas.unansweredCount}
                    {' '}
                    unanswered questions
                  </a>
                </h3>
              </Card>
            </Col>
          </Row>
          <Row gutter={16} className="row">
            {showIncentives
            && (
            <Col span={8}>
              <Card title="Incentives" className="card" bordered={false}>
                <h3>
                  <a href={`/study/${studyId}/payments/`}>
                    {' '}
                    {payments.unpaid}
                    {' '}
                    pending payment
                  </a>
                </h3>
              </Card>
            </Col>
            ) }
            {showForms
            && (
            <Col span={8}>
              <Card title="Forms" className="card" bordered={false}>
                <h3>
                  <a href={`/study/${studyId}/form`}>
                    {' '}
                    {forms.openFormsCount}
                    {' '}
                    open forms
                  </a>
                </h3>
              </Card>
            </Col>
            )}
            {showTestKits
            && (
            <Col span={8}>
              <Card title="Test Kits" bordered={false} className="card">
                <h3>
                  <a href={`/study/${studyId}/testkits/requested`}>
                    {' '}
                    {testkits.requestedCount}
                    {' '}
                    requests awaiting shipment
                  </a>
                </h3>
                <h3>
                  <a href={`/study/${studyId}/testkits/needsreview`}>
                    {' '}
                    {testkits.needsReviewCount}
                    {' '}
                    OraQuick reports need review with
                    {' '}
                    {testkits.seroconversionCount}
                    {' '}
                    possible seroconversions
                  </a>
                </h3>
              </Card>
            </Col>
            )}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  studyId: selectors.getRequestedStudyId,
  dashboardData: selectors.selectDashboard,
  loadDashboardDataStatus: selectors.loadDashboardStatus,
  showForms: selectors.getFormsFeatureEnabled,
  showIncentives: selectors.getIncentivesFeatureEnabled,
  showTestKits: selectors.getTestKitsFeatureEnabled
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadDashboardData: () => dispatch(loadDashboardDataAsync.request())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLandingPage);
