import React, { Component } from 'react';
import moment from 'moment';
import './newQnaPostForm.scss';
import { List } from 'antd';
import QnaComment from './QnaComment';
import { CommentType } from '../../types/serverTypes/forumTypes';

interface ComponentProps {
  level: number;
  comments: CommentType[];
}

class QnaCommentList extends Component<ComponentProps, {}> {
  renderDate = (value: string | Date) => {
    if (!value) {
      return 'N/a';
    }
    return moment(value).calendar();
  };

  render() {
    const {
      level,
      comments
    } = this.props;
    return (
      <List
        className="qna-comment-list"
        itemLayout="vertical"
        dataSource={comments}
        locale={{ emptyText: 'No Comments' }}
        renderItem={item => <QnaComment comment={item} level={level} />}
      />
    );
  }
}

export default QnaCommentList;
