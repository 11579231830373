import React from 'react';
import { Modal, Form, Select, message } from 'antd';

interface SuggestedGoalOptions {
  value: string;
  label: string;
}

interface CollectionCreateFormProps {
  visible: boolean;
  options: SuggestedGoalOptions[];
  onSubmit: (suggestedGoalId: string) => void;
  onCancel: () => void;
}

export const AddSuggestedGoalModal: React.FC<CollectionCreateFormProps> = ({
  visible,
  options,
  onSubmit,
  onCancel,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Add suggested goal"
      okText="Add"
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            const { suggestedGoalId } = values;
            onSubmit(suggestedGoalId);
          })
          .catch((info) => {
            message.error(
              'There was an error while selecting a suggested goal to add'
            );
          });
      }}
    >
      <Form form={form} layout="vertical" name="add-suggested-goal-form">
        <Form.Item
          name="suggestedGoalId"
          label="Suggested Goal"
          rules={[{ required: true, message: 'Please select a goal to add' }]}
        >
          <Select
            showSearch
            placeholder="Choose a goal"
            options={options}
            filterOption={(input, option) =>
              !!option &&
              option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
