import {
  faCogs, faFilesMedical, faHome, faMicroscope, faUsers
} from '@fortawesome/pro-solid-svg-icons';
import Menu from 'antd/lib/menu';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AntdIcon from '../antdIcon/AntdIcon';
import IApplicationState from '../../types/state.types';
import SideBar from './SideBar';

interface ComponentProps {
  collapsed: boolean
}

const AdminSideBar = (props: ComponentProps) => {

  const { collapsed } = props;

  return (
    <SideBar collapsed={collapsed}>
      <Menu
        id="sidebar-menu"
        mode="inline"
        theme="dark"
        defaultSelectedKeys={['/home']}
      >
        <Menu.Item key="/home" className="sidebar-menu-item">
          <Link to="/admin">
            <AntdIcon fontAwesomeIcon={faHome} />
            <span>Home</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/admin/users" className="sidebar-menu-item">
          <Link to="/admin/users">
            <AntdIcon fontAwesomeIcon={faUsers} />
            <span>Users</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/admin/studies" className="sidebar-menu-item">
          <Link to="/admin/studies">
            <AntdIcon fontAwesomeIcon={faMicroscope} />
            <span>Studies</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/admin/cms" className="sidebar-menu-item">
          <Link to="/admin/cms">
            <AntdIcon fontAwesomeIcon={faFilesMedical} />
            <span>CMS</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/settings" className="sidebar-menu-item">
          <Link to="/admin/settings">
            <AntdIcon fontAwesomeIcon={faCogs} />
            <span>Settings</span>
          </Link>
        </Menu.Item>
      </Menu>
    </SideBar>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  const { sidebar } = state.ui;

  return {
    collapsed: sidebar.collapsed
  };
};

export default connect(mapStateToProps)(AdminSideBar);
