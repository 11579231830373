import * as _ from 'lodash';
import React from 'react';
import { TinyColor } from '@ctrl/tinycolor';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from './redux/selectors';
import IApplicationState from './types/state.types';
import LoginPage from './pages/auth/LoginPage';
import Router from './router/Router';
import { QueryClient, QueryClientProvider } from 'react-query';

export const queryClient = new QueryClient();

interface StateProps {
  authenticatedUserId: Optional<number>;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
}

interface ComponentProps extends StateProps {}

class App extends React.Component<ComponentProps, {}> {
  render() {
    // Need this due to some weird parcel problem that results in TinyColor not being
    // compiled in. This is a dependency of antd and we think its being pruned during "tree shaking"
    const tc = TinyColor;

    const { isAuthenticated, isAuthenticating } = this.props;

    if (!isAuthenticated && !isAuthenticating) {
      return <LoginPage />;
    }

    if (isAuthenticating) {
      return <div>Authenticating...</div>;
    }

    if (!isAuthenticated) {
      return <div>Error...</div>;
    }

    return (
      <div>
        {process.env.ENV !== 'production' && (
          <div id="test-env-banner">
            {' '}
            {_.toUpper(process.env.ENV)} ENVIRONMENT{' '}
          </div>
        )}
        <QueryClientProvider client={queryClient}>
          <Router />
        </QueryClientProvider>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    authenticatedUserId: selectors.getAuthenticatedUserId,
    isAuthenticated: selectors.isAuthenticated,
    isAuthenticating: selectors.isAuthenticating,
  }
);

export default connect(mapStateToProps)(App);
