import React from 'react';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import Form from 'antd/lib/form';
import QuestionItem from './QuestionItem';
import '../activity.scss';
import { OptionType, QuestionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  typeId: number;
  disabled: boolean;
  questions: QuestionType[];
  options: OptionType[];
  updateQuestion: (question: QuestionType, index: number) => void;
  addQuestion: () => void;
  removeQuestion: (index: number) => void;
}

const DynamicQuestionItems = (props: ComponentProps) => {
  const {
    questions,
    typeId,
    disabled,
    updateQuestion,
    addQuestion,
    removeQuestion,
    options
  } = props;
  const onAddClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    addQuestion();
  };
  const items = questions.map((q: QuestionType, index: number) => {
    return (
      <li
        key={`question-item-${q.id ? q.id : q.uuid}`}
        className="question-list-container"
      >
        <QuestionItem
          typeId={typeId}
          disabled={disabled}
          question={q}
          options={options}
          updateQuestion={(q) => updateQuestion(q, index)}
        />
        {questions.length > 1 && (!disabled) && (
          <button
            className="dashed-danger-button"
            onClick={() => removeQuestion(index)}
          >
            <MinusOutlined />
            {' '}
            Remove Question
          </button>
        )}
      </li>
    );
  });
  return (
    <ul className="dynamic-question-items">
      {items}
      {(!disabled) && (
        <Item>
          <Button type="dashed" onClick={onAddClick} style={{ width: '100%' }}>
            <PlusOutlined />
            {' '}
            Add question
          </Button>
        </Item>
      )}
    </ul>
  );
};

export default DynamicQuestionItems;
