import { createBrowserHistory, History } from 'history';
import configureStore from './configureStore';

const configure = () => {
  const history: History<any> = createBrowserHistory();
  const store = configureStore(history);
  return { history, store };
};

const configuredStore = configure();

export default configuredStore;
