import React, { ChangeEvent } from 'react';
import {
  Input, Select, Form
} from 'antd';
import '../activity.scss';
import { OptionType, QuestionType } from '../../../types/serverTypes/activityTypes';

const { Option } = Select;
const { Item } = Form;

interface ComponentProps {
  disabled: boolean;
  question: QuestionType;
  options: OptionType[];
  onTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  updateQuestion: (q: QuestionType) => void;
}

const CategoryQuestion = (props: ComponentProps) => {
  const {
    question,
    onTextChange,
    updateQuestion,
    options,
    disabled
  } = props;
  const {
    text, correctOptionId, id, uuid
  } = question;
  const onCorrectOptionChange = (value: string) => {
    question.correctOptionId = value;
    updateQuestion(question);
  };
  return (
    <div style={{ width: '100%' }}>
      <Item
        key={`category-question-text-${id || uuid}`}
        name={`category-question-text-${id || uuid}`}
        rules={[{ required: true, message: 'Questions must have text.' }]}
        initialValue={text}
      >
        <Input
          spellCheck
          disabled={disabled}
          placeholder="Question text"
          onChange={onTextChange}
          style={{ width: '100%' }}
        />
      </Item>
      <Item
        key={`category-question-correct-option-${id || uuid}`}
        name={`category-question-correct-option-${id || uuid}`}
        rules={[{ required: true, message: 'Category questions must have a correct option defined.' }]}
        initialValue={correctOptionId ? `${correctOptionId}` : undefined}
        label="Correct Option:"
      >
        <Select
          disabled={disabled}
          onChange={onCorrectOptionChange}
          style={{ width: 'auto', minWidth: '200px' }}
        >
          {options?.map(option => <Option value={`${option.id ? option.id : option.uuid}`}>{option.text}</Option>)}
        </Select>
      </Item>
    </div>
  );
};

export default CategoryQuestion;
