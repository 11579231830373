import React, { Component } from 'react';
import TextArea from 'react-textarea-autosize';
import './thread.scss';
import { Button } from 'antd';
import websocket from '../../redux/clientWebsocketManager';

interface ComponentProps {
  getMessages: () => void;
  sendMessage: (input: string) => void;
  threadId: number;
  isTypingIndicators: Optional<Map<number, Set<string>>>
}

interface ComponentState {
  input: string;
}

class ComposeMessage extends Component<ComponentProps, ComponentState> {
  readonly state = {
    input: ''
  }

  componentDidUpdate(prevProps: Readonly<ComponentProps>, prevState: Readonly<ComponentState>, snapshot?: any) {
    const { threadId } = this.props;
    const { input } = this.state;

    if (input !== '' && input !== prevState.input) {
      websocket.sendTypingIndicator(threadId);
    } else if (prevState.input !== '' && input === '') {
      websocket.sendNotTypingIndicator(threadId);
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    if (!event || !event.target) {
      return '';
    }

    const input = event.target.value;
    this.setState({ input });
  };

  handleKeyPress = (evt: any) => {
    console.log(evt.code, evt.ctrlKey);
    if (evt.code === 'Enter') {
      if (!evt.ctrlKey && !evt.shiftKey) {
        this.handleSubmit();
      } else {
        const { input } = this.state;
        this.setState({ input: `${input}\n` });
      }
      evt.preventDefault();
    }
  }

  handleSubmit = (event?: React.FormEvent) => {
    if (event) event.preventDefault();

    const trimmedInput = this.state.input.trim();
    const isEmptyMessage = trimmedInput === ''
      || trimmedInput === '>'
      || trimmedInput === '``````'
      || trimmedInput === '``'
      || trimmedInput === '**'
      || trimmedInput === '____'
      || trimmedInput === '__'
      || trimmedInput === '****';
    if (isEmptyMessage) {
      return;
    }
    const { input } = this.state;
    const { sendMessage, getMessages } = this.props;
    if (input.length > 0 && /\S/.test(input)) {
      sendMessage(input);
      getMessages();
      this.setState({ input: '' });
    }
  };

  render() {
    const { input } = this.state;
    const { isTypingIndicators, threadId } = this.props;
    const typingIndicators:string[] = (isTypingIndicators && threadId && isTypingIndicators.get(threadId)) ? Array.from(isTypingIndicators!.get(threadId)!) : [];
    let typingStr;
    if (typingIndicators.length === 1) {
      typingStr = `${typingIndicators[0]} is typing`;
    } else if (typingIndicators.length === 2) {
      typingStr = `${typingIndicators[0]} and ${typingIndicators[1]} are typing`;
    } else if (typingIndicators.length > 2) {
      typingStr = `${typingIndicators[0]}, ${typingIndicators[1]} and more are typing`;
    }

    return (
      <div className="compose-message">
        <div className="compose-message-container">
          <TextArea
            spellCheck
            maxRows={3}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            value={input}
            placeholder="ENTER to send.  Control-ENTER or Shift-ENTER for new line."
            className="compose-message-input"
          />
          <Button type="primary" onClick={this.handleSubmit}>Send</Button>
        </div>
        <div className="typing-indicator">
          {typingStr}
          {!!typingStr
            && (
            <span>
              &nbsp;
              <span className="loader__dot">.</span>
              <span className="loader__dot">.</span>
              <span className="loader__dot">.</span>
            </span>
            )}
        </div>
      </div>
    );
  }
}

export default ComposeMessage;
