import { Col, message, Row, Switch } from 'antd';
import {
  AdminNotificationMethods,
  MessageDeliveryMethodFlags,
} from '../../constant/NotificationConstant';
import React, { useEffect, useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getAvailableAdminNotifications,
  updateSubscription,
} from '../../service/notification/adminNotificationService';
import { AdminNotificationType } from 'types/serverTypes/adminNotificationTypes';
import { enumKeys } from '../util/Util';

interface KeyValuePair {
  key: string;
  value: string;
}

const AdminNotificationSetting = () => {
  const methods = useRef<KeyValuePair[]>([]);

  useEffect(() => {
    for (const value of enumKeys(AdminNotificationMethods)) {
      methods.current.push({
        value: value,
        key: AdminNotificationMethods[value],
      });
    }
  }, []);

  // Access the client
  const queryClient = useQueryClient();
  const mutation = useMutation(updateSubscription, {
    onError: (error, variables, context) => {
      message.error('Error in updating subscription.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('adminNotifications');
      message.success('Subscription successfully updated.');
    },
  });

  // Queries
  const availableNotificationQuery = useQuery<AdminNotificationType[]>(
    'adminNotifications',
    getAvailableAdminNotifications
  );

  const updateNotificationSubscription = (
    notificationId: number,
    method,
    enabled: boolean
  ) => {
    mutation.mutate({ notificationId, method, enabled });
  };

  const getMethodToggle = (notificationId, methodFlags, deliveryMethod) => {
    let methodSubscribed = false;
    if (deliveryMethod === AdminNotificationMethods.EMAIL) {
      if (
        (methodFlags & MessageDeliveryMethodFlags.EMAIL) ===
        MessageDeliveryMethodFlags.EMAIL
      ) {
        methodSubscribed = true;
      }
    }
    if (deliveryMethod === AdminNotificationMethods.SMS) {
      if (
        (methodFlags & MessageDeliveryMethodFlags.SMS) ===
        MessageDeliveryMethodFlags.SMS
      ) {
        methodSubscribed = true;
      }
    }
    return (
      <Switch
        key={`${notificationId}-${deliveryMethod}`}
        checked={methodSubscribed}
        onChange={(checked) => {
          updateNotificationSubscription(
            notificationId,
            deliveryMethod,
            checked
          );
        }}
      />
    );
  };

  let lastKey = '';
  return (
    <div>
      <div className="titleRow">
        <h3>Notification Settings</h3>
      </div>
      {availableNotificationQuery.data?.map((notification) => {
        let returnData = <></>;
        if (lastKey !== notification.type) {
          lastKey = notification.type;
          returnData = (
            <Row
              className="titleRow indent"
              style={{ marginTop: 10 }}
              key={`header-${notification.type}`}
            >
              <Col span={14}>
                <b>{notification.type.toUpperCase()}</b>
              </Col>
              {methods.current.map((method) => (
                <Col
                  span={10 / methods.current.length}
                  key={`header-${method.key}`}
                >
                  <p>{method.value}</p>
                </Col>
              ))}
            </Row>
          );
        }
        return (
          <div key={`data-${notification.id}`}>
            {returnData}
            <Row className="titleRow indent">
              <Col span={14}>{notification.description}</Col>
              {methods.current.map((method) => (
                <Col
                  key={`method-${notification.id}-${method.key}`}
                  span={10 / methods.current.length}
                >
                  <p>
                    {getMethodToggle(
                      notification.id,
                      notification.method,
                      method.key
                    )}
                  </p>
                </Col>
              ))}
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default AdminNotificationSetting;
