import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import IApplicationState from '../../types/state.types';
import entitySchema from '../schema';
import {ParticipantType} from "../../types/serverTypes/studyTypes";

export const selectParticipants = (state: IApplicationState) => {
  return state.entities.participants;
};

export const getParticipants = createSelector(
  [selectParticipants],
  (participants): Optional<ParticipantType[]> => {
    if(!participants) {
      return undefined;
    }
    const { participants: denormalizedParticipants } = denormalize({ participants: participants.allIds }, entitySchema, { participants: participants.byId });
    return toArray(denormalizedParticipants);
  }
)