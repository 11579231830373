import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { ISideBarState, SideBarActionTypes, toggleSideBar } from './sidebar.types';

export const initialSideBarState: ISideBarState = {
  collapsed: false
};

export const sideBarReducer: Reducer<ISideBarState, SideBarActionTypes> = (state: ISideBarState = initialSideBarState, action: SideBarActionTypes) => {
  switch (action.type) {
    case getType(toggleSideBar):
      return {
        ...state,
        collapsed: !state.collapsed
      };
    default:
      return { ...state };
  }
};

export default sideBarReducer;
