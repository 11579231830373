import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import {
  QnaActionTypes,
  getQnaPostCountAsync,
  IQnaUIState
} from './qna.types';

export const initialQnaUIState: IQnaUIState = {
  postCount: 0
}

export const qnaUIReducer: Reducer<IQnaUIState, QnaActionTypes> = (state: IQnaUIState = initialQnaUIState, action: QnaActionTypes) => {
  switch (action.type) {
    case getType(getQnaPostCountAsync.success):
      return {
        ...state,
        postCount: action.payload
      };
    default: return state;
  }
};

export default qnaUIReducer;
