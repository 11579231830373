import React, { Component } from 'react';
import './newQnaPostForm.scss';
import { Popover } from 'antd';
import { FlagOutlined } from '@ant-design/icons';
import FlagResolveMenu from './FlagResolveMenu';
import { FlagType } from '../../types/serverTypes/forumTypes';

interface ComponentProps {
  participantId: number;
  id: number;
  isHidden: boolean;
  type: string;
  flags: FlagType[];
  style?: any;
  innerStyle?: any;
}

class FlagActionItem extends Component<ComponentProps, {}> {
  render() {
    const { participantId, id, isHidden, type, flags, style, innerStyle } =
      this.props;
    const unresolvedFlags = flags
      ? flags.filter((f) => f.resolveDate === null && f.resolution === null)
      : [];
    return unresolvedFlags.length > 0 || isHidden ? (
      <Popover
        key={`popover-flag-action-item-${type}-${id}`}
        content={
          <FlagResolveMenu
            participantId={participantId}
            id={id}
            isHidden={isHidden}
            type={type}
          />
        }
        title="Resolve Flag"
        trigger="click"
        style={style}
      >
        <a style={innerStyle} className="hmp-flag-icon-wrapper red-flag">
          <span key="list-vertical-flag-o">
            <FlagOutlined style={{ marginRight: 8 }} />
            {unresolvedFlags.length}
          </span>
        </a>
      </Popover>
    ) : (
      <span key="list-vertical-flag-o">
        <FlagOutlined style={{ marginRight: 8 }} />
        {unresolvedFlags.length}
      </span>
    );
  }
}

export default FlagActionItem;
