import React, { Component, ReactNode } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { _ } from 'lodash';
import moment from 'moment';
import {
  Table, Card, Button, Modal, message, Switch
} from 'antd';
import { renderDateWithTime } from '../../../components/util/Util';
import * as selectors from '../../../redux/selectors';
import IApplicationState from '../../../types/state.types';
import {
  getActivitySummariesAsync, ActivitySummaryArguments, getActivityCategoriesAsync, getActivityTypesAsync, getActivityAsync, ActivityArguments, deleteActivityAsync, DeleteActivityArguments, unpublishActivityAsync, publishActivityAsync
} from '../../../redux/activities/activities.types';
import ActivityFormContainer from '../../../components/activity/ActivityFormContainer';
import SearchInput from '../../../components/search/SearchInput';
import './activity.scss';
import {
  ActivityType,
  ActivityTypeType,
  CategoryType,
  FeedbackType,
  OptionType, QuestionType
} from '../../../types/serverTypes/activityTypes';

const { Column } = Table;
const { confirm } = Modal;

interface StateProps {
  activities: Optional<ActivityType[]>;
  categories: Optional<CategoryType[]>;
  types: Optional<ActivityTypeType[]>;
}

interface DispatchProps {
  loadSummaries: typeof getActivitySummariesAsync.request;
  loadCategories: typeof getActivityCategoriesAsync.request;
  loadTypes: typeof getActivityTypesAsync.request;
  getActivity: typeof getActivityAsync.request;
  deleteActivity: typeof deleteActivityAsync.request;
  unpublishActivity: typeof unpublishActivityAsync.request;
  publishActivity: typeof publishActivityAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {}

class ActivitiesLandingPage extends Component<ComponentProps, {}> {
  readonly state = {
    viewForm: false,
    currentId: -1,
    copiedId: -1,
    searchTerm: '',
    showDeleted: false
  };

  componentDidMount() {
    const { loadSummaries, loadCategories, loadTypes } = this.props;
    const args: ActivitySummaryArguments = {
      pageNumber: 0,
      pageSize: 100000,
      includeUnpublished: true,
      includeDeleted: true
    };
    loadSummaries(args);
    loadCategories();
    loadTypes();
  }

  closeForm = () => {
    this.setState({
      viewForm: false,
      currentId: -1,
      copiedId: -1
    });
  };

  openForm = () => {
    this.setState({
      viewForm: true,
      currentId: -1
    });
  };

  deleteActivity = (id: number) => {
    const { deleteActivity } = this.props;
    confirm({
      title: 'Are you sure you want to delete this activity?',
      okText: 'Delete',
      okType: 'danger',
      onOk() {
        deleteActivity({ id });
      },
      onCancel() {}
    });
  }

  copyActivity = (id: number) => {
    const { getActivity } = this.props;
    getActivity({ id });
    this.setState({
      viewForm: true,
      copiedId: id
    });
  };

  copyDeepLink = (id: number) => {
    const deepLink = `hmp://activity/${id}`;
    navigator.clipboard.writeText(deepLink).then(() => {
      message.success('Copied to clipboard!');
    }, () => {
      message.error('Failed to copy to clipboard.');
    });
  };

  unpublishActivity = (id: number) => {
    const { unpublishActivity } = this.props;
    confirm({
      title: 'Are you sure you want to unpublish this activity?',
      content: 'When editing previously published activities, be sure not to add/modify the structure of the activity. Only edit existing questions, options, or feedbacks.',
      okText: 'Un-Publish',
      okType: 'primary',
      onOk() {
        unpublishActivity(id);
      },
      onCancel() {}
    });
  };

  publishActivity = (id: number) => {
    const { publishActivity } = this.props;
    confirm({
      title: 'Are you sure you want to publish this activity?',
      content: 'Once published, you will want to avoid adding new questions, options, and feedbacks to an activity.',
      okText: 'Publish',
      okType: 'primary',
      onOk() {
        publishActivity(id);
      },
      onCancel() {}
    });
  };

  // Renderers
  rowClassName = (record: any, index: number): string => {
    return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
  };

  expandedRowRenderer = (record: any): ReactNode => {
    const columns = [
      { title: 'Description', dataIndex: 'description', key: 'description' },
      { title: 'Completed Count', dataIndex: 'numberCompleted', key: 'numberCompleted' },
      {
        title: 'Average (%)', dataIndex: 'average', key: 'average', render: this.renderNull
      }
    ];
    const data = [record];
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  renderActions = (activity: ActivityType) => {
    const { activities } = this.props;
    const currentActivity = activities?.find(a => a.id === activity.id);
    const displayPublish = currentActivity ? currentActivity.publishDate === null : true;
    return (
      <div className="activity-action-container">
        <a title="View" className="activity-action" onClick={(e) => { e.stopPropagation(); this.renderActivityViewEditModal(activity.id!); }}>
          <i className="far fa-eye fa-lg" />
        </a>
        <a title="Delete" className="activity-action" onClick={(e) => { e.stopPropagation(); this.deleteActivity(activity.id!); }}>
          <i className="fal fa-trash-alt fa-lg" />
        </a>
        <a title="Copy" className="activity-action" onClick={(e) => { e.stopPropagation(); this.copyActivity(activity.id!); }}>
          <i className="fal fa-copy fa-lg" />
        </a>
        <a title="Copy deep link to clipboard" className="activity-action" onClick={(e) => { e.stopPropagation(); this.copyDeepLink(activity.id!); }}>
          <i className="far fa-link" />
        </a>
        {displayPublish
          ? (
            <a title="Publish" className="activity-action" onClick={(e) => { e.stopPropagation(); this.publishActivity(activity.id!); }}>
              <i className="fal fa-calendar-plus fa-lg" />
            </a>
          )
          : (
            <a title="Unpublish" className="activity-action" onClick={(e) => { e.stopPropagation(); this.unpublishActivity(activity.id!); }}>
              <i className="fal fa-calendar-times fa-lg" />
            </a>
          )}
      </div>
    );
  }

  renderActivityViewEditModal = (id: number) => {
    const { getActivity } = this.props;
    getActivity({ id });
    this.setState({
      viewForm: true,
      currentId: id
    });
  };

  renderBoolean = (value: boolean) => (value ? 'Yes' : 'No');

  renderNull = (value: any) => (value || '--');

  renderType = (value: string) => {
    switch (value) {
      case 'quiz':
        return 'Quizzes';
      case 'category':
        return 'Break it down';
      case 'category no-answer':
        return 'Break it down (no answer)';
      case 'cyoa':
        return 'Call the shots';
      case 'fill in the blank':
        return 'Fill it in';
      case 'goals':
        return 'Goals';
      case 'ranking':
        return 'Sort it out';
      case 'screener':
        return 'Assessments';
    }
  };

  capitalize = (s: string): string => {
    return s ? s.split(' ').map(word => word[0].toUpperCase() + word.substring(1)).join(' ') : s;
  };

  // Sorters
  sorterAlphabetical = (a: ActivityType, b: ActivityType) => a.title.localeCompare(b.title);

  sorterNumerical = (a: ActivityType, b: ActivityType) => a.id! - b.id!;

  sorterPublishDate = (a: ActivityType, b: ActivityType) => {
    if (!a.publishDate && !b.publishDate) {
      return 0;
    } if (!a.publishDate) {
      return -1;
    } if (!b.publishDate) {
      return 1;
    }
    return moment(a.publishDate).unix() - moment(b.publishDate).unix();

  };

  sorterDeleteDate = (a: ActivityType, b: ActivityType) => {
    if (!a.deleteDate && !b.deleteDate) {
      return 0;
    } if (!a.deleteDate) {
      return -1;
    } if (!b.deleteDate) {
      return 1;
    }
    return moment(a.deleteDate).unix() - moment(b.deleteDate).unix();

  };

  sorterLastUpdateDate = (a: ActivityType, b: ActivityType) => {
    return moment(a.lastUpdateDate).unix() - moment(b.lastUpdateDate).unix();
  };

  // Filters
  onFilterType = (value: any, record: any) => {
    const { types } = this.props;
    const current = types?.find(t => t.type === record.type);
    return current ? current.id === parseInt(value) : false;
  }

  onFilterCategory = (value: any, record: any) => {
    const { categories } = this.props;
    const current = categories?.find(t => t.category === record.category);
    return current ? current.id === parseInt(value) : false;
  }

  onFilterPublished = (value: any, record: any) => {
    return value === 'yes' ? record.isPublished : !record.isPublished;
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchTerm: event.target.value });
  };

  onShowDeletedChange = (checked: boolean) => {
    this.setState({ showDeleted: checked });
  };

  handleRowClick = (record: ActivityType, index?: number) => {
    if (record && record.id) {
      this.renderActivityViewEditModal(record.id);
    }
  };

  preprocessCopiedActivity = (activity: ActivityType): ActivityType => {
    // This method strips the id property off the activity and its child components.
    const processed:ActivityType = _.cloneDeep(activity);
    delete processed.id; // = undefined;
    processed.publishDate = undefined;
    if (processed.options) {
      processed.options = processed.options.map((option): OptionType => {
        if (option.feedback) {
          option.feedback = _.omit(option.feedback, 'id');
        }
        return _.omit(option, 'id');
      });
    }
    if (processed.feedback) {
      processed.feedback = processed.feedback.map((feedback): FeedbackType => {
        return _.omit(feedback, 'id');
      });
    }
    if (processed.questions) {
      processed.questions = processed.questions.map((question): QuestionType => {
        if (question.options) {
          question.options = question.options.map((option): OptionType => {
            if (option.feedback) {
              option.feedback = _.omit(option.feedback, 'id');
            }
            return _.omit(option, 'id');
          });
        }
        if (question.feedback) {
          question.feedback = question.feedback.map((feedback): FeedbackType => {
            return _.omit(feedback, 'id');
          });
        }
        return _.omit(question, 'id');
      });
    }
    return processed;
  }

  render() {
    const { activities, types, categories } = this.props;
    const {
      viewForm, currentId, copiedId, searchTerm, showDeleted
    } = this.state;
    const typeFilters = types ? types.map(type => { return { text: this.renderType(type.type), value: type.id.toString() }; }) : [];
    const categoryFilters = categories ? categories.map(cat => { return { text: cat.category, value: cat.id.toString() }; }) : [];
    let currentActivity = activities?.find(a => (currentId > -1 ? a.id === currentId : a.id === copiedId));
    if (copiedId > -1 && currentActivity) {
      currentActivity = this.preprocessCopiedActivity(currentActivity);
    }
    let displayedActivities = activities;
    if (searchTerm && searchTerm.length) {
      const regex = new RegExp(searchTerm, 'i');
      displayedActivities = _.filter(displayedActivities, a => regex.test(a.title)
        || regex.test(a.description)
        || a.questions?.some(q => regex.test(q.text))
        || a.options?.some(o => regex.test(o.text))
        || a.feedback?.some(f => regex.test(f.text) || (f.title ? regex.test(f.title) : false))) as ActivityType[];
    }
    if (!showDeleted) {
      displayedActivities = _.filter(displayedActivities, a => !a.deleteDate);
    }
    return (
      <div className="activity-list">
        <Card
          title={(
            <div className="activity-title">
              <h1>Activities</h1>
              <Button type="primary" onClick={(e) => { e.stopPropagation(); this.openForm(); }}>+ Add Activity</Button>
            </div>
        )}
        >
          {viewForm
          && (
          <ActivityFormContainer
            activity={currentActivity}
            visible={viewForm}
            closeHandler={this.closeForm}
            creative={currentId === -1 || (currentActivity && !currentActivity.id)}
            copied={copiedId > -1}
          />
          )}
          <div className="table-filter-search">
            <SearchInput
              className="search-bar"
              id="search-input"
              onChangeHandler={this.handleSearchChange}
              placeholder="Search Activities"
              value={this.state.searchTerm}
            />
            <div className="filter-items">
              <span className="filter">
                Show Deleted
                <Switch checked={showDeleted} onChange={this.onShowDeletedChange} />
              </span>
            </div>
          </div>
          <Table
            dataSource={displayedActivities}
            rowKey="id"
            rowClassName={this.rowClassName}
            expandedRowRender={this.expandedRowRenderer}
            scroll={{ y: '65vh' }}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => this.handleRowClick(record), // click row
                onDoubleClick: event => {}, // double click row
                onContextMenu: event => {}, // right button click row
                onMouseEnter: event => {}, // mouse enter row
                onMouseLeave: event => {} // mouse leave row
              };
            }}
          >
            <Column title="ID" dataIndex="id" key="id" sorter={this.sorterNumerical} />
            <Column title="Title" dataIndex="title" sorter={this.sorterAlphabetical} />
            <Column title="Category" dataIndex="category" filters={categoryFilters} onFilter={this.onFilterCategory} />
            <Column title="Type" dataIndex="type" render={this.renderType} filters={typeFilters} onFilter={this.onFilterType} />
            <Column title="Date Modified" dataIndex="lastUpdateDate" defaultSortOrder="descend" render={renderDateWithTime} sorter={this.sorterLastUpdateDate} />
            <Column title="Publish Date" dataIndex="publishDate" render={renderDateWithTime} sorter={this.sorterPublishDate} />
            {
              this.state.showDeleted
                ? <Column title="Delete Date" dataIndex="deleteDate" render={renderDateWithTime} sorter={this.sorterDeleteDate} />
                : undefined
            }
            <Column title="Actions" render={this.renderActions} />
          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    activities: selectors.getActivities(state),
    categories: selectors.getCategories(state),
    types: selectors.getTypes(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadSummaries: (args: ActivitySummaryArguments) => dispatch(getActivitySummariesAsync.request(args)),
    loadCategories: () => dispatch(getActivityCategoriesAsync.request()),
    loadTypes: () => dispatch(getActivityTypesAsync.request()),
    getActivity: (args: ActivityArguments) => dispatch(getActivityAsync.request(args)),
    deleteActivity: (args: DeleteActivityArguments) => dispatch(deleteActivityAsync.request(args)),
    unpublishActivity: (id: number) => dispatch(unpublishActivityAsync.request(id)),
    publishActivity: (id: number) => dispatch(publishActivityAsync.request(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesLandingPage);
