import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { AnalyticsActionTypes, getAnalyticsReportAsync, IAnalyticsUIState } from './analytics.types';

export const initialAnalyticsUIState = {
  reportRows: []
}
export const analyticsUIReducer: Reducer<IAnalyticsUIState, AnalyticsActionTypes> = (state: IAnalyticsUIState = initialAnalyticsUIState, action: AnalyticsActionTypes) => {

  switch (action.type) {
    case getType(getAnalyticsReportAsync.success): return {
      ...state,
      reportRows:  action.payload
    };
    default: return { ...state };
  }
};

export default analyticsUIReducer;
