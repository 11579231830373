import axios from "../../redux/api";
import {ResourceTopicType} from "../../types/serverTypes/resourceTypes";
import {TopicSortType} from "../../types/serverTypes/forumTypes";

class TopicService {
  static async updateTopic(topic: ResourceTopicType) {
    await axios({
      method: "put",
      url: `/a/topic`,
      data: { topic },
    });
  }
  static async updateSort(topics: TopicSortType[]) {
    await axios({
      method: "put",
      url: `/a/topic/sort`,
      data: { topics },
    });
  }
  static async deleteTopic(topicId: number) {
    await axios({
      method: "delete",
      url: `/a/topic/${topicId}`,
    });
  }
}

export default TopicService;
