import {AxiosResponse} from 'axios';
import {normalize} from 'normalizr';
import {all, call, fork, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {getType} from 'typesafe-actions';
import {NormalizedType} from '../../types/state.types';
import axios from '../api';
import {loadDashboardDataAsync} from '../dashboard/dashboard.types';
import {entitySchema} from '../schema';
import {
  archiveThreadAsync,
  assignThreadAsync,
  clearMessages,
  CreateThreadArguments,
  createThreadAsync,
  GetInboxArguments,
  getMessageInboxAsync,
  GetMessagesArguments,
  getMessagesAsync,
  getMessageThreadCountAsync,
  getParticipantThreadsAsync,
  markThreadImportantAsync,
  markThreadReadAsync,
  markThreadUnreadAsync,
  MessagesActionTypes,
  refreshInbox,
  refreshInboxAndThread,
  SendMessageArguments,
  sendMessageAsync,
  unarchiveThreadAsync,
  unassignThreadAsync,
  unmarkThreadImportantAsync,
  updateMessages,
  updateMessageThreadCount,
} from './messages.types';
import {NormalizerResult} from "../../types";
import {InboxResponseType, MessageType} from "../../types/serverTypes/messageTypes";

let lastInboxParams:GetInboxArguments;
let lastGetMessagesParam:GetMessagesArguments;

const getParticipantThreads = (participantId: number) => {
  return axios({
    method: 'get',
    url: `/a/message/threadsByParticipant/${participantId}`
  });
};

const getMessageInbox = (args: GetInboxArguments) => {
  let { pageNumber, pageSize, previewLength, searchTerm, view, filterBy, sortBy} = args;

  let url = `/a/message/inbox`;
  url += `?pageNumber=${pageNumber ? pageNumber : 0}`;
  url += `&pageSize=${pageSize ? pageSize : 25}`;
  url += `&previewLength=${previewLength ? previewLength : 30}`;
  url += `&view=${view ? view : 'all'}`;
  url += `&sortBy=${sortBy ? sortBy : 'newest'}`;
  if (searchTerm) {
    url += `&searchTerm=${searchTerm}`;
  }
  if (filterBy) {
    url += `&filterBy=${filterBy}`;
  }

  return axios({
    method: 'get',
    url
  });
};

const getMessageThreadCount = () => {
  return axios({
    method: 'get',
    url: `/a/message/threadCount`
  });
};

const getMessages = (args: GetMessagesArguments) => {
  let { threadId, pageNumber, pageSize } = args;
  if(!pageSize) {
    pageSize = 16;
  }
  if(!pageNumber) {
    pageNumber = 0;
  }
  return axios({
    method: 'get',
    url: `/a/message/thread/${threadId}?pageSize=${pageSize}&pageNumber=${pageNumber}`
  });
};

const createThread = (thread: CreateThreadArguments) => {
  return axios({
    method: 'put',
    url: `/a/message/newThread/${thread.participantId}`,
    data: {
      title: thread.title,
      body: thread.body
    }
  });
};

const sendMessage = (args: SendMessageArguments) => {
  return axios({
    method: 'put',
    url: `/a/message/${args.threadId}`,
    data: {
      body: args.body
    }
  });
};

const archiveThread = (threadId: number) => {
  return axios({
    method: 'put',
    url: `/a/message/${threadId}/archive`
  });
};

const unarchiveThread = (threadId: number) => {
  return axios({
    method: 'put',
    url: `/a/message/${threadId}/unarchive`
  });
};

const markImportant = (threadId: number) => {
  return axios({
    method: 'put',
    url: `/a/message/${threadId}/important`
  });
};

const unmarkImportant = (threadId: number) => {
  return axios({
    method: 'put',
    url: `/a/message/${threadId}/unimportant`
  });
};

const markUnread = (threadId: number) => {
  return axios({
    method: 'put',
    url: `/a/message/${threadId}/unread`
  });
};

const markRead = (threadId: number) => {
  return axios({
    method: 'put',
    url: `/a/message/${threadId}/read`
  });
};

const assignThread = (threadId: number, userId: number) => {
  return axios({
    method: 'put',
    url: `/a/message/${threadId}/${userId}/assign`
  });
};

const unassignThread = (threadId: number, userId: number) => {
  return axios({
    method: 'put',
    url: `/a/message/${threadId}/${userId}/unassign`
  });
};

function* updateMessagesHandler(messages: Optional<NormalizedType<MessageType>>) {
  if (messages) {
    yield put(updateMessages(messages));
  }
}

function* refreshInboxAndThreadHandler(refreshInboxAndThreadAction: ReturnType<typeof refreshInboxAndThread>): Generator {
  yield call(refreshMessageInboxHandler);
  yield call(refreshThreadHandler, refreshInboxAndThreadAction.payload);
}

function* refreshThreadHandler(threadId: number) {
  if (lastGetMessagesParam && lastGetMessagesParam.threadId === threadId) {
    const pageSize = lastGetMessagesParam.pageNumber && lastGetMessagesParam.pageSize ? (lastGetMessagesParam.pageNumber+1) * lastGetMessagesParam.pageSize : 16;
    const refreshParams = {...lastGetMessagesParam, pageNumber: 0, pageSize};
    yield put(getMessagesAsync.request(refreshParams));
  }
}

function* refreshMessageInboxHandler() {
  const refreshParams = {...lastInboxParams, pageNumber: 0, pageSize: ((lastInboxParams.pageNumber+1) * lastInboxParams.pageSize)}
  yield put(getMessageInboxAsync.request(refreshParams));
  yield put(loadDashboardDataAsync.request());
}

function* getParticipantThreadsHandler(action: ReturnType<typeof getParticipantThreadsAsync.request>): Generator {
  try {
    const participantId: number = action.payload;
    const response: AxiosResponse = (yield call(getParticipantThreads, participantId)) as AxiosResponse;
    const participantThreads = response.data ? response.data : [];
    const { entities } = normalize(participantThreads, entitySchema.threads) as NormalizerResult;
    const { threads } = entities;

    yield put(getParticipantThreadsAsync.success(threads));
  } catch (error) {
    yield put(getParticipantThreadsAsync.failure(error));
  }
}

function* getMessageInboxHandler(action: ReturnType<typeof getMessageInboxAsync.request>): Generator {
  try {
    lastInboxParams = action.payload;
    const response: AxiosResponse<InboxResponseType> = (yield call(getMessageInbox, action.payload)) as AxiosResponse;
    const { entities } = normalize(response.data.data, entitySchema.threads) as NormalizerResult;
    const { threads } = entities;

    yield put(getMessageInboxAsync.success(threads));
    yield put(updateMessageThreadCount(response.data.total));
    yield put(loadDashboardDataAsync.request());
  } catch (error) {
    yield put(getMessageInboxAsync.failure(error));
  }
}

function* getMessageThreadCountHandler(action: ReturnType<typeof getMessageThreadCountAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(getMessageThreadCount)) as AxiosResponse;
    yield put(getMessageThreadCountAsync.success(response.data));
  } catch (error) {
    yield put(getMessageThreadCountAsync.failure(error));
  }
}

function* getMessagesHandler(action: ReturnType<typeof getMessagesAsync.request>): Generator {
  try {
    lastGetMessagesParam = action.payload;
    const response: AxiosResponse = (yield call(getMessages, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.messageResponses) as NormalizerResult;
    const { messages, messageResponses } = entities;

    yield call(updateMessagesHandler, messages);
    yield call(refreshMessageInboxHandler);
    yield put(getMessagesAsync.success(messageResponses));
  } catch (error) {
    yield put(getMessagesAsync.failure(error));
  }
}

function* createThreadHandler(action: ReturnType<typeof createThreadAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(createThread, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.threads) as NormalizerResult;
    const { threads } = entities;
    yield put(createThreadAsync.success(threads));
  } catch (error) {
    yield put(createThreadAsync.failure(error));
  }
}

function* sendMessageHandler(action: ReturnType<typeof sendMessageAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(sendMessage, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.messages) as NormalizerResult;
    const { messages } = entities;
    yield call(refreshMessageInboxHandler);
    yield call(refreshMessagesHandler, action.payload.threadId);
    yield put(sendMessageAsync.success(messages));
  } catch (error) {
    yield put(sendMessageAsync.failure(error));
  }
}

function* archiveThreadHandler(action: ReturnType<typeof archiveThreadAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(archiveThread, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.threads) as NormalizerResult;
    const { threads } = entities;
    yield put(archiveThreadAsync.success(threads));
  } catch (error) {
    yield put(archiveThreadAsync.failure(error));
  }
}

function* unarchiveThreadHandler(action: ReturnType<typeof unarchiveThreadAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(unarchiveThread, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.threads) as NormalizerResult;
    const { threads } = entities;
    yield put(unarchiveThreadAsync.success(threads));
  } catch (error) {
    yield put(unarchiveThreadAsync.failure(error));
  }
}

function* markThreadImportantHandler(action: ReturnType<typeof markThreadImportantAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(markImportant, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.threads) as NormalizerResult;
    const { threads } = entities;
    yield put(markThreadImportantAsync.success(threads));
  } catch (error) {
    yield put(markThreadImportantAsync.failure(error));
  }
}

function* unmarkThreadImportantHandler(action: ReturnType<typeof unmarkThreadImportantAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(unmarkImportant, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.threads) as NormalizerResult;
    const { threads } = entities;
    yield put(unmarkThreadImportantAsync.success(threads));
  } catch (error) {
    yield put(unmarkThreadImportantAsync.failure(error));
  }
}

function* markThreadUnreadHandler(action: ReturnType<typeof markThreadUnreadAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(markUnread, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.threads) as NormalizerResult;
    const { threads } = entities;
    yield put(markThreadUnreadAsync.success(threads));
  } catch (error) {
    yield put(markThreadUnreadAsync.failure(error));
  }
}

function* markThreadReadHandler(action: ReturnType<typeof markThreadReadAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(markRead, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.threads) as NormalizerResult;
    const { threads } = entities;
    yield put(markThreadReadAsync.success(threads));
  } catch (error) {
    yield put(markThreadReadAsync.failure(error));
  }
}

function* assignThreadHandler(action: ReturnType<typeof assignThreadAsync.request>): Generator {
  try {
    const { threadId, userId } = action.payload;
    const response: AxiosResponse = (yield call(assignThread, threadId, userId)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.threads) as NormalizerResult;
    const { threads } = entities;
    yield put(assignThreadAsync.success(threads));
  } catch (error) {
    yield put(assignThreadAsync.failure(error));
  }
}

function* unassignThreadHandler(action: ReturnType<typeof unassignThreadAsync.request>): Generator {
  try {
    const { threadId, userId } = action.payload;
    const response: AxiosResponse = (yield call(unassignThread, threadId, userId)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.threads) as NormalizerResult;
    const { threads } = entities;
    yield put(unassignThreadAsync.success(threads));
  } catch (error) {
    yield put(unassignThreadAsync.failure(error));
  }
}

function* threadActionSuccessHandler(action: MessagesActionTypes): Generator {
  switch(action.type) {
    case getType(archiveThreadAsync.success):
    case getType(unarchiveThreadAsync.success):
    case getType(assignThreadAsync.success):
    case getType(unassignThreadAsync.success):
      yield* clearThreadsHandler();
  }
  yield* refreshMessageInboxHandler();
}

function* clearThreadsHandler() {
  yield put(clearMessages());
}

function* getParticipantMessagesWatcher() {
  yield takeLatest(getType(getParticipantThreadsAsync.request), getParticipantThreadsHandler);
}

function* getMessageInboxWatcher() {
  yield takeEvery(getType(getMessageInboxAsync.request), getMessageInboxHandler);
}

function* getMessageThreadCountWatcher() {
  yield takeLatest(getType(getMessageThreadCountAsync.request), getMessageThreadCountHandler);
}

function* getMessagesWatcher() {
  yield takeEvery(getType(getMessagesAsync.request), getMessagesHandler);
}

function* createThreadWatcher() {
  yield takeLatest(getType(createThreadAsync.request), createThreadHandler);
}

function* refreshMessagesHandler(threadId: number) {
  yield put(getMessagesAsync.request({ threadId }));
}

function* sendMessageWatcher() {
  yield takeLatest(getType(sendMessageAsync.request), sendMessageHandler);
}

function* archiveThreadWatcher() {
  yield takeLatest(getType(archiveThreadAsync.request), archiveThreadHandler);
  yield takeLatest(getType(archiveThreadAsync.success), threadActionSuccessHandler);
}

function* unarchiveThreadWatcher() {
  yield takeLatest(getType(unarchiveThreadAsync.request), unarchiveThreadHandler);
  yield takeLatest(getType(unarchiveThreadAsync.success), threadActionSuccessHandler);
}

function* markThreadImportantWatcher() {
  yield takeLatest(getType(markThreadImportantAsync.request), markThreadImportantHandler);
  yield takeLatest(getType(markThreadImportantAsync.success), threadActionSuccessHandler);
}

function* unmarkThreadImportantWatcher() {
  yield takeLatest(getType(unmarkThreadImportantAsync.request), unmarkThreadImportantHandler);
  yield takeLatest(getType(unmarkThreadImportantAsync.success), threadActionSuccessHandler);
}

function* markThreadUnreadWatcher() {
  yield takeLatest(getType(markThreadUnreadAsync.request), markThreadUnreadHandler);
  yield takeLatest(getType(markThreadUnreadAsync.success), threadActionSuccessHandler);
}

function* markThreadReadWatcher() {
  yield takeLatest(getType(markThreadReadAsync.request), markThreadReadHandler);
  yield takeLatest(getType(markThreadReadAsync.success), threadActionSuccessHandler);
}

function* assignThreadWatcher() {
  yield takeLatest(getType(assignThreadAsync.request), assignThreadHandler);
  yield takeLatest(getType(assignThreadAsync.success), threadActionSuccessHandler);
}

function* unassignThreadWatcher() {
  yield takeLatest(getType(unassignThreadAsync.request), unassignThreadHandler);
  yield takeLatest(getType(unassignThreadAsync.success), threadActionSuccessHandler);
}

function* refreshInboxAndThreadWatcher() {
  yield takeLatest(getType(refreshInboxAndThread), refreshInboxAndThreadHandler);
}

function* refreshInboxWatcher() {
  yield takeLatest(getType(refreshInbox), refreshMessageInboxHandler);
}

export default function* messagesSaga() {
  yield all([
    fork(getParticipantMessagesWatcher),
    fork(getMessageInboxWatcher),
    fork(getMessageThreadCountWatcher),
    fork(getMessagesWatcher),
    fork(createThreadWatcher),
    fork(sendMessageWatcher),
    fork(archiveThreadWatcher),
    fork(unarchiveThreadWatcher),
    fork(markThreadImportantWatcher),
    fork(unmarkThreadImportantWatcher),
    fork(markThreadUnreadWatcher),
    fork(markThreadReadWatcher),
    fork(assignThreadWatcher),
    fork(unassignThreadWatcher),
    fork(refreshInboxAndThreadWatcher),
    fork(refreshInboxWatcher)
  ]);
}
