import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from 'antd';
import { enumKeys } from '../util/Util';
import { AvatarPaletteTypes } from '../../constant/AvatarConstant';
import React, { useState } from 'react';
import { AvatarPartCategoryBaseType } from '../../types/serverTypes/avatarTypes';
import { useMutation, useQueryClient } from 'react-query';
import { createAvatarCategory } from '../../service/avatar/avatarService';
const { Option } = Select;

interface AvatarNewCategoryModalProp {
  showModal: boolean;
  onCancel: () => void;
}
const initialAvatarCategory = {
  label: '',
  sequence: 1,
  isRequired: false,
  paletteType: AvatarPaletteTypes.FullAndAccentAndSkinTone,
  isCustomizable: true,
  isOnlyColorCustomizable: false,
  topVal: 0,
  leftVal: 0,
  widthVal: 100,
  heightVal: 100,
};
const AvatarNewCategoryModal = (props: AvatarNewCategoryModalProp) => {
  const [newCategory, setNewCategory] = useState<AvatarPartCategoryBaseType>(
    initialAvatarCategory
  );
  const [form] = Form.useForm();

  const onSave = () => {
    avatarCategoryMutation.mutate(newCategory);
    onCancel();
  };
  const queryClient = useQueryClient();
  const avatarCategoryMutation = useMutation(createAvatarCategory, {
    onError: (error, variables, context) => {
      message.error('Error in adding new avatar category.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('avatarCategories');
      message.success('Avatar category successfully added.');
    },
  });

  const onCancel = () => {
    form.resetFields();
    setNewCategory({ ...initialAvatarCategory });
    props.onCancel();
  };

  const onUpdateCategory = (data: any) => {
    setNewCategory({ ...newCategory, ...data });
  };
  const getOptions = () => {
    let options: JSX.Element[] = [];
    for (const value of enumKeys(AvatarPaletteTypes)) {
      options.push(
        <Option key={value} value={AvatarPaletteTypes[value]}>
          {value}
        </Option>
      );
    }
    return options;
  };
  return (
    <Modal
      width={800}
      title="Create New Avatar Category"
      visible={props.showModal}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Submit
        </Button>,
      ]}
    >
      <Form
        name="basic"
        initialValues={{
          ...initialAvatarCategory,
        }}
        form={form}
        autoComplete="off"
        onFinish={onSave}
      >
        <Form.Item
          label="Label"
          name="label"
          required
          rules={[{ required: true, message: 'Label is required' }]}
        >
          <Input
            maxLength={50}
            onChange={(e) => {
              onUpdateCategory({ label: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Sequence"
          name="sequence"
          rules={[
            {
              required: true,
              message: 'Sequence is required',
            },
          ]}
        >
          <InputNumber
            onChange={(value) => {
              onUpdateCategory({ sequence: value });
            }}
          />
        </Form.Item>
        <Form.Item label="Palette Type" name="paletteType">
          <Select
            onChange={(value) => {
              onUpdateCategory({ paletteType: value });
            }}
          >
            {getOptions()}
          </Select>
        </Form.Item>
        <Form.Item name="isRequired" valuePropName="checked">
          <Checkbox
            onChange={(e) => {
              onUpdateCategory({ isRequired: e.target.checked });
            }}
          >
            Is Required?
          </Checkbox>
        </Form.Item>
        <Form.Item name="isCustomizable" valuePropName="checked">
          <Checkbox
            onChange={(e) => {
              onUpdateCategory({ isCustomizable: e.target.checked });
            }}
          >
            Is Customizable?
            <br />
            This mean color(s) can be changed.
          </Checkbox>
        </Form.Item>
        <Form.Item name="isOnlyColorCustomizable" valuePropName="checked">
          <Checkbox
            onChange={(e) => {
              onUpdateCategory({
                isOnlyColorCustomizable: e.target.checked,
              });
            }}
          >
            Is Only Color Customizable? (e.g.: body)
          </Checkbox>
        </Form.Item>
        <Form.Item label="Height" name="heightVal">
          <InputNumber
            onChange={(value) => {
              onUpdateCategory({
                heightVal: value,
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Width" name="widthVal">
          <InputNumber
            onChange={(value) => {
              onUpdateCategory({
                widthVal: value,
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Top Offset" name="topVal">
          <InputNumber
            onChange={(value) => {
              onUpdateCategory({
                topVal: value,
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Left Offset" name="leftVal">
          <InputNumber
            onChange={(value) => {
              onUpdateCategory({
                leftVal: value,
              });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AvatarNewCategoryModal;
