import { ActionType, createAsyncAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import { SocialJsonParamType } from '../user/socialUtil';
import {FavoriteType} from "../../types/serverTypes/forumTypes";

export const getFavoritesAsync = createAsyncAction(
  'hmp/admin/GET_FAVORITES_REQUEST',
  'hmp/admin/GET_FAVORITES_SUCCESS',
  'hmp/admin/GET_FAVORITES_FAILURE'
)<SocialJsonParamType[], Optional<NormalizedType<FavoriteType>>, Error>();


const thumbsupsActions = {
  getFavoritesAsync
};

export type FavoritesActionTypes = ActionType<typeof thumbsupsActions>;
