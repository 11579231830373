import { Table } from 'antd';
import * as _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { renderDateWithoutTime } from '../../../components/util/Util';
import * as selectors from '../../../redux/selectors';
import { getUserSurveysAsync } from '../../../redux/userSurvey/userSurvey.types';
import IApplicationState from '../../../types/state.types';
import { UserSurveyType } from '../../../types';

interface StateProps {
  userSurveys: Optional<UserSurveyType[]>;
  studyId: number;
}

interface DispatchProps {
  getUserSurveys: typeof getUserSurveysAsync.request
}

interface ComponentProps extends DispatchProps, StateProps {}

class SurveysLandingPage extends Component<ComponentProps, StateProps> {

  componentDidMount() {
    this.props.getUserSurveys();
  }

  rowClassName = (record: any, index: number): string => {
    return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
  };

  render() {
    const { userSurveys, studyId } = this.props;

    const columns = [
      {
        width: '100px',
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: (a: any, b: any) => a.id - b.id
      },
      {
        title: 'Survey',
        dataIndex: 'surveyName',
        key: 'surveyName',
        sorter: (a: any, b: any) => `${a.surveyName}`.localeCompare(b.surveyName),
        filters: _.map(_.sortedUniq(_.uniq(_.sortBy(_.compact(_.map(userSurveys, 'surveyName'))))), x => {
          return { text: x, value: x };
        }),
        defaultFilteredValue: ['HMP Stigma-3-Month FUP', 'HMP Stigma-6-Month FUP', 'HMP Stigma-9-Month FUP', 'HMP Stigma-12-Month FUP'],
        onFilter: (value:string, record:UserSurveyType) => record.surveyName === value
      },
      {
        title: 'Username',
        dataIndex: 'participant.username',
        key: 'participant.username',
        sorter: (a: any, b: any) => a.participant && b.participant && `${a.participant.username}`.localeCompare(b.participant.username),
        filters: _.map(_.sortedUniq(_.uniq(_.sortBy(_.compact(_.map(userSurveys, 'participant.username'))))), x => {
          return { text: x, value: x };
        }),
        onFilter: (value:string, record:UserSurveyType) => record.participant.username === value,
        render: (username:string, userSurvey:UserSurveyType) => (userSurvey.participant ? (
          <a href={`/study/${studyId}/participants/${userSurvey.participant.id}`}>
            {username}
            {' '}
          </a>
        ) : <div />)
      },
      {
        title: 'Window Open',
        dataIndex: 'windowOpen',
        key: 'windowOpen',
        render: renderDateWithoutTime,
        sorter: (a: any, b: any) => moment(a.windowOpen || 0).unix() - moment(b.windowOpen || 0).unix()
      },
      {
        title: 'Window Close',
        dataIndex: 'windowClose',
        key: 'windowClose',
        render: renderDateWithoutTime,
        sorter: (a: any, b: any) => moment(a.windowClose || 0).unix() - moment(b.windowClose || 0).unix()
      },
      {
        title: 'Days Remaining',
        dataIndex: 'daysRemaining',
        key: 'daysRemaining',
        sorter: (a: any, b: any) => a.daysRemaining - b.daysRemaining,
        defaultSortOrder: 'ascend'
      }
    ];

    return (
      <div style={{ flex: '0 0 auto', height: '100%' }}>
        <Table
          columns={columns}
          dataSource={userSurveys}
          scroll={{ y: 'calc(100vh - 150px)' }}
          pagination={false}
          rowClassName={this.rowClassName}
          rowKey="id"
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  userSurveys: selectors.getUserSurveys,
  studyId: selectors.getRequestedStudyId
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getUserSurveys: () => dispatch(getUserSurveysAsync.request())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveysLandingPage));
