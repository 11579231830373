import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import IApplicationState from '../../types/state.types';
import entitySchema from '../schema';
import { AppointmentType } from '../../types/serverTypes/appointmentTypes';

export const selectAppointments = (state: IApplicationState) => {
  return state.entities.appointments;
};

export const getAppointments = createSelector(
  [selectAppointments],
  (appointments): Optional<AppointmentType[]> => {
    if (appointments && appointments.allIds && appointments.allIds.length > 0) {
      const { appointments: denormalizedAppointments } = denormalize({ appointments: appointments.allIds }, entitySchema, { appointments: appointments.byId });
      return toArray(denormalizedAppointments);
    }
    return undefined;
  }
);

export default { getAppointments };
