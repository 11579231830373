import React, { Component } from 'react';
import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import generateUUID from 'uuid';
import { Checkbox, DatePicker } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import DynamicFeedbackItems from '../feedback/DynamicFeedbackItems';
import '../activity.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import ActivityItemLabel from '../ActivityItemLabel';
import { ActivityType, FeedbackType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  creative: boolean;
  editable: boolean;
  setActivity: (props: any) => void;
  setActiveForm: (form: any) => void;
  activity: ActivityType;
}
const itemStyle = {
  width: '100%',
  marginBottom: '10px',
  marginTop: '10px'
};
const defaultFeedback = {
  uuid: '',
  title: '',
  text: '',
  min: undefined,
  max: undefined,
  isOverallFeedback: false
};
enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener,
}

class ActivityReviewForm extends Component<ComponentProps, {}> {
  form = React.createRef<FormInstance>();

  componentDidMount() {
    const { setActiveForm } = this.props;
    setActiveForm(this.form.current);
  }

  updateActivityFeedback = (feedback: FeedbackType[]) => {
    const { setActivity } = this.props;
    setActivity({ feedback });
  };

  updateFeedback = (fb: FeedbackType, index: number) => {
    const { activity, setActivity } = this.props;
    const { feedback } = activity;
    if (feedback) {
      feedback[index] = fb;
      if (feedback[index].isOverallFeedback) {
        setActivity({ overallFeedback: feedback[index] });
      }
      this.updateActivityFeedback(feedback);
    }
  };

  addFeedback = () => {
    const { activity } = this.props;
    const { feedback } = activity;
    let updatedFeedback = feedback ? _.cloneDeep(feedback) : [];
    const newFeedback = _.cloneDeep(defaultFeedback);
    newFeedback.uuid = generateUUID();
    if (!activity.feedback) {
      updatedFeedback = [newFeedback];
    } else {
      updatedFeedback[updatedFeedback.length] = newFeedback;
    }
    this.updateActivityFeedback(updatedFeedback);
  };

  removeFeedback = (index: number) => {
    const { activity } = this.props;
    let i = 0;
    activity.feedback = activity.feedback
      ? activity.feedback.filter((f) => {
        return index !== i++;
      })
      : [];
    this.updateActivityFeedback(activity.feedback);
  };

  onRandomizeQuestionChange = (e: CheckboxChangeEvent) => {
    const { setActivity } = this.props;
    setActivity({ randomizeQuestions: e.target.checked });
  };

  onHealthAssessmentChange = (e: CheckboxChangeEvent) => {
    const { setActivity } = this.props;
    setActivity({ isHealthAssessment: e.target.checked });
  };

  onPublishDateChange = (date: Moment | null, dateString: string) => {
    const { setActivity } = this.props;
    setActivity({ publishDate: dateString });
  };

  render() {
    const { creative, editable, activity } = this.props;
    const {
      randomizeQuestions,
      publishDate,
      feedback,
      options,
      type
    } = activity;
    const displayRandomizeQuestions = [Type.goals, Type.ranking, Type.cyoa].indexOf(parseInt(type)) === -1;
    return (
      <div className="activity-form">
        <Form ref={this.form} key="form" layout="horizontal" colon={false}>
          {displayRandomizeQuestions && (
            <Item
              name="randomizeQuestions"
              style={itemStyle}
              rules={[{ required: false }]}
              initialValue={randomizeQuestions}
            >
              <Checkbox
                disabled={!creative && !editable}
                checked={randomizeQuestions}
                onChange={this.onRandomizeQuestionChange}
              >
                Randomize Questions
              </Checkbox>
            </Item>
          )}
          <Item
            name="publishDate"
            label={(
              <ActivityItemLabel
                label="Publish Date:"
                tooltip="This is when this article will be available to the participants.  The exact moment it will be published is the first second of the day in Eastern Standard Time"
              />
            )}
            style={itemStyle}
            rules={[{ required: false }]}
            initialValue={publishDate ? moment(publishDate) : undefined}
          >
            <DatePicker
              disabled={!creative && !editable}
              onChange={this.onPublishDateChange}
            />
          </Item>
          <h4>Activity Feedback:</h4>
          <DynamicFeedbackItems
            type={0}
            disabled={!creative && !editable}
            options={options || []}
            feedback={feedback || []}
            updateFeedback={this.updateFeedback}
            addFeedback={this.addFeedback}
            removeFeedback={this.removeFeedback}
          />
        </Form>
      </div>
    );
  }
}
export default ActivityReviewForm;
