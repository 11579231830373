import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import {getParticipantNotesAsync, NotesActionTypes, saveParticipantNoteAsync} from './notes.types';
import {NoteType} from "../../types/serverTypes/noteTypes";

const updateNotesReducer = produce((draft, notes) => {
  if (!notes) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(notes), Number));
  const ids = Object.keys(notes);
  for (const id of ids) {
    draft.byId[id] = notes[id];
  }
});

export const notesReducer = (state: NormalizedState<NoteType> = initialNormalizedState, action: NotesActionTypes) => {

  switch (action.type) {
    case getType(getParticipantNotesAsync.success): return updateNotesReducer(state, action.payload);
    case getType(saveParticipantNoteAsync.success): return updateNotesReducer(state, action.payload);
    default: return state;
  }

};

export default notesReducer;
