export interface NotificationVariable {
  name: string;
  type: string;
  format?: string;
}

export enum AdminNotificationMethods {
  EMAIL = 'email',
  SMS = 'sms',
}

export enum MessageDeliveryMethodFlags {
  EMAIL = 1 << 0,
  SMS = 1 << 1,
}

export enum TemplateEditorMode {
  PLAIN = 'plain',
  HTML = 'html'
};