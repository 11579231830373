import { Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';

const AvatarPartSvgViewer = (props) => {
  if (props.svgString) {
    return (
      <Tooltip
        title={<img src={props.svgString} width={200} height={200} />}
        placement="left"
        color="#ccc"
      >
        <img src={props.svgString} width={50} height={50} />
      </Tooltip>
    );
  } else {
    return <Spin />;
  }
};

export default AvatarPartSvgViewer;
