import {
  Dropdown, Menu, Spin, Tooltip
} from 'antd';
import { _ } from 'lodash';
import moment from 'moment';
import React, { Component, UIEventHandler } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { IApiRequestState } from 'types/api.types';
import IApplicationState from 'types/state.types';
import {
  CreateThreadArguments,
  createThreadAsync,
  GetInboxArguments,
  getMessageInboxAsync
} from '../../redux/messages/messages.types';
import * as selectors from '../../redux/selectors';
import EmptyThreadList from './EmptyThreadList';
import NewThreadListItem from './NewThreadListItem';
import './thread.scss';
import ThreadListItem from './ThreadListItem';
import ThreadSearch from './ThreadSearch';
import Toolbar from './Toolbar';
import ToolbarButton from './ToolbarButton';
import { camelCaseToSentenceCase } from '../util/Util';

interface StateProps {
  threads: Optional<MessageThreadType[]>;
  loadInboxStatus: IApiRequestState;
}

interface DispatchProps {
  createThread: typeof createThreadAsync.request;
  loadInbox: typeof getMessageInboxAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {
  setActiveThread: (threadId: number) => void;
  view: string;
  filterBy: string;
  important: boolean;
  archived: boolean;
  sortBy: string;
  refreshThreads: () => void;
  setSortBy: (sortBy:string) => void,
  setFilterBy: (inboxFilter: string) => void,
  setView: (view: string) => void,
  activeThreadId: number;
  userId: number;
  handleSearch: (searchTerm:string) => void;
  searchTerm?: string;
  onScroll: UIEventHandler;
}

class ThreadList extends Component<ComponentProps, {}> {
  readonly state = {
    showNewThread: false,
    threads: this.props.threads
  }

  showNewThread = () => {
    this.setState({
      showNewThread: true
    });
  };

  cancelNewThread = () => {
    this.setState({
      showNewThread: false
    });
  };

  handleCreateThread = async (thread: CreateThreadArguments): Promise<void> => {
    const { createThread } = this.props;
    if (thread && thread.body && thread.participantId && thread.title) {
      createThread(thread);
    }

  };

  changeViewDropdown = (e: any) => {
    const { setActiveThread, setView } = this.props;
    setView(e.key);
    setActiveThread(-1);
  };

  changeViewBy = (e: any) => {
    const { setActiveThread, setFilterBy } = this.props;
    setFilterBy(e.key);
    setActiveThread(-1);
  };

  changeOrderDropdown = (e: any) => {
    const { setSortBy } = this.props;
    setSortBy(e.key);
  };

  renderThreadListItem = (thread: MessageThreadType) => {
    const { activeThreadId } = this.props;
    return <ThreadListItem key={thread.id} setActiveThread={this.setActiveThreadId} activeThreadId={activeThreadId} thread={thread} />;
  }

  getViewDropdown = () => {
    return (
      <Menu>
        <Menu onClick={this.changeViewDropdown}>
          <Menu.Item key="all">
            <Tooltip title="All unarchived message threads">
              <a href="#">All Threads</a>
            </Tooltip>
          </Menu.Item>
          <Menu.Item key="my">
            <Tooltip title="Any unarchived thread that I've participated in">
              <a href="#">My Threads</a>
            </Tooltip>
          </Menu.Item>
          <Menu.Item key="assigned">
            <Tooltip title="Any unarchived thread assigned to me">
              <a href="#">My Assigned Threads</a>
            </Tooltip>
          </Menu.Item>
        </Menu>
      </Menu>
    );
  }

  getFilterByDropdown = () => {
    return (
      <Menu onClick={this.changeViewBy}>
        <Menu.Item key="none">
          <a href="#">None</a>
        </Menu.Item>
        <Menu.Item key="unread">
          <a href="#">Unread</a>
        </Menu.Item>
        <Menu.Item key="important">
          <a href="#">Important</a>
        </Menu.Item>
        <Menu.Item key="archived">
          <a href="#">Archived</a>
        </Menu.Item>
      </Menu>
    );
  }

  orderByDropdown = (
    <Menu onClick={this.changeOrderDropdown}>
      <Menu.Item key="newest">
        <a href="#">Newest</a>
      </Menu.Item>
      <Menu.Item key="oldest">
        <a href="#">Oldest</a>
      </Menu.Item>
    </Menu>
  );

  setActiveThreadId = (id:number) => {
    this.props.setActiveThread(id);
  }

  render() {
    const {
      loadInboxStatus,
      sortBy,
      view,
      filterBy,
      searchTerm
    } = this.props;
    const { showNewThread } = this.state;
    let threads = _.sortBy(this.props.threads, t => moment(t.lastMessageDate));
    if (sortBy === 'newest') {
      threads = threads.reverse();
    }

    return (
      <div className="thread-list">
        <div className="thread-list-header">
          <Toolbar
            title="Threads"
            leftItems={[
              <ToolbarButton
                key="refresh"
                type="refresh"
                animation={loadInboxStatus.isLoading ? 'spinner' : ''}
                action={this.props.refreshThreads}
              />
            ]}
            rightItems={[
              <ToolbarButton key="add" type="add" action={this.showNewThread} />
            ]}
          />
          <ThreadSearch searchTerm={searchTerm} search={this.props.handleSearch} />
          <div className="thread-filter-container">
            <Dropdown className="thread-filter" overlay={this.orderByDropdown} trigger={['click']} placement="bottomRight">
              <a href="#">
                Sort:
                {camelCaseToSentenceCase(sortBy)}
                {' '}
                <i className="far fa-angle-down fa-sm" />
              </a>
            </Dropdown>
            <Dropdown className="thread-filter" overlay={this.getFilterByDropdown()} trigger={['click']} placement="bottomRight">
              <a href="#">
                Filter:
                {camelCaseToSentenceCase(filterBy)}
                {' '}
                <i className="far fa-angle-down fa-sm" />
              </a>
            </Dropdown>
            <Dropdown className="thread-filter" overlay={this.getViewDropdown()} trigger={['click']} placement="bottomRight">
              <a href="#">
                Threads:
                {camelCaseToSentenceCase(view)}
                {' '}
                <i className="far fa-angle-down fa-sm" />
              </a>
            </Dropdown>
          </div>
        </div>
        {showNewThread && <NewThreadListItem createThread={this.handleCreateThread} close={this.cancelNewThread} visible={showNewThread} />}
        <div className="scrollable" onScroll={this.props.onScroll}>
          {threads && threads.length > 0 ? _.map(threads, this.renderThreadListItem) : loadInboxStatus.isLoading ? <Spin className="thread-list-loading" /> : <EmptyThreadList />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  threads: selectors.getMessageThreads,
  loadInboxStatus: selectors.loadMessageInboxStatus
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    createThread: (thread: CreateThreadArguments) => dispatch(createThreadAsync.request(thread)),
    loadInbox: (args: GetInboxArguments) => dispatch(getMessageInboxAsync.request(args))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThreadList);
