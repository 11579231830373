import {getType} from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import {awardParticipantBadgeAsync, BadgesActionTypes, getParticipantBadgesAsync} from './badges.types';
import {initialNormalizedState, NormalizedState} from '../../types/state.types';
import {BadgeType} from "../../types/serverTypes";

const updateBadgesReducer = produce((draft, badges) => {
  if (!badges) {
    return;
  }
  // fully resetting each time, so this will only hold the badges for the most recently selected participant
  draft.byId = [];
  draft.allIds = keys(badges);
  const ids = Object.keys(badges);
  for (const id of ids) {
    draft.byId[id] = badges[id];
  }
});

export const badgesReducer = (state: NormalizedState<BadgeType> = initialNormalizedState, action: BadgesActionTypes) => {

  switch (action.type) {
    case getType(getParticipantBadgesAsync.success):
    case getType(awardParticipantBadgeAsync.success):
      return updateBadgesReducer(state, action.payload);
    default:
      return state;
  }
};

export default badgesReducer;
