import produce from "immer";
import { _ } from "lodash";
import { getType } from "typesafe-actions";
import {
  initialNormalizedState,
  NormalizedState,
} from "../../types/state.types";
import {
  createAppointmentAsync,
  deleteAppointmentAsync,
  getAppointmentsAsync,
  updateAppointmentAsync,
  updateAppointments,
} from "./appointments.types";
import {AppointmentType} from "../../types/serverTypes/appointmentTypes";

const updateAppointmentsReducer = produce((draft, appointments) => {
  if (!appointments) {
    return;
  }
  draft.allIds = _.map(_.keys(appointments));
  const ids = Object.keys(appointments);
  const draftByIdKeys = Object.keys(draft.byId);
  draftByIdKeys.forEach(byId => {
    if(draft.allIds.findIndex(a => a === byId) === -1)
    {
      delete draft.byId[byId];
    }
  });
  for (const id of ids) {
    if (appointments[id].deleteDate) {
      draft.allIds = draft.allIds.filter((allId: string) => allId !== id);
      delete draft.byId[id];
    }
    else {
      draft.byId[id] = appointments[id];
    }
  }
});

const deleteAppointmentsReducer = produce((draft, appointments) => {
  if (!appointments) {
    return;
  }
  const ids = Object.keys(appointments);
  for (const idToDelete of ids) {
    draft.allIds = draft.allIds.filter((id: string) => id !== idToDelete);
    delete draft.byId[idToDelete];
  }
});

export const appointmentsReducer = (state: NormalizedState<AppointmentType> = initialNormalizedState, action: any) => {
  switch (action.type) {
    case getType(getAppointmentsAsync.success):
    case getType(createAppointmentAsync.success):
    case getType(updateAppointmentAsync.success):
    case getType(updateAppointments):
      return updateAppointmentsReducer(state, action.payload);
    case getType(deleteAppointmentAsync.success):
      return deleteAppointmentsReducer(state, action.payload);
    default:
      return state;
  }
};

export default appointmentsReducer;
