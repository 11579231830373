import { ActionType, createAsyncAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import {PaymentType} from "../../types/serverTypes/paymentTypes";
import {MarkPaidType} from "../../types";

export const requestExitInterviewPaymentAsync = createAsyncAction(
  'hmp/admin/REQUEST_EXIT_INTERVIEW_PAYMENT_REQUEST',
  'hmp/admin/REQUEST_EXIT_INTERVIEW_PAYMENT_SUCCESS',
  'hmp/admin/REQUEST_EXIT_INTERVIEW_PAYMENT_FAILURE'
)<number, Optional<NormalizedType<PaymentType>>, Error>();

export const requestAdHocPaymentAsync = createAsyncAction(
  'hmp/admin/REQUEST_AD_HOCPAYMENT_REQUEST',
  'hmp/admin/REQUEST_AD_HOC_PAYMENT_SUCCESS',
  'hmp/admin/REQUEST_AD_HOC_PAYMENT_FAILURE'
)<number, Optional<NormalizedType<PaymentType>>, Error>();

export const getParticipantPaymentsAsync = createAsyncAction(
  'hmp/admin/GET_PARTICIPANT_PAYMENTS_REQUEST',
  'hmp/admin/GET_PARTICIPANT_PAYMENTS_SUCCESS',
  'hmp/admin/GET_PARTICIPANT_PAYMENTS_FAILURE'
)<number, Optional<NormalizedType<PaymentType>>, Error>();

export const getPaymentsAsync = createAsyncAction(
  'hmp/admin/GET_PAYMENTS_REQUEST',
  'hmp/admin/GET_PAYMENTS_SUCCESS',
  'hmp/admin/GET_PAYMENTS_FAILURE'
)<void, Optional<NormalizedType<PaymentType>>, Error>();

export const getPaymentsReportAsync = createAsyncAction(
  'hmp/admin/GET_PAYMENTS_REPORT_REQUEST',
  'hmp/admin/GET_PAYMENTS_REPORT_SUCCESS',
  'hmp/admin/GET_PAYMENTS_REPORT_FAILURE'
)<void, Optional<NormalizedType<PaymentType>>, Error>();

export const markPaidAsync = createAsyncAction(
  'hmp/admin/MARK_PAID_REQUEST',
  'hmp/admin/MARK_PAID_SUCCESS',
  'hmp/admin/MARK_PAID_FAILURE'
)<MarkPaidType, Optional<NormalizedType<PaymentType>>, Error>();

const paymentActions = {
  getPaymentsAsync,
  getPaymentsReportAsync,
  requestExitInterviewPaymentAsync,
  requestAdHocPaymentAsync,
  getParticipantPaymentsAsync,
  markPaidAsync
};

export type PaymentActionTypes = ActionType<typeof paymentActions>;
