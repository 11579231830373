import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  getParticipantPaymentsAsync,
  getPaymentsAsync,
  getPaymentsReportAsync,
  markPaidAsync,
  PaymentActionTypes, requestAdHocPaymentAsync,
  requestExitInterviewPaymentAsync
} from './payment.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import {PaymentType} from "../../types/serverTypes/paymentTypes";

const updatePaymentsReducer = produce((draft, payments) => {
  if (!payments) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(payments), Number));
  const ids = Object.keys(payments);
  for (const id of ids) {
    draft.byId[id] = payments[id];
  }
});

export const paymentReducer = (state: NormalizedState<PaymentType> = initialNormalizedState, action: PaymentActionTypes) => {

  switch (action.type) {
    case getType(getPaymentsAsync.success): return updatePaymentsReducer(state, action.payload);
    case getType(getPaymentsReportAsync.success): return updatePaymentsReducer(state, action.payload);
    case getType(getParticipantPaymentsAsync.success): return updatePaymentsReducer(state, action.payload);
    case getType(requestExitInterviewPaymentAsync.success): return updatePaymentsReducer(state, action.payload);
    case getType(requestAdHocPaymentAsync.success): return updatePaymentsReducer(state, action.payload);
    case getType(markPaidAsync.success): return updatePaymentsReducer(state, action.payload);
    default: return state;
  }
};

export default paymentReducer;
