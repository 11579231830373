import React, { Component } from 'react';
import './thread.scss';
import {
  faEllipsisH
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class NoThreadSelected extends Component<{}, {}> {
  render() {
    return (
      <div className="thread-content-no-selected">
        <span>No thread selected.</span>
      </div>
    );
  }
}

export default NoThreadSelected;
