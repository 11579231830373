import React from 'react';
import { CascaderOptionType } from 'antd/lib/cascader';
import './resourceEditPage.scss';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { RouteComponentProps, withRouter } from 'react-router';
import { getType } from 'typesafe-actions';

import ResourceEditor from '../../../components/resources/editors/ResourceEditor';
import {
  getRequestedArticleAsync,
  saveArticleAsync,
  updateNewArticleId,
} from '../../../redux/article/article.types';
import * as selectors from '../../../redux/selectors';
import { loadResourceTopicsAsync } from '../../../redux/topics/topic.types';
import { IApiRequestStatus } from '../../../types/api.types';
import IApplicationState from '../../../types/state.types';
import { clearStatus } from '../../../redux/api/api.types';
import { ArticleType } from '../../../types/serverTypes/articleTypes';

interface StateProps {
  studyId: number;
  resourceTopics: CascaderOptionType[];
  article: Optional<ArticleType>;
  loadResourceTopicsStatus: IApiRequestStatus;
  getRequestedEditResourceStatus: IApiRequestStatus;
  saveArticleStatus: IApiRequestStatus;
  requestedArticleId?: number;
  newArticleId?: number;
}

interface DispatchProps {
  loadResourceTopics: typeof loadResourceTopicsAsync.request;
  getRequestedArticle: typeof getRequestedArticleAsync.request;
  saveArticle: typeof saveArticleAsync.request;
  clearLoadStatus: typeof clearStatus;
  updateNewArticleId: typeof updateNewArticleId;
}

interface ComponentProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {}

interface ComponentState {
  shouldCloseOnSave: boolean;
}

class ResourceEditPage extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      shouldCloseOnSave: false,
    };
  }

  componentDidMount(): void {
    const { loadResourceTopics, getRequestedArticle, requestedArticleId } =
      this.props;
    loadResourceTopics();

    if (requestedArticleId && requestedArticleId != -1) {
      getRequestedArticle();
    }
  }

  componentDidUpdate(prevProps: ComponentProps) {
    const {
      saveArticleStatus,
      requestedArticleId,
      newArticleId,
      clearLoadStatus,
      studyId,
      history,
      updateNewArticleId,
    } = this.props;
    if (!prevProps.saveArticleStatus.isSuccess && saveArticleStatus.isSuccess) {
      // Need to reset the state.ui.articles.newArticleId to -1 after each save
      updateNewArticleId(-1);
      // If "Save and Close" button is clicked, we skip all navigation logic and navigation back
      if (this.state.shouldCloseOnSave) {
        this.props.history.goBack();
        return;
      }
      // If we're not editing an existing (non-new) article, navigate to the edit screen for the new article.
      if (requestedArticleId == -1 && newArticleId != -1) {
        clearLoadStatus(getType(saveArticleAsync.success));
        // Upon successful NEW article creation, navigate to the edit page for said article
        history.push(`/study/${studyId}/resources/edit/${newArticleId}`);
      }
    }
  }

  saveArticle = (article: ArticleType, close: boolean) => {
    this.props.saveArticle(article);
    this.setState({
      shouldCloseOnSave: close,
    });
  };

  render() {
    const {
      resourceTopics,
      article,
      loadResourceTopicsStatus,
      getRequestedEditResourceStatus,
    } = this.props;

    // TODO change all of this to allow creating a new article or have a separate ResourceNewPage.

    if (loadResourceTopicsStatus.isLoading) {
      return <div>Loading Topics</div>;
    }

    if (getRequestedEditResourceStatus.isLoading) {
      return <div>Loading Article</div>;
    }

    if (loadResourceTopicsStatus.isError) {
      return (
        <div>
          Error loading topics:
          {loadResourceTopicsStatus.errorMessage}
        </div>
      );
    }

    if (getRequestedEditResourceStatus.isError) {
      return (
        <div>
          Error loading article: {getRequestedEditResourceStatus.errorMessage}
        </div>
      );
    }

    if (!resourceTopics) {
      return (
        <div>
          The topics did not load properly. No error message was returned from
          the server.
        </div>
      );
    }

    if (resourceTopics.length <= 0) {
      return (
        <div>
          No topics were loaded. You need to create topics first, so that
          articles can be categorized appropriately.
        </div>
      );
    }

    return (
      <div id="hmp-resource-edit-page">
        <ResourceEditor
          resourceTopics={resourceTopics}
          originalArticle={article}
          saveArticle={this.saveArticle}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    studyId: selectors.getRequestedStudyId,
    resourceTopics: selectors.getResourceTopicsCascader,
    article: selectors.getRequestedEditResource,
    loadResourceTopicsStatus: selectors.loadResourceTopicsStatus,
    getRequestedEditResourceStatus: selectors.getRequestedEditResourceStatus,
    saveArticleStatus: selectors.saveArticleStatus,
    requestedArticleId: selectors.getRequestedEditResourceId,
    newArticleId: selectors.selectNewArticleId,
  }
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadResourceTopics: () => dispatch(loadResourceTopicsAsync.request()),
    getRequestedArticle: () => dispatch(getRequestedArticleAsync.request()),
    saveArticle: (article: ArticleType) =>
      dispatch(saveArticleAsync.request(article)),
    clearLoadStatus: (type: string) => dispatch(clearStatus(type)),
    updateNewArticleId: (id: number) => dispatch(updateNewArticleId(id)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResourceEditPage)
);
