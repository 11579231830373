// @ts-nocheck
import React, { Component } from 'react';
import uuid from 'uuid';
import { Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import './treeNode.scss';
import { TreeOptionType, TreeQuestionType } from '../../../types/serverTypes/activityTypes';
import { ActivityTreeNode } from '../../../types';
import TreeQuestionNode from './TreeQuestionNode';
import TreeOptionItem from './TreeOptionItem';

interface ComponentProps {
  disabled: boolean;
  firstId?: string;
  tree?: ActivityTreeNode;
  updateTree: (tree: ActivityTreeNode, firstId: string) => void;
}
interface ComponentState {
  firstId: string;
  questions: Map<string, TreeQuestionType>;
  options: Map<string, TreeOptionType>;
  isUnsaved: boolean;
}

class ChooseYourOwnAdventure extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);
    if (props.tree && props.firstId) {
      this.state = {
        firstId: props.firstId,
        questions: new Map(props.tree.questions),
        options: new Map(props.tree.options),
        isUnsaved: false
      };
    } else {
      const firstId = uuid();
      const questions = new Map();
      const options = new Map();
      questions.set(firstId, {
        id: firstId,
        optionIds: [],
        text: ''
      });
      this.state = {
        firstId,
        questions,
        options,
        isUnsaved: false
      };
    }
  }

  componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState) {
    if (!prevState.isUnsaved && this.state.isUnsaved) {
      const { updateTree } = this.props;
      const { questions, options, firstId } = this.state;
      updateTree({ questions, options }, firstId);
      this.setState({
        isUnsaved: false
      });
    }
  }

  addQuestion = (optionId: string) => {
    const { questions, options } = this.state;
    const option = options.get(optionId);
    if (option) {
      const id = uuid();
      this.setState({
        questions: new Map(
          questions.set(id, {
            id,
            optionIds: [],
            text: ''
          })
        ),
        options: new Map(
          options.set(optionId, {
            ...option,
            questionId: id
          })
        ),
        isUnsaved: true
      });
    }
  };

  addOption = (questionId: string) => {
    const { questions, options } = this.state;
    const question = questions.get(questionId);
    if (question) {
      const id = uuid();
      this.setState({
        questions: new Map(
          questions.set(questionId, {
            ...question,
            optionIds: [...question.optionIds, id]
          })
        ),
        options: new Map(
          options.set(id, {
            id,
            text: ''
          })
        ),
        isUnsaved: true
      });
    }
  };

  updateQuestion = (question: TreeQuestionType) => {
    const { questions } = this.state;
    this.setState({
      questions: new Map(questions.set(question.id.toString(), question)),
      isUnsaved: true
    });
  };

  updateOption = (option: TreeOptionType) => {
    const { options } = this.state;
    this.setState({
      options: new Map(options.set(option.id.toString(), option)),
      isUnsaved: true
    });
  };

  removeQuestion = (id: string|number) => {
    const { firstId, questions, options } = this.state;
    if (id === firstId) {
      // If id equals firstId, then we delete the whole tree
      const newFirstId = uuid();
      const newQuestions = new Map();
      newQuestions.set(newFirstId, {
        id: newFirstId,
        optionIds: [],
        text: ''
      });
      this.setState({
        firstId: newFirstId,
        questions: newQuestions,
        options: new Map(),
        isUnsaved: false
      });
    } else {
      const question = questions.get(id);
      if (question) {
        // Remove references to id from options.questionId
        options.forEach(o => {
          if (o.questionId === id) {
            options.set(o.id.toString(), {
              ...o,
              questionId: undefined
            });
          }
        });
        questions.delete(id);
        // Delete child options of the removed question
        question.optionIds.forEach((optionId) => {
          this.removeOption(optionId);
        });
        this.setState({
          questions: new Map(questions),
          options: new Map(options),
          isUnsaved: true
        });
      }
    }
  };

  removeOption = (id: string) => {
    const { questions, options } = this.state;
    const option = options.get(id);
    if (option) {
      // Delete child questions of removed option
      if (option.questionId) {
        this.removeQuestion(option.questionId);
      }
      // Remove references to removed option from questions.optionIds
      questions.forEach(q => {
        if (q.optionIds.includes(id)) {
          questions.set(q.id.toString(), {
            ...q,
            optionIds: q.optionIds
              .filter((optionId) => optionId !== id)
          });
        }
      });
      options.delete(id);
      this.setState({
        questions: new Map(questions),
        options: new Map(options),
        isUnsaved: true
      });
    }
  };

  getQuestion = (id: string): TreeQuestionType | undefined => {
    return this.state.questions.get(id);
  };

  getOption = (id: string): TreeOptionType | undefined => {
    return this.state.options.get(id);
  };

  mapQuestionToTreeNode = (id: string): DataNode => {
    const { disabled } = this.props;
    const { questions } = this.state;
    const question = questions.get(id);
    return {
      title: (
        <TreeQuestionNode
          disabled={disabled}
          question={question!}
          updateQuestion={this.updateQuestion}
          removeQuestion={this.removeQuestion}
          addOption={this.addOption}
        />
      ),
      key: `question-${id}`,
      children: question!.optionIds.map(this.mapOptionToTreeNode)
    };
  };

  mapOptionToTreeNode = (id: string): DataNode => {
    const { disabled } = this.props;
    const { options } = this.state;
    const option = options.get(id);
    return {
      title: (
        <TreeOptionItem
          disabled={disabled}
          option={option!}
          addQuestion={this.addQuestion}
          updateOption={this.updateOption}
          removeOption={this.removeOption}
        />
      ),
      key: `option-${id}`,
      children: option!.questionId
        ? [this.mapQuestionToTreeNode(option!.questionId)]
        : []
    };
  };

  generateTreeData = () => {
    const { firstId } = this.state;
    return [this.mapQuestionToTreeNode(firstId)];
  };

  render() {
    return (
      <Tree
        showLine
        defaultExpandAll
        treeData={this.generateTreeData()}
        selectable={false}
      />
    );
  }
}

export default ChooseYourOwnAdventure;
