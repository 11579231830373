import { ActionType, createAsyncAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import { SocialJsonParamType } from '../user/socialUtil';
import {CommentType, FlagType, PostType} from "../../types/serverTypes/forumTypes";

export const getFlagsAsync = createAsyncAction(
  'hmp/admin/GET_FLAGS_REQUEST',
  'hmp/admin/GET_FLAGS_SUCCESS',
  'hmp/admin/GET_FLAGS_FAILURE'
)<SocialJsonParamType[], Optional<NormalizedType<FlagType>>, Error>();

export const resolveFlagsAsync = createAsyncAction(
  'hmp/admin/RESOLVE_FLAG_REQUEST',
  'hmp/admin/RESOLVE_FLAG_SUCCESS',
  'hmp/admin/RESOLVE_FLAG_FAILURE'
)<{ids: number[], resolution: string}, Optional<NormalizedType<FlagType>>, Error>();

export interface HideContentPayloadType {
  type: string;
  typeId: number;
}

export const hideContentAsync = createAsyncAction(
  'hmp/admin/HIDE_CONTENT_REQUEST',
  'hmp/admin/HIDE_CONTENT_SUCCESS',
  'hmp/admin/HIDE_CONTENT_FAILURE'
)<HideContentPayloadType, Optional<NormalizedType<CommentType|PostType>>, Error>();

export const unhideContentAsync = createAsyncAction(
  'hmp/admin/UNHIDE_CONTENT_REQUEST',
  'hmp/admin/UNHIDE_CONTENT_SUCCESS',
  'hmp/admin/UNHIDE_CONTENT_FAILURE'
)<HideContentPayloadType, Optional<NormalizedType<CommentType|PostType>>, Error>();

const flagsActions = {
  getFlagsAsync,
  resolveFlagsAsync,
  hideContentAsync,
  unhideContentAsync
};

export type FlagsActionTypes = ActionType<typeof flagsActions>;
