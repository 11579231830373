import React, { Component } from 'react';
import IApplicationState from 'types/state.types';
import { connect } from 'react-redux';
import { Switch } from 'antd';
import axios from '../../redux/api';
import './filter.scss';

interface StateProps {
}

interface ComponentProps extends StateProps {
  value: boolean;
  label: string;
  onChange: (checked: boolean) => void;
}

class SwitchFilter extends Component<ComponentProps, {}> {
  componentDidMount() {
  }

  render() {
    const { value, label, onChange } = this.props;
    return (
      <span className="switch-filter">
        {label}
        {' '}
        <Switch checked={value} onChange={onChange} />
      </span>
    );
  }
}

export default SwitchFilter;
