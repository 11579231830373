import { ActionType, createAsyncAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import { StringLookupType } from '../../types/serverTypes';

export interface GetStringLookupArguments {
  types?: string[];
}

export interface UpdateStringLookupArguments {
  id: number;
  stringLookup: string;
}

export const getStringLookupsAsync = createAsyncAction(
  'hmp/admin/GET_STRING_LOOKUPS_REQUEST',
  'hmp/admin/GET_STRING_LOOKUPS_SUCCESS',
  'hmp/admin/GET_STRING_LOOKUPS_FAILURE'
)<
  GetStringLookupArguments,
  Optional<NormalizedType<StringLookupType>>,
  Error
>();

export const getStringLookupAsync = createAsyncAction(
  'hmp/admin/GET_STRING_LOOKUP_REQUEST',
  'hmp/admin/GET_STRING_LOOKUP_SUCCESS',
  'hmp/admin/GET_STRING_LOOKUP_FAILURE'
)<number, Optional<NormalizedType<StringLookupType>>, Error>();

export const updateStringLookupAsync = createAsyncAction(
  'hmp/admin/UPDATE_STRING_LOOKUP_REQUEST',
  'hmp/admin/UPDATE_STRING_LOOKUP_SUCCESS',
  'hmp/admin/UPDATE_STRING_LOOKUP_FAILURE'
)<
  UpdateStringLookupArguments,
  Optional<NormalizedType<StringLookupType>>,
  Error
>();

const stringLookupActions = {
  getStringLookupsAsync,
  getStringLookupAsync,
  updateStringLookupAsync,
};

export type StringLookupActionTypes = ActionType<typeof stringLookupActions>;
