import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import { getType } from 'typesafe-actions';
import {
  initialNormalizedState,
  NormalizedState,
} from '../../types/state.types';

import { AvailabilityType } from '../../../../server/src/types/appointmentTypes';
import {
  createAvailabilityAsync,
  deleteAvailabilityAsync,
  getAvailabilitiesAsync,
} from './availability.types';

const updateAvailabilitiesReducer = produce((draft, availabilities) => {
  if (!availabilities) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(availabilities), String));
  const ids = Object.keys(availabilities);
  for (const id of ids) {
    draft.byId[id] = availabilities[id];
  }
});

const deleteAvailabilityReducer = produce(
  (draft, availabilityIdToDelete: number) => {
    if (!availabilityIdToDelete) {
      return;
    }
    draft.allIds = draft.allIds.filter(
      (id: string) => id !== availabilityIdToDelete.toString()
    );
    delete draft.byId[availabilityIdToDelete];
  }
);

export const availabilitiesReducer = (state: NormalizedState<AvailabilityType> = initialNormalizedState, action: any) => {
  switch (action.type) {
    case getType(getAvailabilitiesAsync.success):
    case getType(createAvailabilityAsync.success):
      return updateAvailabilitiesReducer(state, action.payload);
    case getType(deleteAvailabilityAsync.success):
      return deleteAvailabilityReducer(state, action.payload);
    default:
      return state;
  }
};

export default availabilitiesReducer;
