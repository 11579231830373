import React, { Component } from 'react';
import * as _ from 'lodash';
import generateUUID from 'uuid';
import Form, { FormInstance } from 'antd/lib/form';
import '../activity.scss';
import { InputNumber } from 'antd';
import DynamicQuestionItems from '../questions/DynamicQuestionItems';
import DynamicOptionItems from '../options/DynamicOptionItems';
import ChooseYourOwnAdventure from '../chooseYourOwnAdventure/ChooseYourOwnAdventure';
import { ActivityFormStatus } from '../ActivityFormContainer';
import ScreenerQuestionForm from '../screener/ScreenerQuestionForm';
import { ActivityType, OptionType, QuestionType } from '../../../types/serverTypes/activityTypes';
import { ActivityTreeNode } from '../../../types';

const { Item } = Form;

interface ComponentProps {
  creative: boolean;
  editable: boolean;
  setActivity: (props: any) => void;
  setActiveForm: (form: any) => void;
  activity: ActivityType;
}
enum Type {
  zero,
  quiz,
  category,
  category_no_answer,
  cyoa,
  fill_in_the_blank,
  goals,
  ranking,
  screener,
}
const itemStyle = {
  width: '100%',
  marginBottom: '10px',
  marginTop: '10px'
};
const defaultQuestion = {
  text: '',
  uuid: '',
  randomizeOptions: false,
  numBlanks: undefined,
  options: [],
  correctOptionId: undefined,
  feedback: []
};
const defaultOption = {
  text: '',
  uuid: '',
  points: 0,
  isCorrect: false,
  correctPosition: undefined,
  feedback: undefined
};

class ActivityQuestionForm extends Component<ComponentProps, {}> {
  form = React.createRef<FormInstance>();

  componentDidMount() {
    const { setActiveForm } = this.props;
    if (this.form.current) {
      setActiveForm(this.form.current);
    }
  }

  // Action Handlers
  updateQuestions = (questions: QuestionType[]) => {
    const { setActivity, activity } = this.props;
    activity.questions = questions;
    setActivity({ questions });
  };

  updateOptions = (options: OptionType[]) => {
    const { setActivity, activity } = this.props;
    activity.options = options;
    if (activity.typeId === Type.ranking) {
      // If activity type is ranking, the correctPosition
      // is just the position in the array.
      for (let i = 0; i < options.length; i++) {
        options[i].correctPosition = i;
      }
    }
    setActivity({ options });
  };

  addQuestion = () => {
    const { activity } = this.props;
    const { questions } = activity;
    let updatedQuestions = questions ? _.cloneDeep(questions) : [];
    const newQuestion = _.cloneDeep({
      ...defaultQuestion,
      uuid: generateUUID()
    });
    if (!questions) {
      updatedQuestions = [newQuestion];
    } else {
      updatedQuestions[updatedQuestions.length] = newQuestion;
    }
    this.updateQuestions(updatedQuestions);
  };

  removeQuestion = (index: number) => {
    const { activity } = this.props;
    const { questions } = activity;
    let i = 0;
    const update = questions
      ? questions.filter((q) => {
        return index !== i++;
      })
      : [];
    this.updateQuestions(update);
  };

  updateQuestion = (q: QuestionType, index: number) => {
    const { activity } = this.props;
    const { questions } = activity;
    if (questions) {
      questions[index] = q;
      this.updateQuestions(questions);
    }
  };

  updateOption = (option: OptionType, index: number) => {
    const { activity } = this.props;
    const { options } = activity;
    if (options) {
      options[index] = _.cloneDeep(option);
      this.updateOptions(options);
    }
  };

  addOption = () => {
    const { activity } = this.props;
    const { options } = activity;
    let updatedOptions = options ? _.cloneDeep(options) : [];
    const newOption = _.cloneDeep({
      ...defaultOption,
      uuid: generateUUID()
    });
    if (!options) {
      updatedOptions = [newOption];
    } else {
      updatedOptions[updatedOptions.length] = newOption;
    }
    this.updateOptions(updatedOptions);
  };

  removeOption = (index: number) => {
    const { activity } = this.props;
    let { options } = activity;
    let i = 0;
    options = options
      ? options.filter((o) => {
        return index !== i++;
      })
      : [];
    this.updateOptions(options);
  };

  swapOptionPosition = (indexA: number, indexB: number) => {
    const { activity } = this.props;
    const { options } = activity;
    if (
      options
      && ((indexA < indexB && indexB < options.length)
        || (indexA > indexB && indexB >= 0))
    ) {
      const temp = _.cloneDeep(options[indexA]);
      options[indexA] = _.cloneDeep(options[indexB]);
      options[indexB] = temp;
      this.updateOptions(options);
    }
  };

  onNumToSelectChange = (value: string | number | undefined) => {
    const { activity, setActivity } = this.props;
    if (value) {
      activity.numToSelect = Number(value);
      setActivity({ numToSelect: value });
    }
  };

  validateNumToSelect = (rule, value) => {
    const { activity } = this.props;
    const { numToSelect } = activity;
    try {
      if (!numToSelect) {
        throw new Error('numToSelect is required.');
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  /**
   * Validation method which determines whether every question
   * and option has the text property populated
   *
   * *NOTE* doesn't test for cyclical graphs
   *
   * @returns boolean representing whether the passed in tree structure
   * is valid
   */
  validateTree = (rule, value) => {
    const { activity } = this.props;
    try {
      if (!activity.tree) {
        throw new Error('Invalid tree.');
      }
      const { questions, options } = activity.tree;
      if (_.isEmpty(questions) || questions.size === 0) {
        throw new Error('No questions defined.');
      }
      for (const entry of questions) {
        const [id, question] = entry;
        if (
          id !== question.id
          || !question.text
          || question.text.length === 0
        ) {
          throw new Error('Questions must have text.');
        }
      }
      if (_.isEmpty(options) || options.size === 0) {
        throw new Error('No options defined.');
      }
      for (const entry of options) {
        const [id, option] = entry;
        if (id !== option.id || !option.text || option.text.length === 0) {
          throw new Error('Options must have text.');
        }
        if (!option.questionId) {
          throw new Error('Every option must have a question.');
        }
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  updateTree = (tree: ActivityTreeNode, firstId: string) => {
    const { setActivity } = this.props;
    if (tree) {
      setActivity({ tree, firstId });
    }
  };

  // Custom Field Validators
  validateQuestions = (rule, value) => {
    const {
      activity
    } = this.props;
    try {
      const { questions } = activity;
      if (!questions || questions.length === 0) {
        throw new Error('No questions defined');
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  validateOptions = (rule, value) => {
    const {
      activity
    } = this.props;
    try {
      const { options } = activity;
      if (options && options.length) {
        options.forEach((o) => {
          if (o.text.length === 0) {
            throw new Error('Option text is required');
          }
          if (activity.typeId === Type.screener && !o.groupId) {
            throw new Error('Screener options must be assigned to a group');
          }
        });
      } else {
        throw new Error('No options defined');
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  // Renderers
  render() {
    const { activity, creative, editable } = this.props;
    const {
      questions, options, numToSelect, tree, firstId, typeId
    } = activity;
    const displayActivityOptions = [
      Type.category,
      Type.category_no_answer,
      Type.ranking,
      Type.goals
    ].indexOf(typeId) > -1;
    const displayQuestions = [Type.goals, Type.ranking, Type.cyoa, Type.screener].indexOf(typeId) === -1;
    return (
      <div className="activity-form">
        {typeId !== Type.cyoa && typeId !== Type.screener
          && (
          <Form ref={this.form} key="form" layout="horizontal" colon={false}>
            {typeId === Type.goals && (
            <Item
              required
              key="numToSelect"
              name="numToSelect"
              style={itemStyle}
              validateTrigger={['onChange', 'onSubmit', 'onBlur', 'onFocus']}
              rules={[
                {
                  required: true,
                  message:
                      'Please set how many goals the participant is required to select.',
                  validator: this.validateNumToSelect
                }
              ]}
            >
              <span>
                Require user to select
                {' '}
                <InputNumber
                  disabled={!creative && !editable}
                  defaultValue={numToSelect}
                  min={0}
                  onChange={this.onNumToSelectChange}
                />
                {' '}
                items.
              </span>
            </Item>
            )}
            {displayActivityOptions && (
            <Item
              name="options"
              style={itemStyle}
              validateTrigger={['onChange', 'onSubmit', 'onBlur', 'onFocus']}
              rules={[{ required: true, validator: this.validateOptions }]}
            >
              <h4>Activity Options:</h4>
              <DynamicOptionItems
                typeId={typeId}
                disabled={!creative && !editable}
                options={options || []}
                updateOption={this.updateOption}
                addOption={this.addOption}
                removeOption={this.removeOption}
                swapOptionPosition={this.swapOptionPosition}
              />
            </Item>
            )}
            {displayQuestions && (
            <Item
              name="questions"
              style={itemStyle}
              validateTrigger={['onChange', 'onSubmit', 'onBlur', 'onFocus']}
              rules={[{ required: true, validator: this.validateQuestions }]}
            >
              <DynamicQuestionItems
                typeId={typeId}
                disabled={!creative && !editable}
                questions={questions || []}
                options={options || []}
                updateQuestion={this.updateQuestion}
                addQuestion={this.addQuestion}
                removeQuestion={this.removeQuestion}
              />
            </Item>
            )}
          </Form>
          )}
        {typeId === Type.cyoa && (
          <Form ref={this.form} key="cyoa-form" layout="vertical" colon={false}>
            <Item
              key={`tree-${firstId}`}
              name={`tree-${firstId}`}
              validateTrigger={['onChange', 'onSubmit', 'onBlur', 'onFocus']}
              rules={[{ required: true, validator: this.validateTree }]}
            >
              <ChooseYourOwnAdventure
                disabled={!creative && !editable}
                tree={tree}
                firstId={firstId}
                updateTree={this.updateTree}
              />
            </Item>
          </Form>
        )}
        {typeId === Type.screener && (
          <Form ref={this.form} key="screener-form" layout="vertical" colon={false}>
            <ScreenerQuestionForm
              disabled={!editable && !creative}
              questions={questions}
              options={options}
              updateQuestions={this.updateQuestions}
              updateOptions={this.updateOptions}
            />
          </Form>
        )}
      </div>
    );
  }
}

export default ActivityQuestionForm;
