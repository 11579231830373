import React from 'react';
import uniqBy from 'lodash/uniqBy';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../redux/selectors';
import IApplicationState from '../../types/state.types';
import { AdminUserType } from '../../types/serverTypes/adminTypes';
import FeatureNames from '../../components/util/FeatureNames';
import { hasFeature } from '../../service/studyService';

interface StateProps {
  user: Optional<AdminUserType>;
  isNonYAB: boolean;
}
interface StateType {
  hasForum: boolean;
  authorizedStudies: { studyId: number; studyName: string }[];
}

interface ComponentProps extends StateProps {}

class LandingPage extends React.Component<ComponentProps, {}> {
  readonly state: StateType = {
    hasForum: false,
    authorizedStudies: [],
  };

  componentDidMount = async () => {
    const _authorizedStudies = this.props.user
      ? getAuthorizedStudies(this.props.user)
      : null;
    this.setState({ authorizedStudies: _authorizedStudies });

    const hasForum = await hasFeature(
      FeatureNames.FORUM,
      _authorizedStudies ? _authorizedStudies[0].studyId : 1
    );
    this.setState({ hasForum });
  };
  render() {
    const { user, isNonYAB } = this.props;

    if (!user) {
      return <Redirect to="/auth/login" />;
    }

    // The user did not go to a specific /admin or /study URL, so let's take a look at their permissions and try to
    // redirect them to the best place possible.

    // If Admin redirect to /admin
    if (isAdmin(user)) {
      return <Redirect to="/admin" />;
    }

    // If == 1 Study redirect to /study/id
    if (
      this.state.authorizedStudies &&
      this.state.authorizedStudies.length === 1
    ) {
      if (isNonYAB) {
        return (
          <Redirect to={`/study/${this.state.authorizedStudies[0].studyId}`} />
        );
      }

      if (this.state.hasForum) {
        return (
          <Redirect
            to={`/study/${this.state.authorizedStudies[0].studyId}/forums`}
          />
        );
      } else return null;
    }

    // If > Study display landing page and let them pick
    return (
      <div>
        <h1>
          Welcome
          {user.firstName}
        </h1>
        {this.state.authorizedStudies.map((study) => {
          return (
            <div key={study.studyId}>
              <Link to={`/study/${study.studyId}`}>{study.studyName}</Link>
            </div>
          );
        })}
      </div>
    );
  }
}

const isAdmin = (user: AdminUserType) => {
  for (let i = 0; i < user.roles.length; i++) {
    if (user.roles[i].role.type === 'ADMIN') {
      return true;
    }
  }
  return false;
};

const getAuthorizedStudies = (
  user: AdminUserType
): { studyId: number; studyName: string }[] => {
  const studyRoles = user.roles.filter((role) => {
    return role.studyId != undefined;
  });
  const uniqueStudyRoles = uniqBy(studyRoles, 'studyId').map((role) => {
    return {
      studyId: role.studyId,
      studyName: role.studyName,
    };
  });
  return uniqueStudyRoles as { studyId: number; studyName: string }[];
};

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    user: selectors.getUser,
    isNonYAB: selectors.isNonYAB,
  }
);

export default connect(mapStateToProps)(LandingPage);
