import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import {ArticleType} from "../../types/serverTypes/articleTypes";

export const loadArticlesAsync = createAsyncAction(
  'hmp/admin/LOAD_ARTICLES_REQUEST',
  'hmp/admin/LOAD_ARTICLES_SUCCESS',
  'hmp/admin/LOAD_ARTICLES_FAILURE'
)<{query?: string, topicIds?: number[], includeDeleted?: boolean, includeUnpublished?: boolean}, Optional<NormalizedType<ArticleType>>, Error>();

export const getRequestedArticleAsync = createAsyncAction(
  'hmp/admin/GET_ARTICLE_REQUEST',
  'hmp/admin/GET_ARTICLE_SUCCESS',
  'hmp/admin/GET_ARTICLE_FAILURE'
)<void, Optional<NormalizedType<ArticleType>>, Error>();

export const saveArticleAsync = createAsyncAction(
  'hmp/admin/SAVE_ARTICLE_REQUEST',
  'hmp/admin/SAVE_ARTICLE_SUCCESS',
  'hmp/admin/SAVE_ARTICLE_FAILURE'
)<ArticleType, Optional<NormalizedType<ArticleType>>, Error>();

export const deleteArticleAsync = createAsyncAction(
  'hmp/admin/DELETE_ARTICLE_REQUEST',
  'hmp/admin/DELETE_ARTICLE_SUCCESS',
  'hmp/admin/DELETE_ARTICLE_FAILURE'
)<number, Optional<NormalizedType<ArticleType>>, Error>();

export interface PublishArticleArguments {
  id: number;
  publishDate?: string;
}

export const publishArticleAsync = createAsyncAction(
  'hmp/admin/PUBLISH_ARTICLE_REQUEST',
  'hmp/admin/PUBLISH_ARTICLE_SUCCESS',
  'hmp/admin/PUBLISH_ARTICLE_FAILURE'
)<PublishArticleArguments, Optional<NormalizedType<ArticleType>>, Error>();

export const unpublishArticleAsync = createAsyncAction(
  'hmp/admin/UNPUBLISH_ARTICLE_REQUEST',
  'hmp/admin/UNPUBLISH_ARTICLE_SUCCESS',
  'hmp/admin/UNPUBLISH_ARTICLE_FAILURE'
)<number, Optional<NormalizedType<ArticleType>>, Error>();

export const updateSelectedArticleIds = createStandardAction('hmp/admin/UPDATE_ARTCILE_IDS')<number[]>();

export const updateNewArticleId = createStandardAction(
  "hmp/admin/UPDATE_NEW_ARTICLE_ID"
)<number>();

export const updateSelectedTopicIds = createStandardAction(
  "hmp/admin/UPDATE_SELECTED_ARTICLE_TOPIC_IDS"
)<number[]>();

export const updateIncludeDeleted = createStandardAction(
  "hmp/admin/UPDATE_INCLUDE_DELETED_ARTICLE"
)<boolean>();

export const updateSearchTerm = createStandardAction(
  "hmp/admin/UPDATE_SEARCH_TERM_ARTICLE"
)<string>();

export interface IArticleUIState {
  articleIds: number[];
  newArticleId: number;
}

const articleActions = {
  loadArticlesAsync,
  deleteArticleAsync,
  getRequestedArticleAsync,
  updateSelectedArticleIds,
  saveArticleAsync,
  publishArticleAsync,
  unpublishArticleAsync,
  updateNewArticleId,
  updateSelectedTopicIds,
  updateIncludeDeleted,
  updateSearchTerm,
};

export type ArticleActionTypes = ActionType<typeof articleActions>;
