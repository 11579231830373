import { ActionType, createAsyncAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import { SocialJsonParamType } from '../user/socialUtil';
import {ThumbsupType} from "../../../../server/src/types/forumTypes";

export const getThumbsupsAsync = createAsyncAction(
  'hmp/admin/GET_THUMBSUPS_REQUEST',
  'hmp/admin/GET_THUMBSUPS_SUCCESS',
  'hmp/admin/GET_THUMBSUPS_FAILURE'
)<SocialJsonParamType[], Optional<NormalizedType<ThumbsupType>>, Error>();


const thumbsupsActions = {
  getThumbsupsAsync
};

export type ThumbsupsActionTypes = ActionType<typeof thumbsupsActions>;
