import { _ } from 'lodash';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { selectAvatars } from '../../redux/avatar/avatar.selectors';
import { selectParticipants } from '../../redux/participants/participants.selectors';
import IApplicationState from '../../types/state.types';
import entitySchema from '../schema';
import {MessageResponseType, MessageThreadType, MessageType} from "../../types/serverTypes/messageTypes";

export const selectThreads = (state: IApplicationState) => {
  return state.entities.threads;
};

export const selectMessages = (state: IApplicationState) => {
  return state.entities.messages;
};

export const selectMessageResponses = (state: IApplicationState) => {
  return state.entities.messageResponses;
};

export const getMessageThreads = createSelector(
  [selectThreads, selectParticipants, selectAvatars],
  (threads, participants, avatars): Optional<MessageThreadType[]> => {
    if (threads && threads.allIds && threads.allIds.length > 0) {
      const { threads: denormalizedThreads } = denormalize({ threads: threads.allIds }, entitySchema, { threads: threads.byId, participants: participants.byId, avatars: avatars.byId });
      return _.toArray(denormalizedThreads);
    }
    return undefined;
  }
);

export const getMessageResponses = createSelector(
  [selectMessageResponses, selectMessages],
  (messageResponses, messages): Optional<MessageResponseType[]> => {
    if (messageResponses && messageResponses.allIds && messageResponses.allIds.length > 0) {
      const denormalization = denormalize({ messageResponses: messageResponses.allIds }, entitySchema, { messageResponses: messageResponses.byId, messages: messages.byId });
      const { messageResponses: denormalizedMessages } = denormalization;
      return _.toArray(denormalizedMessages);
    }
    return undefined;
  }
);

export const getMessages = createSelector(
  [selectMessages],
  (messages): Optional<MessageType[]> => {
    if (messages && messages.allIds && messages.allIds.length > 0) {
      const denormalization = denormalize({ messages: messages.allIds }, entitySchema, { messages: messages.byId });
      const { messages: denormalizedMessages } = denormalization;
      return _.toArray(denormalizedMessages);
    }
    return undefined;
  }
);

export const getMessageThreadCount = (state: IApplicationState) => {
  return state.ui.messages.threadCount;
};

export const getMessageIsTypingIndicators = (state: IApplicationState) => {
  return state.ui.messages.isTypingIndicators;
}

export default { getMessageThreads, getMessageResponses };
