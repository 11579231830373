import { _ } from 'lodash';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import {
  MessagesActionTypes,
  updateMessageThreadCount,
  IMessageUIState, addToIsTypingIndicators, removeFromIsTypingIndicators
} from './messages.types';

export const initialMessageUIState: IMessageUIState = {
  threadCount: 0,
  isTypingIndicators: new Map<number, Set<string>>()
}

export const messageUIReducer: Reducer<IMessageUIState, MessagesActionTypes> = (state: IMessageUIState = initialMessageUIState, action: MessagesActionTypes) => {
  let isTypingIndicators;
  switch (action.type) {
    case getType(updateMessageThreadCount):
      return {
        ...state,
        threadCount: action.payload
      };
    case getType(addToIsTypingIndicators):
      isTypingIndicators = _.clone(state.isTypingIndicators);
      if (!isTypingIndicators.has(action.payload.typingIndicator.threadId)) {
        isTypingIndicators.set(action.payload.typingIndicator.threadId, new Set<string>());
      }
      isTypingIndicators.get(action.payload.typingIndicator.threadId)!.add(action.payload.typingIndicator.username);
      return {
        ...state,
        isTypingIndicators
      };
    case getType(removeFromIsTypingIndicators):
      isTypingIndicators = _.clone(state.isTypingIndicators);
      if (isTypingIndicators.has(action.payload.typingIndicator.threadId)) {
        isTypingIndicators.get(action.payload.typingIndicator.threadId)!.delete(action.payload.typingIndicator.username);
      }
      return {
        ...state,
        isTypingIndicators
      };

    default: return state;
  }
};

export default messageUIReducer;
