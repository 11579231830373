import {getType} from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {initialNormalizedState, NormalizedState} from '../../types/state.types';
import {getParticipantUserSurveysAsync, getUserSurveysAsync, UserSurveyActionTypes} from './userSurvey.types';
import {NoteType} from "../../types/serverTypes/noteTypes";

const updateUserSurveysReducer = produce((draft, userSurveys) => {
  if (!userSurveys) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(userSurveys), Number));
  const ids = Object.keys(userSurveys);
  for (const id of ids) {
    draft.byId[id] = userSurveys[id];
  }
});

export const userSurveyReducer = (state: NormalizedState<NoteType> = initialNormalizedState, action: UserSurveyActionTypes) => {

  switch (action.type) {
    case getType(getParticipantUserSurveysAsync.success): return updateUserSurveysReducer(state, action.payload);
    case getType(getUserSurveysAsync.success): return updateUserSurveysReducer(state, action.payload);
    default: return state;
  }

};

export default userSurveyReducer;
