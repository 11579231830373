import React, { Component } from 'react';
import './thread.scss';

interface StateProps {
}

interface ComponentProps extends StateProps {
  title: string,
  leftItems?: any[],
  rightItems?: any[]
}

class Toolbar extends Component<ComponentProps, {}> {
  readonly state = {
  }

  spacer = (<div style={{ width: '32px', height: '21px' }} />)

  render() {
    const { title, leftItems, rightItems } = this.props;
    return (
      <div className="thread-toolbar">
        <div className="left-items">{ leftItems && leftItems.length ? leftItems : this.spacer }</div>
        <h1 className="toolbar-title">{ title }</h1>
        <div className="right-items">{ rightItems && rightItems.length ? rightItems : this.spacer }</div>
      </div>
    );
  }
}

export default Toolbar;
