import {getType} from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  deleteActivityAsync,
  getActivityAsync,
  getActivityCategoriesAsync,
  getActivitySummariesAsync,
  getActivityTypesAsync,
  publishActivityAsync,
  unpublishActivityAsync,
  updateActivities,
  upsertActivityAsync
} from './activities.types';
import {initialNormalizedState, NormalizedState} from '../../types/state.types';
import {ActivityType, ActivityTypeType, CategoryType} from "../../types/serverTypes/activityTypes";

const updateActivitiesReducer = produce((draft, activities) => {
  if (!activities) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(activities), Number));
  const ids = Object.keys(activities);
  for (const id of ids) {
    draft.byId[id] = activities[id];
  }
});

const refreshActivitiesReducer = produce((draft, activities) => {
  if (!activities) {
    return;
  }
  draft.allIds = map(keys(activities), Number);
  const ids = Object.keys(activities);
  for (const id of ids) {
    draft.byId[id] = {
      ...activities[id]
    }
  }
});

const updateActivityCategoriesReducer = produce((draft, activityCategories) => {
  if (!activityCategories) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(activityCategories), Number));
  const ids = Object.keys(activityCategories);
  for (const id of ids) {
    draft.byId[id] = activityCategories[id];
  }
});

const updateActivityTypesReducer = produce((draft, activityTypes) => {
  if (!activityTypes) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(activityTypes), Number));
  const ids = Object.keys(activityTypes);
  for (const id of ids) {
    draft.byId[id] = activityTypes[id];
  }
});

export const activitiesReducer = (state: NormalizedState<ActivityType> = initialNormalizedState, action: any) => {
  switch (action.type) {
    case getType(getActivityAsync.success): return updateActivitiesReducer(state, action.payload);
    case getType(getActivitySummariesAsync.success): return refreshActivitiesReducer(state, action.payload);
    case getType(upsertActivityAsync.success): return updateActivitiesReducer(state, action.payload);
    case getType(deleteActivityAsync.success): return updateActivitiesReducer(state, action.payload);
    case getType(publishActivityAsync.success): return updateActivitiesReducer(state, action.payload);
    case getType(unpublishActivityAsync.success): return updateActivitiesReducer(state, action.payload);
    case getType(updateActivities): return updateActivitiesReducer(state, action.payload);
    default: return state;
  }
};

export const activityCategoriesReducer = (state: NormalizedState<CategoryType> = initialNormalizedState, action: any) => {
  switch(action.type) {
    case getType(getActivityCategoriesAsync.success): return updateActivityCategoriesReducer(state, action.payload);
    default: return state;
  }
}

export const activityTypesReducer = (state: NormalizedState<ActivityTypeType> = initialNormalizedState, action: any) => {
  switch(action.type) {
    case getType(getActivityTypesAsync.success): return updateActivityTypesReducer(state, action.payload);
    default: return state;
  }
}

export default activitiesReducer;
