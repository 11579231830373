import axios from '../../redux/api';

class KnowledgeCenterService {
  static async exportResources() {
    const response = await axios.get(`/a/kc/export`, {
      responseType: 'blob',
      timeout: 0,
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'exportResources.zip');
    document.body.appendChild(link);
    link.click();
  }

  static async importResources(formData) {
    await axios.post('/a/kc/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
    });
  }
}

export default KnowledgeCenterService;
