import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import moment from 'moment';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import { getMessagesAsync, updateMessages, MessagesActionTypes, sendMessageAsync } from './messages.types';
import {MessageResponseType, MessageType} from "../../types/serverTypes/messageTypes";

const updateMessageResponsesReducer = produce((draft, messageResponses) => {
  if (!messageResponses) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(messageResponses), Number));
  const ids = Object.keys(messageResponses);
  for (const id of ids) {
    if(draft.byId[id]) {
      const previousMessages: MessageType[] = draft.byId[id].messages;
      messageResponses[id].messages = union(previousMessages, messageResponses[id].messages);
      messageResponses[id].messages = messageResponses[id].messages.sort((a: MessageType, b: MessageType) => moment(a.createDate).unix() - moment(b.createDate).unix());
    }
    draft.byId[id] = messageResponses[id];
  }
});

const updateMessagesReducer = produce((draft, messages) => {
  if (!messages) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(messages), Number));
  const ids = Object.keys(messages);
  for (const id of ids) {
    draft.byId[id] = messages[id];
  }
});

export const messageResponsesReducer = (state: NormalizedState<MessageResponseType> = initialNormalizedState, action: MessagesActionTypes) => {
  switch (action.type) {
    case getType(getMessagesAsync.success): return updateMessageResponsesReducer(state, action.payload);
    default: return state;
  }
};

export const messagesReducer = (state: NormalizedState<MessageType> = initialNormalizedState, action: MessagesActionTypes) => {
  switch (action.type) {
    case getType(updateMessages): return updateMessagesReducer(state, action.payload);
    case getType(sendMessageAsync.success): return updateMessagesReducer(state, action.payload);
    default: return state;
  }
};

export default messagesReducer;
