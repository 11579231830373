import React from 'react';
import './activity.scss';
import ActivityTooltip from './ActivityTooltip';

interface ComponentProps {
  tooltip?: string;
  label: string;
}
const ActivityItemLabel = (props: ComponentProps) => {
  const { label, tooltip } = props;
  return (
    <span>
      {label}
      {' '}
      {tooltip && <ActivityTooltip text={tooltip} />}
    </span>
  );
};

export default ActivityItemLabel;
