import {createSelector} from 'reselect';
import toArray from 'lodash/toArray';
import {denormalize} from 'normalizr';
import IApplicationState from '../../types/state.types';
import {selectFlaggedPostIds, selectForumUncategorizedPostIds} from '../forum/forum.selectors';
import {selectParticipants} from '../participants/participants.selectors';
import entitySchema from '../schema';
import {PostType} from "../../types/serverTypes/forumTypes";

export const selectPosts = (state: IApplicationState) => {
  return state.entities.posts;
};

export const getPosts = createSelector(
  [selectPosts],
  (posts): Optional<PostType[]> => {
    if (posts && posts.allIds && posts.allIds.length > 0) {
      const { posts: denormalizedPosts } = denormalize({ posts: posts.allIds }, entitySchema, { posts: posts.byId });
      return toArray(denormalizedPosts);
    }
    return undefined;
  }
);

export const selectRecentActivityPostIds = (state: IApplicationState) => {
  return state.ui.forums.recentActivityPostIds;
}

export const getLastSearchResultPosts = createSelector(
  [selectPosts, selectRecentActivityPostIds, selectParticipants],
  (posts, recentActivityPostIds, participants): Optional<PostType[]> => {
    if (posts && posts.allIds && posts.allIds.length > 0) {
      const { posts: denormalizedPosts } = denormalize({ posts: recentActivityPostIds }, entitySchema, { posts: posts.byId, participants: participants.byId });
      return toArray(denormalizedPosts);
    }
    return undefined;
  }
);

export const getUncategorizedPosts = createSelector(
 [selectPosts, selectForumUncategorizedPostIds, selectParticipants],
  (posts, uncategorizedPostIds, participants): Optional<PostType[]> => {
   if (posts?.allIds && uncategorizedPostIds?.length) {
     const { posts: denormalizedPosts } = denormalize({ posts: uncategorizedPostIds }, entitySchema, { posts: posts.byId, participants: participants.byId });
     for(let i=0; i < denormalizedPosts.length; i++) {
       // if any element didn't normalize we're probably in the middle of a saga
       if (!denormalizedPosts[i])
         return undefined;
     }
     return toArray(denormalizedPosts);
   }
   return undefined;
  }
)

export const getFlaggedPosts = createSelector(
  [selectPosts, selectFlaggedPostIds, selectParticipants],
  (posts, flaggedPostIds, participants): Optional<PostType[]> => {
    if (posts?.allIds && flaggedPostIds?.length) {
      const { posts: denormalizedPosts } = denormalize({ posts: flaggedPostIds }, entitySchema, { posts: posts.byId, participants: participants.byId});
      return toArray(denormalizedPosts);
    }
    return undefined;
  }
)
export default { getPosts, selectRecentActivityPostIds};
