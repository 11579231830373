import { createUserManager } from 'redux-oidc';

const url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

const userManagerConfig = {
  client_id: `${process.env.CLIENT_ID}`,
  client_secret: `${process.env.CLIENT_SECRET}`,
  redirect_uri: `${url}/auth/callback`,
  authority: `${url}/oidc`,
  response_type: 'code',
  scope: 'openid profile email api',
  triggerAuthFlow: true,
  post_logout_redirect_uri: `${url}/auth/logout`,
  silent_redirect_uri: `${url}/auth/renew`,
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: true,
  silentRequestTimeout: 30000,
  revokeAccessTokenOnSignout: true
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
