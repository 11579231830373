import { HolderOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
  AvatarPartCategoryType,
  AvatarPartType,
} from '../../types/serverTypes/avatarTypes';
import AvatarCustomPart from './AvatarCustomPart';
const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightyellow' : 'white',
});
interface AvatarCustomCategoryProp {
  category: AvatarPartCategoryType;
  index: number;
  parts: AvatarPartType[];
  baseBody: Map<number, AvatarPartType>;
  onAvatarPartDetail: (part) => void;
  onClearSelection: (categoryId) => void;
  onCategoryDetail: (category, canDelete) => void;
  selectedPart?: AvatarPartType;
}

const AvatarCustomCategory = (props: AvatarCustomCategoryProp) => {
  const partsCount = props.parts.filter(
    (p) => p.avatarPartCategoryId === props.category.id
  ).length;
  const secondaryCount = props.parts.filter(
    (p) => p.secondaryPartCategoryId === props.category.id
  ).length;
  let canDelete =
    (!partsCount || partsCount === 0) &&
    (!secondaryCount || secondaryCount === 0);

  return (
    <Draggable
      draggableId={`category-${props.category.id}`}
      index={props.index}
    >
      {(provided, snapshot) => (
        <div
          className="category-container"
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div
            className="category-label-section"
            {...provided.dragHandleProps}
            style={getListStyle(snapshot.isDragging)}
          >
            <div>
              <Tooltip title="Drag to change sequence">
                <HolderOutlined style={{ marginRight: 10 }} />
              </Tooltip>
              <a
                onClick={() =>
                  props.onCategoryDetail(props.category, canDelete)
                }
              >
                {props.category.label}{' '}
                {`(${partsCount}) Secondary (${secondaryCount})`}
              </a>
            </div>
            {props.parts.length > 0 && (
              <div>
                <a onClick={() => props.onClearSelection([props.category.id])}>
                  Clear
                </a>
              </div>
            )}
          </div>
          <Droppable droppableId={props.category.id.toString()} type="part">
            {(provided, snapshot) => (
              <div
                className="avatar-svg-containers"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {props.parts
                  .filter((p) => p.avatarPartCategoryId === props.category.id)
                  .map((part, index) => (
                    <AvatarCustomPart
                      key={part.id}
                      index={index}
                      part={part}
                      category={props.category}
                      baseBody={props.baseBody}
                      onAvatarPartDetail={props.onAvatarPartDetail}
                      selectedPart={props.selectedPart}
                    />
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};

export default AvatarCustomCategory;
