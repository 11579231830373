import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import IApplicationState from '../../types/state.types';
import { selectParticipants } from '../participants/participants.selectors';
import { entitySchema } from '../schema';
import { selectAdmin } from '../user/user.selectors';
import {FlagType} from "../../types/serverTypes/forumTypes";

export const selectFlags = (state: IApplicationState) => {
  return state.entities.flags;
};

export const getFlags = createSelector(
  [selectFlags, selectParticipants, selectAdmin],
  (flags, participants, admins): Optional<FlagType[]> => {
    if (flags?.allIds?.length && participants?.allIds?.length && admins?.allIds?.length) {
      const { flags: denormalizedFlags } = denormalize({ flags: flags.allIds }, entitySchema, { flags: flags.byId, participants: participants.byId, admin: admins.byId });
      return toArray(denormalizedFlags);
    }
    return undefined;
  }
);

export const getCommentFlags = createSelector(
  [selectFlags, selectParticipants, selectAdmin],
  (flags, participants, admins): Optional<FlagType[]> => {
    if (flags?.allIds?.length && participants?.allIds?.length && admins?.allIds?.length) {
      const { flags: denormalizedFlags } = denormalize({ flags: flags.allIds }, entitySchema, { flags: flags.byId, participants: participants.byId, admin: admins.byId });
      const commentFlags = toArray(denormalizedFlags).filter(f => f.type === 'comment');
      return commentFlags;
    }
    return undefined;
  }
);

export default { getFlags };
