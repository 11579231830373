import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  Descriptions, Input, Button, DatePicker, Alert
} from 'antd';
import { fulfillTestkitAsync } from '../../redux/testkit/testkit.types';
import { TestkitType } from '../../types/serverTypes/testkitTypes';

const { Item } = Descriptions;

interface StateProps {
}

interface DispatchProps {
  fulfillTestkit: typeof fulfillTestkitAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {
  testkit: TestkitType
}

const inputStyle = {
  marginTop: '5px',
  marginBottom: '5px'
};

class TestkitRequestedCard extends Component<ComponentProps, {}> {
  readonly state = {
    trackingNumber: '',
    estimatedDeliveryDate: '',
    fulfillmentError: false
  };

  componentDidMount() {
  }

  onChange(state: any) {
    this.setState(state);
  }

  handleFulfillment = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { fulfillTestkit, testkit } = this.props;
    const { id } = testkit;
    const { trackingNumber, estimatedDeliveryDate } = this.state;
    if (trackingNumber.length > 0 && estimatedDeliveryDate.length > 0) {
      const testkitArg = {
        id,
        trackingNumber,
        estimatedDeliveryDate
      };
      fulfillTestkit(testkitArg);
      this.setState({ fulfillmentError: false });
    } else {
      this.setState({ fulfillmentError: true });
    }
  };

  render() {
    const { testkit } = this.props;
    const { participant } = testkit;
    const { trackingNumber } = this.state;

    if (!participant) {
      return <div>Participant Not Found.</div>;
    }

    return (
      <div>
        <Descriptions layout="vertical" column={5} size="small" colon={false}>
          <Item label="Fulfillment:" span={1}>
            <span>{`${testkit.firstName} ${testkit.lastName}`}</span>
            <br />
            <span>{`${testkit.address1}`}</span>
            <br />
            {testkit.address2 ? (
              <span>
                {`${testkit.address2}`}
                <br />
              </span>
            ) : ''}
            <span>{`${testkit.city}, ${testkit.state} ${testkit.postalCode}`}</span>
            <br />
          </Item>
          <Item label="Type">
            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{`${testkit.type}`}</span>
          </Item>
          {/* <Item label='Tracking Carrier:'> */}
          {/*  <Input placeholder='Tracking Carrier' defaultValue='USPS' style={inputStyle} onChange={(event) => this.onChange({ trackingCarrier: event.target.value })}> */}
          {/*    */}
          {/*  </Input> */}
          {/* </Item> */}
          <Item label="Tracking ID:"><Input placeholder="Tracking ID" style={inputStyle} onChange={(event) => this.onChange({ trackingNumber: event.target.value })} /></Item>
          <Item label="Estimated Delivery Date:"><DatePicker style={inputStyle} onChange={(date, dateString) => this.onChange({ estimatedDeliveryDate: dateString })} /></Item>
          {/* <Item label='Tracking Link:'><h3><a href={`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`}>https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1={trackingNumber}</a></h3> </Item> */}
          <Item><Button type="primary" onClick={this.handleFulfillment} style={inputStyle}>Submit</Button></Item>
        </Descriptions>
        <Alert style={this.state.fulfillmentError ? { visibility: 'visible' } : { visibility: 'hidden' }} message="Must provide Tracking ID and Estimated Delivery Date." type="error" showIcon />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fulfillTestkit: (testkit) => dispatch(fulfillTestkitAsync.request(testkit))
  };
};

export default connect(null, mapDispatchToProps)(TestkitRequestedCard);
