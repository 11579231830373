import React, { Component } from 'react';
import * as _ from 'lodash';
import { Select, Col } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import ActivityImageUpload from '../ActivityImageUpload';
import ActivityGradientSelector from '../ActivityGradientSelector';
import '../activity.scss';
import ActivityPreview from '../ActivityPreview';
import { ActivityFormStatus, BackgroundType } from '../ActivityFormContainer';
import { ActivityBackgroundType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;
const { Option } = Select;

interface ComponentProps {
  creative: boolean;
  editable: boolean;
  background?: ActivityBackgroundType;
  backgroundType: BackgroundType;
  setBackgroundType: (type: BackgroundType) => void;
  setActivity: (props: any) => void;
  previewImage: string;
  image: File | string | undefined;
  uploadFile: (image: File) => Promise<boolean>;
  removeFile: () => void;
  setActiveForm: (form: any) => void;
}

class ActivityBackgroundForm extends Component<ComponentProps, {}> {
  form = React.createRef<FormInstance>();

  componentDidMount() {
    const { setActiveForm } = this.props;
    setActiveForm(this.form.current);
  }

  onChange = (type: BackgroundType) => {
    const { setBackgroundType } = this.props;
    setBackgroundType(type);
  };

  setGradient = (start: string | null, end: string | null) => {
    const { setActivity } = this.props;
    const background = {
      gradient: {
        start,
        end
      }
    };
    setActivity({ background });
  };

  validate = (rule, value) => {
    const {
      backgroundType: type,
      previewImage,
      background,
      creative,
      editable
    } = this.props;
    if (background) {
      const { gradient } = background;
      try {
        if (!creative && !editable) {
          return Promise.resolve();
        }
        if (type === 'image' && previewImage.length === 0) {
          throw new Error('Image upload is required.');
        } else if (type === 'gradient' && !gradient) {
          throw new Error('Gradient is required.');
        }
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    }
  };

  resetForm = () => {
    if (this.form.current) {
      this.form.current.resetFields();
    }
  };

  render() {
    const {
      image,
      uploadFile,
      removeFile,
      previewImage,
      backgroundType,
      background,
      creative,
      editable
    } = this.props;
    return (
      <div className="activity-form">
        <Col span={12}>
          <Form
            ref={this.form}
            key="background-form"
            layout="vertical"
            colon={false}
          >
            <Item>
              <div className="question-switch">
                <Item
                  label="Please select the background type:"
                  name="backgroundType"
                  required
                  initialValue={backgroundType}
                >
                  <Select
                    disabled={!creative && !editable}
                    style={{ width: 120 }}
                    onChange={this.onChange}
                  >
                    <Option value="image">Image</Option>
                    <Option value="gradient">Gradient</Option>
                  </Select>
                </Item>
              </div>
              <span>
                {backgroundType === 'image'
                  ? 'Please choose an image to upload.'
                  : 'Please select two colors for the gradient background.'}
              </span>
            </Item>
            <Item
              name="background"
              rules={[{ required: true, validator: this.validate }]}
              validateTrigger={['onChange', 'onSubmit', 'onBlur', 'onFocus']}
            >
              {backgroundType === 'image' ? (
                <ActivityImageUpload
                  disabled={!creative && !editable}
                  image={image}
                  uploadFile={uploadFile}
                  removeFile={removeFile}
                />
              ) : (
                <ActivityGradientSelector
                  disabled={!creative && !editable}
                  gradient={background?.gradient}
                  setGradient={this.setGradient}
                />
              )}
            </Item>
          </Form>
        </Col>
        <Col span={12}>
          <ActivityPreview
            creative={creative}
            editable={editable}
            background={background}
            backgroundType={backgroundType}
            imageFile={image}
            previewImage={previewImage}
          />
        </Col>
      </div>
    );
  }
}

export default ActivityBackgroundForm;
