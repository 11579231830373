import {
  faCommentAlt,
  faFlag as farFaFlag,
  faStar as farStar,
  faThumbsUp as farThumbsUp
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCommentAltLines,
  faFlag as fasFaFlag,
  faStar as fasStar,
  faThumbsUp as fasThumbsUp,
  faTrashAlt,
  faEllipsisH
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar, Button, Col, Menu, message, Popover, Row, Tooltip, Typography, Modal, Cascader, DatePicker
} from 'antd';
import AntdComment from 'antd/lib/comment';
import Dropdown from 'antd/lib/dropdown';
import Icon from '@ant-design/icons';
import update from 'immutability-helper';
import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import uuid from 'uuid';
import { CascaderOptionType } from 'antd/es/cascader';
import SecureImage from '../../../components/image/SecureImage';
import { getStudyArmLabelFromId, renderDateWithTime } from '../../../components/util/Util';
import {
  hideContentAsync,
  HideContentPayloadType,
  resolveFlagsAsync,
  unhideContentAsync
} from '../../../redux/flags/flags.types';
import { savePostAsync, deletePostAsync } from '../../../redux/posts/posts.types';
import * as selectors from '../../../redux/selectors';
import IApplicationState from '../../../types/state.types';
import Username from '../../../components/username/Username';
import HMPTextArea from '../../../components/textarea/HMPTextArea';
import { CreateUpdateCommentParam } from '../../../redux/user/socialUtil';
import { createCommentAsync, updateCommentAsync } from '../../../redux/comments/comments.types';
import Config from '../../../components/util/Config';
import './forumPage.scss';
import {
  CommentType,
  FavoriteType,
  FlagType,
  PostType,
  ThumbsupType,
  TopicType,
} from '../../../types/serverTypes/forumTypes';
import FeatureNames from "../../../components/util/FeatureNames";
import { ParticipantType, StudyArmType } from '../../../types/serverTypes/studyTypes';
import { AvatarType } from '../../../types/serverTypes/avatarTypes';
const { confirm } = Modal;
const { Paragraph } = Typography;

interface DispatchProps {
  resolveFlags: typeof resolveFlagsAsync.request,
  hideContent: typeof hideContentAsync.request,
  unhideContent: typeof unhideContentAsync.request,
  savePost: typeof savePostAsync.request,
  deletePost: typeof deletePostAsync.request,
  createComment: typeof createCommentAsync.request
  updateComment: typeof updateCommentAsync.request
}

const initialState = {
  posts: [] as Optional<PostType[]>,
  editedPost: undefined as Optional<PostType>,
  allComments: [] as Optional<CommentType[]>,
  allFlags: [] as Optional<FlagType[]>,
  allThumbsups: [] as Optional<ThumbsupType[]>,
  allFavorites: [] as Optional<FavoriteType[]>,
  avatars: [] as Optional<AvatarType[]>,
  topics: [] as Optional<TopicType[]>,
  selectedStyle: undefined as any,
  isEditing: false as Boolean,
  visibleFlagResolutionPopups: { post: [], comment: [] },
  visibleActionMenus: { post: [], comment: [] },
  visiblePostDateChangePopups: { post: [], comment: [] },
  visibleAddCommentPopups: { post: [], comment: [] },
  showTopicSelector: false as Boolean,
  studyArms: [] as StudyArmType[],
  studyId: -1 as number,
  selectedParticipantId: -1 as number,
  newCommentText: '' as string,
  newDate: new Date() as Date
};

interface StateProps {
  hasCareNavigatorRole: Optional<Boolean>;
  pseudoParticipants: Optional<ParticipantType[]>;
  participants: Optional<ParticipantType[]>;
  posts: Optional<PostType[]>,
  allComments: Optional<CommentType[]>,
  allFlags: Optional<FlagType[]>,
  allThumbsups: Optional<ThumbsupType[]>,
  allFavorites: Optional<FavoriteType[]>,
  avatars: Optional<AvatarType[]>,
  topics: Optional<TopicType[]>
  studyArms: Optional<StudyArmType[]>
  studyId: Optional<number>;
  forumPostDateMutableEnabled: boolean;
}

export const enum PostMode {
  FULL,
  PARTIAL,
  EDIT,
}

interface ComponentProps extends StateProps, DispatchProps {
  post: Optional<PostType>;
  mode: PostMode;
}

type ComponentState = Readonly<typeof initialState>

function filter(inputValue: any, path: any): boolean {
  return path.some((option: any) => (option.label ? option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 : false));
}

class Post extends Component<ComponentProps, ComponentState> {

readonly state: ComponentState = initialState;

  capitalize = (s: string): string => (s ? s.split(' ').map(word => word[0].toUpperCase() + word.substring(1)).join(' ') : s);

  handleTitleChange = ({ target: { value } }: {target: any}) => {
    this.setState(update(this.state, { editedPost: { title: { $set: value } } }));
  };

  handleBodyChange = ({ target: { value } }: {target: any}) => {
    this.setState(update(this.state, { editedPost: { body: { $set: value } } }));
  };

  handleCancelPost = () => {
    this.setState({ isEditing: false, editedPost: undefined });
  };

  getFlags = (type:string, typeId: number) => {
    return _.filter(this.props.allFlags, { typeId, type });
  }

  commentCount = (type:string, typeId: number) => {
    const comments = _.filter(this.props.allComments, { typeId, type });
    return comments ? comments.length : 0;
  }

  renderParticipantLink = (value: any, row: any) => {
    const { studyId } = this.props;
    return <Link to={`/study/${studyId}/participants/${row.id}`}>{value}</Link>;
  };

  private makeUsernamesHover = (participants: ParticipantType[]) => {
    const { studyId } = this.props;
    const content:JSX.Element[] = [];
    if (!participants.length || !participants[0]) {
      return <div key={uuid()}>none</div>;
    }
    for (let i = 0; i < participants.length; i++) {
      const p:ParticipantType = participants[i];
      if (!p) {
        return <div />; // this is a temporary state with incompleted data, future render will resolve
      }
      content.push(<span key={p.id}><a href={`/study/${studyId}/participants/${p.id}/`}>{p.username}</a></span>);
      if (i !== participants.length - 1) {
        content.push(<br key={`${p.id}_br`} />);
      }
    }
    return <div key={uuid()}>{...content}</div>;
  };

  commentHover = (type:string, typeId: number) => {
    const comments = _.filter(this.props.allComments, { typeId, type });
    const participants:ParticipantType[] = _.uniqBy(_.map(comments, 'participant'), 'id') as ParticipantType[];
    return this.makeUsernamesHover(participants);
  };

  thumbsupHover = (type: string, typeId: number) => {
    const thumbsups = _.filter(this.props.allThumbsups, { typeId, type });
    const participants:ParticipantType[] = _.uniqBy(_.map(thumbsups, 'participant'), 'id') as ParticipantType[];
    return this.makeUsernamesHover(participants);
  };

  thumbsupCount = (type: string, typeId: number) => {
    const thumbsups = _.filter(this.props.allThumbsups, { typeId, type });
    return thumbsups ? thumbsups.length : 0;
  }

  favoriteCount = (type: string, typeId: number) => {
    const favorites = _.filter(this.props.allFavorites, { typeId, type });
    return favorites ? favorites.length : 0;
  }

  favoriteHover = (type: string, typeId: number) => {
    const favorites = _.filter(this.props.allFavorites, { typeId, type });
    const participants = _.uniqBy(_.map(favorites, 'participant'), 'id') as ParticipantType[];
    return this.makeUsernamesHover(participants);
  };

  postFavoriteCount = (type: string, typeId: number) => {
    const favorites = _.filter(this.props.allFavorites, { typeId, type });
    return favorites ? favorites.length : 0;
  }

  flagCount = (type: string, typeId: number) => {
    const flags = _.filter(this.props.allFlags, { typeId, type });
    return flags ? flags.length : 0;
  }

  flagHover = (type: string, typeId: number) => {
    const flags = _.filter(this.props.flags, { typeId, type });
    const participants = _.uniqBy(_.map(flags, 'participant'), 'id') as ParticipantType[];
    return this.makeUsernamesHover(participants);
  }

  handleDelete = () => {
    const { post, deletePost } = this.props;
    if (post) {
      const { id } = post;
      confirm({
        title: 'Are you sure you want to delete this post?',
        okText: 'Delete',
        okType: 'danger',
        onOk() {
          deletePost(id);
        },
        onCancel() {}
      });
    }
  }

  iconFlagSolid = (): any => {
    return <FontAwesomeIcon icon={fasFaFlag} />;
  };

  iconFlagOutline = (): any => {
    return <FontAwesomeIcon icon={farFaFlag} />;
  };

  iconThumbsupSolid = (): any => {
    return <FontAwesomeIcon icon={fasThumbsUp} />;
  };

  iconThumbsupOutline = (): any => {
    return <FontAwesomeIcon icon={farThumbsUp} />;
  };

  iconFavoriteSolid = (): any => {
    return <FontAwesomeIcon icon={fasStar} />;
  };

  iconFavoriteOutline = (): any => {
    return <FontAwesomeIcon icon={farStar} />;
  };

  iconCommentSolid = (): any => {
    return <FontAwesomeIcon icon={faCommentAltLines} />;
  };

  iconCommentOutline = (): any => {
    return <FontAwesomeIcon icon={faCommentAlt} />;
  };

  iconDelete = (): any => {
    return <FontAwesomeIcon icon={faTrashAlt} />;
  }

  iconElipsis = (): any => {
    return <FontAwesomeIcon icon={faEllipsisH} />;
  }

  copyDeepLink = (id: number) => {
    const deepLink = `hmp://forum/${id}`;
    navigator.clipboard.writeText(deepLink).then(() => {
      message.success('Copied to clipboard!');
    }, () => {
      message.error('Failed to copy to clipboard.');
    });
  };

  handleSelectedParticipantChange = (value: string[]) => {
    this.setState({ selectedParticipantId: parseInt(value[0]) });
  }

  changePostPublishDate = (date:Moment | null) => {
    if (!date) {
      throw Error('No date during date change. how did this happen?');
    }
    this.setState({ newDate: date.toDate() });
  }

  unselectablePostPublishDate = (date: Moment) => {
    return moment().isSameOrAfter(date);
  }

  changeCommentCreateDate = (comment:CommentType) => (date:Moment | null) => {
    const { updateComment } = this.props;
    if (!date) {
      throw Error('No date during date change. how did this happen?');
    } else if (!comment) {
      throw Error('No post during date change. how did this happen?');
    } else {
      comment.createDate = date.toDate();
      updateComment(comment);
      this.handlePostDateChangePopupVisibleChange(false, 'comment', comment.id);
    }
  }

  handleDateChangeOk = (type: string, typeId: number) => (e: React.MouseEvent<HTMLElement>) => {
    const {
      post, allComments, savePost, updateComment
    } = this.props;
    const { newDate } = this.state;

    if (type === 'post' && post) {
      const updatedPost = _.clone(post);
      updatedPost.publishDate = newDate;
      savePost(updatedPost);
    } else {
      const updatedComment = _.find(allComments, c => c.id === typeId);
      updatedComment!.createDate = newDate;
      updateComment(updatedComment!);
    }
    this.handlePostDateChangePopupVisibleChange(false, type, typeId);
  }

  renderPostItemHeader = (post: PostType) => {
    const {
      mode, topics, studyArms, savePost
    } = this.props;
    const { newDate, showTopicSelector } = this.state;

    const menuClick = (event:any) => {
      post.topicId = event.key;
      this.props.savePost(post);
      this.setState({ showTopicSelector: false });
    };
    const currentTopic:TopicType|undefined = _.find(topics, t => t.id == post.topicId);
    const postStudyArm = _.find(studyArms, sa => post.studyArmId === sa.id);

    if (!currentTopic || !postStudyArm) return <div />;

    const topicsInSameCollection = _.filter(topics, t => t.topicCollectionId === currentTopic.topicCollectionId);
    const menu = (
      <Menu onClick={menuClick}>
        {_.map(topicsInSameCollection, (topic:TopicType) => {
          return (
            <Menu.Item key={topic.id}>
              <a>
                {topic.title}
              </a>
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const avatarObj = post?.participant?.avatarId ? this.getAvatar(post.participant?.avatarId) : undefined;

    const avatar = avatarObj
      ? <Avatar src={avatarObj.avatar} style={{ backgroundColor: `#${post?.participant?.avatarBackground}` }} />
      : undefined;

    const redFlagCssStyle = this.hasUnresolvedFlags('post', post.id) ? 'red-flag' : '';

    const tags: JSX.Element[] = [];
    if (post.deleteDate !== null) {
      tags.push(<span key="deleted" title={moment(post.deleteDate).calendar()} className="post-tag-bubble deleted">DELETED</span>);
    }
    if (post.isHidden) {
      tags.push(<span key="hidden" title="hidden" className="post-tag-bubble hidden">HIDDEN</span>);
    }
    return (
      <div id="post-header-container">
        <div className="title-container">
          <p id="title" className="title-truncate">
            {post.title}
          </p>
          <div className="title-extra">
            <a title="Copy deep link to clipboard" className="forum-post-link" onClick={(e) => { e.stopPropagation(); this.copyDeepLink(post.id); }}>
              <i className="far fa-link fa-sm" />
            </a>
            {tags}
          </div>
        </div>
        <span id="arm" className="small-font">
          {getStudyArmLabelFromId(studyArms, post.studyArmId)}
        </span>
        <div id="after-title-container">
          <span id="avatar">
            {avatar}
          </span>
          <p id="username" className="small-font">
            <Username participantId={post.participant?.id} />
          </p>
          <hr id="horizontal-rule" />
          <Modal
            title="Change Post Date"
            visible={this.isPostDateChangePopupVisible('post', post.id)}
            destroyOnClose
            okText="Save"
            onOk={this.handleDateChangeOk('post', post.id)}
            onCancel={() => this.handlePostDateChangePopupVisibleChange(false, 'post', post.id)}
          >
            <div id="date-change-modal-content">
              Date of Post:
              <span id="date-picker-container">
                <DatePicker
                  id="date-picker"
                  defaultValue={moment(newDate)}
                  showTime
                  disabledDate={this.unselectablePostPublishDate}
                  allowClear={false}
                  onChange={this.changePostPublishDate}
                />
              </span>
            </div>
          </Modal>
          <p id="publish-date" className={`small-font ${moment(post.publishDate).isAfter(moment()) && 'post-tag-bubble unpublished'}`}>
            {renderDateWithTime(post.publishDate)}
          </p>
          <Modal
            title="Change Topic"
            visible={showTopicSelector.valueOf()}
          >
            <Dropdown overlay={menu} placement="bottomLeft">
              <Button>{post.topic}</Button>
            </Dropdown>
          </Modal>
          <p id="category" className="small-font">
            {post.topic}
          </p>

          <div id="social-container">
            <Tooltip placement="bottom" title={this.flagHover('post', post.id)}>
              <Popover
                content={this.flagStatsMenu('post', post)}
                title="Resolve Flag"
                trigger="click"
                // @ts-ignore
                visible={this.isFlagResolutionPopupVisible('post', post.id)}
                onVisibleChange={(visible:boolean) => this.handleFlagResolutionPopupVisibleChange(visible, 'post', post.id)}
              >
                <span>
                  <span className={`${redFlagCssStyle}`}>
                    { this.flagCount('post', post.id) }
                    {' '}
                    <Icon style={{ color: '#8185B3', marginRight: 15 }} component={this.iconFlagOutline} />
                  </span>
                </span>
              </Popover>
            </Tooltip>

            <Tooltip placement="bottom" title={this.commentHover('post', post.id)}>
              <Modal
                title="Add Comment"
                visible={this.isAddCommentPopupVisible('post', post.id)}
                destroyOnClose
                onOk={this.handleAddCommentSave('post', post)}
                onCancel={() => this.handleAddCommentPopupVisibleChange(false, 'post', post.id)}
              >
                {this.createCommentMenu('post', post)}
              </Modal>
              {this.commentCount('post', post.id).toString()}
              {' '}
              <Icon style={{ color: '#8185B3', marginRight: '10px' }} component={this.iconCommentOutline} />
            </Tooltip>
            <Tooltip placement="bottom" title={this.thumbsupHover('post', post.id)}>
              {' '}
              {this.thumbsupCount('post', post.id).toString()}
              {' '}
              <Icon style={{ color: '#8185B3', marginRight: '8px' }} component={this.iconThumbsupOutline} />
              {' '}
            </Tooltip>
            <Tooltip placement="bottom" title={this.favoriteHover('post', post.id)}>
              {' '}
              {this.favoriteCount('post', post.id).toString()}
              {' '}
              <Icon style={{ color: '#8185B3', marginRight: '8px' }} component={this.iconFavoriteOutline} />
              {' '}
            </Tooltip>
            { mode === PostMode.FULL
            && (
            <Tooltip placement="bottom" title="Actions">
              <Popover
                content={this.createActionMenu('post', post)}
                title="Action Menu"
                trigger="click"
                visible={this.isActionMenuVisible('post', post.id)}
                onVisibleChange={(visible:boolean) => this.handleActionMenuVisibleChange(visible, 'post', post.id)}
              >
                <Icon style={{ color: '#8185B3', marginRight: '10px' }} component={this.iconElipsis} />
              </Popover>
            </Tooltip>
            )}
          </div>
        </div>
      </div>
    );
  };

  getAvatar = (avatarId: number) => {
    return _.find(this.props.avatars, a => a.id === avatarId);
  }

  hasUnresolvedFlags = (type:string, typeId: number) => {
    const flags = this.getFlags(type, typeId);
    return _.find(flags, f => !f.resolution);
  }

  resolveFlags = (type:string, typeId: number, resolution: string) => {
    const flags = this.getFlags(type, typeId);
    if (flags?.length) {
      const ids = _.map(flags, 'id');
      this.props.resolveFlags({ ids, resolution });
    }
  }

  hideContent = (type:string, typeId: number) => {
    this.props.hideContent({ type, typeId });
    this.resolveFlags(type, typeId, 'confirmed');
  }

  unhideContent = (type:string, typeId: number) => {
    this.props.unhideContent({ type, typeId });
  }

  onCommentTextChange = (e: ChangeEvent<HTMLAreaElement>) => {
    // @ts-ignore
    this.setState({ newCommentText: e.target.value });
  }

  createActionMenu: any = (type:string, postOrComment: PostType | CommentType) => {
    const { post, studyArms, forumPostDateMutableEnabled } = this.props;
    const flagCount = this.flagCount(type, postOrComment.id);
    const postStudyArm:StudyArmType = _.find(studyArms, sa => post?.studyArmId === sa.id) as StudyArmType;

    const actionMenuSelect = (e: any) => {
      switch (e.key) {
        case 'changePostDate':
          this.handlePostDateChangePopupVisibleChange(true, type, postOrComment.id);
          break;
        case 'addComment':
          this.handleAddCommentPopupVisibleChange(true, type, postOrComment.id);
          break;
        case 'hideContent':
          this.hideContent(type, postOrComment.id);
          break;
        case 'unhideContent':
          this.unhideContent(type, postOrComment.id);
          break;
        case 'viewFlagStats':
          this.handleFlagResolutionPopupVisibleChange(true, type, postOrComment.id);
          break;
        case 'ignoreFlags':
          this.resolveFlags(type, postOrComment.id, 'ignored');
          break;
        case 'changeTopic':
          this.setState({ showTopicSelector: true });
          break;
        case 'deletePost':
          this.handleDelete();
          break;
      }
      this.handleActionMenuVisibleChange(false, type, postOrComment.id);
    };

    return (
      <Menu onClick={actionMenuSelect}>
        { type === 'post' && forumPostDateMutableEnabled &&
          <Menu.Item key="changePostDate">{`Change ${this.capitalize(type)} Date`}</Menu.Item>
        }
        {
          (type === 'post' || !(postOrComment as CommentType).parentCommentId)
          && <Menu.Item key="addComment">Add Comment</Menu.Item>
        }
        { type === 'post'
          && <Menu.Item key="deletePost">Delete Post</Menu.Item>}
        {postOrComment.isHidden
          ? <Menu.Item key="unhideContent">Unhide Content</Menu.Item>
          : <Menu.Item key="hideContent">Hide Content</Menu.Item>}
        <Menu.Item key="viewFlagStats">{`View ${postOrComment.participant?.username}'s Flag Stats`}</Menu.Item>
        {flagCount
          && <Menu.Item key="ignoreFlags">Ignore Flags</Menu.Item>}
      </Menu>
    );
  }

  createCommentMenu: any = (type:string, postOrComment: PostType | CommentType) => {
    const {
      hasCareNavigatorRole,
      pseudoParticipants,
      participants,
      allComments
    } = this.props;

    const {
      newCommentText,
      newDate
    } = this.state;

    if (!participants || pseudoParticipants && pseudoParticipants.length && !pseudoParticipants[0]) {
      return <div />; // this is a temporary state with incomplete data, future render will resolve
    }
    const pseudosThisArm = _.filter(pseudoParticipants, p => !!p.username && p.studyArmId === postOrComment.studyArmId);

    if (!pseudosThisArm?.length && !hasCareNavigatorRole) {
      return <div>There are no pseudo participants associated with your account</div>;
    }
    let participantSelectorOptions:CascaderOptionType[] = [];
    if (hasCareNavigatorRole) {
      participantSelectorOptions = _.map(_.filter(participants, p => !!p.username && p.studyArmId === postOrComment.studyArmId && p.type === 'PSEUDO PARTICIPANT'), participant => {
        return { label: participant.username, value: participant.id.toString() };
      });
    } else if (pseudosThisArm.length > 1) {
      participantSelectorOptions = _.map(pseudosThisArm, participant => {
        return { label: participant.username, value: participant.id.toString() };
      });
    }

    let parentDate:Moment;
    if (type === 'comment') {
      if ((postOrComment as CommentType).parentCommentId) {
        parentDate = moment(_.find(allComments, c => c.id === c.parentCommentId)!.createDate);
      } else {
        parentDate = moment((postOrComment as CommentType).createDate);
      }
    } else {
      parentDate = moment((postOrComment as PostType).publishDate);
    }

    const unselectableCommentDate = (date: Moment) => {
      return parentDate.isSameOrAfter(date);
    };

    return (
      <div id="create-comment-popover">
        <div id="button-row">
          <p>Add As</p>
          {hasCareNavigatorRole || pseudosThisArm.length > 1
            ? (
              <Cascader
                placeholder="Enter participant's username"
                options={participantSelectorOptions}
              // @ts-ignore
                onChange={this.handleSelectedParticipantChange}
                showSearch={{ filter }}
              />
            )
            : <p id="add-as-username">{pseudosThisArm[0].username}</p>}
        </div>
        <HMPTextArea
          style={{ width: '450px', marginBottom: '15px' }}
          value={newCommentText}
          minRows={15}
                     // @ts-ignore
          onChange={this.onCommentTextChange}
          spellCheck
          placeholder="Enter your comment"
        />
        Date of Comment:
        <span id="date-picker-container">
          <DatePicker
            id="date-picker"
            defaultValue={moment(newDate)}
            disabledDate={unselectableCommentDate}
            showTime
            allowClear={false}
            onChange={this.changePostPublishDate}
          />
        </span>
      </div>
    );
  }

  handleAddCommentSave = (type:string, postOrComment: PostType | CommentType) => (e: React.MouseEvent<HTMLElement>) => {
    const { post, pseudoParticipants, createComment } = this.props;
    const { newCommentText, selectedParticipantId, newDate } = this.state;
    const pseudosThisArm = _.filter(pseudoParticipants, p => !!p.username && p.studyArmId === postOrComment.studyArmId);

    const typeId = type === 'comment' ? post!.id : postOrComment.id;
    const parentCommentId = type === 'comment' ? (postOrComment as CommentType).id : undefined;
    const participantId = selectedParticipantId !== -1 ? selectedParticipantId : pseudosThisArm[0].id;

    createComment({
      type: 'post', typeId, comment: newCommentText, createDate: newDate, parentCommentId, participantId
    });

    this.setState({ newCommentText: '', selectedParticipantId: -1 });
    this.handleAddCommentPopupVisibleChange(false, type, typeId);
  }

flagStatsMenu: any = (type:string, postOrComment:PostType | CommentType) => {
  const {
    flags, posts, allComments, studyId
  } = this.props;

  const theirPosts = _.filter(posts, (p:PostType) => p.createdByParticipantId === postOrComment.participant?.id);
  const theirFlaggedPosts = _.filter(theirPosts, (p:PostType) => _.find(flags, f => f.flaggedParticipantId === p.createdByParticipantId));
  const theirHiddenPosts = _.filter(theirPosts, (p:PostType) => p.isHidden);
  const theirComments = _.filter(allComments, (c:CommentType) => c.participantId === postOrComment.participant?.id);
  const theirFlaggedComments = _.filter(theirComments, (c:CommentType) => _.find(flags, f => f.flaggedParticipantId === c.participantId));
  const theirHiddenComments = _.filter(theirComments, (c:CommentType) => c.isHidden);

  return (
    <div id="flag-stats-popover">
      <div id="title-row">
        <p>
          {postOrComment.participant?.username}
          's Flag Stats
        </p>
      </div>
      <div id="table-row">
        <table id="table">
          <thead>
            <tr>
              <th />
              <th />
              <th className="center">flagged</th>
              <th className="center">hidden</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Posts</td>
              <td>{theirPosts.length}</td>
              <td className="center">{theirFlaggedPosts.length}</td>
              <td className="center">{theirHiddenPosts.length}</td>
            </tr>
            <tr>
              <td>Comments</td>
              <td>{theirComments.length}</td>
              <td className="center">{theirFlaggedComments.length}</td>
              <td className="center">{theirHiddenComments.length}</td>
            </tr>
            <tr>
              <td className="bottom-row" />
              <td className="bottom-row">{theirPosts.length + theirComments.length}</td>
              <td className="center bottom-row">{theirFlaggedPosts.length + theirFlaggedComments.length}</td>
              <td className="center bottom-row">{theirHiddenPosts.length + theirHiddenComments.length}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

  renderCommentActions = (comment: CommentType) => {
    const { studyArms, post, forumPostDateMutableEnabled } = this.props;
    const postStudyArm:StudyArmType = _.find(studyArms, sa => post?.studyArmId === sa.id) as StudyArmType;

    return ([
      <Tooltip placement="bottom" title={this.flagHover('comment', comment.id)}>
        <Popover
          content={this.flagStatsMenu('comment', comment)}
          title="Resolve Flag"
          trigger="click"
          // @ts-ignore
          visible={this.isFlagResolutionPopupVisible('comment', comment.id)}
          onVisibleChange={(visible:boolean) => this.handleFlagResolutionPopupVisibleChange(visible, 'comment', comment.id)}
        >
          {this.flagCount('comment', comment.id).toString()}
          {' '}
          <Icon style={{ color: '#8185B3', marginRight: 15 }} component={this.iconFlagOutline} />
        </Popover>
      </Tooltip>,
      ...(comment.parentCommentId ? []
        : [<Tooltip placement="bottom" title={this.commentHover('post', comment.id)}>
          <Modal
            title="Add Comment"
            visible={this.isAddCommentPopupVisible('comment', comment.id)}
            destroyOnClose
            onOk={this.handleAddCommentSave('comment', comment)}
            onCancel={() => this.handleAddCommentPopupVisibleChange(false, 'comment', comment.id)}
          >
            {this.createCommentMenu('comment', comment)}
          </Modal>
          {this.commentCount('comment', comment.id).toString()}  <Icon style={{ color: '#8185B3', marginRight: '10px'}} component={this.iconCommentOutline}/>
        </Tooltip>])
     ,
    <Tooltip placement="bottom" title={this.thumbsupHover('comment', comment.id)}>
      {this.thumbsupCount('comment', comment.id).toString()}  <Icon style={{ color: '#8185B3', marginRight: 15 }} component={this.iconThumbsupOutline}/>
    </Tooltip>,
    <Tooltip placement="bottom" title={this.favoriteHover('comment', comment.id)}>
      {this.favoriteCount('comment', comment.id).toString()}  <Icon style={{ color: '#8185B3', marginRight: 15 }} component={this.iconFavoriteOutline}/>
    </Tooltip>
    ,
      ...(forumPostDateMutableEnabled ?
        [
          <Popover
            content={this.createActionMenu('comment', comment)}
            title="Action Menu"
            trigger="click"
            visible={this.isActionMenuVisible('comment', comment.id)}
            onVisibleChange={(visible:boolean) => this.handleActionMenuVisibleChange(visible, 'comment', comment.id)}
          >
            <Tooltip placement="bottom" title="Actions">
              <Icon style={{ color: '#8185B3', marginRight: '10px' }} component={this.iconElipsis} />
            </Tooltip>
          </Popover>
        ] : []
      )
    ]);
  }

  renderCommentsSection = (post:PostType) => {

    const { allComments, studyId } = this.props;
    const { newDate } = this.state;
    const postComments: any[] = _.filter(allComments, { type: 'post', typeId: post.id });
    const sortedComments: any[] = _.reverse(_.sortBy(postComments, 'createDate'));

    // delete any existing replies
    _.forEach(sortedComments, c => delete c.replies);

    // order the comments by created date with the most recent at the top
    const orderedComments = _.reduceRight(sortedComments, (arr:any[], comment: CommentType) => {
      if (comment.parentCommentId) {
        const parentComment:any = _.find(allComments, c => c.id === comment.parentCommentId);
        if (!parentComment.replies) {
          parentComment.replies = [];
        }
        parentComment.replies.splice(0, 0, comment);
      } else {
        arr.splice(0, 0, comment);
      }
      return arr;
    }, []);

    const renderContent = (comment:CommentType) => {
      return <span key={`body-${comment.id}`}>{comment.body}</span>;
    };

    const renderComments = (comments:any[]) => {
      return (
        <div>
          {_.map(comments, comment => {
            const commentThumbsUpCount = this.thumbsupCount('comment', comment.id);
            const avatarObject = comment.participant?.avatarId ? this.getAvatar(comment.participant.avatarId) : undefined;
            const avatar = <Avatar src={avatarObject?.avatar} style={{ backgroundColor: `#${comment.participant?.avatarBackground}` }} />;

            let parentDate:Moment;
            if (comment.parentCommentId) {
              parentDate = moment(_.find(allComments, c => c.id === comment.parentCommentId)!.createDate);
            } else {
              parentDate = moment(post.publishDate);
            }

            const unselectableCommentDate = (date: Moment) => {
              return parentDate.isSameOrAfter(date);
            };

            const tags: JSX.Element[] = [];
            if (comment.isHidden) {
              tags.push(<span key={`hidden-bubble-${comment.id}`} title="hidden" className="post-tag-bubble hidden">HIDDEN</span>);
            }
            return (
              <span key={`comment-span-${comment.id}`}>
                <AntdComment
                  key={`comment-${comment.id}`}
                  avatar={avatar}
                  author={(
                    <span style={{ color: '#ccc' }}>
                      <a key={`a-${comment.id}`} href={`/study/${studyId}/participants/${comment.participant?.id}/`}>{comment.username}</a>
                      {tags}
                    </span>
)}
                  content={renderContent(comment)}
                  datetime={(
                    <div>
                      <Modal
                        title="Change Comment Date"
                        visible={this.isPostDateChangePopupVisible('comment', comment.id)}
                        onOk={this.handleDateChangeOk('comment', comment.id)}
                        okText="Save"
                        destroyOnClose
                        onCancel={() => this.handlePostDateChangePopupVisibleChange(false, 'comment', comment.id)}
                      >
                        <div id="date-change-modal-content">
                  Date of Comment:
                        <span id="date-picker-container">
                          <DatePicker
                                     id="date-picker"
                                     defaultValue={moment(newDate)}
                                     showTime
                                     disabledDate={unselectableCommentDate}
                                     allowClear={false}
                                     onChange={this.changePostPublishDate}
                                   />
                        </span>
                </div>
                      </Modal>
                      <p id="comment-publish-date" className={`small-font ${moment(comment.createDate).isAfter(moment()) && 'post-tag-bubble unpublished'}`}>
                        {renderDateWithTime(comment.createDate)}
                      </p>
                    </div>
                         )}
                  actions={this.renderCommentActions(comment)}
                >
                  {renderComments(comment.replies)}
                </AntdComment>
              </span>
            );
          })}
        </div>
      );
    };

    return renderComments(orderedComments);

  };

  isFlagResolutionPopupVisible = (type:string, typeId: number) => {
    const { visibleFlagResolutionPopups } = this.state;
    return visibleFlagResolutionPopups[type].indexOf(typeId) !== -1;
  }

  isPostDateChangePopupVisible = (type:string, typeId: number) => {
    const { visiblePostDateChangePopups } = this.state;
    return visiblePostDateChangePopups[type].indexOf(typeId) !== -1;
  }

  handleFlagResolutionPopupVisibleChange = (visible:boolean, type:string, typeId:number) => {
    const updated:any = _.cloneDeep(this.state.visibleFlagResolutionPopups);
    if (visible) {
      updated[type].push(typeId);
    } else {
      updated[type] = _.without(updated[type], typeId);
    }
    this.setState({ visibleFlagResolutionPopups: updated });
  }

  handlePostDateChangePopupVisibleChange = (visible:boolean, type:string, typeId:number) => {
    const { post, allComments } = this.props;
    let newDate;
    if (type === 'post') {
      newDate = post!.publishDate;
    } else {
      const comment = _.find(allComments, c => c.id === typeId);
      newDate = comment!.createDate;
    }
    const updated:any = _.cloneDeep(this.state.visiblePostDateChangePopups);
    if (visible) {
      updated[type].push(typeId);
    } else {
      updated[type] = _.without(updated[type], typeId);
    }
    this.setState({ visiblePostDateChangePopups: updated, newDate });
  }

  isAddCommentPopupVisible = (type:string, typeId: number) => {
    const { visibleAddCommentPopups } = this.state;
    return visibleAddCommentPopups[type].indexOf(typeId) !== -1;
  }

  isActionMenuVisible = (type:string, typeId: number) => {
    const { visibleActionMenus } = this.state;
    return visibleActionMenus[type].indexOf(typeId) !== -1;
  }

  handleAddCommentPopupVisibleChange = (visible:boolean, type:string, typeId:number) => {
    const updated:any = _.cloneDeep(this.state.visibleAddCommentPopups);
    if (visible) {
      updated[type].push(typeId);
    } else {
      updated[type] = _.without(updated[type], typeId);
    }
    this.setState({ visibleAddCommentPopups: updated });
  }

  handleActionMenuVisibleChange = (visible:boolean, type:string, typeId:number) => {
    const updated:any = _.cloneDeep(this.state.visibleActionMenus);
    if (visible) {
      updated[type].push(typeId);
    } else {
      updated[type] = _.without(updated[type], typeId);
    }
    this.setState({ visibleActionMenus: updated });
  }

  render() {
    const {
      post, selectedStyle, mode, participants
    } = this.props;

    if (!post || !participants) return <div>error</div>;

    const avatar = post.participant?.avatarId ? this.getAvatar(post.participant.avatarId) : undefined;

    const fadeCssClass = PostMode.PARTIAL === mode ? 'three-line-fade' : '';
    const mediaCssClass = PostMode.PARTIAL === mode ? 'body-media-partial' : '';
    const alertBoxCss = post.isHidden ? 'alert-box' : '';
    let body;
    if (post.poll) {
      const onelinefade = PostMode.PARTIAL === mode ? 'one-line-fade' : '';
      const options = PostMode.PARTIAL === mode ? _.slice(post.poll.options, 0, 2) : post.poll.options;
      body = (
        <div>
          <p id="body-text" className={`${onelinefade}`}>
            {post.body}
          </p>
          <div className={`${PostMode.PARTIAL === mode && 'poll-body-options'}`}>
            {_.map(options, item => {
              return (
                <span key={item.option}>
                  {' '}
                  {item.option}
                  :
                  {' '}
                  {item.count}
                  <br />
                </span>
              );
            })}
            {options.length === post.poll.options.length ? undefined
              : (
                <span>
                  {' '}
                  {post.poll.options.length - options.length}
                  {' '}
                  more
                  {' '}
                </span>
              )}
          </div>
        </div>
      );
    } else if (post.postLinks) {

      // there can only be one entry in the array postLinks per the current design
      const postLink = post.postLinks[0];

      if (postLink.type === 'web') {
        body = (
          <div>
            <p id="body-text" className={`${fadeCssClass}`}>
              {post.body}
            </p>
            <a href={postLink.url}>{postLink.url}</a>
          </div>
        );
      } else if (postLink.type === 'image') {
        // const url = _.replace(postLink.url, 'https://hmpweb-dev.med.unc.edu/api/c/', 'http://localhost:3001/api/a/') + `?participantId=${post.createdByParticipantId}`;
        const url = `${_.replace(postLink.url, '/c/upload/', '/a/upload/')}?participantId=${post.createdByParticipantId}`;
        body = (
          <div id="body-media" className={`${mediaCssClass}`}>
            <div style={{ height: '100%', maxWidth: '50%' }} className={`${alertBoxCss}`}>
              <SecureImage url={url} />
            </div>
            <p id="body-text" className={`${fadeCssClass}`}>
              {post.body}
            </p>
          </div>
        );
      } else if (postLink.type === 'video') {
        const url = _.replace(postLink.url, 'https://youtu.be/', 'https://www.youtube.com/embed/');
        // @ts-ignore
        body = (
          <div id="body-media">
            <div id="ytContainer" className={`${PostMode.PARTIAL === mode ? 'partial-container' : 'full-container'}`}>
              <iframe id="ytplayer" className={`${PostMode.PARTIAL === mode ? 'partial-player' : 'full-player'}`} type="text/html" src={url} frameBorder="0" />
            </div>
            <p id="body-text" className={`${fadeCssClass}`}>
              {post.body}
            </p>
          </div>
        );
      } else {
        body = (
          <p>
            Unhandled Post Link Type:
            {postLink.type}
          </p>
        );
      }
    } else {
      body = (
        <p id="body-text" className={`${fadeCssClass}`}>
          {post.body}
        </p>
      );
    }

    return (
      <div id="post-container" key={post.id} style={{ ...selectedStyle }}>
        <Row>
          <Col span={24}>{this.renderPostItemHeader(post)}</Col>
        </Row>
        <Row>
          <Col span={24}>
            <div id="post-body-container">{body}</div>
          </Col>
        </Row>
        <Col span={24}>
          <div>
            {this.props.mode === PostMode.FULL ? this.renderCommentsSection(post) : <div /> }
          </div>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  hasCareNavigatorRole: selectors.hasCareNavigatorRole,
  pseudoParticipants: selectors.getCurrentUserPseudoParticipants,
  participants: selectors.getParticipants,
  posts: selectors.getPosts,
  allComments: selectors.getComments,
  allFlags: selectors.getFlags,
  allFavorites: selectors.getFavorites,
  allThumbsups: selectors.getThumbsups,
  avatars: selectors.getAvatars,
  topics: selectors.getFlattenedForumTopics,
  studyArms: selectors.getStudyArms,
  studyId: selectors.getRequestedStudyId,
    forumPostDateMutableEnabled: selectors.getForumPostDateMutableEnabled
});

const mapDispatchToProps = (dispatch: Dispatch) : DispatchProps => {
  return {
    resolveFlags: (flagResolution: {ids: number[], resolution: string}) => dispatch(resolveFlagsAsync.request(flagResolution)),
    hideContent: (payload: HideContentPayloadType) => dispatch(hideContentAsync.request(payload)),
    unhideContent: (payload: HideContentPayloadType) => dispatch(unhideContentAsync.request(payload)),
    savePost: (post: PostType) => dispatch(savePostAsync.request(post)),
    deletePost: (id: number) => dispatch(deletePostAsync.request(id)),
    createComment: (param: CreateUpdateCommentParam) => dispatch(createCommentAsync.request(param)),
    updateComment: (param: CommentType) => dispatch(updateCommentAsync.request(param))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Post);
