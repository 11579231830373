import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import moment from 'moment';
import React, { Component } from 'react';
import {
  ParticipantType,
  StudyArmType,
} from '../../../../types/serverTypes/studyTypes';
import * as selectors from '../../../../redux/selectors';
import { createStructuredSelector } from 'reselect';
import IApplicationState from '../../../../types/state.types';
import { connect } from 'react-redux';
var momentzone = require('moment-timezone');

interface StateProps {
  showReferrals: boolean;
  showSurveys: boolean;
}

interface ComponentProps extends StateProps {
  studyArm: StudyArmType;
  participant: ParticipantType;
  editClickHandler: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

class ParticipantDemographicsCard extends Component<ComponentProps, {}> {
  render() {
    const {
      studyArm,
      participant,
      editClickHandler,
      showSurveys,
      showReferrals,
    } = this.props;

    return (
      <Card key="view">
        <div className="profile">
          <div className="profile-container">
            <div className="demo">
              <div className="demo-section">
                <div className="demo-row">
                  <div className="label">
                    <label>First Name</label>
                  </div>
                  <div className="field">{participant.firstName}</div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Last Name</label>
                  </div>
                  <div className="field">{participant.lastName}</div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Username</label>
                  </div>
                  <div className="field">{participant.username}</div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Email</label>
                  </div>
                  <div className="field">{participant.email}</div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Mobile</label>
                  </div>
                  <div className="field">{participant.mobile}</div>
                </div>
                <div className="demo-row">
                  <div className="label" style={{ alignSelf: 'flex-start' }}>
                    <label>Address</label>
                  </div>
                  <div className="field">
                    {participant.address1
                      ? participant.address1
                      : participant.address1}
                    <br />
                    {participant.address2 ? `${participant.address2}` : ''}
                    {participant.address2 ? <br /> : ''}
                    {`${participant.city ? participant.city : ''}, ${
                      participant.state ? participant.state : ''
                    } ${participant.postalCode ? participant.postalCode : ''}`}
                  </div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Date of Birth</label>
                  </div>
                  <div className="field">
                    {participant.dateOfBirth
                      ? moment(participant.dateOfBirth).format('MM/DD/YYYY')
                      : ''}
                  </div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Age</label>
                  </div>
                  <div className="field">
                    {participant.dateOfBirth
                      ? moment().diff(participant.dateOfBirth, 'years')
                      : ''}
                  </div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Sex at Birth</label>
                  </div>
                  <div className="field">{participant.genderAtBirth}</div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Gender Identity</label>
                  </div>
                  <div className="field">{participant.genderIdentity}</div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Ethnicity</label>
                  </div>
                  <div className="field">{participant.ethnicity}</div>
                </div>
              </div>
              <div className="demo-section">
                <div className="demo-row">
                  <div className="label">
                    <label>Created Date</label>
                  </div>
                  <div className="field">
                    {moment(participant.createDate).format('MM/DD/YYYY')}
                  </div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Status</label>
                  </div>
                  <div className="field">{participant.status}</div>
                </div>

                <div className="demo-row">
                  <div className="label">
                    <label>Access Code</label>
                  </div>
                  <div className="field">{participant.accessCode}</div>
                </div>
                {showReferrals && (
                  <div className="demo-row">
                    <div className="label">
                      <label>Referral Code</label>
                    </div>
                    <div className="field">{participant.referralCode}</div>
                  </div>
                )}
                <div className="demo-row">
                  <div className="label">
                    <label>Pin</label>
                  </div>
                  <div className="field">{participant.pin}</div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Mobile OS</label>
                  </div>
                  <div className="field">{participant.mobileOs}</div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Time Zone</label>
                  </div>
                  <div className="field">
                    {participant.timeZone} (
                    {momentzone().tz(participant.timeZone).format('z')})
                  </div>
                </div>
              </div>
              <div className="demo-section">
                <div className="demo-row">
                  <div className="label">
                    <label>Applied Date</label>
                  </div>
                  <div className="field">
                    {participant.appliedDate
                      ? moment(participant.appliedDate).format('MM/DD/YYYY')
                      : null}
                  </div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Accepted Date</label>
                  </div>
                  <div className="field">
                    {participant.acceptedDate
                      ? moment(participant.acceptedDate).format('MM/DD/YYYY')
                      : null}
                  </div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Start Date</label>
                  </div>
                  <div className="field">
                    {participant.startDate
                      ? moment(participant.startDate).format('MM/DD/YYYY')
                      : null}
                  </div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>End Date</label>
                  </div>
                  <div className="field">
                    {participant.endDate
                      ? moment(participant.endDate).format('MM/DD/YYYY')
                      : null}
                  </div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Mobile Enroll Date</label>
                  </div>
                  <div className="field">
                    {participant.mobileEnrollmentDate
                      ? moment(participant.mobileEnrollmentDate).format(
                          'MM/DD/YYYY'
                        )
                      : null}
                  </div>
                </div>
                <div className="demo-row">
                  <div className="label">
                    <label>Graduation Date</label>
                  </div>
                  <div className="field">
                    {participant.graduationDate
                      ? moment(participant.graduationDate).format('MM/DD/YYYY')
                      : null}
                  </div>
                </div>
              </div>
              <div className="demo-section">
                <div className="demo-row" style={{ alignItems: 'flex-start' }}>
                  <div className="label">
                    <label>About Me</label>
                  </div>
                  <textarea
                    rows={3}
                    style={{ width: '250px', overflowY: 'visible' }}
                    readOnly
                  >
                    {participant.aboutMe}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="actions">
              <Button onClick={editClickHandler}>Edit</Button>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    showSurveys: selectors.getSurveysFeatureEnabled,
    showReferrals: selectors.getReferralsFeatureEnabled,
  }
);

export default connect(mapStateToProps, null)(ParticipantDemographicsCard);
