import React, { Component } from 'react';
import { Input, Form, Button } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import './treeNode.scss';
import { TreeOptionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  disabled: boolean;
  option: TreeOptionType;
  addQuestion: (optionId: string) => void;
  updateOption: (option: TreeOptionType) => void;
  removeOption: (id: string) => void;
}

interface ComponentState {}

class TreeOptionItem extends Component<ComponentProps, ComponentState> {
  styles: any = {
    deleteButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#f17878',
      borderColor: '#f17878',
      backgroundColor: '#FBDDDD',
      marginTop: '5px',
      marginRight: '5px',
      marginBottom: '5px'
    },
    addButton: {
      width: '200px',
      color: '#439AFF',
      borderColor: '#439AFF',
      backgroundColor: '#D0E6FF',
      marginTop: '5px',
      marginBottom: '5px'
    },
    option: {
      backgroundColor: '#eeeef1'
    }
  };

  onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { option, updateOption } = this.props;
    updateOption({
      ...option,
      text: e.target.value
    });
  };

  render() {
    const {
      disabled, option, removeOption, addQuestion
    } = this.props;
    const { text, id, questionId } = option;
    return (
      <div className="tree-node-content" style={this.styles.option}>
        <Item
          key={`node-option-text-${id}`}
          name={`node-option-text-${id}`}
          initialValue={text}
          noStyle
        >
          <Input
            spellCheck
            disabled={disabled}
            placeholder="Option text"
            bordered={false}
            onChange={this.onTextChange}
          />
        </Item>
        {!questionId && (
          <Button
            key={`add-option-${id}`}
            className="tree-node-add"
            disabled={disabled}
            type="dashed"
            onClick={() => addQuestion(id)}
            style={this.styles.addButton}
          >
            <PlusOutlined />
            {' '}
            Add question
          </Button>
        )}
        {!disabled && (
          <Button
            key={`delete-option-${id}`}
            className="tree-node-delete"
            color="#f17878"
            onClick={() => removeOption(id)}
            style={this.styles.deleteButton}
          >
            <CloseOutlined />
          </Button>
        )}
      </div>
    );
  }
}

export default TreeOptionItem;
