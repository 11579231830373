import { Layout, message } from 'antd';
import { _ } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getType } from 'typesafe-actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { clearStatus } from '../../../redux/api/api.types';
import MessageList from '../../../components/inbox/MessageList';
import NoThreadSelected from '../../../components/inbox/NoThreadSelected';
import ThreadList from '../../../components/inbox/ThreadList';
import {
  clearMessages,
  createThreadAsync,
  GetInboxArguments,
  getMessageInboxAsync,
  GetMessagesArguments,
  getMessagesAsync
} from '../../../redux/messages/messages.types';
import * as selectors from '../../../redux/selectors';
import { IApiRequestStatus } from '../../../types/api.types';
import IApplicationState from '../../../types/state.types';
import './inbox.scss';
import { getParticipantsByStudyAsync } from '../../../redux/participants/participants.types';
import { getAvatarsAsync } from '../../../redux/avatar/avatar.types';
import { MessageResponseType, MessageThreadType, MessageType } from '../../../types/serverTypes/messageTypes';

const { Sider, Content } = Layout;

interface StateProps {
  studyId: Optional<number>;
  userId: Optional<number>;
  responses: Optional<MessageResponseType[]>;
  threads: Optional<MessageThreadType[]>;
  messages: Optional<MessageType[]>;
  loadInboxStatus: IApiRequestStatus;
  createMessageThreadStatus: IApiRequestStatus;
  threadCount: number;
  view: string
  filterBy: string;
}

interface DispatchProps {
  getParticipants: typeof getParticipantsByStudyAsync.request;
  getAvatars: typeof getAvatarsAsync.request;
  loadInbox: typeof getMessageInboxAsync.request;
  getMessages: typeof getMessagesAsync.request;
  clearMessages: typeof clearMessages;
  clearStatus: typeof clearStatus;
}

interface ComponentProps extends StateProps, DispatchProps, RouteComponentProps { }

class Inbox extends Component<ComponentProps, {}> {
  readonly state = {
    activeThreadId: -1,
    responses: this.props.responses,
    pageNumber: 0,
    previewLength: 40,
    pageSize: 10,
    hasMore: true,
    loading: false,
    hasMoreMessages: true,
    loadingMessages: false,
    searchTerm: '',
    sortBy: 'newest'
  }

  componentDidMount() {
    const {
      getMessages, getParticipants, studyId, getAvatars
    } = this.props; const { activeThreadId } = this.state;

    this.handleInfiniteLoad(0);

    if (activeThreadId !== -1) {
      getMessages({ threadId: activeThreadId });
    }
    if (studyId) {
      getParticipants(studyId);
    }
    getAvatars();
  }

  componentDidUpdate(prevProps: ComponentProps) {
    const { loading } = this.state;
    const {
      loadInboxStatus, threads, threadCount, createMessageThreadStatus, clearStatus
    } = this.props;

    if (!prevProps.loadInboxStatus.isSuccess && loadInboxStatus.isSuccess) {
      // If the activeThreadId isn't in the current threads, reset it.
      if (!this.props.threads?.filter(t => t.id === this.state.activeThreadId).length) {
        this.setState({
          activeThreadId: undefined,
          loading: false
        });
      } else {
        this.setState({
          loading: false
        });
      }
    }
    if (loading && threads && threadCount === threads.length) {
      this.setState({ loading: false });
    }

    // Check create thread async status
    if (!prevProps.createMessageThreadStatus.isError && createMessageThreadStatus.isError) {
      message.error(`Error: ${createMessageThreadStatus.errorMessage}`);
      clearStatus(getType(createThreadAsync.failure));
    }
    if (!prevProps.createMessageThreadStatus.isSuccess && createMessageThreadStatus.isSuccess) {
      message.success('Thread created successfully.');
      clearStatus(getType(createThreadAsync.success));
    }
  }

  setActiveThread = (threadId: number) => {
    const { getMessages } = this.props;
    const { activeThreadId } = this.state;
    getMessages({ pageSize: 16, threadId });
    if (activeThreadId !== threadId) {
      this.setState({
        activeThreadId: threadId
      });
    }
  }

  refreshThreads = () => {
    this.handleInfiniteLoad(0);
  };

  getMessages = (pageSize: number, pageNumber: number) => {
    const { getMessages } = this.props;
    const { activeThreadId } = this.state;
    getMessages({ threadId: activeThreadId, pageSize, pageNumber });
  };

  handleInfiniteLoad = (pageNumberParamFromInfiniteLoader: number) => {
    const {
      loadInboxStatus, loadInbox, view = 'all', filterBy = 'None'
    } = this.props;
    const {
      previewLength, pageNumber, pageSize, searchTerm, sortBy
    } = this.state;

    if (loadInboxStatus.isError) {
      this.setState({ loading: false });
    } else {

      loadInbox({
        previewLength,
        pageNumber,
        pageSize,
        searchTerm,
        view,
        filterBy,
        sortBy
      });
      this.setState({
        pageNumber: pageNumber + 1,
        loading: true
      });
    }
  };

  debouncedSearch = _.debounce(this.handleInfiniteLoad, 500);

  handleSearch = (searchTerm: string) => {
    const { studyId, clearMessages } = this.props;

    this.props.history.push(`/study/${studyId}/inbox?view=all`);

    this.setState({
      pageNumber: 0,
      searchTerm,
      view: 'all',
      filterBy: 'none',
      hasMore: true,
      pageSize: 10,
      loading: true
    }, () => {
      clearMessages();
      this.debouncedSearch(-1);
    });
  };

  setView = (view: string) => {
    const { filterBy } = this.props;
    this.recallHandleInfiniteLoadAfterFilterUpdate(view, filterBy);
  }

  setFilterBy = (filterBy: string) => {
    const { view } = this.props;
    this.recallHandleInfiniteLoadAfterFilterUpdate(view, filterBy);
  };

  setSortBy = (sortBy: string) => {
    const { threads } = this.props;
    const {
      pageSize
    } = this.state;
    // We want to retain the same number of records when we sort
    let newPageSize = pageSize;
    if (threads && threads.length && threads.length > pageSize) {
      newPageSize = threads.length;
    }
    this.setState({
      pageNumber: 0,
      sortBy,
      searchTerm: undefined,
      pageSize: newPageSize
    }, () => {
      clearMessages();
      this.handleInfiniteLoad(-1);
    });
  };

  recallHandleInfiniteLoadAfterFilterUpdate = (view:string, filterBy:string) => {
    const { clearMessages, studyId } = this.props;
    let url = `/study/${studyId}/inbox?view=${view}`;
    if (!_.isEmpty(filterBy)) {
      url += `&filterBy=${filterBy}`;
    }
    this.props.history.push(url);

    this.setState({
      pageNumber: 0,
      searchTerm: '',
      pageSize: 10,
      hasMore: true
    }, () => {
      clearMessages();
      this.handleInfiniteLoad(-1);
    });
  }

  onScroll = (event: UIEvent) => {
    if (!event.target) return;
    const { scrollHeight, clientHeight, scrollTop } = event!.target!;

    if (Math.round(scrollHeight - scrollTop) === clientHeight) {
      let { pageNumber } = this.state;
      pageNumber++;
      this.setState({ pageNumber });
      this.handleInfiniteLoad(pageNumber);
    }
  }

  render() {
    const {
      activeThreadId,
      loading,
      sortBy,
      searchTerm
    } = this.state;
    const {
      responses,
      threads,
      loadInboxStatus,
      view = 'all',
      filterBy = 'None'
    } = this.props;
    const { userId } = this.props;
    const response: MessageResponseType = _.filter(responses, response => response.threadId === activeThreadId)[0];

    return (
      <Layout className="inbox">
        <Sider
          collapsible={false}
          style={{ backgroundColor: 'white' }}
          width={400}
        >
          <div className="sidebar">
            <ThreadList
              setActiveThread={this.setActiveThread}
              activeThreadId={activeThreadId}
              userId={userId}
              handleSearch={this.handleSearch}
              searchTerm={searchTerm}
              setView={this.setView}
              setFilterBy={this.setFilterBy}
              setSortBy={this.setSortBy}
              refreshThreads={this.refreshThreads}
              view={view}
              filterBy={filterBy}
              sortBy={sortBy}
              onScroll={this.onScroll}
            />
          </div>
        </Sider>
        <Content className="scrollable content">
          {activeThreadId !== -1 && response
            ? (
              <MessageList
                userId={userId || -1}
                response={response}
                getMessages={this.getMessages}
              />
            )
            : <NoThreadSelected />}
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state: IApplicationState): StateProps => {
  return {
    studyId: selectors.getRequestedStudyId(state),
    userId: state.auth.user ? state.auth.user.id : -1,
    responses: selectors.getMessageResponses(state),
    messages: selectors.getMessages(state),
    loadInboxStatus: selectors.loadMessageInboxStatus(state),
    createMessageThreadStatus: selectors.createMessageThreadStatus(state),
    threadCount: selectors.getMessageThreadCount(state),
    threads: selectors.getMessageThreads(state),
    view: selectors.getRequestedInboxView(state),
    filterBy: selectors.getRequestedInboxFilterBy(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    getAvatars: () => dispatch(getAvatarsAsync.request()),
    getParticipants: (studyId: number) => dispatch(getParticipantsByStudyAsync.request(studyId)),
    loadInbox: (args: GetInboxArguments) => dispatch(getMessageInboxAsync.request(args)),
    getMessages: (args: GetMessagesArguments) => dispatch(getMessagesAsync.request(args)),
    clearMessages: () => dispatch(clearMessages()),
    clearStatus: (type: string) => dispatch(clearStatus(type))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Inbox));
