import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, DatePicker, Descriptions } from 'antd';
import { reviewTestkitAsync } from '../../redux/testkit/testkit.types';
import { TestkitType } from '../../types/serverTypes/testkitTypes';

const { Item } = Descriptions;

interface StateProps {
}

interface DispatchProps {
  reviewTestkit: typeof reviewTestkitAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {
  testkit: TestkitType,
  trackingNumber: string,
}

const inputStyle = {
  marginTop: '5px',
  marginBottom: '5px'
};

class TestkitRequestedCard extends Component<ComponentProps, {}> {
  readonly state = {
    reportDate: undefined
  };

  onChange(state: any) {
    this.setState(state);
  }

  handleHemaspotReceipt = () => {
    const { reviewTestkit, testkit } = this.props;
    const { reportDate } = this.state;
    const reviewArg = {
      id: testkit.id,
      reportDate
    };
    reviewTestkit(reviewArg);
  };

  render() {
    const { testkit, trackingNumber } = this.props;
    const { participant } = testkit;

    if (!participant) {
      return <div>Participant Not Found.</div>;
    }

    return (
      <Descriptions layout="vertical" column={testkit.type.match(/oraquick/i) ? 5 : 6}>
        <Item label="Fulfillment">
          <span>{`${testkit.firstName} ${testkit.lastName}`}</span>
          <br />
          <span>{`${testkit.address1}`}</span>
          <br />
          {testkit.address2 ? (
            <span>
              {`${testkit.address2}`}
              <br />
            </span>
          ) : ''}
          <span>{`${testkit.city}, ${testkit.state} ${testkit.postalCode}`}</span>
          <br />
        </Item>
        <Item label="Type">
          <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{`${testkit.type}`}</span>
        </Item>
        <Item label="Tracking ID:"><span><a href={`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${testkit.trackingNumber}`}>{testkit.trackingNumber}</a></span></Item>
        <Item label="USPS Delivered Date"><span>{testkit.deliveredDate && testkit.receivedDate ? moment(testkit.deliveredDate).calendar() : 'Kit not yet delivered according to USPS.'}</span></Item>
        $
        {testkit.type.match(/oraquick/i)
          ? undefined
          : (
            <Item label="Actions">
              <Button type="primary" onClick={this.handleHemaspotReceipt} disabled={!this.state.reportDate} style={{ marginTop: '5px' }}>Mark Received</Button>
              <div>
                <br />
                <DatePicker placeholder="Report Date" style={inputStyle} onChange={(date, dateString) => this.onChange({ reportDate: dateString })} />
              </div>
            </Item>
          )}
      </Descriptions>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    reviewTestkit: (testkit) => dispatch(reviewTestkitAsync.request(testkit))
  };
};

export default connect(null, mapDispatchToProps)(TestkitRequestedCard);
