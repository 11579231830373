import moment from 'moment';
import { AxiosRequestConfig } from 'axios';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { loadUser } from 'redux-oidc';
import createSagaMiddleware from 'redux-saga';
import userManager from '../oidc/UserManager';
import axios from './api';
import rootReducer from './reducers';
import rootSaga from './sagas';
import IApplicationState from '../types/state.types';
import ClientWebSocketManager from './clientWebsocketManager';

export default function configureStore(history: History): Store<any> {
  let composeEnhancers = compose;

  if (process.env.NODE_ENV !== 'production') {
    composeEnhancers = composeWithDevTools({});
  }

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer(history),
    {},
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  );

  sagaMiddleware.run(rootSaga);
  loadUser(store, userManager);
  new ClientWebSocketManager(store);

  // create axios interceptor to add the Access Token to every request header
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const { user } = (store.getState() as IApplicationState).oidc;
      if (user) {
        config.headers.Authorization = `Bearer ${user.access_token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const isoDateFormat =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

  function isIsoDateString(value: any): boolean {
    return value && typeof value === 'string' && isoDateFormat.test(value);
  }

  function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== 'object')
      return body;

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (isIsoDateString(value)) {
        body[key] = moment(value).toDate();
      } else if (typeof value === 'object') handleDates(value);
    }
  }

  axios.interceptors.response.use((originalResponse) => {
    handleDates(originalResponse.data);
    return originalResponse;
  });

  return store;
}
