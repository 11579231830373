import { createSelector } from 'reselect';
import toArray from 'lodash/toArray';
import { denormalize } from 'normalizr';
import IApplicationState from '../../types/state.types';
import { selectParticipants } from '../participants/participants.selectors';
import entitySchema from '../schema';
import { selectAvatars } from '../avatar/avatar.selectors';
import { getCommentFlags } from '../flags/flags.selectors';
import { getCommentFavorites } from '../favorites/favorites.selectors';
import { getCommentThumbsups } from '../thumbsups/thumbsups.selectors';
import {CommentType} from "../../types/serverTypes/forumTypes";

export const selectComments = (state: IApplicationState) => {
  return state.entities.comments;
};

export const getComments = createSelector(
  [selectComments, selectParticipants],
  (comments, participants): Optional<CommentType[]> => {
    if (comments && comments.allIds && comments.allIds.length > 0) {
      const { comments: denormalizedComments } = denormalize({ comments: comments.allIds }, entitySchema, { comments: comments.byId, participants: participants.byId});
      return toArray(denormalizedComments);
    }
    return undefined;
  }
);

export const getCommentComments = createSelector(
  [selectComments, selectParticipants, selectAvatars],
  (comments, participants, avatars): Optional<CommentType[]> => {
    if (comments && comments.allIds && comments.allIds.length > 0) {
      const { comments: denormalizedComments } = denormalize({ comments: comments.allIds }, entitySchema, { comments: comments.byId, participants: participants.byId, avatars: avatars.byId});
      const qnaComments = toArray(denormalizedComments).filter(c => c.type === 'qna' && c.parentCommentId);
      return qnaComments;
    }
    return undefined;
  }
);

export const getQnaComments = createSelector(
  [selectComments, selectParticipants, selectAvatars, getCommentComments, getCommentFavorites, getCommentFlags, getCommentThumbsups],
  (comments, participants, avatars, childComments, favorites, flags, thumbsups): Optional<CommentType[]> => {
    if (comments && comments.allIds && comments.allIds.length > 0) {
      const { comments: denormalizedComments } = denormalize({ comments: comments.allIds }, entitySchema, { comments: comments.byId, participants: participants.byId, avatars: avatars.byId});
      const qnaComments = toArray(denormalizedComments).filter(c => c.type === 'qna' && !c.parentCommentId);
      const mappedComments = qnaComments.map(comment => {
        if(childComments) {
          comment.comments = childComments.filter(c => c.parentCommentId === comment.id).map(c => {
            c.flags = flags ? flags.filter(f => f.typeId === c.id) : [];
            c.thumbsups = thumbsups ? thumbsups.filter(t => t.typeId === c.id) : [];
            c.favorites = favorites ? favorites.filter(f => f.typeId === c.id) : [];
            return c;
          });
        }
        if(favorites) {
          comment.favorites = favorites.filter(f => f.typeId === comment.id);
        }
        if(flags) {
          comment.flags = flags.filter(f => f.typeId === comment.id);
        }
        if(thumbsups) {
          comment.thumbsups = thumbsups.filter(t => t.typeId === comment.id);
        }
        return comment;
      });
      return mappedComments;
    }
    return undefined;
  }
);

export default { getComments };
