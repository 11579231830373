import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  BadgesActionTypes,
  deleteBadgeCollectionAsync,
  getBadgeCollectionsAsync,
  saveBadgeCollectionAsync,
  undeleteBadgeCollectionAsync
} from './badges.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import {BadgeType} from "../../types/serverTypes";

const updateBadgesReducer = produce((draft, badges) => {
  if (!badges) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(badges), Number));
  const ids = Object.keys(badges);
  for (const id of ids) {
    draft.byId[id] = badges[id];
  }
});

export const badgeCollectionsReducer = (state: NormalizedState<BadgeType> = initialNormalizedState, action: BadgesActionTypes) => {
  switch (action.type) {
    case getType(getBadgeCollectionsAsync.success):
    case getType(saveBadgeCollectionAsync.success):
    case getType(deleteBadgeCollectionAsync.success):
    case getType(undeleteBadgeCollectionAsync.success):
      return updateBadgesReducer(state, action.payload);
      break;
    default:
      return state;
  }
};

export default badgeCollectionsReducer;
