import { ActionType, createStandardAction, createAsyncAction } from 'typesafe-actions';
import { getParticipantPostsAsync, getPostsAsync, getUncategorizedPostsAsync, updatePosts } from '../posts/posts.types';
import { NormalizedType } from '../../types/state.types';
import {ForumDetailType} from "../../types/serverTypes/forumTypes";

export interface IForumUIState  {
  recentActivityPostIds: number[],
  uncategorizedPostIds: number[],
  flaggedPostIds: number[],
  recentActivity: {
    scrollTop: number,
    selectedPostId: Optional<number>,
    selectedQnaPostId: Optional<number>
  },
  flaggedPosts: {
    scrollTop: number,
    selectedPostId: Optional<number>
  },
  uncategorizedPosts: {
    scrollTop: number,
    selectedPostId: Optional<number>
  }
}

export const resetRecentActivityPostIds = createStandardAction('hmp/admin/RESET_RECENT_ACTIVITY_POST_IDS')<void>();
export const updateRecentActivityPostIds = createStandardAction('hmp/admin/UPDATE_RECENT_ACTIVITY_POST_IDS')<number[]>();
export const updateUncategorizedPostIds = createStandardAction('hmp/admin/UPDATE_UNCATEGORIZED_POST_IDS')<number[]>();
export const updateFlaggedPostIds = createStandardAction('hmp/admin/UPDATE_FLAGGED_POST_IDS')<number[]>();

export const updateRecentActivityScroll = createStandardAction('hmp/admin/UPDATE_RECENT_ACTIVITY_SCROLL')<number>();
export const updateFlaggedScroll = createStandardAction('hmp/admin/UPDATE_FLAGGED_SCROLL')<number>();
export const updateUncategorizedScroll = createStandardAction('hmp/admin/UPDATE_UNCATEGORIZED_SCROLL')<number>();

export const updateRecentActivitySelectedPostId = createStandardAction('hmp/admin/UPDATE_RECENT_ACTIVITY_POST_ID')<number>();
export const updateRecentActivitySelectedQnaPostId = createStandardAction('hmp/admin/UPDATE_RECENT_QNA_ACTIVITY_POST_ID')<number>();
export const resetRecentActivitySelectedQnaPostId = createStandardAction('hmp/admin/RESET_RECENT_QNA_ACTIVITY_POST_ID')();
export const updateFlaggedSelectedPostId = createStandardAction('hmp/admin/UPDATE_FLAGGED_POST_ID')<number>();
export const updateUncategorizedSelectedPostId = createStandardAction('hmp/admin/UPDATE_UNCATEGORIZED_POST_ID')<number>();

export const getForumDetailsByStudyIdAsync = createAsyncAction(
  'hmp/admin/GET_FORUM_DETAILS_BY_STUDY_ID_REQUEST',
  'hmp/admin/GET_FORUM_DETAILS_BY_STUDY_ID_SUCCESS',
  'hmp/admin/GET_FORUM_DETAILS_BY_STUDY_ID_FAILURE',
)<number, Optional<NormalizedType<ForumDetailType>>, Error>();

const forumActions = {
  resetRecentActivityPostIds,
  updateRecentActivityPostIds,
  updateUncategorizedPostIds,
  updateFlaggedPostIds,
  updateRecentActivityScroll,
  updateFlaggedScroll,
  updateUncategorizedScroll,
  updateRecentActivitySelectedPostId,
  updateRecentActivitySelectedQnaPostId,
  updateFlaggedSelectedPostId,
  updateUncategorizedSelectedPostId,
  resetRecentActivitySelectedQnaPostId,
  getForumDetailsByStudyIdAsync
};

export type ForumActionTypes = ActionType<typeof forumActions>;
