import {
  ActionType,
  createAsyncAction,
} from "typesafe-actions";
import { NormalizedType } from "../../types/state.types";
import {AvailabilityType} from "../../types/serverTypes/appointmentTypes";

export interface GetAvailabilitiesQueryParameters {
  startDate: Date;
  endDate?: Date;
}

export const getAvailabilitiesAsync = createAsyncAction(
  "hmp/admin/GET_AVAILABILITY_REQUEST",
  "hmp/admin/GET_AVAILABILITY_SUCCESS",
  "hmp/admin/GET_AVAILABILITY_FAILURE"
)<GetAvailabilitiesQueryParameters | undefined, Optional<NormalizedType<AvailabilityType>>,Error>();

export const createAvailabilityAsync = createAsyncAction(
  "hmp/admin/CREATE_AVAILABILITY_REQUEST",
  "hmp/admin/CREATE_AVAILABILITY_SUCCESS",
  "hmp/admin/CREATE_AVAILABILITY_FAILURE"
)<AvailabilityType, Optional<NormalizedType<AvailabilityType>>, Error>();

export const deleteAvailabilityAsync = createAsyncAction(
  "hmp/admin/DELETE_AVAILABILITY_REQUEST",
  "hmp/admin/DELETE_AVAILABILITY_SUCCESS",
  "hmp/admin/DELETE_AVAILABILITY_FAILURE"
)<number, number, Error>();

const availabilityActions = {
  getAvailabilitiesAsync,
  createAvailabilityAsync,
  deleteAvailabilityAsync,
};

export type AvailabilityActionTypes = ActionType<typeof availabilityActions>;
