import React, { Component } from 'react';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import Form from 'antd/lib/form';
import FeedbackItem from './FeedbackItem';
import '../activity.scss';
import { FeedbackType, OptionType } from '../../../types/serverTypes/activityTypes';

const { Item } = Form;

interface ComponentProps {
  type: number;
  disabled: boolean;
  feedback?: FeedbackType;
  option: OptionType;
  updateFeedback: (feedback: FeedbackType) => void;
  addFeedback: () => void;
  removeFeedback: (e: React.MouseEvent<HTMLElement>) => void;
}
const itemStyle = {
  width: '100%',
  marginBottom: '10px',
  marginTop: '10px'
};

class OptionFeedbackItem extends Component<ComponentProps, {}> {
  onAddClick = (e: React.MouseEvent<HTMLElement>) => {
    const { addFeedback } = this.props;
    addFeedback();
  };

  render() {
    const {
      type,
      disabled,
      feedback,
      updateFeedback,
      removeFeedback,
      option
    } = this.props;
    return (
      <div className="feedback-item">
        {feedback ? (
          <ul className="feedback-list-item-container">
            <FeedbackItem
              type={type}
              disabled={disabled}
              feedback={feedback}
              options={[option]}
              updateFeedback={updateFeedback}
            />
            {(!disabled)
            && (
            <button className="dashed-danger-button" onClick={removeFeedback}>
              <MinusOutlined />
              {' '}
              Remove Option Feedback
            </button>
            )}
          </ul>
        )
          : (
            <Item style={itemStyle}>
              <Button disabled={disabled} type="dashed" onClick={this.onAddClick} style={{ width: '100%' }}>
                <PlusOutlined />
                {' '}
                Add feedback
              </Button>
            </Item>
          )}
      </div>
    );
  }
}

export default OptionFeedbackItem;
