import React, { Component } from 'react';
import { Upload, Button, message } from 'antd';
import './activity.scss';
import { MAX_UPLOAD_SIZE_BYTES } from '../../constant/serverConstants/appConstants';
interface ComponentProps {
  disabled: boolean;
  image: string | File | undefined;
  uploadFile: (image: File) => Promise<boolean>;
  removeFile: () => void;
}

class ActivityImageUpload extends Component<ComponentProps, {}> {
  readonly state = {
    fileList: this.props.image ? [this.props.image] : new Array(),
    uploading: false,
  };

  onRemove = (file: any) => {
    const { removeFile } = this.props;
    this.setState(
      {
        fileList: [],
      },
      async () => {
        removeFile();
      }
    );
  };

  beforeUpload = (file: any) => {
    const { uploadFile } = this.props;
    if (file.size <= MAX_UPLOAD_SIZE_BYTES) {
      this.setState(
        {
          fileList: [file],
        },
        async () => {
          await uploadFile(file)
            .then(() => {
              message.success('Uploaded successfully.');
            })
            .catch((err) => {
              message.error(`Error uploading: ${err}`);
            });
        }
      );
    } else {
      message.error(
        `Upload failed. File exceeds ${MAX_UPLOAD_SIZE_BYTES / 1000}KB limit.`
      );
    }
    return false;
  };

  render() {
    const { disabled } = this.props;
    const { fileList } = this.state;
    return (
      <div className="activity-image-upload">
        <Upload
          disabled={disabled}
          beforeUpload={this.beforeUpload}
          onRemove={this.onRemove}
          fileList={fileList}
          className="upload-list-inline"
        >
          <Button style={{ whiteSpace: 'pre-wrap' }}>
            <i className="fal fa-upload" /> Upload
          </Button>
        </Upload>
      </div>
    );
  }
}

export default ActivityImageUpload;
