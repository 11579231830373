import React, { Component } from 'react';
import './thread.scss';
import { Modal, Transfer } from 'antd';
import IApplicationState from 'types/state.types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { _ } from 'lodash';
import * as selectors from '../../redux/selectors';
import { AdminUserType } from '../../types/serverTypes/adminTypes';
import { ThreadMemberType } from '../../types/serverTypes/messageTypes';

interface StateProps {
  admin: Optional<AdminUserType[]>;
}

interface ComponentProps extends StateProps {
  members: ThreadMemberType[];
  handleAssign: (assignIds: number[], unassignIds: number[]) => Promise<void>;
  close: () => void;
  visible: boolean;
}

type TransferItem = {
  key: string;
  title: string;
}

class ThreadMembers extends Component<ComponentProps, {}> {
  readonly state = {
    targetKeys: this.props.members.filter(m => m.isAssigned).map(m => m.userId.toString()),
    confirmLoading: false
  }

  handleOk = async () => {
    this.setState({
      confirmLoading: true
    });
    const { handleAssign, members } = this.props;
    const { targetKeys } = this.state;
    const assignKeys: number[] = targetKeys.map(key => parseInt(key));
    const unassignKeys: number[] = _.difference(members.filter(m => m.isAssigned).map(m => m.userId), assignKeys);
    await handleAssign(assignKeys, unassignKeys).then(res => {
      this.setState({
        confirmLoading: false
      });
      this.props.close();
    });
  };

  handleCancel = () => {
    this.props.close();
  };

  handleChange = (nextTargetKeys: string[], direction: string, moveKeys: string[]) => {
    this.setState({
      targetKeys: nextTargetKeys
    });
  };

  mapAdminUserToTransferItem = (user: AdminUserType): TransferItem => {
    return {
      key: user.id.toString(),
      title: `${user.firstName} ${user.lastName}`
    };
  };

  render() {
    const { visible, admin } = this.props;
    const { confirmLoading, targetKeys } = this.state;
    const dataSource: TransferItem[] = admin ? admin.map(this.mapAdminUserToTransferItem).sort((a, b) => a.title.localeCompare(b.title)) : [];
    return (
      <Modal
        destroyOnClose
        closable={false}
        centered
        title="Assign/Unassign"
        visible={visible}
        okText="Submit"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        confirmLoading={confirmLoading}
      >
        <Transfer
          dataSource={dataSource}
          showSearch={false}
          showSelectAll={false}
          titles={['Unassigned', 'Assigned']}
          operations={['Assign', 'Unassign']}
          targetKeys={targetKeys}
          onChange={this.handleChange}
          render={item => `${item.title}`}
        />
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
  admin: selectors.getAdmin
});

export default connect(mapStateToProps)(ThreadMembers);
