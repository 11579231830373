import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import {
  all, call, fork, put, takeLatest
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { entitySchema } from '../schema';
import {
  getForumDetailsByStudyIdAsync
} from './forum.types';
import {NormalizerResult} from "../../types";

const getForumDetailsByStudyId = (studyId: number) => {
  return axios({
    method: 'get',
    url: `/a/forum/${studyId}/details`
  });
};

function* getForumDetailsByStudyIdHandler(action: ReturnType<typeof getForumDetailsByStudyIdAsync.request>): Generator {
  try {
    const studyId: number = action.payload;
    const response: AxiosResponse = (yield call(getForumDetailsByStudyId, studyId)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.forums) as NormalizerResult;
    const { forums } = entities;

    yield put(getForumDetailsByStudyIdAsync.success(forums));
  } catch (error) {
    yield put(getForumDetailsByStudyIdAsync.failure(error));
  }
}

function* getForumDetailsByStudyIdWatcher() {
  yield takeLatest(getType(getForumDetailsByStudyIdAsync.request), getForumDetailsByStudyIdHandler);
}

export default function* forumSaga() {
  yield all([
    fork(getForumDetailsByStudyIdWatcher)
  ]);
}