import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { OidcProvider } from 'redux-oidc';
import App from './App';
import userManager from './oidc/UserManager';
import Callback from './pages/auth/Callback';
import Logout from './pages/auth/Logout';
import SilentRenew from './pages/auth/SilentRenew';
import './index.less';
import './index.scss';
import MobilePageNotFound from './pages/404/MobilePageNotFound';
import configuredStore from './redux/store';

const root = document.getElementById('root');

ReactDOM.render(
  <Provider store={configuredStore.store}>
    <OidcProvider userManager={userManager} store={configuredStore.store}>
      <ConnectedRouter history={configuredStore.history}>
        <Switch>
          <Route exact path="/auth/callback" component={Callback} />
          <Route exact path="/auth/logout" component={Logout} />
          <Route exact path="/auth/renew" component={SilentRenew} />
          <Route path="/hmpmobile" component={MobilePageNotFound} />
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </OidcProvider>
  </Provider>,
  root
);
