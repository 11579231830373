import { _ } from 'lodash';
import { Button, Table } from 'antd';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import IApplicationState from '../../types/state.types';
import { renderPopoverDate } from '../util/Util';
import { TestkitType } from '../../types/serverTypes/testkitTypes';

interface StateProps {
}

interface DispatchProps {
}

interface ComponentProps extends DispatchProps, StateProps {
  participantTestkits: TestkitType[]
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a: any, b: any) => a.id - b.id
  }, {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: (a: any, b: any) => `${a.type}`.localeCompare(b.type)
  }, {
    title: 'Request Date',
    dataIndex: 'requestDate',
    key: 'requestDate',
    render: renderPopoverDate,
    sorter: (a: any, b: any) => moment(a.requestDate).unix() - moment(b.requestDate).unix(),
    defaultSortOrder: 'descend'
  }, {
    title: 'Address',
    dataIndex: 'firstName',
    key: 'firstName',
    render: (value:any, row: any) => {

      const {
        firstName, lastName, address1, address2, city, state, postalCode
      } = row;
      return (
        <div style={{ lineHeight: '12px' }}>
          <p>
            {firstName}
            {' '}
            {lastName}
          </p>
          <p>{address1}</p>
          {!_.isEmpty(address2) ? <p>{address2}</p> : undefined }
          <p>
            {city}
            ,
            {' '}
            {state}
            {' '}
            {postalCode}
          </p>
        </div>
      );
    }
  }, {
    title: 'Sent Date',
    dataIndex: 'sentDate',
    key: 'sentDate',
    render: renderPopoverDate,
    sorter: (a: any, b: any) => moment(a.sentDate).unix() - moment(b.sentDate).unix()
  }, {
    title: 'Estimated Delivered Date',
    dataIndex: 'estimatedDeliveryDate',
    key: 'estimatedDeliveryDate',
    render: renderPopoverDate,
    sorter: (a: any, b: any) => moment(a.estimatedDeliveryDate).unix() - moment(b.estimatedDeliveryDate).unix()
  }, {
    title: 'Tracking Number',
    dataIndex: 'trackingNumber',
    key: 'trackingNumber',
    sorter: (a: any, b: any) => `${a.trackingNumber}`.localeCompare(b.trackingNumber)
  }, {
    title: 'Delivered Date',
    dataIndex: 'deliveredDate',
    key: 'deliveredDate',
    render: renderPopoverDate,
    sorter: (a: any, b: any) => moment(a.deliveredDate).unix() - moment(b.deliveredDate).unix()
  }, {
    title: 'Received Date',
    dataIndex: 'receivedDate',
    key: 'receivedDate',
    render: renderPopoverDate,
    sorter: (a: any, b: any) => moment(a.receivedDate).unix() - moment(b.receivedDate).unix()
  }, {
    title: 'Report Date',
    dataIndex: 'reportDate',
    key: 'reportDate',
    render: renderPopoverDate,
    sorter: (a: any, b: any) => moment(a.reportDate).unix() - moment(b.reportDate).unix()
  }, {
    title: 'Completed Date',
    dataIndex: 'completedDate',
    key: 'completedDate',
    render: renderPopoverDate,
    sorter: (a: any, b: any) => moment(a.completedDate).unix() - moment(b.completedDate).unix()
  }
];

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

class TestkitTable extends PureComponent<ComponentProps, {}> {

  render() {
    const { participantTestkits } = this.props;
    return (
      <div>
        <Table
          columns={columns}
          dataSource={participantTestkits}
          pagination={{ pageSize: 20 }}
          rowClassName={rowClassName}
          rowKey="id"
          // expandedRowRender={this.rowRender}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>({
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestkitTable);
