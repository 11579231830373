import { Location } from 'history';
import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { Modal } from 'antd';

interface ComponentProps {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}
interface ComponentState {
  modalVisible: boolean;
  lastLocation: Location | null;
  confirmedNavigation: boolean;
}
/**
 * Credit:
 * https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
 *
 * @param when?: boolean | undefined;
 * @param navigate: (path: string) => void;
 * @param shouldBlockNavigation: (location: Location) => boolean;
 *
 * @example
 * ...
 * render() {
 * ...
 *  <React.Fragment>
 *      // ...Other scene components
 *      <RouteLeavingGuard
 *         when={isDirty}
 *         navigate={path => history.push(path)}
 *         shouldBlockNavigation={location => {
 *            if (isDirty) {
 *              if (location.pathname === 'signup') {
 *                return true
 *              }
 *            }
 *            return false
 *         }}
 *      />
 *  </React.Fragment>
 * ...
 * }
 */
class UnsavedLeavingGuard extends Component<ComponentProps, ComponentState> {
  readonly state: ComponentState = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false
  };

  componentDidUpdate() {
    const {
      navigate
    } = this.props;
    const {
      confirmedNavigation,
      lastLocation
    } = this.state;
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with navigate function
      navigate(lastLocation.pathname);
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.windowConfirmLeave);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.windowConfirmLeave);
  }

  windowConfirmLeave = (e: BeforeUnloadEvent) => {
    const {
      when
    } = this.props;
    if (when) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  setModalVisible = (visible: boolean) => {
    this.setState({
      modalVisible: visible
    });
  };

  setLastLocation = (location: Location | null) => {
    this.setState({
      lastLocation: location
    });
  };

  setConfirmedNavigation = (confirmed: boolean) => {
    this.setState({
      confirmedNavigation: confirmed
    });
  };

  closeModal = () => {
    this.setModalVisible(false);
  };

  handleBlockedNavigation = (nextLocation: Location): boolean => {
    const {
      shouldBlockNavigation
    } = this.props;
    const {
      confirmedNavigation
    } = this.state;
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.setModalVisible(true);
      this.setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  handleConfirmNavigationClick = () => {
    this.setModalVisible(false);
    this.setConfirmedNavigation(true);
  };

  render() {
    const {
      when
    } = this.props;
    const {
      modalVisible
    } = this.state;
    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <Modal
          visible={modalVisible}
          title="Close without saving?"
          okText="Leave"
          okType="danger"
          onOk={this.handleConfirmNavigationClick}
          onCancel={this.closeModal}
        >
          You have unsaved changes. Are you sure you want to leave this page without saving?
        </Modal>
      </>
    );
  }
}
export default UnsavedLeavingGuard;
