import React, { Component } from 'react';
import Avatar from 'antd/lib/avatar';
import Dropdown from 'antd/lib/dropdown';
import Layout from 'antd/lib/layout';
import Menu from 'antd/lib/menu';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { faChevronDown, faUser } from '@fortawesome/pro-solid-svg-icons';
import AntdIcon from '../antdIcon/AntdIcon';
import * as selectors from '../../redux/selectors';
import { getUser } from '../../redux/selectors';
import { toggleSideBar } from '../../redux/sidebar/sidebar.types';
import { logout } from '../../redux/app/app.types';
import IApplicationState from '../../types/state.types';
import SideBarToggleButton from '../sidebar/SideBarToggleButton';
import { AdminUserType } from '../../types/serverTypes/adminTypes';
import './header.scss';

interface StateProps {
  collapsed: boolean;
  user: Optional<AdminUserType>;
  studyId: number;
}

interface DispatchProps {
  toggleCallback: () => void;
  logout: typeof logout;
}

interface ComponentProps extends StateProps, DispatchProps {}

class Header extends Component<ComponentProps> {
  handleLogout = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    this.props.logout();
  };

  handleToggleButtonClick = () => {
    const { toggleCallback } = this.props;
    toggleCallback();
  };

  render() {
    const { Header: AntdHeader } = Layout;
    const { children, collapsed, user, studyId } = this.props;

    const userMenu = (
      <Menu>
        <Menu.Item key="/study/settings">
          <a className="user-menu-item" href={`/study/${studyId}/settings`}>
            <span>Settings</span>
          </a>
        </Menu.Item>
        <Menu.Item key="logout">
          <a className="user-menu-item" onClick={this.handleLogout}>
            <span>Logout</span>
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <AntdHeader id="header">
        <div id="header-left">
          <SideBarToggleButton
            collapsed={collapsed}
            toggleCallback={this.handleToggleButtonClick}
          />
          {children}
        </div>
        <div id="header-right">
          <span>
            <Avatar size="small" icon={<AntdIcon fontAwesomeIcon={faUser} />} />
          </span>
          <Dropdown overlay={userMenu} trigger={['click']}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {user?.firstName} <AntdIcon fontAwesomeIcon={faChevronDown} />
            </a>
          </Dropdown>
        </div>
      </AntdHeader>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    collapsed: selectors.isSidebarCollapsed,
    user: getUser,
    studyId: selectors.getRequestedStudyId,
  }
);

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    logout: () => dispatch(logout()),
    toggleCallback: () => dispatch(toggleSideBar())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
