import { AxiosResponse } from 'axios';
import {
  all, call, fork, put, select, takeLatest
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import {
  loadDashboardDataAsync
} from './dashboard.types';

const getDashboardData = () => {
  return axios({
    method: 'get',
    url: `/a/dashboard/1`
  });
};

function* loadDashboardDataHandler(action: ReturnType<typeof loadDashboardDataAsync.request>): Generator {
  try {
    const response: AxiosResponse  = (yield call(getDashboardData)) as AxiosResponse;

    yield put(loadDashboardDataAsync.success(response.data));

  } catch (error) {
    yield put(loadDashboardDataAsync.failure(error));
  }
}

function* loadDashboardDataWatcher() {
  yield takeLatest(getType(loadDashboardDataAsync.request), loadDashboardDataHandler);
}

export default function* dashbordSaga() {
  yield all([
    fork(loadDashboardDataWatcher)
  ])
}
