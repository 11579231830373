import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import * as _ from 'lodash';
import {
  all, call, fork, put, takeLatest
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { entitySchema } from '../schema';
import {
  getAnnouncementsAsync,
  createAnnouncementAsync,
  updateAnnouncements,
  updateAnnouncementAsync
} from './announcements.types';
import {AnnouncementType} from "../../types/serverTypes/announcementTypes";
import {NormalizerResult} from "../../types";

const getAnnouncements = (studyId: number) => {
  return axios({
    method: 'get',
    url: `/a/announcement/${studyId}`
  });
};

const createAnnouncement = (announcement: AnnouncementType) => {
  const {
    type,
    typeId
  } = announcement;
  return axios({
    method: 'put',
    url: `/a/announcement/${type}/${typeId}/create`,
    data: announcement
  });
}

const updateAnnouncement = (announcement: AnnouncementType) => {
  const {
    id
  } = announcement;
  return axios({
    method: 'put',
    url: `/a/announcement/${id}/update`,
    data: announcement
  });
}

function* getAnnouncementsHandler(action: ReturnType<typeof getAnnouncementsAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(getAnnouncements, action.payload)) as AxiosResponse;
    const { entities } = normalize(response.data, entitySchema.announcements) as NormalizerResult;
    const { announcements } = entities;
    yield put(getAnnouncementsAsync.success(announcements));
  } catch (error) {
    yield put(getAnnouncementsAsync.failure(error as Error));
  }
}

function* createAnnouncementHandler(action: ReturnType<typeof createAnnouncementAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(createAnnouncement, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.announcements) as NormalizerResult;
    const { announcements } = entities;
    yield put(createAnnouncementAsync.success(announcements));
  } catch (error) {
    yield put(createAnnouncementAsync.failure(error as Error));
  }
}

function* updateAnnouncementHandler(action: ReturnType<typeof updateAnnouncementAsync.request>): Generator {
  try {
    const response: AxiosResponse = (yield call(updateAnnouncement, action.payload)) as AxiosResponse;
    const { entities } = normalize([response.data], entitySchema.announcements) as NormalizerResult;
    const { announcements } = entities;
    yield put(updateAnnouncementAsync.success(announcements));
  } catch (error) {
    yield put(updateAnnouncementAsync.failure(error as Error));
  }
}

function* getAnnouncementsWatcher() {
  yield takeLatest(getType(getAnnouncementsAsync.request), getAnnouncementsHandler);
}

function* createAnnouncementWatcher() {
  yield takeLatest(getType(createAnnouncementAsync.request), createAnnouncementHandler);
}

function* updateAnnouncementWatcher() {
  yield takeLatest(getType(updateAnnouncementAsync.request), updateAnnouncementHandler);
}

export default function* announcementsSaga() {
  yield all([
    fork(getAnnouncementsWatcher),
    fork(createAnnouncementWatcher),
    fork(updateAnnouncementWatcher)
  ]);
}
