import React from 'react';
import userManager from '../../oidc/UserManager';

const LoginPage = () => {

  const onLoginButtonClick = () => {
    userManager.signinRedirect();
  };

  return (
    <div style={{
      margin: 20, display: 'flex', justifyContent: 'center', flexDirection: 'column'
    }}
    >
      <h2>Welcome to HealthMpowerment!!</h2>
      <p>Please log in.</p>
      <button style={{ width: 150 }} onClick={onLoginButtonClick}>Login</button>
    </div>
  );
};

export default LoginPage;
