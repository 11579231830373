import _ from 'lodash';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import Draggable from 'react-draggable';
import UnsavedLeavingGuard from '../util/UnsavedLeavingGuard';
import IApplicationState from '../../types/state.types';
import * as selectors from '../../redux/selectors';
import './appointmentModal.scss';
import { ModalHeader } from '../modal/ModalHeader';
import { createStructuredSelector } from 'reselect';
import { AvailabilityType } from '../../types/serverTypes/appointmentTypes';
import AvailabilityForm from './AvailabilityForm';
import { deleteAvailabilityAsync } from '../../redux/availabilty/availability.types';
import moment from 'moment';

const { confirm } = Modal;

interface StateProps {
  studyId: Optional<number>;
  newOrEdit: Optional<string>;
  requestedAvailabilityTab: Optional<string>;
}

interface DispatchProps {
  deleteAvailability: typeof deleteAvailabilityAsync.request;
}

interface ComponentProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {
  selectedAvailability: AvailabilityType;
  saveAvailability: Function;
  removeEvent?: Function;
}

interface ComponentState {
  isUnsaved: boolean;
  disableDrag: boolean;
}

const defaultState: ComponentState = {
  isUnsaved: false,
  disableDrag: true,
};

class AvailabilityModal extends Component<ComponentProps, ComponentState> {
  readonly state = _.clone(defaultState);

  form = React.createRef<FormInstance>();

  handleSave = async () => {
    const { studyId, history } = this.props;
    if (this.form.current) {
      try {
        const values = await this.form.current.validateFields();
        const { userId, startDate, startTime, endTime } = values;
        const newAvailability: AvailabilityType = {
          userId,
          startDate: moment(
            `${startDate.format('YYYY-MM-DD')} ${startTime.format('hh:mm A')}`,
            'YYYY-MM-DD hh:mm A'
          ).toDate(),
          endDate: moment(
            `${startDate.format('YYYY-MM-DD')} ${endTime.format('hh:mm A')}`,
            'YYYY-MM-DD hh:mm A'
          ).toDate(),
        };
        this.props.saveAvailability(newAvailability);
        this.setIsUnsaved(false);
        history.push(`/study/${studyId}/appointments/availability`);
      } catch (err) {
        console.log(err);
      }
    }
  };

  handleCancel = () => {
    const { history, studyId, removeEvent, newOrEdit } = this.props;
    if (newOrEdit === 'new' && removeEvent) {
      removeEvent();
    }
    history.push(`/study/${studyId}/appointments/availability`);
  };

  handleRemove = () => {
    const {
      studyId,
      selectedAvailability,
      newOrEdit,
      deleteAvailability,
      history,
      removeEvent,
    } = this.props;
    if (selectedAvailability) {
      if (newOrEdit === 'new' && removeEvent) {
        removeEvent();
      } else {
        confirm({
          title: 'Are you sure you want to remove this availability?',
          okText: 'Remove',
          okType: 'danger',
          onOk: () => {
            deleteAvailability(selectedAvailability.id!);
            this.resetState();
            history.push(`/study/${studyId}/appointments/availability`);
            if (removeEvent) {
              removeEvent();
            }
          },
          onCancel() {},
        });
      }
    }
  };

  setIsUnsaved = (isUnsaved: boolean) => {
    this.setState({ isUnsaved });
  };

  resetState = () => {
    this.setState(_.clone(defaultState));
  };

  onChange = () => {
    this.setState({
      isUnsaved: true,
    });
  };

  onMouseOverHeader = () => {
    if (this.state.disableDrag) {
      this.setState({
        disableDrag: false,
      });
    }
  };

  onMouseOutHeader = () => {
    if (!this.state.disableDrag) {
      this.setState({
        disableDrag: true,
      });
    }
  };

  render() {
    const { isUnsaved, disableDrag } = this.state;
    const { newOrEdit, requestedAvailabilityTab, selectedAvailability } =
      this.props;

    const showModal: boolean = !!(
      newOrEdit && requestedAvailabilityTab === 'availability'
    );

    return (
      <Modal
        title={
          <ModalHeader
            editing={!!newOrEdit}
            showRemove={newOrEdit === 'edit'}
            showSave={newOrEdit === 'new' || isUnsaved}
            editClick={() => {}}
            removeClick={this.handleRemove}
            closeClick={this.handleCancel}
            saveClick={this.handleSave}
            onMouseOver={this.onMouseOverHeader}
            onMouseOut={this.onMouseOutHeader}
          />
        }
        visible={showModal}
        onOk={this.handleSave}
        onCancel={this.handleCancel}
        footer={null}
        closable={false}
        mask={false}
        destroyOnClose
        afterClose={() => this.resetState()}
        modalRender={(modal) => (
          <Draggable disabled={disableDrag}>{modal}</Draggable>
        )}
      >
        <>
          <UnsavedLeavingGuard
            when={isUnsaved}
            navigate={(path) => {
              this.props.history.push(path);
            }}
            shouldBlockNavigation={() => isUnsaved}
          />
          <AvailabilityForm
            formRef={this.form}
            availability={selectedAvailability}
            onChange={this.onChange}
            disabled={newOrEdit !== 'new'}
          />
        </>
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    studyId: selectors.getRequestedStudyId,
    requestedAvailabilityTab: selectors.getUrlRouteSubpage,
    newOrEdit: selectors.getUrlRouteNewOrEdit,
  }
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    deleteAvailability: (id: number) =>
      dispatch(deleteAvailabilityAsync.request(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AvailabilityModal));
