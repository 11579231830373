import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  getParticipantsByStudyArmAsync,
  ParticipantActionTypes,
  saveParticipantActualHivStatusAsync,
  saveParticipantAsync,
  saveParticipantInterviewSelectedAsync,
  updateParticipants,
  getParticipantsByStudyAsync,
} from "./participants.types";
import { initialNormalizedState, NormalizedState } from '../../types/state.types';
import {ParticipantType} from "../../types/serverTypes/studyTypes";

const updateParticipantsReducer = produce((draft, participants) => {
  if (!participants) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(participants), Number));
  const ids = Object.keys(participants);
  for (const id of ids) {
    draft.byId[id] = participants[id];
  }
});

export const participantsReducer = (
  state: NormalizedState<ParticipantType> = initialNormalizedState,
  action: ParticipantActionTypes
) => {
  switch (action.type) {
    case getType(saveParticipantAsync.success):
      return updateParticipantsReducer(state, action.payload);
    case getType(updateParticipants):
      return updateParticipantsReducer(state, action.payload);
    case getType(saveParticipantActualHivStatusAsync.success):
      return updateParticipantsReducer(state, action.payload);
    case getType(saveParticipantInterviewSelectedAsync.success):
      return updateParticipantsReducer(state, action.payload);
    case getType(getParticipantsByStudyArmAsync.success):
      return updateParticipantsReducer(state, action.payload);
      case getType(getParticipantsByStudyAsync.success):
        return updateParticipantsReducer(state, action.payload);
    default:
      return state;
  }
};

export default participantsReducer;
