import { ActionType, createAction, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import {CreateUpdateCommentParam, SocialJsonParamType} from '../user/socialUtil';
import {CommentType} from "../../types/serverTypes/forumTypes";

export const getParticipantCommentsAsync = createAsyncAction(
  'hmp/admin/GET_PARTICIPANT_COMMENTS_REQUEST',
  'hmp/admin/GET_PARTICIPANT_COMMENTS_SUCCESS',
  'hmp/admin/GET_PARTICIPANT_COMMENTS_FAILURE'
)<number, Optional<NormalizedType<CommentType>>, Error>();

export const getCommentsAsync = createAsyncAction(
  'hmp/admin/GET_COMMENTS_REQUEST',
  'hmp/admin/GET_COMMENTS_SUCCESS',
  'hmp/admin/GET_COMMENTS_FAILURE'
)<SocialJsonParamType[], Optional<NormalizedType<CommentType>>, Error>();

export const createCommentAsync = createAsyncAction(
  'hmp/admin/CREATE_COMMENT_REQUEST',
  'hmp/admin/CREATE_COMMENT_SUCCESS',
  'hmp/admin/CREATE_COMMENT_FAILURE'
)<CreateUpdateCommentParam, Optional<NormalizedType<CommentType>>, Error>();

export const updateCommentAsync = createAsyncAction(
  'hmp/admin/UPDATE_COMMENT_REQUEST',
  'hmp/admin/UPDATE_COMMENT_SUCCESS',
  'hmp/admin/UPDATE_COMMENT_FAILURE'
)<CommentType, Optional<NormalizedType<CommentType>>, Error>();

export const updateComments = createStandardAction('hmp/admin/UPDATE_COMMENTS')<Optional<NormalizedType<CommentType>>>();

const commentsActions = {
  getParticipantCommentsAsync,
  getCommentsAsync,
  createCommentAsync,
  updateCommentAsync,
  updateComments
};

export type CommentsActionTypes = ActionType<typeof commentsActions>;
