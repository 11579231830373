import { getType } from 'typesafe-actions';
import { DashboardActionTypes, loadDashboardDataAsync } from './dashboard.types';
import {DashboardDataType} from "../../types";

const initialDashboardData: DashboardDataType = {
  testkits: {
    requestedCount: -1, needsReviewCount: -1, seroconversionCount: -1, awaitingResultCount: -1, completedCount: -1
  },
  payments: { unpaid: -1 },
  forum: { uncategorized: -1, flagged: -1 },
  inbox: { unreadThreads: -1, unreadMyThreads: -1, unreadAssignedThreads: -1 },
  qnas: { unansweredCount: -1 },
  forms: { openFormsCount: -1 }
};

export const dashboardReducer = (state: DashboardDataType  = initialDashboardData, action: DashboardActionTypes) => {

  switch (action.type) {
    case getType(loadDashboardDataAsync.success): return action.payload;
    default: return state;
  }
};

export default dashboardReducer;
