import { getType } from 'typesafe-actions';
import produce from 'immer';
import keys from 'lodash/keys';
import map from 'lodash/map';
import union from 'lodash/union';
import {
  getFormsAsync, FormsActionTypes, updateForms, saveFormAsync, getParticipantFormsAsync, updateFormAsync
} from './form.types';
import { initialNormalizedState, NormalizedState } from '../../types/state.types';

const updateFormsReducer = produce((draft, forms) => {
  if (!forms) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(forms), Number));
  const ids = Object.keys(forms);
  for (const id of ids) {
    draft.byId[id] = forms[id];
  }
});

export const formsReducer = (state: NormalizedState<FormType> = initialNormalizedState, action: FormsActionTypes) => {

  switch (action.type) {
    case getType(getFormsAsync.success): return updateFormsReducer(state, action.payload);
    case getType(getParticipantFormsAsync.success): return updateFormsReducer(state, action.payload);
    case getType(saveFormAsync.success): return updateFormsReducer(state, action.payload);
    case getType(updateForms): return updateFormsReducer(state, action.payload);
    case getType(updateFormAsync.success): return updateFormsReducer(state, action.payload);
    default: return state;
  }

};

export default formsReducer;
