import React, { Component } from 'react';
import moment from 'moment';
import './newQnaPostForm.scss';
import { Comment } from 'antd';
import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import Username from '../username/Username';
import QnaCommentList from './QnaCommentList';
import FlagActionItem from './FlagActionItem';
import { CommentType } from '../../types/serverTypes/forumTypes';

interface ComponentProps {
  level: number;
  comment: CommentType;
}

class QnaComment extends Component<ComponentProps, {}> {
  renderDate = (value: string | Date) => {
    if (!value) {
      return 'N/a';
    }
    return moment(value).calendar();
  };

  render() {
    const { comment, level } = this.props;
    const {
      id,
      participant,
      body,
      createDate,
      comments: childComments,
      thumbsups: commentThumbsups,
      favorites: commentFavorites,
      flags,
      isHidden
    } = comment;
    if (participant) {
      const {
        id: participantId,
        avatar,
        avatarBackground
      } = participant;
      const tags = [];
      if (isHidden) {
        tags.push(<span title="hidden" className="tag-bubble hidden">HIDDEN</span>);
      }
      return (
        <div>
          <Comment
            style={
              level > 0
                ? {
                  marginLeft: `${level * 10 + 10}px`,
                  backgroundColor: 'white'
                }
                : { backgroundColor: 'white' }
            }
            className="qna-post-comment"
            actions={[
              <span key="list-vertical-message">
                <MessageOutlined style={{ marginRight: 8 }} />
                {childComments ? childComments.length : 0}
              </span>,
              <span key="list-vertical-like-o">
                <LikeOutlined style={{ marginRight: 8 }} />
                {commentThumbsups ? commentThumbsups.length : 0}
              </span>,
              <span key="list-vertical-star-o">
                <StarOutlined style={{ marginRight: 8 }} />
                {commentFavorites ? commentFavorites.length : 0}
              </span>,
              <FlagActionItem
                participantId={participantId}
                id={id}
                isHidden={isHidden}
                type="comment"
                flags={flags}
              />
            ]}
            author={(
              <span>
                <Username
                  participantId={participantId}
                  style={{ color: '#8185B3' }}
                />
                {tags}
              </span>
            )}
            avatar={(
              <img
                className="qna-avatar"
                alt="avatar"
                src={`${avatar ? avatar.avatar : ''}`}
                style={{
                  backgroundColor: avatarBackground
                    ? `#${avatarBackground}`
                    : '#FFB100'
                }}
              />
            )}
            content={<p>{body}</p>}
            datetime={moment(createDate).calendar()}
          />
          {childComments && childComments.length > 0 && (
            <QnaCommentList level={level + 1} comments={childComments} />
          )}
        </div>
      );
    }
    return <div />;
  }
}

export default QnaComment;
