import React, { Component, RefObject } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IApplicationState from '../../types/state.types';
import {
  Input, InputNumber, Modal, Popover, Select
} from 'antd';
import * as selectors from '../../redux/selectors';
import { createStructuredSelector } from 'reselect';
import './badge.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import Form, { FormInstance } from 'antd/es/form';
import { saveBadgeCollectionAsync } from '../../redux/badges/badges.types';
import {
  BadgeCollectionType, BadgeCollectionTypeType, BadgeGroupType, BadgeType
} from '../../types/serverTypes';

const { confirm } = Modal;

interface StateProps {
  studyId: Optional<number>,
  badges: Optional<BadgeType[]>,
  badgeGroups: Optional<BadgeGroupType[]>,
  badgeCollectionTypes: Optional<BadgeCollectionTypeType[]>
  badgeCollection: Optional<BadgeCollectionType>
}

interface DispatchProps {
  saveBadgeCollection: typeof saveBadgeCollectionAsync.request
}

interface ComponentProps extends StateProps, DispatchProps, RouteComponentProps {
  hide: Function,
  visible: boolean,
}

const initialState = {
  label: '' as string,
  description: '' as string,
  image: '' as string, // should be an image
  quota: 0 as number,
  type: '' as string,
  sequence: 0 as number,
  badgeCollectionGroupId: -1 as number,
  meta: '' as string,
  error: '' as string
};

type ComponentState = typeof initialState;

class AddBadgeCollectionModal extends Component<ComponentProps, ComponentState> {
  readonly state = initialState;

  form = React.createRef<FormInstance>();

  componentDidUpdate(prevProps: Readonly<ComponentProps>, prevState: Readonly<ComponentState>, snapshot?: any) {
    if (prevProps.badgeCollection?.id !== this.props.badgeCollection?.id) {
      if (this.props.badgeCollection) {
        this.setState(
          {
            label: this.props.badgeCollection!.label,
            description: this.props.badgeCollection!.description,
            sequence: this.props.badgeCollection!.sequence,
            badgeCollectionGroupId: this.props.badgeCollection.badgeCollectionGroupId
          }
        );
      } else {
        this.setState(
          {
            label: '',
            description: '',
            sequence: 0,
            badgeCollectionGroupId: -1
          }
        );
      }
    }
  }

  handleBadgeCollectionGroupChange = (badgeCollectionGroupId: number) => {
    this.setState({ badgeCollectionGroupId });
  }

  handleBadgeChange = async (e:any) => {
    const file = e.target.files[0];
    const image = await this.convertBase64(file);
    this.setState({ image });
  }

  hide = () => {
    this.setState({ image: '' });
    this.props.hide();
  }

  convertBase64 = (file:Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  onFinish = (values: any) => {
    const { image } = this.state;
    if (this.props.badgeCollection?.id) {
      Object.assign(values, { id: this.props.badgeCollection.id });
    }
    Object.assign(values, { badge: image });
    this.props.saveBadgeCollection(values);
    this.hide();
  };

  normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  validateImage = (_: any, value: { number: number }) => {
    const { image } = this.state;
    if (image) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Upload an image for the badge'));
  };

  handleOk = () => {
    if (this.form?.current) {
      this.form.current
        .validateFields()
        .then(() => {
          confirm({
            title: 'Are you sure you want to create this badge collection?',
            content: '',
            okText: 'Confirm',
            onOk: () => {
              this.onFinish(this.form.current?.getFieldsValue());
            },
            onCancel() {}
          });
        });
    }
  }

  handleCancel = () => {
    confirm({
      title: 'Are you sure you want to leave this badge collection?',
      content: 'You will lose all changes',
      okText: 'Leave',
      okType: 'danger',
      onOk: () => {
        this.hide();
      },
      onCancel() {}
    });
  }

  render() {
    const {
      visible, badgeCollectionTypes, badgeCollection, badgeGroups
    } = this.props;
    const {
      label, description, quota, image, type, sequence, badgeCollectionGroupId, meta, error
    } = this.state;

    const badgeCollectionTypeOptions = (badgeCollectionTypes || []).map((b:BadgeCollectionTypeType) => {
      return { value: b.id, label: b.label };
    });

    const badgeCollectionGroupOptions = (badgeGroups || []).map((b:BadgeGroupType) => {
      return { value: b.id, label: b.label };
    });

    return (
      <Modal
        className="add-badge-modal"
        title="Add Badge Collection"
        width="45%"
        visible={visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        destroyOnClose
      >
        <Form
          ref={this.form}
          key="form"
          initialValues={badgeCollection}
          onFinish={this.onFinish}
        >
          <Form.Item label="Label" name="label" rules={[{ required: true }]}>
            <Input placeholder="This is the label the user's will see on the badge" />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <Input placeholder="This is the Description the user's will see on the badge" />
          </Form.Item>
          <Form.Item label="Sequence" name="sequence" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item label="Image" name="image" getValueFromEvent={this.normFile} validateTrigger="onSubmit" rules={[{ required: true, validator: this.validateImage }]}>
            <div className="form-row">
              <div className="input">
                {image
                  ? (
                    <Popover style={{ width: '100px' }} placement="right" content={<img src={image} />}>
                      <img style={{ height: '100px' }} src={image} />
                    </Popover>
                  )
                  : <Input ref="file" type="file" name="file" className="upload-file" id="file" onChange={this.handleBadgeChange} encType="multipart/form-data" />}
              </div>
            </div>
          </Form.Item>
          <Form.Item label="Quota" name="quota" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item label="Type" name="badgeCollectionTypeId" rules={[{ required: true }]}>
            <Select
              placeholder="Select a type"
              options={badgeCollectionTypeOptions}
            />
          </Form.Item>
          <Form.Item label="Group" name="badgeCollectionGroupId">
            <Select
              placeholder="Select a group (not required)"
              options={badgeCollectionGroupOptions}
            />
          </Form.Item>
          <Form.Item label="Meta" name="meta">
            <Input placeholder="Use with caution" />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    studyId: selectors.getRequestedStudyId,
    badges: selectors.getBadges,
    badgeGroups: selectors.getBadgeGroups,
    badgeCollectionTypes: selectors.getBadgeCollectionTypes,
    badgeCollection: selectors.getRequestedBadgeCollection
  }
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    saveBadgeCollection: (badgeCollection: BadgeCollectionType) => dispatch(saveBadgeCollectionAsync.request(badgeCollection))
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddBadgeCollectionModal));
