import { get } from 'lodash';
import getAsyncActionName from './api.util';
import { IApiRequestState } from '../../types/api.types';
import IApplicationState from '../../types/state.types';

export const getStatus = (state: IApplicationState, type: string): Optional<IApiRequestState> => {
  const actionName = getAsyncActionName(type);

  if (!actionName) {
    return undefined;
  }
  return get(state, `api.${actionName}`);
};

export default getStatus;
