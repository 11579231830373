import Table from 'antd/lib/table';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { renderDateWithTime } from '../util/Util';
import { NoteType } from '../../types/serverTypes/noteTypes';

const initialState = {
};

type ComponentState = Readonly<typeof initialState>;

interface ComponentProps {
  notes: Optional<NoteType[]>
}

const columns = [
  // {
  //   title: 'ID',
  //   dataIndex: 'id',
  //   key: 'id',
  //   sorter: (a: any, b: any) => a.id - b.id
  // },
  {
    title: 'Created Date',
    dataIndex: 'createDate',
    key: 'createDate',
    render: renderDateWithTime,
    sorter: (a: any, b: any) => {
      return moment(a.createDate || 0).unix() - moment(b.createDate || 0).unix();
    },
    width: '200px',
    defaultSortOrder: 'descend'
  },
  {
    title: 'Admin',
    dataIndex: 'adminUsername',
    key: 'adminUsername',
    sorter: (a: any, b: any) => `${a.adminUsername}`.localeCompare(b.adminUsername),
    width: '100px'
  },
  {
    title: 'Note',
    dataIndex: 'body',
    key: 'body',
    sorter: (a: any, b: any) => `${a.body}`.localeCompare(b.body)
  }

];

const rowClassName = (record: any, index: number): string => {
  return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
};

class NotesTable extends Component<ComponentProps, ComponentState> {
  static defaultProps = { };

  readonly state: ComponentState = initialState;

  render() {

    return (
      <Table
        columns={columns}
        dataSource={this.props.notes}
        pagination={false}
        rowClassName={rowClassName}
        rowKey="id"
      />
    );
  }
}

export default connect(null, null)(NotesTable);
