import { ActionType, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { NormalizedType } from '../../types/state.types';
import {TestkitType} from "../../types/serverTypes/testkitTypes";

export type FulfillTestkitArgumentType = {
  id: number;
  trackingNumber: string;
  estimatedDeliveryDate: string;
}

export type ReviewTestkitArgumentType = {
  id: number;
  actualResult?: string;
  reportDate?: Date;
}

export type UnsubmitTestkitArgumentType = {
  id: number;
}

export const getParticipantsTestkitsAsync = createAsyncAction(
  'hmp/admin/GET_PARTICIPANTS_TESTKITS_REQUEST',
  'hmp/admin/GET_PARTICIPANTS_TESTKITS_SUCCESS',
  'hmp/admin/GET_PARTICIPANTS_TESTKITS_FAILURE'
)<number, Optional<NormalizedType<TestkitType>>, Error>();

export const getRequestedTestkitsAsync = createAsyncAction(
  'hmp/admin/GET_REQUESTED_TESTKITS_REQUEST',
  'hmp/admin/GET_REQUESTED_TESTKITS_SUCCESS',
  'hmp/admin/GET_REQUESTED_TESTKITS_FAILURE'
)<number, Optional<NormalizedType<TestkitType>>, Error>();

export const getAwaitingResultTestkitsAsync = createAsyncAction(
  'hmp/admin/GET_AwaitingResult_TESTKITS_REQUEST',
  'hmp/admin/GET_AwaitingResult_TESTKITS_SUCCESS',
  'hmp/admin/GET_AwaitingResult_TESTKITS_FAILURE'
)<{ studyId: number }, Optional<NormalizedType<TestkitType>>, Error>();

export const getNeedsReviewTestkitsAsync = createAsyncAction(
  'hmp/admin/GET_NeedsReview_TESTKITS_REQUEST',
  'hmp/admin/GET_NeedsReview_TESTKITS_SUCCESS',
  'hmp/admin/GET_NeedsReview_TESTKITS_FAILURE'
)<{ studyId: number }, Optional<NormalizedType<TestkitType>>, Error>();

export const getCompletedTestkitsAsync = createAsyncAction(
  'hmp/admin/GET_COMPLETED_TESTKITS_REQUEST',
  'hmp/admin/GET_COMPLETED_TESTKITS_SUCCESS',
  'hmp/admin/GET_COMPLETED_TESTKITS_FAILURE'
)<{ studyId: number }, Optional<NormalizedType<TestkitType>>, Error>();

export const fulfillTestkitAsync = createAsyncAction(
  'hmp/admin/FULFILL_TESTKIT_REQUEST',
  'hmp/admin/FULFILL_TESTKIT_SUCCESS',
  'hmp/admin/FULFILL_TESTKIT_FAILURE'
)<FulfillTestkitArgumentType, Optional<NormalizedType<TestkitType>>, Error>();

export const reviewTestkitAsync = createAsyncAction(
  'hmp/admin/REVIEW_TESTKIT_REQUEST',
  'hmp/admin/REVIEW_TESTKIT_SUCCESS',
  'hmp/admin/REVIEW_TESTKIT_FAILURE'
)<ReviewTestkitArgumentType, Optional<NormalizedType<TestkitType>>, Error>();

export const updateTestkits = createStandardAction('hmp/admin/UPDATE_TESTKITS')<Optional<NormalizedType<TestkitType>>>();

const testkitActions = {
  getParticipantsTestkitsAsync,
  getRequestedTestkitsAsync,
  getAwaitingResultTestkitsAsync,
  getNeedsReviewTestkitsAsync,
  getCompletedTestkitsAsync,
  fulfillTestkitAsync,
  reviewTestkitAsync,
  updateTestkits
};

export type TestkitActionTypes = ActionType<typeof testkitActions>;
