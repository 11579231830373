import { getType } from "typesafe-actions";
import produce from "immer";
import keys from "lodash/keys";
import map from "lodash/map";
import union from "lodash/union";
import {
  getGoalsAsync,
  updateGoals,
  createGoalAsync,
  updateGoalAsync,
  getGoalAsync,
  clearGoals,
  deleteGoalAsync,
} from "./goals.types";
import {
  initialNormalizedState,
  NormalizedState,
} from "../../types/state.types";
import {GoalType} from "../../types/serverTypes/goalTypes";

const updateGoalsReducer = produce((draft, goals) => {
  if (!goals) {
    return;
  }
  draft.allIds = union(draft.allIds, map(keys(goals), String));
  const ids = Object.keys(goals);
  for (const id of ids) {
    draft.byId[id] = goals[id];
  }
});

export const goalsReducer = (
  state: NormalizedState<GoalType> = initialNormalizedState,
  action: any
) => {
  switch (action.type) {
    case getType(getGoalsAsync.success):
      return updateGoalsReducer(state, action.payload);
    case getType(getGoalAsync.success):
      return updateGoalsReducer(state, action.payload);
    case getType(createGoalAsync.success):
      return updateGoalsReducer(state, action.payload);
    case getType(updateGoalAsync.success):
      return updateGoalsReducer(state, action.payload);
    case getType(deleteGoalAsync.success):
      return updateGoalsReducer(state, action.payload);
    case getType(updateGoals):
      return updateGoalsReducer(state, action.payload);
    case getType(clearGoals): return { allIds: [], byId: {}};
    default:
      return state;
  }
};

export default goalsReducer;
