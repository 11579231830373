import React, { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { DatePicker } from 'antd';
import {
  ParticipantType,
  StudyArmType,
} from '../../../../types/serverTypes/studyTypes';
import Select from 'antd/lib/select';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Radio from 'antd/lib/radio';
import Form, { FormInstance } from 'antd/lib/form';
import Input from 'antd/lib/input';
import { createStructuredSelector } from 'reselect';
import IApplicationState from '../../../../types/state.types';
import * as selectors from '../../../../redux/selectors';
import { connect } from 'react-redux';
import TimezoneSelect from 'react-timezone-select';
import { TimeZoneColorStyles } from '../../../../styles/styles';

interface StateProps {
  accessCodeEnrollmentEnabled: boolean;
  referralCodeEnrollmentEnabled: boolean;
}

interface ComponentProps extends StateProps {
  studyArm: StudyArmType;
  initialParticipant: ParticipantType;
  saveClickHandler: (participant: ParticipantType) => void;
  cancelClickHandler: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
}

interface ComponentState {
  participant: ParticipantType;
  validateStatus: any;
}

const ParticipantEditCard = (props: ComponentProps) => {
  const [state, setState] = useState<ComponentState>({
    participant: cloneDeep(props.initialParticipant),
    validateStatus: {},
  });
  const form = React.createRef<FormInstance>();

  const [selectedTimezone, setSelectedTimezone] = useState<any>(
    props.initialParticipant.timeZone
  );
  const setTimeZone = (timeZoneValue) => {
    setSelectedTimezone(timeZoneValue);
    setState({
      ...state,
      participant: {
        ...state.participant,
        timeZone: timeZoneValue.value,
      },
    });
  };

  const handleSubmit = () => {
    if (form.current) {
      form.current.validateFields().then(() => {
        const isValid = validatePassword();
        if (isValid) {
          props.saveClickHandler(state.participant);
        }
      });
    }
  };

  const validatePassword = () => {
    let isValid = true;
    if (!!state.participant.password) {
      if (
        !state.participant.passwordConfirm ||
        state.participant.password !== state.participant.passwordConfirm
      ) {
        setState({
          ...state,
          validateStatus: {
            ...state.validateStatus,
            passwordConfirm: 'error',
          },
        });
        isValid = false;
      }
    }
    if (isValid) {
      setState({
        ...state,
        validateStatus: {
          ...state.validateStatus,
          passwordConfirm: undefined,
        },
      });
    }
    return isValid;
  };

  const {
    studyArm,
    cancelClickHandler,
    accessCodeEnrollmentEnabled,
    referralCodeEnrollmentEnabled,
  } = props;
  const { participant } = state;
  const dateFormat = 'MM/DD/YYYY';
  const { TextArea } = Input;
  const { Option } = Select;

  return (
    <Card key="edit">
      <Form ref={form} key={participant.id} onFinish={handleSubmit}>
        <div className="profile">
          <div className="profile-container">
            <div className="demo">
              <div className="demo-section">
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    { required: true, message: 'First name is required!' },
                  ]}
                  initialValue={participant.firstName}
                >
                  <Input
                    onChange={(v) => {
                      participant.firstName = v.target.value;
                    }}
                  />
                </Form.Item>
                <Form.Item name="lastName" label="Last Name">
                  <Input
                    defaultValue={participant.lastName}
                    onChange={(v) => {
                      participant.lastName = v.target.value;
                    }}
                  />
                </Form.Item>
                <Form.Item name="username" label="Username">
                  <label>{participant.username}</label>
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ min: 8, message: 'Minimum 8 characters' }]}
                >
                  <Input
                    autoComplete="off"
                    type="password"
                    onChange={(v) => {
                      participant.password = v.target.value;
                      validatePassword();
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="passwordConfirm"
                  label="Confirm Password"
                  hasFeedback
                  validateStatus={state.validateStatus['passwordConfirm']}
                  help={
                    !!state.validateStatus['passwordConfirm']
                      ? 'Must match password'
                      : undefined
                  }
                >
                  <Input
                    type="password"
                    autoComplete="off"
                    onChange={(v) => {
                      participant.passwordConfirm = v.target.value;
                      validatePassword();
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true, message: 'Email is required!' }]}
                  initialValue={participant.email}
                >
                  <Input
                    onChange={(v) => {
                      participant.email = v.target.value;
                    }}
                  />
                </Form.Item>
                <Form.Item name="mobile" label="Mobile">
                  <Input
                    defaultValue={participant.mobile}
                    onChange={(v) => {
                      participant.mobile = v.target.value;
                    }}
                  />
                </Form.Item>
                <Form.Item name="address1" label="Address">
                  <Input
                    defaultValue={participant.address1}
                    onChange={(v) => {
                      participant.address1 = v.target.value;
                    }}
                  />
                </Form.Item>
                <Form.Item name="address2" label="Line 2">
                  <Input
                    defaultValue={participant.address2}
                    onChange={(v) => {
                      participant.address2 = v.target.value;
                    }}
                  />
                </Form.Item>
                <Form.Item name="city" label="City">
                  <Input
                    defaultValue={participant.city}
                    onChange={(v) => {
                      participant.city = v.target.value;
                    }}
                  />
                </Form.Item>
                <Form.Item name="state" label="State">
                  <Input
                    defaultValue={participant.state}
                    onChange={(v) => {
                      participant.state = v.target.value;
                    }}
                  />
                </Form.Item>
                <Form.Item name="postalCode" label="Postal Code">
                  <Input
                    defaultValue={participant.postalCode}
                    onChange={(v) => {
                      participant.postalCode = v.target.value;
                    }}
                  />
                </Form.Item>
                <Form.Item name="dateOfBirth" label="Date of Birth">
                  <DatePicker
                    defaultValue={moment(participant.dateOfBirth)}
                    format={dateFormat}
                    onChange={(date, dateString) => {
                      participant.dateOfBirth = date
                        ? date.toDate()
                        : undefined;
                    }}
                  />
                </Form.Item>
                <Form.Item name="age" label="Age">
                  <label>
                    {moment().diff(participant.dateOfBirth, 'years')}
                  </label>
                </Form.Item>
                <Form.Item name="genderAtBirth" label="Sex at Birth">
                  <Radio.Group
                    defaultValue={participant.genderAtBirth}
                    onChange={(v) => {
                      participant.genderAtBirth = v.target.value;
                    }}
                  >
                    <Radio value="F">FEMALE</Radio>
                    <Radio value="M">MALE</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item name="genderIdentity" label="Gender Identity">
                  <Input
                    defaultValue={participant.genderIdentity}
                    onChange={(v) => {
                      participant.genderIdentity = v.target.value;
                    }}
                  />
                </Form.Item>
                <Form.Item name="ethnicity" label="Ethnicity">
                  <Input
                    defaultValue={participant.ethnicity}
                    onChange={(v) => {
                      participant.ethnicity = v.target.value;
                    }}
                  />
                </Form.Item>
              </div>
              <div className="demo-section">
                <Form.Item name="createDate" label="Created Date">
                  <label>
                    {moment(participant.createDate).format('MM/DD/YYYY')}
                  </label>
                </Form.Item>
                <Form.Item name="studyArm" label="Study Arm">
                  <label>{studyArm.name}</label>
                </Form.Item>
                <Form.Item name="status" label="Status">
                  <Select
                    style={{ width: 200 }}
                    defaultValue={participant.status}
                    onChange={(value: string) => {
                      participant.status = value;
                    }}
                  >
                    <Option value="APPLIED">APPLIED</Option>
                    <Option value="DISQUALIFIED">DISQUALIFIED</Option>
                    <Option value="ACCEPTED">ACCEPTED</Option>
                    <Option value="ACTIVE">ACTIVE</Option>
                    <Option value="DROPPED">DROPPED</Option>
                    <Option value="DISMISSED">DISMISSED</Option>
                    <Option value="FINISHED">FINISHED</Option>
                  </Select>
                </Form.Item>
                {accessCodeEnrollmentEnabled && (
                  <Form.Item label="Access Code">
                    <Input
                      defaultValue={participant.accessCode}
                      onChange={(v) => {
                        participant.accessCode = v.target.value;
                      }}
                    />
                  </Form.Item>
                )}
                {referralCodeEnrollmentEnabled && (
                  <Form.Item label="Referral Code">
                    <Input
                      defaultValue={participant.referralCode}
                      onChange={(v) => {
                        participant.referralCode = v.target.value;
                      }}
                    />
                  </Form.Item>
                )}
                <Form.Item name="pin" label="Pin">
                  <Input
                    defaultValue={participant.pin}
                    onChange={(v) => {
                      participant.pin = v.target.value;
                    }}
                  />
                </Form.Item>
                <Form.Item name="externalId" label="External ID">
                  <Input
                    defaultValue={participant.externalId}
                    onChange={(v) => {
                      participant.externalId = v.target.value;
                    }}
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item label="Time Zone">
                  <TimezoneSelect
                    value={selectedTimezone}
                    onChange={setTimeZone}
                    styles={TimeZoneColorStyles}
                    labelStyle="abbrev"
                  />
                </Form.Item>
              </div>
              <div className="demo-section">
                <Form.Item name="appliedDate" label="Applied Date">
                  <DatePicker
                    defaultValue={
                      participant.appliedDate
                        ? moment(participant.appliedDate)
                        : undefined
                    }
                    format={dateFormat}
                    onChange={(date, dateString) => {
                      participant.appliedDate = date
                        ? date.toDate()
                        : undefined;
                    }}
                  />
                </Form.Item>
                <Form.Item name="acceptedDate" label="Accepted Date">
                  <DatePicker
                    defaultValue={
                      participant.acceptedDate
                        ? moment(participant.acceptedDate)
                        : undefined
                    }
                    format={dateFormat}
                    onChange={(date, dateString) => {
                      participant.acceptedDate = date
                        ? date.toDate()
                        : undefined;
                    }}
                  />
                </Form.Item>
                <Form.Item name="startDate" label="Start Date">
                  <DatePicker
                    defaultValue={
                      participant.startDate
                        ? moment(participant.startDate)
                        : undefined
                    }
                    format={dateFormat}
                    onChange={(date, dateString) => {
                      participant.startDate = date ? date.toDate() : undefined;
                    }}
                  />
                </Form.Item>
                <Form.Item name="endDate" label="End Date">
                  <DatePicker
                    defaultValue={
                      participant.endDate
                        ? moment(participant.endDate)
                        : undefined
                    }
                    format={dateFormat}
                    onChange={(date, dateString) => {
                      participant.endDate = date ? date.toDate() : undefined;
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="mobileEnrollmentDate"
                  label="Mobile Enroll Date"
                >
                  <DatePicker
                    defaultValue={
                      participant.mobileEnrollmentDate
                        ? moment(participant.mobileEnrollmentDate)
                        : undefined
                    }
                    format={dateFormat}
                    onChange={(date, dateString) => {
                      participant.mobileEnrollmentDate = date
                        ? date.toDate()
                        : undefined;
                    }}
                  />
                </Form.Item>
                <Form.Item name="graduationDate" label="Graduation Date">
                  <DatePicker
                    defaultValue={
                      participant.graduationDate
                        ? moment(participant.graduationDate)
                        : undefined
                    }
                    format={dateFormat}
                    onChange={(date, dateString) => {
                      participant.graduationDate = date
                        ? date.toDate()
                        : undefined;
                    }}
                  />
                </Form.Item>
              </div>
              <div className="demo-section">
                <Form.Item
                  name="aboutMe"
                  label="About Me"
                  style={{ alignItems: 'flex-start' }}
                >
                  <TextArea
                    rows={3}
                    defaultValue={participant.aboutMe}
                    onChange={(v) => {
                      participant.aboutMe = v.target.value;
                    }}
                    style={{ width: '250px' }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="actions">
              <Button htmlType="submit">Save</Button>
              <br />
              <Button onClick={cancelClickHandler}>Cancel</Button>
            </div>
          </div>
        </div>
      </Form>
    </Card>
  );
};
const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    accessCodeEnrollmentEnabled: selectors.getAccessCodeEnrollmentEnabled,
    referralCodeEnrollmentEnabled: selectors.getReferralsFeatureEnabled,
  }
);

export default connect(mapStateToProps, null)(ParticipantEditCard);
