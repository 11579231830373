import { _ } from 'lodash';
import { Button, Card, Dropdown, Menu, Tabs } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Steps from 'antd/lib/steps';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Dispatch } from 'redux';
import BadgesTable from '../../../../components/badge/BadgesTable';
import CommentsTable from '../../../../components/forum/CommentsTable';
import PostsTable from '../../../../components/forum/PostsTable';
import IncentivesTable from '../../../../components/incentives/IncentivesTable';
import NotificationLogTable from '../../../../components/notification/NotificationLogTable';
import UserSurveyTable from '../../../../components/survey/UserSurveyTable';
import TestkitTable from '../../../../components/testkit/TestkitTable';
import {
  loadRequestedParticipantAsync,
  saveParticipantAsync,
} from '../../../../redux/participants/participants.types';
import * as selectors from '../../../../redux/selectors';
import IApplicationState from '../../../../types/state.types';
import './participant.scss';
import ParticipantEditCard from './ParticipantEditCard';
import ParticipantViewCard from './ParticipantViewCard';
import { IApiRequestState } from '../../../../types/api.types';
import ThreadsTable from '../../../../components/forum/ThreadsTable';
import FormTable from '../../../../components/form/FormTable';
import ParticipantDemographicsCard from './ParticipantDemographicsCard';
import ParticipantNoteCard from './ParticipantNoteCard';
import { saveParticipantNoteAsync } from '../../../../redux/notes/notes.types';
import FeatureNames from '../../../../components/util/FeatureNames';
import { AvatarType } from '../../../../types/serverTypes/avatarTypes';
import {
  CommentType,
  PostType,
} from '../../../../types/serverTypes/forumTypes';
import { TestkitType } from '../../../../types/serverTypes/testkitTypes';
import {
  ParticipantType,
  StudyArmType,
} from '../../../../types/serverTypes/studyTypes';
import { NoteType } from '../../../../types/serverTypes/noteTypes';
import { NotificationType } from '../../../../types/serverTypes/notificationTypes';
import { FormType } from '../../../../types/serverTypes/formTypes';
import { PaymentType } from '../../../../types/serverTypes/paymentTypes';
import { MessageThreadType } from '../../../../types/serverTypes/messageTypes';
import { UserSurveyType } from '../../../../types';
import { BadgeType } from '../../../../types/serverTypes';

const Tab = Tabs;

const { Step } = Steps;

const footerActionStyle = {
  textAlign: 'right',
  width: '50%',
};

interface StateProps {
  avatar: Optional<AvatarType>;
  loadParticipantStatus: IApiRequestState;
  studyArm: Optional<StudyArmType>;
  participant: Optional<ParticipantType>;
  notes: Optional<NoteType[]>;
  posts: Optional<PostType[]>;
  comments: Optional<CommentType[]>;
  threads: Optional<MessageThreadType[]>;
  forms: Optional<FormType[]>;
  testkits: Optional<TestkitType[]>;
  participantSubpage: Optional<string>;
  userSurveys: Optional<UserSurveyType[]>;
  incentives: Optional<PaymentType[]>;
  participantBadges: Optional<BadgeType[]>;
  showSurveys: boolean;
  showTestKits: boolean;
  showForms: boolean;
  showIncentives: boolean;
}

interface DispatchProps {
  loadParticipant: typeof loadRequestedParticipantAsync.request;
  saveParticipant: typeof saveParticipantAsync.request;
  saveParticipantNote: typeof saveParticipantNoteAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {}

const initialState = {
  inEditMode: false as boolean,
  editedParticipant: {} as ParticipantType,
  selectedKey: 'posts' as string,
  activeForm: '',
};
type ComponentState = Readonly<typeof initialState>;

class Participant extends Component<ComponentProps, ComponentState> {
  readonly state: ComponentState = initialState;

  componentDidMount(): void {
    const { loadParticipant } = this.props;
    loadParticipant();
  }

  handleEditClick = () => {
    const { participant } = this.props;
    if (participant) {
      this.setState({ inEditMode: true, editedParticipant: participant });
    }
  };

  handleSaveClick = (participant: ParticipantType) => {
    const { saveParticipant } = this.props;
    saveParticipant(participant);
    this.setState({ inEditMode: false });
  };

  handleSaveNoteClick = (note: NoteType) => {
    const { saveParticipantNote } = this.props;
    saveParticipantNote(note);
  };

  handleCancelClick = () => {
    this.setState({ inEditMode: false });
  };

  closeForm = () => {
    this.setState({
      activeForm: '',
    });
  };

  handleMenuClick(e: any) {
    this.setState({
      activeForm: e.key,
    });
  }

  formOptions = (
    <Menu onClick={this.handleMenuClick.bind(this)}>
      <Menu.Item key="ADVERSE_EVENT">Adverse Event</Menu.Item>
      <Menu.Item key="EARLY_TERMINATION">Early Termination</Menu.Item>
      <Menu.Item key="PROTOCOL_DEVIATION">Protocol Deviation</Menu.Item>
      <Menu.Item key="SOCIAL_HARM">Social Harm</Menu.Item>
    </Menu>
  );

  renderFormModal = (type: string) => {
    switch (type) {
      default:
        return <div />;
    }
  };

  render() {
    const {
      avatar,
      loadParticipantStatus,
      studyArm,
      participant,
      posts,
      notes,
      threads,
      forms,
      testkits,
      comments,
      userSurveys,
      incentives,
      participantBadges,
      showSurveys,
      showIncentives,
      showForms,
      showTestKits,
    } = this.props;
    const participantSubpage =
      this.props.participantSubpage === ''
        ? 'Timeline'
        : this.props.participantSubpage;
    const { editedParticipant, activeForm } = this.state;

    if (loadParticipantStatus.isLoading) {
      return <div>Loading Participant</div>;
    }

    if (loadParticipantStatus.isError) {
      return <div>Error loading the requested participant.</div>;
    }

    if (studyArm === undefined) {
      return (
        <div>
          The participant is assigned to an invalid study arm or you do not have
          permissions to view the study.
        </div>
      );
    }

    if (participant === undefined) {
      return (
        <div>
          The participant does not exist or you do not have permissions to view
          the participant.
        </div>
      );
    }

    const avatarBase64 = avatar ? avatar.avatar : undefined;

    return (
      <div id="participant">
        <Steps type="navigation" size="small" current={1}>
          <Step title="Accepted" />
          <Step title={`${_.capitalize(participant.status)}`} />
          <Step title="Finished" />
        </Steps>
        <ParticipantViewCard
          avatarBase64={avatarBase64}
          participant={participant}
        />
        <ParticipantNoteCard
          participant={participant}
          notes={notes}
          saveClickHandler={this.handleSaveNoteClick}
        />
        {this.renderFormModal(activeForm)}
        <div id="tab-bar">
          <Tab defaultActiveKey={participantSubpage}>
            {/* <Tab.TabPane tab="Timeline" key="1"> */}
            {/*  <div> */}
            {/*    <ParticipantTimelineCard /> */}
            {/*  </div> */}
            {/* </Tab.TabPane> */}
            <Tab.TabPane tab="Demographics" key="2">
              <div>
                {this.state.inEditMode ? (
                  <ParticipantEditCard
                    studyArm={studyArm}
                    initialParticipant={participant}
                    saveClickHandler={this.handleSaveClick}
                    cancelClickHandler={this.handleCancelClick}
                  />
                ) : (
                  <ParticipantDemographicsCard
                    studyArm={studyArm}
                    participant={participant}
                    editClickHandler={this.handleEditClick}
                  />
                )}
              </div>
            </Tab.TabPane>
            {showSurveys && (
              <Tab.TabPane tab="User Surveys" key="userSurveys">
                <div>
                  <UserSurveyTable userSurveys={userSurveys} />
                </div>
              </Tab.TabPane>
            )}
            <Tab.TabPane tab="Posts" key="forum">
              <div>
                <PostsTable posts={posts} />
              </div>
            </Tab.TabPane>
            <Tab.TabPane tab="Comments" key="comments">
              <div>
                <CommentsTable comments={comments} />
              </div>
            </Tab.TabPane>
            <Tab.TabPane tab="Message Threads" key="4">
              <Card>
                <div>
                  <ThreadsTable threads={threads} />
                </div>
              </Card>
            </Tab.TabPane>
            {showForms && (
              <Tab.TabPane tab="Forms" key="5">
                <Card
                  style={{ background: 'white' }}
                  title={
                    <Dropdown
                      style={footerActionStyle}
                      overlay={this.formOptions}
                    >
                      <Button type="default">
                        New Form <DownOutlined />
                      </Button>
                    </Dropdown>
                  }
                >
                  <FormTable forms={forms} />
                </Card>
              </Tab.TabPane>
            )}
            <Tab.TabPane tab="Notifications" key="6">
              <div>
                <NotificationLogTable participantId={participant.id} />
              </div>
            </Tab.TabPane>
            {showTestKits && (
              <Tab.TabPane tab="Test Kits" key="7">
                <div>
                  <TestkitTable participantTestkits={testkits} />
                </div>
              </Tab.TabPane>
            )}
            {showIncentives && (
              <Tab.TabPane tab="Incentives" key="8">
                <div>
                  <IncentivesTable
                    participantId={participant.id}
                    participantIncentives={incentives}
                  />
                </div>
              </Tab.TabPane>
            )}
            <Tab.TabPane tab="Badges" key="9">
              <div>
                <BadgesTable participantBadges={participantBadges} />
              </div>
            </Tab.TabPane>
          </Tab>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    avatar: selectors.getRequestedStudyParticipantAvatar,
    loadParticipantStatus: selectors.loadRequestedParticipantStatus,
    studyArm: selectors.getRequestedStudyParticipantStudyArm,
    participant: selectors.getRequestedStudyParticipant,
    notes: selectors.getRequestedStudyParticipantNotes,
    posts: selectors.getRequestedStudyParticipantPosts,
    comments: selectors.getRequestedStudyParticipantComments,
    threads: selectors.getRequestedStudyParticipantThreads,
    forms: selectors.getRequestedParticipantForms,
    testkits: selectors.getRequestedParticipantTestkits,
    participantSubpage: selectors.getRequestedParticipantTab,
    userSurveys: selectors.getRequestedParticipantUserSurveys,
    incentives: selectors.getRequestedParticipantIncentives,
    participantBadges: selectors.getRequestedStudyParticipantBadges,
    showSurveys: selectors.getSurveysFeatureEnabled,
    showIncentives: selectors.getIncentivesFeatureEnabled,
    showTestKits: selectors.getTestKitsFeatureEnabled,
    showForms: selectors.getFormsFeatureEnabled,
  }
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadParticipant: () => dispatch(loadRequestedParticipantAsync.request()),
    saveParticipant: (participant: ParticipantType) =>
      dispatch(saveParticipantAsync.request(participant)),
    saveParticipantNote: (note: NoteType) =>
      dispatch(saveParticipantNoteAsync.request(note)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Participant);
